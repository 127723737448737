import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import StarIcon from '@material-ui/icons/Star'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ProfileActions } from '../profile/ProfileActions';
import Typography from '@material-ui/core/Typography';
import { capitalizeArray, removeDuplicate } from '../../utils/utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalTags } from '../../utils/DataHelper'
import TextField from '@material-ui/core/TextField'
import '../../styles/Profile.css'

export default class tags extends Component {
    state = {
        editMode: false,
        newTag: ''
    }

    sectionFields = ['tags', 'selectedTags'];

    handleEdit = () => {
        this.setState({
            editMode: true,
            newTag: ''
        })

        this.props.changeOnEditCount(true)
    }

    handleSaveSection = e => {
        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false,
            newTag: ''
        })

        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false,
            newTag: ''
        })

        this.props.changeOnEditCount(false)
    }

    handleDeleteSkill = tag => {
        const tags = this.props.jobInfo.tags || [];
        const selectedTags = this.props.jobInfo.selectedTags || [];
        this.props.handleChange(tags.filter(item => item !== tag), selectedTags.filter(item => item !== tag))
    }

    handleClickSkill = tag => {
        const selectedTags = this.props.jobInfo.selectedTags || []
        if (selectedTags.includes(tag)){
            this.props.handleChange(null, selectedTags.filter(item => item !== tag))
        } else {
            if(selectedTags.length < 3){
                this.props.handleChange(null, [...selectedTags, tag])
            }
        }
    }

    handleChangeTags = (event, value)=> {
        const tags = removeDuplicate(capitalizeArray(value)) || [];  
        const selectedTags = removeDuplicate(capitalizeArray(this.props.jobInfo.selectedTags)) || []

        if (tags.length <= 10){
            this.props.handleChange(tags, selectedTags.filter(selectedTag => tags.includes(selectedTag)));
        }
    }

    getFields = (tags, selectedTags, jobType) => {
        const tagsSuggestions = getLocalTags()
        
        return (
            <React.Fragment>
                <Typography>Add up to 10 tags. Mark up to 3 {(jobType)? 'most required' : 'of your best'} tags ({(selectedTags.length === 3)?'Max Reached':3-selectedTags.length +' left'})</Typography>
                {/*(tags.length < 10)?*/
                <Autocomplete
                    value={tags}
                    multiple
                    options={tagsSuggestions.map(option => option)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip 
                                className="profileTag" 
                                variant="outlined" 
                                label={option}
                                onClick={()=>this.handleClickSkill(option)}
                                icon={<StarIcon size={20} className={(selectedTags.includes(option))?'inputTag selected':'inputTag'}/>}
                                {...getTagProps({ index })} 
                            />
                        ))
                    }
                    onChange={this.handleChangeTags}
                    renderInput={params => (
                    <TextField
                        {...params}
                        placeholder="Skill or Specialization"
                        fullWidth
                    />
                    )}
                />
                /*:<div>
                    {tags.map(tag => 
                        <Chip key={tag} className="profileTag" label={tag} variant="outlined" 
                            onDelete={()=>this.handleDeleteSkill(tag)} 
                            onClick={()=>this.handleClickSkill(tag)}
                            icon={<StarIcon size={20} className={(selectedTags.includes(tag))?'selected':'star'}/>}
                        />
                    )}
                </div>*/}
            </React.Fragment>
        )
    }

    render() {
        const { jobInfo, noEdit, isNew, jobType, userTags, isAuthed } = this.props
        const {editMode} = this.state

        const selectedTags = removeDuplicate(capitalizeArray(jobInfo.selectedTags)) || [];  
        const tags = removeDuplicate(capitalizeArray(jobInfo.tags)) || [];  

        const userHasTags = (isAuthed && userTags !== undefined)

        let firstTags = []
        let secondTags = []

        if (userHasTags){
            firstTags = tags.filter(tag => userTags.includes(tag))
            secondTags = tags.filter(tag => !userTags.includes(tag))
        } else {
            firstTags = selectedTags
            secondTags = tags.filter(tag => !selectedTags.includes(tag))
        }

        return (
            <React.Fragment>
                {(noEdit) ? 
                    <React.Fragment>
                        {(firstTags.length > 0 || secondTags.length > 0)&&<div className="resumeSection" style={{direction:"ltr"}}>
                            <div className="sectionTitle">{(userHasTags) ? 'How you match' : 'Skills & Specialization'}</div>
                            {(userHasTags)&&<Typography className="sectionText">See how your JobSwipe profile matches the job poster’s preferences.</Typography>}
                            <div className="profileField">
                                {firstTags.map(tag => 
                                    <Chip key={tag} className="profileTag" label={tag} variant="outlined" 
                                        icon={(userHasTags)?<CheckCircleIcon fontSize="small" className='selected'/>:<StarIcon fontSize="small" className='selected'/>}
                                    />
                                )}
                                {secondTags.map(tag => 
                                    <Chip key={tag} className="profileTag" label={tag} variant="outlined" />
                                )}
                            </div>
                        </div>}
                    </React.Fragment>:
                    <Paper className="profileSection" elevation={1}>
                        <div className="sectionTitle">Tags</div>
                        <Typography className="sectionText">
                            <span>e.g. “Java”, “web developer” etc.</span><br/>
                            <span>Tags will enable candidates to find your job with keywords</span>
                        </Typography>
                        {(isNew) && this.getFields(tags, selectedTags, jobType)}
                        {(editMode && !isNew) && 
                            <React.Fragment>
                                {this.getFields(tags, selectedTags, jobType)}
                                <div className="profileButtons">
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.handleCancel}
                                        aria-label="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={this.handleSaveSection}
                                        aria-label="Save Changes"
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </React.Fragment>}
                        {(!editMode && !isNew) && 
                            <React.Fragment>
                                <ProfileActions handleEdit={this.handleEdit}/>   
                                <div>
                                    {(tags.length > 0)?
                                        <div className="profileField">
                                            {tags.map(tag => 
                                                <Chip key={tag} className="profileTag" label={tag} variant="outlined" 
                                                    icon={<StarIcon size={20} className={(selectedTags.includes(tag))?'selected':'star'}/>}
                                                />
                                            )}
                                        </div>:
                                        <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Add Skills or Specializations</span></React.Fragment>}
                                </div>      
                            </React.Fragment>}
                    </Paper>}
            </React.Fragment>
        )
    }
}