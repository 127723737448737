import React/*, { useState, useEffect, Fragment }*/ from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

export default function JSTablePagination(props) {
    const count = props.count
    const rowsPerPage = props.rowsPerPage
    const page = props.page
    const disabled = props.disabled

    const lastPage = (rowsPerPage===0)?0:(Math.ceil(count/rowsPerPage)-1)

    let fromRows = page*rowsPerPage+1
    if(fromRows>count){
        fromRows = count
    }

    let toRows = (page+1)*rowsPerPage
    if(toRows>count){
        toRows = count
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 52}}>
            <div>
                {props.children}
            </div>
            <div style={{display: 'flex',alignItems: 'center'}}>
                {<Typography variant='body2' style={{marginRight: 20}}><span>{fromRows}-{toRows} of {count}</span></Typography>}
                {(lastPage>1)&&<IconButton
                    onClick={()=>{props.onChangePage(0)}}
                    disabled={disabled || page===0}
                >
                    <FirstPageIcon/>
                </IconButton>}
                <IconButton
                    onClick={()=>{props.onChangePage(page-1)}}
                    disabled={disabled || page===0}
                >
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton
                    onClick={()=>{props.onChangePage(page+1)}}
                    disabled={disabled || page===lastPage}
                >
                    <ChevronRightIcon/>
                </IconButton>
                {(lastPage>1)&&<IconButton
                    onClick={()=>{props.onChangePage(lastPage)}}
                    disabled={disabled || page===lastPage}
                >
                    <LastPageIcon/>
                </IconButton>}
            </div>
        </div>
    )
}