import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import JHApi from "../utils/JHApi"

export default function FieldTitle(props) {
    const field = props.field || {}

    const titleGroup = props.getValue(field.name, '')

    const [selectedValue, setSelectedValue] = useState({title:titleGroup});

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([{title:titleGroup}]);
    const [loadTitles, setLoadTitles] = useState(false);

    useEffect(() => {
        if(open){
            setLoadTitles(true)

            JHApi.getTitlesOptions({},(response)=>{
                setOptions([{title:''},...response]);
                setLoadTitles(false)
            }, (error) => {
                setLoadTitles(false)
            })
        } else {
            //setOptions([{title:newData.title}]);
        }
    }, [open]);

    const handleSelectTitleOption = (event, newValue) => {
        if (newValue && newValue.title){
            const categories = (options.filter(option => option.title === newValue.title)).map(option => option.category)
            field.handleChangeTitle(newValue.title, categories)
        } else {
            field.handleChangeTitle('', undefined)
        }

        setSelectedValue(newValue||{title:''})
    }

    return (
        <>
            <Grid item sm={field.sm || 6} xs={field.xs || 12}>
                <Autocomplete
                    onChange={handleSelectTitleOption}
                    value={selectedValue}
                    options={options}
                    groupBy={(option) => option.category}
                    getOptionLabel={(option) => option.title||''}
                    open={open}
                    onOpen={() => {setOpen(true);}}
                    onClose={() => {setOpen(false);}}
                    getOptionSelected={(option, value) => option.title === value.title}
                    loading={loadTitles}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={field.name} label={field.label} margin="none" fullWidth required
                        />
                    )}
                />
            </Grid>
        </>
    )
}