import React, { Component } from 'react'

import Button from '@material-ui/core/Button';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';

import '../styles/Pagination.css'

export default class Pagination extends Component {
    handlePageClick = (pageOffset) => {
        if (pageOffset !== this.props.offset){
            this.props.pageChange(pageOffset);
        }
    }

    render() {
        const { offset, pages, maxPage } = this.props;

        let from = (offset - 2 < 1) ? 1 : offset - 2;
        let to = (from + pages - 1 > maxPage) ? maxPage : from + pages - 1;
        if (to === maxPage){
            from = (to - pages + 1 < 1) ? 1 : to - pages + 1;
        }

        let pagesToShow = [];

        for (let i = from; i <= to; i++){
            pagesToShow.push(i)
        }
        return (
            <div className="pageContainer">
                {(offset > 1) && 
                <Button className="page" size="small" variant="outlined" color="primary" onClick={() => this.handlePageClick(offset - 1)} aria-label="Previous Page">
                    <NavigateBefore/>
                </Button> }
                {pagesToShow.map((page) => 
                <Button className="page" size="small" key={page} variant={(page === offset) ? 'contained' : 'outlined'} color="primary" onClick={() => this.handlePageClick(page)}  aria-label={page}>
                    {page}
                </Button>
                )}
                {(offset < maxPage) && 
                <Button className="page" size="small" variant="outlined" color="primary" onClick={() => this.handlePageClick(offset + 1)} aria-label="Next Page">
                    <NavigateNext/>
                </Button>
                }
            </div>
        )
    }
}