import React, { useState } from 'react';
import { displayPosition, showDateTimeFromString } from '../../../utils/utils';
import { Button, Collapse, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';

export default function JourneyCycle(props) {
    const cycle = props.cycle || {}
    const publishesDataForCycle = props.publishesDataForCycle || {}

    const isAdmin = props.isAdmin

    const [showParts, setShowParts] = useState(false);
    const [showCoverLeter, setShowCoverLeter] = useState(false);

    const handleHideCoverLeter = () => {
        setShowCoverLeter(false)
    }

    const handleShowCoverLeter = () => {
        setShowCoverLeter(true)
    }

    return (
        <div>
            <Dialog onClose={handleHideCoverLeter} open={showCoverLeter} fullWidth>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: publishesDataForCycle.coverLetter||''}} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHideCoverLeter} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <strong>{publishesDataForCycle.first_name} {publishesDataForCycle.last_name} {displayPosition(cycle.position) + ' Publication'} - {showDateTimeFromString(cycle.firstSent, false)}</strong> <br/>
            {publishesDataForCycle.emailTitle} <br/>
            <span style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleShowCoverLeter}>Cover letter</span> 
            <span>  |  </span>
            <a style={{color: '#1861bf', textDecoration: 'none'}} target='_blank' rel="noopener noreferrer" href={publishesDataForCycle.CVUrl}>CV</a>

            {/*<div style={{margin: '16px 0'}}>
                {<Rating name={"rating"+cycle._id} size="small" value={cycle.rating || 0} onChange={handleChangeCycleRating} style={{marginRight: 8}} readOnly={!isAdmin}/>}
                {(isAdmin||cycle.csComment)&&<FieldWithEdit value={cycle.csComment||''} changeValue={handleCycleComment} fullWidth multiline newLine noPaddingButton noEdit={!isAdmin}>
                    <span style={{whiteSpace: 'pre-line'}}>{cycle.csComment||'No Summary'}</span>
                </FieldWithEdit>}
            </div>*/}

            {/*(cycle.csComment||isAdmin)&&<>
                <br/><br/>
                {editSummary?
                    <EditComment summary={{csComment:cycle.csComment, summaryDate:cycle.summaryDate||today}} disabled={disabled} save={handleSaveSummary} cancel={handleCancelSummary}/>:
                    <Summary disabled={disabled} isAdmin={isAdmin} summary={{summary:cycle.csComment, author:cycle.summaryAuthor, date:cycle.summaryDate}} handleEditSummary={handleEditSummary} handleCopySummary={handleCopySummary} name={name}/>}
            </>*/}

            {/*<div style={{margin: '16px 0'}}>
                <Rating precision={0.5} value={ratings.avg.ratingGeneral || 0} size="small" readOnly/>
                <RatingsExtra data={ratings.avg}/>
                <span style={{marginLeft: 8}}><strong>{interviewProcesses.length} procceses ({interviewCount} interviews)</strong> {(interviewProcesses.length>0) && <Typography component='span' style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=> {setShowInterviews(!showInterviews)}}>show {showInterviews?'less':'more'}</Typography>}</span>
                <Collapse in={showInterviews} timeout="auto" unmountOnExit>
                    {interviewProcesses.map(interviewProcess => 
                        <div key={interviewProcess.company}>
                            <Rating precision={0.5} value={interviewProcess.ratingGeneral} size="small" readOnly/>
                            <RatingsExtra data={interviewProcess}/>
                            <span style={{marginLeft: 8}}>{interviewProcess.interviews.length} interviews at <span style={{textTransform: 'capitalize'}}>{interviewProcess.company}</span> - {interviewProcess.status}
                                {(isAdmin||interviewProcess.csComment)&&<FieldWithEdit value={interviewProcess.csComment||''} changeValue={newComment => {handleInterviewComment(interviewProcess.company, newComment)}} fullWidth multiline newLine noPaddingButton noEdit={!isAdmin}>
                                    <span>{' - ' + (interviewProcess.csComment||'No Summary')}</span>
                                </FieldWithEdit>}
                            </span>
                        </div>
                    )}
                </Collapse>
            </div>*/}

            {/*<div style={{margin: '16px 0'}}>2 offers, If have </div>*/}

            {isAdmin&&<div>
                <br/>
                <Typography component='span' style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=> {setShowParts(!showParts)}}>{showParts?'hide':'show'} parts</Typography> <br/>
                <Collapse in={showParts} timeout="auto" unmountOnExit>
                    1 - {cycle.part1.date?`Sent to ${cycle.part1.sentCount||''} emails on ${showDateTimeFromString(cycle.part1.date, false)}`:''} <br/>
                    2 - {cycle.part2.date?`Sent to ${cycle.part2.sentCount||''} emails on ${showDateTimeFromString(cycle.part2.date, false)}`:''} <br/>
                    3 - {cycle.part3.date?`Sent to ${cycle.part3.sentCount||''} emails on ${showDateTimeFromString(cycle.part3.date, false)}`:''} <br/>
                    4 - {cycle.part4.date?`Sent to ${cycle.part4.sentCount||''} emails on ${showDateTimeFromString(cycle.part4.date, false)}`:''} <br/>
                    <strong>Total - Sent to {(cycle.part1.sentCount||0) + (cycle.part2.sentCount||0) + (cycle.part3.sentCount||0) + (cycle.part4.sentCount||0)} emails</strong>
                </Collapse>
            </div>}
        </div>
    )
}