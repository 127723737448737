import { Paper, Typography } from '@material-ui/core';
import React/*, { useState, useEffect, Fragment }*/ from 'react';
import { PersonalJourneyClasses } from '../../../styles/PersonalJourneyClasses';

export default function Social(props) {
    const classes = PersonalJourneyClasses();
    return (
        <div>
            <Paper className={classes.prePublishSection} elevation={1} style={{marginTop:0}}>
                <Typography variant='h5'> How to Build & Grow your Social Presence</Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>Linkedin</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    <a href='https://www.linkedin.com/in/ronozery/' target='_blank' rel="noopener noreferrer">לינקדאין</a> היא רשת חברתית ממוקדת בתחום התעסוקה, בבעלות מיקרוסופט. <br/>
                    אנחנו רואים שהרבה מעסיקים נכנסים לראות את פרופיל הלינקדאין (גרסת אונליין של הקו"ח), התמונה והפעילות <br/>
                    של מועמדים אחרי שהם מקבלים קורות חיים, לכן חשוב לוודא שהלינקדאין מסונכרן עם הקו"ח המעודכנים. 
                </Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>1. Build Profile</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    Short Linkedin Webinar with all the tips: <br/>
                    <a href='https://www.linkedin.com/feed/update/urn:li:activity:6839207445005389824' target='_blank' rel="noopener noreferrer">https://www.linkedin.com/feed/update/urn:li:activity:6839207445005389824</a>
                </Typography>
            
                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>2. Grow</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    Share 5 popular posts from this page everyday <br/>
                    <a href='https://www.linkedin.com/showcase/%D7%9E%D7%A9%D7%A8%D7%95%D7%AA-%D7%94%D7%99%D7%99%D7%98%D7%A7-%D7%91%D7%99%D7%9F-%D7%97%D7%91%D7%A8%D7%99%D7%9D/?viewAsMember=true' target='_blank' rel="noopener noreferrer">https://www.linkedin.com/showcase/משרות-הייטק-בין-חברים/?viewAsMember=true</a>
                </Typography>

                <Typography dir='auto' style={{marginTop:8}}><strong>3. Track</strong> your Progress here:</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    <a href='https://www.linkedin.com/sales/ssi' target='_blank' rel="noopener noreferrer">https://www.linkedin.com/sales/ssi</a> <br/>
                    <a href='https://www.linkedin.com/analytics/creator' target='_blank' rel="noopener noreferrer">https://www.linkedin.com/analytics/creator</a>
                </Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>4. Linkedin Job Research: </Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    באמצעות מחקר משרות בלוח המשרות של לינקדאין אתם יכולים להבין יותר טוב את הטרנדים החמים, <br/>
                    איפה יש יותר משרות, מה דורשים בכל תפקיד, מה הסקילים הכי מבוקשים. <br/>
                    המידע הזה עוזר לנו להחליט מה טייטל המטרה שלפיו נבנה את קורות החיים. 
                </Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>5. Apply via Linkedin Jobs</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    בונסף לפרסום שלנו, <b>מומלץ לשלוח כמה שיותר קורות חיים.</b> מומלץ להתאים את טייטל המטרה בראש הקו"ח לטייטל של המשרה ששולחים. <br/>
                    <b>אין חשש מהתנגשויות</b> וכולי - כל המרבה הרי זה משובח. <br/>
                    ניתן להפעיל <b>התרעות</b> על מילות מפתח מסוימות ואז תקבלו התראה מלינקדאין למייל ותוכלו להכנס ולשלוח קו"ח למשרה. 
                </Typography>
                <Typography>
                    <a href="https://docs.google.com/document/d/1k7Nb15cjLiO4Ajp-d4N4hrtqDFY_pFm7KHLedKtmgDk" target="_blank" rel="noopener noreferrer">LinkedIn handbook</a>
                </Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>Github</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    גיטהאב היא רשת חברתית למתכנתים, בבעלות מיקרוסופט. <br/>
                    מגייסים יכולים להכנס לפרופיל האישי ולראות את הפעילות-ראו דוגמא-הריבועים הירוקים מראים מתי עלה קוד. <br/>
                    אגב לא חייבים להעלות כל פרויקט, אפשר להכניס לקו"ח פרויקטים גם ללא קישור לגיטהאב.<br/>
                    <a href="https://docs.google.com/document/d/1ZsGqzlMztCq5C7ZBRJEz3aRkWc0s-R3qiz7j5YUsick" target="_blank" rel="noopener noreferrer">GitHub handbook</a>
                </Typography>

                <Typography dir='auto' style={{fontWeight:'bold', marginTop:8}}>JobSwipe</Typography>
                <Typography dir='auto' style={{margin:'0 8px'}}>
                    <a href="https://jobswipe.co/" target="_blank" rel="noopener noreferrer">ג'ובסווייפ</a> הוא לוח משרות שמאפשר להגיע <b>ישירות למגייסים בתוך החברה.</b> שימו לב שיש לכם חשבון פרימיום שמאפשר לכם לחשוף את המייל והטלפון של המעסיק. <br/><br/>

                    גם כאן מומלץ <b>לחפש משרות עצמאית ולהגיש מועמדות. </b><br/>
                    ג'ובסוויפ מאפשר לך לגלות את המייל הישיר של המגייס ולשלוח אליו ישירות קורות חיים בצירוף המכתב המקדים, או להשתמש בפרטים כדי לעשות פולואו אפ אחרי יום מהשליחה."
                </Typography>
            </Paper>
        </div>
    )
}