import React, { Component } from 'react'
import { connect } from 'react-redux'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import JSChip from '../components/JSChip'

import { toggleFilterAndRefreshPage, clearFilteredAndRefreshPage, FILTER_COMPANY_TYPES } from '../redux/actions/filterJobs'

import '../styles/FilterJobs.css'

class FilterJobs extends Component {
    state = {
        value: 0
    };
    
    handleChange = (event, value) => {
        this.setState({value});
        this.props.handleExpanded(true);
    };

    toggleExpanded = () => {
        this.props.handleExpanded(!this.props.expanded);
    }

    handleToggleFilter = (filterType, filterValue) => {
        const { dispatch } = this.props
        dispatch(toggleFilterAndRefreshPage(filterType, filterValue))
    }

    handleClearFiltered = filterType => {
        const { dispatch } = this.props
        dispatch(clearFilteredAndRefreshPage(filterType))
    }

    handleClearAllFiltered = () => {
        const { dispatch } = this.props
        dispatch(clearFilteredAndRefreshPage())
    }

    render() {
        const { filterJobs, expanded, histogramType } = this.props;

        const filtersForJobs = ['tags', 'location', 'date posted', 'employment type', 'company', 'seniority Level'];
        const filtersForResumes = ['tags', 'location', 'updated', 'employment type', 'seniority Level'];
        const filtersForJobhuntUsers = ['manager','status', 'plan', 'seniority', 'title', /*'step', 'publish step',*/ 'need attention'/*, 'other'*/];

        let filters

        switch (histogramType){
            case 'jobs':{
                filters = filtersForJobs
                break;
            } case 'resumes':{
                filters = filtersForResumes
                break;
            } case 'jobhuntUsers':{
                filters = filtersForJobhuntUsers
                break;
            } default:{
                filters = []
            }
        }

        const filterArray = filterJobs[filters[this.state.value]];

        let count = 0;
        for (let i = 0; i < filters.length; i++){
            if (filterJobs[filters[i]]){
                count = count + filterJobs[filters[i]].filtered.length;
            }
        }

        return (
            <div className={(expanded)?"filterSection expanded" : "filterSection"}>
                <div className="filterTabs">
                    <div className="filterTabsLabel" onClick={this.toggleExpanded}>
                        {(count === 0) ?
                        <span>Filter by:</span> :
                        <Chip onDelete={this.handleClearAllFiltered} label={count} className="clearFilter"/>
                        }
                    </div>
                    <div className="filterTabsRow">
                        <Tabs 
                            value={this.state.value} 
                            onChange={this.handleChange} 
                            variant="scrollable"
                            scrollButtons="off"
                            classes={{ indicator: (filterArray && filterArray.filtered.length > 0) ? "filtered tabIndicator" : "tabIndicator" }}
                            >

                            {filters.map((filter, index) =>
                                <Tab key={filter} 
                                    label={(filter === 'updated'?'date':filter)} 
                                    value={index} 
                                    className={(filterJobs[filter] && filterJobs[filter].filtered.length > 0) ? "filtered filterTab" : "filterTab"}
                                    classes={{ selected: "selected"}}/>
                            )}
                        </Tabs>
                    </div>
                </div>

                {(filterArray && filterArray.posible.length > 0) &&
                <div className="filterTagsSection">
                    <JSChip
                        onClick={() => this.handleClearFiltered(filters[this.state.value])} 
                        selected={(filterArray.filtered.length === 0)}>ALL</JSChip>
                    {filterArray.posible.map(filterValue => 
                        <JSChip 
                            key={filterValue} 
                            onClick={() => this.handleToggleFilter(filters[this.state.value], filterValue)} 
                            selected={(filterArray.filtered.includes(filterValue))}
                            rect={FILTER_COMPANY_TYPES.includes(filterValue)}>
                            {(this.state.value === 1 && histogramType !== 'jobhuntUsers') ? filterValue + ' KM' : filterValue}
                        </JSChip>)}
                    </div>}
			</div>
        )
    }
}

function mapStateToProps(state) {
    const { filterJobs } = state;
    const { histogramType } = state.histogramData

    return {
        filterJobs, histogramType
    }
}

export default connect(mapStateToProps)(FilterJobs)