import { REQUEST_TEMPLATES, 
	RECEIVE_TEMPLATES, 
	CLEAR_TEMPLATES} from '../actions/templates'

const initialState = {
	isFetching: false,
	templates: [],
}

export function templates(state = initialState, action) {
    switch (action.type) {
		case CLEAR_TEMPLATES:
			return initialState
      	case REQUEST_TEMPLATES:
			return Object.assign({}, state, {
				isFetching: true,
				templates: []
			})
	  	case RECEIVE_TEMPLATES:
			return Object.assign({}, state, {
				isFetching: false,
				templates: action.templates
			})
      	default:
        	return state
    }
}