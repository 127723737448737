import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Grid, Paper, Switch, Typography } from '@material-ui/core';
import { dateAsString, getDateStringToShow } from '../../utils/utils';
import { PersonalJourneyClasses } from '../../styles/PersonalJourneyClasses';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdminJHApi from "../../utils/AdminJHApi"

function CycleData(props){
    const cycle = props.cycle || {}
    const withBorder = props.withBorder || false

    return (
        <Grid container spacing={1} style={withBorder?{borderTop: "1px solid rgba(0,0,0,.10)", padding: '8px 0'}:{}}>
            {(cycle.cycle)&&<Grid item xs={6}>
                {"cycle: "+cycle.cycle}
            </Grid>}
            <Grid item xs={cycle.cycle?6:12}>
                {cycle.status}
            </Grid>
            <Grid item xs={3}>
                {"Part1: "+getDateStringToShow(cycle.partOneDate)}
            </Grid>
            <Grid item xs={3}>
                {"Part2: "+getDateStringToShow(cycle.partTwoDate)}
            </Grid>
            <Grid item xs={3}>
                {"Part3: "+getDateStringToShow(cycle.partThreeDate)}
            </Grid>
            <Grid item xs={3}>
                {"Part4: "+getDateStringToShow(cycle.partFourDate)}
            </Grid>
        </Grid>
    )
}

export function PublishStep(props) {
    const explenations = props.explenations || {}
    const journeyData = props.journeyData || {}
    //const sawExplenations = journeyData.sawExplenations || {}
    const cycles = props.cycles || []
    const upcomingPublish = props.upcomingPublish || {}
    //const publishData = props.publishData
    const isLoading = props.isLoading || false
    const userId = props.userId

    const [dialogData, setDialogData] = useState({open: false});

    const handleNewPublish = () => {
        setDialogData({open: false})
        props.preServerCall()

        AdminJHApi.newPersonalJourneyPublish({userId},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }

    const approvePublish = () => {
        setDialogData({open: false})
        props.preServerCall()

        AdminJHApi.approvePublish({userId, newCycle:false},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }

    const approveAndNewCycle = () => {
        setDialogData({open: false})
        props.preServerCall()

        AdminJHApi.approvePublish({userId, newCycle:true},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }

    /*const declinePublish = (reason) => {
        setDialogData({open: false})
        props.preServerCall()

        AdminJHApi.declinePublish({userId, reason},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }*/

    const pausePublish = () => {
        setDialogData({open: false})
        props.preServerCall()

        AdminJHApi.pausePublish({userId},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }

    const changeAutoPutOnPublishList = event => {
        props.preServerCall()

        AdminJHApi.changeAutoPutOnPublishList({userId, autoPutOnPublishList:event.target.checked},(response)=>{
            props.postServerCall(null, response)
        }, (error) => {
            props.postServerCall(error)
        })
    }

    const handleCloseNewPublishDialog = () => {
        setDialogData({open: false})
    }

    const handleConfirmNewPublish = () => {
        setDialogData({
            open: true,
            title: "Back To Test Email Step",
            contentText: "Are you sure you want to switch to 'Test Email' step?",
            handleAgree: handleNewPublish
        })
    }

    const handleApprovePublish = () => {
        setDialogData({
            open: true,
            title: "Approve Publish",
            contentText: "Are you sure you want to approve publish?",
            handleAgree: approvePublish
        })
    }

    const handleNewCycle = () => {
        setDialogData({
            open: true,
            title: "Approve Publish",
            contentText: "Are you sure you want to approve publish and create new cycle?",
            handleAgree: approveAndNewCycle
        })
    }

    /*const handleDeclinePublish = () => {
        setDialogData({
            open: true,
            title: "Decline Publish",
            contentText: "",
            handleAgree: declinePublish,
            fields: [{name: "publishDeclineReason", label: "Reason",type:FIELD_TYPES.MULTILINE, rowsMax:4, sm:12, defaultValue: '', required:true}]
        })
    }*/

    const handlePausePublish = () => {
        setDialogData({
            open: true,
            title: "Pause Publish",
            contentText: "Are you sure you want to Pause Publish?",
            handleAgree: pausePublish
        })
    }

    const activeCycle = cycles.find(cycle => cycle.status !== 'CLOSED');  

    const classes = PersonalJourneyClasses();

    return (
        <>
            <ConfirmDialog
                title={dialogData.title} 
                contentText={dialogData.contentText} 
                open={dialogData.open} 
                handleClose={handleCloseNewPublishDialog}
                handleAgree={dialogData.handleAgree}
                fields={dialogData.fields}
            />

            {props.isAdmin && <>
                <Accordion style={{marginBottom:8}} defaultExpanded={false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h6'>Cycles: {cycles.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{width:'100%'}}>
                        {cycles.map(cycle => <CycleData key={cycle._id} cycle={cycle} withBorder/>)}
                        </div>
                    </AccordionDetails>
                </Accordion>
                {(activeCycle)&&<Paper className={classes.prePublishSection} elevation={1}>
                    <Typography variant='h6'>Open Cycle</Typography>
                    <CycleData cycle={activeCycle}/>
                    {(upcomingPublish.part)&&<Typography><br/><b>Upcoming:</b> part {upcomingPublish.part} today: {upcomingPublish.timeHours}:{upcomingPublish.timeMinutes||'00'}</Typography>}
                </Paper>}
                <Paper className={classes.prePublishSection} elevation={1}>
                    <FormControlLabel
                        onChange={changeAutoPutOnPublishList}
                        checked={journeyData.autoPutOnPublishList}
                        control={<Switch color="primary"/>}
                        label="Auto Put On Publish List"
                        labelPlacement="end"
                        name="autoPutOnPublishList"
                        disabled={isLoading}
                    /><br/>
                    {(journeyData.beginPublish)&&<Typography><br/><b>Begin Publish:</b> {dateAsString(new Date(journeyData.beginPublish))}</Typography>}<br/>
                    {(journeyData.isPublishApproved)?<>
                        <Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handlePausePublish}>Pause Publish</Button>
                    </>:<>
                        {(activeCycle)?
                            <Button style={{marginRight:16}} disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleApprovePublish}>Start Publish</Button>
                        :
                            <Button style={{marginRight:16}} disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleNewCycle}>Start Publish</Button>
                        }
                        
                        {/*<Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleDeclinePublish}>Decline</Button>*/}
                    </>}
                </Paper>
            </>}

            {(!props.isAdmin)&&<Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant="body1"><span dangerouslySetInnerHTML={{__html: explenations.Publish||''}} /></Typography>
            </Paper>}

            {(journeyData.isPublishUpdateNeeded || props.isAdmin)&&<Paper className={classes.prePublishSection} elevation={1}>
                {(journeyData.isPublishUpdateNeeded)&&<Button style={{marginRight:16}} disabled={isLoading} variant="contained" color="primary" size="small" onClick={props.updatePublish}>Update Publish</Button>}
                {(props.isAdmin)&&<Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleConfirmNewPublish}>back to test</Button>}
            </Paper>}
        </>
    )
}