import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { TextField } from '@material-ui/core';

export default function FieldWithEdit(props) {
    const value = props.value || ''
    const disabled = props.disabled || false
    const noEdit = props.noEdit || false

    const [iSEditValue, setISEditValue] = useState(false);
    const [newObject, setNewObject] = useState('');

    const handleStartEditJourneyLink = e => {
        setNewObject(value)
        setISEditValue(true)
    }

    const handleChange = e => {
        setNewObject(e.target.value)
    };

    const handleSave = () => {
        props.changeValue(newObject)
        setISEditValue(false)
        setNewObject('')
    }

    const handleCancel = () => {
        setISEditValue(false)
        setNewObject('')
    }

    return (
        <>
            {iSEditValue?<>
                {props.newLine&&<br/>}
                <TextField onChange={handleChange} value={newObject} name={props.fieldName} type={props.fieldInputType} fullWidth={props.fullWidth} multiline={props.multiline}/>
                <IconButton color='primary' onClick={handleSave} aria-label="save" disabled={(disabled || !newObject)}>
                    <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton disabled={disabled} color='primary' onClick={handleCancel} aria-label="cancel">
                    <CancelIcon fontSize="small" />
                </IconButton>
            </>:
            <>
                {props.children}
                {!noEdit&&<IconButton aria-label="edit" className="editButton" onClick={handleStartEditJourneyLink} style={props.noPaddingButton?{padding: '0 8px'}:{}}>
                    <EditIcon fontSize="small" />
                </IconButton>}
            </>}
        </>
    )
}