import React, { Component } from 'react'
import JobCardContent from './JobCardContent';
import ResumeCardContent from './ResumeCardContent';
import { ReportRevieCardContent } from './review/reportComponents';

export default class ReportCardContent extends Component {
    render() {
        const {object, selectedjobbgcolor, applyOnTop, view} = this.props

		return(
            <React.Fragment>
                {(object.type === 'job')&&<JobCardContent job={object.object} selectedjobbgcolor={selectedjobbgcolor} applyOnTop={applyOnTop} view={view}/>}
                {(object.type === 'profile')&&<ResumeCardContent resume={object.object} selectedjobbgcolor={selectedjobbgcolor} applyOnTop={applyOnTop} view={view}/>}
                {(object.type === 'review' || object.type === 'interview')&&
                    <ReportRevieCardContent type={object.type} object={object.object} selectedjobbgcolor={selectedjobbgcolor} applyOnTop={applyOnTop} view={view}/>}
            </React.Fragment>
        )
    }
}