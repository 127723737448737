import React, { Component } from 'react'
import '../styles/LandingPage.css'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { gaPageView, filterUrl, getTitleDescription } from '../utils/utils';

import { ReactComponent as IntelLogo } from '../assets/svgs/logos/intel.svg';
import { ReactComponent as FacebookLogo } from '../assets/svgs/logos/facebook.svg';
import { ReactComponent as TevaLogo } from '../assets/svgs/logos/teva.svg';
import { ReactComponent as MicrosoftLogo } from '../assets/svgs/logos/microsoft.svg';
import { ReactComponent as GoogleLogo } from '../assets/svgs/logos/google.svg';
import { ReactComponent as CheckPointLogo } from '../assets/svgs/logos/checkPoint.svg';
import { ReactComponent as WeWorkLogo } from '../assets/svgs/logos/weWork.svg';

import { ReactComponent as SearchIcon } from '../assets/svgs/search.svg';
import { ReactComponent as ConnectIcon } from '../assets/svgs/connect.svg';
import { ReactComponent as MatchIcon } from '../assets/svgs/match.svg';

import jobCat1 from '../assets/images/jobCat1.png';
import jobCat2 from '../assets/images/jobCat2.png';
import jobCat3 from '../assets/images/jobCat3.png';
import jobCat4 from '../assets/images/jobCat4.png';
import jobCat5 from '../assets/images/jobCat5.png';
import jobCat6 from '../assets/images/jobCat6.png';
import jobCat7 from '../assets/images/jobCat7.png';
import jobCat8 from '../assets/images/jobCat8.png';

import jobCat1W from '../assets/images/webp/jobCat1.webp';
import jobCat2W from '../assets/images/webp/jobCat2.webp';
import jobCat3W from '../assets/images/webp/jobCat3.webp';
import jobCat4W from '../assets/images/webp/jobCat4.webp';
import jobCat5W from '../assets/images/webp/jobCat5.webp';
import jobCat6W from '../assets/images/webp/jobCat6.webp';
import jobCat7W from '../assets/images/webp/jobCat7.webp';
import jobCat8W from '../assets/images/webp/jobCat8.webp';

const JOB_LINKS = [
    {label:'QA', img:jobCat1, webP:jobCat1W},
    {label:'Junior', img:jobCat2, webP:jobCat2W},
    {label:'Software', img:jobCat3, webP:jobCat3W},
    {label:'Product Manager', img:jobCat4, webP:jobCat4W},
    {label:'Administration', img:jobCat5, webP:jobCat5W},
    {label:'Human Resources', img:jobCat6, webP:jobCat6W},
    {label:'Marketing / Sales', img:jobCat7, webP:jobCat7W},
    {label:'Remote', img:jobCat8, webP:jobCat8W},
]

export default class LandingPage extends Component {
    componentDidMount() {
        const lookingforwork = (this.props.lookingforwork === undefined) ? true : this.props.lookingforwork
        const titleDescription = getTitleDescription(lookingforwork)

        const pathname = (window.location.pathname) ? (window.location.pathname) : '/'
        if (window.location.search){
            gaPageView(pathname, titleDescription.title, window.location.search);
        } else {
            gaPageView(pathname, titleDescription.title);
        }
    }
    
    showJobs = (label) => {
        const lookingforwork = (this.props.lookingforwork === undefined) ? true : this.props.lookingforwork

        const link = (lookingforwork) ? 'jobs' : 'resumes'

        let route = label + '-' + link + '-SRCH_L_Q0,' + label.length
        route = filterUrl(route);

        this.props.changeRoute('/' + link + '/' + route)
    }

    canUseWebP = () => {
        var elem = document.createElement('canvas');
    
        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
    
        // very old browser like IE 8, canvas not supported
        return false;
    }

    render() {
        const lookingforwork = (this.props.lookingforwork === undefined) ? true : this.props.lookingforwork
        let mainStyle = {}
        if(window.innerWidth <= 768){
            mainStyle = {height: window.innerWidth/1.47}
        }
        
        return (
            <div className="landingPageContainer" style={mainStyle}>
                <div className={"landingPageImage" + (this.canUseWebP()?"":" no-webp")}>
                    <Typography variant="h4" className="title">Your Dream {(lookingforwork)?'Job':'Talent'}<br/> Is Just One<br/> Click Away.</Typography>
                    <Typography className="footerCaption" variant="caption">
                        <span>Photo by </span>
                        <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@brucemars?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">bruce mars</a>
                        <span> on </span>
                        <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                    </Typography>
                </div>
                <div className="logos">
                    <IntelLogo className="logo"/>
                    <FacebookLogo className="logo"/>
                    <TevaLogo className="logo" style={{height:30}}/>
                    <MicrosoftLogo className="logo"/>
                    <GoogleLogo className="logo"/>
                    <CheckPointLogo className="logo"/>
                    <WeWorkLogo className="logo" style={{marginBottom:22}}/>
                </div>
                <div className="freeSpace"></div>
                <div className="landingPageSection howWorks">
                    <Typography className="lpsTitle" color="primary" variant="h4">How JobSwipe Works</Typography>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <SearchIcon className="icon"/>
                            <Typography className="subtitle" variant="h5" color="primary">Search</Typography>
                            <Typography color="primary">From thousands of {(lookingforwork)?'jobs':'talents'}</Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <MatchIcon className="icon"/>
                            <Typography className="subtitle" variant="h5" color="primary">Match</Typography>
                            <Typography color="primary">With {(lookingforwork)?'jobs':'talents'} offers</Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <ConnectIcon className="icon"/>
                            <Typography className="subtitle" variant="h5" color="primary">Connect</Typography>
                            <Typography color="primary">And interview</Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className="landingPageSection exploreJobs">
                    <Typography className="lpsTitle" color="primary" variant="h4">Explore {(lookingforwork)?'Jobs':'Talents'}</Typography>
                    <Grid container spacing={3}>
                        {JOB_LINKS.map((jobLink) =>
                            <Grid item sm={3} xs={6} key={jobLink.label}>
                                <div className="jobLink" onClick={() => this.showJobs(jobLink.label)}>
                                    <picture>
                                        <source type="image/webp" srcSet={jobLink.webP} />
                                        <img src={jobLink.img} alt={jobLink.label} />
                                    </picture>                                
                                    <Typography className="jobLinkLabel" color="primary">{jobLink.label}</Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <div className="footer">
                    <div className="termsArea">
                        <a target="_blank" rel="noopener noreferrer" href="/terms">Terms of Use</a>
                        <a target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>
                    </div>
                </div>
            </div>
        )
    }
}