import React, { Component } from 'react'
import HistApi from "../../utils/HistApi";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Typography, ButtonGroup, 
    FormControl, InputLabel, Select, MenuItem, Divider, FormLabel, FormGroup, FormControlLabel, Checkbox, Radio } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import EmploymentTypes from '../profile/EmploymentTypes'
import { getLastYears } from '../../utils/utils';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const jobFunctions = [
    'Administrative',
    'Arts & Design',
    'Business',
    'Consulting',
    'Customer Services & Support',
    'Education',
    'Engineering',
    'Finance & Accounting',
    'Healthcare',
    'Human Resources',
    'Information Technology',
    'Legal',
    'Marketing',
    'Media & Communications',
    'Military & Protective Services',
    'Operations',
    'Other',
    'Product & Project Management',
    'Research & Science',
    'Retail & Food Services',
    'Sales',
    'Skilled Labor & Manufacturing',
    'Transportation'
]

const lengthOfEmployments = [
    '< 1 year',
    '1-2 years',
    '3-4 years',
    '5-7 years',
    '8-10 years',
    '10+ years'
]

const difficulties = [
    'Very Easy',
    'Easy',
    'Average',
    'Difficult',
    'Very Difficult'
]

const howGotInterviewOptions = [
    'Applied Online',
    'College or University',
    'Employee Referral',
    'In-Person',
    'Recruiter',
    'Staffing Agency',
    'Other'
]

const howHelpfulOptions = [
    'Very Helpful',
    'Helpful',
    'Not Helpful',
    "I didn't use JobSwipe to prepare for this interview"
]

const processPeriodLabels = ['Days', 'Weeks', 'Months']

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const initialState = {
    data:{},
    interviewQuestions: [{question:'', answer:''}],
    extraReview:false,
    miisingFields:false
}

export default class AddReview extends Component {
    state = initialState

    handleChangeQuestion = (index, isQuestion, value) => {
        let {interviewQuestions} = this.state
        if (interviewQuestions[index]){
            if(isQuestion){
                interviewQuestions[index].question = value
            } else {
                interviewQuestions[index].answer = value
            }
        }
        this.setState(interviewQuestions)
    }

    addQuestion = () => {
        let {interviewQuestions} = this.state
        interviewQuestions.push({question:'', answer:''})
        this.setState(interviewQuestions)
    }

    handleChange = event => {
        this.handleChangeDataValue(event.target.name, event.target.value)
    };

    handleChangeCheckBox = event => {
        this.handleChangeDataValue(event.target.name, event.target.checked)
    };

    handleChangeDataValue = (name, value) => {
        let newState = {
            data : {...this.state.data,
                [name]:value 
            }
        }

        if (['headline', 'pros', 'cons', 'adviceToManagement'].includes(name)){
            newState.extraReview = true
        }

        this.setState(newState);
    };

    handleSave = e => {
        e.preventDefault()
        
        let {data, interviewQuestions} = this.state
        if(this.props.company){
            data.company = this.props.company
        }

        if (this.isValidData(data)){
            HistApi.addReview({...data, interviewQuestions}, (results)=>{
                this.setState(initialState)
                this.props.onClose('Saved', 'Thank you for writing a review', results)
            }, (error) => {
                this.props.onClose('Something went wrong')
            })
        } else {
            this.setState({miisingFields:true})
        }
    };

    isValidData = (data) => {
        if (!data || !data.type){
            return false
        }

        switch (data.type){
            case 'interview':{
                return (
                    data.rating && 
                    data.title && 
                    data.interviewProcess && 
                    data.difficulty && 
                    data.gotOffer)
                 
            } case 'former': {
                return (
                    data.rating && 
                    data.lastYearEmployment && 
                    data.employmentType &&
                    data.title &&
                    data.headline && 
                    data.pros && 
                    data.cons)
            } case 'current': {
                return (
                    data.rating &&
                    data.employmentType &&
                    data.title &&
                    data.headline &&
                    data.pros &&
                    data.cons)
            } default: {
                return false 
            }
        }
    }

    getRequiredStageCheckBox = (name, label) => {
        const {data} = this.state
        return (
            <FormControlLabel
                control={<Checkbox color="primary" checked={data[name] || false} onChange={this.handleChangeCheckBox} id={name} name={name} />}
                label={label}
            />
        )
    }

    getCustomRadio = (name, value, icon) => {
        const {data} = this.state

        return (
            <Radio
                checked={data[name] === value}
                onChange={this.handleChange}
                value={value}
                name={name}
                inputProps={{ 'aria-label': value }}
                checkedIcon={icon}
                icon={icon}
                color="primary"
            />
        )
    }

    onClose = () => {
        this.setState(initialState)
        this.props.onClose()
    }

    render() {
        const {data, interviewQuestions, extraReview, miisingFields} = this.state
        const {company} = this.props
        const ratingSize = 'medium'
        
        return (
            <Dialog
                open={this.props.open}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="paper"
            >
                <DialogTitle id="alert-dialog-title">Add Review</DialogTitle>
                <form onSubmit={this.handleSave}>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField id="company" name="company" label="Company" margin="none" fullWidth 
                                    required value={(company)?company:(data.company || '')} onChange={this.handleChange} disabled={company !== undefined}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend" color={(miisingFields && (!data.rating || data.rating === 0))?"error":"textPrimary"}>Overall Rating *</Typography>
                                <Rating
                                    name="rating" size={ratingSize} value={data.rating || 0}
                                    onChange={(event, newValue) => {
                                        this.handleChangeDataValue('rating', newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="legend" color={(miisingFields && (!data.type))?"error":"textPrimary"}>Are you a current or former employee? *</Typography>
                                <ButtonGroup color="primary" aria-label="review type" size="small">
                                    <Button variant={(data.type === 'current')?"contained":"outlined"}
                                        onClick={()=>this.handleChangeDataValue('type', 'current')}>Current</Button>
                                    <Button variant={(data.type === 'former')?"contained":"outlined"}
                                        onClick={()=>this.handleChangeDataValue('type', 'former')}>Former</Button>
                                    <Button variant={(data.type === 'interview')?"contained":"outlined"}
                                        onClick={()=>this.handleChangeDataValue('type', 'interview')}>Had an Interview</Button>
                                </ButtonGroup>
                            </Grid>
                            {(data.type === 'current' || data.type === 'former')&&<React.Fragment>
                                {(data.type === 'former')&&<Grid item xs={12}>
                                    <FormControl required fullWidth>
                                        <InputLabel htmlFor="lastYearEmployment">Last Year at Employer</InputLabel>
                                        <Select
                                            value={data.lastYearEmployment || ''}
                                            onChange={this.handleChange}
                                            name="lastYearEmployment"
                                            inputProps={{
                                                id: 'lastYearEmployment',
                                            }}
                                        >
                                            {getLastYears(5).map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>}
                                <Grid item xs={12}>
                                    <FormControl required fullWidth>
                                        <InputLabel htmlFor="employmentType">Employment Status</InputLabel>
                                        <Select
                                            value={data.employmentType || ''}
                                            onChange={this.handleChange}
                                            name="employmentType"
                                            inputProps={{
                                                id: 'employmentType',
                                            }}
                                        >
                                            {EmploymentTypes.map(option => (
                                                <MenuItem key={option.label} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="title" name="title" label="Your Job Title at Google" margin="none" fullWidth 
                                        required value={data.title || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="headline" name="headline" label="Review Headline" margin="none" fullWidth 
                                        required value={data.headline || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="pros" name="pros" label="Pros" margin="none" fullWidth 
                                        required multiline rowsMax="20" value={data.pros || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="cons" name="cons" label="Cons" margin="none" fullWidth 
                                        required multiline rowsMax="20" value={data.cons || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="adviceToManagement" name="adviceToManagement" label="Advice to Management" margin="none" fullWidth 
                                        multiline rowsMax="20" value={data.adviceToManagement || ''} onChange={this.handleChange}/>
                                </Grid>
                                {(extraReview)&&<React.Fragment>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Ratings (Optional)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Career Opportunities</Typography>
                                        <Rating
                                            name="careerOpportunities" size={ratingSize} value={data.careerOpportunities || 0}
                                            onChange={(event, newValue) => {
                                                this.handleChangeDataValue('careerOpportunities', newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Compensations & Benefits</Typography>
                                        <Rating
                                            name="compensationsBenefits" size={ratingSize} value={data.compensationsBenefits || 0}
                                            onChange={(event, newValue) => {
                                                this.handleChangeDataValue('compensationsBenefits', newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Work/Life Balance</Typography>
                                        <Rating
                                            name="workLifeBalance" size={ratingSize} value={data.workLifeBalance || 0}
                                            onChange={(event, newValue) => {
                                                this.handleChangeDataValue('workLifeBalance', newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Senior Management</Typography>
                                        <Rating
                                            name="seniorManagement" size={ratingSize} value={data.seniorManagement || 0}
                                            onChange={(event, newValue) => {
                                                this.handleChangeDataValue('seniorManagement', newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Culture & Values</Typography>
                                        <Rating
                                            name="cultureValues" size={ratingSize} value={data.cultureValues || 0}
                                            onChange={(event, newValue) => {
                                                this.handleChangeDataValue('cultureValues', newValue);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Rate CEO Job Performance</Typography>
                                        {this.getCustomRadio('ceoJobPerformance', 'Approve', <ThumbUpIcon/>)}
                                        {this.getCustomRadio('ceoJobPerformance', 'Neutral', <RemoveIcon/>)}
                                        {this.getCustomRadio('ceoJobPerformance', 'Disapprove', <ThumbDownIcon/>)}
                                        <br/><Typography variant="subtitle1" color="textSecondary">{data.ceoJobPerformance || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">Recommend to a friend?</Typography>
                                        {this.getCustomRadio('recommendToFriend', 'Positive', <ThumbUpIcon/>)}
                                        {this.getCustomRadio('recommendToFriend', 'Negative', <ThumbDownIcon/>)}
                                        <br/><Typography variant="subtitle1" color="textSecondary">{data.recommendToFriend || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend">6 Month Business Outlook</Typography>
                                        {this.getCustomRadio('sixMonthBusinessOutlook', 'Positive', <ThumbUpIcon/>)}
                                        {this.getCustomRadio('sixMonthBusinessOutlook', 'Neutral', <RemoveIcon/>)}
                                        {this.getCustomRadio('sixMonthBusinessOutlook', 'Negative', <ThumbDownIcon/>)}
                                        <br/><Typography variant="subtitle1" color="textSecondary">{data.sixMonthBusinessOutlook || ''}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>About You</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="jobFunction">Job Function</InputLabel>
                                            <Select
                                                value={data.jobFunction || ''}
                                                onChange={this.handleChange}
                                                name="jobFunction"
                                                inputProps={{
                                                    id: 'jobFunction',
                                                }}
                                            >
                                                {jobFunctions.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="lengthOfEmployment">Length of Employment</InputLabel>
                                            <Select
                                                value={data.lengthOfEmployment || ''}
                                                onChange={this.handleChange}
                                                name="lengthOfEmployment"
                                                inputProps={{
                                                    id: 'lengthOfEmployment',
                                                }}
                                            >
                                                {lengthOfEmployments.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="location" name="location" label="Location" margin="none" fullWidth 
                                            value={data.location || ''} onChange={this.handleChange}/>
                                    </Grid>
                                </React.Fragment>}
                            </React.Fragment>}
                            {(data.type === 'interview')&&<React.Fragment>
                                <Grid item xs={12}>
                                    <TextField id="title" name="title" placeholder="What role did you apply for?" label="Job Title" margin="none" fullWidth 
                                        required value={data.title || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="interviewProcess" name="interviewProcess" placeholder="Briefly describe the hiring and interview process." 
                                        label="Describe the Interview Process" margin="none" fullWidth required multiline rowsMax="20" 
                                        helperText="30 word minimum" value={data.interviewProcess || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Interview Questions*</Typography>
                                </Grid>
                                {interviewQuestions.map((question, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField id={"question"+index} name={"question"+index} label={"Question " + (index + 1)} 
                                                placeholder="Q: What was one thing that they asked you?" 
                                                margin="none" fullWidth required multiline rowsMax="20" 
                                                helperText="5 word minimum" value={question.question || ''} 
                                                onChange={(event)=>this.handleChangeQuestion(index, true, event.target.value)}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField id={"answer"+index} name={"answer"+index}  label={"Answer " + (index + 1)} 
                                                placeholder="How did you answer this question? (Optional)" 
                                                margin="none" fullWidth multiline rowsMax="20" 
                                                value={question.answer || ''} 
                                                onChange={(event)=>this.handleChangeQuestion(index, false,  event.target.value)}/>
                                        </Grid>  
                                    </React.Fragment>
                                ))}
                                <Grid item xs={12} style={{textAlign:"right"}}>
                                    <Button onClick={this.addQuestion} variant="outlined" color="primary" size="small" aria-label="Add a question">
                                        Add a question
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="difficulty">Interview Difficulty *</InputLabel>
                                        <Select
                                            required
                                            value={data.difficulty || ''}
                                            onChange={this.handleChange}
                                            name="difficulty"
                                            inputProps={{
                                                id: 'difficulty',
                                            }}
                                        >
                                            {difficulties.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component="legend" color={(miisingFields && (!data.gotOffer))?"error":"textPrimary"}>Did you get an offer?*</Typography>
                                    {this.getCustomRadio('gotOffer', 'No', <ClearIcon/>)}
                                    {this.getCustomRadio('gotOffer', 'Yes, but I declined', <RemoveIcon/>)}
                                    {this.getCustomRadio('gotOffer', 'Yes, and I accepted', <CheckIcon/>)}
                                    <br/><Typography variant="subtitle1" color="textSecondary">{data.gotOffer || ''}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Tell us more</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="howGotInterview">How did you get the interview?</InputLabel>
                                        <Select
                                            value={data.howGotInterview || ''}
                                            onChange={this.handleChange}
                                            name="howGotInterview"
                                            inputProps={{
                                                id: 'howGotInterview',
                                            }}
                                        >
                                            {howGotInterviewOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:0}}>
                                    <Typography component="legend">How long was the process?</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        value={data.processPeriod || ''}
                                        id="processPeriod"
                                        name="processPeriod"
                                        type="number"
                                        margin="none" fullWidth
                                        inputProps={{min:0}}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={data.processPeriodLabel || ''}
                                            onChange={this.handleChange}
                                            name="processPeriodLabel"
                                            inputProps={{
                                                id: 'processPeriodLabel',
                                            }}
                                        >
                                            {processPeriodLabels.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{paddingBottom:0}}>
                                    <Typography component="legend">When was this interview?</Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="interviewMonth">Month</InputLabel>
                                        <Select
                                            value={data.interviewMonth || ''}
                                            onChange={this.handleChange}
                                            name="interviewMonth"
                                            inputProps={{
                                                id: 'interviewMonth',
                                            }}
                                        >
                                            {months.map((option, index) => (
                                                <MenuItem key={option} value={index + 1}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="interviewYear">Year</InputLabel>
                                        <Select
                                            value={data.interviewYear || ''}
                                            onChange={this.handleChange}
                                            name="interviewYear"
                                            inputProps={{
                                                id: 'interviewYear',
                                            }}
                                        >
                                            {getLastYears(5).map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="location" name="location" label="Where was this interview?" margin="none" fullWidth 
                                        value={data.location || ''} onChange={this.handleChange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>What stages were required?</Typography>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Interview Type</FormLabel>
                                        <FormGroup>
                                            {this.getRequiredStageCheckBox("phoneScreen", "Phone Screen")}
                                            {this.getRequiredStageCheckBox("oneOnOne", "1:1")}
                                            {this.getRequiredStageCheckBox("groupPanel", "Group/Panel")}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Testing</FormLabel>
                                        <FormGroup>
                                            {this.getRequiredStageCheckBox("skillsReview", "Skills Review")}
                                            {this.getRequiredStageCheckBox("personalityQuiz", "Personality Quiz")}
                                            {this.getRequiredStageCheckBox("drugTest", "Drug Test")}
                                            {this.getRequiredStageCheckBox("iqTest", "IQ Test")}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Other</FormLabel>
                                        <FormGroup>
                                            {this.getRequiredStageCheckBox("backgroundCheck", "Background Check")}
                                            {this.getRequiredStageCheckBox("presentation", "Presentation")}
                                            {this.getRequiredStageCheckBox("other", "Other")}
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="howHelpfulJobSwipe">How helpful was JobSwipe in preparing for this interview?</InputLabel>
                                        <Select
                                            value={data.howHelpfulJobSwipe || ''}
                                            onChange={this.handleChange}
                                            name="howHelpfulJobSwipe"
                                            inputProps={{
                                                id: 'howHelpfulJobSwipe',
                                            }}
                                        >
                                            {howHelpfulOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </React.Fragment>}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Submit"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}