import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { showPayment } from '../redux/actions/MainDialog';

import '../styles/Pro.css'

class Pro extends Component {
    jobswipePro = () => {
        const { dispatch, lookingforwork, parent } = this.props
        if (lookingforwork){
            dispatch(showPayment(null, (lookingforwork)?'jobseeker':'recruiter', parent+'ProButton'))
        }
    }
    
    render() {
        const {isPro, contactCredit, promoteCredit, lookingforwork} = this.props
        const showContactCredit = (lookingforwork || (contactCredit && contactCredit>0))
        const showPromoteCredit = (lookingforwork || (promoteCredit && promoteCredit>0))

        return (
            <React.Fragment>
                {(!isPro && lookingforwork)&&<Paper className="jobswipePro" elevation={1} onClick={this.jobswipePro}>
                    <Typography className="JobSwipeLogo" variant="h5" color="inherit">JobSwipe</Typography>
                    <Typography className="proLogo" variant="h6">Pro</Typography>
                    <Typography className="mtext">Unlock Our Most Exclusive Features</Typography>
                </Paper>}

                {(showContactCredit || showPromoteCredit)&&<Grid container spacing={1} className="crefitContainer">
                    {(showContactCredit)&&<Grid item xs={6}>
                        <Paper className="jobswipeProCredits" elevation={1}>
                            <Typography className="jobswipeProCreditsTitle">Get details now</Typography>
                            <Typography>{contactCredit || 0} Remaining</Typography>
                        </Paper>
                    </Grid>}
                    {(showPromoteCredit)&&<Grid item xs={6}>
                        <Paper className="jobswipeProCredits" elevation={1}>
                            <Typography className="jobswipeProCreditsTitle">Skip The Line</Typography>
                            <Typography>{promoteCredit || 0} Remaining</Typography>
                        </Paper>
                    </Grid>}
                </Grid>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {userInfo, contacts, promotes} = state.authUser;
    const {isPro, contactCredit, promoteCredit, lookingforwork} = userInfo

    return {
        isPro, 
        lookingforwork,
        contactCredit:(contactCredit||0) - (contacts||[]).length, 
        promoteCredit:(promoteCredit||0) - (promotes||[]).length
    }
}

export default connect(mapStateToProps)(Pro)