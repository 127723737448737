import React, { useState, useEffect, Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import JSPaper from '../../JSPaper';
import { capitalize, dateAsString, displayCash, FIELD_TYPES, SENIORITIES, showDateTimeFromString } from '../../../utils/utils';
import { Fields } from '../../Fields';
import JHApi from "../../../utils/JHApi"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ContractOfferDialog from './ContractOfferDialog';
import { withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';

/*function JobBenefits(props) {
    const benefits = props.benefits || [{key: 'transportation', value: 'fgdfg'}]
    const isEdit = props.isEdit || true

    const benefitsOptions = [
        'Food',
        'Transportation',
        'Bonuses',
        'Hybrid days',
        'Days Off',
        'Extra Perks',
        'Options & Stock Package',
        'Other'
    ]

    const [isShowDialog, setIsShowDialog] = useState(false);

    const handleEditBenefit = benefit => {
        console.log(benefit)
        setIsShowDialog(true)
    }

    const handleAddBenefit = () => {
        console.log('add')
        setIsShowDialog(true)
    }

    const handleCancel = e => {
        setIsShowDialog(false)
    }

    const handleBenefitClicked = benefitsOption => () => {
        console.log(benefitsOption)
    }

    return (
        <>
            <Dialog onClose={handleCancel} open={isShowDialog}>
                <DialogTitle>Benefit</DialogTitle>
                <List>
                    {benefitsOptions.map(benefitsOption =>
                    <ListItem key={benefitsOption} button onClick={handleBenefitClicked(benefitsOption)}>
                        {benefitsOption}
                    </ListItem>)}
                </List>
            </Dialog>

            {benefits.map(benefit => 
                <Chip key={benefit.key} className="profileTag" label={benefit.key+': '+benefit.value} variant="outlined" 
                    deleteIcon={<EditIcon/>}
                    onDelete={(isEdit?()=>handleEditBenefit(benefit):null)} 
                    
                />
            )}

            <Chip className="profileTag" label="+ benefit" color='primary'
                onClick={handleAddBenefit} 
            />
        </>
    )
}*/

const StyledTableCell = withStyles((theme) => ({
    /*head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },*/
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

export default function JobsFound(props) {
    const initialFoundJobData = {salaryCurrency:'ILS', salaryFrequency:'MONTH', type:'After Service'}
    const today = new Date().toISOString().substring(0, 10)

    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journetId = journeyData._id
    const freezeHistory = props.freezeHistory || []
    const signedDate = props.signedDate

    const [isLoadingDataLoading, setisLoadingDataLoading] = useState(false);
    const [error, setError] = useState();
    const [talentJobs, setTalentJobs] = useState([]);
    const [talentOffers, setTalentOffers] = useState([]);
    const [objectToEdit, setObjectToEdit] = useState();
    const [editId, setEditId] = useState();
    const [newJobData, setNewJobData] = useState(initialFoundJobData);
    const [signContractData, setSignContractData] = useState({open:false});

    const [showCopied, setShowCopied] = useState(false);

    const disabled = isLoading || isLoadingDataLoading

    const jobType = [
        {
            value: 'Before Service',
            label: 'Before Service',
        }, {
            value: 'After Service',
            label: 'After Service',
        }, {
            value: 'Safety Net',
            label: 'Safety Net',
        }
    ]

    useEffect(() => {
        if (journetId){
            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setisLoadingDataLoading(true)

            JHApi.getTalentJobsAndOffers(paramsToPass,(response)=>{
                setTalentJobs(response.talentJobs||[])
                setTalentOffers(response.talentOffers||[])

                setisLoadingDataLoading(false)
            }, (error) => {
                setisLoadingDataLoading(false)
                //setIsError(true)
            })
        }
    }, [journetId, isAdmin, userId]);

    const handleAddOffer = () => {
        setObjectToEdit({})
    }

    const handleEditOffer = (object) => () => {
        setObjectToEdit(object)
    }
    
    const handleAddJob = () => {
        setEditId(0)
        setError()
        setNewJobData(initialFoundJobData)
    }

    const handleCnacelFoundJob = () => {
        setEditId()
        setError()
        setNewJobData(initialFoundJobData)
    }

    const handleAddTalentJob = e => {
        e.preventDefault();

        let paramsToPass = {newJobData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.addTalentJob(paramsToPass,(response)=>{
            setTalentJobs(response.talentJobs)
            setEditId()
            setError()
            setNewJobData(initialFoundJobData)

            props.postServerCall(null, response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleEditDeleteTalentJob = e => {
        e.preventDefault();

        let paramsToPass = {editId, isDelete:newJobData.deleteObject===true, newJobData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.editDeleteTalentJob(paramsToPass,(response)=>{
            setTalentJobs(response)
            setEditId()
            setError()
            setNewJobData(initialFoundJobData)

            props.postServerCall()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const getValueFoundJob = (name, defaultValue) => {
        if (newJobData[name] !== undefined){
            return newJobData[name]
        } else {
            return defaultValue
        }
    }

    const getDateValue = (name) => {
        if (newJobData[name]){
            return newJobData[name].toString()
        } else {
            return ''
        }
    }

    const handleChangeField = (name, date) => {
        setNewJobData({...newJobData,[name]: date})
    };

    const handleChangeFieldFoundJob = e => {
        setNewJobData({...newJobData,[e.target.name]: e.target.value})
    };

    const handleEdit = job => () => {
        setEditId(job._id)
        setError()
        setNewJobData(job)
    }

    const handleDelete = id => () => {
        setEditId(id)
        setError()
        setNewJobData({deleteObject:true})
    }

    const handleCopy = job => () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setisLoadingDataLoading(true)

        JHApi.getQuestionnaire(paramsToPass,(response)=>{
            const questionnaire = response.questionnaire||{}

            /*console.log(journeyData)
            console.log(job)
            console.log(questionnaire)*/

            const beginServiceDate = journeyData.beginServiceDate || journeyData.dateCreated
            const oneDay = 1000 * 60 * 60 * 24;

            let daysInFreeze = 0
            freezeHistory.forEach(element => {
                if (element.start>=beginServiceDate){
                    daysInFreeze += Math.round((new Date(element.end||signedDate||job.dateCreated) - new Date(element.start))/oneDay)
                }
            });

            const daysInService = (beginServiceDate?Math.round((new Date(signedDate||job.dateCreated) - new Date(beginServiceDate))/oneDay):0) - daysInFreeze

            let text = `${journeyData.first_name} ${journeyData.last_name} signed a contract 🥳🥳🥳
City: ${questionnaire.city||''}
Years: ${questionnaire.age||''}
Seniority level: ${job.seniority||''}
Before the service: ${capitalize(questionnaire.title)||'Unknown title'}, ${capitalize(questionnaire.company)||'Unknown company'}, ${capitalize(questionnaire.currentSalary)||'Unknown salary'}
After the service: ${capitalize(job.title)||'Unknown title'}, ${capitalize(job.company)||'Unknown company'}, ${displayCash({value:job.salary, currency:job.salaryCurrency, frequency:job.salaryFrequency})||'Unknown salary'}
Number of publications: ${props.numberOfCycles}
Plan: ${capitalize(journeyData.plan)}
Time spent in service: ${daysInService} days${daysInFreeze>0?(' + '+daysInFreeze+' days in freeze'):''}
Safety net: ${showDateTimeFromString(job.dateStart, false)} - ${showDateTimeFromString(journeyData.safetyNetExpireDate, false)}`

//The total amount of approaches: up to 5

            navigator.clipboard.writeText(text)

            console.log(text)
            setShowCopied(true)

            setisLoadingDataLoading(false)
        }, (error) => {
            setisLoadingDataLoading(false)
        })
    }

    const handleChangeTitle = (titleGroup, titleCategories) => {
        if (getValueFoundJob('title', '')){
            setNewJobData({...newJobData, titleGroup, titleCategories})
        } else {
            setNewJobData({...newJobData, title:titleGroup, titleGroup, titleCategories})
        }
    }

    const fieldsTalentJob = [
        {name:'type', label:'Type', defaultValue: '', type:FIELD_TYPES.SELECT, options:jobType, required:true},
        {name:'company', label:'Company', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'titleGroup', label:'Professional Title', type:FIELD_TYPES.TITLE, handleChangeTitle, required: true},
        {name:'title', label:'Professional Title (Free Text)', defaultValue: '', hide: (!getValueFoundJob('titleGroup', '') && !getValueFoundJob('title', '')) , type:FIELD_TYPES.TEXT, required: true},
        {name:'seniority', label:'Seniority', defaultValue: '', type:FIELD_TYPES.SELECT, options:SENIORITIES},
        {name:'salary', label:'Salary', curField:'salaryCurrency', freqField:'salaryFrequency', defaultValue: 0, type:FIELD_TYPES.CASH},
        {name:'dateStart', label:'Start', defaultValue: '', type:FIELD_TYPES.DATE2},
    ]

    const fieldsTalentJob2 = [
        {name:'description', label:'Description', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},
    ]

    const startEditData = () => {
        setisLoadingDataLoading(true)
        props.preServerCall()
    }

    const finishEditData = response => {
        if (response.talentOffers){
            setTalentOffers(response.talentOffers)
        }

        setisLoadingDataLoading(false)
        props.postServerCall()
    }

    const handleSignOffer = object => () => {
        setSignContractData({
            open: true,
            offer: object,
            company: object.company,
            startDate: today,
        })
    }

    const handleCancelSign = () => {
        setSignContractData({open: false})
    }

    const handleSaveSign = e => {
        e.preventDefault();

        let paramsToPass = {offer:signContractData.offer, startDate:signContractData.startDate}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.talentSignedOffer(paramsToPass,(response)=>{
            setTalentJobs(response.talentJobs)
            setSignContractData({open: false})

            props.postServerCall(null, response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleChangeSign = e => {
        setSignContractData({...signContractData,startDate: e.target.value})
    };

    const propsToChildren = {
        isAdmin,
        userId,
        disabled,
        startEditData,
        finishEditData,
        setObjectToEdit
    }

    const offersFields = [
        {name:'company', label:'Company'},
        {name:'title', label:'Title'},
        {name:'seniority', label:'Seniority'},

        {name:'firstSalaryOffer', label:'First Salary Offer', curField:'firstSalaryCur', freqField:'firstSalaryFreq'},
        {name:'finalSalary', label:'Final Salary', curField:'finalSalaryCur', freqField:'finalSalaryFreq'},
        {name:'food', label:'Food'},
        {name:'transportation', label:'Transportation'},
        {name:'bonuses', label:'Bonuses'},
        {name:'hybridDays', label:'Hybrid Days'},
        {name:'daysOff', label:'Days Off'},
        {name:'companyLocation', label:'Company Location'},
        {name:'extraPerks', label:'Extra Perks'},
        {name:'optionsAndStockPackage', label:'Options & Stock Package'},
        {name:'cLevelTips', label:'C-Level Tips'},
        {name:'clearJobDefenition', label:'Clear Job Defenition'},
    ]

    return (
        <div>
            <Snackbar open={showCopied} autoHideDuration={2000} onClose={()=>{setShowCopied(false)}}>
                <Alert onClose={()=>{setShowCopied(false)}} severity="success">
                    Copied
                </Alert>
            </Snackbar>

            <Dialog onClose={handleCancelSign} open={signContractData.open}>
                <DialogTitle>Signed in {signContractData.company}</DialogTitle>
                <form onSubmit={handleSaveSign}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <TextField
                            type="date" name="dateStart" label="Start" margin="none" fullWidth 
                            value={signContractData.startDate||''} onChange={handleChangeSign}
                            required InputLabelProps={{shrink: true}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disabled} onClick={handleCancelSign} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <ContractOfferDialog {...propsToChildren} objectToEdit={objectToEdit}/>
            <JSPaper style={{textAlign:'left', padding:16}}>
                <Typography variant='h6'>Offers: {talentOffers.length}</Typography>
                {(talentOffers.length>0)?<TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <Button disabled={disabled} color="primary" size="small" onClick={handleAddOffer}>+ Offer</Button>
                                </StyledTableCell>
                                {talentOffers.map((talentOffer) => 
                                    <StyledTableCell key={talentOffer._id} align="left" style={{borderLeft: '1px solid #e0e0e0'}}>
                                        <Button disabled={disabled} color="primary" size="small" onClick={handleEditOffer(talentOffer)}>Edit</Button>
                                    </StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {offersFields.map((offersField) => 
                            <StyledTableRow key={offersField.name}>
                                <StyledTableCell component="th" scope="row">
                                    <strong>{offersField.label}</strong>
                                </StyledTableCell>
                                {talentOffers.map((talentOffer) => {
                                    let value

                                    if (offersField.curField || offersField.freqField){
                                        value = displayCash({value:talentOffer[offersField.name], currency:talentOffer[offersField.curField], frequency:talentOffer[offersField.freqField]})
                                    } else {
                                        value = talentOffer[offersField.name]||''
                                    }

                                    return (
                                        <StyledTableCell key={talentOffer._id} align="left" style={{borderLeft: '1px solid #e0e0e0'}}>
                                            {value}
                                        </StyledTableCell>
                                    )
                                })}
                            </StyledTableRow>
                        )}
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row"></StyledTableCell>
                                {talentOffers.map((talentOffer) => 
                                    <StyledTableCell key={talentOffer._id} align="left" style={{borderLeft: '1px solid #e0e0e0'}}>
                                        <Button disabled={disabled} variant='contained' color="primary" size="small" onClick={handleSignOffer(talentOffer)}>Signed</Button>
                                    </StyledTableCell>
                                )}
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>:
                <Button disabled={disabled} color="primary" size="small" onClick={handleAddOffer}>+ Offer</Button>}
            </JSPaper>

            <JSPaper style={{textAlign:'left', padding:16}}>
                <Typography variant='h6'>Jobs: {talentJobs.length}</Typography>
                {(editId===0)?
                    <form onSubmit={handleAddTalentJob}>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <Fields fields={fieldsTalentJob} getValue={getValueFoundJob} handleChange={handleChangeFieldFoundJob} getDateValue={getDateValue} handleChangeField={handleChangeField}/>
                        {/*<Grid container spacing={3}>
                            <Grid item xs={12}>
                                <JobBenefits/>
                            </Grid>
                        </Grid>*/}
                        <Fields fields={fieldsTalentJob2} getValue={getValueFoundJob} handleChange={handleChangeFieldFoundJob} getDateValue={getDateValue} handleChangeField={handleChangeField}/>
                        <Button
                            color="primary"
                            size="small"
                            onClick={handleCnacelFoundJob}
                            aria-label="Cancel"
                            disabled={disabled}
                            style={{marginTop:8, marginRight:8}}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save"
                            disabled={disabled}
                            style={{marginTop:8}}
                        >
                            Save
                        </Button>
                    </form>:
                <Button disabled={disabled || editId!==undefined} color="primary" size="small" onClick={handleAddJob}>+ Job</Button>}
                <List>
                    {talentJobs.map((talentJob, index) => {
                        return (
                            <Fragment key={index}>
                                <Divider />
                                <ListItem>
                                {(editId===talentJob._id)?<form onSubmit={handleEditDeleteTalentJob}>
                                    {(error)&&<Typography color="error">{error}</Typography>}
                                    {newJobData.deleteObject?<Typography>Are you sure you want to delete?</Typography>:
                                    <>
                                    <Fields fields={fieldsTalentJob} getValue={getValueFoundJob} handleChange={handleChangeFieldFoundJob} getDateValue={getDateValue} handleChangeField={handleChangeField}/>
                                    {/*<Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <JobBenefits/>
                                        </Grid>
                                    </Grid>*/}
                                    <Fields fields={fieldsTalentJob2} getValue={getValueFoundJob} handleChange={handleChangeFieldFoundJob} getDateValue={getDateValue} handleChangeField={handleChangeField}/>
                                    </>}
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={handleCnacelFoundJob}
                                        aria-label="Cancel"
                                        disabled={disabled}
                                        style={{marginTop:8, marginRight:8}}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        disabled={disabled}
                                        style={{marginTop:8}}
                                    >
                                        {newJobData.deleteObject?'Yes':'Save'}
                                    </Button>
                                </form>:
                                <Grid container spacing={1} style={{alignItems: 'center'}}>
                                        <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                            <Typography style={{fontWeight:'bold', fontSize: 18,textTransform: 'capitalize'}}>{talentJob.title}</Typography>
                                            <div style={{display: 'flex'}}>
                                                {isAdmin&&<IconButton onClick={handleCopy(talentJob)} disabled={disabled || editId!==undefined}>  
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>}
                                                <IconButton onClick={handleEdit(talentJob)} disabled={disabled || editId!==undefined}>  
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton onClick={handleDelete(talentJob._id)} disabled={disabled || editId!==undefined}>  
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Typography>{talentJob.company}</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={12} style={{textAlign:'right'}}>
                                            <Typography>Begin: {dateAsString(new Date(talentJob.dateStart))}</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <Typography>{displayCash({value:talentJob.salary, currency:talentJob.salaryCurrency, frequency:talentJob.salaryFrequency})}</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={12} style={{textAlign:'right'}}>
                                            <Typography>{talentJob.type}</Typography>
                                        </Grid>
                                        {/*<Grid item xs={12}>
                                            <JobBenefits/>
                                        </Grid>*/}
                                        <Grid item sm={12} xs={12}>
                                            <Typography dir="auto" style={{whiteSpace:'pre-line'}}>{talentJob.description}</Typography>
                                        </Grid>
                                        
                                    </Grid>}
                                </ListItem>
                            </Fragment>
                    )})}
                </List>
            </JSPaper>
        </div>
    )
}