import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { PersonalJourneyClasses } from '../../../styles/PersonalJourneyClasses';
import JHApi from "../../../utils/JHApi"
import AdminJHApi from "../../../utils/AdminJHApi"
import { FIELD_TYPES, capitalize, displayCash, getNameFromEmail, showDateTimeFromString } from '../../../utils/utils';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import SecondQuestionnaireDialog from './SecondQuestionnaireDialog';

export default function DreamJob(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 
    const questionnaireComments = journeyData.questionnaireComments || {}
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journetId = journeyData._id
    const midServiceQuestionnaire = journeyData.midServiceQuestionnaire || {}

    const [questionnaire, setQuestionnaire] = useState({});
    const [leads, setLeads] = useState([]);
    const [isLoadingQuestionnaire, setisLoadingQuestionnaire] = useState(false);
    const [editField, seteditField] = useState('');
    const [editFieldLabel, setEditFieldLabel] = useState();
    const [newValue, setNewValue] = useState();
    const [newComment, setNewComment] = useState();
    const [error, setError] = useState();

    const [showMidServiceQuestionnaire, setShowMidServiceQuestionnaire] = useState(false);

    const disabled = isLoading || isLoadingQuestionnaire

    useEffect(() => {
        if (journetId){
            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setisLoadingQuestionnaire(true)

            JHApi.getQuestionnaire(paramsToPass,(response)=>{
                if(response){
                    setQuestionnaire(response.questionnaire||{})
                    setLeads(response.leads||[])
                }

                setisLoadingQuestionnaire(false)
            }, (error) => {
                setisLoadingQuestionnaire(false)
                //setIsError(true)
            })
        }
    }, [journetId, isAdmin, userId]);

    const handleChange = e => {
        setNewValue(e.target.value)
    };

    const handleChangeComment = e => {
        setNewComment(e.target.value)
    };

    const handleStartEditField = (name,label) => {
        setError()
        setNewValue(questionnaire[name]||'')
        setNewComment(questionnaireComments[name]||'')
        setEditFieldLabel(label)
        seteditField(name)
    }

    const handleCancel = e => {
        seteditField('')
        setEditFieldLabel()
    }

    const handleSave = e => {
        e.preventDefault() 

        let paramsToPass = {questionnaireId:questionnaire._id, fieldName:editField, value:newValue, comment:newComment}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.setQuestionnaireFieldAndComments(paramsToPass,(response)=>{
            setQuestionnaire(response.questionnaire)
            setError('')
            seteditField('')
            setEditFieldLabel()

            props.postServerCall(null, response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleChangeMidServiceQuestionnaire = event => {
        let paramsToPass = {data:{isMidServiceQuestionnaire:event.target.checked}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            setError()

            props.postServerCall(null, response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleFillMidServiceQuestionnaire = () => {
        setShowMidServiceQuestionnaire(true)
        /*Current job status, 
        Job title, 
        Company name, 
        Location, 
        Salary, 
        Start Date, 
        Clear Job Definition */
    }

    const sectionsFields = [
        [
            {name:'timestamp', label:'Date Created', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, cantEdit:true},
            {name:'email', label:'Email Address', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, cantEdit:true},
            {name:'fullName', label:'שם', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'phone', label:"מס' טלפון", defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'city', label:'עיר מגורים', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'age', label:'גיל', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'howFindUs', label:"מי המליץ לך על השירות של ג'ובהאנט? איך שמעת עלינו?", defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
        ],
        [
            {name:'isWorking', label:'האם עובד/ת היום?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'seniority', label:'וותק בחברה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'jobTitle', label:'הגדרת תפקיד', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'whyLeave', label:'מדוע רוצה לעזוב / עזבת?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
        ],
        [
            {name:'jobSearchMonths', label:'כמה זמן אתה מחפש עבודה באופן אקטיבי?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'interviews', label:'בכמה ראיונות עבודה שונים היית עד היום?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'waysJobSearch', label:'באילו דרכים חיפשת עבודה עד עכשיו?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
        ],
        [
            {name:'witchPositionsLookingFor', label:'אילו משרות מחפש/ת? מה המטרות העיקריות?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'searchIssues', label:'מה לדעתך הקשיים העיקריים בחיפוש העבודה שלך?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'currentSalary', label:'מהו השכר הנוכחי?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'searchGeoRenge', label:'גבולות חיפוש גיאוגרפיים', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'dreamJob', label:'אז מהי משרת החלומות שלך?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
            {name:'wishedSalary', label:'ציפיות שכר?', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}
        ]
    ]    

    const sectionFields = [
        {id:'status', label:'Current job status', defaultValue: '', capitalize:true},
        {id:'title', label:'Job title', defaultValue: '', capitalize:true},
        {id:'company', label:'Company', defaultValue: '', capitalize:true},
        {id:'location', label:'Location', defaultValue: '', capitalize:true},
        {id:'salary', label:'Salary', curField:'salaryCurrency', freqField:'salaryFrequency', defaultValue: 0, type:'SALARY'},
        {id:'dateStart', label:'Start Date', defaultValue: '', type:'DATE'},
        {id:'description', label:'Clear Job Definition', defaultValue: ''},
    ]

    const classes = PersonalJourneyClasses(); 

    return (
        <div>
            {isLoadingQuestionnaire?<Typography style={{padding:16}}>Loading</Typography>:
            <>{questionnaire._id?<>
                <Dialog onClose={handleCancel} open={(editField!=='')} fullWidth>
                    <DialogTitle>{editFieldLabel}</DialogTitle>
                    <form onSubmit={handleSave}>
                        <DialogContent dividers>
                            {(error)&&<Typography color="error">{error}</Typography>}
                            <TextField variant='outlined' onChange={handleChange} multiline rows={4} rowsMax={8} fullWidth value={newValue} name={editField}/>
                            <br/><br/>
                            <TextField variant='outlined' onChange={handleChangeComment} multiline rows={4} rowsMax={8} fullWidth value={newComment} label='comment' name={editField+'Comment'}/>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="small"
                                aria-label='Save'
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                {(isAdmin||journeyData.isMidServiceQuestionnaire)&&<Paper elevation={1} style={{marginTop:0, whiteSpace: 'pre-line', padding: 16, marginBottom: 16}}>
                    {isAdmin&&<div>
                        <FormControlLabel
                            checked={journeyData.isMidServiceQuestionnaire || false}
                            control={<Switch color="primary"/>}
                            label="Mid-Service Questionnaire"
                            labelPlacement="end"
                            name="publishOn"
                            onChange={handleChangeMidServiceQuestionnaire}
                            disabled={disabled}
                        />
                    </div>}
                    {journeyData.isMidServiceQuestionnaire&&<div>
                        <SecondQuestionnaireDialog {...props}
                            showMidServiceQuestionnaire={showMidServiceQuestionnaire} 
                            setShowMidServiceQuestionnaire={setShowMidServiceQuestionnaire}
                        />
                        {isAdmin&&<div style={{marginBottom:8}}></div>}
                        <Button 
                            style={{textTransform: 'capitalize', marginBottom: 8}} 
                            variant='contained' 
                            color='primary' 
                            size='small'
                            onClick={handleFillMidServiceQuestionnaire}
                        >Edit</Button>

                        {sectionFields.map((field, index) => {
                            let value = midServiceQuestionnaire[field.id] === undefined ? '' : midServiceQuestionnaire[field.id]

                            if(field.capitalize){
                                value = capitalize(value)
                            }

                            switch (field.type){
                                case 'DATE': {
                                    value = showDateTimeFromString(value, false);
                                    break;
                                } case 'DATE_TIME': {
                                    value = showDateTimeFromString(value, true);
                                    break;
                                } case 'SALARY': {
                                    value = displayCash({value:value, currency:midServiceQuestionnaire[field.curField], frequency:midServiceQuestionnaire[field.freqField]})
                                    break;
                                } default:{
                                    
                                }
                            }

                            return (
                                <div key={field.id} style={{display:'flex', marginBottom: 8, alignItems:'center', paddingBottom: 8, width:'100%', borderBottom: '1px solid silver'}}>
                                    <div style={{marginRight:8}}>
                                        <Typography color='primary' dir="auto" style={{textAlign:'left'}}><strong>{field.label}</strong></Typography>
                                        <Typography dir="auto" style={{textAlign:'left', whiteSpace: 'pre-line'}}>{value}</Typography>
                                    </div>
                                </div>
                            )})}
                    </div>}
                </Paper>}
                {(leads.length>0)&&<Paper elevation={1} style={{marginTop:0, whiteSpace: 'pre-line', padding: 16, marginBottom: 16}}>
                    {leads.map((lead) => (
                        <div key={lead._id} style={{display:'flex', marginBottom: 8, alignItems:'center', paddingBottom: 8, width:'100%', borderBottom: '1px solid silver'}}>
                            <div style={{marginRight:8}}>
                                <Typography color='primary' dir="auto" style={{textAlign:'left'}}><strong>{showDateTimeFromString(lead.date, false)} - {lead.communicationChannel}{lead.csManager?' - ':''}{getNameFromEmail(lead.csManager)}</strong></Typography>
                                <Typography dir="auto" style={{textAlign:'left', whiteSpace: 'pre-line'}}>{lead.summary}</Typography>
                            </div>
                        </div>
                    ))}
                </Paper>}
                {sectionsFields.map((sectionFields, index) => (
                    <Paper key={index} className={classes.prePublishSection} elevation={1} style={{marginTop:0, whiteSpace: 'pre-line'}}>
                        {sectionFields.map((field, index) => (
                            <div key={field.name} style={{display:'flex', marginBottom: 8, alignItems:'center', paddingBottom: 8, width:'100%', borderBottom: '1px solid silver'}}>
                                <div style={{marginRight:8}}>
                                    <Typography color='primary' dir="auto" style={{textAlign:'left'}}><strong>{field.label}</strong></Typography>
                                    <Typography dir="auto" style={{textAlign:'left'}}>{questionnaire[field.name] || ''}</Typography>
                                    <Typography variant='caption' dir="auto" style={{textAlign:'left', color: 'rgba(0, 0, 0, 0.54)'}}>{questionnaireComments[field.name] || ''}</Typography>
                                </div>
                                {/*!field.cantEdit && !editField&&*/}<div><IconButton aria-label="edit" className="editButton" onClick={()=>handleStartEditField(field.name, field.label)}>
                                    <EditIcon fontSize="small" />
                                </IconButton></div>
                            </div>
                        ))}
                    </Paper>
                ))}
            </>:<Typography style={{padding:16}}>No Questionnaire - please fill it here: <a target='_blank' rel="noopener noreferrer" href='https://jobhunt.co.il/?career-questionnaire'>Link To Form</a></Typography>}
            </>}
        </div>
    )
}