import React from 'react';

import Dialog from '@material-ui/core/Dialog'
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types'

import SignIn from '../Login/SignIn'
import ForgotPassword from '../Login/ForgotPassword'
import SignUp from '../Login/SignUp'

import '../../styles/Login.css'

export default class LoginDialog extends React.Component {
    handleClose = () => {
		this.props.onClose();
    };

    handleSetSection = section => {
        this.props.handleSetSection(section)
    }
    
    render(){
        const { onClose, handleLogin, handleSetSection, showMessage, loginErrors, section, redirectPath, ...other } = this.props;

		return (
			<Dialog onClose={this.handleClose} aria-labelledby="share-dialog-title" classes={{ paper: "loginPaper" }}   {...other}>
                <span className="close-modal" onClick={this.handleClose}>
                    <Close/>
                </span>
                {(section === 'signIn') && <SignIn redirectPath={redirectPath} setSection = {this.handleSetSection} handleLogin={handleLogin} loginErrors={loginErrors}/>}
                {(section === 'forgotPassword') && <ForgotPassword setSection = {this.handleSetSection} showMessage={showMessage}/>}
                {(section === 'signUp') && <SignUp redirectPath={redirectPath} setSection = {this.handleSetSection} showMessage={showMessage}/>}
			</Dialog>
		)
	}
}

LoginDialog.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}