import React, { Component } from 'react'
import ExpandMore from '@material-ui/icons/ExpandMore';

import ReactGA from 'react-ga4'

import '../styles/ReadMore.css'

export default class ReadMore extends Component {
    state = {
        expanded: false
    };

    expandText = () => {
        ReactGA.event({category: 'Card',action: 'read_more'});

        this.setState({expanded: true})
    }

    componentDidUpdate(prevProps){
        if (prevProps.children && this.props.children !== prevProps.children) {
            this.setState({expanded: false})
        }
    }
    
    render() {
        const numOfChars = (this.props.view === 'MOBILE') ? 510 : 1010;
        var textToShow = (this.props.children) ? this.props.children : '';

        var firstPart = '';
        var secondPart = '';

        if (textToShow.length > numOfChars){
            var posToCut = numOfChars;
    
            var blankRegExp = new RegExp('[ ]');
            while(posToCut > 0 && !blankRegExp.test(textToShow.charAt(posToCut))){
                posToCut--;
            }
    
            firstPart = textToShow.substring(0, posToCut);
            secondPart = textToShow.substring(posToCut, textToShow.length);
        } else {
            firstPart = textToShow;
            secondPart = '';
        }

        const direction = (this.props.direction) ? ' ' + this.props.direction : ''

        //const searchWords = this.props.searchWords || []

        return (
            <div className={"jobDescription" + direction}>
                <span className="text">
                    {firstPart || ''}
                    {/*<Highlighter
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={firstPart || ''}
                    />*/}
                </span>
                {(this.state.expanded && secondPart)&&<span className="text">
                    {secondPart || ''}
                    {/*<Highlighter
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={secondPart || ''}
                    />*/}
                </span>}
                {(!this.state.expanded && secondPart)&&<span className="text">...</span>}
                {(!this.state.expanded && secondPart)&&<div className="readMore" onClick={this.expandText}>
                    <span>READ MORE</span>
                    <span className="icon">
                        <ExpandMore style={{ fontSize: 18 }}/>
                    </span>
                </div>}
			</div>
        )
    }
}