import HistApi from "../../utils/HistApi";

export const CARD_CONTENT_REQUEST = 'CARD_CONTENT_REQUEST'
export const CARD_CONTENT_RECEIVE = 'CARD_CONTENT_RECEIVE'
export const CARD_CONTENT_APPEND = 'CARD_CONTENT_APPEND'
export const CARD_CONTENT_SET_TYPE = 'CARD_CONTENT_SET_TYPE'
export const CARD_CONTENT_SET_FILTERED = 'CARD_CONTENT_SET_FILTERED'
export const CARD_CONTENT_CLEAR = 'CARD_CONTENT_CLEAR'

const setCardContentType = cardContentType => {
    return {
        type: CARD_CONTENT_SET_TYPE,
        cardContentType: cardContentType
    }
}

const requestData = cardContentType => {
    return {
        type: CARD_CONTENT_REQUEST,
        cardContentType: cardContentType
    }
}

const recieveData = data => {
    return {
        type: CARD_CONTENT_RECEIVE, 
        data
    }
}

const appendData = data => {
    return {
        type: CARD_CONTENT_APPEND, 
        data
    }
}

export const setCardContentTypeAndFetch = (cardContentType, company, data) => dispatch => {
    if (cardContentType === 'Jobs'){
        dispatch(setCardContentType(cardContentType))
    } else if (['Interviews', 'Reviews'].includes(cardContentType)){
        dispatch(requestData(cardContentType))
        if (data){
            dispatch(recieveData([data]))
            HistApi.getReviews({cardContentType, company:company, skipId:data._id}, (results)=>{
                if (results){
                    dispatch(appendData(results.data))
                }
            })
        } else {
            HistApi.getReviews({cardContentType, company:company}, (results)=>{
                if (results){
                    dispatch(recieveData(results.data))
                }
            })   
        }
    }
}