import { Button, Collapse, Dialog, DialogActions, DialogContent, IconButton, Snackbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FIELD_TYPES, capitalize, getNameFromEmail, showDateTimeFromString } from '../../../utils/utils';
//import { Rating } from '@material-ui/lab';
//import FieldWithEdit from '../../FieldWithEdit';
import JHApi from "../../../utils/JHApi"
import EditIcon from '@material-ui/icons/Edit';
import { Fields } from '../../Fields';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Alert } from '@material-ui/lab';
//import RatingsExtra from '../../RatingsExtra';

function Summary(props) {
    const disabled = props.disabled || false
    const isAdmin = props.isAdmin
    const summary = props.summary || {}
    //const name = props.name || ''

    return(
        <div style={{display:'flex',justifyContent:'space-between',alignItems: 'flex-start'}}>
            {summary.summary&&<Typography dir="auto" variant='body1' component='div'>
                {summary.author&&<><strong>
                    {getNameFromEmail(summary.author)} - {showDateTimeFromString(summary.date, false)}
                </strong><br/></>}
                <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary.summary}</span>
            </Typography>}
            {isAdmin&&<>
                {summary.summary?
                <div>
                    <IconButton color='primary' onClick={props.handleEditSummary} disabled={disabled}>  
                    <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton color='primary' onClick={props.handleCopySummary} disabled={disabled}>  
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                </div>:
                <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={props.handleEditSummary}>Add Publication Summary</Typography>}
            </>}
        </div>
    )
}

function EditComment(props) {
    const summary = props.summary || {}
    const disabled = props.disabled || false

    const [newData, setNewData] = useState(summary);

    const getValueComment = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleSave = e => {
        e.preventDefault();

        props.save(newData)
    }

    const handleCancel = () => {
        props.cancel()
    }

    const commentsFields = [
        {name:'summaryDate', label:'Date', defaultValue: '', type:FIELD_TYPES.DATE2, required:true},
        {name:'csComment', label:'Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:6, sm:12, required:true},
    ]

    return (
        <form onSubmit={handleSave}>
            <Fields fields={commentsFields} getValue={getValueComment} handleChange={handleChangeField}/>
            <div style={{marginTop: 8}}>
                <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                    Cancel
                </Button>
                <Button
                    disabled={disabled}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    size="small"
                    aria-label='Save'
                >
                    Save
                </Button>
            </div>
        </form>
    )
}

export default function CycleSummary(props) {
    const cycle = props.cycle || {}
    const interviewProcesses = props.interviewProcesses || []
    const publishesData = props.publishesData || []
    const ratingsFields = props.ratingsFields || []
    const disabled = props.disabled || false

    const journeyData = props.journeyData || {}
    const isAdmin = props.isAdmin
    const userId = props.userId

    //const [showInterviews, setShowInterviews] = useState(false);
    const [showParts, setShowParts] = useState(false);
    const [showCoverLeter, setShowCoverLeter] = useState(false);
    const [editSummary, setEditSummary] = useState(false);

    const [showCopied, setShowCopied] = useState(false);

    const today = new Date().toISOString().substring(0, 10)

    const handleHideCoverLeter = () => {
        setShowCoverLeter(false)
    }

    const handleShowCoverLeter = () => {
        setShowCoverLeter(true)
    }

    const publishesDataForCycle = publishesData.find(row => row._id === cycle.publishId) || {};

    let ratings = {avg:{}}
    //let interviewCount = 0

    interviewProcesses.forEach(function(interviewProcesse) {
        ratingsFields.forEach(function(ratingsField) {
            if(interviewProcesse[ratingsField]){
                if (!ratings[ratingsField]){
                    ratings[ratingsField] = {sum:0, count:0}
                }
    
                ratings[ratingsField].sum += interviewProcesse[ratingsField]
                ratings[ratingsField].count++
                ratings.avg[ratingsField] = ratings[ratingsField].sum / ratings[ratingsField].count
            }
        })
        //interviewCount += interviewProcesse.interviews.length
    })

    const handleEditSummary = () => {
        setEditSummary(true)
    }

    const handleSaveSummary = (newComment) => {
        let paramsToPass = {id: cycle._id, comment:newComment}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditInterviews()

        JHApi.changeCycleComment(paramsToPass,(response)=>{
            props.finishEditInterviews({newCycles:response})
            setEditSummary(false)
        }, (error) => {
            props.finishEditInterviews()
        })
    }

    const handleCancelSummary = () => {
        setEditSummary(false)
    }

    const handleCopySummary = () => {
        const oneDay = 1000 * 60 * 60 * 24;
        const daysSinceCycleStart = cycle.summaryDate?Math.ceil((new Date(cycle.summaryDate) - new Date(cycle.firstSent))/oneDay):0

        const text = `${getNameFromEmail(cycle.summaryAuthor)} - Summary after meeting ${showDateTimeFromString(cycle.summaryDate, false)} - ${name}
${props.label||''} - ${capitalize(journeyData.plan||'')} - ${daysSinceCycleStart} ${(daysSinceCycleStart===1?'day':'days')} passed - ${capitalize(journeyData.title)||''}

${cycle.csComment}`

        navigator.clipboard.writeText(text)

        console.log(text)
        setShowCopied(true)
    }

    const name = journeyData.first_name + ' ' + journeyData.last_name

    return (
        <>
            <Dialog onClose={handleHideCoverLeter} open={showCoverLeter} fullWidth>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: publishesDataForCycle.coverLetter||''}} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHideCoverLeter} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={showCopied} autoHideDuration={2000} onClose={()=>{setShowCopied(false)}}>
                <Alert onClose={()=>{setShowCopied(false)}} severity="success">
                    Copied
                </Alert>
            </Snackbar>

            <Typography variant='body1' component='div'>
                <strong>{publishesDataForCycle.first_name} {publishesDataForCycle.last_name} {props.label||''} - {showDateTimeFromString(cycle.firstSent, false)}</strong> <br/>
                {publishesDataForCycle.emailTitle} <br/>
                <span style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleShowCoverLeter}>Cover letter</span> 
                <span>  |  </span>
                <a style={{color: '#1861bf', textDecoration: 'none'}} target='_blank' rel="noopener noreferrer" href={publishesDataForCycle.CVUrl}>CV</a>

                {/*<div style={{margin: '16px 0'}}>
                    {<Rating name={"rating"+cycle._id} size="small" value={cycle.rating || 0} onChange={handleChangeCycleRating} style={{marginRight: 8}} readOnly={!isAdmin}/>}
                    {(isAdmin||cycle.csComment)&&<FieldWithEdit value={cycle.csComment||''} changeValue={handleCycleComment} fullWidth multiline newLine noPaddingButton noEdit={!isAdmin}>
                        <span style={{whiteSpace: 'pre-line'}}>{cycle.csComment||'No Summary'}</span>
                    </FieldWithEdit>}
                </div>*/}

                {(cycle.csComment||isAdmin)&&<>
                    <br/><br/>
                    {editSummary?
                        <EditComment summary={{csComment:cycle.csComment, summaryDate:cycle.summaryDate||today}} disabled={disabled} save={handleSaveSummary} cancel={handleCancelSummary}/>:
                        <Summary disabled={disabled} isAdmin={isAdmin} summary={{summary:cycle.csComment, author:cycle.summaryAuthor, date:cycle.summaryDate}} handleEditSummary={handleEditSummary} handleCopySummary={handleCopySummary} name={name}/>}
                </>}

                {/*<div style={{margin: '16px 0'}}>
                    <Rating precision={0.5} value={ratings.avg.ratingGeneral || 0} size="small" readOnly/>
                    <RatingsExtra data={ratings.avg}/>
                    <span style={{marginLeft: 8}}><strong>{interviewProcesses.length} procceses ({interviewCount} interviews)</strong> {(interviewProcesses.length>0) && <Typography component='span' style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=> {setShowInterviews(!showInterviews)}}>show {showInterviews?'less':'more'}</Typography>}</span>
                    <Collapse in={showInterviews} timeout="auto" unmountOnExit>
                        {interviewProcesses.map(interviewProcess => 
                            <div key={interviewProcess.company}>
                                <Rating precision={0.5} value={interviewProcess.ratingGeneral} size="small" readOnly/>
                                <RatingsExtra data={interviewProcess}/>
                                <span style={{marginLeft: 8}}>{interviewProcess.interviews.length} interviews at <span style={{textTransform: 'capitalize'}}>{interviewProcess.company}</span> - {interviewProcess.status}
                                    {(isAdmin||interviewProcess.csComment)&&<FieldWithEdit value={interviewProcess.csComment||''} changeValue={newComment => {handleInterviewComment(interviewProcess.company, newComment)}} fullWidth multiline newLine noPaddingButton noEdit={!isAdmin}>
                                        <span>{' - ' + (interviewProcess.csComment||'No Summary')}</span>
                                    </FieldWithEdit>}
                                </span>
                            </div>
                        )}
                    </Collapse>
                </div>*/}

                {/*<div style={{margin: '16px 0'}}>2 offers, If have </div>*/}

                {isAdmin&&<div>
                    <br/>
                    <Typography component='span' style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=> {setShowParts(!showParts)}}>{showParts?'hide':'show'} parts</Typography> <br/>
                    <Collapse in={showParts} timeout="auto" unmountOnExit>
                        1 - {cycle.part1.date?`Sent to ${cycle.part1.sentCount||''} emails on ${showDateTimeFromString(cycle.part1.date, false)}`:''} <br/>
                        2 - {cycle.part2.date?`Sent to ${cycle.part2.sentCount||''} emails on ${showDateTimeFromString(cycle.part2.date, false)}`:''} <br/>
                        3 - {cycle.part3.date?`Sent to ${cycle.part3.sentCount||''} emails on ${showDateTimeFromString(cycle.part3.date, false)}`:''} <br/>
                        4 - {cycle.part4.date?`Sent to ${cycle.part4.sentCount||''} emails on ${showDateTimeFromString(cycle.part4.date, false)}`:''} <br/>
                        <strong>Total - Sent to {(cycle.part1.sentCount||0) + (cycle.part2.sentCount||0) + (cycle.part3.sentCount||0) + (cycle.part4.sentCount||0)} emails</strong>
                    </Collapse>
                </div>}
            </Typography>
        </>
    )
}