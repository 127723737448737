import AdminApi from "../../utils/AdminApi";

export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS'
export const REQUEST_PAYMENTS = 'REQUEST_PAYMENTS'
export const RECEIVE_PAYMENTS = 'RECEIVE_PAYMENTS'

const isCanFetch = state => {
    const isFetching = state.payments.isFetching

    if (isFetching) {
        return false
    } else {
        return true
    }
}

const requestPayments = () => {
    return {
      	type: REQUEST_PAYMENTS
    }
}

function receivePayments(payments, from, to) {
    return {
        type: RECEIVE_PAYMENTS, payments, from, to
	}
}

export const clearHistogramSelected = () => {
    return {
        type: CLEAR_PAYMENTS
    }
}

export const fetchPayments = (from, to) => {
	return (dispatch, getState) => {
        const state = getState();

        if (isCanFetch(state)) {
            dispatch(requestPayments())

            AdminApi.getPayments({from, to}, (results)=>{
                dispatch(receivePayments(results.payments, from, to))
            }, (error)=>{
                dispatch(receivePayments([]))
            });
        }
    }
}