import { Button, Paper, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import EventApi from "../../utils/EventApi"
import { addDays, dateAsString, getNextDayOfWeek } from '../../utils/utils';
import EventOccurence from './EventOccurence';
import EvetsRegister from './EvetsRegister';
import Registered from './Registered';
import '../../styles/Events.css'

function EventButton(props) {
    const label = props.label || ''
    return (
        <Button 
            {...props}
            style={{marginRight: 8, fontSize:17}} 
            size='small' 
            color='primary' 
            variant='contained'>
            {label}
        </Button>
    )
}

export default function Events(props) {
    const isAdmin = props.isAdmin || props.isJHAdmin

    let eventUrl = undefined
    if (props.match.params  && props.match.params.eventUrl){
        eventUrl = props.match.params.eventUrl
    }

    const searchParams = new URLSearchParams(window.location.search);
    const registeredId = searchParams.get("id")
    const registeredCode = searchParams.get("code")
    const registeredCanceled = searchParams.get("canceled")

    const [isLoading, setIsLoading] = useState(false);
    const [eventData, setEventData] = useState({});
    const [eventOccurrences, setEventOccurrences] = useState([]);
    const [registeredData, setRegisteredData] = useState({});
    const [isRegester, setIsRegester] = useState(false);
    const [showOccurenceDialog, setShowOccurenceDialog] = useState(false);
    const [selectedOccurence, setSelectedOccurence] = useState({});
    const [registeredOnOccurrences, setRegisteredOnOccurrences] = useState([]);

    useEffect(() => {
        setIsLoading(true)

        EventApi.getEventData({eventUrl, registeredId, registeredCode},(response)=>{
            updateDataByRespone(response||{})

            setIsLoading(false)
        }, (error) => {
            console.log(error)
            setIsLoading(false)
            //setIsError(true)
        })
    }, [eventUrl, registeredId, registeredCode, isAdmin]);

    const updateDataByRespone = response => {
        setEventData(response.eventData||{})
        setEventOccurrences(response.eventOccurrences||[])
        if (response.registeredData && response.registeredData.status === 'A' ){
            //haveQuestionnaire
            setRegisteredData({...response.registeredData, haveQuestionnaire:response.haveQuestionnaire})
        } else {
            setRegisteredData({})
        }

        setRegisteredOnOccurrences(response.registeredOnOccurrences||[])
    }

    const handleRegister = occurrence => () => {
        setSelectedOccurence(occurrence)
        setIsRegester(true)
    }

    const handleEditOccurrence = occurrence => () => {
        setSelectedOccurence(occurrence)
        setShowOccurenceDialog(true)
    }

    const handleCloseRegister = () => {
        setIsRegester(false)
        setSelectedOccurence({})
    }

    const handleCloseOccurenceDialog = () => {
        setShowOccurenceDialog(false)
        setSelectedOccurence({})
    }

    const preServerCall = () => {
        setIsLoading(true)
    }

    const postServerCall = (response, extra={}) => {
        if(extra.chngeUrl){
            props.changeUrl(extra.chngeUrl)
        } else {
            updateDataByRespone(response||{})
        }

        if (extra && extra.closeRegister){
            setIsRegester(false)
        }

        if (extra && extra.closeOccurenceDialog){
            handleCloseOccurenceDialog()
        }
        setIsLoading(false)
    }

    const firstDate = getNextDayOfWeek(new Date(), 4).setHours(15,0,0,0);

    let showOccurences = 0

    return (
        <div className="pageMainContainer hebrew-text" style={{top:64}}>
            {(registeredCanceled)&&<Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}><Typography variant='h6'>Registration Canceled</Typography></Paper>}
            {(eventData._id)&&<>
            {(registeredData._id)&&<Registered registeredData={registeredData} eventUrl={eventUrl} isLoading={isLoading} preServerCall={preServerCall} postServerCall={postServerCall}/>}
            <EvetsRegister isLoading={isLoading} preServerCall={preServerCall} postServerCall={postServerCall}
                selectedOccurence={selectedOccurence} isRegester={isRegester} handleCloseRegister={handleCloseRegister}/>
            {(isAdmin)&&<EventOccurence isLoading={isLoading} preServerCall={preServerCall} postServerCall={postServerCall} eventUrl={eventUrl}
                selectedOccurence={selectedOccurence} showOccurenceDialog={showOccurenceDialog} handleCloseOccurenceDialog={handleCloseOccurenceDialog}/>}
            <Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                <Typography variant='h5' className='hebrew-text'>{eventData.title}</Typography>
                {/*eventData.description&&<Typography variant='body1' style={{whiteSpace:"pre-line"}}>{eventData.description}</Typography>*/}
                {eventData.description&&<Typography variant='body1' className='hebrew-text' style={{fontSize:18}}><br/>
                    הצטרפו אלינו לוובינר אישי ומצומצם עם מומחי ג'ובהאנט! <br/><br/>

                    נדבר ביחד על טיפים מעשיים לשדרוג חיפוש העבודה, ונסביר איך לחפש עבודה בשיטה הנדסית וחכמה יותר.<br/><br/>

                    ג'ובהאנט מלווה מחפשי עבודה מזה 15 שנים. אנחנו משווים נתונים בזמן אמת על שוק העבודה, מנגישים את המידע למחפשי העבודה ומעניקים חוויה אחרת לחיפוש העבודה הסטנדרטי.
                </Typography>}
            </Paper>

            {(!registeredData._id)&&<>{[...Array(8)].map((x, i) => {
                const occurrenceDate = addDays(firstDate, 7*i)
                const occurrence = eventOccurrences.find(occurrence =>    
                    occurrence.dateYear === (occurrenceDate.getFullYear())&&
                    occurrence.dateMonth === (occurrenceDate.getMonth()+1)&&
                    occurrence.dateDay === (occurrenceDate.getDate())
                )||{
                    eventId : eventData._id,
                    date : occurrenceDate,
                    dateYear : occurrenceDate.getFullYear(),
                    dateMonth : occurrenceDate.getMonth()+1,
                    dateDay : occurrenceDate.getDate(),
                    status : 'D',
                    limit : 50
                };

                const registeredCount = (registeredOnOccurrences.find(registeredOnOccurrence =>    
                    registeredOnOccurrence._id.dateYear === (occurrenceDate.getFullYear())&&
                    registeredOnOccurrence._id.dateMonth === (occurrenceDate.getMonth()+1)&&
                    registeredOnOccurrence._id.dateDay === (occurrenceDate.getDate())
                )||{
                    count:0
                }).count

                const limitReached = registeredCount >= (occurrence.limit||0)

                if (occurrence.status === 'A'){
                    showOccurences++
                }

                return (
                    <Fragment key={i}>
                    {(isAdmin || (showOccurences <= 2 && occurrence.status === 'A'))&&<Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                        {isAdmin&&<span style={{backgroundColor:(occurrence.status === 'A')?'green':'red', border: "1px solid white", borderRadius: "50%", width: 15, display: "inline-block",height: 15, marginLeft: 8}}></span>}
                        <span className='hebrew-text'>ב-{dateAsString(occurrenceDate)} בין 15:00-16:00</span>
                        <EventButton disabled={isLoading||limitReached||occurrence.status !== 'A'} label={limitReached?"לא נשארו מקומות פנויים":"רישום למפגש"} onClick={handleRegister(occurrence)}/>
                        {(isAdmin)&&<EventButton disabled={isLoading} label="Manage" onClick={handleEditOccurrence(occurrence)}/>}
                    </Paper>}
                    </Fragment>
                )
            })}</>}

            <Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                {eventData.description&&<Typography variant='body1' className='hebrew-text' style={{fontSize:18}}>
                    הצטרפו אלינו לוובינר אישי ואינטימי - בחינם וללא התחייבות - קבלו טיפים מעשיים שיחסכו לכם זמן יקר, ונצלו את ההזדמנות לשאול אותנו את כל השאלות, שלא היה לכם את מי לשאול<br/><br/>

                    YourPersonalTalentAgent#
                </Typography>}
            </Paper>
             {/*showOccurences*/}
            </>}
        </div>
    )
}
