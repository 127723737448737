import AdminApi from "../../utils/AdminApi";
//import { showDialog } from "./MainDialog";

export const CLEAR_TEMPLATES = 'CLEAR_TEMPLATES'
export const REQUEST_TEMPLATES = 'REQUEST_TEMPLATES'
export const RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES'

const isCanFetch = state => {
    const isFetching = state.templates.isFetching

    if (isFetching) {
        return false
    } else {
        return true
    }
}

const requestTemplates = () => {
    return {
      	type: REQUEST_TEMPLATES
    }
}

export function receiveTemplates(templates) {
	return {
		type: RECEIVE_TEMPLATES,
		templates: templates
	}
}

export const clearHistogramSelected = () => {
    return {
        type: CLEAR_TEMPLATES
    }
}

export const fetchTemplates = () => {
	return (dispatch, getState) => {
        const state = getState();

        if (isCanFetch(state)) {
            dispatch(requestTemplates())

            AdminApi.getTemplates((results)=>{
                dispatch(receiveTemplates(results.templates))
            }, (error)=>{
                dispatch(receiveTemplates([]))
                //dispatch(showDialog('Error', JSON.stringify(error)))
            });


            //dispatch(doFetchData(getState(), params, append, queryParams))
            /*let chunk = (append) ? state.histogramJobs.chunk + 1 : 1;
            dispatch(requestObjects(chunk, !append))

            dispatch(fetchObjects(state, params, chunk, append, queryParams))*/
        }
    }
}