import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import '../../styles/MatchDialog.css'
import React from 'react'
import Conffeti from 'react-confetti'
import ActivityIcon from '@material-ui/icons/Favorite';
import JobInfoSection from '../JobInfoSection';

export default class MatchDialog extends React.Component {
    state = {
        textFieldValue: ''
    }

    call = () => {
        const { phone } = this.props
        window.open('tel:' + phone)
    }

    closeDialog = () => {
        this.props.onClose()
    }

    sendEmail = (body = '') => {
        const { email, object, userInfo, mailRecieverData } = this.props
        let objectName = ''
        userInfo.lookingforwork? (objectName = mailRecieverData.name) : (objectName = object.first_name)

        window.open(`mailto:${email}?subject=${objectName}, We matched on JobSwipe&body=${body}`)
        this.closeDialog()
    }

    handleSumbit = e => {
        e.preventDefault()
        const body = this.state.textFieldValue

        this.sendEmail(body)
    }

    handleChange = e => {
        this.setState({ textFieldValue: e.target.value })
    }

    render() {
        const { object, userInfo, mailRecieverData } = this.props
        const view = (window.innerWidth <= 768) ? 'MOBILE' : 'DESKTOP'
        const varianttype = (view === 'MOBILE') ? 'h3' : 'h2';

        return (
            <Dialog onClose={this.closeDialog} open={this.props.open} classes={{ paper: 'matchDialogPaper' }}>
                <Conffeti recycle={false} colors={['#eeee', '#396a92']} numberOfPieces={450} style={{ height: '100%', width: '100%' }} />
                <div className='matchDialogContainer'>
                    <Box m={3} textAlign={'left'}>
                        <JobInfoSection object={object} mailRecieverData={mailRecieverData} call={this.call} sendEmail={this.sendEmail} lookingforwork={userInfo.lookingforwork}></JobInfoSection>
                    </Box>
                    <Box m={4}>
                        <Typography style={{ color: 'rgb(75, 150, 150)', fontWeight: '1000' }} variant={varianttype} className='matchTitle'>
                            <div style={{ fontSize: '30px' }}>IT'S A</div>
                            <div>MATCH!</div>
                            <div style={{ color: 'white' }} className="matchTitleShadow blueShadow">MATCH!</div>
                            <div style={{ color: 'white' }} className="matchTitleShadow lightBlueShadow">MATCH!</div>
                        </Typography>
                    </Box>
                    <Box m={1} position='relative'>
                        <ActivityIcon style={{ fontSize: 20, color: '#396a92' }} />
                        <Typography style={{wordWrap: "break-word", width: '100%', textAlign: 'center'}}>
                            {userInfo.lookingforwork ? `${mailRecieverData.name} from company ${object.company} likes your profile!`
                                : `${object.first_name} likes your job!`}
                        </Typography>
                        <Box m={1} mb={3}>
                            <form onSubmit={this.handleSumbit}>
                                <OutlinedInput margin='dense' value={this.state.textFieldValue} onChange={this.handleChange} placeholder='Say something nice!' endAdornment={
                                    <InputAdornment position="end">
                                        <Button color='primary' type="submit">Send</Button>
                                    </InputAdornment>
                                }>
                                </OutlinedInput>
                            </form>
                        </Box>
                        <Button onClick={this.closeDialog} color='primary'>
                            Keep Swiping
                        </Button>
                    </Box>
                </div>
            </Dialog>
        )
    }

}