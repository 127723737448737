import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getLocationText, setSearchJobs } from '../redux/actions/searchJobs'
import { fetchLocationsIfNeeded } from '../redux/actions/locations'

import SearchJobsMobile from '../components/SearchJobsMobile'
import SearchJobsDesktop from '../components/SearchJobsDesktop'
import ReactGA from 'react-ga4'

import '../styles/SearchJobs.css'
import SearchJobsMain from '../components/SearchJobsMain';
import { getLocation } from '../utils/AuthHelper'
import { showDialog } from '../redux/actions/MainDialog'

class SearchJobs extends Component {
    state = {
        newQueryString: '', 
        //newLocationCode: '',
        newLLocationObj: {},
        newLLocationText: '',
        newEmail: ''
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchLocationsIfNeeded())
    }

    isChnged(prevValue, newValue){
        return ((newValue) && (!prevValue || newValue !== prevValue))
    }

    componentDidUpdate(prevProps){
        let updated = false
        let newState = {}
        
        if(this.isChnged(prevProps.queryString, this.props.queryString)){
            updated = true
            newState.newQueryString = this.props.queryString
        }

        if(this.isChnged(prevProps.locationText, this.props.locationText)){
            updated = true
            newState.newLLocationText = this.props.locationText
        }

        if (this.props.locationObj){
            const prevPropsLocationObj = prevProps.locationObj||{}

            if(this.isChnged(prevPropsLocationObj.lng, this.props.locationObj.lng) || this.isChnged(prevPropsLocationObj.lat, this.props.locationObj.lat)){
                updated = true
                newState.newLLocationObj = this.props.locationObj
            }
        }

        if(updated){
            this.setState(newState)
        }
    }

    setQueryString = event => {
        this.setState({
            newQueryString: event.target.value,
        });   
    }

    setEmail= event => {
        this.setState({
            newEmail: event.target.value,
        });   
    }

    setLocationText = value => {
        if (value){
            this.setState({
                newLLocationText: value,
            });
        } else {
            this.setState({
                newLLocationObj: {},
                newLLocationText: ''
            })
        } 
    };

    setLocationObj = (locationObj)  => {
        let newState
        if(locationObj.lat && locationObj.lng && locationObj.text){
            newState = {
                newLLocationObj: locationObj,
                newLLocationText: locationObj.text
            }
        } else {
            newState = {
                newLLocationObj: {},
                newLLocationText: ''
            }
        }  

        this.setState(newState)

        this.handleSearch({locationObj})
    }

    handleSearch = async (nlParams) => {
        const { dispatch, searchType, locationCode } = this.props
        const newQueryString = (nlParams && nlParams.queryString) ? nlParams.queryString : this.state.newQueryString
        const newLLocationObj = (nlParams && nlParams.locationObj) ? nlParams.locationObj : this.state.newLLocationObj

        const newEmail = (nlParams && nlParams.email) ? nlParams.email : this.state.newEmail

        if(searchType === 'jobs'){
            ReactGA.event({category: 'Card',action: 'SearchJob'});
        } else if (searchType === 'resumes'){
            ReactGA.event({category: 'Card',action: 'SearchResume'});
        }

        let params = {queryString:newQueryString, email:newEmail}

        if (searchType === 'jobhuntUsers'){
            dispatch(setSearchJobs(params, null, searchType))
        } else {
            if ((!newLLocationObj.lng || !newLLocationObj.lat || !newLLocationObj.text) && locationCode){
                params.locationCode = locationCode

                dispatch(setSearchJobs(params, null, searchType))
            } else if (!newLLocationObj.lat || !newLLocationObj.lng){
                const {err, userLocation} = await getLocation()

                if (err){
                    dispatch(showDialog('Location required', 'Please enter a location'))
                } else {
                    params.locationObj = userLocation
                    dispatch(setSearchJobs(params, null, searchType))
                }

            } else {
                params.locationObj = newLLocationObj
                dispatch(setSearchJobs(params, null, searchType))
            }
        }
    }

    render() {
        const { searchType, histogramType } = this.props
        const {newQueryString, newLLocationText, newEmail} = this.state
        const keyWordPlaceholder = (searchType === 'resumes') ? 'Talent Keywords, Title, or Skills' : 'Job Title, Keywords, or Company'
        const searchTitle = (searchType === 'resumes') ? 'Search Talents' : 'Search Jobs'

        let childrenProps = {
            keyWordPlaceholder: keyWordPlaceholder,
            //locationSuggestions: this.state.locationSuggestions,
            queryString: newQueryString || '',
            locationText: newLLocationText || '',
            setQueryString: this.setQueryString,
            setLocationObj: this.setLocationObj,
            setLocationText: this.setLocationText,
            handleSearch: this.handleSearch,
            histogramType,
            email: newEmail || '',
            setEmail: this.setEmail,
        }

        if (this.props.landingPage){

        } else {
            if(this.props.view === 'MOBILE'){
                childrenProps.searchTitle = searchTitle
                childrenProps.top = this.props.top
            } else {
                childrenProps.top = 64
            }
        }

        return (
            <React.Fragment>
                {(this.props.landingPage)?<SearchJobsMain {...childrenProps}/>:
                <React.Fragment>
                    {(this.props.view === 'MOBILE')?
                        <SearchJobsMobile {...childrenProps}/>:
                        <SearchJobsDesktop {...childrenProps}/>
                    }
                </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser
    const { histogramType } = state.histogramData
    const {queryString, locationCode, locationObj} = state.searchJobs

    let searchType = histogramType
    if(histogramType !== 'jobs' && histogramType !== 'resumes' && histogramType !=='jobhuntUsers'){
        searchType = (isAuthed && userInfo.lookingforwork === false) ? 'resumes' : 'jobs'
    }

    let locationText = getLocationText(state)

    return {
        searchType, histogramType, queryString, locationText, locationCode, locationObj
    }
}

export default connect(mapStateToProps)(SearchJobs)