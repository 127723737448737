import { SET_HISTOGRAM_TYPE, SET_HISTOGRAM_SINGLE, SET_AUTO_REFRESH_LIST, SET_HIDDEN_LIST } from '../actions/histogram'

export function histogramData(
    state = {
			histogramType: undefined,
			histogramSubType: undefined,
			single: false,
			autoRefreshList: true,
			hiddenList: false
    }, action) {
	switch (action.type) {
		case SET_HISTOGRAM_TYPE:
			return Object.assign({}, state, {
				histogramType:action.histogramType,
				histogramSubType:action.histogramSubType,
				single:action.single
			})
		case SET_HISTOGRAM_SINGLE:
			return Object.assign({}, state, {
				single:action.single
			})
		case SET_AUTO_REFRESH_LIST:
			return Object.assign({}, state, {
				autoRefreshList:action.autoRefreshList
			})
		case SET_HIDDEN_LIST:
			return Object.assign({}, state, {
				hiddenList:action.hiddenList
			})
      	default:
        	return state
    }
}