import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDaysToNextSubscriptionPay, PESONAL_JOURNEY_STEPS } from '../../utils/utils';
import UserAvatar from '../../containers/UserAvatar';

export function HistogramPersonalJourney(props) {
    const { jobHuntUser, onClick, fetchingData } = props;

    const personal_journey = jobHuntUser

    const avatarSrc = jobHuntUser.avatar_url || undefined

    //const {badge, badgeDesc} = getJobBadge(jobHuntUser, 'profile')

    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const daysSincelLastPublish = personal_journey.lastPublishDate?Math.round((now - new Date(personal_journey.lastPublishDate))/oneDay):0
    //const daysSinceFirstDaySent = personal_journey.firstPartSent?Math.round((now - new Date(personal_journey.firstPartSent))/oneDay):0

    const lastCycle = (
        (personal_journey.numberOfCycles===5 && personal_journey.plan==='standard pro') || 
        (personal_journey.numberOfCycles===6 && personal_journey.plan==='standard executive')
    )

    const daysToNextPay = getDaysToNextSubscriptionPay(personal_journey.subscriptionStart);

    return (
        <div onClick={onClick}>
            <UserAvatar
                    positionAbsolute
                    firstName={jobHuntUser.first_name} 
                    lastName={jobHuntUser.last_name} 
                    avatarSrc={avatarSrc} 
                    backgroundColor={jobHuntUser.card_color}
                    margin 
                    fetchingData={fetchingData}/>
            <div className="clInfo">
                <div className="clTitle">
                    {(fetchingData)?<Skeleton variant="text" width={"90%"} animation="wave"/>:<>{personal_journey.title || ''}</>}
                </div>
                <div className="clCompany" style={{fontWeight:'bold'}}>
                    {(fetchingData)?<Skeleton variant="text" width={"60%"} animation="wave"/>:
                    <>{personal_journey.first_name || jobHuntUser.first_name || ''} {personal_journey.last_name || jobHuntUser.last_name || ''}</>}
                </div>
                <div className="clLocation" style={{textTransform: 'lowercase'}}>
                    {(fetchingData)?<Skeleton variant="text" width={"70%"} animation="wave"/>:<>{personal_journey.email || jobHuntUser.email || ''}</>}
                </div>
                {(!fetchingData && personal_journey.currentStep!==undefined)&&<div className="clCompany" style={{textTransform: 'capitalize'}}>
                    Step: {PESONAL_JOURNEY_STEPS[personal_journey.currentStep||0]}
                </div>}
                {(!fetchingData && personal_journey.status!==undefined)&&<div className="clLocation" style={{textTransform: 'capitalize'}}>
                    Status: {personal_journey.status}
                </div>}
                {(!fetchingData && personal_journey.plan!==undefined)&&<div className="clCompany" style={{textTransform: 'capitalize'}}>
                    Plan: {personal_journey.plan}
                </div>}
                {(!fetchingData && personal_journey.lastPublishDate!==undefined)&&<div className="clLocation" style={{textTransform: 'capitalize'}}>
                    Published {daysSincelLastPublish} days ago
                </div>}
                {(!fetchingData && daysToNextPay!==undefined && !personal_journey.isSubscriptionClosed 
                    && personal_journey.subscriptionStart!==undefined && personal_journey.subscriptionId!==undefined)
                    && daysToNextPay <= 7
                    &&<div className="clCompany" style={{textTransform: 'capitalize'}}>
                    Next Pay: <span style={{color:'#f44336',fontWeight:'bold'}}>In {daysToNextPay} days</span>
                </div>}
                {(!fetchingData && personal_journey.numberOfCycles!==0)&&<div className="clCompany" style={{textTransform: 'capitalize'}}>
                    <span>Cycles: {personal_journey.numberOfCycles}</span> {lastCycle&&<span style={{color:'#f44336',fontWeight:'bold'}}>Last Cycle</span>}
                    {personal_journey.numberOfCycles%3===0&&<span style={{color:'#f44336',fontWeight:'bold'}}> Journey Review</span>}
                </div>}
                {/*(badge)&&<div className="clJobMoreInfoSection">
                    <div className="clDateCreated jobMoreInfo ltr">
                        <span style={{color:"#ea7600"}} className="jobMoreInfoText">{badge}</span>&nbsp;
                        <span className="jobMoreInfoText">{badgeDesc}</span>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}