import React from 'react'
import Fab from '@material-ui/core/Fab';
import MailIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';

export class ContactButton extends React.Component{

    doAction = () => {
        this.props.onClick()
    }

    render(){
        const styling = this.props.style;
        const direction = this.props.direction;

        return (
            <Fab
                className={"contactButton " + direction}
                size="small"
                style={styling}
                color="primary"
                aria-label={this.props.ariatext}
                onClick={this.doAction}>
                {this.props.type === "email" && <MailIcon className="icon"/>}
                {this.props.type === "phone" && <PhoneIcon className="icon"/>}
            </Fab>
        )
    }
}
