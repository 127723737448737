import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';

import { ProfileActions } from '../profile/ProfileActions';

import '../../styles/Profile.css'
import ReadMore from '../ReadMore';
import Typography from '@material-ui/core/Typography';

export default class Description extends Component {
    state = {
        editMode: false
    }

    sectionFields = ['description'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })

        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false
        })

        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })

        this.props.changeOnEditCount(false)
    }

    getFields = (jobInfo) => {
        return (
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <TextField id="description" name="description" label="Job Description" margin="none" fullWidth 
                        required multiline rowsMax="20" value={jobInfo.description || ''} onChange={this.handleChange}/>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { jobInfo, noEdit, isNew, view } = this.props
        const searchWords = this.props.searchWords ||  []
        const {editMode} = this.state

        return (
            <React.Fragment>
                {(noEdit) ? 
                    <React.Fragment>
                        {(jobInfo.description)&&<ReadMore view={view} searchWords={searchWords}>{jobInfo.description}</ReadMore>}
                    </React.Fragment>:
                    <Paper className="profileSection" elevation={1}>
                        <div className="sectionTitle">Description</div>
                        <Typography className="sectionText">
                            <span>A good job description helps candidates answer three questions:</span><br/>
                            <span>1. What is the job? The roles responsibilities and how it fits into the rest of your organization</span><br/>
                            <span>2. Why should they want it? Exciting or challenging projects, what its like to work for you company, and any benefits or perks available</span><br/>
                            <span>3. Are they qualified? Skills and experience needed for success</span>
                        </Typography>
                        {(isNew) && this.getFields(jobInfo)}
                        {(editMode && !isNew) && 
                            <form onSubmit={this.handleSaveSection}>
                                {this.getFields(jobInfo)}
                                <div className="profileButtons">
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.handleCancel}
                                        aria-label="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        aria-label="Save Changes"
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </form>}
                        {(!editMode && !isNew) && 
                            <React.Fragment>
                                <ProfileActions handleEdit={this.handleEdit}/>
                                <div className="profileField">
                                    {(jobInfo.description)?
                                        <React.Fragment>{jobInfo.description}</React.Fragment>:
                                        <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Add an description</span> about a job.</React.Fragment>}
                                </div>   
                            </React.Fragment>}
                    </Paper>}
            </React.Fragment>
        )
    }
}