import React from 'react'
import HistApi from "../../utils/HistApi";
import { Typography, Divider, ListItem, ListItemAvatar, ListItemText, Button, Grid, makeStyles } from '@material-ui/core'
import { SquareChip, InterviewSection, months, ShareButtons } from './helpers';
import CompanyLogo from '../../containers/CompanyLogo';
import { getHost, filterUrl } from '../../utils/utils';
import ReportDialog from '../../containers/ReportDialog';

const gotOffers = {
    'No': {label:'No Offer', color:'red'},
    'Yes, but I declined': {label:'Declined Offer', color:'yellow'},
    'Yes, and I accepted': {label:'Accepted Offer', color:'green'}
}

const experienceByRatings = {
    1: {label:'Very Negative Experience', color:'red'},
    2: {label:'Negative Experience', color:'red'},
    3: {label:'Neutral Experience', color:'yellow'},
    4: {label:'Very Positive Experience', color:'green'},
    5: {label:'Very Positive Experience', color:'green'},
}

const difficulties = {
    'Very Easy': {label:'Very Easy', color:'green'},
    'Easy': {label:'Easy', color:'green'},
    'Average': {label:'Average', color:'yellow'},
    'Difficult': {label:'Difficult', color:'red'},
    'Very Difficult': {label:'Very Difficult', color:'red'}
}

const howGotInterview = {
    'Applied Online': {label:'I applied online'},
    'College or University': {label:'I applied through a college or university'},
    'Employee Referral': {label:'I applied through an employee referral'},
    'In-Person': {label:'I applied in-person'},
    'Recruiter': {label:'I applied through a recruiter'},
    'Staffing Agency': {label:'I applied through a staffing agency'},
}

const useStyles = makeStyles((theme) => ({
    left: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    right: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            textAlign: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
}));

export default function(props) {
    const {interview,userId,showLoginDialog,companyUrl} = props
    const classes = useStyles();

    const [helpful, setHelpful] = React.useState(interview.helpful||[]);

    const gotOffer = gotOffers[interview.gotOffer]
    const experienceByRating = experienceByRatings[interview.rating]
    const difficulty = difficulties[interview.difficulty]

    let applicationText = ''
    if (interview.howGotInterview && howGotInterview[interview.howGotInterview]){
        applicationText = applicationText + `${howGotInterview[interview.howGotInterview].label}. `
    }
    if (interview.processPeriod && interview.processPeriodLabel){
        applicationText = applicationText + `The process took ${interview.processPeriod} ${interview.processPeriodLabel}. `
    }
    if (interview.interviewMonth && interview.interviewYear && months[interview.interviewMonth-1]){
        applicationText = applicationText + `I interviewed at ${interview.company} in ${months[interview.interviewMonth-1]} ${interview.interviewYear}. `
    }
    
    const url = `${getHost()}/Interviews/Employee-Interview-${filterUrl(interview.company)}-${interview._id}`

    const dateCreated = new Date(interview.datecreated);

    const helpfulLength = helpful.length
    const isHelpful = helpful.some(e => e.userId === userId)

    const addHelpful = (id) => {
        if (!userId){
            return showLoginDialog()
        }
        
        HistApi.addHelpful({id, type:'interview'}, results => {
            setHelpful(results)
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={0} style={{marginTop:8}}>
                <Grid item xs={6}>
                    <Typography color="textSecondary" component="span" variant="body2">
                        {`${dateCreated.getDate()} ${months[dateCreated.getMonth()]}, ${dateCreated.getFullYear()}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    {(helpfulLength > 0)&&<Typography color="textSecondary" component="span" variant="body2">
                        {`Helpful (${helpfulLength})`}
                    </Typography>}
                </Grid>
            </Grid>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <CompanyLogo companyName={interview.company} companyUrl={companyUrl} className="cardLogo"/>
                </ListItemAvatar>
                <ListItemText
                disableTypography
                    primary={interview.title + ' Interview'}
                    secondary={
                        <div>
                            <Typography component="span" variant="body2" color="textPrimary">
                                {'Anonymous Employee' + (interview.location ? ' in ' + interview.location:'')}
                            </Typography>
                            <div>
                                {(gotOffer)&&<SquareChip label={gotOffer.label} color={gotOffer.color}/>}
                                {(experienceByRating)&&<SquareChip label={experienceByRating.label} color={experienceByRating.color}/>}
                                {(difficulty)&&<SquareChip label={difficulty.label} color={difficulty.color}/>}
                            </div>
                            {(applicationText)&&<InterviewSection label="Application" text={applicationText}/>}
                            <InterviewSection label="Interview" text={interview.interviewProcess}/>
                            {(interview.interviewQuestions && interview.interviewQuestions.length > 0)&&
                                <InterviewSection label="Interview Questions" questions={interview.interviewQuestions}/>}
                        </div>
                    }
                />
            </ListItem>
            <Grid container spacing={0} style={{marginBottom:8}}>
                <Grid item xs={12} sm={6} className={classes.left}>
                    <ShareButtons title={interview.title + ' Interview'} description={applicationText} url={url} 
                        emailText={`Read this review of ${interview.company} on JobSwipe. ${interview.title} Interview `}
                        emailSubject={`${interview.company} review on JobSwipe`}/>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.right}>
                    <Button color="primary" variant="outlined" size="small" disabled={isHelpful} onClick={()=>{addHelpful(interview._id)}}>
                        {(helpfulLength>0)?`Helpful (${helpfulLength})`:`Helpful`}
                    </Button>
                    <ReportDialog
                        type={"interview"}
                        objectId={interview._id}
                    />
                </Grid>
            </Grid>
            <Divider/>
        </React.Fragment>
    )
}