import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError((error.response)?error.response.data:error)
    });
}

const getSitemapWords = (onSuccess, onError = ()=>{}) => {
    post('getSitemapWords', null, onSuccess, onError)
}

const saveSitemapWords = (params ,onSuccess, onError = ()=>{}) => {
    post('saveSitemapWords', params, onSuccess, onError)
}

const getTemplates = (onSuccess, onError = ()=>{}) => {
    post('getTemplates', null, onSuccess, onError)
}

const previewTemplate = (params ,onSuccess, onError = ()=>{}) => {
    post('previewTemplate', params, onSuccess, onError)
}

const saveTemplate = (params ,onSuccess, onError = ()=>{}) => {
    post('saveTemplate', params, onSuccess, onError)
}

const getHtmlTemplates = (onSuccess, onError = ()=>{}) => {
    post('gethtmlTemplates', null, onSuccess, onError)
}

const previewHtmlTemplate = (params ,onSuccess, onError = ()=>{}) => {
    post('previewhtmlTemplate', params, onSuccess, onError)
}

const saveHtmlTemplate = (params ,onSuccess, onError = ()=>{}) => {
    post('savehtmlTemplate', params, onSuccess, onError)
}

const getReports = (onSuccess, onError = ()=>{}) => {
    post('getReports', null, onSuccess, onError)
}

const ignoreReport = (params ,onSuccess, onError = ()=>{}) => {
    post('ignoreReport', params, onSuccess, onError)
}

const deleteReportedUser = (params ,onSuccess, onError = ()=>{}) => {
    post('deleteReportedUser', params, onSuccess, onError)
}

const deleteReportedJob = (params ,onSuccess, onError = ()=>{}) => {
    post('deleteReportedJob', params, onSuccess, onError)
}

const deleteReportedObject = (params ,onSuccess, onError = ()=>{}) => {
    post('deleteReportedObject', params, onSuccess, onError)
}

const getPayments = (params, onSuccess, onError = ()=>{}) => {
    post('getPayments', params, onSuccess, onError)
}

const sendPushNotifications = (params, onSuccess, onError = () => {}) => {
    post('sendPushNotifications', params, onSuccess, onError)
}

const addPushNotificaitonHistory = (params, onSuccess, onError = () => {}) => {
    post('addPushNotificaitonHistory', params, onSuccess, onError)
}

const getPushNotificationHistory = (onSuccess, onError = () => {}) => {
    post('getPushNotificationHistory', null, onSuccess, onError)
}

const removePushNotificationHisotry = (params, onSuccess, onError = () => {}) => {
    post('removePushNotificationHisotry', params, onSuccess, onError)
}

const getAdminUserData = (params, onSuccess, onError = () => {}) => {
    post('getAdminUserData', params, onSuccess, onError)
}

const getAdminExtraUserInfo = (params, onSuccess, onError = () => {}) => {
    post('getAdminExtraUserInfo', params, onSuccess, onError)
}

const setAdminUserData = (params, onSuccess, onError = () => {}) => {
    post('setAdminUserData', params, onSuccess, onError)
}

const excludeUserByAdminAndGetUsers = (params, onSuccess, onError = () => {}) => {
    post('excludeUserByAdminAndGetUsers', params, onSuccess, onError)
}

const api = {
    getSitemapWords,
    saveSitemapWords,
    getTemplates,
    previewTemplate,
    saveTemplate,
    getHtmlTemplates,
    previewHtmlTemplate,
    saveHtmlTemplate,
    getReports,
    ignoreReport,
    deleteReportedUser,
    deleteReportedJob,
    deleteReportedObject,
    getPayments,
    sendPushNotifications,
    addPushNotificaitonHistory,
    getPushNotificationHistory,
    removePushNotificationHisotry,
    getAdminUserData,
    getAdminExtraUserInfo,
    setAdminUserData,
    excludeUserByAdminAndGetUsers
};
export default api;
