import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const login = userInfo => axios.post(`${SERVER_URL}/api/loginR`, userInfo);

const register = (params, onSuccess, onError = ()=>{}) => {
    post('registerR', params, onSuccess, onError)
}

const resetPassword = (params, onSuccess, onError = ()=>{}) => {
    post('reset_passwordR', params, onSuccess, onError)
}

const updateUserPassword = (params, onSuccess, onError = ()=>{}) => {
    post('update_user_password', params, onSuccess, onError)
}

const applyOrphan = (params, onSuccess, onError = ()=>{}) => {
    post('applyOrphan', params, onSuccess, onError)
}

const setPasswordOrphan = (params, onSuccess, onError = ()=>{}) => {
    post('setPasswordOrphan', params, onSuccess, onError)
}

const getUnregistedUserToken = (params, onSuccess, onError = ()=>{}) => {
    post('getUnregistedUserToken', params, onSuccess, onError)
}

const testPushNotification = (params, onSuccess, onError = ()=>{}) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/testPushNotification`, 
        headers: { authorization: `Bearer ${getToken()}` }, 
        data: params,
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const deleteAccount = (params, onSuccess, onError = ()=>{}) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/deleteAccount`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getUserData = (params, onSuccess, onError = ()=>{}) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/getUserData`, 
        headers: { authorization: `Bearer ${getToken()}` }, 
        data: params,
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const api = {
    register,
    login,
    getUserData,
    resetPassword,
    updateUserPassword,
    applyOrphan,
    setPasswordOrphan,
    getUnregistedUserToken,
    deleteAccount,
    testPushNotification
};
export default api;