import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import { FIELD_TYPES, capitalize, displayCash, showDateTimeFromString } from '../../../utils/utils';
import JHApi from "../../../utils/JHApi"

export default function SecondQuestionnaireDialog(props) {
    const showMidServiceQuestionnaire = props.showMidServiceQuestionnaire || false
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {}
    const isAdmin = props.isAdmin
    const userId = props.userId

    const [error, setError] = useState();
    const [newData, setNewData] = useState({});
    const [lastJob, setLastJob] = useState({});
    const [firstCheck, setFirstCheck] = useState();

    const disabled = isLoading

    useEffect(() => {       
        if (showMidServiceQuestionnaire){
            setFirstCheck(true)

            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            JHApi.getLastTalentJob(paramsToPass,(response)=>{
                setNewData({...journeyData.midServiceQuestionnaire||{}})
                setLastJob(response||{})
                setFirstCheck(false)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setFirstCheck(false)
            })
        }
    }, [showMidServiceQuestionnaire, journeyData, isAdmin, userId]);
    
    const handleCancel = () => {
        props.setShowMidServiceQuestionnaire(false)
    }

    const handleChangeField = (name, value) => {
        setNewData({...newData,[name]: value})
    };

    const handleChange = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        let paramsToPass = {data:{midServiceQuestionnaire:{...newData, jobAnswered:true}}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.updatePersonalJourneyData(paramsToPass,(response)=>{
            props.postServerCall(null, response)
            props.setShowMidServiceQuestionnaire(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleLastJobAnswer = useThisJob => {
        if (useThisJob){
            setNewData({...lastJob, jobAnswered:true})
        } else {
            setNewData({jobAnswered:true})
        }
    }

    const statuses = [
        {value: "Employed", label:"Employed"},
        {value: "Not employed", label:"Not employed"},
    ]

    const fields = [
        {name:'status', label:'Current job status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, required:true, sm:12},
        {name:'title', label:'Job title', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'company', label:'Company', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'location', label:'Location', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'salary', label:'Salary', curField:'salaryCurrency', freqField:'salaryFrequency', defaultValue: 0, type:FIELD_TYPES.CASH, sm:12},
        {name:'dateStart', label:'Start Date', defaultValue: '', type:FIELD_TYPES.DATE2, sm:12},
        {name:'description', label:'Clear Job Definition', defaultValue: '', type:FIELD_TYPES.MULTILINE, sm:12},
    ];

    const sectionFields = [
        {id:'title', label:'Job title', defaultValue: '', capitalize:true},
        {id:'company', label:'Company', defaultValue: '', capitalize:true},
        {id:'salary', label:'Salary', curField:'salaryCurrency', freqField:'salaryFrequency', defaultValue: 0, type:'SALARY'},
        {id:'dateStart', label:'Start Date', defaultValue: '', type:'DATE'},
        {id:'description', label:'Clear Job Definition', defaultValue: ''},
    ]

    return (
        <Dialog onClose={handleCancel} open={showMidServiceQuestionnaire} fullWidth maxWidth="sm">
            {!firstCheck&&<>
                {(lastJob._id&&!newData.jobAnswered)?<DialogContent>
                    <div>
                        <Typography component="span"><strong>Is this your last job?</strong></Typography>
                        <Button onClick={()=>{handleLastJobAnswer(true)}} style={{margin: '0 8px'}} variant='outlined' size='small' color='primary'>Yes</Button>
                        <Button onClick={()=>{handleLastJobAnswer(false)}} variant='outlined' size='small' color='primary'>No</Button>
                    </div>
                    {sectionFields.map((field, index) => {
                        let value = lastJob[field.id] === undefined ? '' : lastJob[field.id]

                        if(field.capitalize){
                            value = capitalize(value)
                        }

                        switch (field.type){
                            case 'DATE': {
                                value = showDateTimeFromString(value, false);
                                break;
                            } case 'DATE_TIME': {
                                value = showDateTimeFromString(value, true);
                                break;
                            } case 'SALARY': {
                                value = displayCash({value:value, currency:lastJob[field.curField], frequency:lastJob[field.freqField]})
                                break;
                            } default:{
                                
                            }
                        }

                        return (
                            <div key={field.id} style={{display:'flex', marginBottom: 8, alignItems:'center', paddingBottom: 8, width:'100%', borderBottom: '1px solid silver'}}>
                                <div style={{marginRight:8}}>
                                    <Typography color='primary' dir="auto" style={{textAlign:'left'}}><strong>{field.label}</strong></Typography>
                                    <Typography dir="auto" style={{textAlign:'left', whiteSpace: 'pre-line'}}>{value}</Typography>
                                </div>
                            </div>
                        )})}
                </DialogContent>:
                <form onSubmit={handleSave}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <Fields fields={fields} getValue={getValue} handleChange={handleChange} handleChangeField={handleChangeField}/>
                    </DialogContent>
                    <DialogActions>
                        <div style={{flex: "1 1 0%"}}></div>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>}
            </>}
        </Dialog>
    )
}