import React, { useState, useEffect, Fragment } from 'react';
import { AppBar, Button, Dialog, DialogContent, Divider, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { capitalize, dateAsString, FIELD_TYPES, getDateText, getInternationalPhone } from '../../utils/utils';
import EventApi from "../../utils/EventApi"
import { Fields } from '../Fields';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&.checkIn':{
        backgroundColor: '#fafa07'
      },
      '&.checkIn&:nth-of-type(odd)':{
        backgroundColor: '#e8e801'
      }
    },
  }))(TableRow);

export default function EventOccurence(props) {
    const isLoading = props.isLoading || false
    const showOccurenceDialog = props.showOccurenceDialog || false
    const occurence = props.selectedOccurence || {}
    const eventUrl = props.eventUrl || ''

    const [error, setError] = useState('');
    const [newOccurenceData, setNewOccurenceData] = useState({});
    const [fetchingUsers, setFetchingUsers] = useState(false);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [jobhuntSheelonClients, setJobhuntSheelonClients] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [fetchNeeded, setFetchNeeded] = useState(true);

    useEffect(() => {
        if(showOccurenceDialog && fetchNeeded){
            setFetchingUsers(true)
            setFetchNeeded(false)
            EventApi.getRegisteredUsers({occurence},(response)=>{
                setError('')
                setRegisteredUsers(response.registeredUsers)
                setJobhuntSheelonClients(response.jobhuntSheelonClients) 
                setFetchingUsers(false)
            }, (error) => {
                if (error && error.response && error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }

                setFetchingUsers(false)
            })
        }
    }, [showOccurenceDialog, occurence, fetchNeeded]);

    useEffect(() => {
        let secTimer = setInterval( () => {
            setFetchNeeded(true)
        },60000)

        return () => clearInterval(secTimer);
    }, []);

    const handleCheckOutEveryone = e => {
        setFetchingUsers(true)
        setFetchNeeded(false)
        EventApi.checkOutEveryone({occurence},(response)=>{
            setError('')
            setRegisteredUsers(response.registeredUsers)
            setJobhuntSheelonClients(response.jobhuntSheelonClients) 
            setFetchingUsers(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            setFetchingUsers(false)
        })
    }

    const handleChangeCheckIn = (id, checkIn) => () => {
        setFetchingUsers(true)
        setFetchNeeded(false)
        EventApi.changeCheckIn({id, checkIn, occurence},(response)=>{
            setError('')
            setRegisteredUsers(response.registeredUsers)
            setJobhuntSheelonClients(response.jobhuntSheelonClients) 
            setFetchingUsers(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            setFetchingUsers(false)
        })
    }

    const handleCloseOccurenceDialog = e => {
        setError('')
        setRegisteredUsers([])
        setFetchNeeded(true)
        props.handleCloseOccurenceDialog()
    }

    const getValue = (name, defaultValue) => {
        if (newOccurenceData[name] !== undefined){
            return newOccurenceData[name]
        } else if (occurence[name] !== undefined){
            return occurence[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setDataChanged(true)
        setNewOccurenceData({...newOccurenceData,[e.target.name]: e.target.value})
    };

    const handleCancelEdit = e => {
        setDataChanged(false)
        setNewOccurenceData({})
    }

    const handleSaveEdit = e => {
        e.preventDefault();

        props.preServerCall()

        EventApi.editEventOccurrence({occurence, newOccurenceData, eventUrl},(response)=>{
            setDataChanged(false)
            setNewOccurenceData({})

            props.postServerCall(response, {closeOccurenceDialog:true})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    var statuses = [
        {value: "D", label:"No"},
        {value: "A", label:"Yes"},
    ]

    const fields = [
        {name:'host', label:'Host', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true, inputType:'email'},
        {name:'googleMeetLink', label:'Google Meet Link', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'limit', label:'Limit', defaultValue: 50, type:FIELD_TYPES.TEXT, inputType:'number', sm:12, required:true},
        {name:'status', label:'Active', defaultValue: "A", options:statuses, type:FIELD_TYPES.SELECT, sm:12},
    ]

    const canceledUsers = registeredUsers.filter(registeredUser => registeredUser.status === 'D')
    const activeUsers = registeredUsers.filter(registeredUser => registeredUser.status === 'A')

    let withSheelonUsers = []
    let withoutSheelonUsers = []

    let csvContent = "";

    if (activeUsers.length>0){
        csvContent = "data:text/csv;charset=utf-8,";

        const CSVheaders = ["email", "firstName", "lastName", "phone"]
        const CSVSheelonHeader = ["timestamp", "email", "fullName", "phone", "city", "age",
            "howFindUs", "emptyH", "isWorking", "jCol", "seniority", "jobTitle", "whyLeave",
            "jobSearchMonths", "interviews", "waysJobSearch", "witchPositionsLookingFor", "searchIssues",
            "currentSalary", "emptyT", "searchGeoRenge", "dreamJob", "wishedSalary", "linkSource"]
        let first = true

        CSVheaders.forEach(function(CSVheader) {
            if(first){
                first = false
            } else {
                csvContent += ','
            }
            csvContent += CSVheader
        });
        CSVSheelonHeader.forEach(function(CSVheader) {
            if(first){
                first = false
            } else {
                csvContent += ','
            }
            csvContent += CSVheader
        });
        csvContent += '\n'

        activeUsers.forEach(function(registeredUser) {
            const sheelons = jobhuntSheelonClients.filter(jobhuntSheelonClient => registeredUser.email === jobhuntSheelonClient.email)

            if(sheelons.length > 0){
                withSheelonUsers.push(registeredUser)
            } else {
                withoutSheelonUsers.push(registeredUser)
            }

            first = true
            CSVheaders.forEach(function(CSVheader) {
                if(first){
                    first = false
                } else {
                    csvContent += ','
                }
                let cell = (registeredUser[CSVheader] !== undefined) ? registeredUser[CSVheader] : ''
                if (typeof cell === 'string' || cell instanceof String){
                    cell = cell.replaceAll('"', '""')
                }
                csvContent += '"' + cell + '"'
            });
            if(sheelons.length > 0){
                const sortedSheelons = sheelons.sort((a,b) => new Date(b.timestamp)-new Date(a.timestamp))
                const sheelon = sortedSheelons[0]

                CSVSheelonHeader.forEach(function(CSVheader) {
                    if(first){
                        first = false
                    } else {
                        csvContent += ','
                    }
                    let cell = (sheelon[CSVheader] !== undefined && sheelon[CSVheader] !== null) ? sheelon[CSVheader] : ''
                    if (typeof cell === 'string' || cell instanceof String){
                        cell = cell.replaceAll('"', '""')
                    }
                    csvContent += '"' + cell + '"'
                });
            }
            csvContent += '\n'
        });
    }

    const headCells = [
        /*{ id: 'email', align: "left", label: 'אימייל', sticky:true },
        { id: 'firstName', align: "right", label: 'שם פרטי', sticky:true },
        { id: 'lastName', align: "right", label: 'שם משפחה', sticky:true },*/
        { id: 'email', align: "left", label: 'אימייל' }, //להקטין
        { id: 'phone', align: "right", label: 'טלפון' }//להקטין
    ];

    const headSheelonCells = [
        { id: 'city', align: "right", label: 'עיר מגורים' },//להקטין
        { id: 'age', align: "right", label: 'גיל' },//להקטין
        { id: 'howFindUs', align: "right", label: "מי המליץ לך על השירות של ג'ובהאנט? איך שמעת עלינו?" , minWidth: 90},//להקטין קצת
        //{ id: 'emptyH', align: "right", label: '' },
        { id: 'isWorking', align: "right", label: `האם עובד/ת היום?` },//להקטין
        //{ id: 'jCol', align: "right", label: 'משמרות לא קשור לתחום מקום עבודה אחרון / נוכחי' },
        { id: 'seniority', align: "right", label: 'וותק בחברה' },//להקטין
        { id: 'jobTitle', align: "right", label: 'הגדרת תפקיד' , minWidth: 100},
        { id: 'whyLeave', align: "right", label: 'מדוע רוצה לעזוב / עזבת?', minWidth: 100},
        //{ id: 'jobSearchMonths', align: "right", label: 'jobSearchMonths' },
        { id: 'interviews', align: "right", label: 'בכמה ראיונות עבודה שונים היית עד היום?', minWidth:300 },//read  more //להקטין
        { id: 'waysJobSearch', align: "right", label: 'באילו דרכים חיפשת עבודה עד עכשיו?', minWidth: 100 }, 
        { id: 'witchPositionsLookingFor', align: "right", label: 'אילו משרות מחפש/ת? מה המטרות העיקריות?', minWidth:300 },
        { id: 'searchIssues', align: "right", label: 'מה לדעתך הקשיים העיקריים בחיפוש העבודה שלך? ', minWidth: 100 },
        { id: 'currentSalary', align: "right", label: 'מהו השכר הנוכחי?' },//להקטין
        //{ id: 'emptyT', align: "right", label: '' },
        { id: 'searchGeoRenge', align: "right", label: 'גבולות חיפוש גיאוגרפיים  ' },//להקטין
        { id: 'dreamJob', align: "right", label: 'אז מהי משרת החלומות שלך?' , minWidth:350},
        { id: 'wishedSalary', align: "right", label: 'ציפיות שכר?', minWidth: 100 },
        //{ id: 'linkSource', align: "right", label: 'linkSource' },
    ];

    return (
        <Dialog
            open={showOccurenceDialog}
            onClose={handleCloseOccurenceDialog}
            scroll="paper"
            fullScreen
        >
            {(occurence.eventId)&&<><AppBar position="static" color="primary">
                <Toolbar>
                    <IconButton disabled={isLoading || dataChanged} aria-label="close" color="inherit" onClick={handleCloseOccurenceDialog}>
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h5" color="inherit" style={{flex:1}}>
                        {dateAsString(new Date(occurence.date))} 15:00-16:00 Registered: {activeUsers.length}
                    </Typography>
                    {/*<IconButton disabled={isLoading} aria-label="Search" color="inherit" onClick={handleCloseOccurenceDialog}>
                        <SearchIcon/>
                    </IconButton>*/}
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                {(error)&&<Typography color="error">{error}</Typography>}
                {(activeUsers.length>0)&&<Paper style={{margin:8, position:'relative', overflow:'auto', height:'100%'}} elevation={1}>
                    <Table stickyHeader size="small">
                        <TableHead color='primary'>
                            <TableRow>
                                <StyledTableCell
                                    align='left'
                                    style={{fontWeight: 'bold', position: 'sticky',left: 0,zIndex: 9999, borderRight: '2px solid #e0e0e0'}}
                                >
                                    שם
                                </StyledTableCell>
                                {headCells.map(headCell => (
                                    <StyledTableCell
                                        key={headCell.id} 
                                        align={headCell.align}
                                        style={{minWidth:headCell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                                    >
                                        {headCell.label}
                                    </StyledTableCell>
                                ))}
                                {headSheelonCells.map(headCell => (
                                    <StyledTableCell
                                        key={headCell.id} 
                                        align={headCell.align} 
                                        style={{minWidth:headCell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                                    >
                                        {headCell.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registeredUsers.map(registeredUser => {
                                const sheelons = jobhuntSheelonClients.filter(jobhuntSheelonClient => registeredUser.email === jobhuntSheelonClient.email)
                                const sortedSheelons = sheelons.sort((a,b) => new Date(b.timestamp)-new Date(a.timestamp))
                                const sheelon = sortedSheelons[0] || {}

                                const internationalPhone = getInternationalPhone(registeredUser.phone || '')

                                const waLink = `https://api.whatsapp.com/send/?type=phone_number&phone=`+internationalPhone+
                                    `&text=Hello%20`+capitalize(registeredUser.firstName)+`!%20%F0%9F%8C%B8%0A%0AI%20saw%20you%20RSVP%20for%20today's%20webinar%20-%20we%20will%20start%20on%20time%20(15%3A00)%20at%20this%20link%3A%0A%0Ahttps%3A%2F%2Fjobswipe.co%2Fevents%2Fgoogle_meet%3Fid%3D` + registeredUser._id + `%26code%3D` +registeredUser.code +
                                    ((sheelon._id)?``:`%0A%0AMiriam%2C%20our%20COO%2C%20will%20be%20able%20to%20give%20better%20solutions%20to%20your%20job-hunting%20needs%20if%20you%20fill%20out%20the%20next%2C%20short%20questionnaire%0A%0Ahttps%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSdF82ljF1mjgGJpB6b12X1xpfjIia9LrgSYocNQ4iJ_ul0erQ%2Fviewform`) +
                                    `%0A%0AHave%20a%20great%20day!%0A%0AAneli`

                                return (
                                    <Fragment key={registeredUser._id}>
                                        {(registeredUser.status==='A')&&<StyledTableRow className={registeredUser.checkIn?'checkIn':''}>
                                            <TableCell
                                                align='left'
                                                style={{position: 'sticky',left: 0,zIndex: 2, color: '#fff', backgroundColor: '#396a92', borderRight: '2px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                                {registeredUser.firstName + ' ' + registeredUser.lastName}
                                            </TableCell>
                                            {headCells.map(headCell => (
                                                <TableCell
                                                    key={headCell.id} 
                                                    align={headCell.align} 
                                                    style={{borderRight: '1px solid #e0e0e0'}}
                                                    dir="auto"
                                                >
                                                    {registeredUser[headCell.id]} 
                                                    {headCell.id==='phone'&&<a target='_blank' rel="noopener noreferrer" href={waLink}>WA</a>}
                                                    {headCell.id==='email'&&<>
                                                        <div style={{cursor: 'pointer',textDecoration: 'underline',color: '#0000ee'}} onClick={handleChangeCheckIn(registeredUser._id, registeredUser.checkIn?false:true)}>{(registeredUser.checkIn)?'check out':'check in'}</div>
                                                    </>}
                                                </TableCell>
                                            ))}
                                            {headSheelonCells.map(headCell => (
                                                <TableCell
                                                    key={headCell.id} 
                                                    align={headCell.align} 
                                                    dir="auto"
                                                    style={{borderRight: '1px solid #e0e0e0', whiteSpace: 'pre-line'}}
                                                >
                                                    {sheelon[headCell.id]}
                                                </TableCell>
                                            ))}
                                        </StyledTableRow>}
                                    </Fragment>
                                )})}
                        </TableBody>
                    </Table>
                </Paper>}

                {(activeUsers.length>0)&&<Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                    <Button disabled={fetchingUsers} color="primary" variant='contained' onClick={handleCheckOutEveryone}>
                        Check Out Everyone
                    </Button>
                </Paper>}

                <Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                    <form onSubmit={handleSaveEdit}>
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                        <Button
                            color="primary"
                            size="small"
                            onClick={handleCancelEdit}
                            aria-label="Cancel"
                            disabled={isLoading || !dataChanged}
                            style={{marginTop:8, marginRight:8}}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save"
                            disabled={isLoading || !dataChanged}
                            style={{marginTop:8}}
                        >
                            Save
                        </Button>
                    </form>
                </Paper>

                <Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1}>
                    {(activeUsers.length>0)&&<Button disabled={fetchingUsers} href={encodeURI(csvContent)} download={"my_data.csv"} color="primary" variant='contained'>
                        Export
                    </Button>}
                    {(withoutSheelonUsers.length > 0)&&<List>
                        <Typography variant='h6'>Without Questionnaire: {withoutSheelonUsers.length}</Typography>
                        {withoutSheelonUsers.map((registeredUser, i) => <Fragment key={registeredUser._id}>
                            <ListItem>
                                <Typography>{registeredUser.firstName} {registeredUser.lastName} ({registeredUser.email}) ({registeredUser.phone}) {getDateText(registeredUser.datecreated)}</Typography>
                            </ListItem>
                            <Divider />
                        </Fragment>)}
                    </List>}
                    <List>
                        <Typography variant='h6'>With Questionnaire: {withSheelonUsers.length}</Typography>
                        {withSheelonUsers.map((registeredUser, i) => <Fragment key={registeredUser._id}>
                            <ListItem>
                                <Typography>{registeredUser.firstName} {registeredUser.lastName} ({registeredUser.email}) ({registeredUser.phone}) {getDateText(registeredUser.datecreated)}</Typography>
                            </ListItem>
                            <Divider />
                        </Fragment>)}
                    </List>
                    <List>
                        <Typography variant='h6'>Canceled</Typography>
                        {canceledUsers.map((registeredUser, i) => <Fragment key={registeredUser._id}>
                            <ListItem>
                                <Typography>{registeredUser.firstName} {registeredUser.lastName} ({registeredUser.email}) ({registeredUser.phone}) {getDateText(registeredUser.datecreated)} Reason:{registeredUser.cancelReason}</Typography>
                            </ListItem>
                            <Divider />
                        </Fragment>)}
                    </List>
                </Paper>
            </DialogContent></>}
        </Dialog>
    )
}