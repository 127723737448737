import React, { Component } from 'react'
import { connect } from 'react-redux'
import { JHReviews } from '../../components/JHReviews';
import { showDialog } from '../../redux/actions/MainDialog';
import HistogramC from '../HistogramC';

class JHReviewsPC extends Component {
    showMessageDialog = (title, content) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content))
    }

    render() {
        const { isAdmin, isJHAdmin } = this.props

        return (
            <React.Fragment>
                {(isAdmin || isJHAdmin)?
                    <JHReviews showMessageDialog={this.showMessageDialog}/>
                :<HistogramC histogramType={'main'} />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const isAdmin = (userInfo && userInfo.isAdmin)
    const isJHAdmin = (userInfo && userInfo.isJHAdmin)

    return {
        isAuthed, isAdmin, isJHAdmin
    }
}

export default connect(mapStateToProps)(JHReviewsPC)