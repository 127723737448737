import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState, Fragment } from 'react';
import { InterviewClasses } from '../../styles/InterviewClasses';
import { FIELD_TYPES, filterUrl, getHebrewDateText } from '../../utils/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Fields } from '../Fields';
import InterviewsApi from "../../utils/InterviewsApi"
import { Pagination } from '@material-ui/lab';

export default function InterviewList(props) {
    const isAuthed = props.isAuthed || false
    const userObjects = props.userObjects || []
    const urlParams = props.urlParams || ''
    const page = props.page || 1

    const searchParams = new URLSearchParams(window.location.search);

    const list = props.list || []
    const isLoading = props.isLoading || false

    const [showDialog, setShowDialog] = useState(searchParams.get("new")==='true');
    const [dialogData, setDialogData] = useState({});
    const [error, setError] = useState();
    const [mode, setmode] = useState('Add');

    const [randoms, setRandoms] = useState([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)]);

    const handleTopicClick = (topicID, title) => () => {
        props.changeUrl('/interviews/' + filterUrl(title) + '-' + topicID)
    }

    const handleCloseNewInterview = () => {
        setShowDialog(false);
    };

    const handleOpenNewInterview = () => {
        setRandoms([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)])

        if (mode !== 'Add'){
            setmode('Add')
            setDialogData({});
        }
        setError()
        setShowDialog(true);
    };

    const handleEditInterview = interview => () => {
        setmode('Edit')
        setDialogData(interview);
        setError()
        setShowDialog(true);
    }

    const handleDeleteInterview = interview => () => {
        setmode('Delete')
        setDialogData(interview);
        setError()
        setShowDialog(true);
    }

    const saveDialogInterview = e => {
        e.preventDefault() 

        switch (mode){
            case 'Add':{
                saveNewInterview();
                break;
            } case 'Edit':{
                saveEditInterview();
                break;
            } case 'Delete':{
                saveDeleteInterview();
                break;
            } default: {
                setmode('Add')
                setDialogData({});
                setShowDialog(false);
            }
        }
    }

    const saveNewInterview = () => {
        props.preServerCall()

        InterviewsApi.addInterview({urlParams, interview:dialogData, randoms, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            setRandoms([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)])
            setDialogData({...dialogData,validate: ''})

            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const saveEditInterview = () => {
        props.preServerCall()

        InterviewsApi.editInterview({urlParams, interview:dialogData, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const saveDeleteInterview = () => {
        props.preServerCall()

        InterviewsApi.deleteInterview({urlParams, interview:dialogData, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const handleChange = e => {
        setDialogData({...dialogData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (dialogData[name] !== undefined){
            return dialogData[name]
        } else {
            return defaultValue
        }
    }

    const fields = [
        {name:'title', label:'נושא', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'displayName', label:'שם להצגה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'content', label:'', defaultValue: '', variant:"outlined", type:FIELD_TYPES.MULTILINE, rows:6, rowsMax:6, sm:12, xs:12, required:true}
    ]

    const isDeleteDialog = (mode === 'Delete')    
    const question = 'איזה מספר יותר ' + (randoms[2]===0?'גדול ':'קטן ') + (randoms[0]) + ' או ' + ((randoms[1]===randoms[0])?randoms[1]+1:randoms[1])

    let hebrewMode = ''
    switch (mode) {
        case 'Add':{
            hebrewMode = 'הוסף'
            break;
        } case 'Edit':{
            hebrewMode = 'ערוך'
            break;
        } case 'Delete':{
            hebrewMode = 'מחק'
            break;
        } default:
            break;
    }

    const classes = InterviewClasses(); 

    return (
        <>
            <Dialog onClose={handleCloseNewInterview} open={showDialog} fullScreen className='hebrew-text'>
                <div style={{maxWidth:1200, width:'100%', margin:'0 auto'}}>
                <DialogTitle><span>{hebrewMode} ראיון</span></DialogTitle>
                <form onSubmit={saveDialogInterview}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        {(isDeleteDialog)?<Typography>למחוק את הראיון עם התגובות?</Typography>:
                        <Fields fields={fields} getValue={getValue} handleChange={handleChange}/>}
                        {(!isAuthed)&&<Grid container spacing={1}>
                            <Grid item sm={12} xs={12}>
                                <TextField name="validate" label={question} margin="none" fullWidth 
                                    required value={getValue('validate', '')} onChange={handleChange}
                                    helperText={'שאלה זו היא אמצעי למניעת שליחת טפסים אוטומטית על ידי ספאם בוטים.'} />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'flex-start', padding: '8px 24px'}}>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label={isDeleteDialog?'כן':'שמור'}
                        >
                            {isDeleteDialog?'כן':'שמור'}
                        </Button>
                        <Button disabled={isLoading} onClick={handleCloseNewInterview} color="primary" size="small" aria-label="Cancel">
                            ביטול
                        </Button>
                    </DialogActions>
                </form>
                </div>
            </Dialog>
            <div className={classes.paginationButtonContainer}>
                <div className={classes.paginationContainer}>
                    {props.paginationParams.show&&<Pagination {...props.paginationParams}/>}
                </div>
                <Button disabled={isLoading} onClick={handleOpenNewInterview} color="primary" variant="contained" aria-label="Add Interview">+ ראיון</Button>
            </div>
            <List className={classes.interviews}>
                <ListItem className={classes.interviewHeader}>
                    <Grid container spacing={2}>
                        <Grid item sm={7} xs={7}>
                            נושא
                        </Grid>
                        <Grid item sm={2} xs={2}>
                            תגובות
                        </Grid>
                        <Grid item sm={3} xs={3}>
                            תגובה אחרונה
                        </Grid>
                    </Grid>
                </ListItem>
                {list.map((interview, index) =>
                <Fragment key={interview._id}>
                    <ListItem disabled={isLoading} button onClick={handleTopicClick(interview._id, interview.title)}
                        className={clsx(classes.interview, (index%2===0)&&classes.odd, interview.sticky&&classes.sticky)}>
                        <Grid container spacing={2}>
                            <Grid item sm={7} xs={7}>
                                <div className='clTitle' dir="auto">{interview.title}</div>
                                <div className='clLocation'>ע"י: {interview.displayName}</div>
                            </Grid>
                            <Grid item sm={2} xs={2}>
                                <div className='clTitle'>{interview.repliesCount||0}</div>
                            </Grid>
                            {(!interview.sticky)&&<Grid item sm={3} xs={3}>
                                <div className='clLocation'>ע"י: {interview.lastReplyDisplayName || interview.displayName}</div>
                                <div className='clJobMoreInfoSection'>
                                    <div className='jobMoreInfoText'>{getHebrewDateText(interview.lastReplyDate || interview.datecreated)}</div>
                                </div>
                            </Grid>}
                        </Grid>
                    </ListItem>
                    {(userObjects.includes(interview._id))&&
                    <ListItem style={{borderTop:'none'}} className={clsx(classes.interview, (index%2===0)&&classes.odd, interview.sticky&&classes.sticky)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    style={{marginLeft:8}}
                                    variant="outlined"
                                    color="primary"
                                    size='small'
                                    onClick={handleEditInterview(interview)}
                                    startIcon={<EditIcon style={{marginLeft: 8, marginRight: -10}}/>}
                                >
                                    ערוך
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size='small'
                                    onClick={handleDeleteInterview(interview)}
                                    startIcon={<DeleteIcon style={{marginLeft: 8, marginRight: -10}}/>}
                                >
                                    מחק
                                </Button>
                            </Grid>
                        </Grid>
                    </ListItem>}
                </Fragment>)}
            </List>
        </>
    )
}