import React, { Component } from 'react'
import { connect } from 'react-redux'

import HistogramFooterLinks from '../components/HistogramFooterLinks'

import '../styles/HistogramFooter.css'
import Pro from './Pro'
import { NO_COMPANY } from '../utils/utils'
import { FILTER_COMPANY_TYPES } from '../redux/actions/filterJobs'

class HistogramFooter extends Component {
    render(){
        const {tags, companies, histogramType, lookingforwork} = this.props

        const HIST_TYPES = ['jobs', 'resumes'];
        const JOB_HIST_TYPES = ['jobs', 'myJobs', 'reports'];
        
        const showFooterLinks = HIST_TYPES.includes(histogramType)
        const showClearbitCredit = (JOB_HIST_TYPES.includes(histogramType) || (histogramType === 'activity' && lookingforwork))

        return (
            <React.Fragment>
                {(showFooterLinks)&&<div className="histogramFooter">
                    <HistogramFooterLinks label="People Also Searched:" links={tags} histogramType={histogramType}/>
                    <HistogramFooterLinks label="Top Companies:" links={companies} histogramType={histogramType}/>
                </div>}
                {(showClearbitCredit)&&<a style={{fontSize:12, padding:'8px 16px', color:'#396a92', textDecoration:'none'}} rel="noopener noreferrer" target="_blank" href="https://clearbit.com">Logos provided by Clearbit</a>}
                {(histogramType === 'profile')&&<Pro parent='profile'/>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { tags, company } = state.filterJobs;
    const histogramType = state.histogramData.histogramType
    const { isAuthed, userInfo } = state.authUser;

    const lookingforwork = (isAuthed) ? userInfo.lookingforwork : true;

    let filteredCompany = company.posible;
    var index = filteredCompany.indexOf(NO_COMPANY);
    if (index > -1) {
        filteredCompany.splice(index, 1);
    }

    filteredCompany = filteredCompany.filter(company => !FILTER_COMPANY_TYPES.includes(company))

    return {
        tags:tags.posible.slice(0, 10),
        companies:filteredCompany.slice(0, 10),
        histogramType,
        lookingforwork
    }
}

export default connect(mapStateToProps)(HistogramFooter)