import { REQUEST_JOBS, 
	RECEIVE_JOBS, 
	APPEND_JOBS, 
	SET_FILTERED_JOBS, 
	APPEND_FILTERED_JOBS,
	SET_PAGE, 
	CLEAR_OBJECTS} from '../actions/histogramJobs'

const initialState = {
	isFetching: false,
	jobs: [],
	noMore: false,
	chunk: 0,
	page: 1,
	filteredJobs: []
}

export function histogramJobs(state = initialState, action) {
	let noMore = false;

    switch (action.type) {
		case CLEAR_OBJECTS:
			return initialState
    	case REQUEST_JOBS:
			return Object.assign({}, state, {
				isFetching: true,
				chunk: action.chunk,
				noMore: false,
				jobs: action.clearJobs ? [] : state.jobs,
				filteredJobs: action.clearJobs ? [] : state.filteredJobs
			})
	  	case RECEIVE_JOBS:
			noMore = (action.noMore === undefined) ? (action.jobs.length < 600) : action.noMore
			
			return Object.assign({}, state, {
				isFetching: false,
				jobs: action.jobs,
				lastUpdated: action.receivedAt,
				noMore: noMore,
				total: action.total
			})
		case APPEND_JOBS:
			noMore = action.jobs.length < 600;

			return Object.assign({}, state, {
				isFetching: false,
				jobs: [...state.jobs, ...action.jobs],
				lastUpdated: action.receivedAt,
				noMore: noMore
			})
		case SET_FILTERED_JOBS:
			return Object.assign({}, state, {
				filteredJobs: action.filteredJobs
			})
		case APPEND_FILTERED_JOBS:
			return Object.assign({}, state, {
				filteredJobs: [...state.filteredJobs, ...action.filteredJobs],
			})
		case SET_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
      	default:
        	return state
    }
}