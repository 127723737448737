import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import JobCard from '../components/JobCard'
import JobCardDialog from '../components/dialogs/JobCardDialog';
import { getCompanyText, getNameLetters, mergeJobsTags, capitalizeArray } from '../utils/utils'
import { setEditMode, closeSelectedDialog, addSavedItem, removeSavedItem } from '../redux/actions/selectedJob';
import { toggleLoginDialog } from '../redux/actions/authActions';
import { showDialog } from '../redux/actions/MainDialog';
import { setHistogramWithDataNoReset } from '../redux/actions/histogramJobs';

class HistSelectedObject extends Component {
    setEditMode = (editMode) => {
        const { dispatch } = this.props
        dispatch(setEditMode(editMode))
    }

    onCloseDialog = () => {
        const { dispatch } = this.props
        dispatch(closeSelectedDialog())
    }

    showLogin = () => {
        const { dispatch } = this.props
        dispatch(toggleLoginDialog(true))
    }

    addSavedItem = () => {
        const { dispatch, job, histogramType } = this.props
        dispatch(addSavedItem(job._id, histogramType, job))
    }

    removeSavedItem = () => {
        const { dispatch, job, histogramType } = this.props
        dispatch(removeSavedItem(job._id, histogramType))
    }

    showMessageDialog = (title, content, pre) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content, pre))
    }

    updateHist = (data, id, edit = false) => {
        const {dispatch} = this.props
        dispatch(setHistogramWithDataNoReset(data, id, edit));
    }

    render() {
        const { isFetching, job, notFound, companies, histogramType, view, editMode, openDialog, lookingforwork, singleHist, isAuthed, userTags, savedItems, autoRefreshList } = this.props;
        const searchWords = this.props.searchWords ||  []
        const object = (histogramType === 'reports' && job.object)?job.object:job
        const company = (histogramType === 'companies') ? object.name : getCompanyText(object.company)
        //const name = (object.first_name && object.last_name)?object.first_name.charAt(0).toUpperCase() + object.last_name.charAt(0).toUpperCase():company
        const name = (object.first_name)?getNameLetters(object.first_name, object.last_name):company
        const selectedjobbgcolor = (companies[name])? companies[name].colors.dark:null;//:'#396a92';

        return (
            <Fragment>
                {(view === 'MOBILE')?
                <JobCardDialog
                    fullScreen={!notFound}
                    open={openDialog}
                    onClose={this.onCloseDialog}
                    job={job}
                    selectedjobbgcolor={selectedjobbgcolor}
                    isfetchingjob={isFetching}
                    view={view}
                    jobnotfound={notFound}
                    histogramType={histogramType}
                    editMode={editMode} 
                    setEditMode={this.setEditMode}
                    lookingforwork={lookingforwork}
                    singleHist={singleHist}
                    isAuthed={isAuthed}
                    showLogin={this.showLogin}
                    searchWords={searchWords}
                    userTags={userTags}
                    savedItems={savedItems}
                    removeSavedItem={this.removeSavedItem}
                    addSavedItem={this.addSavedItem}
                    showMessageDialog={this.showMessageDialog}
                    updateHist={this.updateHist}
                    autoRefreshList={autoRefreshList}
                /> :
                <JobCard 
                    job={job} 
                    isfetchingjob={isFetching} 
                    view={view} 
                    jobnotfound={notFound} 
                    histogramType={histogramType}
                    editMode={editMode}
                    setEditMode={this.setEditMode}
                    lookingforwork={lookingforwork}
                    singleHist={singleHist}
                    isAuthed={isAuthed}
                    showLogin={this.showLogin}
                    searchWords={searchWords}
                    userTags={userTags}
                    savedItems={savedItems}
                    removeSavedItem={this.removeSavedItem}
                    addSavedItem={this.addSavedItem}
                    showMessageDialog={this.showMessageDialog}
                    updateHist={this.updateHist}
                    autoRefreshList={autoRefreshList}
                />}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isFetching, job, notFound, editMode, openDialog } = state.selectedJob;
    const { histogramType, single, autoRefreshList } = state.histogramData
    const { companies, authUser } = state;
    const { isAuthed, userInfo, jobs, savedItems } = authUser;
    const lookingforwork = (isAuthed && userInfo.lookingforwork);
    let userTags = []
    const searchWords = []//queryString.split(' ')

    if (isAuthed && userInfo){
        userTags = (lookingforwork) ? userInfo.tags : mergeJobsTags(jobs)
        userTags = capitalizeArray(userTags)
    }

    return {
        isFetching, job, notFound, companies, histogramType, editMode, openDialog, lookingforwork, singleHist: single, isAuthed, searchWords, userTags, savedItems,
        autoRefreshList
    }
}

export default connect(mapStateToProps)(HistSelectedObject)