import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {createCompanyIfNeeded} from '../redux/actions/companies'
import Skeleton from '@material-ui/lab/Skeleton';

class CompanyLogo extends Component {
    state = {
        error: false
    };

    componentDidMount() {
        const { dispatch, companyName } = this.props
        dispatch(createCompanyIfNeeded(companyName))
    }

    componentDidUpdate(prevProps){
        if (prevProps.companyUrl !== this.props.companyUrl) {
            this.setState({
                error: false
            })
        }
    }

    addDefaultSrc(){
        this.setState({
            error: true
        })
    }

    render(){
        const {companies, companyName, companyUrl, className, fetchingData} = this.props
        
        return (
            <React.Fragment>
                {(fetchingData)?<Skeleton className="logoSkeleton" variant="rect" animation="wave"/>:
                    <React.Fragment>
                        {(companyUrl && !this.state.error)?<div className={className}>
                            <img alt={companyName} onError={() => this.addDefaultSrc()} src={"//logo.clearbit.com/" + companyUrl}/>
                        </div>:
                        <React.Fragment>
                            {companies[companyName] &&
                            <div className={className} style={{ backgroundColor: companies[companyName].colors.light }}>
                                <span>{companyName.charAt(0).toUpperCase()}</span>
                            </div>}
                        </React.Fragment>}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

CompanyLogo.propTypes = {
    //companies: PropTypes.object.isRequired,
    companyName: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    const { companies } = state;

    return {
        companies
    }
}

export default connect(mapStateToProps)(CompanyLogo)