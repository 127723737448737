import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

/*const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}*/

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getSynamediaClients = (params, onSuccess, onError = ()=>{}) => {
    post('getSynamediaClients', params, onSuccess, onError)
}

const getSynamediaUserPermissions = (params, onSuccess, onError = ()=>{}) => {
    post('getSynamediaUserPermissions', params, onSuccess, onError)
}

const approveSynamediaClient = (params, onSuccess, onError = ()=>{}) => {
    post('approveSynamediaClient', params, onSuccess, onError)
}

const updateSynamediaEmailsList = (params, onSuccess, onError = ()=>{}) => {
    post('updateSynamediaEmailsList', params, onSuccess, onError)
}

const api = {
    getSynamediaClients,
    getSynamediaUserPermissions,
    approveSynamediaClient,
    updateSynamediaEmailsList
};
export default api;