import { fetchSingle, clearHistogramSelected } from "./selectedJob";
import { clearHistogramObjects } from "./histogramJobs";
import { setSearchJobs } from "./searchJobs";
import { ALLOWED_HIST_TYPES, CANDIDATE_ACTIVITY_SUB_TYPES, RECRUITER_ACTIVITY_SUB_TYPES } from "../../utils/utils";
import { toggleActivityBadge } from "./authActions";

export const SET_HISTOGRAM_TYPE = 'SET_HISTOGRAM_TYPE'
export const SET_HISTOGRAM_SINGLE = 'SET_HISTOGRAM_SINGLE'
export const SET_AUTO_REFRESH_LIST = 'SET_AUTO_REFRESH_LIST'
export const SET_HIDDEN_LIST = 'SET_HIDDEN_LIST'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'

export function setHistogramType(histogramType, histogramSubType, single = false){
    return dispatch => {
        if (ALLOWED_HIST_TYPES.includes(histogramType)){
            dispatch({type: SET_HISTOGRAM_TYPE, histogramType, histogramSubType, single});
            dispatch(toggleActivityBadge(histogramSubType, false))
        } else {
            dispatch({type: SET_HISTOGRAM_TYPE, histogramType: 'main', histogramSubType:undefined, single});
        }
    }
}

export function setHistogramSingle(single = false){
    return dispatch => {
        dispatch({type: SET_HISTOGRAM_SINGLE, single});
    }
}

export function setFilterData(filterData = {}){
    return dispatch => {
        dispatch({type: SET_FILTER_DATA, filterData});
    }
}

export function setAutoRefreshList(autoRefreshList = true){
    return dispatch => {
        dispatch({type: SET_AUTO_REFRESH_LIST, autoRefreshList});
    }
}

export function setHiddenListList(hiddenList = false){
    return dispatch => {
        dispatch({type: SET_HIDDEN_LIST, hiddenList});
    }
}

const getValidSubType = (histogramType, subHistogramType, state) => {
    if (histogramType === 'activity' && state.authUser.isAuthed){
        const lookingforwork = state.authUser.userInfo.lookingforwork;

        const candidateIndex = CANDIDATE_ACTIVITY_SUB_TYPES.indexOf(subHistogramType);
        const recruiterIndex = RECRUITER_ACTIVITY_SUB_TYPES.indexOf(subHistogramType);
        
        if (candidateIndex === -1 && recruiterIndex === -1){
            if (lookingforwork){
                return CANDIDATE_ACTIVITY_SUB_TYPES[1]
            } else {
                return RECRUITER_ACTIVITY_SUB_TYPES[1]
            }
        } else {
            if (lookingforwork && candidateIndex === -1){
                return CANDIDATE_ACTIVITY_SUB_TYPES[recruiterIndex]
            } else if (!lookingforwork && recruiterIndex === -1){
                return RECRUITER_ACTIVITY_SUB_TYPES[candidateIndex]
            } else {
                return subHistogramType
            }
        }
    } else {
        return undefined
    }
}

const clearHistogram = () => {
    return dispatch => {
        dispatch(clearHistogramObjects())
        dispatch(clearHistogramSelected())
    }
}

export const removeHistogramType = () => (dispatch, getState) => {
    if (getState().histogramData.histogramType){
        dispatch(setHistogramType(null))
    }
}

export const initHistogram = (histogramType, isSingle, params, queryParams, hasPermission, subHistogramType) => (dispatch, getState) => {
    const validSubType = getValidSubType(histogramType, subHistogramType, getState())
    dispatch(clearHistogram())
    dispatch(setHistogramType(histogramType, validSubType, isSingle))
    
    if (histogramType !== 'main' && hasPermission){
        if(isSingle){
            dispatch(fetchSingle(params, queryParams))
        } else {
            dispatch(setSearchJobs(params, queryParams))
        }
    }
}