import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import '../../styles/Profile.css'

export function ProfileActions(props) {
    const { handleEdit, handleDelete } = props;

    return (
        <div className="profileActions">
            {(handleEdit)&&
            <IconButton aria-label="edit" className="editButton" onClick={handleEdit}>
                <EditIcon fontSize="small" />
            </IconButton>}
            {(handleDelete)&&
            <IconButton aria-label="delete" className="deleteButton" onClick={handleDelete}>
                <DeleteIcon fontSize="small" />
            </IconButton>    
            }
        </div>   
    )
}

/*export default class ProfileActions extends Component {
    render() {
        const { handleEdit, handleDelete } = this.props;
        
        return(
            <div className="profileActions">
                {(handleEdit)&&
                <IconButton aria-label="edit" className="editButton" onClick={handleEdit}>
                    <EditIcon fontSize="small" />
                </IconButton>}
                {(handleDelete)&&
                <IconButton aria-label="delete" className="deleteButton" onClick={handleDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>    
                }
            </div>   
        )
    }
}*/