import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { closeDialog, showDialog, showConfirmOrphanApply, showNewPassword, showPayment } from '../redux/actions/MainDialog';
import AlertDialog from '../components/dialogs/AlertDialog';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import DialogContent from '@material-ui/core/DialogContent';
import HistApi from "../utils/HistApi"
import '../styles/MainDialog.css'
import OrphanApplyDialog from '../components/dialogs/OrphanApplyDialog';
import ConfirmsOrphanApply from '../components/dialogs/ConfirmsOrphanApply';
import { toggleLoginDialog, updateUserInfo, socialAuthUser } from '../redux/actions/authActions';
import NewPasswordDialog from '../components/dialogs/NewPasswordDialog';
import PaymentDialog, { GET_CONTACTS } from '../components/dialogs/PaymentDialog';
import { getRedirectPath } from '../utils/utils';
import MatchDialog from '../components/dialogs/MatchDialog';
import DeleteAlertDialog from '../components/dialogs/DeleteAlertDialog';
import ConfirmPushNotificationDialog from '../components/dialogs/ConfirmPushNotificationDialog'
import { RemoveAlertToUser } from '../redux/actions/selectedJob';
import AdminApi from "../utils/AdminApi"
import SearchEnginesDialog from '../components/dialogs/SearchEnginesDialog';

class MainDialog extends Component {
    state={
        feedback: ''
    }

    componentDidMount() {
        if(window.location.hash && window.location.hash.indexOf('pick_job=true') !== -1){
            window.location.replace(window.location.hash.substring(1));
        }

        const searchParams = new URLSearchParams(window.location.search);
        const { dispatch } = this.props
        if (searchParams.get("payment")){
            dispatch(showPayment(null, '', 'url'))
        } else if (searchParams.get("confirmed")){
            dispatch(showDialog('Signup Confrimation', 'Your email has successfully confirmed.'))
        } else if (searchParams.get("confirmExpired")){
            dispatch(showDialog('Signup Confrimation', 'Cofirm signup link expired'))
        } else if (searchParams.get("emailChanged")){
            dispatch(showDialog('Email Change Confrimation', 'Your email has successfully changed.'))
        } else if (searchParams.get("emailChangeConfirmExpired")){
            dispatch(showDialog('Email Change Confrimation', 'Confirm link expired'))
        } else if (searchParams.get("invalidParams")){
            dispatch(showDialog('Unsubscribe', 'Invalid params for unsubscribe'))
        } else if (searchParams.get("invalidHash")){
            dispatch(showDialog('Unsubscribe', 'Invalid hash for unsubscribe'))
        } else if (searchParams.get("emailNotFound")){
            dispatch(showDialog('Unsubscribe', 'Unsubscribe error, no such email in system'))
        } else if (searchParams.get("notAdded")){
            dispatch(showDialog('Unsubscribe', "Can't add record to unsubscribed"))
        } else if (searchParams.get("unsubscribed")){
            dispatch(showDialog('Unsubscribe', 'Unsubscribed'))
        } else if (searchParams.get("newPassword")){
            if (searchParams.get("reset_pass_expired")){
                dispatch(showDialog('Reset password', 'Reset password link expired'))
            } else {
                if (searchParams.get("email") && searchParams.get("code")){
                    dispatch(showNewPassword(searchParams.get("email"), searchParams.get("code")))
                }
            }
        } else if (searchParams.get("proSuccess")){
            const redirectPath = localStorage.getItem("redirectPath") 
            if (redirectPath){
                localStorage.removeItem("redirectPath");
                this.props.dispatch(push(redirectPath+'?proSuccess=true'));
            } else {
                dispatch(showDialog('JobSwipe Pro', 'JobSwipe Pro is activated\nYou will receive an invoice to your email in a few minutes', true))
            }
        } else if (searchParams.get("proError")){
            const redirectPath = localStorage.getItem("redirectPath") 
            if (redirectPath){
                localStorage.removeItem("redirectPath");
                this.props.dispatch(push(redirectPath+'?proError=true'));
            } else {
                dispatch(showDialog('JobSwipe Pro', 'Something went wrong, please contact us by email payments@jobswipe.co'))
            }
        } else if (searchParams.get("startPersonalJourneyPublishExpired")){
            dispatch(showDialog('Approve Link', 'Approve link expired'))
        } else if (searchParams.get("startPersonalJourneyPublishSuccess")){
            dispatch(showDialog('Approve Link', 'Approved'))
        } else if (searchParams.get("startPersonalJourneyPublishError")){
            dispatch(showDialog('Approve Link Error', searchParams.get("startPersonalJourneyPublishError")))
        }

        if(searchParams.get("androidToken")) {
            localStorage.setItem("androiddevice",searchParams.get("androidToken"));
        }
    }

    onCloseDialog = () => {
        const { dispatch } = this.props
        dispatch(closeDialog())

        this.setState({
            feedback:''
        })
    }

    handleChange = event => {
        this.setState({
            feedback:event.target.value
        })
    };

    enableSearchEngines = () => {
        const { dispatch } = this.props
        HistApi.saveProfile({visibleSearchEngines:true}, (result)=>{
            dispatch(updateUserInfo({userInfo: result.userData}, true));
            this.onCloseDialog()
        }, error =>{
            if (error.response.data){
                dispatch(showDialog('Error', error.response.data))
            }
        })
    }

    sendFeedback = () => {
        const { dispatch } = this.props

        HistApi.sendFeedback({feedback:this.state.feedback},(data)=>{
            this.onCloseDialog()
            dispatch(showDialog('Feedback', 'Thank you for your feedback'))
        }, (error) => {
            dispatch(showDialog('Error', 'Error occurred'))
        })
    }

    handleSignIn = (section = 'signIn') => {
		const { dispatch } = this.props
		dispatch(closeDialog())
        dispatch(toggleLoginDialog(true, section))
    }

    showMessage = (title, content) => {
        const { dispatch } = this.props
        dispatch(showDialog(title, content))
    }

    showConfirmOrphanApply = () => {
        const { dispatch } = this.props
        dispatch(showConfirmOrphanApply())
    }

    handelLoginUser = token =>{
        this.props.dispatch(socialAuthUser(token, () => {
            this.onCloseDialog();
            this.props.dispatch(push('/'));
        }))
    }

    deleteAlert = () => {
        const {dispatch, alertId, alertHistogramType} = this.props
        dispatch(RemoveAlertToUser(alertId, alertHistogramType))
        this.onCloseDialog()
    }

    sendNotification = () => {
        const { dispatch, messageTitle, message, query, registeredUsers} = this.props

        AdminApi.sendPushNotifications({messageTitle, message, query, registeredUsers}, (res) => {
            dispatch(showDialog('Success', res))
        }, (error) => {
            dispatch(showDialog('Error', error))
        })
    }

    showPaymentDialog = () => {
        const { dispatch, contactType, userInfo, histogramType, histogramSubType } = this.props

        if (userInfo.lookingforwork){
            let fromGa = 'dialog'
            if (histogramType){
                if(histogramType === 'activity'){
                    fromGa = fromGa + '_' + histogramSubType
                } else {
                    fromGa = fromGa + '_' + histogramType
                }
            }

            dispatch(showPayment(GET_CONTACTS, (userInfo.lookingforwork)?'jobseeker':'recruiter', fromGa, contactType))
        }
    }

    render() {
        const { isShowed, modalType, title, content, selectedJob, email, mailRecieverData, code, featureIndex, isAuthed, userInfo, 
            loginChecked, pre, proButton, pathname, histogramType, showNotification, object } = this.props;
        const { feedback } = this.state

        const redirectPath = getRedirectPath(pathname, histogramType, selectedJob._id)

        return (
            <React.Fragment>
                {(modalType === 'dialog')&&<AlertDialog
                    open={isShowed}
                    onClose={this.onCloseDialog}
                    title={title}
                    content={content}
                    pre={pre}
                    proButton={proButton && userInfo.lookingforwork}
                    showPaymentDialog={this.showPaymentDialog}
                />}
                {(modalType === 'feedback')&&<Dialog      
                    classes={{ paper: "feedback-dialog-paper" }}          
                    open={isShowed}
                    onClose={this.onCloseDialog}
                    aria-labelledby="feedback-dialog-title">
                    {<DialogTitle id="feedback-dialog-title">Send feedback</DialogTitle>}
                    <DialogContent dividers>
                        <Input
                            className="feedback-text"
                            multiline
                            rows="6"
                            placeholder="Describe your issue or share your ideas"
                            disableUnderline
                            onChange={this.handleChange}
                            value={feedback}
                        />
                    </DialogContent>
                    <DialogActions className="feedback-actions">
                        <Button onClick={this.onCloseDialog} aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button onClick={this.sendFeedback} color="primary" aria-label="Send" disabled={(!feedback)}>
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>}
                {(modalType === 'snack')&&<Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={isShowed}
                    //TransitionComponent={<Slide direction="down" />}
                    autoHideDuration={6000}
                    onClose={this.onCloseDialog}
                    ContentProps={{'aria-describedby': 'message-id'}}
                    message={<span id="message-id">{content}</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />}
                {(modalType === 'orphanApply')&&<OrphanApplyDialog open={isShowed} onClose={this.onCloseDialog} job={selectedJob}
                    handleSignIn={this.handleSignIn} showConfirmOrphanApply={this.showConfirmOrphanApply}/>}
                {(modalType === 'confirmOrphanApply')&&<ConfirmsOrphanApply open={isShowed} onClose={this.onCloseDialog} 
                    handleSignIn={this.handleSignIn} showMessage={this.showMessage}/>}
                {(modalType === 'newPassword')&&<NewPasswordDialog
                    open={isShowed} 
                    onClose={this.onCloseDialog} 
                    handelLoginUser={this.handelLoginUser}
                    showDialog={this.showMessage}
                    email={email}
                    code={code}
                />}
                {(loginChecked && isAuthed && userInfo.lookingforwork && modalType === 'payment')&&<PaymentDialog redirectPath={redirectPath} open={isShowed} onClose={this.onCloseDialog} 
                    lookingforwork={userInfo.lookingforwork} featureIndex={featureIndex} />}
                {(modalType === 'match')&&<MatchDialog object={object} mailRecieverData={mailRecieverData} email={mailRecieverData.email} phone={mailRecieverData.phone} open={isShowed} onClose={this.onCloseDialog} showNotification={showNotification} userInfo={userInfo}/>}
                {(modalType === 'deleteAlert'&&<DeleteAlertDialog open={isShowed} onClose={this.onCloseDialog} deleteAlert={this.deleteAlert}/>)}
                {(modalType === 'confirmPushNotification'&&<ConfirmPushNotificationDialog open={isShowed} onClose={this.onCloseDialog} sendNotification={this.sendNotification}/>)}
                {(modalType === 'searchEngines')&&<SearchEnginesDialog open={isShowed} onClose={this.onCloseDialog} title={title} content={content} enableSearchEngines={this.enableSearchEngines}/>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isShowed, title, content, modalType, mailRecieverData, code, featureIndex, pre, proButton, contactType, showNotification, object, email, alertId, alertHistogramType, messageTitle, message, query, registeredUsers } = state.MainDialog;    
    const selectedJob = state.selectedJob.job;
    const { isAuthed, userInfo, loginChecked } = state.authUser;
    const pathname = state.router.location.pathname
    const {histogramType, histogramSubType} = state.histogramData    

    return {
        isShowed, title, content, modalType, selectedJob, mailRecieverData, email, code, featureIndex, isAuthed, userInfo, loginChecked, pre, proButton, contactType, pathname, histogramType, histogramSubType, showNotification, object, alertId, alertHistogramType, messageTitle, message, query, registeredUsers
    }
}

export default connect(mapStateToProps)(MainDialog)