import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getPersonalJourneyData = (params, onSuccess, onError = ()=>{}) => {
    post('getPersonalJourneyData', params, onSuccess, onError)
}

const updatePersonalJourneyData = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyData', params, onSuccess, onError)
}

const finishPersonalJourneyStep = (params, onSuccess, onError = ()=>{}) => {
    post('finishPersonalJourneyStep', params, onSuccess, onError)
}

const addResume = (params, onSuccess, onError = ()=>{}) => {
    post('addResume', params, onSuccess, onError)
}

const updateResume = (params, onSuccess, onError = ()=>{}) => {
    post('updateResume', params, onSuccess, onError)
}

const uploadPublishCVFile = (params, onSuccess, onError = ()=>{}) => {
    post('uploadPublishCVFile', params, onSuccess, onError)
}

const convertGoogleDocsToDocx = (params, onSuccess, onError = ()=>{}) => {
    post('convertGoogleDocsToDocx', params, onSuccess, onError)
}

const convertGoogleDocsToPdf = (params, onSuccess, onError = ()=>{}) => {
    post('convertGoogleDocsToPdf', params, onSuccess, onError)
}

const addEditCoverLetter = (params, onSuccess, onError = ()=>{}) => {
    post('addEditCoverLetter', params, onSuccess, onError)
}

const sendPersonalJourneyTest = (params, onSuccess, onError = ()=>{}) => {
    post('sendPersonalJourneyTest', params, onSuccess, onError)
}

const startPersonalJourneyPublish = (params, onSuccess, onError = ()=>{}) => {
    post('startPersonalJourneyPublish', params, onSuccess, onError)
}

const updatePersonalJourneyPublish = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyPublish', params, onSuccess, onError)
}

const getInterviewsForUser = (params, onSuccess, onError = ()=>{}) => {
    post('getInterviewsForUser', params, onSuccess, onError)
}

const getTimelineDataForUser = (params, onSuccess, onError = ()=>{}) => {
    post('getTimelineDataForUser', params, onSuccess, onError)
}

const createEditTalentInterview = (params, onSuccess, onError = ()=>{}) => {
    post('createEditTalentInterview', params, onSuccess, onError)
}

const editTalentInterviews = (params, onSuccess, onError = ()=>{}) => {
    post('editTalentInterviews', params, onSuccess, onError)
}

const editTalentInterviewStatus = (params, onSuccess, onError = ()=>{}) => {
    post('editTalentInterviewStatus', params, onSuccess, onError)
}

const changePersonalJourneyManager = (params, onSuccess, onError = ()=>{}) => {
    post('changePersonalJourneyManager', params, onSuccess, onError)
}

const changePersonalJourneyStatus = (params, onSuccess, onError = ()=>{}) => {
    post('changePersonalJourneyStatus', params, onSuccess, onError)
}

const changePersonalJourneyPlan = (params, onSuccess, onError = ()=>{}) => {
    post('changePersonalJourneyPlan', params, onSuccess, onError)
}

const updatePersonalJourneyLink = (params, onSuccess, onError = ()=>{}) => {
    post('updatePersonalJourneyLink', params, onSuccess, onError)
}

const getQuestionnaire = (params, onSuccess, onError = ()=>{}) => {
    post('getQuestionnaire', params, onSuccess, onError)
}

const setQuestionnaireFieldAndComments = (params, onSuccess, onError = ()=>{}) => {
    post('setQuestionnaireFieldAndComments', params, onSuccess, onError)
}

const getTalentJobsAndOffers = (params, onSuccess, onError = ()=>{}) => {
    post('getTalentJobsAndOffers', params, onSuccess, onError)
}

const getTalentProjects = (params, onSuccess, onError = ()=>{}) => {
    post('getTalentProjects', params, onSuccess, onError)
}

const addTalentJob = (params, onSuccess, onError = ()=>{}) => {
    post('addTalentJob', params, onSuccess, onError)
}

const editDeleteTalentJob = (params, onSuccess, onError = ()=>{}) => {
    post('editDeleteTalentJob', params, onSuccess, onError)
}

const talentSignedOffer = (params, onSuccess, onError = ()=>{}) => {
    post('talentSignedOffer', params, onSuccess, onError)
}

const getCompaniesOptions = (params, onSuccess, onError = ()=>{}) => {
    post('getCompaniesOptions', params, onSuccess, onError)
}

const changeCycleRating = (params, onSuccess, onError = ()=>{}) => {
    post('changeCycleRating', params, onSuccess, onError)
}

const changeCycleComment = (params, onSuccess, onError = ()=>{}) => {
    post('changeCycleComment', params, onSuccess, onError)
}

const addEditInterviewComment = (params, onSuccess, onError = ()=>{}) => {
    post('addEditInterviewComment', params, onSuccess, onError)
}

const addEditInterviewCommentNew = (params, onSuccess, onError = ()=>{}) => {
    post('addEditInterviewCommentNew', params, onSuccess, onError)
}

const createEditTalentOffer = (params, onSuccess, onError = ()=>{}) => {
    post('createEditTalentOffer', params, onSuccess, onError)
}

const createEditTalentProject = (params, onSuccess, onError = ()=>{}) => {
    post('createEditTalentProject', params, onSuccess, onError)
}

const getLastTalentJob = (params, onSuccess, onError = ()=>{}) => {
    post('getLastTalentJob', params, onSuccess, onError)
}

const getTitlesOptions = (params, onSuccess, onError = ()=>{}) => {
    post('getTitlesOptions', params, onSuccess, onError)
}

const api = {
    getPersonalJourneyData,
    updatePersonalJourneyData,
    finishPersonalJourneyStep,
    addResume,
    updateResume,
    uploadPublishCVFile,
    convertGoogleDocsToDocx,
    convertGoogleDocsToPdf,
    addEditCoverLetter,
    sendPersonalJourneyTest,
    startPersonalJourneyPublish,
    updatePersonalJourneyPublish,
    createEditTalentInterview,
    editTalentInterviews,
    editTalentInterviewStatus,
    getInterviewsForUser,
    getTimelineDataForUser,
    changePersonalJourneyManager,
    changePersonalJourneyStatus,
    changePersonalJourneyPlan,
    updatePersonalJourneyLink,
    getQuestionnaire,
    setQuestionnaireFieldAndComments,
    getTalentJobsAndOffers,
    getTalentProjects,
    createEditTalentOffer,
    addTalentJob,
    editDeleteTalentJob,
    talentSignedOffer,
    getCompaniesOptions,
    changeCycleRating,
    changeCycleComment,
    addEditInterviewComment,
    addEditInterviewCommentNew,
    createEditTalentProject,
    getLastTalentJob,
    getTitlesOptions
};
export default api;