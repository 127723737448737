import React, { Component } from 'react'

import { getDateText, getemploymentTypeText, getlocationText, getJobBadge } from '../utils/utils'
import UserAvatar from '../containers/UserAvatar'
import AccessTime from '@material-ui/icons/AccessTime';
import Work from '@material-ui/icons/Work';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import '../styles/ObjectOnHist.css'
import Skeleton from '@material-ui/lab/Skeleton';

export default class HistogramResume extends Component {
    render() {
        const { resume, onClick, isApplied, histogramSubType, userJobs, lookingforwork, isAuthed, isProfile, fetchingData } = this.props;

		const location = getlocationText(resume.location)
        const employmentType = getemploymentTypeText(resume.employmentType);
        const dateText = getDateText(resume.lastActive || resume.updated);
        const avatarSrc = (resume.avatar && resume.avatar.url) ? resume.avatar.url : undefined

        let job
        if (resume.votedjobid && userJobs && userJobs.length > 0){
            job = userJobs.find(userJob => userJob._id === (resume.votedjobid));  
        }

        const {badge, badgeDesc} = getJobBadge(resume, 'profile')

        return (
            <React.Fragment>
                {(!fetchingData && !lookingforwork && isAuthed && !isProfile)&&<div>
                        {this.props.isBookedmark ? <BookmarkIcon onClick={this.props.removeSavedItem} className="bookmarkIcon" style={{color: '#396a92'}}/> :
                        <TurnedInNotIcon onClick={this.props.addSavedItem} className="bookmarkIcon" style={{color: 'rgba(0,0,0,.54)'}}/>}
                </div>}
                <div onClick={onClick}>
                    <UserAvatar 
                        positionAbsolute
                        firstName={resume.first_name} 
                        lastName={resume.last_name} 
                        avatarSrc={avatarSrc} 
                        backgroundColor={resume.card_color}
                        margin 
                        fetchingData={fetchingData}/>
                    <div className="clInfo">
                        <div className="clTitle">
                            {(fetchingData)?<Skeleton variant="text" width={"90%"} animation="wave"/>:<React.Fragment>{resume.title}</React.Fragment>}
                        </div>
                        <div className="clCompany">
                            {(fetchingData)?<Skeleton variant="text" width={"60%"} animation="wave"/>:
                            <React.Fragment>{resume.first_name + ((resume.last_name) ? ' ' + resume.last_name:'')}</React.Fragment>}
                        </div>
                        <div className="clLocation">
                            {(fetchingData)?<Skeleton variant="text" width={"70%"} animation="wave"/>:<React.Fragment>{location}</React.Fragment>}
                        </div>
                        <div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr">
                                <span className="jobMoreInfoIcon">
                                    {(fetchingData)?<Skeleton variant="circle" width={18} height={18} animation="wave"/>:<AccessTime style={{ fontSize: 18 }}/>}
                                </span>
                                {(fetchingData)?<Skeleton variant="text" width={60} animation="wave" style={{display:"inline-block"}}/>:<React.Fragment>{dateText}</React.Fragment>}
                            </div>
                            <div className="clJobType jobMoreInfo ltr">
                                <span className="jobMoreInfoIcon">
                                    {(fetchingData)?<Skeleton variant="circle" width={18} height={18} animation="wave"/>:<Work style={{ fontSize: 18 }}/>}
                                </span>
                                {(fetchingData)?<Skeleton variant="text" width={50} animation="wave" style={{display:"inline-block"}}/>:<React.Fragment>{employmentType}</React.Fragment>}
                            </div>
                        </div>
                        {(badge)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr">
                                <span style={{color:"#ea7600"}} className="jobMoreInfoText">{badge}</span>&nbsp;
                                <span className="jobMoreInfoText">{badgeDesc}</span>
                            </div>
                        </div>}
                        {(isApplied && histogramSubType)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">{(histogramSubType === 'matches') ? 'Matched' : 'Connect Sent'}{(resume.date)? ' ' + getDateText(resume.date):''}</span>
                            </div>
                        </div>}
                        {(!isApplied && resume.date)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">Applied{(resume.date)? ' ' + getDateText(resume.date):''}</span>
                                {(job)&&<span>{" for " + job.title}</span>}
                            </div>
                        </div>}
                        {(isApplied && resume.connects && resume.itemType)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">{(resume.email !== undefined) ? 'Matched' : 'Connect Sent'}{(resume.connects.date)? ' ' + getDateText(resume.connects.date):''}</span>
                            </div>
                        </div>}
                        {(!isApplied && resume.itemType && resume.connects)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">Applied{(resume.connects)? ' ' + getDateText(resume.connects.date):''}</span>
                            </div>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}