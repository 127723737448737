import React from 'react'
import CompanyLogo from '../../containers/CompanyLogo';
import CardContentInterview from './CardContentInterview';
import CardContentReview from './CardContentReview';
import CardActionButton from '../../containers/CardActionButton';
import '../../styles/JobCard.css';
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { ReviewRating } from './helpers';

export function HistogramReview (props){
    const {review} = props

    let employeeText = review.type + ' Employee'
    if (review.title || review.location){
        employeeText = employeeText + ' -'
    }
    if (review.title){
        employeeText = employeeText + ' ' + review.title
    }
    if (review.location){
        employeeText = employeeText + ' in ' + review.location
    }

    return (
        <div onClick={props.onClick}>
            <ListItem alignItems="flex-start" component="div">
                <ListItemAvatar>
                    <CompanyLogo companyName={review.company} companyUrl={''} className="cardLogo"/>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={review.title + ' Review at ' + review.company}
                    secondary={
                        <div>
                            <ReviewRating review={review}/>
                            <Typography component="span" variant="body2" color="textPrimary">
                                {employeeText}
                            </Typography>
                        </div>
                    }
                />
            </ListItem>
        </div>
    )
}

export function HistogramInterview (props){
    const {interview} = props
    
    return (
        <div onClick={props.onClick}>
            <ListItem alignItems="flex-start" component="div">
                <ListItemAvatar>
                    <CompanyLogo companyName={interview.company} companyUrl={''} className="cardLogo"/>
                </ListItemAvatar>
                <ListItemText
                disableTypography
                    primary={interview.title + ' Interview at ' + interview.company}
                    secondary={
                        <div>
                            <ReviewRating review={interview}/>
                            <Typography component="span" variant="body2" color="textPrimary">
                                {'Anonymous Employee' + (interview.location ? ' in ' + interview.location:'')}
                            </Typography>
                        </div>
                    }
                />
            </ListItem>
        </div>
    )
}

export function ReportRevieCardContent (props){
    const {object, type, selectedjobbgcolor, applyOnTop, view} = props

    const applyButtonColor = (view === 'MOBILE') ? selectedjobbgcolor : 'primary'

    return (
        <div className={"card ltr"}>
            <div className="cardFooter">
                <CardActionButton selectedjobbgcolor={applyButtonColor} applyOnTop={applyOnTop} type={type} company={object.company}/>
                {(type === 'interview')&&<CardContentInterview interview={object}/>}
                {(type === 'review')&&<CardContentReview review={object}/>}
            </div>
        </div>
    )
}