import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showDialog } from '../redux/actions/MainDialog'
import { push } from 'connected-react-router'
import { toggleLoginDialog } from '../redux/actions/authActions'

class GlobalContainer extends Component {
    showMessageDialog = (title, content) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content))
    }

    showLoginDialog = (section = 'signIn') => {
        const {dispatch} = this.props
        dispatch(toggleLoginDialog(true, section))
    }

    changeUrl = (newRoute) => {    
        const { dispatch } = this.props
        dispatch(push(newRoute));
    }

    render() {
        const { isAuthed, isAdmin, isJHAdmin, match } = this.props

        return (
            <>
                {React.cloneElement(this.props.children, { 
                    showMessageDialog: this.showMessageDialog, 
                    showLoginDialog: this.showLoginDialog,
                    changeUrl: this.changeUrl, 
                    isAuthed, isAdmin, isJHAdmin, match })}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const isAdmin = (userInfo && userInfo.isAdmin)
    const isJHAdmin = (userInfo && userInfo.isJHAdmin)

    return {
        isAuthed, isAdmin, isJHAdmin
    }
}

export default connect(mapStateToProps)(GlobalContainer)