import React, { Component } from 'react'
import ObjectOnHist from '../containers/ObjectOnHist'
//import CircularProgress from '@material-ui/core/CircularProgress';
import HistogramFooter from '../containers/HistogramFooter'
import Pagination from './Pagination';
import { ROWS_PER_PAGE } from '../utils/utils';
import '../styles/Histogram.css'
import HistogramHeader from '../containers/HistogramHeader';
import HistAlert from '../containers/HistAlert';
import { Divider } from '@material-ui/core';

export default class Histogram extends Component {
    handleScroll(event) {
        let scrollTop = event.target.scrollTop;

        this.props.handleScroll(scrollTop);
    }

    handlePageClick = (pageOffset) => {
        this.props.handlePageClick(pageOffset)
        this.scrollToTop()
    }

    scrollToTop = () => {
        this.histTop.scrollIntoView();
    }

    render() {
        const { histogramObjects, showJobCardDialog, view, page, hiddenList } = this.props;
        const isFetchingJobs = (this.props.isFetchingJobs) ? this.props.isFetchingJobs : false;

        const handleScroll = (view === 'MOBILE') ? (e) => this.handleScroll(e) : undefined;

        const lastPage = Math.ceil(histogramObjects.length / ROWS_PER_PAGE);
        const slicedJobs = histogramObjects.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE)

        const NotificationIndex = ((slicedJobs.length < 3) ? slicedJobs.length : 3) - 1

        return (
                <div className={"histogramSection" + (hiddenList?' hidden':'')} onScroll={handleScroll}>
                    <HistogramHeader view={view}/>
                    {(histogramObjects.length > 0) && <div>
                        <div ref={(el) => { this.histTop = el }}></div>
                        <ul className="cards">
                            {slicedJobs.map((histogramObject, index) => <React.Fragment key={histogramObject._id}>
                                <ObjectOnHist showJobCardDialog={showJobCardDialog} histogramObject={histogramObject}/>
                                {(index === NotificationIndex)&&<HistAlert/>}
                            </React.Fragment>)}
                            {(isFetchingJobs)&&<ObjectOnHist histogramObject={{}} fetchingData/>}
                        </ul>
                        {(lastPage > 1)&&<div className="pagesContainer">
                            <Pagination
                                offset={page}
                                pages={5}
                                pageChange={this.handlePageClick}
                                maxPage={lastPage}
                            />
                        </div>}
                        <HistogramFooter/>
                    </div>}
                    {(!isFetchingJobs&&histogramObjects.length === 0)&&<HistAlert/>}
                    {/*isFetchingJobs&&<div className="histogramSpinner"><CircularProgress/></div>*/}
                    {(isFetchingJobs && !(histogramObjects.length > 0))&&<ul className="cards">
                        <ObjectOnHist histogramObject={{}} fetchingData/>
                        <Divider/>
                    </ul>}
			    </div>
        )
    }
}