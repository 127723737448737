import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        textAlign: 'center',
        overflow: 'auto'
    },
}));

export default function JSPaper(props) {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={classes.paper} style={props.style}>
            {props.children}
        </Paper>
    )
}