import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

/*const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}*/

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getEventData = (params, onSuccess, onError = ()=>{}) => {
    post('getEventData', params, onSuccess, onError)
}

const registerToEvent = (params, onSuccess, onError = ()=>{}) => {
    post('registerToEvent', params, onSuccess, onError)
}

const getRegisteredUsers = (params, onSuccess, onError = ()=>{}) => {
    post('getRegisteredUsers', params, onSuccess, onError)
}

const cancelRegistrationToEvent = (params, onSuccess, onError = ()=>{}) => {
    post('cancelRegistrationToEvent', params, onSuccess, onError)
}

const editEventOccurrence = (params, onSuccess, onError = ()=>{}) => {
    post('editEventOccurrence', params, onSuccess, onError)
}

const checkOutEveryone = (params, onSuccess, onError = ()=>{}) => {
    post('checkOutEveryone', params, onSuccess, onError)
}

const changeCheckIn = (params, onSuccess, onError = ()=>{}) => {
    post('changeCheckIn', params, onSuccess, onError)
}

const api = {
    getEventData,
    registerToEvent,
    cancelRegistrationToEvent,
    getRegisteredUsers,
    editEventOccurrence,
    checkOutEveryone,
    changeCheckIn
};
export default api;