import React, { Component } from 'react'

import { getDateText, getCompanyText, getemploymentTypeText, getlocationText, getJobBadge } from '../utils/utils'
import CompanyLogo from '../containers/CompanyLogo'
import AccessTime from '@material-ui/icons/AccessTime';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Work from '@material-ui/icons/Work';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import '../styles/ObjectOnHist.css'
import Button from '@material-ui/core/Button';
import { ConfirmDialog } from './dialogs/ConfirmDialog';
import BlockIcon from '@material-ui/icons/Block';

export default class HistogramJob extends Component {
    state = {
        openConfirmDialog: false,
    };
    
    handleOpenConfirmDialog = () => {
        this.setState({ openConfirmDialog: true });
    };
    
    handleCloseConfirmDialog = () => {
        this.setState({ openConfirmDialog: false });
    };

    handleActivate = () => {
        this.props.handleActivate()
    }

    handleDelete = () => {
        this.props.handleDelete()
    }

    handleDeactivate = () => {
        this.handleOpenConfirmDialog()
    }

    render() {
        const { job, histogramType, onClick, isApplied, histogramSubType, orphanApply, lookingforwork, isAuthed, isMyJobs, fetchingData } = this.props;

        const company = getCompanyText(job.company);
		const location = getlocationText(job.location)
        const employmentType = getemploymentTypeText(job.employmentType);
        const dateText = getDateText(job.datecreated);
        const expired = (job.jobstatus !== 'A' && !fetchingData && histogramType === 'jobs')

        const {badge, badgeDesc} = getJobBadge(job, 'job')

        return (
            <React.Fragment>
                <ConfirmDialog 
                    title="Warning" 
                    contentText="This will hide all your connects and matches for this job, Are you sure you want to continue?" 
                    open={this.state.openConfirmDialog} 
                    handleClose={this.handleCloseConfirmDialog}
                    handleAgree={this.props.handleDeactivate}/>
                {(!fetchingData && lookingforwork && isAuthed && !isMyJobs)&&<div>
                    {this.props.isBookedmark ? <BookmarkIcon onClick={this.props.removeSavedItem} className="bookmarkIcon" style={{color: '#396a92'}}/> :
                    <TurnedInNotIcon onClick={this.props.addSavedItem} className="bookmarkIcon" style={{color: 'rgba(0,0,0,.54)'}}/>}
                </div>}
                <div onClick={onClick} className={(orphanApply)?'orphanApplyJob':''}>
                    <CompanyLogo companyName={company} companyUrl={job.company_url} className="clLogo" fetchingData={fetchingData}/>
                    <div className="clInfo">
                        <div className="clTitle">
                            {(fetchingData)?<Skeleton variant="text" width={"90%"} animation="wave"/>:<React.Fragment>{job.title}</React.Fragment>}
                        </div>
                        <div className="clCompany">
                            {(fetchingData)?<Skeleton variant="text" width={"60%"} animation="wave"/>:<React.Fragment>{company}</React.Fragment>}
                        </div>
                        <div className="clLocation">
                            {(fetchingData)?<Skeleton variant="text" width={"70%"} animation="wave"/>:<React.Fragment>{location}</React.Fragment>}
                        </div>
                        {(!orphanApply && !expired)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr">
                                <span className="jobMoreInfoIcon">
                                    {(fetchingData)?<Skeleton variant="circle" width={18} height={18} animation="wave"/>:<AccessTime style={{ fontSize: 18 }}/>}
                                </span>
                                <span className="jobMoreInfoText">
                                    {(fetchingData)?<Skeleton variant="text" width={60} animation="wave" style={{display:"inline-block"}}/>:<React.Fragment>{dateText}</React.Fragment>}
                                </span>
                            </div>
                            <div className="clJobType jobMoreInfo ltr">
                                <span className="jobMoreInfoIcon">
                                    {(fetchingData)?<Skeleton variant="circle" width={18} animation="wave" height={18}/>:<Work style={{ fontSize: 18 }}/>}
                                </span>
                                <span className="jobMoreInfoText">
                                    {(fetchingData)?<Skeleton variant="text" width={50} animation="wave" style={{display:"inline-block"}}/>:<React.Fragment>{employmentType}</React.Fragment>}
                                </span>
                            </div>
                        </div>}
                        {(badge && !fetchingData && !expired)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr">
                                <span style={{color:"#ea7600"}} className="jobMoreInfoText">{badge}</span>&nbsp;
                                <span className="jobMoreInfoText">{badgeDesc}</span>
                            </div>
                        </div>}
                        {(expired)&&<div className="clJobMoreInfoSection">
                            <div className="jobMoreInfo expired ltr">
                                <span className="jobMoreInfoIcon"><BlockIcon style={{ fontSize: 18 }}/></span>
                                <span className="jobMoreInfoText">Expired</span>
                            </div>
                        </div>}
                        {(isApplied && histogramSubType)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">{(histogramSubType === 'matches')?'Matched':'Applied'}{(job.date)? ' ' + getDateText(job.date):''}</span>
                            </div>
                        </div>}
                        {(!isApplied && job.date)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">Connect Sent{(job.date)? ' ' + getDateText(job.date):''}</span>
                            </div>
                        </div>}
                        {(isApplied && job.itemType)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">{(job.email !== undefined)?'Matched':'Applied'}{(job.connects)? ' ' + getDateText(job.connects.date):''}</span>
                            </div>
                        </div>}
                        {(!isApplied && job.itemType && job.connects)&&<div className="clJobMoreInfoSection">
                            <div className="clDateCreated jobMoreInfo ltr" style={{color:"green"}}>
                                <span className="jobMoreInfoIcon">
                                    <CheckCircleOutlinedIcon style={{ fontSize: 18 }}/>
                                </span>
                                <span className="jobMoreInfoText">Connect Sent{(job.connects)? ' ' + getDateText(job.connects.date):''}</span>
                            </div>
                        </div>}
                    </div>
                </div>
                {(histogramType === 'myJobs')&&
                <div className="listActions">
                    {(job.jobstatus === 'O')&&<React.Fragment>
                        <Button color="primary" size="small" onClick={this.handleActivate} aria-label="Activate">Activate</Button>
                        <Button color="primary" size="small" onClick={this.handleDelete} aria-label="Delete">Delete</Button>
                    </React.Fragment>}
                    {(job.jobstatus === 'A')&&<React.Fragment>
                        <Button color="primary" size="small" onClick={this.handleDeactivate} aria-label="Deactivate">Deactivate</Button>
                    </React.Fragment>}
                </div>}
            </React.Fragment>
        )
    }
}