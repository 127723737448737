import { PESONAL_JOURNEY_STEPS, PUBLISH_STEP_FILTERS, SENIORITIES_LIST } from '../../utils/utils'
import {INIT_FILTER, 
    ADD_TO_FILTER, 
    TOGGLE_FILTER, 
    CLEAR_FILTERED, 
    POSIBLE_LOCATIONS,
    POSIBLE_DATE_POSTED,
    POSIBLE_EMPLOYMENT_TYPE,
    POSIBLE_SENIORITY_LEVEL,
    FILTER_COMPANY_TYPES,
    FILER_SET_COMPANY,
    FILTER_MANAGER,
    FILTER_STATUS,
    FILTER_PLAN,
    FILTER_NEED_ATTENTION,
    FILTER_OTHER,
    FILTER_TITLE} from '../actions/filterJobs'

function filterType(
    state = {
        posible:[], 
        filtered:[],
        params:{}
    },
    action
) {
    switch (action.type) {
        case ADD_TO_FILTER:
            return Object.assign({}, state, {
                posible: [...state.posible, action.filterValue],
            })
        case TOGGLE_FILTER:
            if(action.singleValue){
                return Object.assign({}, state, {
                    filtered: [action.filterValue],
                    params: action.params || state.params
                })
            }
            if (state.filtered.includes(action.filterValue)){
                return Object.assign({}, state, {
                    filtered: state.filtered.filter(item => {
                        return item !== action.filterValue
                    })
                })
            } else {
                return Object.assign({}, state, {
                    filtered: [...state.filtered, action.filterValue],
                })
            }
        case CLEAR_FILTERED:
            return Object.assign({}, state, {
                filtered: [],
            })
        default:
            return state
    }
}
export function filterJobs(
    state = {tags:{posible:[], filtered:[]},
            company:{posible:[...FILTER_COMPANY_TYPES], filtered:[]},
            'employment type':{posible:POSIBLE_EMPLOYMENT_TYPE, filtered:[]},
            'date posted':{posible:POSIBLE_DATE_POSTED, filtered:[]},
            'updated':{posible:POSIBLE_DATE_POSTED, filtered:[]},
            'location':{posible:POSIBLE_LOCATIONS, filtered:[]},
            'seniority Level':{posible:POSIBLE_SENIORITY_LEVEL, filtered:[]},
            'step': {posible:PESONAL_JOURNEY_STEPS, filtered:[]},
            'publish step': {posible:PUBLISH_STEP_FILTERS, filtered:[]},
            'manager': {posible:FILTER_MANAGER, filtered:[]},
            'title': {posible:FILTER_TITLE, filtered:[]},
            'status': {posible:FILTER_STATUS, filtered:[]},
            'plan': {posible:FILTER_PLAN, filtered:[]},
            'need attention': {posible:FILTER_NEED_ATTENTION, filtered:[]},
            'seniority': {posible:['not selected', ...SENIORITIES_LIST], filtered:[]},
            'other': {posible:FILTER_OTHER, filtered:[]}
        }, action
) {
    switch (action.type) {
        case INIT_FILTER:
            return action.filter
        case ADD_TO_FILTER:
            return Object.assign({}, state, {
                [action.filterType]: filterType(state[action.filterType], action)
            })
        case TOGGLE_FILTER:
            return Object.assign({}, state, {
                [action.filterType]: filterType(state[action.filterType], action)
            })
        case CLEAR_FILTERED:
            if (action.filterType){
                return Object.assign({}, state, {
                    [action.filterType]: filterType(state[action.filterType], action)
                })
            } else {        
                return Object.assign({}, state, {
                    'tags': filterType(state['tags'], action),
                    'company': filterType(state['company'], action),
                    'employment type': filterType(state['employment type'], action),
                    'location': filterType(state['location'], action),
                    'date posted': filterType(state['date posted'], action),
                    'updated': filterType(state['updated'], action),
                    'seniority Level': filterType(state['seniority Level'], action),
                    'step': filterType(state['step'], action),
                    'publish step': filterType(state['publish step'], action),
                    'manager': filterType(state['manager'], action),
                    'title': filterType(state['title'], action),
                    'status': filterType(state['status'], action),
                    'plan': filterType(state['plan'], action),
                    'need attention': filterType(state['need attention'], action),
                    'seniority': filterType(state['seniority'], action),
                    'other': filterType(state['other'], action),
                });
            }
        case FILER_SET_COMPANY:
            return Object.assign({}, state, {
                companyText: action.companyText,
            })
        default:
            return state
    }
}