import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'
import HistApi from "../../utils/HistApi";

export default function(props) {
    const [company, setCompany] = React.useState('');
    const [error, setError] = React.useState('');

    const handleChange = (event) => {
        setCompany(event.target.value);
    }

    const onClose = () => {
        setCompany('');
        setError('')
        props.onClose()
    }

    const handleSave = e => {
        e.preventDefault()

        HistApi.addCompany({company}, (results)=>{
            setCompany('');
            setError('')
            props.onClose(results) 
        }, (error) => {
            console.log('error')
            if (error && error.response && error.response.data && error.response.data.error){
                setError(error.response.data.error)
            } else {
                setError('Something went wrong')
            }
        })
    }

    return (
        <Dialog
                open={props.open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="paper"
            >
                <DialogTitle id="alert-dialog-title">Add Company</DialogTitle>
                <form onSubmit={handleSave}>
                    <DialogContent style={{paddingTop:0}}>
                        <TextField id="company" name="company" label="Company name" margin="none" fullWidth 
                            required value={company || ''} onChange={handleChange}/>
                        {(error && typeof error === 'string')&&<Typography color="error">{error}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Submit"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </form>
        </Dialog>
    )
}
