import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import { FIELD_TYPES, dateAsString } from '../../../utils/utils';
import AdminJHApi from "../../../utils/AdminJHApi"

export default function SafetyNet(props) {
    const journeyData = props.journeyData || {}
    const disabled = props.isLoading || false
    const style = props.style || {}
    const isAdmin = props.isAdmin
    const userId = props.userId

    const safetyNetExpireDate = journeyData.safetyNetExpireDate
    const safetyNetActive = journeyData.safetyNetActive || false

    const isSafetyNet = (safetyNetActive || safetyNetExpireDate >= new Date().toISOString().substring(0, 10))

    const [isEditValue, setIsEditValue] = useState(false);
    const [newData, setNewData] = useState({});
    const [error, setError] = useState();

    const handleEditSafetyNet = e => {
        setNewData({})
        setIsEditValue(true)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleChangeFinal = (name, value) => {
        setNewData({...newData,[name]: value})
    }

    const handleCancel = e => {
        setNewData({})
        setIsEditValue(false)
    }

    const handleSave = e => {
        e.preventDefault() 

        let paramsToPass = {newData:newData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.updateSafetyNet(paramsToPass,(response)=>{
            setNewData({})
            setIsEditValue(false)
            setError()

            props.postServerCall(null, response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (journeyData[name] !== undefined){
            return journeyData[name]
        } else {
            return defaultValue
        }
    }

    const today = new Date().toISOString().substring(0, 10)
    const safetyNetExpireDateF = getValue('safetyNetExpireDate','')
    const safetyNetActiveF = getValue('safetyNetActive',false)

    const canActivateSafetyNet = safetyNetActiveF || (safetyNetExpireDateF && safetyNetExpireDateF>=today)

    const fields = [
        {name:'safetyNetExpireDate', label:'Expire Date', defaultValue: '', type:FIELD_TYPES.DATE2},
        {name:'safetyNetActive', label:'Active', defaultValue: false, type:FIELD_TYPES.CHECK_BOX, disabled:!canActivateSafetyNet},
    ]

    return (
        <>
        <Dialog onClose={handleCancel} open={isEditValue}>
            <DialogTitle>Safety Net</DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(journeyData.safetyNetActivatingDate)&&<Typography style={{marginBottom:8}}>
                        Activated on: {dateAsString(new Date(journeyData.safetyNetActivatingDate))}
                    </Typography>}
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField} handleChangeFinal={handleChangeFinal}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
        <Chip
            size="small" 
            label={(isSafetyNet?'':'+ ') + 'Safety Net'}
            variant={safetyNetActive?'default':'outlined'}
            onClick={handleEditSafetyNet} 
            color="primary" 
            onDelete={handleEditSafetyNet} 
            deleteIcon={<EditIcon/>}
            disabled={disabled}
            style={style}
        />
        </>
    )
}