import React from 'react'
import { Chip, Typography, Popover, IconButton, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { red, yellow, green } from '@material-ui/core/colors/';
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, 
    TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon } from 'react-share';
import Rating from '@material-ui/lab/Rating';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from 'mdi-material-ui/LinkVariant'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        height: 14,
        width: 14,
        marginLeft: 8,
        borderRadius: 2,
    },
    yellow: {
        color: theme.palette.getContrastText(yellow[700]),
        backgroundColor: yellow[700],
        height: 14,
        width: 14,
        marginLeft: 8,
        borderRadius: 2,
    },
    green: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        height: 14,
        width: 14,
        marginLeft: 8,
        borderRadius: 2,
    },
    shareButtons: {
        display: 'flex',
    },
    shareButton: {
        boxSizing: 'border-box',
        cursor: 'pointer',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
        borderRadius: '50%',
        backgroundClip: 'padding-box',
        marginRight: 8
    },
    copyUrlBitton: {
        padding: 0,
        width: 36,
        height: 36,
        backgroundColor: "#396a92",
        color: "white",
        boxShadow: "0 2px 5px 0 rgba(0,0,0,.26) !important",
        '&:hover': {
            backgroundColor: "#396a92",
            color: "white",
        }
    },
    bottomContainer: {
        height:36, 
        marginBottom:8
    },
    bottomLeft: {
        float:'left',
        height:'inherit',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bottomRight: {
        float:'right', 
        height:'inherit',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rating: {
        width: 'fit-content',
        cursor: 'pointer'
    }
}));

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

function ColoredSquare(props) {
    const classes = useStyles(); 
  
    return (
        <i className={classes[props.color]}></i>
    );
}

function RatingSection(props){
    const {label, value} = props

    return (
        <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary">{label}</Typography><br/>
            <Rating value={value} precision={0.5} size="small" readOnly /><br/>
        </React.Fragment>
    )
}

export function ShareButtons(props) {
    const classes = useStyles(); 
    const {title, description, url, emailText, emailSubject} = props

    const [copySuccess, setCopySuccess] = React.useState('');

    const size = props.size || 36

    function copyToClipboard(e) {
        const textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopySuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setCopySuccess(false);
    };

    return (
        <React.Fragment>
            <div className={classes.shareButtons}>
                <FacebookShareButton className={classes.shareButton} url={url}>
                    <FacebookIcon round size={size}/>
                </FacebookShareButton>
                <LinkedinShareButton className={classes.shareButton} url={url} title={title} description={description}>
                    <LinkedinIcon round size={size}/>
                </LinkedinShareButton>
                <TwitterShareButton className={classes.shareButton} url={url} title={description}>
                    <TwitterIcon round size={size}/>
                </TwitterShareButton>
                <EmailShareButton className={classes.shareButton} subject={emailSubject} url={emailText + url}>
                    <EmailIcon round size={size}/>
                </EmailShareButton>
                <IconButton onClick={copyToClipboard} className={classes.copyUrlBitton} aria-label="copy url">
                    <LinkIcon size={size}/>
                </IconButton>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={copySuccess}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    message="Copied!"
                    action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                    }
                />
                {copySuccess}
            </div>
        </React.Fragment>
    )
}

export function SquareChip(props) {
    return (
        <Chip variant="outlined" size="small" label={props.label} style={{marginRight:8, marginTop:8}} avatar={<ColoredSquare color={props.color}/>} />
    )
}

export function InterviewSection(props) {
    return (
        <React.Fragment>
            {(props.label)&&<div style={{marginTop:8}}>
                <Typography color="textPrimary" component="span" variant="subtitle1">{props.label}</Typography>
            </div>}
            {(props.text)&&<div style={{marginTop:8}}>
                <Typography color="textSecondary" component="span" variant="body2">{props.text}</Typography>
            </div>}
            {(props.questions)&&<React.Fragment>
                {props.questions.map((question, index) => (
                    <React.Fragment key={index}>
                        <div style={{marginBottom:8}}>
                            <Typography color="textPrimary" component="span" variant="body2">{question.question}</Typography>
                        </div>
                        <div style={{marginBottom:8}}>
                            <Typography color="textSecondary" component="span" variant="body2">{question.answer}</Typography>
                        </div>
                    </React.Fragment>
                ))}
            </React.Fragment>}
        </React.Fragment>
    );
}

export function LeftRight (props) {
    const classes = useStyles(); 
    /*
    justify-content: center;
  align-items: center;
    */
    return (
        <div className={classes.bottomContainer}>
            <div className={classes.bottomLeft}>
                {props.left}
            </div>
            <div className={classes.bottomRight}>
                {props.right}
            </div>
        </div>
    )
}

export function ReviewRating (props){
    const classes = useStyles(); 
    const {review} = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const moreInfo = review.workLifeBalance || review.cultureValues || review.careerOpportunities || review.compensationsBenefits || review.seniorManagement

    return (
        <React.Fragment>
            <div className={classes.rating} onClick={handleClick}>
                <Rating value={review.rating} precision={0.5} size="small" readOnly/>
                {(moreInfo)&&<ExpandMoreIcon fontSize="small"/>}
            </div>
            {(moreInfo)&&<Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{padding:20}}>
                    {(review.workLifeBalance)&&<RatingSection label="Work/Life Balance" value={review.workLifeBalance}/>}
                    {(review.cultureValues)&&<RatingSection label="Culture & Values" value={review.cultureValues}/>}
                    {(review.careerOpportunities)&&<RatingSection label="Career Opportunities" value={review.careerOpportunities}/>}
                    {(review.compensationsBenefits)&&<RatingSection label="Compensation and Benefits" value={review.compensationsBenefits}/>}
                    {(review.seniorManagement)&&<RatingSection label="Senior Management" value={review.seniorManagement}/>}
                </div>
            </Popover>}
        </React.Fragment>
    )
}
