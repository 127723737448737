import React from 'react';
import { getHost } from '../../utils/utils'
import Button from '@material-ui/core/Button';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import '../../styles/Login.css'

export default class SocialLogin extends React.Component {
    handleSocialLogin = (user) => {
        console.log(user)
    }
       
    handleSocialLoginFailure = (err) => {
        console.error(err)
    }

    socialLogin = (source) => {
        const { redirectPath } = this.props

        const host = getHost();
        const url = `${host}/auth/${source}`

        if(redirectPath){
            localStorage.setItem("redirectPath", redirectPath)
        }
        window.location=url;
    }

    /* <Button
        fullWidth
        variant="contained"
        color="primary"
        aria-label="Sign In with Facebook"
        size="large"
        className="facebookLoginButton"
        onClick={()=>this.socialLogin('facebook')}
    >
        <FacebookIcon />
        Sign In with Facebook
    </Button>*/

    render(){
		return (
            <div style={{marginTop:10}}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    aria-label="Sign In with LinkedIn"
                    size="large"
                    className="linkedInLoginButton"
                    onClick={()=>this.socialLogin('linkedin')}
                >
                    <LinkedInIcon />
                    Sign In with LinkedIn
                </Button>

                        
                <p style={{ textAlign: "center", marginBottom:0 }}>- OR -</p>
            </div>
        )
    }
}