import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { initHistogram } from '../redux/actions/histogram'
import HistogramView from '../components/HistogramView'
import { showDialog } from '../redux/actions/MainDialog';

class HistogramC extends Component {
    componentDidMount() {
        this.fetchData(this.props)
    }

    componentDidUpdate(prevProps){
        if (prevProps.histogramType !== this.props.histogramType || 
            prevProps.loginChecked !== this.props.loginChecked || 
            (this.props.loginChecked && prevProps.hasPermission !== this.props.hasPermission) || 
            (this.props.loginChecked && prevProps.isAuthed !== this.props.isAuthed) || 
            (prevProps.match && this.props.match && prevProps.match.params.activityView && prevProps.match.params.activityView !== this.props.match.params.activityView)){
            this.fetchData(this.props)
        }
    }

    fetchData = props => {
        const { dispatch, match, histogramType, hasPermission, cardContent} = props

        let jobParams = (match)?match.params.job:''

        let queryParams = (this.props.location) ? this.props.location.search : '';

        if (jobParams) {
            let jobParamsParts = jobParams.split("-");
            let params = jobParamsParts[jobParamsParts.length - 1];

            let parts = params.split("_");

            if (parts[0] === 'SRCH'){
                for (let i = 1; i < parts.length; i++){
                    switch(parts[i].charAt(0)){
                        case 'L':{
                            var location = parts[i].substr(1);
                            break;
                        }
                        case 'C':{
                            var coordinataes = parts[i].substr(1).replace(/d/mgi, '.').replace(/p/mgi, ',').replace(/m/mgi, '-');
                            break;
                        }
                        case 'Q':{
                            var keyWordsPos = parts[i].substr(1);
                            break;
                        }
                        case 'D':{
                            var datePosted = parts[i].substr(1);
                            break;
                        }
                        default:{}
                    }
                }

                let keyWords = '';
                let locationObj = {}

                if (keyWordsPos){
                    keyWordsPos = keyWordsPos.split(",");
                    keyWords = jobParams.substr(keyWordsPos[0], keyWordsPos[1]).replace(/-/mgi, ' ');
                } 

                if (coordinataes){
                    coordinataes = coordinataes.split(",");
                    if (coordinataes.length === 4){
                        locationObj.lng = coordinataes[0];
                        locationObj.lat = coordinataes[1];
                        const countryLength = parseInt(coordinataes[2])
                        const localityLength = parseInt(coordinataes[3])
                        if (countryLength > 0){
                            locationObj.country = jobParams.substr(0, countryLength).replace(/-/mgi, ' ');
                        }
                        if (localityLength > 0){
                            if (countryLength > 0){
                                locationObj.locality = jobParams.substr(countryLength + 1, localityLength).replace(/-/mgi, ' ');
                                locationObj.text = locationObj.locality + ', ' + locationObj.country
                            } else {
                                locationObj.locality = jobParams.substr(0, localityLength).replace(/-/mgi, ' ');
                                locationObj.text = locationObj.locality
                            }
                        } else {
                            locationObj.text = locationObj.country
                        }      

                        if (keyWordsPos && countryLength === 0 && localityLength === 0){
                            locationObj.text = jobParams.substr(0, keyWordsPos[0]-1).replace(/-/mgi, ' ');
                        }
                    }
                }

                dispatch(initHistogram(histogramType, false, {queryString:keyWords, locationCode:location, locationObj, datePosted}, queryParams, hasPermission))
            } else {
                let id = parts[0];
                dispatch(initHistogram(histogramType, true, {id, cardContent}, queryParams, hasPermission))
            }
        } else if (match && match.params.activityView){
            dispatch(initHistogram(histogramType, false, {queryString:'', locationObj:{}}, queryParams, hasPermission, match.params.activityView))
        } else {
            dispatch(initHistogram(histogramType, false, {queryString:'', locationObj:{}}, queryParams, hasPermission))
        }
    }

    showDialog = (title, message) => {
        const { dispatch } = this.props
        dispatch(showDialog(title, message))
    }

    changeRoute = route => {
        const { dispatch } = this.props
        dispatch(push(route));
    }

    render() {
        const { histogramTypeC, hasPermission, loginChecked, userInfo, savedItems } = this.props;

        return (
            <HistogramView histogramType={histogramTypeC} hasPermission={hasPermission} loginChecked={loginChecked} changeRoute={this.changeRoute} userInfo={userInfo} savedItems={savedItems}/>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo, loginChecked, savedItems } = state.authUser;
    const histogramType = state.histogramData.histogramType;

    let hasPermission = false

    switch (histogramType){
        case 'jobs':
            hasPermission = true//(!isAuthed || userInfo.lookingforwork === true)
            break
        case 'resumes':
            hasPermission = true//(isAuthed && userInfo.lookingforwork === false)
            break
        case 'myJobs':
            hasPermission = (isAuthed && userInfo.lookingforwork === false)
            break
        case 'profile':
            hasPermission = (isAuthed && userInfo)
            break
        case 'activity':
            hasPermission = (isAuthed)
            break
        case 'reports':
            hasPermission = (isAuthed && userInfo.isAdmin)
            break
        case 'saveditems':
            hasPermission = (isAuthed)
            break
        case 'companies':
            hasPermission = true
            break
        case 'jobhuntUsers':
            hasPermission = (isAuthed && (userInfo.isAdmin || userInfo.isJHAdmin))
            break
        case 'interviews':
            hasPermission = true
            break 
        default:
            hasPermission = false
    }

    return {
        histogramTypeC:histogramType, hasPermission, loginChecked, isAuthed, userInfo, savedItems
    }
}

export default connect(mapStateToProps)(HistogramC)