import {ADD_COMPANY} from '../actions/companies'

export function companies(
    state = [],
    action
) {
    switch (action.type) {
        case ADD_COMPANY:
            return Object.assign({}, state, {
                [action.companyName]: action.companyData
            })
        default:
            return state
    }
}