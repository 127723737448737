import {
    AUTH_USER,
    UNAUTH_USER,
    REGISTER_FAIL,
    LOGIN_FAIL,
    TOGGLE_LOGIN_DIALOG,
    LOGIN_CHECKED
} from '../actions/authActions'
    
const initialState = {
    isAuthed: false,
    registerErrors: {},
    loginErrors: {},
    userInfo: {},
    applies: [],
    reports: [],
    contacts: [],
    promotes: [],
    jobs: [],
    openDialog: false,
    loginChecked: false,
    section: 'signIn',
    alerts: [],
    savedItems: []
};

export function authUser(
        state = initialState,
        action
    ) {
    switch (action.type) {
        case LOGIN_CHECKED:
            return {
                ...state,
                loginChecked: true
            };
        case AUTH_USER:
            return {
                isAuthed: true,
                registerErrors: {},
                loginErrors: {},
                userInfo: action.userInfo || state.userInfo,
                applies: action.applies || state.applies,
                jobs: action.jobs || state.jobs,
                reports: action.reports || state.reports,
                contacts: action.contacts || state.contacts,
                promotes: action.promotes || state.promotes,
                openDialog: false,
                loginChecked: true,
                alerts: action.alerts || state.alerts,
                savedItems: action.savedItems || state.savedItems
            };
        case UNAUTH_USER:
                return {
                    ...initialState,
                    loginChecked: true
                };
        case REGISTER_FAIL:
            return {
                ...state,
                registerErrors: action.errors,
                loginChecked: true
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loginErrors: action.errors,
                loginChecked: true
            };
        case TOGGLE_LOGIN_DIALOG:
            return{
                ...state,
                openDialog: action.state,
                section: action.section
            }
        default:
            return state;
    }
};