import { SHOW_DIALOG, 
	CLOSE_DIALOG} from '../actions/MainDialog'

const initialState = {
    isShowed: false,
    title: '',
    content: '',
    email: '',
    code: '',
    featureIndex: '',
    pre: false,
    proButton: false,
    contactType: '',
    anchorEl:null
}

export function MainDialog(state = initialState, action) {
    switch (action.type) {
		case CLOSE_DIALOG:
            return initialState
      	case SHOW_DIALOG:
			return Object.assign({}, state, {
                isShowed: true,
                modalType: action.modalType,
                title: action.title,
                content: action.content,
                email:action.email || '',
                code: action.code || '',
                featureIndex: action.featureIndex,
                pre: action.pre || false,
                mailRecieverData: action.mailRecieverData,
                showNotification: action.showNotification,
                object: action.object,
                location: action.location,
                alertHistogramType: action.alertHistogramType,
                alertId: action.alertId,
                messageTitle: action.messageTitle,
                registeredUsers: action.registeredUsers,
                message: action.message,
                query: action.query,
                proButton: action.proButton || false,
                contactType: action.contactType || '',
                anchorEl: action.anchorEl || null
			})
      	default:
        	return state
    }
}