import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import { FIELD_TYPES, showDateTimeFromString } from '../../../utils/utils';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import PickIcon from '@material-ui/icons/RadioButtonUnchecked';
import UnPickIcon from '@material-ui/icons/RadioButtonChecked';
import DeleteIcon from '@material-ui/icons/Delete';
import JHApi from "../../../utils/JHApi"

export default function CVManager(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 

    const isAdmin = props.isAdmin
    const userId = props.userId

    const defResumes = journeyData.CVLinkInCoverLetter ? [{title:'CV 1', link:journeyData.CVLinkInCoverLetter, picked:true}] : []

    const resumes = journeyData.resumes&&journeyData.resumes.length>0 ? journeyData.resumes : defResumes

    const defDialogData = {open:false}

    const [dialogData, setDialogData] = useState(defDialogData);
    const [convertStatus, setConvertStatus] = useState();
    const [uplaodMessage, setUplaodMessage] = useState('');

    const handlePickCV = (id) => {
        let fields = {CVLinkInCoverLetter: resumes[id].link, goToNextStep:false}

        fields.resumes = resumes.map((resume, index) => (index === id)?{...resume, picked:true}:{...resume, picked:false})

        props.handleSaveData(fields,(response)=>{
            setDialogData(defDialogData)
            converToDocx()
        }, (error) => {
            setDialogData({...dialogData,error})
        })
    }

    const handleSave = e => {
        e.preventDefault();

        let paramsToPass = {goToNextStep:false}

        let title = dialogData.title
        if (!title){
            title = 'CV ' + (resumes.length + 1)
        }

        if (dialogData.id !== undefined){
            paramsToPass.resumes = resumes.map((resume, index) => (index === dialogData.id)?{...resume, title, link:dialogData.link, isDeleted:dialogData.isDelete}:resume)

            if(resumes[dialogData.id].picked){
                paramsToPass.CVLinkInCoverLetter = dialogData.link
            }
        } else {
            paramsToPass.resumes = [...resumes, {title, link:dialogData.link}]
        }

        props.handleSaveData(paramsToPass,(response)=>{
            setDialogData(defDialogData)
        }, (error) => {
            setDialogData({...dialogData,error})
        })
    }

    const converToDocx = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()
        setConvertStatus()

        JHApi.convertGoogleDocsToDocx(paramsToPass,(response)=>{
            props.postServerCall(null, response)
            setConvertStatus('Completed')
        }, (error) => {
            if(error && error.response&& error.response.data){
                console.log(error.response.data)     
            } else {
                console.log(error)
            }

            setConvertStatus('Failed')
            props.postServerCall()
        })
    }

    const converToPdf = () => {
        let paramsToPass = {}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()
        setConvertStatus()

        JHApi.convertGoogleDocsToPdf(paramsToPass,(response)=>{
            props.postServerCall(null, response)
            setConvertStatus('DownloadCompleted')
        }, (error) => {
            if(error && error.response&& error.response.data){
                console.log(error.response.data)     
            } else {
                console.log(error)
            }

            setConvertStatus('DownloadFailed')
            props.postServerCall()
        })
    }

    const uploadPublishCVFile = event => {
        const maxUploadResumeSize = 50 * 1024; // 50 KB
        const file = event.target.files[0];

        if (!file) {
            return null
        }

        if(file.size > maxUploadResumeSize){
            setUplaodMessage('File too big - 50 KB Max')
            return null
        }
        
        const extension =  file.name.split('.').pop();
        if(!extension.match(/docx|pdf/ig)){
            setUplaodMessage('Invalid extension')
            return null
        }

        props.preServerCall()
        setUplaodMessage('Uploading...')

        const data = new FormData()
        data.append('file', file, file.name)

        if (isAdmin){
            data.append('userId', userId)
        }

        JHApi.uploadPublishCVFile(data, (response)=>{
            props.postServerCall(null, response)
            setUplaodMessage('')
        }, (error)=>{
            const errorMessage = (error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : 'Something went wrong'
            setUplaodMessage('')
            props.postServerCall(errorMessage)
        });
    }

    const getValue = (name, defaultValue) => {
        if (dialogData[name] !== undefined){
            return dialogData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setDialogData({...dialogData,[e.target.name]: e.target.value})
    };

    const handleCancel = () => {
        setDialogData(defDialogData)
    }

    const handleAddLink = () => {
        setDialogData({open:true})
    }

    const handleEditLink = (id, object) => () => {
        setDialogData({open:true, id, ...object})
    }

    const handleDeleteLink = (id, object) => () => {
        setDialogData({open:true, id, isDelete:true, ...object})
    }

    const fields = [
        {name:'title', label:'Title', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'link', label:'Link', defaultValue: '', type:FIELD_TYPES.TEXT, placeholder:'Example: https://docs.google.com/document/d/{id}/edit?usp=sharing&ouid={uid}&rtpof=true&sd=true', sm:12, required:true},
    ]

    //const linkStyle = {display: 'inline-flex',border: '1px solid rgb(192 192 192)',padding: '4px 8px',fontSize: 16, minHeight:26, alignItems:'center'}
    //const linkStylePicked = {...linkStyle, backgroundColor: 'rgb(255, 242, 204)'}
    /*style={resume.picked?linkStylePicked:linkStyle}*/

    return (
        <>
            <Dialog onClose={handleCancel} open={dialogData.open}>
                <form onSubmit={handleSave}>
                    {(dialogData.error)&&<Typography color="error">{dialogData.error}</Typography>}
                    <DialogContent dividers>
                        {dialogData.isDelete?
                        <Typography>Delete CV?</Typography>:  
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            {dialogData.isDelete?'No':'Cancel'}
                        </Button>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            {dialogData.isDelete?'Yes':'Save'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {resumes.map((resume, index) => <Fragment key={index}>            
                {!resume.isDeleted&&<div  className={"tag"+(resume.picked?" selected":"")} style={{display: 'inline-flex', fontSize: 15, alignItems: 'center', cursor: 'default'}} >
                    {isAdmin&&<IconButton aria-label="edit" onClick={()=>{if(!resume.picked){handlePickCV(index)}}} size='small' style={{marginRight:4}} color='primary'>
                        {resume.picked?<UnPickIcon fontSize="small" />:<PickIcon fontSize="small" />}
                    </IconButton>}
                    <a href={resume.link} target="_blank" rel="noopener noreferrer">{resume.title}</a>
                    <IconButton aria-label="edit" onClick={handleEditLink(index, resume)} size='small' style={{marginLeft:4}}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    {(isAdmin&&!resume.picked)&&<IconButton aria-label="delete" onClick={handleDeleteLink(index, resume)} size='small' style={{marginLeft:4}}>
                        <DeleteIcon/>
                    </IconButton>}
                </div>}
            </Fragment>)}
            <div style={{display: 'inline-flex', alignItems: 'center', margin:3}}>
                <Button
                    disabled={isLoading}
                    onClick={handleAddLink}
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                >
                    CV
                </Button>
            </div>

            {(isAdmin && journeyData.CVLinkInCoverLetter)&&<div>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={converToDocx}
                    aria-label="Convert"
                    disabled={isLoading || !journeyData.CVLinkInCoverLetter}
                    style={{margin:'16px 8px 16px 0'}}
                >
                    Convert To Docx
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={converToPdf}
                    aria-label="Convert"
                    disabled={isLoading || !journeyData.CVLinkInCoverLetter}
                    style={{margin:'16px 8px 16px 0'}}
                >
                    Convert To Pdf
                </Button>

                {(convertStatus==='Completed')&&<Typography>
                    <strong>Convert Completed</strong><br/>
                    Check the file, if incorect:<br/>
                    1. Open CV on Google docs<br/>
                    2. Select and copy all content of file (Ctrl + A, then Ctrl + C)<br/>
                    3. Open new Word file, paste the content (Ctrl + V) <br/>
                    4. Save as Word file (.docx)<br/>
                    5. Upload Word file here
                </Typography>}
                {(convertStatus==='DownloadCompleted')&&<Typography>
                    <strong>Convert Completed</strong><br/>
                    Check the file
                </Typography>}
                {(convertStatus==='Failed')&&<Typography>
                    <strong style={{color:'#f44336'}}>Convert Failed</strong><br/>
                    - Validate that the file is Google docs<br/>
                    - Validate is shared with everyone<br/>
                    If none of this help:<br/>
                    1. Open CV on Google docs<br/>
                    2. Select and copy all content of file (Ctrl + A, then Ctrl + C)<br/>
                    3. Open new Word file, paste the content (Ctrl + V) <br/>
                    4. Save as Word file (.docx)<br/>
                    5. Upload Word file here
                </Typography>}
                {(convertStatus==='DownloadFailed')&&<Typography>
                    <strong style={{color:'#f44336'}}>Convert Failed</strong><br/>
                    - Validate that the file is correct type<br/>
                    - Validate is shared with everyone<br/>
                </Typography>}

                {journeyData.CVUrl&&<Typography><a target='_blank' rel="noopener noreferrer" href={journeyData.CVUrl}>Uploaded CV{journeyData.CVUrlUploadTime?(' - '+showDateTimeFromString(journeyData.CVUrlUploadTime, true)):''}</a></Typography>}
                <Typography>{uplaodMessage}</Typography>
                <input
                    accept=".docx,.pdf"
                    id="contained-button-file"
                    type="file"
                    style={{display:"none"}}
                    onChange={uploadPublishCVFile}
                />
                <label htmlFor="contained-button-file">
                    <Button 
                        disabled={isLoading} 
                        variant="outlined" 
                        color="primary" 
                        component="span" 
                        aria-label="Upload" 
                        size="small" 
                        style={{margin:'16px 8px 0 0'}}
                    >
                        Upload New File
                    </Button>
                </label>
            </div>}
        </>
    )
}