import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import AdminJHApi from "../utils/AdminJHApi"
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { getHost, showDateTimeFromString } from '../utils/utils'
import Axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    accordionContainer: {
        margin: 16
    },
    ytVideos:{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: theme.spacing(-1)
    },
    ytVideo:{
        width:180,
        margin:theme.spacing(1),
        cursor: "pointer"
    },
    users:{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    ytVideoImg:{
        width:"100%"
    },
    gReviews:{
        width:"100%"
    },
    gReview:{
        textAlign: "right",
        borderTop: "1px solid #f5f5f5",
        padding: "28px 0",
        cursor: "pointer"
    },
    grImageContainer:{
        float: "right",
        marginLeft: 5
    },
    grImage:{
        height: 40,
        width: 40
    },
    grContent:{
        marginTop: 0,
        marginRight: 47,
        position: "relative",
        verticalAlign: "middle"
    },
    grAuthor:{
        fontSize: 13,
        paddingLeft: 6,
        fontWeight: "bold",
        color: "#000"
    },
    grRating: {
        marginLeft: 8,
        fontSize: 13,
        letterSpacing: 1
    },
    grDate: {
        color: "#70757a",
        fontSize: 13
    },
    grDescription: {
        color: "#222",
        fontSize: 13,
        lineHeight: "18px",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "pre-wrap"
    }
}));

const FETCH_STATUS = {
    INITIAL: "INITIAL",
    FETCHING: "FETCHING",
    SUCCESS: "SUCCESS",
    FAIL: "FAIL"
};

function AccordionJS(props) {
    const classes = useStyles(); 

    return (
        <div className={classes.accordionContainer}>
            <Accordion /*expanded={expanded} onChange={onChange}*/>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{props.children}</AccordionDetails>
            </Accordion>
        </div>
    )
}

const getRatingNumber = string => {
    switch(string){
        case 'ONE':{
            return 1
        } case 'TWO':{
            return 2
        }case 'THREE':{
            return 3
        }case 'FOUR':{
            return 4
        }case 'FIVE':{
            return 5
        } default:{
            return 0
        }
    }
}

export function JHReviews(props) {
    const [videos, setVideos] = useState([]);
    const [videosExtra, setVideosExtra] = useState({});
    const [gReviews, setGReviews] = useState([]);
    const [gReviewsExtra, setGReviewsExtra] = useState({});
    const [usersData, setUsersData] = useState({});
    const [googleCientId, setGoogleCientId] = useState("");

    const [needFetchData, setNeedFetchData] = useState(true);
    const [accessToken, setAccessToken] = useState("");
    const [locationId, setLocationId] = useState("");

    const [videoStatus, setVideosStatus] = useState(FETCH_STATUS.INITIAL);
    const [reviewsStatus, setResviewsStatus] = useState(FETCH_STATUS.INITIAL);

    const [showDialog, setShowDialog] = useState(false);
    const [dialogData,setDialogData] = useState({});
    const [dialogUserData,setDialogUserData] = useState({});

    const [filter, setFilter] = useState({});

    const host = getHost()

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const hashParams = window.location.hash;

        if (needFetchData){
            if (hashParams){
                getReviews(hashParams)
            } else {
                getDataFromDB()
            }
        }
    });

    const setFetchedData = response => {
        setVideos(response.videos || [])
        setVideosExtra(response.videosExtra || {})
        setGReviews(response.gReviews || [])
        setGReviewsExtra(response.gReviewsExtra || {})
        setUsersData(response.usersData || {})
        setGoogleCientId(response.googleCientId || "")
    }

    const getReviews = hashParams => {
        setNeedFetchData(false)
    
        const searchParams = new URLSearchParams('?' + hashParams.slice(1));
        const accessTokenFromParams = searchParams.get("access_token")
        
        if(accessTokenFromParams){
            setResviewsStatus(FETCH_STATUS.FETCHING)
            Axios({ method:'GET', 
                url: `https://mybusinessaccountmanagement.googleapis.com/v1/accounts`, 
                headers: { authorization: `Bearer ${accessTokenFromParams}` },
            })
            .then((response) => {
                if (!response || !response.data || !response.data.accounts || response.data.accounts.length === 0){
                    setResviewsStatus(FETCH_STATUS.FAIL)
                    props.showMessageDialog('Error', 'No Accounts')
                    return 
                }

                if (response.data.accounts.length > 1){
                    setResviewsStatus(FETCH_STATUS.FAIL)
                    props.showMessageDialog('Error', 'More than One Account')
                    return 
                }

                const accountParam = response.data.accounts[0].name

                Axios({ method:'GET', 
                    url: `https://mybusinessbusinessinformation.googleapis.com/v1/${accountParam}/locations?read_mask=name`, 
                    headers: { authorization: `Bearer ${accessTokenFromParams}` },
                })
                .then((response) => {
                    if (!response || !response.data || !response.data.locations || response.data.locations.length === 0){
                        setResviewsStatus(FETCH_STATUS.FAIL)
                        props.showMessageDialog('Error', 'No Locations')
                        return 
                    }
    
                    if (response.data.locations.length > 1){
                        setResviewsStatus(FETCH_STATUS.FAIL)
                        props.showMessageDialog('Error', 'More than One Location')
                        return 
                    }

                    const locationParam = response.data.locations[0].name

                    setAccessToken(accessTokenFromParams)
                    setLocationId(accountParam +  '/' + locationParam)
                    Axios({ method:'GET', 
                        url: `https://mybusiness.googleapis.com/v4/${accountParam +  '/' + locationParam}/reviews`, 
                        headers: { authorization: `Bearer ${accessTokenFromParams}` },
                    })
                    .then(async (response) => {
                        if(response && response.data){
                            let nextPageToken = response.data.nextPageToken
                            let reviews = response.data.reviews || []
                            let averageRating = response.data.averageRating
                            let totalReviewCount = response.data.totalReviewCount
                            let calls = 1

                            while(nextPageToken && calls < 10){
                                try {
                                    const response = await Axios.get(`https://mybusiness.googleapis.com/v4/${accountParam +  '/' + locationParam}/reviews?pageToken=${nextPageToken}`, {
                                        headers: {
                                            'authorization': `Bearer ${accessTokenFromParams}`
                                        }
                                    })

                                    if(response && response.data){            
                                        nextPageToken = response.data.nextPageToken
                                        reviews = [...reviews, ...response.data.reviews]
                                    }
                                } catch (error) {
                                    setResviewsStatus(FETCH_STATUS.FAIL)
                                    console.error(error);
                                }
                                calls++
                            }

                            AdminJHApi.saveReviewsFromGoogle({data:{averageRating, totalReviewCount, reviews}},(response)=>{
                                setResviewsStatus(FETCH_STATUS.SUCCESS)
                                setFetchedData(response)
                            }, (error) => {
                                setResviewsStatus(FETCH_STATUS.FAIL)
                                console.log(error)
                                props.showMessageDialog('Error', JSON.stringify(error))
                            })
                        } else {
                            props.showMessageDialog('Error', 'No Data')
                            setResviewsStatus(FETCH_STATUS.FAIL)
                        }
                    })
                    .catch((error) => {
                        setResviewsStatus(FETCH_STATUS.FAIL)
                        console.log(error)
                        window.open("/jobhunt-reviews","_self")
                        //props.showMessageDialog('Error', JSON.stringify(error))
                    });
                })
                .catch((error) => {
                    setResviewsStatus(FETCH_STATUS.FAIL)
                    console.log(error)
                    window.open("/jobhunt-reviews","_self")
                    //props.showMessageDialog('Error', JSON.stringify(error))
                });
            })
            .catch((error) => {
                setResviewsStatus(FETCH_STATUS.FAIL)
                console.log(error)
                window.open("/jobhunt-reviews","_self")
                //props.showMessageDialog('Error', JSON.stringify(error))
            });
        }
    }

    const getReviewNoAuth = () => {
        setResviewsStatus(FETCH_STATUS.FETCHING)
        Axios({ method:'GET', 
            url: `https://mybusiness.googleapis.com/v4/${locationId}/reviews`, 
            headers: { authorization: `Bearer ${accessToken}` },
        })
        .then(async (response) => {
            if(response && response.data){
                let nextPageToken = response.data.nextPageToken
                let reviews = response.data.reviews || []
                let averageRating = response.data.averageRating
                let totalReviewCount = response.data.totalReviewCount
                let calls = 1

                while(nextPageToken && calls < 10){
                    try {
                        const response = await Axios.get(`https://mybusiness.googleapis.com/v4/${locationId}/reviews?pageToken=${nextPageToken}`, {
                            headers: {
                                'authorization': `Bearer ${accessToken}`
                            }
                        })

                        if(response && response.data){            
                            nextPageToken = response.data.nextPageToken
                            reviews = [...reviews, ...response.data.reviews]
                        }
                    } catch (error) {
                        setResviewsStatus(FETCH_STATUS.FAIL)
                        console.error(error);
                    }
                    calls++
                }

                AdminJHApi.saveReviewsFromGoogle({data:{averageRating, totalReviewCount, reviews}},(response)=>{
                    setResviewsStatus(FETCH_STATUS.SUCCESS)
                    setFetchedData(response)
                }, (error) => {
                    setResviewsStatus(FETCH_STATUS.FAIL)
                    console.log(error)
                    props.showMessageDialog('Error', JSON.stringify(error))
                })
            } else {
                setResviewsStatus(FETCH_STATUS.FAIL)
                props.showMessageDialog('Error', 'No Data')
            }
        })
        .catch((error) => {
            setResviewsStatus(FETCH_STATUS.FAIL)
            setAccessToken('')
            setLocationId('')
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        });
    }

    const getDataFromDB = () => {
        setNeedFetchData(false)

        AdminJHApi.getJHReviewData({},(response)=>{
            setFetchedData(response)
        }, (error) => {
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const closeDialog = () => {
        setShowDialog(false)
    }

    const saveData = e => {
        e.preventDefault()

        AdminJHApi.saveJHReviewData({data:dialogData, userData: dialogUserData},(response)=>{
            setFetchedData(response)
            setShowDialog(false)
        }, (error) => {
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const openDialog = (type, object, extra, userData) => {
        let newdialogData = {
            type,
            id: (type==="video") ? object.snippet.resourceId.videoId : object.reviewId,
            ...extra,
        }

        setDialogData(newdialogData)
        setDialogUserData(userData)
        setShowDialog(true)
    }

    const handleChange = e => {
        if (e.target.name === 'email' && usersData[e.target.value]){
            setDialogUserData(usersData[e.target.value])
        }

        setDialogData({...dialogData,[e.target.name]: e.target.value})
    }

    const handleChangeCheck = e => {
        setDialogData({...dialogData,[e.target.name]: e.target.checked})
    }

    const handleChangeUser = e => {
        setDialogUserData({...dialogUserData,[e.target.name]: e.target.value})
    }
    

    const handleFilter = e =>{
        setFilter({...filter,[e.target.name]: e.target.checked})
    }

    const classes = useStyles(); 

    const getVideosFromYoutube = () => {
        /*setVideosStatus(FETCH_STATUS.FETCHING)
        Axios({ method:'GET', 
            url: `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=UU2fAA1ow9yK5IkjodwsG-qQ&key=AIzaSyA6YBQoFNHDz0MW58RIP4z5WpPj1ZL17Xo&part=snippet&maxResults=150`, 
        })
        .then((response) => {
            if (!response || !response.data || !response.data.items){
                setVideosStatus(FETCH_STATUS.FAIL)
                props.showMessageDialog('Error', 'No Data')
            } else {
                AdminJHApi.saveVideosFromYoutube({videos: response.data.items},(response)=>{
                    setVideosStatus(FETCH_STATUS.SUCCESS)
                    setFetchedData(response)
                }, (error) => {
                    setVideosStatus(FETCH_STATUS.FAIL)
                    console.log(error)
                    props.showMessageDialog('Error', JSON.stringify(error))
                })
            }
                
        })
        .catch((error) => {
            setVideosStatus(FETCH_STATUS.FAIL)
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        });*/

        const fetchVideos = async () => {
            try {
                setVideosStatus(FETCH_STATUS.FETCHING)

                //all videos playlist UU2fAA1ow9yK5IkjodwsG-qQ
                const allVideos = await fetchPlaylistItems('PL--mYx4gxcsXoWaanf1nLlEqnpRWnb7ue', 'AIzaSyA6YBQoFNHDz0MW58RIP4z5WpPj1ZL17Xo');
                //setVideos(allVideos);
                AdminJHApi.saveVideosFromYoutube({videos: allVideos},(response)=>{
                    setVideosStatus(FETCH_STATUS.SUCCESS)
                    setFetchedData(response)
                }, (error) => {
                    setVideosStatus(FETCH_STATUS.FAIL)
                    console.log(error)
                    props.showMessageDialog('Error', JSON.stringify(error))
                })
            } catch (error) {
                setVideosStatus(FETCH_STATUS.FAIL)
                console.log(error)
                props.showMessageDialog('Error', JSON.stringify(error))
            } finally {
                //setLoading(false);
            }
        };
      
        fetchVideos();
    }

    const fetchPlaylistItems = async (playlistId, apiKey, nextPageToken = '', fetchedItems = []) => {
        const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';

        const { data } = await Axios.get(YOUTUBE_PLAYLIST_ITEMS_API, {
            params: {
                part: 'snippet',
                playlistId: playlistId,
                maxResults: 50,
                pageToken: nextPageToken,
                key: apiKey,
            },
        });
      
        const newFetchedItems = fetchedItems.concat(data.items);
      
        if (data.nextPageToken) {
            return fetchPlaylistItems(playlistId, apiKey, data.nextPageToken, newFetchedItems);
        } else {
            return newFetchedItems;
        }
    };

    return (
        <div className="pageMainContainer">
            <Dialog
                open={showDialog}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="paper"
            >
                <DialogTitle id="alert-dialog-title">Extra data</DialogTitle>
                <form onSubmit={saveData}>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            {(dialogData && dialogData.type === 'video' && dialogData.id)&&<Grid item xs={12}>
                                <a href={"https://www.youtube.com/watch?v="+dialogData.id} target='_blank' rel="noopener noreferrer">Video Link</a>
                            </Grid>}
                            <Grid item xs={12}>
                                <TextField name="email" label="Email" margin="none" fullWidth type='email'
                                    value={dialogData.email || ""} onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="firstName" label="First Name" margin="none" fullWidth type='text' disabled={!dialogData.email}
                                    value={dialogUserData.firstName || ""} onChange={handleChangeUser}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField name="lastName" label="Last Name" margin="none" fullWidth type='text' disabled={!dialogData.email}
                                    value={dialogUserData.lastName || ""} onChange={handleChangeUser}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="title" label="Title" margin="none" fullWidth type='text' disabled={!dialogData.email}
                                    value={dialogUserData.title || ""} onChange={handleChangeUser}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="linkedin" label="Linkedin" margin="none" fullWidth type='text' disabled={!dialogData.email}
                                    value={dialogUserData.linkedin || ""} onChange={handleChangeUser}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="linkedinPost" label="Linkedin Post" margin="none" fullWidth type='text' disabled={!dialogData.email}
                                    value={dialogUserData.linkedinPost || ""} onChange={handleChangeUser}/>
                            </Grid>
                            {((dialogData.type==="gReview"))&&<Grid item xs={12}>
                                <TextField name="gReviewLink" label="Review Link" margin="none" fullWidth type='text'
                                    value={dialogData.gReviewLink || ""} onChange={handleChange}/>
                            </Grid>}
                            <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox name="hide" color="primary" onChange={handleChangeCheck} checked={dialogData.hide||false}/>}
                                label="Hide"
                            />
                            <FormControlLabel
                                control={<Checkbox name="synamedia" color="primary" onChange={handleChangeCheck} checked={dialogData.synamedia||false}/>}
                                label="Show in Synamedia"
                            />
                            <FormControlLabel
                                control={<Checkbox name="bfl" color="primary" onChange={handleChangeCheck} checked={dialogData.bfl||false}/>}
                                label="Show in Bfl"
                            />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Paper className={classes.accordionContainer} style={{padding:16, textAlign:'center'}} elevation={1}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {(videoStatus === FETCH_STATUS.FETCHING || !googleCientId)?<Skeleton variant="rect" width={250} height={36} style={{margin:"0 auto"}}/>:
                        <Button onClick={getVideosFromYoutube} color="primary" variant="contained" disabled={(videoStatus === FETCH_STATUS.FETCHING)}
                            startIcon={<React.Fragment>
                                {(videoStatus === FETCH_STATUS.FETCHING)&&<HourglassEmptyIcon/>}
                                {(videoStatus === FETCH_STATUS.SUCCESS)&&<CheckIcon />}
                                {(videoStatus === FETCH_STATUS.FAIL)&&<ErrorIcon />}
                            </React.Fragment>}>
                            Get Videos From Youtube
                        </Button>}
                    </Grid>
                    <Grid item xs={6}>
                        {(reviewsStatus === FETCH_STATUS.FETCHING || !googleCientId)?<Skeleton variant="rect" width={250} height={36} style={{margin:"0 auto"}}/>:
                        <React.Fragment>
                            {(accessToken&&locationId)?<Button onClick={getReviewNoAuth} color="primary" variant="contained" disabled={(reviewsStatus === FETCH_STATUS.FETCHING)}
                                startIcon={<React.Fragment>
                                    {(reviewsStatus === FETCH_STATUS.FETCHING)&&<HourglassEmptyIcon/>}
                                    {(reviewsStatus === FETCH_STATUS.SUCCESS)&&<CheckIcon />}
                                    {(reviewsStatus === FETCH_STATUS.FAIL)&&<ErrorIcon />}
                                </React.Fragment>}>
                                Get Reviews From Google
                            </Button>:
                            <React.Fragment>
                                {(googleCientId)&&<Button color="primary" variant="contained" href={"https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + host + "/jobhunt-reviews&prompt=consent&response_type=token&client_id=" + googleCientId + "&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage&access_type=online"}
                                    disabled={(reviewsStatus === FETCH_STATUS.FETCHING)}
                                    startIcon={<React.Fragment>
                                        {(reviewsStatus === FETCH_STATUS.FETCHING)&&<HourglassEmptyIcon/>}
                                        {(reviewsStatus === FETCH_STATUS.SUCCESS)&&<CheckIcon />}
                                        {(reviewsStatus === FETCH_STATUS.FAIL)&&<CloseIcon />}
                                    </React.Fragment>}>
                                    Get Reviews From Google
                                </Button>}
                            </React.Fragment>}
                        </React.Fragment>}
                    </Grid>
                </Grid>
            </Paper>
            {(videos.length>0)&&<AccordionJS title="Videos">
                <div>
                    <Grid item sm={12} xs={12}>
                        <FormControlLabel
                            control={<Checkbox name="noEmail" color="primary" onChange={handleFilter} checked={filter.noEmail||false}/>}
                            label="No Email"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noLinkedin" color="primary" onChange={handleFilter} checked={filter.noLinkedin||false}/>}
                            label="No Linkedin"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noLinkedinPost" color="primary" onChange={handleFilter} checked={filter.noLinkedinPost||false}/>}
                            label="No Linkedin Post"
                        />
                        <FormControlLabel
                            control={<Checkbox name="hidden" color="primary" onChange={handleFilter} checked={filter.hidden||false}/>}
                            label="Hidden"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noTitle" color="primary" onChange={handleFilter} checked={filter.noTitle||false}/>}
                            label="No Title"
                        />
                        <FormControlLabel
                            control={<Checkbox name="synamedia" color="primary" onChange={handleFilter} checked={filter.synamedia||false}/>}
                            label="Synamedia"
                        />
                        <FormControlLabel
                            control={<Checkbox name="bfl" color="primary" onChange={handleFilter} checked={filter.bfl||false}/>}
                            label="Bfl"
                        />
                    </Grid>
                    <div className={classes.ytVideos}>
                    {videos.map((video) =>{
                        const videoExtra = videosExtra[video.snippet.resourceId.videoId] || {}
                        const userData = usersData[videoExtra.email] || {}

                        let showVideo = true
                        if(showVideo && filter.noEmail){
                            showVideo = !videoExtra.email
                        }
                        if(showVideo && filter.noLinkedin){
                            showVideo = !userData.linkedin
                        }
                        if(showVideo && filter.noLinkedinPost){
                            showVideo = !userData.linkedinPost
                        }
                        if(showVideo && filter.hidden){
                            showVideo = videoExtra.hide
                        }
                        if(showVideo && filter.noTitle){
                            showVideo = !userData.title
                        }
                        if(showVideo && filter.synamedia){
                            showVideo = videoExtra.synamedia
                        }
                        if(showVideo && filter.bfl){
                            showVideo = videoExtra.bfl
                        }
                        return (
                            <React.Fragment key={video.id}>
                            {(showVideo)&&<div className={classes.ytVideo} onClick={() => {openDialog('video', video, videoExtra, userData)}}>
                                {video.snippet.thumbnails ? 
                                <img className={classes.ytVideoImg} alt={video.snippet.thumbnails.medium.url} src={video.snippet.thumbnails.medium.url}/>:
                                <Typography>No Image</Typography>}
                                {(videoExtra.email)&&<CheckIcon style={{color:'green'}}/>}
                            </div>}
                            </React.Fragment>
                        )}
                    )}
                    </div>
                </div>
            </AccordionJS>}
            {(gReviews.length>0)&&<AccordionJS title="Reviews">
                <div style={{width:"100%"}}>
                    <Grid item sm={12} xs={12}>
                        <FormControlLabel
                            control={<Checkbox name="noEmail" color="primary" onChange={handleFilter} checked={filter.noEmail||false}/>}
                            label="No Email"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noLinkedin" color="primary" onChange={handleFilter} checked={filter.noLinkedin||false}/>}
                            label="No Linkedin"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noLinkedinPost" color="primary" onChange={handleFilter} checked={filter.noLinkedinPost||false}/>}
                            label="No Linkedin Post"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noLink" color="primary" onChange={handleFilter} checked={filter.noLink||false}/>}
                            label="No Link"
                        />
                        <FormControlLabel
                            control={<Checkbox name="hidden" color="primary" onChange={handleFilter} checked={filter.hidden||false}/>}
                            label="Hidden"
                        />
                        <FormControlLabel
                            control={<Checkbox name="noTitle" color="primary" onChange={handleFilter} checked={filter.noTitle||false}/>}
                            label="No Title"
                        />
                        <FormControlLabel
                            control={<Checkbox name="showText" color="primary" onChange={handleFilter} checked={filter.showText||false}/>}
                            label="Show Text"
                        />
                        <FormControlLabel
                            control={<Checkbox name="synamedia" color="primary" onChange={handleFilter} checked={filter.synamedia||false}/>}
                            label="Synamedia"
                        />
                        <FormControlLabel
                            control={<Checkbox name="bfl" color="primary" onChange={handleFilter} checked={filter.bfl||false}/>}
                            label="Bfl"
                        />
                    </Grid>
                <div className={classes.gReviews}>
                    {gReviews.map((gReview) =>{
                        const gReviewExtra = gReviewsExtra[gReview.reviewId] || {}
                        const userData = usersData[gReviewExtra.email] || {}

                        let show = true
                        if(show && filter.noEmail){
                            show = !gReviewExtra.email
                        }
                        if(show && filter.noLinkedin){
                            show = !userData.linkedin
                        }
                        if(show && filter.noLinkedinPost){
                            show = !userData.linkedinPost
                        }
                        if(show && filter.noLink){
                            show = !gReviewExtra.gReviewLink
                        }
                        if(show && filter.hidden){
                            show = gReviewExtra.hide
                        }
                        if(show && filter.noTitle){
                            show = !userData.title
                        }
                        if(show && filter.synamedia){
                            show = gReviewExtra.synamedia
                        }
                        if(show && filter.bfl){
                            show = gReviewExtra.bfl
                        }

                        return (
                            <React.Fragment key={gReview.reviewId}>
                                {(show)&&<div className={classes.gReview} onClick={() => {openDialog('gReview', gReview, gReviewExtra, userData)}}>
                                    <div className={classes.grImageContainer}>
                                        <img className={classes.grImage} src={gReview.reviewer.profilePhotoUrl} alt={gReview.reviewer.displayName}/><br/>
                                        {(gReviewExtra.email)&&<CheckIcon style={{color:'green'}}/>}
                                    </div>
                                    <div className={classes.grContent}>
                                        <div>
                                            <span className={classes.grAuthor}>{gReview.reviewer.displayName}</span>
                                        </div>
                                        <div>
                                            <span className={classes.grRating}><Rating precision={1} value={getRatingNumber(gReview.starRating)} size="small" readOnly/></span> <span className={classes.grDate}>{showDateTimeFromString(gReview.createTime, false)}</span>
                                        </div>
                                        {filter.showText&&<div dir="auto" className={classes.grDescription}>{gReview.comment}</div>}
                                    </div>
                                </div>}
                            </React.Fragment>
                        )}
                    )}
                    </div>
                </div>
            </AccordionJS>}
            {(Object.keys(usersData).length>0)&&<AccordionJS title="Users">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                        {Object.keys(usersData).map((key) =>{
                            const user = usersData[key]
                            return (
                                <TableRow key={key}>
                                    <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{(user.linkedin)&&<a rel="noopener noreferrer" target="_blank" href={user.linkedin}>Linkedin</a>}</TableCell>
                                    <TableCell>{(user.linkedinPost)&&<a rel="noopener noreferrer" target="_blank" href={user.linkedinPost}>Linkedin Post</a>}</TableCell>
                                </TableRow>
                            )}
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionJS>}
        </div>
    )
}