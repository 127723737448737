import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fields } from '../Fields';

export function ConfirmDialog(props) {
    const fields = props.fields || []
    
    const [newData, setNewData] = useState({});

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleAgree = e => {
        e.preventDefault();

        setNewData({})
        props.handleAgree(newData)
    };

    const handleClose = () => {
        setNewData({})
        props.handleClose()
    }
    

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <form onSubmit={handleAgree}>
                <DialogContent>
                    {(props.contentText)&&<DialogContentText id="alert-dialog-description">{props.contentText}</DialogContentText>}
                    {(fields&&fields.length>0)&&<div style={{margin:"4px 0"}}><Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/></div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" aria-label= {(fields&&fields.length>0)?"Cancel":"No"}>
                        {(fields&&fields.length>0)?"Cancel":"No"}
                    </Button>
                    <Button color="primary" aria-label={(fields&&fields.length>0)?"Confirm":"Yes"} autoFocus type="submit">
                        {(fields&&fields.length>0)?"Confirm":"Yes"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}