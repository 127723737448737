import React from 'react'
import { Card, CardContent, CardHeader, Grid, TextField, CardActions, Collapse, IconButton, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import UserAvatar from '../containers/UserAvatar';
import AdminApi from "../utils/AdminApi"
import ExtraUserData from "./ExtraUserData"

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    actionButton: {
        marginRight: theme.spacing(1)
    },
    extraInfoButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    userCard: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
}));

function ExtraInfo(props) {
    const classes = useStyles(); 

    return (
        <Button variant="outlined" className={classes.extraInfoButton} onClick={() => {props.getExtraData(props.type)}}>{props.label}: {props.data}</Button>
    )
}

export function UserCard(props) {
    const classes = useStyles(); 
    const user = props.user 

    const isPro = (user.proEndDate && new Date(user.proEndDate) >= new Date())
    const avatarSrc = (user.avatar && user.avatar.url) ? user.avatar.url : undefined

    const [expanded, setExpanded] = React.useState(false);
    const [isDeleteUser, setIsDeleteUser] = React.useState(false);
    const [updateUserData, setUpdateUserData] = React.useState({});
    const [extraUserInfo, setExtraUserInfo] = React.useState({});
    const [extraUserData, setExtraUserData] = React.useState({});
    const [showExtraUserData, setShowExtraUserData] = React.useState(false);
    const [extraUserDataType, setExtraUserDataType] = React.useState('');

    const handleExpandClick = () => {
        setExpanded(!expanded);

        if (!expanded){
            AdminApi.getAdminExtraUserInfo({userId:user._id},(data)=>{
                setExtraUserInfo(data)
            }, (error) => {
                console.log(error)
            })
        } 
    };

    const handleChangeData = (name, value) =>{
        const newUserData = Object.assign({}, updateUserData, {[name]:value, changed:true})

        setUpdateUserData(newUserData)
    }

    /*const handleChangeDataAndSave = event =>{
        props.handleChangeUser(user._id, {[event.target.name]: event.target.checked}, succees=>{})
    }*/

    const handleSave = () => {
        props.handleChangeUser(user._id, updateUserData, succees=>{
            if (succees){
                setUpdateUserData({})
            }
        })
    }

    const handleCancel = () => {
        setUpdateUserData({})
    }

    const handleCancelDeleteUser = () => {
        setIsDeleteUser(false)
    }

    const handleAskDeleteUser = () => {
        setIsDeleteUser(true)
    }

    const handleDeleteUser = () => {
        setIsDeleteUser(false)
        props.handleDeleteUser(user._id)
    }

    const handleMakePro = months => {
        let contactCredit = (updateUserData.contactCredit || user.contactCredit || 0) + 10 * months
        let promoteCredit = (updateUserData.promoteCredit || user.promoteCredit || 0) + 10 * months
        let proEndDate = (updateUserData.proEndDate || user.proEndDate || new Date())

        proEndDate = (new Date(proEndDate) >= new Date()) ? new Date(proEndDate) : new Date()
        proEndDate.setMonth(proEndDate.getMonth() + months);
        proEndDate = proEndDate.toISOString()

        const newUserData = Object.assign({}, updateUserData, {contactCredit, promoteCredit, proEndDate, changed:true})

        setUpdateUserData(newUserData)
    }

    const getExtraData = type => {
        AdminApi.getAdminExtraUserInfo({userId:user._id, type},(data)=>{
            setExtraUserData(data)
            setExtraUserDataType(type)
            setShowExtraUserData(true)
        }, (error) => {
            console.log(error)
        })
    }

    const hideExtraUserData = () => {
        setShowExtraUserData(false)
    }
  
    return (
        <Card className={classes.userCard}>
            <CardHeader
                avatar={
                    <UserAvatar
                        firstName={user.first_name} 
                        lastName={user.last_name} 
                        name={user.name} 
                        avatarSrc={avatarSrc} 
                        backgroundColor={user.card_color} 
                        pro={isPro}
                    />
                }
 
                /*action={
                    <IconButton aria-label="settings">
                    <MoreVertIcon />
                    </IconButton>
                }*/
                title={`${user.first_name} ${user.last_name}`} 
                subheader={`${user.title || ''} ${(user.lookingforwork)?'<Talent>':'<Recruiter>'} ${user.excludedByAdmin?'<Excluded By Admin>':''}`}
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <FormControlLabel
                            control={<Checkbox name="isJHAdmin" color="primary" disabled /*onChange={handleChangeDataAndSave}*/ checked={user.isJHAdmin || false}/>}
                            label="JobHunt admin"
                        />
                        <FormControlLabel
                            control={<Checkbox name="isJHUser" color="primary" disabled /*onChange={handleChangeDataAndSave}*/ checked={user.isJHUser || false}/>}
                            label="JobHunt user"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField label="Id" margin="none" fullWidth value={user._id || ''}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField label="Email" margin="none" fullWidth value={user.email || ''}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField label="Email To Send" margin="none" fullWidth value={user.emailts || ''}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField label="Phone" margin="none" fullWidth value={user.phone || ''}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <TextField type="number" name="contactCredit" margin="none" fullWidth 
                            label={"Contact Credit"}
                            value={updateUserData.contactCredit || user.contactCredit || 0}
                            onChange={e => {handleChangeData(e.target.name, e.target.value)}}
                            helperText={(extraUserInfo.contacts?(' (' + ((updateUserData.contactCredit || user.contactCredit || 0) - extraUserInfo.contacts + ' left)')) : '')}/>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <TextField type="number" name="promoteCredit" margin="none" fullWidth 
                            label={"Promote Credit"}
                            value={updateUserData.promoteCredit || user.promoteCredit || 0}
                            onChange={e => {handleChangeData(e.target.name, e.target.value)}}
                            helperText={(extraUserInfo.promotes?(' (' + ((updateUserData.promoteCredit || user.promoteCredit || 0) - extraUserInfo.promotes + ' left)')) : '')}/>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField type="datetime-local" name="proEndDate" label="Pro End Date" margin="none" fullWidth 
                            value={(updateUserData.proEndDate || user.proEndDate || '').substr(0, 16)}
                            onChange={e => {
                                const value = e.target.value + ((user.proEndDate && e.target.value) ? user.proEndDate.substr(16, 8) : '')
                                return handleChangeData(e.target.name, value)
                            }}
                            InputLabelProps={{shrink: true}}/>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
                {(!isDeleteUser)&&<React.Fragment><Button aria-label="Pro" variant="contained" color="secondary" className={classes.actionButton}
                        onClick={()=>{handleMakePro(1)}}>+1</Button>
                    <Button aria-label="Pro" variant="contained" color="secondary" className={classes.actionButton}
                        onClick={()=>{handleMakePro(3)}}>+3</Button>
                    <Button aria-label="Pro" variant="contained" color="secondary" className={classes.actionButton}
                        onClick={()=>{handleMakePro(6)}}>+6</Button>
                </React.Fragment>}
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <CardActions disableSpacing>
                {(updateUserData.changed && !isDeleteUser)&&<React.Fragment>
                    <Button aria-label="Save" variant="contained" color="primary" className={classes.actionButton}
                        onClick={handleSave}>Save</Button>
                    <Button aria-label="Cancel" variant="outlined" color="primary" className={classes.actionButton}
                        onClick={handleCancel}>Cancel</Button>
                </React.Fragment>}
                {(!updateUserData.changed && isDeleteUser)&&<React.Fragment>
                    <Button aria-label="Delete" variant="contained" color="primary" className={classes.actionButton}
                        onClick={handleDeleteUser}>Delete</Button>
                    <Button aria-label="Cancel" variant="outlined" color="primary" className={classes.actionButton}
                        onClick={handleCancelDeleteUser}>Cancel</Button>
                </React.Fragment>}
                {((!updateUserData.changed && !isDeleteUser))&&<Button aria-label="Delete User" variant="contained" color="primary" className={classes.actionButton}
                    onClick={handleAskDeleteUser}>Delete User</Button>}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <ExtraUserData open={showExtraUserData} data={extraUserData} type={extraUserDataType} handleClose={hideExtraUserData}/>
                            <ExtraInfo label="contacts" data={extraUserInfo.contacts} getExtraData={getExtraData} type="contacts"/>
                            <ExtraInfo label="promotes" data={extraUserInfo.promotes} getExtraData={getExtraData} type="promotes"/>
                            <ExtraInfo label="active jobs" data={extraUserInfo.activeJobs} getExtraData={getExtraData} type="activeJobs"/>
                            <ExtraInfo label="archived jobs" data={extraUserInfo.archivedJobs} getExtraData={getExtraData} type="archivedJobs"/>
                            <ExtraInfo label="deleted jobs" data={extraUserInfo.deletedJobs} getExtraData={getExtraData} type="deletedJobs"/>
                            <ExtraInfo label="wait for response" data={extraUserInfo.waitForResponse} getExtraData={getExtraData} type="waitForResponse"/>
                            <ExtraInfo label="need to respond" data={extraUserInfo.needToRespond} getExtraData={getExtraData} type="needToRespond"/>
                            <ExtraInfo label="matches" data={extraUserInfo.matches} getExtraData={getExtraData} type="matches"/>
                            <ExtraInfo label="inactive connects" data={extraUserInfo.inactiveConnects} getExtraData={getExtraData} type="inactiveConnects"/>
                            <ExtraInfo label="alerts" data={extraUserInfo.alerts} getExtraData={getExtraData} type="alerts"/>
                            <ExtraInfo label="reports" data={extraUserInfo.reports} getExtraData={getExtraData} type="reports"/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Date Created" margin="none" fullWidth value={user.datecreated || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Last Active" margin="none" fullWidth value={user.lastActive || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Source" margin="none" fullWidth value={user.source || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Loggedin from" margin="none" fullWidth value={user.loggedinfrom || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Location" margin="none" fullWidth value={user.location || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Seniority Level" margin="none" fullWidth value={user.seniorityLevel || ''}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Employment Type" margin="none" fullWidth value={user.employmentType || ''}/>
                        </Grid>
                        {(user.resume)&&<Grid item sm={12} xs={12}>
                            <a rel="noopener noreferrer" target="_blank" href={user.resume}>Resume</a>
                        </Grid>}
                        <Grid item sm={12} xs={12}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={user.verified || false}/>}
                                label="Verified"
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={user.visible || false}/>}
                                label="Visible"
                            />
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={user.anon || false}/>}
                                label="Anon"
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
    );
}