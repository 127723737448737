import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from 'connected-react-router'

import { socialAuthUser } from "../../redux/actions/authActions";
import { showDialog } from "../../redux/actions/MainDialog";

class SocialAuthRedirect extends Component {
    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectPath = localStorage.getItem("redirectPath") 
        if (searchParams.get("token")){
            this.props.dispatch(
                socialAuthUser(searchParams.get("token"), () => {
                    let addToUrl = (searchParams.get("confirmed")?'?confirmed=true':'')

                    if (redirectPath){
                        localStorage.removeItem("redirectPath");
                        this.props.dispatch(push(redirectPath+addToUrl));
                    } else {
                        this.props.dispatch(push('/profile'+addToUrl));
                    } 
                })
            );
        } else {
            if (redirectPath){
                localStorage.removeItem("redirectPath");
                this.props.dispatch(push(redirectPath));
            } else {
                this.props.dispatch(push('/'));
            } 
            
            this.props.dispatch(showDialog('Login Failed', (searchParams.get("err")?searchParams.get("err"):'')))
        }
    }

    render() {
        return <div />;
    }
}

SocialAuthRedirect = connect()(SocialAuthRedirect);

export default SocialAuthRedirect;
