import {randomColor} from '../../utils/utils'

export const ADD_COMPANY = 'ADD_COMPANY'

export function createCompanyIfNeeded(companyName) {
    return (dispatch, getState) => {
        if (!getState().companies[companyName]) {
            return dispatch(addCompany(companyName, {colors: randomColor()}))
        }
    }
}

function addCompany(companyName, companyData){
    return {
        type: ADD_COMPANY,
        companyName: companyName,
        companyData: companyData
    }
}