import React, { useState } from 'react';
import { Chip, Tooltip, Typography } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import AdminJHApi from "../../../utils/AdminJHApi"

export default function Subscription(props) {
    const style = props.style || {}
    const disabled = props.disabled || false
    const userId = props.userId


    const [showEndSubscriptionConfirm, setShowEndSubscriptionConfirm] = useState(false);
    const [error, setError] = useState();

    const handleEndSubscription = () => {
        setShowEndSubscriptionConfirm(true)
    }

    const handleClosehEndSubscriptionConfirm = () => {
        setShowEndSubscriptionConfirm(false)
    }

    const handleConfirmEndSubscription = () => {
        props.preServerCall()

        AdminJHApi.endSubscription({userId},(response)=>{
            props.postServerCall(null, response)
            setShowEndSubscriptionConfirm(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    return (
        <>
            <ConfirmDialog
                title="End Subscription" 
                contentText="Are you sure you want to end subscription" 
                open={showEndSubscriptionConfirm} 
                handleClose={handleClosehEndSubscriptionConfirm}
                handleAgree={handleConfirmEndSubscription}
                //fields={approveDialogField}
            />

            {(error)&&<Typography color="error">{error}</Typography>}
            <Tooltip title="This will set subscription in stripe to end when current billig cycle finished." arrow>
            <Chip
                size="small" 
                label="Subscription"
                variant="default"
                //onClick={handleEditSafetyNet} 
                color="primary" 
                onDelete={handleEndSubscription} 
                deleteIcon={<PowerSettingsNewIcon/>}
                disabled={disabled}
                style={style}
            />
            </Tooltip>
        </>
    )
}