import React from 'react'
import HistApi from "../../utils/HistApi";
import { Typography, ListItem, ListItemAvatar, ListItemText, Divider, Button, Grid, makeStyles } from '@material-ui/core';
import { InterviewSection, months, SquareChip, ShareButtons, ReviewRating } from './helpers';
import CompanyLogo from '../../containers/CompanyLogo';
import { getHost, filterUrl } from '../../utils/utils';
import ReportDialog from '../../containers/ReportDialog';

const ceoJobPerformances = {
    'Approve': {label:'Approves of CEO', color:'green'},
    'Neutral': {label:'No opinion of CEO', color:'yellow'},
    'Disapprove': {label:'Disapproves of CEO', color:'red'}
}

const businessOutlookOptions = {
    'Positive': {label:'Positive Outlook', color:'green'},
    'Neutral': {label:'Neutral Outlook', color:'yellow'},
    'Negative': {label:'Negative Outlook', color:'red'}
}

const recommendsToFriend = {
    'Positive': {label:'Recommends', color:'green'},
    'Negative': {label:"Doesn't Recommend", color:'red'}
}

const useStyles = makeStyles((theme) => ({
    left: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    right: {
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            textAlign: 'left',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
    },
}));

export default function(props) {
    const {review,userId,showLoginDialog,companyUrl} = props
    const classes = useStyles();

    const [helpful, setHelpful] = React.useState(review.helpful||[]);

    const dateCreated = new Date(review.datecreated);

    let employeeText = review.type + ' Employee'
    if (review.title || review.location){
        employeeText = employeeText + ' -'
    }
    if (review.title){
        employeeText = employeeText + ' ' + review.title
    }
    if (review.location){
        employeeText = employeeText + ' in ' + review.location
    }

    let secondEmployeeText
    if (review.type === 'current'){
        secondEmployeeText = 'I have been working at'
    } else if (review.type === 'former'){
        secondEmployeeText = 'I worked at'
    }
    secondEmployeeText = secondEmployeeText + ' ' + review.company
    if(review.employmentType){
        secondEmployeeText = secondEmployeeText + ' ' + review.employmentType.toLowerCase()
    }
    if(review.lengthOfEmployment){
        secondEmployeeText = secondEmployeeText + ' for ' + review.lengthOfEmployment
    }
    
    const url = `${getHost()}/Reviews/Employee-Review-${filterUrl(review.company)}-${review._id}`

    const ceoJobPerformance = ceoJobPerformances[review.ceoJobPerformance]
    const recommendToFriend = recommendsToFriend[review.recommendToFriend]
    const businessOutlook = businessOutlookOptions[review.sixMonthBusinessOutlook]

    const helpfulLength = helpful.length
    const isHelpful = helpful.some(e => e.userId === userId)

    const addHelpful = (id) => {
        if (!userId){
            return showLoginDialog()
        }
        
        HistApi.addHelpful({id, type:'review'}, results => {
            setHelpful(results)
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={0} style={{marginTop:8}}>
                <Grid item xs={6}>
                    <Typography color="textSecondary" component="span" variant="body2">
                        {`${dateCreated.getDate()} ${months[dateCreated.getMonth()]}, ${dateCreated.getFullYear()}`}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign:'right'}}>
                    {(helpfulLength > 0)&&<Typography color="textSecondary" component="span" variant="body2">
                        {`Helpful (${helpfulLength})`}
                    </Typography>}
                </Grid>
            </Grid>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <CompanyLogo companyName={review.company} companyUrl={companyUrl} className="cardLogo"/>
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={review.headline}
                    secondary={
                        <div>
                            <ReviewRating review={review}/>
                            <Typography component="span" variant="body2" color="textPrimary">
                                {employeeText}
                            </Typography>
                            <div style={{marginTop:8}}>
                                {(recommendToFriend)&&<SquareChip label={recommendToFriend.label} color={recommendToFriend.color}/>}
                                {(businessOutlook)&&<SquareChip label={businessOutlook.label} color={businessOutlook.color}/>}
                                {(ceoJobPerformance)&&<SquareChip label={ceoJobPerformance.label} color={ceoJobPerformance.color}/>}
                            </div>
                            <InterviewSection text={secondEmployeeText}/>
                            <InterviewSection label="Pros" text={review.pros}/>
                            <InterviewSection label="Cons" text={review.cons}/>
                        </div>
                    }
                />
            </ListItem>
            <Grid container spacing={0} style={{marginBottom:8}}>
                <Grid item xs={12} sm={6} className={classes.left}>
                    <ShareButtons title={review.headline} description={secondEmployeeText} url={url} 
                        emailText={`Read this review of ${review.company} on JobSwipe. "${review.headline}" `}
                        emailSubject={`${review.company} review on JobSwipe`}/>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.right}>
                    <Button color="primary" variant="outlined" size="small" disabled={isHelpful} onClick={()=>{addHelpful(review._id)}}>
                        {(helpfulLength>0)?`Helpful (${helpfulLength})`:`Helpful`}
                    </Button>
                    <ReportDialog
                        type={"review"}
                        objectId={review._id}
                    />
                </Grid>
            </Grid>
            <Divider/>
        </React.Fragment>
    )
    
}