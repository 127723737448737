import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { InterviewClasses } from '../../styles/InterviewClasses';
import { FIELD_TYPES, getHebrewDateText, isHebrewText } from '../../utils/utils';
import { Fields } from '../Fields';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InterviewsApi from "../../utils/InterviewsApi"
import { Pagination } from '@material-ui/lab';

export default function InterviewSingle(props) {
    const isAuthed = props.isAuthed || false
    const userObjects = props.userObjects || []
    const urlParams = props.urlParams || ''
    const page = props.page || 1
    
    const searchParams = new URLSearchParams(window.location.search);
    const isParamNewReply= searchParams.get("new")==='true';

    const topic = props.topic || {}
    const list = props.list || []
    const isLoading = props.isLoading || false
    
    const [showDialog, setShowDialog] = useState(isParamNewReply);
    const [dialogData, setDialogData] = useState({});
    const [error, setError] = useState();
    const [mode, setmode] = useState('Add');

    const [randoms, setRandoms] = useState([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)]);

    const handleCloseNewReply = () => {
        setShowDialog(false);
    };

    const handleOpenNewReply = () => {
        setRandoms([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)])

        if (mode !== 'Add'){
            setmode('Add')
            setDialogData({});
        } else {
            setDialogData({...dialogData,validate: ''})
        }
        setError()
        setShowDialog(true);
    };

    const handleEditInterview = interview => () => {
        setmode('Edit')
        setDialogData(interview);
        setError()
        setShowDialog(true);
    }

    const handleDeleteInterview = interview => () => {
        setmode('Delete')
        setDialogData(interview);
        setError()
        setShowDialog(true);
    }

    const saveDialog = e => {
        e.preventDefault() 

        switch (mode){
            case 'Add':{
                saveNewReply();
                break;
            } case 'Edit':{
                saveEditInterview();
                break;
            } case 'Delete':{
                saveDeleteInterview();
                break;
            } default: {
                setmode('Add')
                setDialogData({});
                setShowDialog(false);
            }
        }
    }

    const saveNewReply = e => {
        props.preServerCall()

        InterviewsApi.addReply({urlParams, interviewId:topic._id, reply:dialogData, randoms, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            setRandoms([Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 99) + 1, Math.floor(Math.random() * 2)])
            setDialogData({...dialogData,validate: ''})

            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const saveEditInterview = () => {
        props.preServerCall()

        InterviewsApi.editInterview({urlParams, interview:dialogData, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const saveDeleteInterview = () => {
        props.preServerCall()

        InterviewsApi.deleteInterview({urlParams, interview:dialogData, page},(response)=>{
            props.postServerCall(response)

            setShowDialog(false);
            setDialogData({})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const handleChange = e => {
        setDialogData({...dialogData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (dialogData[name] !== undefined){
            return dialogData[name]
        } else {
            return defaultValue
        }
    }

    const isReplyDialog = (mode==='Add' || dialogData.isReply)
    const isDeleteDialog = (mode === 'Delete')

    const fields = isReplyDialog ? [
        {name:'displayName', label:'שם להצגה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'content', label:'', defaultValue: '', variant:"outlined", type:FIELD_TYPES.MULTILINE, rows:6, rowsMax:6, sm:12, xs:12, required:true}
    ] : [
        {name:'title', label:'נושא', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'displayName', label:'שם להצגה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'content', label:'', defaultValue: '', variant:"outlined", type:FIELD_TYPES.MULTILINE, rows:6, rowsMax:6, sm:12, xs:12, required:true}
    ]

    const question = 'איזה מספר יותר ' + (randoms[2]===0?'גדול ':'קטן ') + (randoms[0]) + ' או ' + ((randoms[1]===randoms[0])?randoms[1]+1:randoms[1])

    let hebrewMode = ''
    switch (mode) {
        case 'Add':{
            hebrewMode = 'הוסף'
            break;
        } case 'Edit':{
            hebrewMode = 'ערוך'
            break;
        } case 'Delete':{
            hebrewMode = 'מחק'
            break;
        } default:
            break;
    }

    const classes = InterviewClasses();

    return (
        <>
            <Dialog onClose={handleCloseNewReply} open={showDialog} fullScreen className='hebrew-text'>
                <div style={{maxWidth:1200, width:'100%', margin:'0 auto'}}>
                <DialogTitle>{hebrewMode} {(isReplyDialog)?'תגובה':'ראיון'}</DialogTitle>
                <form onSubmit={saveDialog}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        {(isDeleteDialog)?<Typography>למחוק את ה{(isReplyDialog)?'תגובה':'ראיון עם התגובות'}?</Typography>:
                        <Fields fields={fields} getValue={getValue} handleChange={handleChange}/>}
                        {(!isAuthed)&&<Grid container spacing={1}>
                            <Grid item sm={12} xs={12}>
                                <TextField name="validate" label={question} margin="none" fullWidth 
                                    required value={getValue('validate', '')} onChange={handleChange}
                                    helperText={'שאלה זו היא אמצעי למניעת שליחת טפסים אוטומטית על ידי ספאם בוטים.'} />
                            </Grid>
                        </Grid>}
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'flex-start', padding: '8px 24px'}}>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label={isDeleteDialog?'כן':'שמור'}
                        >
                            {isDeleteDialog?'כן':'שמור'}
                        </Button>
                        <Button disabled={isLoading} onClick={handleCloseNewReply} color="primary" size="small" aria-label="Cancel">
                            ביטול
                        </Button>
                    </DialogActions>
                </form>
                </div>
            </Dialog>
            <div className={classes.paginationButtonContainer}>
                <div className={classes.paginationContainer}>
                    {props.paginationParams.show&&<Pagination {...props.paginationParams}/>}
                </div>
                <Button disabled={isLoading} onClick={handleOpenNewReply} color="primary" variant="contained" aria-label="Add Reply">+ תגובה</Button>
            </div>
            <List className={classes.interviews}>
                <ListItem className={classes.interviewHeader}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} style={{padding: "16px 8px"}}>
                            <div style={{fontWeight: 'bold'}} dir="auto">{topic.title}</div>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem className={clsx(classes.interview, classes.sticky)}>
                    <Grid container spacing={2}>
                        <Grid item sm={2} xs={6} style={{padding: "16px 8px"}}>
                            {(!topic.sticky)&&<div>{getHebrewDateText(topic.datecreated)}</div>}
                            <div>ע"י: {topic.displayName}</div>
                        </Grid>
                        <Grid item sm={10} xs={12} style={{padding: "16px 8px"}}>
                            <div dir="auto" style={{whiteSpace: 'pre-line', textAlign: isHebrewText(topic.content)?'right':'left'}} dangerouslySetInnerHTML={{__html: topic.content}} />
                        </Grid>
                    </Grid>
                </ListItem>
                {(userObjects.includes(topic._id))&&
                <ListItem style={{borderTop:'none'}} className={clsx(classes.interview, classes.sticky)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                style={{marginLeft:8}}
                                variant="outlined"
                                color="primary"
                                size='small'
                                onClick={handleEditInterview(topic)}
                                startIcon={<EditIcon style={{marginLeft: 8, marginRight: -10}}/>}
                            >
                                ערוך
                            </Button>
                            <Button
                                onClick={handleDeleteInterview(topic)}
                                variant="outlined"
                                color="primary"
                                size='small'
                                startIcon={<DeleteIcon style={{marginLeft: 8, marginRight: -10}}/>}
                            >
                                מחק
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>}
            {list.map((interview, index) =>
                <Fragment key={interview._id}>
                    <ListItem className={clsx(classes.interview, (index%2===0)&&classes.odd)}>
                        <Grid container spacing={2}>
                            <Grid item sm={2} xs={6} style={{padding: "16px 8px"}}>
                                <div>{getHebrewDateText(interview.datecreated)}</div>
                                <div>ע"י: {interview.displayName}</div>
                            </Grid>
                            <Grid item sm={10} xs={12} style={{padding: "16px 8px"}}>
                                <div dir="auto" style={{whiteSpace: 'pre-line', textAlign: isHebrewText(interview.content)?'right':'left'}} dangerouslySetInnerHTML={{__html: interview.content}} />
                            </Grid>
                        </Grid>
                    </ListItem>
                    {(userObjects.includes(interview._id))&&
                    <ListItem style={{borderTop:'none'}} className={clsx(classes.interview, (index%2===0)&&classes.odd, interview.sticky&&classes.sticky)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    style={{marginLeft:8}}
                                    variant="outlined"
                                    color="primary"
                                    size='small'
                                    onClick={handleEditInterview(interview)}
                                    startIcon={<EditIcon style={{marginLeft: 8, marginRight: -10}}/>}
                                >
                                    ערוך
                                </Button>
                                <Button
                                    onClick={handleDeleteInterview(interview)}
                                    variant="outlined"
                                    color="primary"
                                    size='small'
                                    startIcon={<DeleteIcon style={{marginLeft: 8, marginRight: -10}}/>}
                                >
                                    מחק
                                </Button>
                            </Grid>
                        </Grid>
                    </ListItem>}
                </Fragment>
            )}
            </List>
        </>
    )
}