import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';

import '../../styles/ShareDialog.css'

export default class JobsListDialog extends React.Component {
	handleClose = () => {
		this.props.onClose()
    };
    
    handleJobClicked = jobId => {
        this.props.handleApplyToProfile(jobId)
        this.handleClose()
    }

	render(){
		const { handleApplyToProfile, ...other } = this.props;
		const jobs = this.props.jobs || []

		return (
			<Dialog onClose={this.handleClose} aria-labelledby="share-dialog-title" {...other}>
				<DialogTitle style={{minWidth:155}}>Choose a job</DialogTitle>
				<DialogContent>
					<span className="close-modal" onClick={this.handleClose}>
						<Close/>
					</span>

                    {jobs.map((job) =>
                        <div key={job._id}>
                            <Button color="primary" className="modalOption" onClick={() => this.handleJobClicked(job._id)} aria-label={job.title}>
                                {job.title}
                            </Button>
                        </div>
                    )}
				</DialogContent>
			</Dialog>
		)
	}
}