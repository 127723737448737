import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AdminJHApi from "../../../utils/AdminJHApi"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getNameFromEmail, showDateTimeFromString } from '../../../utils/utils';

export default function CoverLetterChanges(props) {
    const changesDialog = props.changesDialog || {}

    const open = changesDialog.open || false
    const id = changesDialog.id
    const journeyId = changesDialog.journeyId

    const [changes, setChanges] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open && journeyId){
            setIsLoading(true)

            AdminJHApi.getCoverLetterChanges({journeyId, id},(response)=>{
                setChanges(response)
                setIsLoading(false)
            }, (error) => {
                setIsLoading(false)
            })
        } else {
            setChanges([])
        }
    }, [open, id, journeyId]);

    const handleClose = () => {
        props.setShowJourneyChanges({open:false})
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
        >
            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center'}} elevation={1}>
                <div style={{flex: "1 1 0%"}}></div>
                <IconButton aria-label="close" color="primary" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </Paper>
            <div style={{margin:'2px 0', position:'relative', overflow:'auto'}}>
                {isLoading ? <></>:<>
                    {changes.map(change => (
                        <Accordion key={change._id} style={{margin:'2px 4px'}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{getNameFromEmail(change.byUserEmail)} - {showDateTimeFromString(change.dateCreated, false)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <Paper style={{padding:8}}>
                                            <Typography style={{textAlign:'center'}} variant='h6' color='primary'>Before</Typography>
                                            <div dangerouslySetInnerHTML={{__html: (change.before||{}).content||''}} />
                                        </Paper>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Paper style={{padding:8}}>
                                            <Typography style={{textAlign:'center'}} variant='h6' color='primary'>After</Typography>
                                            <div dangerouslySetInnerHTML={{__html: (change.after||{}).content||''}} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>}
            </div>
        </Dialog>
    )
}