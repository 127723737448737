import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { ConfirmDialog } from '../dialogs/ConfirmDialog'
import { ProfileActions } from './ProfileActions'
import Months from './Months'

import '../../styles/Profile.css'

export default class Experience extends Component {
    state = {
        openConfirmDialog: false,
    };
    
    handleOpenConfirmDialog = () => {
        this.setState({ openConfirmDialog: true });
    };
    
    handleCloseConfirmDialog = () => {
        this.setState({ openConfirmDialog: false });
    };

    handleEdit = () => {
        this.props.handleEdit(this.props.index)
    }

    handleDelete = () => {
        this.props.handleDelete(this.props.index)
        this.handleCloseConfirmDialog()
    }

    handleChange = event => {
        const newExperience = Object.assign({}, this.props.experience, {
            [event.target.name]: event.target.value
        })

        this.props.handleChange(this.props.index, newExperience)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.props.index)
    };

    handleCancel = () => {
        this.props.handleCancel()
    }

    getMonthLabel = (month) => {
        if (month){
            return Months[month].label
        }
        return ''
    }

    getDatesString = (data) => {
        let text;

        text = this.getMonthLabel(data.startMonth - 1) + ' ' + data.startYear + ' - '

        if (data.endMonth && data.endYear) {
            text = text + this.getMonthLabel(data.endMonth - 1) + ' ' + data.endYear
        } else {
            text = text + 'present'
        }

        return text;
    }

    render() {
        const {editMode, experience, noEdit} = this.props
        //const searchWords = this.props.searchWords ||  []
        
        return (
            <div className="profileListSection">
                {(editMode)?
                <form onSubmit={this.handleSaveSection}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <TextField id="title" name="title" label="Title" margin="none" fullWidth 
                                required value={experience.title || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="company" name="company" label="Company" margin="none" fullWidth 
                                required value={experience.company || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="location" name="location" label="Location" margin="none" fullWidth 
                                value={experience.location || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="startMonth" name="startMonth" label="Start Month" margin="none" fullWidth 
                                required select value={experience.startMonth || ''} onChange={this.handleChange}>
                                {Months.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="startYear" name="startYear" label="Start Year" margin="none" fullWidth 
                                required type="number" value={experience.startYear || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="endMonth" name="endMonth" label="End Month" margin="none" fullWidth 
                                select type="number" value={experience.endMonth || ''} onChange={this.handleChange}>
                                {Months.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="endYear" name="endYear" label="End Year" margin="none" fullWidth 
                                type="number" value={experience.endYear || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <TextField id="description" name="description" label="Description" margin="none" fullWidth 
                                multiline rowsMax="4" value={experience.description || ''} onChange={this.handleChange}/>
                        </Grid>
                    </Grid>
                    <div className="profileButtons">
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.handleCancel}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save Changes"
                        >
                            Save Changes
                        </Button>
                    </div>
                </form> :
                <React.Fragment>
                    {(!noEdit)&&<React.Fragment>
                    <ConfirmDialog 
                        title="Delete Experience" 
                        contentText="Are you sure you want to delete this experience?" 
                        open={this.state.openConfirmDialog} 
                        handleClose={this.handleCloseConfirmDialog}
                        handleAgree={this.handleDelete}/>
                    <ProfileActions handleEdit={this.handleEdit} handleDelete={this.handleOpenConfirmDialog}/></React.Fragment>}
                    <div className="profileField list">
                        <div className="title">
                            {experience.title || ''}
                            {/*<Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={experience.title || ''}
                            />*/}
                        </div>
                        <div className="underTitle">{experience.company}</div>
                        {(experience.location || !noEdit)&&<div className="location">
                            {(experience.location)?
                                <React.Fragment>{experience.location}</React.Fragment>:
                                <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Location</span></React.Fragment>}
                        </div>}
                        {(experience.description || !noEdit)&&<div className="description">
                            {(experience.description)?
                                <React.Fragment>
                                    {experience.description || ''}
                                    {/*<Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={searchWords}
                                        autoEscape={true}
                                        textToHighlight={experience.description || ''}
                                    />*/}
                                </React.Fragment>:
                                <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Description</span></React.Fragment>}
                        </div>}
                        <div className="dates">{this.getDatesString(experience)}</div>
                    </div>      
                </React.Fragment>}
            </div>            
        )
    }
}