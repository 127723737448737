import React, { useState, useEffect } from 'react';
import InterviewList from './InterviewList';
import InterviewSingle from './InterviewSingle';
import InterviewsApi from "../../utils/InterviewsApi"
import { Button, TextField } from '@material-ui/core';
import { filterUrl } from '../../utils/utils';

export default function Interviews(props) {
    const isAuthed = props.isAuthed || false

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [isInterview, setIsInterview] = useState(undefined);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(1);
    const [topic, setTopic] = useState({});
    const [userObjects, setUserObjects] = useState();

    const pages = Math.ceil(count/rowPerPage);

    let urlParams = undefined
    if (props.match.params  && props.match.params.param){
        urlParams = props.match.params.param
    }

    useEffect(() => {
        setIsLoading(true)

        InterviewsApi.getInterviews({urlParams},(response)=>{
            updateDataByRespone(response)

            setIsLoading(false)
        }, (error) => {
            console.log(error)
            setIsLoading(false)
            //setIsError(true)
        })
    }, [urlParams, isAuthed]);

    const handleSearchType = e => {
        setSearchText(e.target.value)
    };

    const handleSearch = e => {
        e.preventDefault() 

        let newUrl = '';
        let queryString = ''
        /*let title = '';
        let description = ''*/

        if (searchText){
            queryString = filterUrl(searchText);
            newUrl = newUrl + queryString + '-';

            newUrl = newUrl + 'Interviews'

            newUrl = newUrl + '-SRCH'
            newUrl = newUrl + '_Q' + 0 + ',' + queryString.length
        }
        
        /*if (searchText){
            title = title + searchText + ' ';
        }
        title = title + 'Interviews'
        title = title.charAt(0).toUpperCase() + title.slice(1);
        description = title;*/

        props.changeUrl('/interviews/'+newUrl)
    }

    const handleChangePage = (event, value) => {
        let newUrl = '';
        let queryString = ''

        if (isInterview){
            newUrl = newUrl + topic._id
        } else {
            if (searchText){
                queryString = filterUrl(searchText);
                newUrl = newUrl + queryString + '-';
    
                newUrl = newUrl + 'Interviews'
    
                newUrl = newUrl + '-SRCH'
                newUrl = newUrl + '_Q' + 0 + ',' + queryString.length
            } else {
                if (value!==1){
                    newUrl = newUrl + 'Interviews'
                    newUrl = newUrl + '-SRCH'
                }
            }
        }

        if (value!==1){
            newUrl = newUrl + '_P' + value
        }

        props.changeUrl('/interviews/'+newUrl)
    };

    const preServerCall = () => {
        setIsLoading(true)
    }

    const postServerCall = (response) => {
        if(response){
            if (response.added){
                setIsLoading(false)
                handleChangePage(null, 1)
            } else {
                updateDataByRespone(response)
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
        }
    }

    const updateDataByRespone = response => {
        if (response.notFound){
            setIsInterview('')
        } else {
            setRowPerPage(response.rowPerPage)
            setPage(response.page);
            setList(response.list)    
            setCount(response.count)
            setIsInterview(response.isInterview)
            
            if (response.keyWords){
                setSearchText(response.keyWords)
            }
            if (response.topic){
                setTopic(response.topic)
            }
            if(response.userObjects){
                setUserObjects(response.userObjects)
            } else {
                setUserObjects([])
            }
        }
    }

    const propsToChildren = {
        isLoading,
        userObjects,
        list,
        preServerCall,
        postServerCall,
        urlParams, 
        page,
        paginationParams: {show:(pages>1), style:{direction:'ltr'}, disabled:isLoading, count:pages, page:page, onChange:handleChangePage}
    }

    return (
        <div className="pageMainContainer hebrew-text">
            <div style={{padding: '0 15px'}}>
                <form onSubmit={handleSearch} style={{padding: '16px 0', display:'flex'}}>
                    <TextField 
                        id="keyWords" 
                        className="keywordsField" 
                        //placeholder="Search" 
                        variant="outlined" 
                        type="search" 
                        value={searchText} 
                        onChange={handleSearchType}
                        disabled={isLoading}
                    />
                    <Button 
                        type="submit"
                        className="searchButtonD" 
                        variant="contained" 
                        color="primary" 
                        aria-label="חיפוש" 
                        onClick={handleSearch}
                        disabled={isLoading}
                        style={{height: 43}}
                    >חיפוש</Button>
                </form>
                {/*(!isLoading&&pages>1)&&<Pagination style={{direction:'ltr'}} disabled={isLoading} count={pages} page={page} onChange={handleChangePage} />*/}
                {isInterview===true&&<InterviewSingle {...propsToChildren} topic={topic} {...props}/>}
                {isInterview===false&&<InterviewList {...propsToChildren} {...props}/>}
            </div>
        </div>
    )  
}