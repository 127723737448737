import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import HistApi from "../../utils/HistApi";

import '../../styles/Profile.css'
import UploadDialog from '../dialogs/UploadDialog';
import ReactGA from 'react-ga4'

export default class ResumeUpload extends Component {
    state = {
        selectedFile: null, 
        uploading: false,
        uploadTitle: '',
    }

    handleSelectedFile = event => {
        const maxUploadResumeSize = 500 * 1024; // 500 KB
        const file = event.target.files[0];

        if (!file) {
            return null
        }

        if(file.size > maxUploadResumeSize){
            this.props.showDialog('Upload Resume', 'File too big - 500 KB Max')
            return null
        }
        
        const extension =  file.name.split('.').pop();
        if(!extension.match(/docx|doc|pdf/ig)){
            this.props.showDialog('Upload Resume', 'Invalid extension')
            return null
        }

        ReactGA.event({category: 'uploadingResume',action: 'PC_uploadingStart_profile'});

        this.setState({uploading: true, uploadTitle: 'Uploading...'})
        this.timer = setTimeout((this.changeUploadTitle), 2000);

        const data = new FormData()
        data.append('file', file, file.name)

        HistApi.uploadResumeR(data, (results)=>{
            clearTimeout(this.timer);
            this.setState({uploading: false, uploadTitle: ''})
            if(results.userData && results.userData.visibleSearchEngines){
                this.props.showDialog('Upload Resume', 'Resume uploaded successfully')
            } else {
                this.props.showEnableSearchEngines('Resume uploaded successfully', 'Show resume on search engines? Recruiters can find you on Google, Bing and other search engines')
            }
            ReactGA.event({category: 'uploadingResume',action: 'PC_uploadingSuccess_profile'});
            this.props.handleNewResume(results.userData)
        }, (error)=>{
            this.setState({uploading: false, uploadTitle: ''})
            this.props.showDialog('Upload Resume', error)
            ReactGA.event({category: 'uploadingResume',action: 'PC_uploadingFailed_profile'});
        });
    }

    changeUploadTitle = () => {
        this.setState({uploadTitle: 'Analyzing...'})
        clearTimeout(this.timer);
    }

    render() {
        const {selectedjobbgcolor} = this.props

        return (
            <React.Fragment>
                <UploadDialog open={this.state.uploading} title={this.state.uploadTitle}/>
                <input
                    accept=".doc,.docx,.pdf"
                    id="contained-button-file"
                    type="file"
                    style={{display:"none"}}
                    onChange={this.handleSelectedFile}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span" style={{backgroundColor:selectedjobbgcolor}} aria-label="Upload">
                        Upload New File
                    </Button>
                </label>
            </React.Fragment>
        )
    }
}