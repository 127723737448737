import React, { Component } from 'react'
import { connect } from 'react-redux'

class UserInfo extends Component {
    render() {
        const userEmail = this.props.email
        return (
            <React.Fragment>
                {React.cloneElement(this.props.children, {userEmail})}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const authUser = state.authUser || {};
    const userInfo = authUser.userInfo || {}

    return {
        email:userInfo.email
    }
}

export default connect(mapStateToProps)(UserInfo)