import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, Chip,  IconButton, Paper, TextField, Tooltip } from '@material-ui/core';
import { dateAsString, FIELD_TYPES, PESONAL_JOURNEY_STEPS_NAMES, removeDuplicate, SENIORITIES } from '../../utils/utils';
import { Fields } from '../Fields';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import { PersonalJourneyClasses } from '../../styles/PersonalJourneyClasses';
import CVManager from './personalJourney/CVManager';
import CoverLetterManager from './personalJourney/CoverLetterManager';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 800,
        fontSize: 14,
        border: '1px solid #dadde9',
        padding: '8px 16px'
    },
  }))(Tooltip);

  export function PersonalJourneyExplainNew(props) {
    const explenations = props.explenations || []
    const index = props.index    

    const name = PESONAL_JOURNEY_STEPS_NAMES[index]
    const text = explenations[name]

    return (
        <>
            {(text)&&<HtmlTooltip title={<span dangerouslySetInnerHTML={{__html: text||''}} />}>
                <InfoIcon fontSize='small' color='disabled' style={{marginLeft: 4,color: '#ababab',height: 16,width: 16}}/>
            </HtmlTooltip>}
        </>
    )
}

export function TitleDetailsStep(props) {
    //const explenations = props.explenations || {}
    const journeyData = props.journeyData || {}
    const sawExplenations = journeyData.sawExplenations || {}
    const isLoading = props.isLoading || false

    const defNewData = {}

    const [newData, setNewData] = useState(defNewData);

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else if (journeyData[name] !== undefined){
            return journeyData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleSaveFields = e => {
        e.preventDefault();

        let newSawExplenations = {...sawExplenations}

        newSawExplenations.general = true
        newSawExplenations.TitleDetails = true

        props.handleSaveData({...newData, sawExplenations:newSawExplenations, goToNextStep:journeyData.currentStep===0},(response)=>{
            setNewData(defNewData)
        }, (error) => {

        })
    }

    const handleCancelFields = () => {
        setNewData(defNewData)
    }

    const handleChangeTitle = (titleGroup, titleCategories) => {
        if (getValue('title', '')){
            setNewData({...newData, titleGroup, titleCategories})
        } else {
            setNewData({...newData, title:titleGroup, titleGroup, titleCategories})
        }
    }

    const fields = [
        {name:'titleGroup', label:'Professional Title', type:FIELD_TYPES.TITLE, handleChangeTitle, sm:12, required: true},
        {name:'title', label:'Professional Title (Free Text)', defaultValue: '', hide: (!getValue('titleGroup', '') && !getValue('title', '')) , type:FIELD_TYPES.TEXT, sm:12, required: true},
        //{name:'title', label:'Professional Title', defaultValue: '', type:FIELD_TYPES.TEXT, placeholder:'Example: Java Developer', sm:12, required: true},
        {name:'email', label:'Email', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required: true},
        {name:'first_name', label:'First name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required: true},
        {name:'last_name', label:'Last name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required: true},
        {name:'seniorityLevel', label:'Seniority', defaultValue: '', type:FIELD_TYPES.SELECT, options:SENIORITIES, sm:12},
    ]

    const classes = PersonalJourneyClasses();

    return (
        <>
            <Paper className={classes.prePublishSection} elevation={1}>

                <form onSubmit={handleSaveFields}>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleCancelFields}
                        aria-label="Cancel"
                        className={classes.cancelButton}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label="Save Changes"
                        className={classes.saveButton}
                        disabled={isLoading}
                    >
                        Save Changes
                    </Button>
                </form>
            </Paper>
        </>
    )
}

export function CoverLetterStep(props) {
    //const explenations = props.explenations || {}
    //const journeyData = props.journeyData || {}

    //const sawExplenations = journeyData.sawExplenations || {}

    const classes = PersonalJourneyClasses();

    return (
        <>
            <Paper className={classes.prePublishSection} elevation={1}>
                <CoverLetterManager {...props}/>
            </Paper>
        </>
    )
}

export function CVStep(props) {
    //const explenations = props.explenations || {}
    //const journeyData = props.journeyData || {}
    //const sawExplenations = journeyData.sawExplenations || {}

    const classes = PersonalJourneyClasses();

    return (
        <>
            <Paper className={classes.prePublishSection} elevation={1}>
                <CVManager {...props}/>
            </Paper>
        </>
    )
}

export function ExcludeDetailsStep(props) {
    //const explenations = props.explenations || {}
    const journeyData = props.journeyData || {}
    const sawExplenations = journeyData.sawExplenations || {}
    const excludeList = journeyData.excludeList || []
    const isLoading = props.isLoading || false

    const [newExcludeListItem, setNewExcludeListItem] = useState('');
    const [isNewExcludeListItem, setIsNewExcludeListItem] = useState(false);

    const handleDeleteExcludeListItem = excludeListItem => {
        const lowerCaseExcludeListItem = (excludeListItem) ? excludeListItem.toLowerCase() : ''
        const newExcludeList = excludeList.filter(item => item.toLowerCase() !== lowerCaseExcludeListItem)

        let newSawExplenations = {...sawExplenations}

        newSawExplenations.general = true
        newSawExplenations.ExcludeDetails = true

        props.handleSaveData({excludeList:newExcludeList, sawExplenations:newSawExplenations},(response)=>{
            /*setIsNewExcludeListItem(false)
            setNewExcludeListItem('')*/
        }, (error) => {

        })
    }

    const handleAddExcludeListItem = () => {
        setIsNewExcludeListItem(true)
    }

    const handleSaveExcludeListItem = () => {
        const newExcludeList = removeDuplicate([...excludeList, newExcludeListItem.toLowerCase()])

        let newSawExplenations = {...sawExplenations}

        newSawExplenations.general = true
        newSawExplenations.ExcludeDetails = true

        props.handleSaveData({excludeList:newExcludeList, sawExplenations:newSawExplenations},(response)=>{
            setIsNewExcludeListItem(false)
            setNewExcludeListItem('')
        }, (error) => {

        })
    }

    const handleCancelAddExcludeListItem = () => {
        setIsNewExcludeListItem(false)
        setNewExcludeListItem('')
    }

    const handleChange = e => {
        setNewExcludeListItem(e.target.value)
    };

    const classes = PersonalJourneyClasses();

    return (
        <>
            <Paper className={classes.prePublishSection} elevation={1}>
                {excludeList.map(excludeListItem => 
                    <Chip key={excludeListItem} className="profileTag" label={excludeListItem} variant="outlined" 
                        onDelete={(isNewExcludeListItem?null:()=>handleDeleteExcludeListItem(excludeListItem))} 
                    />
                )}
                {(isNewExcludeListItem)?<>
                    <TextField onChange={handleChange} value={newExcludeListItem}/>
                    <IconButton color='primary' onClick={handleSaveExcludeListItem} aria-label="save" disabled={(isLoading || !newExcludeListItem)}>
                        <SaveIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={isLoading} color='primary' onClick={handleCancelAddExcludeListItem} aria-label="cancel">
                        <CancelIcon fontSize="small" />
                    </IconButton>
                </>:
                <IconButton disabled={isLoading} color='primary' onClick={handleAddExcludeListItem} aria-label="add">
                    <AddIcon fontSize="small" />
                </IconButton>}
                {(journeyData.isPublishUpdateNeeded)&&<div style={{marginTop:16}}>
                    <Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={props.updatePublish} aria-label="Update Publish">Update Publish</Button>
                </div>}
            </Paper>
        </>
    )
}

export function TestEmailStep(props) {
    //const explenations = props.explenations || {}
    const journeyData = props.journeyData || {}
    //const sawExplenations = journeyData.sawExplenations || {}
    const isLoading = props.isLoading || false

    const isNeedTest = (journeyData.isNeedTest === undefined) ? true : journeyData.isNeedTest

    const [showStartPublishDialog, setShowStartPublishDialog] = useState(false);

    const handleShowStartPublishDialog = () => {
        setShowStartPublishDialog(true)
    }

    const handleCloseStartPublishDialog = () => {
        setShowStartPublishDialog(false)
    }

    const handleStartPublish = (fields) => {
        setShowStartPublishDialog(false)
        props.startPublish(fields.beginPublish)
    }

    let beginPublishValues = [{"value": "","label": "As soon as posible"}]

    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    for (let i=1; i<=7; i++){
        const today = new Date();
        const newDay = new Date(today.setDate(today.getDate() + i));

        const dayOfWeek = newDay.getDay()

        if (dayOfWeek!==5 && dayOfWeek!==6){
            beginPublishValues.push({"value": newDay,"label": dateAsString(newDay) + ' - ' + weekdays[dayOfWeek]})
        }
    }

    /*const approveDialogField = [
        {name:'beginPublish', label:'Begin Publish', defaultValue: '', options:beginPublishValues, type:FIELD_TYPES.SELECT, sm:12, displayEmpty:true},
    ]*/

    const classes = PersonalJourneyClasses();

    return (
        <>
            <ConfirmDialog
                title="Approve" 
                contentText="Are you sure you want to approve" 
                open={showStartPublishDialog} 
                handleClose={handleCloseStartPublishDialog}
                handleAgree={handleStartPublish}
                //fields={approveDialogField}
            />

            {(journeyData.publishDeclineReason)&&<Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='body1' dir="auto" style={{whiteSpace:"pre"}}>{journeyData.publishDeclineReason}</Typography>
            </Paper>}

            <Paper className={classes.prePublishSection} elevation={1}>
                <Button disabled={isLoading || journeyData.isPublish} variant="contained" color="primary" size="small" onClick={props.sendTest} aria-label="Send Test">Send Test To Email</Button><br/><br/>
                <Button disabled={isLoading || journeyData.isPublish || isNeedTest || !journeyData.lastTestId} variant="contained" color="primary" size="small" onClick={handleShowStartPublishDialog} aria-label="Approve">Approve</Button>
            </Paper>
        </>
    )
}

/*export function PublishStep(props) {
    const explenations = props.explenations || {}
    const journeyData = props.journeyData || {}
    const sawExplenations = journeyData.sawExplenations || {}
    const isLoading = props.isLoading || false

    const [showNewPublishDialog, setShowNewPublishDialog] = useState(false);

    const handleConfirmNewPublish = () => {
        setShowNewPublishDialog(true)
    }

    const handleCloseNewPublishDialog = () => {
        setShowNewPublishDialog(false)
    }

    const handleNewPublish = () => {
        setShowNewPublishDialog(false)
        props.newPublish()
    }

    const classes = PersonalJourneyClasses();

    return (
        <>
            <ConfirmDialog
                title="New Publish" 
                contentText="Are you sure you want new publish?" 
                open={showNewPublishDialog} 
                handleClose={handleCloseNewPublishDialog}
                handleAgree={handleNewPublish}
            />

            {(!props.isAdmin)&&<Paper className={classes.prePublishSection} elevation={1}>
                <div style={{textAlign: "right", direction: "rtl", lineHeight: 1.5}}>
                תודה שאישרת את הפרסום, הוא מייד יוצא להפצה.
                <br /><br />
מספר דברים שיש לשים לב אליהם:
<br />
1. להיות זמין - הדבר החשוב ביותר היא מידת הזמינות שלך. ענה לטלפון, בדוק את המייל והווטסאפ. זכור, בזמן שלא ענית המגייסת עוברת למועמד אחר. במידה וזומנת לראיון, לא לדחות אותו- יש להגיע בזמן.
<br />
2. אין לשלול הזדמנויות מראש, יש להגיע לראיון ורק לאחר ששמעת את ההצעה המלאה תוכל לשקול את הדברים ביתר בהירות
<br />
3. תיעוד הנתונים - יש חשיבות רבה לתיעוד השיחות. רק כך נוכל לנתח את התוצאות הסבב ולהבין על מה יש לעבוד בפרסום הבא.
<br />
4. זכור, אנחנו פה ביחד איתך, לכל שאלה, עדכון או מידע שאתה חושב שצריך להעביר בזמן אמת, צור קשר עם המנהל שמלווה אותך.
                </div> 
            </Paper>}

            {(journeyData.isPublishUpdateNeeded || props.isAdmin)&&<Paper className={classes.prePublishSection} elevation={1}>
                {(journeyData.isPublishUpdateNeeded)&&<Button style={{marginRight:16}} disabled={isLoading} variant="contained" color="primary" size="small" onClick={props.updatePublish} aria-label="Update Publish">Update Publish</Button>}
                {(props.isAdmin)&&<Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleConfirmNewPublish} aria-label="New Publish">New Publish</Button>}
            </Paper>}
        </>
    )
}*/