import { fetchData } from "./histogramJobs";
import { setHistogramSingle } from "./histogram";

export const SET_SEARCH_JOBS = 'SET_SEARCH_JOBS'

export const setSearchJobsSingle = (histogramSingle = false) => dispatch => {
    dispatch(setSearchJobs({}, null, null, histogramSingle))
}

export const setSearchJobs = (params={}, queryParams, searchType, histogramSingle = false) => dispatch => {
    const validateParams = {
        queryString: (params.queryString || '').trim(),
        locationCode: params.locationCode || '',
        locationObj: params.locationObj || {},
        datePosted: params.datePosted,
        email: (params.email || '').trim(),
    }
    
    dispatch({type: SET_SEARCH_JOBS, searchJobs:validateParams});
    dispatch(setHistogramSingle(histogramSingle))
    dispatch(fetchData(validateParams, false, queryParams, searchType))
}

export const getLocationText = state => {
    const locationObj = getLocationObject(state)
    return (locationObj&&locationObj.text) ? locationObj.text : ''
}

export const getLocationObject = state => {
    const { locations, searchJobs } = state;
    const { locationObj, locationCode } = searchJobs

    if (locationCode && locations.locations[locationCode]){
        let locationCodeObject = locations.locations[locationCode]
        return {
            lng: locationCodeObject.lng,
            lat: locationCodeObject.lat,
            text:((locationCodeObject.name) ? locationCodeObject.name + ', ' : '') + locationCodeObject.country,
            locality: locationCodeObject.name,
            country: locationCodeObject.country
        }
    } else if (locationObj.lng && locationObj.lat && locationObj.text){
        return locationObj
    } else {
        return {}
    }
}