import React, { useState, useEffect } from 'react';
import JSPaper from '../../JSPaper';
import JSData from '../../JSData';
import JHApi from "../../../utils/JHApi"
import AdminJHApi from "../../../utils/AdminJHApi"
import ProjectsDialog from './ProjectsDialog';
import { FormControlLabel, Switch } from '@material-ui/core';

export default function Projects(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journetId = journeyData._id

    const [localLoading, setLocalLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [objectToEdit, setObjectToEdit] = useState();

    const disabled = isLoading || localLoading

    const cells = [
        { id: 'name', align: "left", label: 'Project name'}, 
        { id: 'dateStart', align: "left", label: 'Start Date', type: 'DATE'}, 
        { id: 'dateEnd', align: "left", label: 'End Date', type: 'DATE'}, 
        { id: 'gitLink', align: "left", label: 'Link to git', type: 'LINK', linkLabel: 'Link'},
        { id: 'totalHours', align: "left", label: 'Total hours'},
        { id: 'totalDays', align: "left", label: 'Total days'},
        { id: 'summary', align: "left", label: 'Short summary, code level'},
    ];

    useEffect(() => {
        if (journetId){
            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setLocalLoading(true)

            JHApi.getTalentProjects(paramsToPass,(response)=>{
                if(response){
                    setProjects(response.projects||[])
                }

                setLocalLoading(false)
            }, (error) => {
                setLocalLoading(false)
                //setIsError(true)
            })
        }
    }, [journetId, isAdmin, userId]);

    const handleNew=()=>{
        setObjectToEdit({
        })
    }

    const handleEditRow=(project, params={})=>{
        setObjectToEdit({...project})
    }

    const startEdit = () => {
        setLocalLoading(true)
    }

    const finishEdit = response => {
        if(response){
            setProjects(response.projects||[])
        }

        setLocalLoading(false)
    }

    const handleChangeIsEnableProjects = event => {
        let paramsToPass = {data:{isEnableProjects:event.target.checked}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            //setError()

            props.postServerCall(null, response)
        }, (error) => {
            /*if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }*/
            props.postServerCall()
        })
    }

    const propsToChildren = {
        disabled,
        startEdit,
        finishEdit,
        setObjectToEdit,
        isAdmin,
        userId
    }

    return (
        <>
            {isAdmin&&<JSPaper style={{textAlign:'left'}}>
                <FormControlLabel
                    checked={journeyData.isEnableProjects || false}
                    control={<Switch color="primary"/>}
                    label="Enable Projects"
                    labelPlacement="end"
                    name="publishOn"
                    onChange={handleChangeIsEnableProjects}
                    disabled={disabled}
                />
            </JSPaper>}

            {journeyData.isEnableProjects&&<JSPaper style={{textAlign:'left', padding:0}}>
                <ProjectsDialog {...propsToChildren} objectToEdit={objectToEdit}/>

                <JSData cells={cells} data={projects} handleNewRow={handleNew} handleEditRow={handleEditRow}/>
            </JSPaper>}
        </>
    )
}