import React, { useState } from 'react';
import { Button, DialogContent, DialogTitle, Divider, FormControl, Input, InputLabel, Typography } from '@material-ui/core';
import AuthApi from "../../utils/AuthApi";
import ReCAPTCHA from 'react-google-recaptcha';

export default function ForgotPassword(props) {    
    const [payload, setPayload] = useState({email:localStorage.getItem("email") || ''});
    const [errors, setErrors] = useState();

    const recaptchaRef = React.useRef();

    const handleChange = event => {
        let value = event.target.value
        if (event.target.name === 'email'){
            value = value.toLowerCase().trim()
        }

        setPayload({...payload, [event.target.name]: value });
    };

    const handleOnSubmit = (event) => {
        event.preventDefault();

        recaptchaRef.current.executeAsync().then(token => {
            AuthApi.resetPassword({...payload, captchaToken: token}, data=>{
                setErrors()
                props.showMessage('Email sent', 'We sent you an email, please check your inbox')
            }, (error)=>{
                if (error.response.data && error.response.data.message){
                    setErrors(error.response.data.message)
                }

                recaptchaRef.current.reset();
            });

        })
    }

    return (
        <>
            <DialogTitle>Forgot password</DialogTitle>
            <DialogContent>
                <Typography>Please enter your email address and we will send you an email with instructions for resetting your password</Typography>
                <form onSubmit={handleOnSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" value={payload.email} onChange={handleChange}/>
                    </FormControl>
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6LdtJQEqAAAAAKfoQH6DYc7wwa9fOmasffaAQoNi" size="invisible"/>
                    {(typeof errors === 'string')&&<Typography color="error">{errors}</Typography>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="Reset"
                    >
                        Reset
                    </Button>
                    <div style={{marginTop:15}} className="greyText">Back to <span onClick={() => props.setSection('signIn')} className="link">Sign In</span></div>
                </form>
                <Divider className="loginFooterDivider"/>
                <div className="greyText">Don't have an account? <span onClick={() => props.setSection('signUp')} className="link">Sign Up</span></div>
            </DialogContent>
        </>
    )
}