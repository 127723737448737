import React/*, { useState, useEffect, Fragment }*/ from 'react';
import { Dialog, Paper, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Kpis from '../Kpis';

export default function DashboarDialog(props) {
    const showDashboard = props.showDashboard || false
    const isAdmin = props.isAdmin
    const userId = props.userId

    const handleClose = () => {
        props.setShowDashboard(false)
    }

    return (
        <Dialog
            open={showDashboard}
            onClose={handleClose}
            fullScreen
        >
            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center'}} elevation={1}>
                <div style={{flex: "1 1 0%"}}></div>
                <Kpis isAdmin={isAdmin} userId={userId}/>
                <IconButton aria-label="close" color="primary" onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </Paper>
        </Dialog>
    )
}