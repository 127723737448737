import { makeStyles } from '@material-ui/core/styles';

export const PersonalJourneyClasses = makeStyles((theme) => ({
    journeyTab: {
        minWidth: 'initial !important',
        '& span' :{
            fontSize: 14,
            textTransform: 'capitalize'
        },
    },
    contactButton:{
        width: 30,
        height: 30,
        minHeight: 25,
        marginRight:16
    },
    contactButtonIcon:{
        height: 15
    },
    nextStepButton: {
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1)
    },
    backStepButton: {
        /*[theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },*/
        marginRight:theme.spacing(1),
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1)
    },
    stepper:{
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            paddingRight: 0
        },
    },
    resumeSection:{
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        position: 'relative',
        overflow: 'auto'
    },
    prePublishSection:{
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    cancelButton:{
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        marginRight:theme.spacing(1)
    }, 
    saveButton:{
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1)
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    /*,
    interviewGroups:{
        display:'flex'
    },
    interviewInGroup:{
        display:'flex'
    }*/
}));