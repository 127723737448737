import React, { useState/*, useEffect, Fragment*/ } from 'react';
import { FIELD_TYPES, capitalize, displayPosition, getNameFromEmail, showDateTimeFromString } from '../../../utils/utils';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Fields } from '../../Fields';
import { Alert } from '@material-ui/lab';

export default function JourneySummary(props) {
    const today = new Date().toISOString().substring(0, 10)
    const newSummary = props.newSummary || false

    const index = props.index
    const date = props.date || ''
    const author = props.author || ''
    const summary = props.summary || ''
    const title = props.title || ''
    const name = props.name || ''
    const isAdmin = props.isAdmin || false
    const disabled = props.disabled || false
    const cycle = props.cycle || {}
    const journeyData = props.journeyData || {}
    const cyclesNoSummary = props.cyclesNoSummary || []
    const summaryTypes = props.summaryTypes
    const cycleSummary = props.cycleSummary || false

    const [isEdit, setIsEdit] = useState(newSummary);
    const [newData, setNewData] = useState(newSummary?{id:-1, date:today}:{});
    const [showCopied, setShowCopied] = useState(false);

    const oneDay = 1000 * 60 * 60 * 24;
    const daysSinceStart = date?Math.ceil((new Date(date) - new Date(cycle.firstSent || props.startDate))/oneDay):0
    const publicationString = cycle.position?(displayPosition(cycle.position) + ' Publication - '):''

    const cyclePosition = (title==='publication summary'&&props.cycle&&props.cycle.position)?(displayPosition(props.cycle.position)+' '):''

    const firstRow = `${getNameFromEmail(author)} - ${showDateTimeFromString(date, false)}${title?(' - '+cyclePosition+capitalize(title)):''}${name?(' - '+name):''}`
    const secondRow = `${publicationString}${capitalize(journeyData.plan||'')} - ${daysSinceStart} ${(daysSinceStart===1?'day':'days')} passed${journeyData.title?' - ' + capitalize(journeyData.title):''}`

    const handleStartEdit = () => {
        setNewData({title:cycleSummary?'publication summary':title, date, comment:summary, id:index, cycleId:cycleSummary?cycle._id:'', summaryId:props.summaryId})
        setIsEdit(true)
    }

    const handleCopy = () => {
        let text = `${firstRow}
${secondRow}

${summary}`

        navigator.clipboard.writeText(text)

        console.log(text)
        setShowCopied(true)
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        if (newData.cycleId && e.target.name === 'title' && e.target.value !== 'publication summary'){
            setNewData({...newData,[e.target.name]: e.target.value, cycleId:''})
        } else {
            setNewData({...newData,[e.target.name]: e.target.value})
        }
    };

    const handleSave = e => {
        e.preventDefault();

        props.handleSaveSummary({...newData, cyclePosition:cycle.position, ogCycleId:cycleSummary?cycle._id:undefined}, error=>{
            if(error){
                console.log(error)
            } else {
                setNewData({})
                setIsEdit(false)
            }
        })   
    }

    const handleCancel = () => {
        if (props.handleCancelNewSummary){
            props.handleCancelNewSummary()
        } else {
            setNewData({})
            setIsEdit(false)
        }
    }

    /*let cyclesOptions = [
        {value: '', label:`Not Publication's Summary`},
        ...cyclesNoSummary.sort((a, b) => {
            if (a.position > b.position){
                return -1
            } else {
                return 1
            }
        }).map(
            cycleNoSummary=>({value: cycleNoSummary._id, label:displayPosition(cycleNoSummary.position) + ' Publication'})
        )
    ]*/

    let cyclesOptions = cyclesNoSummary.sort((a, b) => {
        if (a.position > b.position){
            return -1
        } else {
            return 1
        }
    }).map(
        cycleNoSummary=>({value: cycleNoSummary._id, label:displayPosition(cycleNoSummary.position) + ' Publication'})
    )

    if (cycleSummary){
        cyclesOptions.push({value: cycle._id, label:displayPosition(cycle.position) + ' Publication'})
    }

    let summaryTypesOptions = [...summaryTypes.map(summaryType=>{return {value:summaryType, label:capitalize(summaryType)}})]

    let fields = [
        {name: 'title', label:'Summary Type', defaultValue: '', options:summaryTypesOptions, type:FIELD_TYPES.SELECT, required:true},
        {name:'date', label:'Date', defaultValue: '', type:FIELD_TYPES.DATE2, required:true},
    ]

    /*if (newData.title === 'publication summary'){
        fields.push({name:'cycleId', label:'Summary For Publication', defaultValue: '', options:cyclesOptions, type:FIELD_TYPES.SELECT, sm:12, required:true},)
    }*/

    fields.push({name:'comment', label:'Summary', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:6, sm:12, required:true})

    return (
        <>
            <Snackbar open={showCopied} autoHideDuration={2000} onClose={()=>{setShowCopied(false)}}>
                <Alert onClose={()=>{setShowCopied(false)}} severity="success">
                    Copied
                </Alert>
            </Snackbar>

            {isEdit?
            <div>
                <form onSubmit={handleSave}>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                    <div style={{marginTop: 8}}>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </div>:
            <div style={{display:'flex',alignItems: 'flex-start'}}>
                <div>
                    <strong>{firstRow}</strong><br/>
                    {secondRow}<br/><br/>
                    <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary}</span>
                </div>
                {isAdmin&&<div>
                    <IconButton color='primary' onClick={handleStartEdit} disabled={disabled}>  
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton color='primary' onClick={handleCopy}>  
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                </div>}
            </div>
            }
        </>
    )
}