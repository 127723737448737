import React, { useState, useEffect } from 'react';
import JHApi from "../../../utils/JHApi"
import { Button, Paper, Typography } from '@material-ui/core';
import InterviewsList from './InterviewsList'
import InterviewsDialog from './InterviewsDialog';
import SelectAsChip from './SelectAsChip';
import CycleSummary from './CycleSummary';
import ContractOfferDialog from './ContractOfferDialog';
//import Explenations from '../../Explenations';
//import HelpIcon from '@material-ui/icons/Help';
import { displayPosition } from '../../../utils/utils';

export default function Interviews(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journeyId = journeyData._id

    const [interviews, setInterviews] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [publishesData, setPublishesData] = useState([]);
    const [talentOffers, setTalentOffers] = useState([]);
    const [ratingsFields, setRatingsFields] = useState([]);
    const [selectedCycleIndex, setSelectedCycleIndex] = useState(-1);
    const [isLoadingInterviews, setIsLoadingInterviews] = useState(false);
    const [objectToEdit, setObjectToEdit] = useState();
    const [offerToEdit, setOfferToEdit] = useState();

    //const [explanationDialog, setExplanationDialog] = useState({open:false});

    const disabled = isLoading || isLoadingInterviews
    const today = new Date().toISOString().substring(0, 10)

    useEffect(() => {
        if (journeyId){
            let paramsToPass = {}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setIsLoadingInterviews(true)

            JHApi.getInterviewsForUser(paramsToPass,(response)=>{
                setInterviews(response.interviews || []);
                setCycles(response.cycles || []);
                setPublishesData(response.publishesData || []);    
                setRatingsFields(response.ratingsFields || [])
                setTalentOffers(response.talentOffers || [])
                setIsLoadingInterviews(false)
            }, (error) => {
                setIsLoadingInterviews(false)
                //setIsError(true)
            })
        }
    }, [journeyId, isAdmin, userId]);

    const handleNewInterview = () => {
        setObjectToEdit({
            interviewDate:today,
            type:"HR Interview",
            status:"Interviewing",
            seniorityLevel: journeyData.seniorityLevel||'',
        })
    }

    const startEditInterviews = () => {
        setIsLoadingInterviews(true)
        props.preServerCall()
    }

    const finishEditInterviews = (response) => {
        if (response.interviews){
            setInterviews(response.interviews);
        }

        if (response.newCycles){
            setCycles(response.newCycles)
        }

        setTalentOffers(response.talentOffers || [])

        setIsLoadingInterviews(false)
        props.postServerCall()
    }

    const finishEditOffers = response => {
        if (response.talentOffers){
            setTalentOffers(response.talentOffers)
        }

        setIsLoadingInterviews(false)
        props.postServerCall()
    }

    const getCycleLabel = cycleIndex => {
        if(cycleIndex === -1){
            return 'All Publications'
        } else {
            return displayPosition(cycles.length - cycleIndex) + ' Publication'
        }
    }

    const propsToChildren = {
        journeyData,
        isAdmin,
        userId,
        disabled,
        startEditInterviews,
        finishEditInterviews,
        setObjectToEdit,
        setOfferToEdit
    }

    const cyclesList = [-1, ...(cycles||[]).map((cycle, index) => index)]

    let interviewProcesses = {}
    let companies = []

    interviews.forEach(function(interview) {
        let company = interview.company.toLowerCase().trim()
        if (company === 'constructor'){
            company = 'constructor.'
        }

        if(!companies.includes(company)){
            companies.push(company)
        }

        if(!interviewProcesses[company]){
            interviewProcesses[company] = {interviews:[]}
        }

        if(!interviewProcesses[company].first || interview.interviewDate < interviewProcesses[company].first){
            interviewProcesses[company].first = interview.interviewDate
        }

        if(!interviewProcesses[company].last || interview.interviewDate > interviewProcesses[company].last){
            interviewProcesses[company].last = interview.interviewDate
        }

        if(!interviewProcesses[company].dateCreated || interview.dateCreated < interviewProcesses[company].dateCreated){
            interviewProcesses[company].dateCreated = interview.dateCreated < interview.interviewDate ? interview.dateCreated : interview.interviewDate
        }

        if(!interviewProcesses[company].status){
            interviewProcesses[company].status = interview.status
        }

        if(!interviewProcesses[company].csComment){
            interviewProcesses[company].csComment = interview.csComment
        }

        ratingsFields.forEach(function(ratingsField) {
            if(interview[ratingsField]){
                if (!interviewProcesses[company].ratings){
                    interviewProcesses[company].ratings = {}
                }
                if (!interviewProcesses[company].ratings[ratingsField]){
                    interviewProcesses[company].ratings[ratingsField] = {sum:0, count:0}
                }
    
                interviewProcesses[company].ratings[ratingsField].sum += interview[ratingsField]
                interviewProcesses[company].ratings[ratingsField].count++
                //interviewProcesses[company].ratings[ratingsField].avg = interviewProcesses[company].ratings[ratingsField].sum / interviewProcesses[company].ratings[ratingsField].count
                interviewProcesses[company][ratingsField] = interviewProcesses[company].ratings[ratingsField].sum / interviewProcesses[company].ratings[ratingsField].count
            }
        })

        interviewProcesses[company].interviews.push(interview)
    })

    interviewProcesses = companies.map(company => ({
        company:company, 
        ...interviewProcesses[company], 
        talentOffer:talentOffers.find(talentOffer => company === (talentOffer.company||'').toLowerCase().trim())
    }))

    const selectCycle = selectedCycleIndex===-1 ? {} : cycles[selectedCycleIndex]
    if (selectedCycleIndex !== -1){
        const nextCycle = (selectedCycleIndex===-1 || selectedCycleIndex === 0) ? {} : cycles[selectedCycleIndex-1]

        interviewProcesses = interviewProcesses.filter(interviewProcess => interviewProcess.first >= selectCycle.firstSent && (!nextCycle.firstSent || interviewProcess.first <= nextCycle.firstSent))
    }

    interviewProcesses = interviewProcesses.sort((a, b) => {
        if (a.last >= today){
            if (b.last >= today){
                return (a.last < b.last) ? -1 : 1
            } else {
                return -1
            }
        }

        return 0;
    })

    return (
        <div>
            {/*<Explenations explanationDialog={explanationDialog} setExplanationDialog={setExplanationDialog}/>*/}

            <ContractOfferDialog 
                {...propsToChildren} 
                objectToEdit={offerToEdit} 
                setObjectToEdit={setOfferToEdit} 
                startEditData={startEditInterviews}
                finishEditData={finishEditOffers}
            />
            <InterviewsDialog {...propsToChildren} objectToEdit={objectToEdit} interviewsSort={-1}/>

            {(cycles.length>0)&&<Paper style={{margin: 8, padding: '8px 16px'}} elevation={1}>
                <Typography variant='body1' component="span" style={{marginRight: 8}}>Filter:</Typography>
                
                <SelectAsChip 
                    labelFunc={getCycleLabel}
                    value={selectedCycleIndex} 
                    onChange={setSelectedCycleIndex} 
                    list={cyclesList} 
                    disabled={disabled} 
                />
            </Paper>}

            <Paper style={{margin: 8, padding: '8px 16px'}} elevation={1}>
                <Typography> 
                    <a href="https://docs.google.com/document/d/1NtpUdBchCvJchZ2llS_DAtp_UOOAcw4Y4HiE4dlAsHo" target="_blank" rel="noopener noreferrer">טיפים לראיונות</a><br/><br/>
                    <a href="https://docs.google.com/document/d/100kZXcLStQwwd7nc-zR6f_-2bYuuy3CeqLnBnwGbUmw" target="_blank" rel="noopener noreferrer">Tips for interviews</a><br/><br/>
                    <a href="https://docs.google.com/document/d/1m9wHV-QlegzzBO-Rks0VsjTFoZ2O6Y0yJAYEB2Di2ic/edit" target="_blank" rel="noopener noreferrer">How to structure presentation for interview + tips?</a><br/><br/>
                    <a href="https://docs.google.com/document/d/1lmMUijbdhhU06xVYmtxsj7VbyEyq1dBDs78WHXg67Sc/edit" target="_blank" rel="noopener noreferrer">Integrity interview possible questions + answers</a>
                </Typography>
            </Paper>

            {(selectedCycleIndex!==-1)&&<Paper style={{margin: 8, padding: '8px 16px', backgroundColor:'#fff2cc'}} elevation={1}>
                <CycleSummary {...propsToChildren} cycle={selectCycle} publishesData={publishesData} label={getCycleLabel(selectedCycleIndex)} interviewProcesses={interviewProcesses} ratingsFields={ratingsFields}/>
            </Paper>}

            <Paper style={{margin: 8, padding: '8px 16px'}} elevation={1}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8}}
                    onClick={handleNewInterview}
                >+ Interview</Button>
                {/*<Button 
                    disabled={disabled} 
                    size='small' 
                    color='primary' 
                    variant='contained' 
                    onClick={()=>setExplanationDialog({open:true, type:'interviews'})}
                    startIcon={<HelpIcon/>}
                >Help</Button>*/}
            </Paper>
            
            {interviewProcesses.map(interviewProcess => 
                <InterviewsList key={interviewProcess.company} {...propsToChildren} company={interviewProcess.company} interviews={interviewProcess.interviews} offer={interviewProcess.talentOffer} disabled={disabled} />
            )}
        </div>
    );
}