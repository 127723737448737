import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { getLocalTags } from '../utils/DataHelper';

class Head extends Component {
    componentDidMount() {
        getLocalTags()
    }

    render(){
        const {title, description, canonicalUrl} = this.props

        return (
            <div className="application">
                <Helmet>
                    <title>{title}</title>
                    <meta name="title" content={title}/>
                    <meta name="description" content={description}/>
                    <link rel="canonical" href={canonicalUrl} />

                    <meta property="og:title" content={title}/>
                    <meta property="og:description" content={description}/>
                    <meta property="og:url" content={canonicalUrl}/>
                    
                    {/*props.showSchema && <script type="application/ld+json">{JSON.stringify(props.schema)}</script>*/}
                </Helmet>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { head } = state;

    const title = head.title + ' | JobSwipe';
    const description = head.description;
    const canonicalUrl = (head.canonicalUrl) ? head.canonicalUrl : window.location.href;

    return {
        title,
        description,
        canonicalUrl
    }
}

export default connect(mapStateToProps)(Head)