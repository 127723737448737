import { CARD_CONTENT_REQUEST, 
	CARD_CONTENT_RECEIVE, 
	CARD_CONTENT_APPEND,
	CARD_CONTENT_SET_FILTERED, 
    CARD_CONTENT_CLEAR,
    CARD_CONTENT_SET_TYPE} from '../actions/cardContent'

const initialState = {
	isFetching: false,
	data: [],
	filteredData: []
}

export function cardContent(state = initialState, action) {
    switch (action.type) {
		case CARD_CONTENT_CLEAR:
            return initialState
        case CARD_CONTENT_SET_TYPE:
            return Object.assign({}, state, {
				cardContentType: action.cardContentType
            })
    	case CARD_CONTENT_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
                data: [],
                cardContentType: action.cardContentType,
                filteredData: []
            })
	  	case CARD_CONTENT_RECEIVE:
			return Object.assign({}, state, {
				isFetching: false,
				data: action.data,
			})
		case CARD_CONTENT_APPEND:
			return Object.assign({}, state, {
				isFetching: false,
				data: [...state.data, ...action.data],
			})
		case CARD_CONTENT_SET_FILTERED:
			return Object.assign({}, state, {
				filteredData: action.filteredData
			})
      	default:
        	return state
    }
}