import { makeStyles } from '@material-ui/core/styles';

export const InterviewClasses = makeStyles((theme) => ({
    paginationButtonContainer:{
        [theme.breakpoints.up('sm')]: {
            display: 'flex', 
            justifyContent: 'space-between', 
            flexDirection: 'row-reverse',
            alignItems: 'center', 
        },
        marginBottom: 8
    },
    paginationContainer:{
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            marginBottom: 16
        },
    },
    interviews:{
        listStyle:'none', 
        border: "1px solid #eee",
        paddingTop: 0
    },
    interviewHeader:{
        backgroundColor: "#eaeaea",
        textAlign: 'right'
    },
    interview:{
        borderTop: "1px solid #eee",
        backgroundColor: "#fff",
        textAlign: 'right',
        '& a' :{
            color: '#396a92'
        },
    },
    odd: {
        backgroundColor: "#fbfbfb"
    },
    sticky: {
        backgroundColor:'#e6f8ff'
    }
}));