import { getHost, isAgency, NO_COMPANY, getHistogramTypeLabel, getTitleDescription, DEF_LAT, DEF_LNG, PESONAL_JOURNEY_STEPS, getNameFromEmail, getDaysFromHistory } from '../../utils/utils'
import { selectJob } from './selectedJob'
import { updateHead } from './head'
import { setFilterByJobs, toggleFilterAndRefreshPage, POSIBLE_DATE_POSTED, NO_UNDISCLOSED, NO_AGENCY } from './filterJobs'
import { gaPageView,
    getCompanyText, 
    getemploymentTypeText, 
    getDiffInSecondsString, 
    getDiffInSeconds, 
    distance, 
    filterUrl, 
    ROWS_PER_PAGE } from '../../utils/utils'
import HistApi from "../../utils/HistApi";
import AdminApi from "../../utils/AdminApi"
import AdminJHApi from "../../utils/AdminJHApi"

import { push } from 'connected-react-router'
import { updateUserInfo } from './authActions'
import { setFilterData } from './histogram'

export const CLEAR_OBJECTS = 'CLEAR_OBJECTS'
export const REQUEST_JOBS = 'REQUEST_JOBS'
export const RECEIVE_JOBS = 'RECEIVE_JOBS'
export const APPEND_JOBS = 'APPEND_JOBS'
export const SET_FILTERED_JOBS = 'SET_FILTERED_JOBS'
export const APPEND_FILTERED_JOBS = 'APPEND_FILTERED_JOBS'
export const SET_PAGE = 'SET_PAGE'

function requestJobs(chunk = 1, clearJobs) {
    return {
        type: REQUEST_JOBS,
        chunk,
        clearJobs
    }
}

function receiveJobs(jobs, total, noMore) {
    return {
        type: RECEIVE_JOBS,
        jobs: jobs,
        receivedAt: Date.now(),
        total,
        noMore
    }
}

function appendJobs(jobs) {
    return {
        type: APPEND_JOBS,
        jobs: jobs,
        receivedAt: Date.now(),
    }
}

function setPage(page){
    return {
        type: SET_PAGE,
        page
    }
}

function setFilteredJobs(filteredJobs){
    return {
        type: SET_FILTERED_JOBS,
        filteredJobs
    }
}

function appendFilteredJobs(filteredJobs){
    return {
        type: APPEND_FILTERED_JOBS,
        filteredJobs
    }
}

export function filterJobs(jobs, append, resetPage=true){
    return (dispatch, getState) => {
        const state = getState()
        const { filterJobs, histogramData, filterData} = state;
        const histogramType = histogramData.histogramType

        const sceduledMeetingStatuses = filterData.sceduledMeetingStatuses || []

        const oneDay = 1000 * 60 * 60 * 24;
        const now = new Date();

        jobs = (jobs) ? jobs : state.histogramJobs.jobs;

        let filteredJobs = [];
        let j;

        let diffLesserThan = (filterJobs['date posted'].filtered.length > 0) ? getDiffInSecondsString(filterJobs['date posted'].filtered[0]) : 0;
        let updatedDiffLesserThan = (filterJobs['updated'].filtered.length > 0) ? getDiffInSecondsString(filterJobs['updated'].filtered[0]) : 0;
        let maxDistance = (filterJobs['location'].filtered.length > 0) ? filterJobs['location'].filtered[0] : 0;

        let locationObject
        if (maxDistance && filterJobs['location'].params && filterJobs['location'].params.locationObj){
            locationObject = filterJobs['location'].params.locationObj
        }

        const noUndisclosed = (filterJobs['company'] && filterJobs['company'].filtered.length > 0 && filterJobs['company'].filtered.includes(NO_UNDISCLOSED))
        const noAgency = (filterJobs['company'] && filterJobs['company'].filtered.length > 0 && filterJobs['company'].filtered.includes(NO_AGENCY))
        const companyFilterLength = (noUndisclosed?1:0)+(noAgency?1:0)

        for (j = 0; j < jobs.length; j++){
            let job = jobs[j];

            const company = getCompanyText(job.company);
            const employmentType = getemploymentTypeText(job.employmentType)
            let toShow = true;

            if (maxDistance > 0 && locationObject && locationObject.lat && locationObject.lng){
                let loc_lat = (job.loc_lat && job.loc_lat !== 1) ? job.loc_lat : DEF_LAT;
                let loc_lng = (job.loc_lng && job.loc_lng !== 1) ? job.loc_lng : DEF_LNG;
                toShow = (distance(loc_lat, loc_lng, locationObject.lat, locationObject.lng, 'K') <= maxDistance);
            }
            if (diffLesserThan > 0){
                toShow = (getDiffInSeconds(job.datecreated) <= diffLesserThan);
            }
            if (updatedDiffLesserThan > 0){
                if (histogramType === 'resumes'){
                    toShow = (getDiffInSeconds(job.lastActive) <= updatedDiffLesserThan);
                } else {
                    toShow = (getDiffInSeconds(job.updated) <= updatedDiffLesserThan);
                }
            }
            if(toShow && noUndisclosed){
                toShow = (company !== NO_COMPANY)
            }
            if(toShow && noAgency){
                toShow = !isAgency(company)
            }
            if (toShow && filterJobs['company'] && filterJobs['company'].filtered.length > companyFilterLength){
                toShow = filterJobs['company'].filtered.includes(company);
            }
            if (toShow && filterJobs['employment type'] && filterJobs['employment type'].filtered.length > 0){
                toShow = filterJobs['employment type'].filtered.includes(employmentType);
            }
            if (toShow && filterJobs['seniority Level'] && filterJobs['seniority Level'].filtered.length > 0){
                toShow = filterJobs['seniority Level'].filtered.includes(job.seniorityLevel);
            }
            if (toShow && filterJobs['tags'] && filterJobs['tags'].filtered.length > 0){
                if(job.tags && Array.isArray(job.tags) && job.tags.length > 0){
                    toShow = job.tags.some(r=> filterJobs['tags'].filtered.indexOf(r) >= 0)
                } else {
                    toShow = false
                }
            }
            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['step'] && filterJobs['step'].filtered.length > 0){
                let step = 0
                if (job.currentStep){
                    step = job.currentStep
                }
                toShow = filterJobs['step'].filtered.includes(PESONAL_JOURNEY_STEPS[step]);
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['publish step'] && filterJobs['publish step'].filtered.length > 0){
                if (job.currentStep === 5 ){
                    if(filterJobs['publish step'].filtered.includes('publishing - auto') && 
                        job.isPublishApproved && job.autoPutOnPublishList){
                        toShow = true
                    } else if(filterJobs['publish step'].filtered.includes('publishing - manual') && 
                        job.isPublishApproved && !job.autoPutOnPublishList){
                        toShow = true
                    } else if(filterJobs['publish step'].filtered.includes('approve needed - new cycle') && 
                        !job.isPublishApproved && !job.active_cycle){
                        toShow = true
                    } else if(filterJobs['publish step'].filtered.includes('approve needed - exists cycle') && 
                        !job.isPublishApproved && job.active_cycle){
                        toShow = true
                    } else {
                        toShow = false
                    }
                } else {
                    toShow = false
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['manager'] && filterJobs['manager'].filtered.length > 0){
                if (job.csManager){
                    toShow = filterJobs['manager'].filtered.includes(getNameFromEmail(job.csManager));
                } else if(filterJobs['manager'].filtered.includes('No Manager')){
                    toShow = true
                } else {
                    toShow = false
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['title'] && filterJobs['title'].filtered.length > 0){
                if (job.titleCategories&& job.titleCategories.length>0){
                    toShow = false
                    job.titleCategories.forEach(caegory => {
                        toShow = (toShow || filterJobs['title'].filtered.includes(caegory));
                    });
                } else if(filterJobs['title'].filtered.includes('No Title')){
                    toShow = true
                } else {
                    toShow = false
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && (job.status==='signed' || job.status==='freezed' || job.status==='not in galina' || job.status==='out of service' || job.status==='unsubscribed')){
                if(
                    !job.safetyNetActive && 
                    !filterJobs['status'].filtered.includes('signed') && 
                    !filterJobs['status'].filtered.includes('freezed') && 
                    !filterJobs['status'].filtered.includes('not in galina') &&
                    !filterJobs['status'].filtered.includes('out of service') &&
                    !filterJobs['status'].filtered.includes('unsubscribed') &&
                    !state.searchJobs.queryString && !state.searchJobs.email
                ){
                    toShow = (filterJobs['other'].filtered.includes('have not active safety net') && job.safetyNetExpireDate && job.safetyNetExpireDate >= new Date().toISOString().substring(0, 10))
                }
            }
            //['No Status', 'Active', 'Not Active']
            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['status'] && filterJobs['status'].filtered.length > 0){
                if (job.status){
                    toShow = filterJobs['status'].filtered.includes(job.status);
                } else if(filterJobs['status'].filtered.includes('No Status')){
                    toShow = true
                } else {
                    toShow = false
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['plan'] && filterJobs['plan'].filtered.length > 0){
                if (job.plan){
                    toShow = filterJobs['plan'].filtered.includes(job.plan);
                } else if(filterJobs['plan'].filtered.includes('No Plan')){
                    toShow = true
                } else {
                    toShow = false
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['seniority'] && filterJobs['seniority'].filtered.length > 0){
                toShow = (filterJobs['seniority'].filtered.includes(job.seniorityLevel||'not selected'))
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['need attention'] && filterJobs['need attention'].filtered.length > 0){
                toShow = false
                const daysSincelLastPublish = job.lastPublishDate?Math.round((now - new Date(job.lastPublishDate))/oneDay):0
                if (job.lastPublishDate && filterJobs['need attention'].filtered.includes('last publish 7-14 days')){    
                    toShow = (daysSincelLastPublish >=7 && daysSincelLastPublish <14)
                }
                if (!toShow && job.lastPublishDate && filterJobs['need attention'].filtered.includes('last publish more than 14 days')){
                    toShow = (daysSincelLastPublish >= 14)
                }
                /*if (!toShow && job.currentStep===4 && filterJobs['need attention'].filtered.includes('waiting for approval')){
                    toShow = !job.isNeedTest && job.lastTestId
                }*/
                toShow = (!toShow && 'waiting for approval' === job.status && filterJobs['need attention'].filtered.includes('waiting for approval'))

                if (!toShow && job.plan && job.numberOfCycles!==0 && filterJobs['need attention'].filtered.includes('last publication for standard')){
                    toShow = (job.numberOfCycles===5 && job.plan==='standard pro') || (job.numberOfCycles===6 && job.plan==='standard executive')
                }
                if (!toShow && job.firstPartSent && filterJobs['need attention'].filtered.includes('7th day first part sent')){
                    toShow = Math.round((now - new Date(job.firstPartSent))/oneDay) === 7
                }
                if (!toShow && job.firstPartSent && filterJobs['need attention'].filtered.includes('more than 10 cycles')){
                    toShow = job.numberOfCycles>=10
                }
            }

            if (histogramType === 'jobhuntUsers' && filterJobs['need attention'].filtered.includes('frozen more than month')){
                if (job.status === 'freezed' && job.freezeHistory && job.freezeHistory.length>0){
                    toShow = getDaysFromHistory(job.freezeHistory, job.beginServiceDate || job.dateCreated) >= 30
                }
            }

            if (toShow && histogramType === 'jobhuntUsers' && filterJobs['other'] && filterJobs['other'].filtered.length > 0){
                toShow = false

                if (!toShow && filterJobs['other'].filtered.includes('scheduled meeting')){    
                    toShow = sceduledMeetingStatuses.includes(job.status)
                }

                if (!toShow && filterJobs['other'].filtered.includes('not scheduled meeting')){    
                    toShow = !sceduledMeetingStatuses.includes(job.status)
                }

                if (!toShow && filterJobs['other'].filtered.includes('have not active safety net')){    
                    toShow = (!job.safetyNetActive && job.safetyNetExpireDate && job.safetyNetExpireDate >= new Date().toISOString().substring(0, 10))
                }

                if (!toShow && filterJobs['other'].filtered.includes('active safety net')){    
                    toShow = job.safetyNetActive
                }
            }

            if (toShow){
                filteredJobs.push(job)
            }
        }

        if (append){
            dispatch(appendFilteredJobs(filteredJobs));
        } else {
            dispatch(setFilteredJobs(filteredJobs))
            if (resetPage){
                dispatch(setPageAndFilter(1))
            } else {
                const lastPage = Math.ceil(filteredJobs.length/ROWS_PER_PAGE)
                if (state.histogramJobs.page > lastPage){
                    dispatch(setPage(lastPage));
                }
            }
        }
    }
}

export function setCompanies(companyText){
    return (dispatch, getState) => {
        let companies = getState().histogramJobs.jobs;

        if (companyText){
            const regExp = new RegExp(companyText,'mgi');
            companies = companies.filter(company => company.name.match(regExp))
        }

        dispatch(setFilteredJobs(companies))
        dispatch(setPage(1))
    }
}

export function setPageAndFilter(page, resetFilter = false){
    return (dispatch, getState) => {
        const {noMore, filteredJobs} = getState().histogramJobs;
        const lastPage = Math.ceil(filteredJobs.length / ROWS_PER_PAGE);

        if (page){
            if (page > lastPage){
                page = lastPage
            }

            dispatch(setPage(page));
        } else {
            page = getState().histogramJobs.page

            if (page > lastPage){
                page = lastPage
                dispatch(setPage(page));
            }
        }

        const slicedJobs = filteredJobs.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE)
        dispatch(setFilterByJobs(((getState().histogramData.histogramType==='jobhuntUsers')?filteredJobs:slicedJobs), resetFilter))

        if (!noMore && (lastPage < 5 || page + 2 >= lastPage)){
            //dispatch(fetchJobsIfNeeded(getState().searchJobs, true))
            dispatch(fetchData({searchJobs:getState().searchJobs}, true))
        }
    }
}

export function appendJobsSetFilter(objects){
    return dispatch => {
        dispatch(appendJobs(objects));
        dispatch(filterJobs(objects, true))
        dispatch(setPageAndFilter());
    }
}

export function setHistogramWithDataNoReset(objects, total){
    return (dispatch) => {
        dispatch(receiveJobs(objects, total));
        dispatch(filterJobs(objects, false, false))
        dispatch(setFilterByJobs(objects, false))
    }
}

function afterFetchObjects(jobs, total, searchParams, host, queryParams, histType){
    return (dispatch, getState) => {
        const state = getState()
        const { userInfo } = state.authUser
        const histogramType = histType || state.histogramData.histogramType;
        const isDatePosted = (histogramType === 'jobs' && searchParams.datePosted && POSIBLE_DATE_POSTED[searchParams.datePosted])

        if (!state.selectedJob.editMode){
            if (jobs[0] && !state.histogramData.single){
                dispatch(selectJob(jobs[0]));
            } else {
                dispatch(selectJob({}, (state.histogramData.single && state.selectedJob.notFound)));
            }
        }

        let resetFilter = true

        let noMore

        if(histogramType === 'jobhuntUsers'){
            noMore = true
        }

        dispatch(receiveJobs(jobs, total, noMore));
        dispatch(setFilteredJobs(jobs));

        if (isDatePosted){
            dispatch(toggleFilterAndRefreshPage('date posted', POSIBLE_DATE_POSTED[searchParams.datePosted]))
            resetFilter = false
        }

        dispatch(setPageAndFilter(1, resetFilter));

        if (histogramType==='jobhuntUsers'){
            dispatch(filterJobs(jobs, false, false))
        }

        const titleDescription = getTitleDescription(userInfo.lookingforwork, histogramType)
        let newUrl = '';
        let title = titleDescription.title
        let description = titleDescription.description

        let locationObjSP = searchParams.locationObj || {};
        if(searchParams.locationCode ||  searchParams.queryString || isDatePosted ||
            (locationObjSP.lat && locationObjSP.lng)){
            let locationCode = searchParams.locationCode || '';
            
            let location = ''
            let queryString = searchParams.queryString;

            let datePostedLabel
            if (isDatePosted){
                datePostedLabel = POSIBLE_DATE_POSTED[searchParams.datePosted].toLowerCase()
            }

            if (state.locations.locations[locationCode]){
                let locationObj = state.locations.locations[locationCode]
                location = (locationObj.name) ? locationObj.name : locationObj.country;
            } else if ((locationObjSP.country || locationObjSP.locality || locationObjSP.text) && locationObjSP.lat && locationObjSP.lng ){
                if (locationObjSP.country){
                    location = locationObjSP.country
                }
                if (locationObjSP.locality){
                    if (locationObjSP.country){
                        location = location + ','
                    }
                    location = location + locationObjSP.locality
                }
                if (!locationObjSP.country && !locationObjSP.locality){
                    location = locationObjSP.text
                }
            }

            let locationLength = 0;
            if (location){
                newUrl = newUrl + filterUrl(location) + '-';
                locationLength = filterUrl(location).length + 1;
            }

            if (searchParams.queryString){
                queryString = filterUrl(queryString);
                newUrl = newUrl + queryString + '-';
            }

            newUrl = newUrl + getHistogramTypeLabel(histogramType)

            if (isDatePosted){
                newUrl = newUrl + '-' + filterUrl(datePostedLabel) + '-';
            }

            title = '';
            if (searchParams.queryString){
                title = title + searchParams.queryString + ' ';
            }
            title = title + ((histogramType === 'resumes') ? 'candidates' : histogramType)
            if (location){
                title = title + ' near ' + location;
            }
            if (isDatePosted){
                title = title + ' ' + datePostedLabel;
            }
            title = title.charAt(0).toUpperCase() + title.slice(1);
            description = title;

            newUrl = newUrl + '-SRCH'

            if (state.locations.locations[locationCode]){
                newUrl = newUrl + '_L' + locationCode
            } else if (locationObjSP.lat && locationObjSP.lng){
                newUrl = newUrl + '_C' + `${locationObjSP.lng}p${locationObjSP.lat}`.replace(/[-]/mgi, 'm')

                newUrl = newUrl + 'p' + (locationObjSP.country ? locationObjSP.country.length : 0)
                newUrl = newUrl + 'p' + (locationObjSP.locality ? locationObjSP.locality.length : 0)

                newUrl = newUrl.replace(/[.]/mgi, 'd')
            }

            newUrl = newUrl + '_Q' + locationLength + ',' + queryString.length

            if (isDatePosted){
                newUrl = newUrl + '_D' + searchParams.datePosted;
            }
        } else if (histogramType === 'activity'){
            newUrl = state.histogramData.histogramSubType
        }
        newUrl = filterUrl(newUrl);
        newUrl = '/' + histogramType + '/' + newUrl;

        dispatch(updateHead({title, description, canonicalUrl:host + newUrl}))
        if (!['myJobs', 'jobhuntUsers'].includes(histogramType)){
            dispatch(push(newUrl));
        }
        if (!['jobhuntUsers'].includes(histogramType)){
            gaPageView(newUrl, title + ' | JobSwipe', queryParams);
        }
    }
}

export function setHistogramIfNeeded(){
    return (dispatch, getState) => {
        const histogramType = getState().histogramData.histogramType;
        if (histogramType === 'profile'){
            const {isAuthed, userInfo} = getState().authUser
            if (isAuthed && userInfo){
                return dispatch(setHistogram(userInfo));
            }
        } else if (histogramType === 'myJobs'){
            const {isAuthed, userInfo} = getState().authUser
            if (isAuthed && !userInfo.lookingforwork){
                return dispatch(fetchUserJobs());
            }
        }
    }
}

export const setHistogramWithData = (data, id, edit = true, reset = true) =>{
    return dispatch => {
        let selctedObject = {};
        let openDialog = false;
        let editMode = false;

        if (id){
            selctedObject = data.results.find( object => object._id === id )
            if (edit){
                editMode = true;
            }
            openDialog = true;
        } else if (data.results && data.results.length > 0){
            selctedObject = data.results[0]
        }

        if (reset){
            dispatch(requestJobs(1, true))
        }
        dispatch(afterFetchObjects(data.results, data.total, {}, getHost()))
        dispatch(selectJob(selctedObject, openDialog, editMode))
    }
}

const fetchUserJobs = () => dispatch => {
    HistApi.getUserJobs(data => {
        return dispatch(afterFetchObjects(data.results, data.total, {}, getHost()))
    });
}

const fetchUserActivity = () => (dispatch, getState) => {
    const histogramSubType = getState().histogramData.histogramSubType
    const lookingforwork = getState().authUser.userInfo.lookingforwork

    HistApi.getUserActivity({histogramSubType, lookingforwork}, data => {
        return dispatch(afterFetchObjects(data.results, data.total, {}, getHost()))
    });
}

const fetchReports = () => dispatch => {
    AdminApi.getReports(data => {
        return dispatch(afterFetchObjects(data.results, data.total, {}, getHost()))
    });
}

export const fetchSavedItems = (id) => {
    return dispatch => {
        HistApi.getSavedItems((results) => {
            dispatch(updateUserInfo({savedItems: results}))
            dispatch(afterFetchObjects(results, results.length, {}, getHost()))
            if(id) {
                let selctedObject = {};
                selctedObject = results.find( object => object._id === id )
        
                dispatch(requestJobs(1, true))
                dispatch(afterFetchObjects(results, results.length, {}, getHost()))
                dispatch(selectJob(selctedObject, false, false))
            }
        })
    }
}

export const fetchPersonalJourneys = (params, id) => {
    return dispatch => {
        AdminJHApi.getPersonalJourneys(params, (results) => {
            //dispatch(updateUserInfo({savedItems: results}))
            dispatch(afterFetchObjects(results.jobHuntUsers, results.jobHuntUsers.length, {}, getHost()))
            if(results.sceduledMeetingStatuses){
                dispatch(setFilterData({sceduledMeetingStatuses:results.sceduledMeetingStatuses}))
            }
            /*if(id) {
                let selctedObject = {};
                selctedObject = results.find( object => object._id === id )
        
                dispatch(requestJobs(1, true))
                dispatch(afterFetchObjects(results, results.length, {}, getHost()))
                dispatch(selectJob(selctedObject, false, false))
            }*/
        })
    }
}

export function fetchUserJobsIfNeeded(){
    return (dispatch, getState) => {
        if (getState().histogramData.histogramType === 'myJobs'){
            const {isAuthed, userInfo} = getState().authUser
            if (isAuthed && !userInfo.lookingforwork){
                return dispatch(fetchUserJobs());
            }
        }
    }
}

function setHistogram(userInfo){
    return dispatch => {
        dispatch(selectJob(userInfo, true, true));
        dispatch(receiveJobs([userInfo], 1));
        dispatch(setFilteredJobs([userInfo]));
    }
}

export function removeObjectFromHistogram(objectToRemove){
    return (dispatch, getState) => {
        const selectedObject = getState().selectedJob.job
        const total = getState().histogramJobs.total
        let {jobs, filteredJobs} = getState().histogramJobs

        const objectPos = filteredJobs.findIndex(object => object._id === objectToRemove)
        if (objectPos !== -1){
            if (selectedObject._id === objectToRemove){
                if (filteredJobs[objectPos + 1]){
                    dispatch(selectJob(filteredJobs[objectPos + 1], true));
                } else if (objectPos > 0){
                    dispatch(selectJob(filteredJobs[objectPos - 1], true));
                } else {
                    dispatch(selectJob({}));
                }
            }   
        }

        jobs = jobs.filter(object => object._id !== objectToRemove)
        filteredJobs = filteredJobs.filter(object => object._id !== objectToRemove)

        dispatch(receiveJobs(jobs, total-1));
        dispatch(setFilteredJobs(filteredJobs));
        dispatch(setPageAndFilter())
    }
}

const fetchCompanies = (params, chunk, append, queryParams, histogramType) => dispatch => {
    HistApi.getCompanies({...params}, data => {
        if (append){
            dispatch(appendJobsSetFilter(data.results));
        } else {
            dispatch(afterFetchObjects(data.results, data.total, {queryString: '', locationCode: ''}, getHost(), queryParams, histogramType))
        }
    });
}

const fetchInterviews = (params, chunk, append, queryParams, histogramType) => dispatch => {
    HistApi.getInterviews({...params}, data => {
        if (append){
            dispatch(appendJobsSetFilter(data.results));
        } else {
            dispatch(afterFetchObjects(data.results, data.total, {queryString: '', locationCode: ''}, getHost(), queryParams, histogramType))
        }
    })
}

const regularFetch = (histogramType, params, chunk, append, queryParams) => {  
    return dispatch => {
        HistApi.getHistogramObjects({
            histogramType,
            params,
            chunk,
            getCount: !append
        }, (results)=>{
            if (append){
                dispatch(appendJobsSetFilter(results.results));
            } else {
                dispatch(afterFetchObjects(results.results, results.total, params, getHost(), queryParams, histogramType))
            }
        }, (error)=>{
            console.log(error)
        });
    }
}

const fetchObjects = (state, params, chunk, append, queryParams, searchType) => {
    const histogramType = searchType || state.histogramData.histogramType;
    const {isAuthed, userInfo} = state.authUser
    return dispatch => {
        switch (histogramType){
            case 'jobs':
                dispatch(regularFetch(histogramType, params, chunk, append, queryParams));
                break;
            case 'resumes':
                dispatch(regularFetch(histogramType, params, chunk, append, queryParams));
                break;
            case 'companies':
                dispatch(fetchCompanies(params, chunk, append, queryParams, histogramType));
                break;
            case 'interviews':
                dispatch(fetchInterviews(params, chunk, append, queryParams, histogramType));
                break;
            case 'myJobs':
                if (isAuthed && !userInfo.lookingforwork){
                    return dispatch(fetchUserJobs());
                }
                break;
            case 'profile':
                if (isAuthed && userInfo){
                    return dispatch(setHistogram(userInfo));
                }
                break;
            case 'activity':
                if (isAuthed){
                    return dispatch(fetchUserActivity());
                }
                break;
            case 'reports':
                if (isAuthed && true){
                    return dispatch(fetchReports());
                }
                break;
            case 'saveditems':
                if (isAuthed){
                    dispatch(fetchSavedItems())
                }
                break;
            case 'jobhuntUsers':
                if (isAuthed && (userInfo.isJHAdmin || userInfo.isAdmin)){
                    dispatch(fetchPersonalJourneys(params))
                }
                break
            default:
                return;
        }
    }
}

const requestObjects = (chunk = 1, clearJobs) => {
    return {
        type: REQUEST_JOBS,
        chunk,
        clearJobs
    }
}

const isCanFetch = (state, searchType) => {
    const isFetching = state.histogramJobs.isFetching
    const histogramType = searchType || state.histogramData.histogramType;

    if (!histogramType){
        return false;
    } else if (isFetching) {
        return false
    } else {
        return true
    }
}

const hasPermission = (state, searchType) => {
    const histogramType = searchType || state.histogramData.histogramType;
    const {isAuthed, userInfo} = state.authUser

    switch (histogramType){
        case 'jobs':
            return true//(!isAuthed || userInfo.lookingforwork === true)
        case 'resumes':
            return true//(isAuthed && userInfo.lookingforwork === false)
        case 'companies':
            return true
        case 'myJobs':
            return (isAuthed && userInfo.lookingforwork === false)
        case 'profile':
            return (isAuthed && userInfo)
        case 'activity':
            return (isAuthed)
        case 'reports':
            return (isAuthed && true)
        case 'saveditems':
            return (isAuthed)
        case 'jobhuntUsers':
            return (isAuthed && (userInfo.isJHAdmin || userInfo.isAdmin))
        case 'interviews':
            return true
        default:
            return false;
    }
}

export const fetchData = (params, append = false, queryParams, searchType) => (dispatch, getState) => {
    //const state = getState();

    if (!hasPermission(getState(), searchType)){
        
    } else if (isCanFetch(getState(), searchType)) {
        let chunk = (append) ? getState().histogramJobs.chunk + 1 : 1;
        dispatch(requestObjects(chunk, !append))

        dispatch(fetchObjects(getState(), params, chunk, append, queryParams, searchType))
    }
}

export const clearHistogramObjects = () => {
    return {
        type: CLEAR_OBJECTS
    }
}