import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class SearchEnginesDialog extends Component {
    render(){
        const contentStyle = (this.props.pre) ? {whiteSpace:"pre"} : {}

		return (
			<Dialog
                classes={{ paper: "alertPaper" }}   
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={contentStyle}>
                        Show profile on search engines?<br/>
                        Get 300% more offers by showing your profile on search engines<br/>
                        Recruiters can find you on Google, Bing and other search engines
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary" aria-label="No">
                        No
                    </Button>
                    <Button onClick={this.props.enableSearchEngines} color="primary" variant="contained" aria-label="Yes, get more offers" autoFocus>
                        Yes, get more offers
                    </Button>
                </DialogActions>
            </Dialog>
		)
	}
}