import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ReactGA from 'react-ga4'
import HistApi from "../utils/HistApi";
import '../styles/HistAlert.css'
import { updateUserInfo } from '../redux/actions/authActions';
import { showDialog } from '../redux/actions/MainDialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton'

class HistAlert extends Component {
    goToRoute = (route) => {
        const {lookingforwork, step} = this.props

        ReactGA.event({category: 'Suggestions',action: 'click_suggestions_' + ((lookingforwork) ? 'talent' : 'recruiter') + '_' + step});

        const { dispatch } = this.props
        dispatch(push(route));
    }

    getUrlText = (text, route) => {
        return (
            <span onClick={()=>this.goToRoute(route)} className="clickableText">{text}</span>
        )
    }

    noPhone = () => {
        ReactGA.event({category: 'Suggestions',action: 'click_suggestions_closePhone'});

        HistApi.saveProfile({noPhone:true}, (result)=>{
            const { dispatch } = this.props
            dispatch(updateUserInfo({userInfo: result.userData}));
        }, error =>{
            if (error.response.data){
                const { dispatch } = this.props
                dispatch(showDialog('Error', error.response.data))
            }
        })
    }

    render() {
        const {lookingforwork, histogramType, step} = this.props

        return (
            <React.Fragment>
                {(step)?
                <li className="histAlert">
                    <div className="alert">
                        <b>For best results:</b><br/>
                        {(lookingforwork)?<React.Fragment>
                            {(step === 'title') && <React.Fragment>                        
                                {this.getUrlText('Add your goal title', '/profile')}
                            </React.Fragment>}
                            {(step === 'phone') && <React.Fragment>                        
                                {this.getUrlText('Add a phone number', '/profile')}<br/>
                                *Phone number will be displayed only to a match
                            </React.Fragment>}
                            {(step === 'tags') && <React.Fragment>                        
                                {this.getUrlText('Add tags', '/profile')}<br/>
                                Tags will enable recruters to find your resume with keywords
                            </React.Fragment>}
                            {(step === 'applies') && <React.Fragment>    
                                Find a jobs that match your talents And click apply
                                {(histogramType !== 'jobs')&&<React.Fragment><br/>{this.getUrlText('Apply for jobs', '/jobs')}</React.Fragment>}
                            </React.Fragment>}
                            {(step === 'seniorityLevel') && <React.Fragment>    
                                {this.getUrlText('Add seniority level', '/profile')}
                            </React.Fragment>}
                        </React.Fragment>:
                        <React.Fragment>
                            {(step === 'noJobs') && <React.Fragment>
                                Create a new job or Activate an existing one, Find talents that match for your jobs And send them connect<br/>
                                {this.getUrlText('Create / Activate a job', '/myJobs')}
                            </React.Fragment>}
                            {(step === 'company') && <React.Fragment>
                                {this.getUrlText('Add a company', '/myJobs')}
                                <br/>Talents are more likely to apply to jobs that show company
                            </React.Fragment>}
                            {(step === 'phone') && <React.Fragment>   
                                <IconButton className="closeIcon" aria-label="no phone" size="small" onClick={this.noPhone}>
                                    <CloseIcon/>
                                </IconButton>              
                                {this.getUrlText('Add a phone number', '/myJobs')}<br/>
                                *Phone number will be displayed only to a match
                            </React.Fragment>}
                            {(step === 'tags') && <React.Fragment>
                                {this.getUrlText('Add tags', '/myJobs')}
                                <br/>Tags will enable talents to find your jobs with keywords
                            </React.Fragment>}
                            {(step === 'salary') && <React.Fragment>
                                {this.getUrlText('Add salary', '/myJobs')}
                                <br/>Talents are more likely to apply to jobs that show salary
                            </React.Fragment>}
                            {(step === 'applies') && <React.Fragment>
                                Find talents that match for your jobs and send them connect
                                {(histogramType !== 'resumes')&&<React.Fragment><br/>{this.getUrlText('Send connect to talents', '/resumes')}</React.Fragment>}
                            </React.Fragment>}
                            {(step === 'seniorityLevel') && <React.Fragment>    
                                {this.getUrlText('Add seniority level to your jobs', '/myJobs')}
                            </React.Fragment>}
                        </React.Fragment>}
                    </div>
                </li>:
                <React.Fragment></React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const {histogramType} = state.histogramData;
    const {isAuthed, userInfo, jobs, applies} = state.authUser;

    let step

    if (isAuthed && histogramType !== 'jobhuntUsers'){
        if (userInfo.lookingforwork){
            if (!userInfo.title){
                step = 'title'
            } else if (!userInfo.phone){
                step = 'phone'
            } else if (!userInfo.tags || userInfo.tags.length === 0){
                step = 'tags'
            } else if (!userInfo.seniorityLevel){
                step = 'seniorityLevel'
            } else if (!applies || applies.length < 5){
                step = 'applies'
            }
        } else {
            if (jobs.length === 0){
                step = 'noJobs'
            } else if (jobs.find(({company}) => (!company))){
                step = 'company'
            } else if (!userInfo.noPhone && jobs.find(({phone}) => (!phone))){
                step = 'phone'
            } else if (jobs.find(({tags}) => (!tags || tags.length === 0))){
                step = 'tags'
            } else if (jobs.find(({salaryMin}) => (!salaryMin))){
                step = 'salary'
            } else if (jobs.find(({seniorityLevel}) => (!seniorityLevel))){
                step = 'seniorityLevel'
            } else if (!applies || applies.length < 5){
                step = 'applies'
            }
        }
    }
    
    return {
        histogramType, lookingforwork:userInfo.lookingforwork, step
    }
}

export default connect(mapStateToProps)(HistAlert)