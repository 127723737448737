import React, { useState } from 'react';
import { Button, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SocialLogin from './SocialLogin';
import AuthApi from "../../utils/AuthApi";
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignUp(props) {
    const {redirectPath} = props

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [payload, setPayload] = useState({});
    const [errors, setErrors] = useState();

    const recaptchaRef = React.useRef();

    const handleOnSubmit = async e => {
        e.preventDefault();

        if ((payload.password === payload.confirmPassword)){
            const token = await recaptchaRef.current.executeAsync();

            AuthApi.register({...payload, captchaToken: token}, data=>{
                props.showMessage('Email sent', 'We sent you an email, please check your inbox')
                //console.log(data)
            }, (error)=>{
                if (error.response.data && error.response.data.message){
                    setErrors(error.response.data.message)
                }

                recaptchaRef.current.reset();
            });
        }
    };

    const handleChange = event => {
        let value = event.target.value
        if (event.target.name === 'email'){
            value = value.toLowerCase().trim()
        }

        setPayload({...payload, [event.target.name]: value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <DialogTitle>Sign Up</DialogTitle>
            <DialogContent>
                <Typography className="terms" variant="caption">
                    <span>By continuing, you agree to our </span>
                    <a target="_blank" rel="noopener noreferrer" href="/terms">Terms of Use</a>
                    <span> and </span>
                    <a target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>
                    <span>.</span>
                </Typography>
                <SocialLogin redirectPath={redirectPath}/>
                <form onSubmit={handleOnSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" value={payload.email||''}
                            onChange={handleChange}/>
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={payload.password||''}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="confirmPassword" error={(payload.password !== payload.confirmPassword)}>Confirm password</InputLabel>
                        <Input
                            name="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={payload.confirmPassword||''}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowConfirmPassword}>
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="first_name">First name</InputLabel>
                        <Input
                            name="first_name"
                            type="text"
                            id="first_name"
                            value={payload.first_name||''}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="last_name">Last name</InputLabel>
                        <Input
                            name="last_name"
                            type="text"
                            id="last_name"
                            value={payload.last_name||''}
                            onChange={handleChange}
                        />
                    </FormControl>
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6LdtJQEqAAAAAKfoQH6DYc7wwa9fOmasffaAQoNi" size="invisible"/>
                    {(errors&&typeof errors === 'string')&&<Typography color="error">{errors}</Typography>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="Sign Up"
                    >
                        Sign Up
                    </Button>
                </form>
                <Divider className="loginFooterDivider"/>
                <div className="greyText">Already have an account? <span onClick={() => props.setSection('signIn')} className="link">Sign In</span></div>
            </DialogContent>
        </>
    )
}