import {SET_HEAD} from '../actions/head'
import { getMetaDescription } from '../../utils/utils'

export function head(
    state = {
        title: document.title,//'JobSwipe Job Search | Your Dream Job Is 1 Click Away',
        description: getMetaDescription(),//"Search for jobs in your area using JobSwipe's smart job search engine - the best way to find a job. Apply with just 1 click.",
        showSchema: false
    },
    action
) {
    switch (action.type) {
        case SET_HEAD:
            return {
                title: action.title,
                description: action.description,
                canonicalUrl: action.canonicalUrl,
                showSchema: action.showSchema
            }
        default:
            return state
    }
}