import { SET_FILTER_DATA } from '../actions/histogram'

export function filterData(
    state = {}, action) {
	switch (action.type) {
		case SET_FILTER_DATA:
			return action.filterData
      	default:
        	return state
    }
}