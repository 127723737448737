import { getHost } from '../../utils/utils'

export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS'
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS'

function requestLocations() {
    return {
        type: REQUEST_LOCATIONS
    }
}
  
function receiveLocations(json) {
    return {
        type: RECEIVE_LOCATIONS,
        locations: json,
        receivedAt: Date.now()
    }
}
  
function fetchLocations() {
    return dispatch => {
        dispatch(requestLocations())

        var object = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        
        let host = getHost();

        return fetch(host + '/api/getLocations', object)
            .then(response => response.json())
            .then(locations => {
                dispatch(receiveLocations(locations))
                }
            )
    }
}
  
function shouldFetchLocations(state) {
    const locations = state.locations;

    if (locations.isFetching){
        return false;
    } else {
        if (locations.locations.length === 0){
            return true;
        } else {
            return false;
        }
    }
}
  
export function fetchLocationsIfNeeded(){
    return (dispatch, getState) => {
        if (shouldFetchLocations(getState())) {
            return dispatch(fetchLocations())
        }
    }
}