import React, { Component } from 'react'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import { getHost } from '../utils/utils'

class ProLogo extends Component {
    render() {
        const { isAuthed, userInfo, isJobHunt } = this.props
        const isPro = (isAuthed && userInfo.isPro)
        const isJHUser = (isAuthed && userInfo.isActiveJHUser)
        const isJHAdmin = (isAuthed && userInfo.isJHAdmin)
        const isAdmin = (isAuthed && userInfo.isAdmin)

        const isJH = (isJobHunt || isJHUser || isJHAdmin || isAdmin)

        return (
            <>
                <a href={getHost()} style={{color:"#424242", textDecoration:"none", display: 'flex'}}>
                    {!isJH&&<Typography className="JobSwipeLogo" variant="h5" color="inherit">{window.IS_PROD?'JobSwipe':'JS-Test'}</Typography>}
                    {isJH&&window.IS_PROD&&<img style={{height:40}} src="https://jobhunt.co.il/wp-content/themes/jobboard/assets/images/jobhunt-ntl.png" alt="סוכן טאלנטים אישי לעולם ההיי-טק"/>}
                    {isJH&&!window.IS_PROD&&<Typography className="JobSwipeLogo" variant="h5" color="inherit">JH-Test</Typography>}
                </a>
                {(!isJH && isPro)&&<Typography className="proLogo" variant="h6">Pro</Typography>}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    return {
        isAuthed, userInfo
    }
}

export default connect(mapStateToProps)(ProLogo)