import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { IconButton, Paper, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JSData from '../JSData';
import JSTablePagination from '../JSTablePagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadCSV, getCSVContent } from '../../utils/utils';

function Search(props) {
    //const cells = props.cells || []

    //const filteredCells = cells.filter(cell => cell.search)

    const [search, setSearch] = useState('');
    //const [fields, setFields] = useState([]);

    const handleChangeSearch = e => {
        setSearch(e.target.value)
    };

    /*const handleToggleField = (event, newFields) => {
        setFields(newFields||[]);
    };*/

    const handleSearch = e => {
        e.preventDefault();

        props.handleSearch(search, [])
    }

    return (
        <form onSubmit={handleSearch}>
            <TextField size='small' variant='outlined' placeholder="Search" margin="none" value={search || ''} onChange={handleChangeSearch} type="search"/>
            {/*(filteredCells.length>0)&&<ToggleButtonGroup value={fields} onChange={handleToggleField} size='small' style={{margin: '0 8px'}}>
                {filteredCells.map(cell => (
                    <ToggleButton key={cell.id} value={cell.id} aria-label={cell.label}>
                        {cell.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>*/}
            <IconButton disabled={props.disabled/*||(fields.length===0&&search!=='')*/} aria-label="Search" color='primary' style={{padding: 10}} onClick={handleSearch} type="submit">
                <SearchIcon fontSize='small' />
            </IconButton>
        </form>
    )
}

export default function JobsFound(props) {
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [cells, setCells] = useState([]);
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState(false);

    const [search, setSearch] = useState('');
    const [fieldsToSearch, setFieldsToSearch] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        AdminJHApi.getJobsFoundData({},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setError()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }, []);

    const handleChangePage = newPage => {
        setDisabled(true)

        AdminJHApi.getJobsFoundData({search, fieldsToSearch, page:newPage},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(newPage);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    };

    const handleSearch = (search, fieldsToSearch) => {
        setSearch(search||'')
        setFieldsToSearch(fieldsToSearch||[])
        setDisabled(true)

        AdminJHApi.getJobsFoundData({search, fieldsToSearch},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const exportToCsv = () => {
        if (count > 100){
            AdminJHApi.getJobsFoundData({search, fieldsToSearch, noLimit:true},(response)=>{
                const csvContent = getCSVContent(response.cells||[], response.data||[])
                downloadCSV(csvContent, `Jobs Found.csv`)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setDisabled(false)
            })
        } else {
            const csvContent = getCSVContent(cells, data)
            downloadCSV(csvContent, `Jobs Found.csv`)
        }
    }

    return (
        <div className="fullWidthContainer" >
            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center'}} elevation={1}>
                {(error)&&<Typography color="error">{error}</Typography>}
                {(cells.length>0)&&<Search handleSearch={handleSearch} disabled={disabled} cells={cells}/>}
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                <JSData cells={cells} data={data}/>
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                <JSTablePagination
                    count={count}
                    rowsPerPage={100}
                    page={page}
                    onChangePage={handleChangePage}
                    disabled={disabled}
                >
                    <IconButton onClick={exportToCsv} disabled={disabled} color='primary'>
                        <GetAppIcon/>
                    </IconButton>
                    {/*<IconButton onClick={handleShowStats} disabled={disabled} color='primary'>
                        <EqualizerIcon/>
                    </IconButton>*/}
                </JSTablePagination>
            </Paper>
        </div>
    )
}