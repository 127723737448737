import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab, List } from '@material-ui/core';
import { setCardContentTypeAndFetch } from '../redux/actions/cardContent';
import CardContentInterview from '../components/review/CardContentInterview';
import CardContentReview from '../components/review/CardContentReview';
import { toggleLoginDialog } from '../redux/actions/authActions';
import { getCompanyUrl } from '../utils/utils';
import Pagination from '@material-ui/lab/Pagination';

const ROWS_PER_PAGE = 10

class CardContentData extends Component {
    state = {
        page:1
    }

    handleChangePage = (event, value) => {
        this.setState({
            page:value
        })
    }

    handleChange = (event, cardContentType) => {
        const { dispatch, company } = this.props
        if (cardContentType === 'Jobs'){
            window.open(`/jobs/${company.name}-jobs-SRCH_Q0,${company.name.length}`);
        } else {
            dispatch(setCardContentTypeAndFetch(cardContentType, company.name))
        }
    };

    setFetchingData = (status) => {
        this.setState({isFetchingData:status})
    }

    showLoginDialog = () => {
        const { dispatch } = this.props
        dispatch(toggleLoginDialog(true))
    };

    componentDidUpdate(prevProps){
        if (this.props.cardContentType !== prevProps.cardContentType || this.props.company !== prevProps.company) {
            this.setState({page:1})
        }
    }

    render() {
        const { data, isFetching, cardContentType, userId, company } = this.props
        const page = this.state.page
        const PageCount = Math.ceil(data.length/ROWS_PER_PAGE)
        const pagedData = data.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE)

        const companyUrl = getCompanyUrl(company.urls)
        const hasData = ['Interviews', 'Reviews'].includes(cardContentType)

        return (
            <React.Fragment>
                <Tabs
                    value={cardContentType || false}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}
                    aria-label="disabled tabs example"                        
                >
                    <Tab value="Interviews" icon={<div>{(company.interviews || 0)}</div>} label="Interviews" disabled={isFetching}/>
                    <Tab value="Reviews" icon={<div>{(company.reviews || 0)}</div>} label="Reviews" disabled={isFetching}/>
                    <Tab value="Jobs" icon={<div>{(company.jobs || 0)}</div>} label="Jobs" disabled={isFetching}/>
                </Tabs>
                {(hasData)&&<React.Fragment>
                    <List>                
                        {pagedData.map((object) => <React.Fragment key={object._id}>
                            {(cardContentType === 'Interviews')&&<CardContentInterview interview={object} companyUrl={companyUrl}
                                userId={userId} showLoginDialog={this.showLoginDialog}/>}
                            {(cardContentType === 'Reviews')&&<CardContentReview review={object} companyUrl={companyUrl} 
                                userId={userId} showLoginDialog={this.showLoginDialog}/>}
                        </React.Fragment>)}
                    </List>
                    {(PageCount > 1)&&<Pagination count={PageCount} page={page} onChange={this.handleChangePage} />}
                </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { data, isFetching, cardContentType } = state.cardContent;
    const userId = state.authUser.userInfo._id
    const { job } = state.selectedJob

    return {
        data, isFetching, cardContentType, userId, company:job
    }
}

export default connect(mapStateToProps)(CardContentData)