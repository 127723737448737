import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button';

import '../styles/JobCard.css'
import { setEditMode, apply, promoteObject } from '../redux/actions/selectedJob';
import { getDateText, RECRUITER_ACTIVITY_SUB_TYPES } from '../utils/utils';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdminApi from "../utils/AdminApi"
import { setHistogramWithData, removeObjectFromHistogram } from '../redux/actions/histogramJobs';
import JobsListDialog from '../components/dialogs/JobsListDialog';
import { toggleLoginDialog } from '../redux/actions/authActions';

import ReactGA from 'react-ga4'
import { showDialog, showOrphanApply } from '../redux/actions/MainDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import AddReview from '../components/dialogs/AddReview';
// import Histogram from '../components/Histogram';

const EDIT_HIST_TYPES = ['myJobs', 'profile'];

class CardActionButton extends Component {
    state = {
        openJobList: false,
        addReview: false
    }

    handleCloseAddReview = (title, content, results) => {
        this.setState({addReview:false})

        if (title){
            if (results){
                const selectedId = this.props.selectedObject._id
                this.props.dispatch(setHistogramWithData(results, selectedId))
            }

            this.props.dispatch(showDialog(title, content))
        }
    }

    handleAddReview = () => {
        if(this.props.isAuthed){
            this.setState({addReview:true})
        } else {
            this.props.dispatch(toggleLoginDialog(true))
        }
    }

    showJobListDialog = () => {
		this.setState({
			openJobList: true,
		});
	};

	closeJobListDialog = () => {
		this.setState({ 
			openJobList: false 
		});
	};

    handleApply = () =>{
        const {type, footer, dispatch, isAuthed, histogramType} = this.props
        if (isAuthed){
            if (type === 'jobs'){
                ReactGA.event({category: 'Card',action: 'clickJobYes' + ((footer)?'_footer':'')});
                this.applyObject()
            } else if (type === 'profiles'){
                ReactGA.event({category: 'Card',action: 'clickResumeYes' + ((footer)?'_footer':'')});
                if (this.props.jobs.length > 1){
                    this.showJobListDialog();
                } else if (this.props.jobs.length === 1){
                    this.applyObject(this.props.jobs[0]._id)
                } else if (this.props.jobs.length === 0){
                    dispatch(showDialog('Error', 'You need to have at least one active job'))
                }
            }
        } else {
            if (histogramType === 'jobs'){
                dispatch(showOrphanApply())
                //dispatch(toggleLoginDialog(true))
            } else {
                dispatch(toggleLoginDialog(true))
            }
        }
    }

    handleNext = () => {
        const { dispatch, selectedObject } = this.props
        dispatch(removeObjectFromHistogram(selectedObject._id))
        this.props.scrollToTop()
    }

    handlePromote = () => {
        const {type, dispatch, isAuthed, selectedObject, isOnProcess, lookingforwork} = this.props

        if (isAuthed){
            dispatch(promoteObject(type, selectedObject._id, isOnProcess, (lookingforwork)?'jobseeker':'recruiter', type + 'Promote'))
        } else {
            dispatch(toggleLoginDialog(true))
        }
    }

    applyObject = (jobId = undefined) => {
        const { dispatch, selectedObject, isOnProcess, isAuthed, lookingforwork, visible, histogramType } = this.props
        let { type }= this.props
        if (isAuthed){
            if (histogramType === 'jobs' && !lookingforwork){
                dispatch(showDialog('Error', 'You need to be a job seeker'))
            } else if (!visible){
                dispatch(showDialog('Error', 'Your profile is invisible'))
            } else if (histogramType === 'resumes' && lookingforwork){
                dispatch(showDialog('Error', 'You need to be a recruiter'))
            } else {
                dispatch(apply(type, selectedObject._id, jobId, isOnProcess, histogramType))
            }
        } else {
            if (histogramType === 'jobs'){
                dispatch(showOrphanApply())
                //dispatch(toggleLoginDialog(true))
            } else {
                dispatch(toggleLoginDialog(true))
            }
        }
    }

    handleEditJob = () =>{
		const { dispatch } = this.props
        dispatch(setEditMode(true))
    }

    onIgnoreReport = () => {
        AdminApi.ignoreReport({
            reportId: this.props.selectedObject._id
		}, (results)=>{
			this.updateReports(results)
		}, (error)=>{
            this.props.dispatch(showDialog('Error', JSON.stringify(error)))
		});
    }

    onDeleteReportedUser = () => {
        const {_id: reportId, type, object, objectOwner} = this.props.selectedObject

        AdminApi.deleteReportedUser({
			reportId, reportedId: (type === 'job') ? objectOwner._id : object._id
		}, (results)=>{
			this.updateReports(results)
		}, (error)=>{
            this.props.dispatch(showDialog('Error', JSON.stringify(error)))
		});
    }

    onDeleteReportedJob = () => {
        const {_id: reportId, object} = this.props.selectedObject

        AdminApi.deleteReportedJob({
			reportId, reportedId: object._id
		}, (results)=>{
			this.updateReports(results)
		}, (error)=>{
			this.props.dispatch(showDialog('Error', JSON.stringify(error)))
		});
    }

    onDeleteReportedObject = () => {
        const {selectedObject, type, company} = this.props
        const {_id: reportId, object} = selectedObject

        AdminApi.deleteReportedObject({
			reportId, reportedId: object._id, type, company
		}, (results)=>{
			this.updateReports(results)
		}, (error)=>{
			this.props.dispatch(showDialog('Error', JSON.stringify(error)))
		});
    }

    updateReports = results => {
        const { dispatch } = this.props
        dispatch(setHistogramWithData(results)); 
    }

    sendEmail = () => {
        const email = this.props.selectedObject.emailts || this.props.selectedObject.email

        if(email){
            window.open('mailto:' + email);
        }        
    }

    call = () => {
        const phone = this.props.selectedObject.phone

        if(phone){
            window.open('tel:' + phone);
        }        
    }

    render() {
        const {selectedjobbgcolor,applyOnTop, histogramType, applies, selectedObject, isAuthed, isOnProcess, jobs, 
            lookingforwork, histogramSubType, expired, type, company, showPromote} = this.props;

        const canEdit = EDIT_HIST_TYPES.includes(histogramType);
        let label = '';
        let appliedLabel = '';
        let showIcon = false;

        if (canEdit){
            label = 'Edit';
        } else if (histogramSubType === 'matches'){
            appliedLabel = 'Matched'
        } else if (histogramType === 'resumes' || (histogramType === 'activity' && RECRUITER_ACTIVITY_SUB_TYPES.includes(histogramSubType))){
            label = 'Connect';
            appliedLabel = 'Connect Sent'
        } else if (histogramType === 'saveditems') {
            if(lookingforwork) {
                label = 'Apply'
                if(selectedObject.histogramSubType === 'matches'){
                    appliedLabel = 'Matched'
                } else {
                    appliedLabel = 'Applied'
                }
            } else {
                label = 'Connect'
                if(selectedObject.histogramSubType === 'matches'){
                    appliedLabel = 'Matched'
                } else {
                    appliedLabel = 'Connect Sent'
                }
            }
        } else {
            label = 'Apply'
            if (!isAuthed){
                showIcon = true
                label = (!isAuthed && selectedObject.direction === 'rtl')?'שלח קורות חיים':'Send CV'
            }
            appliedLabel = 'Applied'
        }
        
        const handleFunction = (canEdit) ? this.handleEditJob : this.handleApply;

        let isApplied = false;
        let rowApply = [];
        let dateApply = '';

        if (isAuthed){
            if (lookingforwork){
                rowApply = applies.filter(apply => apply.jobId === selectedObject._id)
            } else {
                rowApply = applies.filter(apply => apply.talentId === selectedObject._id)
            }
            isApplied = (rowApply.length > 0);
            if (isApplied){
                dateApply = getDateText(selectedObject.date || rowApply[0].date)
            }
        }

        const isPromoted = (selectedObject.promoted === 1)        

        return (
            <div className="applySection">
                {(histogramType === 'reports')&&
                <div style={{direction:"ltr", display:"inline-flex"}}>
                    <Button className={"apply" + ((applyOnTop)?"":"")} 
                        style={{backgroundColor:selectedjobbgcolor, marginRight:8}} 
                        variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                        onClick={this.onIgnoreReport}
                        aria-label="Ignore">Ignore</Button>
                    <Button className={"apply" + ((applyOnTop)?"":"")} 
                        style={{backgroundColor:selectedjobbgcolor, marginRight:8}} 
                        variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                        onClick={this.onDeleteReportedUser}
                        aria-label="Delete User">Delete User</Button>
                    {(selectedObject.type==='job')&&<Button className={"apply" + ((applyOnTop)?"":"")} 
                        style={{backgroundColor:selectedjobbgcolor}} 
                        variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                        onClick={this.onDeleteReportedJob}
                        aria-label="Delete Job">Delete Job</Button>}
                    {(type==='review'||type === 'interview')&&<Button className={"apply" + ((applyOnTop)?"":"")} 
                        style={{backgroundColor:selectedjobbgcolor}} 
                        variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                        onClick={this.onDeleteReportedObject}
                        aria-label={"Delete "+type}>Delete {type}</Button>}
                </div>}
                {(histogramType === 'companies')&&
                <div className={"apply" + ((applyOnTop)?" onTop":"")} style={{direction:"ltr"}}>
                    <Button style={{backgroundColor:selectedjobbgcolor}} 
                        variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                        onClick={this.handleAddReview}
                        aria-label="Add a review">
                            <AddIcon style={{marginRight: 4}} />
                            Add a review
                    </Button>
                    <AddReview
                        open={this.state.addReview}
                        onClose={this.handleCloseAddReview}
                        company={company}
                    />
                </div>}
                {(histogramType !== 'reports' && histogramType !== 'companies')&&<React.Fragment>
                    {(isOnProcess)?<CircularProgress/>:
                    <React.Fragment>
                        {(isApplied)?
                        <div>{appliedLabel} {dateApply}</div>:
                        <div className="buttonContainer">
                            <JobsListDialog
                                open={this.state.openJobList}
                                onClose={this.closeJobListDialog}
                                jobs={jobs}
                                handleApplyToProfile={this.applyObject}
                            />
                            <div className={"apply" + ((applyOnTop)?" onTop":"")} style={{direction:"ltr"}}>
                                {!(applyOnTop && expired)&&<Button 
                                    className={"applyButton" + ((isAuthed)?"":" orphan")}
                                    style={((expired)?{marginRight:12}:{backgroundColor:selectedjobbgcolor, marginRight:12})} 
                                    variant={(applyOnTop)?"outlined":"contained"} color="primary" 
                                    onClick={handleFunction}
                                    aria-label={label}
                                    disabled={expired}>
                                        {(showIcon)&&<AttachFileIcon style={{width:16, height:16, marginRight:8, marginLeft:-8}}/>}
                                        {label}
                                    </Button>}
                                {(canEdit && showPromote)&&<Button color="secondary" style={{height:36}} 
                                    variant={(applyOnTop)?"outlined":"contained"}
                                    onClick={this.handlePromote}
                                    disabled={isPromoted}
                                    aria-label={(isPromoted)?'Promoted':'Promote'}>{(isPromoted)?'Promoted':'Promote'}</Button>}
                                {(!canEdit && (isAuthed || expired))&&<Button style={{color:selectedjobbgcolor, borderColor:selectedjobbgcolor, height:36}} 
                                    variant={(applyOnTop)?"outlined":"outlined"} color="primary" 
                                    onClick={this.handleNext}
                                    aria-label="Next">Next</Button>}
                            </div>
                        </div>}
                    </React.Fragment>}
                </React.Fragment>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { histogramSubType, histogramType } = state.histogramData;
    const { isAuthed, applies, jobs, userInfo, promotes } = state.authUser;
    const { job: selectedObject, isOnProcess } = state.selectedJob;
    const { lookingforwork, visible, promoteCredit } = userInfo

    const showPromote = (lookingforwork || (histogramType!=='profile' && ((promoteCredit||0) - (promotes||[]).length) > 0))

    return {
        histogramType, isAuthed, applies, selectedObject, isOnProcess, jobs, lookingforwork, histogramSubType, visible, showPromote
    }
}

export default connect(mapStateToProps)(CardActionButton)