import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PersonalJourneyContent } from '../../components/JH/PersonalJourneyContent'
import { showDialog } from '../../redux/actions/MainDialog'
import HistogramC from '../HistogramC'

class PersonalJourneyPC extends Component {
    /*componentDidMount() {
        this.fetchData(this.props)
    }

    componentDidUpdate(prevProps){
        if (prevProps.userId !== this.props.userId){
            this.fetchData(this.props)
        }
    }

    fetchData = props => {
        const { isJHUser, userInfo } = props

        if (isJHUser){

        }
    }*/

    showMessageDialog = (title, content, pre) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content, pre))
    }

    render() {
        const { isJHUser, userInfo, isActiveJHUser } = this.props

        return (
            <React.Fragment>
                {(isJHUser)?
                    <div className="pageMainContainer" style={{borderRadius: 2, boxShadow:'0 0 2px rgba(0,0,0,0.2)', backgroundColor: '#fff', marginTop: 16}}>
                        <PersonalJourneyContent showMessageDialog={this.showMessageDialog} userInfo={userInfo} isActiveJHUser={isActiveJHUser}/>
                    </div>
                :<HistogramC histogramType={'main'} />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const isJHUser = (userInfo && userInfo.isJHUser)
    const isActiveJHUser = (userInfo && userInfo.isActiveJHUser)

    const userId = (userInfo||{})._id

    return {
        isAuthed, isJHUser, userInfo, userId, isActiveJHUser
    }
}

export default connect(mapStateToProps)(PersonalJourneyPC)