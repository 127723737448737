import React, { useState, Fragment } from 'react';
import { Chip, IconButton, TextField } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { removeDuplicate } from '../utils/utils';

export default function ObjectsList(props) {
    const disabled = props.disabled || false
    const ObjectsList = props.ObjectsList || []

    const [isNewObject, setIsNewObject] = useState(false);
    const [newObject, setNewObject] = useState('');
    
    const handleDeleteSynamediaEmail = synamediaEmailItem => {
        const lowerCaseExcludeListItem = (synamediaEmailItem) ? synamediaEmailItem.toLowerCase() : ''
        const newObjectsList = ObjectsList.filter(item => item.toLowerCase() !== lowerCaseExcludeListItem)

        props.setNewObjects(newObjectsList)
    }

    const handleChange = e => {
        setNewObject(e.target.value)
    };

    const handleSaveSynamediaEmail = () => {
        const newObjectsList = removeDuplicate([...ObjectsList, newObject.toLowerCase()])

        props.setNewObjects(newObjectsList)
        setNewObject('')
        setIsNewObject(false)
    }

    const handleCancelAddSynamediaEmail = () => {
        setIsNewObject(false)
        setNewObject('')
    }

    const handleSynamediaEmailItem = () => {
        setIsNewObject(true)
    }

    return (
        <>
            {ObjectsList.map(synamediaEmail => 
                <Chip key={synamediaEmail} className="profileTag" label={synamediaEmail} variant="outlined" 
                    onDelete={(isNewObject?null:()=>handleDeleteSynamediaEmail(synamediaEmail))} 
                />
            )}
            {(isNewObject)?<Fragment>
                <TextField onChange={handleChange} value={newObject} name={props.fieldName} type={props.fieldInputType}/>
                <IconButton color='primary' onClick={handleSaveSynamediaEmail} aria-label="save" disabled={(disabled || !newObject)}>
                    <SaveIcon fontSize="small" />
                </IconButton>
                <IconButton disabled={disabled} color='primary' onClick={handleCancelAddSynamediaEmail} aria-label="cancel">
                    <CancelIcon fontSize="small" />
                </IconButton>
            </Fragment>:
            <IconButton disabled={disabled} color='primary' onClick={handleSynamediaEmailItem} aria-label="add">
                <AddIcon fontSize="small" />
            </IconButton>}
        </>
    )
}