import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, Paper, Popover, TextField, Typography } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import SendIcon from '@material-ui/icons/Send';
import { dateAsString, getNameFromEmail } from '../../../utils/utils';
import AdminJHApi from "../../../utils/AdminJHApi"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    commentsList: {
        marginTop: 8
    },
    commentsListPaper: {
        backgroundColor: '#e8f0fe'
    }
})

export default function Comments(props) {
    const userEmail = props.userEmail
    const isLoading = props.isLoading
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journeyData = props.journeyData || {}
    const journeyId = journeyData._id

    const comments = journeyData.comments || []

    const [anchorEl, setAnchorEl] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [error, setError] = useState();
    const [dialogData, setDialogData] = useState({});
    const [editContent, setEditContent] = useState();

    const handleChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setError('')
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddComment = e => {
        e.preventDefault()

        let paramsToPass = {journeyId, newComment}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.addCommentToPesonalJourney(paramsToPass,(response)=>{
            setNewComment('');
            setError('')
            
            props.postServerCall(null, response)
        }, (error) => {
            if(error){
                setError(error)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleEdit = (id, content) => () => {
        setDialogData({open:true, id, content, action:'Edit'})
        setEditContent(content)
    }

    const handleDelete = id => () => {
        setDialogData({open:true, id, action:'Delete'})
    }

    const handleCancel = () => {
        setDialogData({})
        setEditContent()
    }

    const handleSaveComment = e => {
        e.preventDefault()

        let paramsToPass = {journeyId, editContent, id:dialogData.id, action:dialogData.action}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.editDeleteCommentToPesonalJourney(paramsToPass,(response)=>{
            setDialogData({})
            setEditContent()
            setError('')
            
            props.postServerCall(null, response)
        }, (error) => {
            if(error){
                setError(error)
            } else {
                setError('Something went wrong')
            }
            props.postServerCall()
        })
    }

    const handleChangeEdit = (event) => {
        setEditContent(event.target.value);
    };

    const open = Boolean(anchorEl);

    const classes = useStyles();

    return (
        <>
            <Dialog onClose={handleCancel} open={(dialogData.open||false)} fullWidth>
                <DialogTitle>{dialogData.action}</DialogTitle>
                {<form onSubmit={handleSaveComment}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        {(dialogData.action==='Edit')&&<TextField variant='outlined' onChange={handleChangeEdit} multiline fullWidth value={editContent}/>}
                        {(dialogData.action==='Delete')&&<Typography>Are you sure?</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={(isLoading||(dialogData.action==='Edit'&&!editContent))}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            {(dialogData.action==='Edit')?'Save':'Delete'}
                        </Button>
                    </DialogActions>
                </form>}
            </Dialog>

            <Badge badgeContent={comments.length} overlap="circular" color="secondary">
                <Fab onClick={handleClick} color="primary" size='small'>
                    <NoteIcon fontSize='small'/>
                </Fab>
            </Badge>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.commentsList}
                classes={{ paper: classes.commentsListPaper}}
            >
                <div style={{width:350, minHeight:300, maxHeight:550}}>
                    <Paper elevation={1} style={{margin: 8, padding: 8}}>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <form onSubmit={handleAddComment}>
                            <div style={{display: 'flex',width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                                <TextField value={newComment||''} variant="outlined" multiline fullWidth margin="none" size='small' onChange={handleChange}/>
                                <div>
                                    <IconButton type="submit" color='primary' disabled={isLoading || !newComment}>  
                                        <SendIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>
                        </form>
                    </Paper>
                    {comments.map((comment, index) => (
                        <Paper key={index} elevation={1} style={{margin: 8, padding: 8}}>
                            <div style={{display: 'flex',width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography><strong>{getNameFromEmail(comment.author)||''}</strong></Typography>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {(comment.id!==undefined && comment.author===userEmail)&&<>
                                    <IconButton color='primary' onClick={handleEdit(comment.id, comment.content)} disabled={isLoading}>  
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton color='primary' onClick={handleDelete(comment.id)} disabled={isLoading}>  
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    </>}
                                    <Typography style={{color:'rgba(0, 0, 0, 0.54)', display: 'inline'}}>{dateAsString(new Date(comment.date)||'')}</Typography>
                                </div>
                            </div>
                            <Typography dir='auto' style={{whiteSpace: 'pre-line'}}>{comment.content||''}</Typography>
                        </Paper>
                    ))}
                </div>
            </Popover>
        </>
    )
}
