import React, { Component } from 'react'
import { Button, Checkbox, FormControlLabel, Paper, TextField } from '@material-ui/core';
import { connect } from 'react-redux'
import HistogramC from './HistogramC';
import AdminApi from "../utils/AdminApi"
import { showDialog } from '../redux/actions/MainDialog';
import { UserCard } from '../components/UserCard';

class Users extends Component {
    state = {
        userId: '',
        email: '',
        isJHAdmin: false,
        isJHUser: false,
        users: []
    }

    isChnged(prevValue, newValue){
        return ((newValue) && (!prevValue || newValue !== prevValue))
    }

    componentDidUpdate(prevProps){
        if (this.props.isAdmin && ((prevProps.match && this.props.match && this.isChnged(prevProps.match.params.userId, this.props.match.params.userId)) ||
            this.isChnged(prevProps.isAdmin, this.props.isAdmin))){
            this.setState({userId: this.props.match.params.userId})

            this.fetchData({userId: this.props.match.params.userId})
        }
    }

    handleChange = event => {
        let newState = {[event.target.name] : event.target.value}
        if(event.target.name === 'userId'){
            newState.email = ''
        }
        if(event.target.name === 'email'){
            newState.userId = ''
        }

        this.setState(newState)
    }

    handleChangeCheck = event => {
        this.setState({[event.target.name] : event.target.checked})
    }

    handleSearch = e => {
        if(e){
            e.preventDefault();
        }

        const { userId, email, isJHAdmin, isJHUser } = this.state

        this.fetchData({userId, email, isJHAdmin, isJHUser})
    };

    fetchData = params => {
        AdminApi.getAdminUserData(params,(data)=>{
            this.setState({users: data})
        }, (error) => {
            const { dispatch } = this.props
            dispatch(showDialog('Error', error.error))
        })
    }

    handleChangeUser = (userIdToChange, data, cb) => {
        const { userId, email, isJHAdmin, isJHUser } = this.state

        AdminApi.setAdminUserData({userId:userIdToChange, data, fetchParams:{userId, email, isJHAdmin, isJHUser}},(data)=>{
            this.setState({users: data})
            return cb(true)
        }, (error) => {
            const { dispatch } = this.props
            dispatch(showDialog('Error', error.error))
            return cb(false)
        })
    }

    handleDeleteUser = userIdToDelete => {
        const { userId, email, isJHAdmin, isJHUser } = this.state

        AdminApi.excludeUserByAdminAndGetUsers({userId:userIdToDelete, fetchParams:{userId, email, isJHAdmin, isJHUser}},(data)=>{
            this.setState({users: data})
        }, (error) => {
            const { dispatch } = this.props
            dispatch(showDialog('Error', error.error))
        })
    }

    render() {
        const { isAdmin } = this.props
        const { userId, email, isJHAdmin, isJHUser, users } = this.state

        return (
            <React.Fragment>
                {(isAdmin)?
                    <div className="pageMainContainer">
                        <Paper className="profileSection" elevation={1}>
                            <form onSubmit={this.handleSearch}>
                                <TextField name="userId" label="User Id" margin="none" style={{marginRight:16}}
                                    value={userId} onChange={this.handleChange}/>
                            
                                <TextField name="email" label="Email" margin="none" style={{marginRight:16}}
                                    value={email} onChange={this.handleChange}/>
                                <FormControlLabel
                                    control={<Checkbox name="isJHAdmin" color="primary" onChange={this.handleChangeCheck} checked={isJHAdmin || false}/>}
                                    label="JobHunt admin"
                                />
                                <FormControlLabel
                                    control={<Checkbox name="isJHUser" color="primary" onChange={this.handleChangeCheck} checked={isJHUser || false}/>}
                                    label="JobHunt user"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{marginTop:8}}
                                    aria-label="Search"
                                >
                                    Search
                                </Button>
                            </form>
                        </Paper>
                        {users.map((user) => <UserCard key={user._id} user={user} handleChangeUser={this.handleChangeUser} handleDeleteUser={this.handleDeleteUser}/>)}
                    </div>
                :<HistogramC histogramType={'main'} />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const isAdmin = (userInfo && userInfo.isAdmin)

    return {
        isAuthed, isAdmin
    }
}

export default connect(mapStateToProps)(Users)