import React/*, { useState, useEffect, Fragment }*/ from 'react';

import { PersonalJourneyContent } from './PersonalJourneyContent';

export function PersonalJourneyCardContent(props) {
    //console.log(props)

    //const { selectedjobbgcolor/*, scrollToTop, userTags, isAuthed, lookingforwork*/ } = props;
    const jobHuntUser = props.jobHuntUser || {}
    const personal_journey = jobHuntUser || {}//.personal_journeys || {}
    //const editMode = props.editMode || false;
    //const host = getHost();

    //const location = getlocationText(jobHuntUser.location);
    //const employmentType = getemploymentTypeText(jobHuntUser.employmentType);
    //const dateText = getDateText(jobHuntUser.lastActive || jobHuntUser.updated);
    //const selectedjobbgcolor = (props.selectedjobbgcolor) ? props.selectedjobbgcolor : '#396a92'
    //const applyButtonColor = (props.view === 'MOBILE') ? selectedjobbgcolor : 'primary'
    //const applyOnTop = (props.applyOnTop) ? props.applyOnTop : false;
    //const avatarSrc = (jobHuntUser.avatar && jobHuntUser.avatar.url) ? jobHuntUser.avatar.url : undefined

    const direction = 'ltr'//(jobHuntUser.direction && jobHuntUser.direction !== 'N/A')?jobHuntUser.direction:'ltr'

    /*const showCVText = !((jobHuntUser.personal_info !== undefined && jobHuntUser.personal_info !== '') || 
        (jobHuntUser.experience !== undefined && jobHuntUser.experience.length > 0) || 
        (jobHuntUser.education !== undefined && jobHuntUser.education.length > 0))*/

    //const {badge, badgeDesc} = getJobBadge(resume, 'profile')

    //const ResumeUrl = host + getRusumeRoute(jobHuntUser._id, jobHuntUser.first_name, jobHuntUser.title)
    
    return (
        <div className={"card " + direction}>
            {/*<div className="jobHuntUsersCardHeader" style={{backgroundColor:selectedjobbgcolor}}>
                <div className="cardHeaderContent">
                    <div className="jobTitleSection">
                        <div className="jobTitle">
                            {personal_journey.title || ''}
                        </div>
                        {\*<div className={"shareButton " + direction}>
                            <span onClick={showShareDialog}>
                                <Share/>
                            </span>
                        </div>*\}
                    </div>
                    <div className="jobInfoSection">
                        <UserAvatar positionAbsolute 
                            firstName={jobHuntUser.first_name} 
                            lastName={jobHuntUser.last_name} 
                            avatarSrc={avatarSrc} 
                            backgroundColor={jobHuntUser.card_color} />
                        <div className={"jobInfoSectionInner " + direction}>
                            <div className="jobCompany">{(personal_journey.first_name || '') + ' ' + (personal_journey.last_name || '')}</div>
                            <div className="jobLocation" style={{textTransform: 'lowercase'}}>{personal_journey.email || ''}</div>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="jobHuntUsersCardFooter">
                <PersonalJourneyContent 
                    admin 
                    userId={personal_journey.userId} 
                    /*userInfo={jobHuntUser}*/ 
                    showMessageDialog={props.showMessageDialog} 
                    updateHist={props.updateHist}
                    autoRefreshList={props.autoRefreshList}
                />
            </div>
        </div>
    )
}