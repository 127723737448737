import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class AlertDialog extends React.Component {
	render(){
        const { proButton } = this.props
        const contentStyle = (this.props.pre) ? {whiteSpace:"pre-line"} : {}

		return (
			<Dialog
                classes={{ paper: "alertPaper" }}   
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={contentStyle}>{this.props.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(proButton)&&<Button onClick={this.props.showPaymentDialog} color="secondary" variant="contained" aria-label="JobSwipe Pro">
                        JobSwipe Pro
                    </Button>}
                    <Button onClick={this.props.onClose} color="primary" aria-label="Ok" autoFocus={!proButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
		)
	}
}