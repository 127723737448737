import AdminApi from "../../utils/AdminApi";

export const CLEAR_HTML_TEMPLATES = 'CLEAR_HTML_TEMPLATES'
export const REQUEST_HTML_TEMPLATES = 'REQUEST_HTML_TEMPLATES'
export const RECEIVE_HTML_TEMPLATES = 'RECEIVE_HTML_TEMPLATES'

const isCanFetch = state => {
    const isFetching = state.htmlTemplates.isFetching

    if (isFetching) {
        return false
    } else {
        return true
    }
}

const requestHtmlTemplates = () => {
    return {
      	type: REQUEST_HTML_TEMPLATES
    }
}

export function receiveHtmlTemplates(allHtmlTemplates, paramsUnfiltered) {
    const templateSupports = allHtmlTemplates.find(selected => selected.name === 'templateSupports') || {};
    const htmlTemplates = allHtmlTemplates.filter(selected => selected.name !== 'templateSupports') || []
    const css = templateSupports.css || ''
    const mainHtml = templateSupports.mainHtml || ''
    const params = paramsUnfiltered || {}
    
    return {
        type: RECEIVE_HTML_TEMPLATES,
        css, mainHtml, params, htmlTemplates
	}
}

export const clearHistogramSelected = () => {
    return {
        type: CLEAR_HTML_TEMPLATES
    }
}

export const fetchHtmlTemplates = () => {
	return (dispatch, getState) => {
        const state = getState();

        if (isCanFetch(state)) {
            dispatch(requestHtmlTemplates())

            AdminApi.getHtmlTemplates((results)=>{
                dispatch(receiveHtmlTemplates(results.htmlTemplates, results.params))
            }, (error)=>{
                dispatch(receiveHtmlTemplates([]))
                //dispatch(showDialog('Error', JSON.stringify(error)))
            });


            //dispatch(doFetchData(getState(), params, append, queryParams))
            /*let chunk = (append) ? state.histogramJobs.chunk + 1 : 1;
            dispatch(requestObjects(chunk, !append))

            dispatch(fetchObjects(state, params, chunk, append, queryParams))*/
        }
    }
}