import React, { Component } from 'react'
import Share from '@material-ui/icons/Share';
import AccessTime from '@material-ui/icons/AccessTime';
import Work from '@material-ui/icons/Work';
import UserAvatar from '../containers/UserAvatar'

import ShareDialog from './dialogs/ShareDialog'
import ReportDialog from '../containers/ReportDialog';
import { getDateText, getHost, getemploymentTypeText, getlocationText, pushShareGaEvent, getJobBadge, getRusumeRoute } from '../utils/utils'
import Profile from '../containers/Profile';
import AboutMe from './profile/AboutMe';
import Skills from './profile/Skills';
import ProfileList from './profile/ProfileList';
import Links from './profile/Links';

import '../styles/JobCard.css'
import CardActionButton from '../containers/CardActionButton';
import Resume from './profile/Resume';
import Contact from '../containers/Contact';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BriefcaseAccountIcon from 'mdi-material-ui/BriefcaseAccount'

export default class ResumeCardContent extends Component {
    state = {
		open: false,
		openReportDialog: false
	};

	showShareDialog = () => {
		const {histogramType}=this.props
		pushShareGaEvent(histogramType, 'show')

		this.setState({
			open: true,
		});
	};

	closeShareDialog = () => {
		const {histogramType}=this.props
		pushShareGaEvent(histogramType, 'close')
		
		this.setState({ 
			open: false 
		});
	};

	showReportDialog = () => {
		if (this.props.isAuthed){
			this.setState({
				openReportDialog: true,
			});
		} else {
			this.props.showLogin()
		}
	};

	closeReportDialog = () => {
		this.setState({ 
			openReportDialog: false 
		});
	};

	handleDoneEdit = () => {
		this.props.setEditMode(false)
	}

    render() {
		const { selectedjobbgcolor, histogramType, scrollToTop, userTags, isAuthed, lookingforwork } = this.props;
		const resume = this.props.resume || {}
		const editMode = this.props.editMode || false;
		const host = getHost();

		const location = getlocationText(resume.location);
		const employmentType = getemploymentTypeText(resume.employmentType);
		const tags = resume.tags || [];
		const dateText = getDateText(resume.lastActive || resume.updated);
		//const selectedjobbgcolor = (this.props.selectedjobbgcolor) ? this.props.selectedjobbgcolor : '#396a92'
		const applyButtonColor = (this.props.view === 'MOBILE') ? selectedjobbgcolor : 'primary'
		const applyOnTop = (this.props.applyOnTop) ? this.props.applyOnTop : false;
		const avatarSrc = (resume.avatar && resume.avatar.url) ? resume.avatar.url : undefined

		const direction = 'ltr'//(resume.direction && resume.direction !== 'N/A')?resume.direction:'ltr'

		const searchWords = this.props.searchWords ||  []

		let tagsSearch = '';
		let numOfWordsTotal = 0;

		for(let i=0; i<tags.length; i++){
			let tag = tags[i];
			let numOfWords = tag.split(' ').length;

			if (numOfWordsTotal + numOfWords <= 10){
				if(tagsSearch){
					tagsSearch = tagsSearch + '|';
				}
				tagsSearch = tagsSearch + tag.replace(/ /mgi, '|');

				numOfWordsTotal += numOfWords;
			}
		}

		const showCVText = !((resume.personal_info !== undefined && resume.personal_info !== '') || 
			(resume.experience !== undefined && resume.experience.length > 0) || 
			(resume.education !== undefined && resume.education.length > 0))

		const {badge, badgeDesc} = getJobBadge(resume, 'profile')

		const ResumeUrl = host + getRusumeRoute(resume._id, resume.first_name, resume.title)

        return (
			<React.Fragment>
				{(editMode)?<Profile selectedjobbgcolor={selectedjobbgcolor} handleDoneEdit={this.handleDoneEdit}/>:
            <div className={"card " + direction}>
				<div ref={(el) => { this.cardTop = el }}></div>
				<ShareDialog
					open={this.state.open}
					onClose={this.closeShareDialog}
					url={ResumeUrl}
					title={resume.title}
					description={resume.description}
					histogramType={histogramType}
				/>            	
                    <div className="cardHeader" style={{backgroundColor:selectedjobbgcolor}}>
						<div className="cardHeaderContent">
							<div className="jobTitleSection">
								<div className="jobTitle">
									{resume.title || ''}
									{/*<Highlighter
										highlightClassName="YourHighlightClass"
										searchWords={searchWords}
										autoEscape={true}
										textToHighlight={resume.title || ''}
									/>*/}
								</div>
								<div className={"shareButton " + direction}>
									<span onClick={this.showShareDialog}>
										<Share/>
									</span>
								</div>
								{(!applyOnTop && !lookingforwork && isAuthed && histogramType !== 'profile') && <div className={"bookmarkIconContent " + direction}>
									{this.props.isBookedmark ? 
									<div onClick={this.props.removeSavedItem}>
										{(this.props.view !== 'MOBILE') ? <BookmarkIcon style={{color: '#396a92'}}/> : <BookmarkIcon/>}
										{(this.props.view !== 'MOBILE')&&<div className={"bookmarkIconContentText " + direction}>
											SAVED
										</div>}
									</div> :
									<div onClick={this.props.addSavedItem}>
										<TurnedInNotIcon/>
										{(this.props.view !== 'MOBILE')&&<div className={"bookmarkIconContentText " + direction}>
											SAVE
										</div>}
									</div>}
                    			</div>}
							</div>
							<div className="jobInfoSection">
								<UserAvatar positionAbsolute 
									firstName={resume.first_name} 
									lastName={resume.last_name} 
									avatarSrc={avatarSrc} 
									backgroundColor={resume.card_color} />
								<div className={"jobInfoSectionInner " + direction}>
									<div className="jobCompany">{resume.first_name + ((resume.last_name) ? ' ' + resume.last_name:'')}</div>
									<div className="jobLocation">{location}</div>
									<Contact direction={direction} type="profiles"/>
								</div>
							</div>
						</div>
					</div>
					
                    <div className="cardFooter">
						<CardActionButton selectedjobbgcolor={applyButtonColor} applyOnTop={applyOnTop} scrollToTop={scrollToTop} type="profiles"/>
						{(badge)&&<div className="jobBadge">
							<span className="name">{badge}</span>
							<span>{badgeDesc}</span>
                        </div>}
						<div className="jobMoreInfoSection">
							<div className={"jobMoreInfo " + direction}>
								<span className="jobMoreInfoIcon">
									<AccessTime style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{dateText}</span>
							</div>
							<div className={"jobMoreInfo " + direction}>
								<span className="jobMoreInfoIcon">
									<Work style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{employmentType}</span>
							</div>
							{(resume.seniorityLevel)&&<div className={"jobMoreInfo " + direction}>
								<span className="jobMoreInfoIcon">
									<BriefcaseAccountIcon style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{resume.seniorityLevel}</span>
							</div>}
						</div>
						{(!showCVText)&&<AboutMe userInfo={resume} noEdit searchWords={searchWords}/>}
						<Skills userInfo={resume} noEdit searchWords={searchWords} userTags={userTags} isAuthed={isAuthed}/>
						{(!showCVText)&&<ProfileList field="experience" userInfo={resume} noEdit searchWords={searchWords}/>}
						{(!showCVText)&&<ProfileList field="education" userInfo={resume} noEdit searchWords={searchWords}/>}
						<Links userInfo={resume} noEdit/>
						<Resume userInfo={resume} noEdit view={this.props.view} searchWords={searchWords}/>
						<CardActionButton selectedjobbgcolor={applyButtonColor} scrollToTop={scrollToTop} type="profiles" footer/>
						<ReportDialog
							type={"profile"}
							objectId={resume._id}
						/>
					</div>
                </div>}
			</React.Fragment>
        )
    }
}