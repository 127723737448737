import React, { useState } from 'react';
import { Button, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import SocialLogin from './SocialLogin'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignIn(props) {
    const { redirectPath, loginErrors } = props

    const [showPassword, setShowPassword] = useState(false);
    const [payload, setPayload] = useState({});

    const recaptchaRef = React.useRef();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleOnSubmit = async e => {
        e.preventDefault();

        const token = await recaptchaRef.current.executeAsync();

        props.handleLogin({...payload, captchaToken: token});

        recaptchaRef.current.reset();
    };

    const handleChange = event => {
        let value = event.target.value
        if (event.target.name === 'email'){
            value = value.toLowerCase().trim()
        }

        setPayload({...payload, [event.target.name]: value });
    };

    return (
        <>
            <DialogTitle>Sign in</DialogTitle>
            <DialogContent>
                <Typography className="terms" variant="caption">
                    <span>By continuing, you agree to our </span>
                    <a target="_blank" rel="noopener noreferrer" href="/terms">Terms of Use</a>
                    <span> and </span>
                    <a target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>
                    <span>.</span>
                </Typography>
                <SocialLogin redirectPath={redirectPath}/>
                <form onSubmit={handleOnSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" value={payload.email || ''}
                            onChange={handleChange} />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={payload.password || ''}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {(typeof loginErrors === 'string')&&<Typography color="error">{loginErrors}</Typography>}
                    <div className="link forgotPassword" onClick={() => props.setSection('forgotPassword')}>Forgot Password?</div>
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6LdtJQEqAAAAAKfoQH6DYc7wwa9fOmasffaAQoNi" size="invisible"/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="Sign in"
                    >
                        Sign in
                    </Button>
                </form>
                <Divider className="loginFooterDivider"/>
                <div className="greyText">Don't have an account? <span onClick={() => props.setSection('signUp')} className="link">Sign Up</span></div>
            </DialogContent>
        </>
    )
}