import React, { useEffect, useState } from 'react';
import JourneyTableView from './personalJourney/JourneyTableView';
import JHApi from "../../utils/JHApi"

export default function DashboardJH(props) {
    const isAdmin = props.admin
    const userId = props.userId
    const isActiveJHUser = props.isActiveJHUser || isAdmin
    const autoRefreshList = props.autoRefreshList    

    const [journeyData, setJourneyData] = useState({});
    //const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [freezeHistory, setFreezeHistory] = useState([]);

    const [showJourney, setShowJourney] = useState(false);

    useEffect(() => {
        let paramsToPass = {getExplanations : true}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        //setIsLoadingAll(true)

        JHApi.getPersonalJourneyData(paramsToPass,(response)=>{
            if(response){
                setJourneyData(response.personaljourney);
                setFreezeHistory(response.freezeHistory || [])
            } else {
                setJourneyData({});
                setFreezeHistory([])
            }

            //setIsLoadingAll(false)
        }, (error) => {
            if(error && error.response&& error.response.data&& error.response.data.error){
                console.log(error.response.data.error)     
            } else {
                console.log('Something went wrong')
            }
            setJourneyData({});
        })
    }, [isAdmin, userId]);

    const handleSaveData = (data, onSuccess, onError) => {
        let paramsToPass = {data}

        if (isAdmin){
            paramsToPass.userId = userId
        }
    
        setIsLoading(true)

        JHApi.updatePersonalJourneyData(paramsToPass,(response)=>{
            setJourneyData(response.personaljourney);
            setIsLoading(false)
            if (onSuccess){
                onSuccess(response)
            }
        }, (error) => {
            setIsLoading(false)
            //setIsError(true)
            if (onError){
                onError(error)
            }
        })
    }

    const preServerCall = () => {
        setIsLoading(true)
    }

    const postServerCall = (error, response) => {
        if(error){
            props.showMessageDialog('Error', error)
        } else {
            updateDataByRespone(response)
        }

        setIsLoading(false)
    }

    const updateDataByRespone = response => {
        if(response){
            if(response.personaljourney){
                setJourneyData(response.personaljourney);
            }
            if (autoRefreshList && response.jobHuntClients){
                props.updateHist(response.jobHuntClients, response.jobHuntClients.length)
            }
        }
    }

    const propsStepsContent = {
        isAdmin,
        journeyData,
        //resumes,
        handleSaveData,
        isLoading,
        userId,
        preServerCall,
        postServerCall,
        isActiveJHUser
        //handleAddResume,
        //handleUpdateResume
    }

    return (

        <div className="fullWidthContainer">
            <JourneyTableView {...propsStepsContent} showJourney={showJourney} setShowJourney={setShowJourney} freezeHistory={freezeHistory}/>
        </div>
    )
}