import { Button, Chip, Divider, IconButton, List, ListItem, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import SynamediaClientsApi from "../utils/SynamediaClientsApi"
import { getDateText, removeDuplicate } from '../utils/utils';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

export default function SynamediaClients(props) {
    const permissions = props.permissions || {}

    const [isLoading, setIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [error, setError] = useState('');
    
    const [synamediaEmails, setSynamediaEmails] = useState([]);
    const [isNewSynamediaEmail, setIsNewSynamediaEmail] = useState(false);
    const [newSynamediaEmail, setNewSynamediaEmail] = useState('');

    useEffect(() => {
        setIsLoading(true)

        SynamediaClientsApi.getSynamediaClients({},(response)=>{
            updateDataByRespone(response)

            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }, []);

    const handleApproveClient = clientId => () => {
        setIsLoading(true)

        SynamediaClientsApi.approveSynamediaClient({clientId},(response)=>{
            updateDataByRespone(response)

            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }

    const updateDataByRespone = response => {
        //console.log(response)
        setClients(response.clients)  
        
        if (response.synamediaEmails){
            setSynamediaEmails(response.synamediaEmails)
        } else {
            setSynamediaEmails([])
        }
    }

    const handleDeleteSynamediaEmail = synamediaEmailItem => {
        const lowerCaseExcludeListItem = (synamediaEmailItem) ? synamediaEmailItem.toLowerCase() : ''
        const newSynamediaEmailsList = synamediaEmails.filter(item => item.toLowerCase() !== lowerCaseExcludeListItem)

        setIsLoading(true)

        SynamediaClientsApi.updateSynamediaEmailsList({synamediaEmails:newSynamediaEmailsList},(response)=>{
            setSynamediaEmails(response)
            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }

    const handleSaveSynamediaEmail = () => {
        const newSynamediaEmailsList = removeDuplicate([...synamediaEmails, newSynamediaEmail.toLowerCase()])

        setIsLoading(true)

        SynamediaClientsApi.updateSynamediaEmailsList({synamediaEmails:newSynamediaEmailsList},(response)=>{
            setIsNewSynamediaEmail(false)
            setNewSynamediaEmail('')
            setSynamediaEmails(response)
            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }

    const handleSynamediaEmailItem = () => {
        setIsNewSynamediaEmail(true)
    }

    const handleCancelAddSynamediaEmail = () => {
        setIsNewSynamediaEmail(false)
        setNewSynamediaEmail('')
    }

    const handleChange = e => {
        setNewSynamediaEmail(e.target.value)
    };

    const unapprovedClients = clients.filter(result => result.approved === false)
    const approvedClients = clients.filter(result => result.approved === true)

    return (
        <div>
            <Paper className='profileSection' elevation={1}>
                {(error)&&<Typography color="error">{error}</Typography>}
                <Typography variant="h6">Unapproved: {unapprovedClients.length}</Typography>
                <List>
                    {unapprovedClients.map((client, index) => {
                        //const data = client.data || {}
                        return (
                            <Fragment key={client._id}>
                                <ListItem>
                                    <Button disabled={isLoading} color='primary' variant='contained' style={{margin:8, marginLeft:0}}
                                        onClick={handleApproveClient(client._id)}>Approve</Button>
                                    <Typography>{client.fullName} ({client.email}) {getDateText(client.datecreated)}</Typography>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        )
                    })}
                </List>
            </Paper>
            <Paper className='profileSection' elevation={1}>
                {(error)&&<Typography color="error">{error}</Typography>}
                <Typography variant="h6">Approved: {approvedClients.length}</Typography>
                <List>
                    {approvedClients.map((client, index) => {
                        //const data = client.data || {}
                        return (
                            <Fragment key={client._id}>
                                <ListItem>
                                    <Typography>{client.fullName} ({client.email}) {getDateText(client.datecreated)}, Approved {getDateText(client.approveDate)} by:{client.approvedByEmail}</Typography>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        )
                    })}
                </List>
            </Paper>
            {(permissions.Admin || permissions.JHAdmin)&&<Paper className='profileSection' elevation={1}>
                {(error)&&<Typography color="error">{error}</Typography>}
                <Typography variant='h6'>Synamedia Emails</Typography>
                {synamediaEmails.map(synamediaEmail => 
                    <Chip key={synamediaEmail} className="profileTag" label={synamediaEmail} variant="outlined" 
                        onDelete={(isNewSynamediaEmail?null:()=>handleDeleteSynamediaEmail(synamediaEmail))} 
                    />
                )}
                {(isNewSynamediaEmail)?<Fragment>
                    <TextField onChange={handleChange} type="email" value={newSynamediaEmail}/>
                    <IconButton color='primary' onClick={handleSaveSynamediaEmail} aria-label="save" disabled={(isLoading || !newSynamediaEmail)}>
                        <SaveIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={isLoading} color='primary' onClick={handleCancelAddSynamediaEmail} aria-label="cancel">
                        <CancelIcon fontSize="small" />
                    </IconButton>
                </Fragment>:
                <IconButton disabled={isLoading} color='primary' onClick={handleSynamediaEmailItem} aria-label="add">
                    <AddIcon fontSize="small" />
                </IconButton>}
            </Paper>}
        </div>
    )
}