import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import UserNav from '../containers/UserNav'
import MainMenu from '../containers/MainMenu';
import HeaderTabs from '../containers/HeaderTabs';
import ActivityNav from '../containers/ActivityNav';
import PostNewJob from '../containers/PostNewJob';
import ProLogo from '../containers/ProLogo';

export default function Header(props) {
    const view = (window.innerWidth <= 768)?'MOBILE':'DESKTOP'
    const isJobHunt = props.isJobHunt || false

    return (
        <AppBar className={"mainBar"+(window.IS_PROD?'':' testEnv')} color="default" style={{backgroundColor:'blue !important'}}>
            <Toolbar className="centeredScreen">
                {/*isJobHunt&&<a href="https://jobhunt.co.il"><img style={{height:64}} src="https://jobhunt.co.il/wp-content/themes/jobboard/assets/images/jobhunt-logo.png" alt="סוכן טאלנטים אישי לעולם ההיי-טק"/></a>*/}
                {(view === 'MOBILE')&&<MainMenu isJobHunt={isJobHunt}/>}
                <ProLogo isJobHunt={isJobHunt}/>
                <div style={{flex:1}}></div>
                {(view === 'DESKTOP')&&<HeaderTabs isJobHunt={isJobHunt}/>}
                <ActivityNav view={view}/>
                {(view === 'DESKTOP' && !isJobHunt)&&<PostNewJob/>}
                <UserNav isJobHunt={isJobHunt}/>
            </Toolbar>
        </AppBar>
    )
}