import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, Switch, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import JSPaper from '../JSPaper';
//import CallToActionIcon from '@material-ui/icons/CallToAction';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DoneIcon from '@material-ui/icons/Done';
//import TodayIcon from '@material-ui/icons/Today';

import clsx from  'clsx';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import AdminJHApi from "../../utils/AdminJHApi"
import ObjectsList from '../ObjectsList';

//getPublishesData

const useStyles = makeStyles((theme) => ({
    cell: {
        border: "1px solid rgba(224, 224, 224, 1)",
        padding: 4
    },
    cellOnList: {
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    userRow:{
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    problem: {
        background: red[300],
        /*'& $cell': {
            color:'white'
        }*/
    },
    published:{
        background: green[200],
    },
    publishing:{
        background: yellow[200],
    },
    publishfailed:{
        background: red[300],
    },
    selected:{
        background: blue[100],
    },
    selectedCanDelete:{
        background: purple[200],
        '&:hover': {
            background: 'white',
        }
    },
    hidden:{
        backgroundColor: grey[400]
    },
    canSelect:{
        cursor: 'pointer',
        '&:hover': {
            background: blue[100],
        }
    },
    canFill:{
        cursor: 'pointer',
        '&:hover': {
            background: purple[200],
        }
    }
}));

function PartSentCell(props) {
    return(
        <>
            {(props.havePart&&props.onList)&&<ScheduleIcon/>}
            {/*(props.havePart&&!props.onList)&&<CallToActionIcon/>*/}
            {(!props.havePart)&&<DoneIcon/>}
        </>
    )
}


export default function JHPublishes(props) {
    const [needFetchData, setNeedFetchData] = useState(true);
    const [isLoading, setIsLoading] = useState();
    const [users, setUsers] = useState([]);
    const [publishList, setPublishList] = useState({});
    const [consts, setconsts] = useState({});
    const [usersOnList, setUsersOnList] = useState({});
    const [globals, setGlobals] = useState({});
    const [selected, setSelected] = useState({});

    const [emailsToSimulate, setEmailsToSimulate] = useState([]);
    const [simulateResults, setSimulateResults] = useState({});

    useEffect(() => {
        if (needFetchData){
            console.log('fetching')
            setNeedFetchData(false)
            setIsLoading(true)

            AdminJHApi.getPublishesData({},(response)=>{
                updateDataByRespone(response)
                setIsLoading(false)
            }, (error) => {
                setIsLoading(false)
                console.log(error)
                //props.showMessageDialog('Error', JSON.stringify(error))
            })
        }
    }, [needFetchData]);

    useEffect(() => {
        let secTimer = setInterval( () => {
            setNeedFetchData(true)
        },30000)

        return () => clearInterval(secTimer);
    }, []);

    const PUBLISH_TIMES = consts.publishTimes || []
    const PARTS = consts.parts || []

    let selectedCellData = {}
    let cellId
    if (selected.type === 'cellOnList' && publishList[selected.publishTime] && publishList[selected.publishTime][selected.part]){
        selectedCellData = publishList[selected.publishTime][selected.part]
        cellId = selectedCellData._id
    }

    if(selected.type === 'user' && usersOnList[selected.user.email]){
        cellId = usersOnList[selected.user.email].id
    }

    const sortedUsers = users.sort((a, b) => {
        const usersOnListA = usersOnList[a.email]
        const usersOnListB = usersOnList[b.email]

        let partsA = a.partsAvailable
        let partsB = b.partsAvailable

        /*PARTS.forEach(function(part){
            if (a[part]){
                partsA++
            }
            if (b[part]){
                partsB++
            }
        })*/

        if (usersOnListA){
            if (usersOnListB){
                return partsA-partsB
            } else {
                return 1
            }
        } else {
            if (usersOnListB){
                return -1
            } else {
                return partsA-partsB
            }
        }
    })

    const handleClickUser = (user) => {
        setSimulateResults({})
        if (selected.type === 'cellOnList'){
            handlePlacement(user, selected.publishTime, selected.part)
        } else {
            if (selected && selected.user && selected.user.email === user.email){
                setSelected({})
            } else {
                setSelected({type:'user', user})
            }
        }
    }

    const handleClickCellOnList = (publishTime, part) => {
        setSimulateResults({})
        if (selected.type === 'user'){
            handlePlacement(selected.user, publishTime, part)
        } else {
            if (selected && selected.publishTime === publishTime && selected.part === part){
                setSelected({})
            } else {
                setSelected({type:'cellOnList', publishTime, part})
            }
        }
    }

    const handlePlacement = (user, publishTime, part) => {
        /*const userOnList = usersOnList[user.email]
        const selectedCellDataClicked = (publishList[publishTime] && publishList[publishTime][part])?publishList[publishTime][part]:{}
        const emailOnCell = selectedCellDataClicked.email

        let newPublishList = {...publishList}
        if (!newPublishList[publishTime]){
            newPublishList[publishTime] = {}
        }

        if (userOnList){
            newPublishList[userOnList.publishTime][userOnList.part] = {}
        }

        if (emailOnCell){
            if (user.email === emailOnCell) {
            } else {
                newPublishList[publishTime][part] = {email:user.email}
                if (userOnList){
                    newPublishList[userOnList.publishTime][userOnList.part] = {email:emailOnCell}
                }
            }
        } else {
            newPublishList[publishTime][part] = {email:user.email}
        }
        setPublishList(newPublishList)*/

        setIsLoading(true)

        AdminJHApi.changePublishList({part, publishTime,userData:user},(response)=>{
            updateDataByRespone(response)
            setSelected({})
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const updateDataByRespone = response => {
        setUsers(response.users)
        setPublishList(response.publishList)
        setconsts(response.consts)
        setUsersOnList(response.usersOnList)
        setGlobals(response.globals)
    }

    const updateGlobals = event => {
        setIsLoading(true)

        AdminJHApi.jhPublishUpdateGlobals({[event.target.name]:event.target.checked},(response)=>{
            setGlobals(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleInlay = event => {
        setIsLoading(true)

        AdminJHApi.inlayPublishList({},(response)=>{
            updateDataByRespone(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleSimulate = event => {
        setIsLoading(true)
        setSimulateResults({})

        AdminJHApi.simulatePublish({publishId:selected.user.publishId, emailsToSimulate},(response)=>{
            setSimulateResults(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            console.log(error)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleDeleteCell = event => {
        if (cellId){
            setIsLoading(true)

            AdminJHApi.deletePublishCell({cellId:cellId},(response)=>{
                updateDataByRespone(response)
                setSelected({})
                setIsLoading(false)
            }, (error) => {
                setIsLoading(false)
                console.log(error)
                props.showMessageDialog('Error', JSON.stringify(error))
            })
        }
    }

    const classes = useStyles();

    return (
        <div className="fullWidthContainer">          
            <Grid container spacing={1} style={{direction: "rtl"}}>
                <Grid item lg={8} md={7} sm={12} style={{direction: "ltr"}}>
                    <TableContainer component={JSPaper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell}></TableCell>
                                    {PARTS.map((part) => (
                                        <TableCell key={part} className={classes.cell}>{part}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {PUBLISH_TIMES.map((publishTime) => {
                                    const publishTimeRow = publishList[publishTime] || {}
                                    return (
                                        <TableRow key={publishTime}>
                                            <TableCell component="th" scope="row" className={classes.cell}>{publishTime}</TableCell>
                                            {PARTS.map((part) => {
                                                const publishData = publishTimeRow[part] || {}

                                                let isSelected = false
                                                let isSelectedCanDelete = false
                                                let isHidden = false
                                                let isClickAble = false
                                                let status = ''

                                                switch (selected.type){
                                                    case 'user':{
                                                        if (publishData.email === selected.user.email){
                                                            isSelectedCanDelete = true
                                                            isClickAble = true
                                                        } else {
                                                            if (selected.user[part]){
                                                                isClickAble = true
                                                            } else {
                                                                isHidden = true
                                                            }
                                                        }
                                                        break;
                                                    }case 'cellOnList':{
                                                        isClickAble = true

                                                        if(selected.publishTime === publishTime && selected.part === part){
                                                            isSelected = true
                                                        }
                                                        break;
                                                    } default:{
                                                        isClickAble = true
                                                    }
                                                }

                                                if(publishData.status && publishData.status !== 'READY'){
                                                    status = publishData.status.toLowerCase()
                                                }

                                                if(isLoading/* || status === 'published' || status === 'publishing'*/){
                                                    isClickAble = false
                                                }

                                                return (
                                                    <TableCell key={part} 
                                                        className={clsx(classes.cell, 
                                                            classes.cellOnList,
                                                            isSelected&&classes.selected, 
                                                            isSelectedCanDelete&&classes.selectedCanDelete,
                                                            isHidden&&classes.hidden, 
                                                            isClickAble&&(selected.type==='user'?classes.canFill:classes.canSelect), 
                                                            status&&classes[status], 
                                                        )} 
                                                        onClick={()=>{
                                                            if(isClickAble){
                                                                handleClickCellOnList(publishTime, part, publishData.email)
                                                            }
                                                        }}>
                                                        {publishData.email}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {(JSON.stringify(usersOnList) === '{}')&&<JSPaper>
                        <Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleInlay}>Fill</Button>
                    </JSPaper>}
                    {(cellId)&&<JSPaper>
                        <Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleDeleteCell}>Delete Form List</Button>
                    </JSPaper>}
                    <JSPaper>
                        <FormControlLabel
                            checked={globals.publishGlobalsCreated || false}
                            control={<Switch color="primary"/>}
                            label="Publish Globals Created"
                            labelPlacement="end"
                            name="publishOn"
                            disabled
                        /><br/>
                        <FormControlLabel
                            checked={globals.publishOn || false}
                            control={<Switch color="primary"/>}
                            label="Can Publish"
                            labelPlacement="end"
                            name="publishOn"
                            onChange={updateGlobals}
                            disabled={isLoading && !globals.publishGlobalsCreated}
                        /><br/>
                        <FormControlLabel
                            checked={globals.isPublishing || false}
                            control={<Switch color="primary"/>}
                            label={"Is Publishing" + (globals.isPublishing?' '+globals.publishTime:'')}
                            labelPlacement="end"
                            name="isPublishing"
                            onChange={updateGlobals}
                            disabled={isLoading && !globals.publishGlobalsCreated}
                        /><br/>
                        {globals.isPublishing&&<div>{globals.publishStep}</div>}
                        <FormControlLabel
                            checked={globals.userApproving!==undefined}
                            control={<Switch color="primary"/>}
                            label={"User Approving: " + (globals.userApproving||'')}
                            labelPlacement="end"
                            name="userApproving"
                            onChange={updateGlobals}
                            disabled={isLoading && !globals.publishGlobalsCreated}
                        />
                    </JSPaper>
                    {(selected.type && selected.type === 'user')&&<JSPaper>
                        <Grid container spacing={1}>
                            <Grid item sm={12} xs={12}>
                                <ObjectsList ObjectsList={emailsToSimulate} setNewObjects={setEmailsToSimulate} fieldName="email" fieldInputType="email"/>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Button disabled={isLoading} variant="contained" color="primary" size="small" onClick={handleSimulate}>Simulate</Button>
                            </Grid>
                            {(simulateResults.emailsSent!==undefined)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsSent: {simulateResults.emailsSent}</Typography>
                            </Grid>}
                            {(simulateResults.runningTime)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>runningTime: {simulateResults.runningTime}</Typography>
                            </Grid>}
                            {(simulateResults.emailsExcluded&&simulateResults.emailsExcluded.length>0)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsExcluded: {JSON.stringify(simulateResults.emailsExcluded)}</Typography>
                            </Grid>}
                            {(simulateResults.emailsFailed&&simulateResults.emailsFailed.length>0)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsFailed: {JSON.stringify(simulateResults.emailsFailed)}</Typography>
                            </Grid>}
                            {(simulateResults.emailsSentForLog&&simulateResults.emailsSentForLog.length>0)&&<Grid item sm={12} xs={12}>
                                <Typography variant='body1'>emailsSentForLog: {JSON.stringify(simulateResults.emailsSentForLog)}</Typography>
                            </Grid>}
                        </Grid>
                    </JSPaper>}
                    {(selectedCellData._id)&&<JSPaper>
                        <Grid container spacing={1}>
                            <Grid item sm={6} xs={12}>
                                <Typography variant='body1'>listId: {selectedCellData._id}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant='body1'>cycleId: {selectedCellData.cycleId}</Typography>
                            </Grid>
                            {(selectedCellData.emailsSent!==undefined)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsSent: {selectedCellData.emailsSent}</Typography>
                            </Grid>}
                            {(selectedCellData.runningTime)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>runningTime: {selectedCellData.runningTime}</Typography>
                            </Grid>}
                            {(selectedCellData.emailsExcluded&&selectedCellData.emailsExcluded.length>0)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsExcluded: {JSON.stringify(selectedCellData.emailsExcluded)}</Typography>
                            </Grid>}
                            {(selectedCellData.emailsFailed&&selectedCellData.emailsFailed.length>0)&&<Grid item sm={6} xs={12}>
                                <Typography variant='body1'>emailsFailed: {JSON.stringify(selectedCellData.emailsFailed)}</Typography>
                            </Grid>}
                            {(selectedCellData.publishError)&&<Grid item sm={12} xs={12}>
                                <Typography variant='body1'>publishError: {selectedCellData.publishError}</Typography>
                            </Grid>}
                        </Grid>
                    </JSPaper>}
                </Grid>

                <Grid item lg={4} md={5} sm={12} style={{direction: "ltr"}}>
                    <TableContainer component={JSPaper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell}></TableCell>
                                    {PARTS.map((part) => (
                                        <TableCell key={part} className={classes.cell} align="center">{part}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedUsers.map((user) => {
                                    const userOnList = usersOnList[user.email] || {}

                                    let isSelected = false
                                    let isSelectedCanDelete = false
                                    let isHidden = false
                                    let isClickAble = false
                                    let isProblem = false

                                    if (!userOnList.part){
                                        isProblem = true
                                    }

                                    switch (selected.type){
                                        case 'user':{
                                            isClickAble = true
                                            if (selected.user.email === user.email){
                                                isSelected = true
                                                isProblem = false
                                            }
                                            break;
                                        }case 'cellOnList':{
                                            if (selectedCellData.email){
                                                if (selectedCellData.email === user.email){
                                                    isSelectedCanDelete = true
                                                    isClickAble = true
                                                } else {
                                                    if (isProblem && user[selected.part]){
                                                        isClickAble = true
                                                        isProblem = false
                                                    } else {
                                                        isHidden = true
                                                    }
                                                }
                                            } else {
                                                if (isProblem){
                                                    if (user[selected.part]){
                                                        isClickAble = true
                                                        isProblem = false
                                                    } else {
                                                        isHidden = true
                                                    }
                                                } else {
                                                    isHidden = true
                                                }
                                            }
                                            break;
                                        } default:{
                                            isClickAble = true
                                        }
                                    }

                                    if(isLoading){
                                        isClickAble = false
                                    }

                                    return (
                                        <TableRow key={user.email} 
                                            className={clsx(
                                                classes.userRow, 
                                                isSelected&&classes.selected, 
                                                isSelectedCanDelete&&classes.selectedCanDelete,
                                                isHidden&&classes.hidden, 
                                                isClickAble&&(selected.type==='cellOnList'?classes.canFill:classes.canSelect), 
                                                isProblem&&classes.problem
                                            )} 
                                            onClick={()=>{
                                                if(isClickAble){
                                                    handleClickUser(user, userOnList)
                                                }
                                            }}>
                                            <TableCell component="th" scope="row" className={classes.cell}>{user.email}</TableCell>
                                            {PARTS.map((part) => (
                                                <TableCell key={part} className={classes.cell} align="center">
                                                    <PartSentCell havePart={user[part]} onList={userOnList.part === part}/>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}