import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getMenuObjects } from '../utils/utils';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ResumesIcon from '@material-ui/icons/Description';
import JobsIcon from '@material-ui/icons/Work';
import ActivityIcon from '@material-ui/icons/Favorite';
import PostJobIcon from 'mdi-material-ui/BriefcasePlus'
import BusinessIcon from '@material-ui/icons/Business';
//import PostJobIcon from 'mdi-material-ui/BriefcasePlusOutline'
import { postNewJob } from '../redux/actions/selectedJob';

class MainMenu extends Component {
    state = {
        openMenu: false,
    };
    
    handletoMenuItemClicked = (location) => {    
        const { dispatch } = this.props
        dispatch(push('/' + location));

        this.handleMenuClose()
    }

    handleMenu = () => {
        this.setState({ openMenu: true });
    };
    
    handleMenuClose = () => {
        this.setState({ openMenu: false });
    };  

    handlePostNewJob = () => {
        this.props.dispatch(postNewJob())
        this.handleMenuClose()
    }

    handleJobHunt = (event, newValue) => {
        this.props.dispatch(push('/personal-journey'));
        this.handleMenuClose()
    }
    
    getIcon = value => {
        return (
            <React.Fragment>
                {(value === 'resumes')&&<ResumesIcon/>}
                {(value === 'jobs')&&<JobsIcon/>}
                {(value === 'activity')&&<ActivityIcon/>}
                {(value === 'companies')&&<BusinessIcon/>}
                {(value === 'myJobs')&&<JobsIcon/>}
            </React.Fragment>
        )
    }

    render() {
        const { isAuthed, userInfo, isFetching, loginChecked, histogramType, isJHUser, isJobHunt } = this.props

        const isRecruiter = (isAuthed && userInfo.lookingforwork === false)

        const menuObject = getMenuObjects(isAuthed, isRecruiter, isJobHunt)

        return (
            <React.Fragment>
                <IconButton color="inherit" aria-label="Menu" style={{marginLeft:-12, marginRight:2}} onClick={this.handleMenu}>
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={this.state.openMenu}
                    onClose={this.handleMenuClose}
                    transitionDuration={300}
                >
                    <div style={{minWidth: 150}}>
                        <IconButton onClick={this.handleMenuClose}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        {menuObject.allowedMenus.map((menu) => (
                        <ListItem button key={menu.value} onClick={() => this.handletoMenuItemClicked(menu.value)} disabled={isFetching}>
                            <ListItemIcon style={{minWidth:40}}>{this.getIcon(menu.value)}</ListItemIcon>
                            <ListItemText primary={menu.label} />
                        </ListItem>
                        ))}
                    </List>
                    {(loginChecked && !isJHUser && histogramType !== 'myJobs')&&
                    <React.Fragment>
                        <Divider/>
                        <ListItem className="postNewJob" button onClick={this.handlePostNewJob} disabled={isFetching}>
                            <ListItemIcon className="icon" style={{minWidth:40}}><PostJobIcon/></ListItemIcon>
                            <ListItemText className="text" primary="Post Jobs Free" />
                        </ListItem>
                    </React.Fragment>}
                    {(isJHUser)&&
                    <React.Fragment>
                        <Divider/>
                        <ListItem button onClick={this.handleJobHunt} disabled={isFetching}>
                            <ListItemText className="text" primary="JobHunt" />
                        </ListItem>
                    </React.Fragment>}
                </Drawer>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo, loginChecked } = state.authUser;
    const isFetching = state.histogramJobs.isFetching
    const histogramType = state.histogramData.histogramType

    return {
        isAuthed, userInfo, isFetching, loginChecked, histogramType, isJHUser: userInfo.isJHUser
    }
}

export default connect(mapStateToProps)(MainMenu)