import React from 'react';
import { connect } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ReportIcon from '@material-ui/icons/FlagOutlined';
import HistApi from "../utils/HistApi"

import '../styles/ShareDialog.css'
import { toggleLoginDialog, updateUserInfo } from '../redux/actions/authActions';
import { IconButton } from '@material-ui/core';

class ReportDialog extends React.Component {
    state = {
        reason: '',
        open: false
    }

    showReportDialog = () => {
        const {isAuthed} = this.props

		if (isAuthed){
			this.setState({
				open: true,
			});
		} else {
			const { dispatch } = this.props
            dispatch(toggleLoginDialog(true))
		}
	};

	handleClose = () => {
        this.setState({
            reason: '', 
            open: false 
        })
    };
    
    handleReport = reason => {
        const {objectId, type, dispatch} = this.props

        HistApi.reportObject({objectId, type, reason},(data)=>{
            dispatch(updateUserInfo({reports:data.reports}))
            this.handleClose()
        }, (error) => {
            console.log(error)
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    handleSaveSection = e => {
        e.preventDefault();

        if(this.state.reason){
            this.handleReport(this.state.reason)
        }
    };

	render(){
        const {objectId, type, isAuthed, loginChecked, reports, dispatch, histogramType, ...other } = this.props;

        let canReport = loginChecked
        if(canReport){
            const ALOW_REPORT = ['jobs', 'resumes', 'activity', 'companies'];
            canReport = ALOW_REPORT.includes(histogramType)
        }
        if (canReport && isAuthed && reports && reports.length > 0){
            let report = reports.find(report => report.objectId === objectId && report.object === type);  
            if(report){
                canReport = false
            }
        }

        const isReview = ['review', 'interview'].includes(type)
        
        const reasons = (isReview)?[
            "This review contains false information",
            "The same person has written multiple reviews",
            "This review was written by management or HR",
            "This review is for the wrong company"
        ]:['Offensive to abusive content', 'This is spam or Scam', 'This make me uncomfortable'];

		return (
            <React.Fragment>
                {(canReport)&&<React.Fragment>
                    {(isReview)?
                    <IconButton onClick={this.showReportDialog} className="reportButtonReview" omponent="span">
                        <ReportIcon className="icon"/>
                    </IconButton>
                    :
                    <Button onClick={this.showReportDialog} className="reportButton">
                        <ReportIcon className="icon"/>
                        <span className="label">Report</span>
                    </Button>
                    }
                    
                    <Dialog onClose={this.handleClose} aria-labelledby="report-dialog" open={this.state.open} {...other}>
                        <DialogTitle id="share-dialog-title" className="modalTitle">Report</DialogTitle>
                        <DialogContent>
                            <span className="close-modal" onClick={this.handleClose}>
                                <Close/>
                            </span>
                            {<Typography>Reason for report</Typography>}
                            {reasons.map((reason) =>
                                <div key={reason}>
                                    <Button color="primary" className="modalOption" onClick={() => this.handleReport(reason)} aria-label={reason}>
                                        {reason}
                                    </Button>
                                </div>
                            )}
                            {<form onSubmit={this.handleSaveSection} style={{marginBottom:8}}>
                                <Grid container spacing={1}>
                                    <Grid item sm={12} xs={12}>
                                        <TextField id="reason" name="reason" label="Other" margin="none" fullWidth 
                                            value={this.state.reason} onChange={this.handleChange}/>
                                    </Grid>
                                    {(this.state.reason)&&<Grid item sm={12} xs={12}>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            aria-label="Submit Report"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>}
                                </Grid>
                            </form>}
                        </DialogContent>
                    </Dialog>
                </React.Fragment>}
            </React.Fragment>
		)
	}
}

function mapStateToProps(state) {
    const { histogramType } = state.histogramData
    const { authUser } = state;
    const { isAuthed, loginChecked, reports } = authUser;

    return {isAuthed, loginChecked, reports, histogramType}
}

export default connect(mapStateToProps)(ReportDialog)