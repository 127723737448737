import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

/*const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}*/

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getInterviews = (params, onSuccess, onError = ()=>{}) => {
    post('getInterviews', params, onSuccess, onError)
}

const addInterview = (params, onSuccess, onError = ()=>{}) => {
    post('addInterview', params, onSuccess, onError)
}

const editInterview = (params, onSuccess, onError = ()=>{}) => {
    post('editInterview', params, onSuccess, onError)
}

const deleteInterview = (params, onSuccess, onError = ()=>{}) => {
    post('deleteInterview', params, onSuccess, onError)
}

const addReply = (params, onSuccess, onError = ()=>{}) => {
    post('addReply', params, onSuccess, onError)
}

const api = {
    getInterviews,
    addInterview,
    editInterview,
    deleteInterview,
    addReply
};
export default api;