import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga4'
import '../styles/Contact.css'
import HistApi from "../utils/HistApi";
import { getCompanyText, getNameLetters, getMyJobs } from '../utils/utils';
import { fetchContact } from '../redux/actions/selectedJob';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toggleLoginDialog, updateUserInfo } from '../redux/actions/authActions';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { showDialog } from '../redux/actions/MainDialog';
import { ContactButton } from '../components/ContactButton';
import { setHistogramWithData } from '../redux/actions/histogramJobs';

class Contact extends Component {
    state = {
        addPhone: false,
        phoneToAllJobs: false,
        newPhone:'',
    };

    sendEmail = () => {
        const {dispatch, email, isAppliedMe, histogramSubType} = this.props

        if(email){
            window.open('mailto:' + email);
        } else if (isAppliedMe){
            switch (histogramSubType){
                case 'applicants':{
                    dispatch(showDialog('Get Contact', 'Press connect to uncover email'))
                    break;
                }
                case 'jobsSuggestion':{
                    dispatch(showDialog('Get Contact', 'Press apply to uncover email'))
                    break;
                }
                default:{

                }
            }
        } else {
            this.getContact('email')
        }   
    }

    call = () => {
        const {dispatch, phone, isMyObject, isAppliedMe, histogramSubType} = this.props

        if(phone){
            window.open('tel:' + phone);
        } else if (isMyObject){
            this.setState({
                addPhone: true,
                newPhone:'',
                phoneToAllJobs: false,
            })
        } else if (isAppliedMe){
            switch (histogramSubType){
                case 'applicants':{
                    dispatch(showDialog('Get Contact', 'Press connect to uncover phone number'))
                    break;
                }
                case 'jobsSuggestion':{
                    dispatch(showDialog('Get Contact', 'Press apply to uncover phone number'))
                    break;
                }
                default:{

                }
            }
        } else {
            this.getContact('phone')
        } 
    }

    getContact = contactType => {
        const {dispatch, objectId, type, isOnProcess, jobownerid, isAuthed} = this.props

        if (isAuthed){
            dispatch(fetchContact((type==='jobs')?jobownerid:objectId, isOnProcess, (type==='jobs')?objectId:undefined, contactType))
        } else {
            ReactGA.event({category:'Payment', action:`GetContacts_notLogedIn_${contactType}`});
            dispatch(toggleLoginDialog(true))
        }
    }

    getText = (object) => {
        const {phone, email, isMyObject, hasPhone} = this.props

        switch (object){
            case 'email':{
                if (isMyObject){
                    return email || 'Missing Email'
                } else {
                    return email || 'Hidden Email'
                }
            }
            case 'phone':{
                if (isMyObject){
                    return phone || 'Missing Phone'
                } else {
                    return phone || ((hasPhone)?'Hidden Phone':'')
                }
            }
            default:{
                return ''
            }
        }
    }

    handleChange = event => {
        this.setState({
            newPhone: event.target.value
        })
    };

    handleCheckBoxChange = name => event => {
        this.setState({[name]:event.target.checked})
    };

    handleSavePhone = e => {
        e.preventDefault();
        const { histogramType, objectId } = this.props
        const { newPhone, phoneToAllJobs } = this.state

        if (newPhone){
            if (histogramType === 'profile') {
                HistApi.saveProfile({phone:newPhone}, (result)=>{
                    const { dispatch } = this.props
                    dispatch(updateUserInfo({userInfo: result.userData}));
                    this.closeAddPhone()
                }, error =>{
                    if (error.response.data){
                        const { dispatch } = this.props
                        dispatch(showDialog('Error', error.response.data))
                    }
                })
            } else if (histogramType === 'myJobs'){
                HistApi.saveUserJob({jobId:objectId, phone:newPhone, phoneToAllJobs}, (results)=>{
                    const { dispatch } = this.props
                    dispatch(updateUserInfo({jobs:getMyJobs(results.results)}))
                    dispatch(setHistogramWithData(results, objectId));
                })
            }
        }
    }

    closeAddPhone = () => {
        this.setState({
            addPhone: false,
            newPhone:'',
            phoneToAllJobs: false,
        })
    }

    render() {
        const {companies, company, phone, email, isOnProcess, hasPhone, isMyObject, histogramType, jobs, showContacts} = this.props
        const {addPhone, newPhone, phoneToAllJobs} = this.state
        const direction = this.props.direction || 'ltr'
        const emailText = this.getText('email')
        const phoneText = this.getText('phone')

        const buttonsColor = (companies && companies[company] && companies[company].colors && companies[company].colors.light && window.innerWidth <= 768) ? 
            companies[company].colors.light : '#396a92';

        const showPhone = isMyObject || phone || (hasPhone && !email)
        const updatePhoneToAllJobs = (histogramType === 'myJobs' && jobs && jobs.length > 1)

        return (
            <React.Fragment>
            {(showContacts)&&<React.Fragment>
                <Dialog
                    open={addPhone}
                    onClose={this.closeAddPhone}
                    aria-labelledby="alert-dialog-title"
                >
                    <form onSubmit={this.handleSavePhone}>
                        <DialogTitle id="alert-dialog-title">Add Phone Number</DialogTitle>
                        <DialogContent style={{maxWidth:350}}>
                            <TextField id="phone" name="phone" label="Phone Number" fullWidth  required
                                value={newPhone || ''} onChange={this.handleChange} helperText="Phone number will be displayed only to a match"/>
                            {(updatePhoneToAllJobs)&&<FormControlLabel label="Set this phone number to other jobs"
                                control={<Checkbox
                                    checked={phoneToAllJobs || false}
                                    onChange={this.handleCheckBoxChange('phoneToAllJobs')}
                                    value="phoneToAllJobs"
                                    color="primary"
                                />}
                            />}
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={this.closeAddPhone} aria-label="Cancel">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary" aria-label="Save">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <div className="contactLink">{emailText}</div>
                {(showPhone)&&<div className="contactLink">{phoneText}</div>}
                {(isOnProcess)?<CircularProgress/>:
                <React.Fragment>
                    <div className="contactContainer">
                        <ContactButton
                        style={{backgroundColor: buttonsColor}}
                        ariatext={"Send email"}
                        onClick={this.sendEmail}
                        direction={direction}
                        type={"email"}>
                        </ContactButton>
                        {(!email)&&<LockOpenIcon onClick={this.sendEmail} className={"unlockBadge " + direction}/>}
                    </div>
                    {(showPhone)&&<div className="contactContainer">
                        <ContactButton
                        style={{backgroundColor: buttonsColor}}
                        ariatext={"Call"}
                        onClick={this.call}
                        direction={direction}
                        type={"phone"}>
                        </ContactButton>
                        {(!phone && !isMyObject)&&<LockOpenIcon onClick={this.call} className={"unlockBadge " + direction}/>}
                        {(!phone && isMyObject)&&<AddIcon onClick={this.call} className={"addBadge " + direction}/>}
                    </div>}
                </React.Fragment>}
            </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { job: selectedObject, isOnProcess } = state.selectedJob;
    const { histogramType, histogramSubType } = state.histogramData;
    const { isAuthed, contacts, jobs, userInfo } = state.authUser;
    const { companies } = state;
    const isMyObject = ['profile', 'myJobs'].includes(histogramType)
    const isAppliedMe = (histogramType === 'activity' && ['applicants', 'jobsSuggestion'].includes(histogramSubType))

    let object = (histogramType === 'reports' && selectedObject.object) ? selectedObject.object : selectedObject

    const { phone:phonedb, emailts, email:emaildb, _id:objectId, jobownerid, hasPhone:hasPhonedb } = object
    let email = emailts || emaildb
    let phone = phonedb
    let hasPhone = hasPhonedb
    
    if (isMyObject){
        const { emailts:emailU } = state.authUser.userInfo

        email = emailU
    } else {
        if (!email && contacts && contacts.length > 0){
            const profileId = (ownProps.type === 'jobs')?jobownerid:objectId
            const contact = contacts.find(contact => contact.profileId === profileId)
            if (contact){
                email = contact.emailts
                phone = contact.phone
            }
        }
    }

    const letters = getNameLetters(object.first_name, object.last_name)
    const company = (letters !== 'U') ? letters : getCompanyText(object.company);

    const {lookingforwork, contactCredit} = userInfo

    const showContacts = (lookingforwork || phone || email || (((contactCredit||0) - (contacts||[]).length) > 0))

    return {phone, email, histogramType, histogramSubType, companies, company, objectId, isOnProcess, 
        jobownerid, isAuthed, hasPhone, isMyObject, isAppliedMe, jobs, showContacts}
}

export default connect(mapStateToProps)(Contact)