import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

/*const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}*/

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getBflClients = (params, onSuccess, onError = ()=>{}) => {
    post('getBflClients', params, onSuccess, onError)
}

const getBflUserPermissions = (params, onSuccess, onError = ()=>{}) => {
    post('getBflUserPermissions', params, onSuccess, onError)
}

const updateBflEmailsList = (params, onSuccess, onError = ()=>{}) => {
    post('updateBflEmailsList', params, onSuccess, onError)
}

const updateBflClientData = (params, onSuccess, onError = ()=>{}) => {
    post('updateBflClientData', params, onSuccess, onError)
}

const api = {
    getBflClients,
    getBflUserPermissions,
    updateBflClientData,
    updateBflEmailsList
};
export default api;