import { getLocation } from '../../utils/AuthHelper'
import { getCompanyText, getNameFromEmail, NO_COMPANY, PESONAL_JOURNEY_STEPS, PUBLISH_STEP_FILTERS, SENIORITIES_LIST } from '../../utils/utils'

import { filterJobs } from './histogramJobs'
import { showDialog } from './MainDialog'
import { getLocationObject } from './searchJobs'

export const INIT_FILTER = 'INIT_FILTER'
export const ADD_TO_FILTER = 'ADD_TO_FILTER'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const CLEAR_FILTERED = 'CLEAR_FILTERED'
export const FILER_SET_COMPANY = 'FILER_SET_COMPANY'

export const NO_UNDISCLOSED = 'Hide Undisclosed'
export const NO_AGENCY = 'Hide Agency'

export const FILTER_COMPANY_TYPES = [NO_UNDISCLOSED,NO_AGENCY]
export const POSIBLE_LOCATIONS = [2,5,10,25,50,100,320]
export const POSIBLE_DATE_POSTED = ['Past day', 'Past 3 days', 'Past week', 'Past month']
export const POSIBLE_EMPLOYMENT_TYPE = ['Full-time','Part-time','Contractor','Temporary','Intern','Volunteer','Per-Diem']
export const POSIBLE_SENIORITY_LEVEL = ['Junior', 'Mid-Level', 'Senior']
export const FILTER_MANAGER = ['No Manager']
export const FILTER_TITLE = ['No Title']
export const FILTER_STATUS = ['No Status', 'signed', 'freezed', 'out of service', 'unsubscribed', 'on hold']
export const FILTER_PLAN = ['No Plan']
export const FILTER_NEED_ATTENTION = [/*'last publish 7-14 days',*/ 'last publish more than 14 days', 'waiting for approval', /*'last publication for standard', */'7th day first part sent', 'more than 10 cycles', 'frozen more than month']
export const FILTER_OTHER = ['scheduled meeting', 'not scheduled meeting', 'have not active safety net', 'active safety net']
export const CLOSED_STATUSES = ['signed', 'freezed', 'not in galina', 'out of service', 'unsubscribed']
//export const STATUSES_TO_SHOW = ['signed', 'freezed', 'out of service', 'unsubscribed', 'on hold']

function initFilter(newFilter={}) {
    return {
        type: INIT_FILTER,
        filter: newFilter
    }
}

function toggleFilter(filterType, filterValue, params) {
    let singleValue = ['date posted', 'location', 'updated'].includes(filterType);

    return {
        type: TOGGLE_FILTER,
        filterType,
        filterValue,
        singleValue,
        params
    }
}

function clearFiltered(filterType) {
    return {
        type: CLEAR_FILTERED,
        filterType
    }
}

const setFilterCompanyText = companyText => {
    return {
        type: FILER_SET_COMPANY,
        companyText
    }
}

export const  toggleFilterAndRefreshPage = (filterType, filterValue) => async (dispatch, getState) => {
    const histogramType = getState().histogramData.histogramType
    let locationObj = {}
    if (filterType === "location"){
        locationObj = getLocationObject(getState())
        if (locationObj && (!locationObj.lat || !locationObj.lng)){
            const {err, userLocation} = await getLocation()

            if(err){
                return dispatch(showDialog('Location required', 'Please enter a location'))
            }

            locationObj = userLocation
        }
    }

    dispatch(toggleFilter(filterType, filterValue, {locationObj}))
    dispatch(filterJobs())

    if (histogramType === 'jobhuntUsers' && filterType === 'status' && CLOSED_STATUSES.includes(filterValue)){
        dispatch(setFilterByJobs(getState().histogramJobs.jobs))
    }
}

export function clearFilteredAndRefreshPage(filterType){
    return dispatch => {
        dispatch(clearFiltered(filterType))
        dispatch(filterJobs())
    }
}

export function setFilterByJobs(jobs, resetFilter=false){
    /*'tags', 'employment type', 'company', 'location', 'date posted', 'updated'  */
    return (dispatch, getState) => {   
        let filterJobs = {}

        if (resetFilter){
            filterJobs['tags'] = {posible:[], filtered:[]};
            filterJobs['company'] = {posible:[...FILTER_COMPANY_TYPES], filtered:[]};
            filterJobs['employment type'] = {posible:POSIBLE_EMPLOYMENT_TYPE, filtered:[]};
            filterJobs['date posted'] = {posible:POSIBLE_DATE_POSTED, filtered:[]}
            filterJobs['updated'] = {posible:POSIBLE_DATE_POSTED, filtered:[]}
            filterJobs['location'] = {posible:POSIBLE_LOCATIONS, filtered:[]}
            filterJobs['seniority Level'] = {posible:POSIBLE_SENIORITY_LEVEL, filtered:[]}
            filterJobs['step'] = {posible:PESONAL_JOURNEY_STEPS, filtered:[]}
            filterJobs['publish step'] = {posible:PUBLISH_STEP_FILTERS, filtered:[]}
            filterJobs['manager'] = {posible:[...FILTER_MANAGER], filtered:[]}
            filterJobs['title'] = {posible:[...FILTER_TITLE], filtered:[]}
            filterJobs['status'] = {posible:[...FILTER_STATUS], filtered:[]}
            filterJobs['plan'] = {posible:[...FILTER_PLAN], filtered:[]}
            filterJobs['need attention'] = {posible:[...FILTER_NEED_ATTENTION], filtered:[]}
            filterJobs['seniority'] = {posible:['not selected', ...SENIORITIES_LIST], filtered:[]}
            filterJobs['other'] = {posible:[...FILTER_OTHER], filtered:[]}
        } else {
            let currentFilterJobs = Object.assign({}, getState().filterJobs, {});

            filterJobs['tags'] = {posible:[...currentFilterJobs.tags.filtered], 
                filtered:[...currentFilterJobs.tags.filtered]};
            filterJobs['company'] = {posible:[...currentFilterJobs.company.posible], 
                filtered:[...currentFilterJobs.company.filtered]};
            filterJobs['employment type'] = {posible:POSIBLE_EMPLOYMENT_TYPE, 
                filtered:[...currentFilterJobs['employment type'].filtered]};
            filterJobs['seniority Level'] = {posible:POSIBLE_SENIORITY_LEVEL, 
                filtered:[...currentFilterJobs['seniority Level'].filtered]};
            filterJobs['date posted'] = {posible:POSIBLE_DATE_POSTED, 
                filtered:[...currentFilterJobs['date posted'].filtered]}
            filterJobs['updated'] = {posible:POSIBLE_DATE_POSTED, 
                    filtered:[...currentFilterJobs['updated'].filtered]}
            filterJobs['location'] = {posible:POSIBLE_LOCATIONS, 
                filtered:[...currentFilterJobs['location'].filtered], params:currentFilterJobs['location'].params}
            filterJobs['step'] = {posible:PESONAL_JOURNEY_STEPS, 
                    filtered:[...currentFilterJobs['step'].filtered]};
            filterJobs['publish step'] = {posible:PUBLISH_STEP_FILTERS, 
                filtered:[...currentFilterJobs['publish step'].filtered]};
            filterJobs['manager'] = {posible:[...currentFilterJobs.manager.posible], 
                filtered:[...currentFilterJobs.manager.filtered]};
            filterJobs['title'] = {posible:[...currentFilterJobs.title.posible], 
                filtered:[...currentFilterJobs.title.filtered]};
            filterJobs['status'] = {posible:[...currentFilterJobs.status.posible], 
                filtered:[...currentFilterJobs.status.filtered]};
            filterJobs['plan'] = {posible:[...currentFilterJobs.plan.posible], 
                filtered:[...currentFilterJobs.plan.filtered]};
            filterJobs['need attention'] = {posible:[...currentFilterJobs['need attention'].posible], 
                filtered:[...currentFilterJobs['need attention'].filtered]};
            filterJobs['seniority'] = {posible:[...currentFilterJobs['seniority'].posible], 
                filtered:[...currentFilterJobs['seniority'].filtered]};
            filterJobs['other'] = {posible:[...currentFilterJobs['other'].posible], 
                filtered:[...currentFilterJobs['other'].filtered]};
        }

        const histogramType = getState().histogramData.histogramType

        jobs.forEach(function(job) { 
            const company = getCompanyText(job.company);

            if (company !== NO_COMPANY && (!filterJobs['company'] || !filterJobs['company'].posible.includes(company))) {
                filterJobs['company'].posible.push(company)
            }

            if(job.tags && job.tags.length > 0){
                job.tags.forEach(function(tag) {
                    if (!filterJobs['tags'] || !filterJobs['tags'].posible.includes(tag)) {
                        filterJobs['tags'].posible.push(tag)
                    }        
                })
            }

            if (histogramType === 'jobhuntUsers'){
                let showManager = true
                if (job.status){
                    if (CLOSED_STATUSES.includes(job.status) && !filterJobs['status'].filtered.includes(job.status)){
                        showManager = false
                    }

                    /*if(!filterJobs['status'].posible.includes(job.status)){
                        filterJobs['status'].posible.push(job.status)
                    }*/
                }

                if (showManager && job.csManager){
                    const manager = getNameFromEmail(job.csManager)
                    if(!filterJobs['manager'].posible.includes(manager)){
                        filterJobs['manager'].posible.push(manager)
                    }
                }

                if (job.titleCategories&& job.titleCategories.length>0){
                    job.titleCategories.forEach(caegory => {
                        if(!filterJobs['title'].posible.includes(caegory)){
                            filterJobs['title'].posible.push(caegory)
                        }
                    });
                }

                if (showManager && job.plan){
                    if(!filterJobs['plan'].posible.includes(job.plan)){
                        filterJobs['plan'].posible.push(job.plan)
                    }
                }
            }
        })

        dispatch(initFilter(filterJobs))
    }
}

export const setFilterCompanyTextAndFilter = companyText => dispatch => {
    dispatch(setFilterCompanyText(companyText))
}
