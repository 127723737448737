import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'

import Experience from './Experience'
import Education from './Education'

import '../../styles/Profile.css'

export default class ProfileList extends Component {
    state = {
        editIndex: undefined,
    }

    handleAdd = () => {
        if (this.state.editIndex){
            this.props.handleCancel([this.props.field]);
        }

        if (this.state.editIndex ===  undefined){
            this.props.changeOnEditCount(true)
        }

        this.setState({
            editIndex: -1,
            newObject: {title:''}
        })
    }

    handleDelete = index => {
        const { userInfo, field } = this.props;
        const list = userInfo[field] || [];

        const newList = [...list.slice(0, index), ...list.slice(index + 1, list.length)]

        this.props.handleChange(field, newList, true)
        
        if (this.state.editIndex !==  undefined){
            this.props.changeOnEditCount(false)
            this.setState({
                editIndex: undefined
            })
        }
    }

    handleEdit = index => {
        if (this.state.editIndex){
            this.props.handleCancel([this.props.field]);
        }

        if (this.state.editIndex ===  undefined){
            this.props.changeOnEditCount(true)
        }

        this.setState({
            editIndex:index
        })
    }

    handleChange = (index, value) => {
        if (index === -1){
            this.setState({
                newObject: value
            })
        } else {
            const { userInfo, field } = this.props;
            const list = userInfo[field] || [];

            list[index] = value;

            this.props.handleChange(field, list)
        }
    };

    handleSaveSection = index => {
        const { userInfo, field } = this.props;

        if (index === -1){
            let list = userInfo[field];
            list.unshift(this.state.newObject);
            this.props.handleChange(field, list)
        }

        this.props.handleSaveSection([field])
        this.props.changeOnEditCount(false)
        this.setState({
            editIndex: undefined
        })
    };

    handleCancel = () => {
        this.props.handleCancel([this.props.field]);
        this.props.changeOnEditCount(false)
    
        this.setState({
            editIndex:undefined
        })
    }

    render() {
        const { userInfo, field, searchWords } = this.props;
        const list = userInfo[field] || [];
        const editIndex = this.state.editIndex;
        const noEdit = this.props.noEdit

        return(
            <React.Fragment>
                {(noEdit)?<React.Fragment>
                    {(list.length > 0)&&<div className="resumeSection">
                        <div className="sectionTitle">{field}</div>
                        {list.map((listObject, index) => 
                            <React.Fragment key={index}>
                                {(field === 'experience')&&<Experience index={index} experience = {listObject} noEdit={noEdit} searchWords={searchWords}/>}
                                {(field === 'education')&&<Education index={index} education = {listObject} noEdit={noEdit} searchWords={searchWords}/>}
                            </React.Fragment>
                        )}
                    </div>}
                </React.Fragment>:

            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">{field}</div>
                {(editIndex === -1)?
                    <React.Fragment>
                        {(field === 'experience')&&<Experience editMode index={editIndex} experience = {this.state.newObject} handleEdit={this.handleEdit}
                            handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} searchWords={searchWords}/>}
                        {(field === 'education')&&<Education editMode index={editIndex} education = {this.state.newObject} handleEdit={this.handleEdit}
                            handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} searchWords={searchWords}/>}
                    </React.Fragment>:
                    <div className="profileField" style={{marginBottom:10}}>
                        <span onClick={this.handleAdd} className="profileEditText">+ Add {field}</span>
                    </div>  
                }
                {list.map((listObject, index) => 
                    <React.Fragment key={index}>
                        <Divider/>
                        {(field === 'experience')&&<Experience editMode={index === editIndex} index={index} experience = {listObject} handleEdit={this.handleEdit}
                            handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} handleDelete={this.handleDelete} searchWords={searchWords}/>}
                        {(field === 'education')&&<Education editMode={index === editIndex} index={index} education = {listObject} handleEdit={this.handleEdit}
                            handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} handleDelete={this.handleDelete} searchWords={searchWords}/>}
                    </React.Fragment>
                )}
            </Paper>}
            </React.Fragment>
        )
    }
}