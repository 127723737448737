import React, { Component } from 'react'
import HistogramJob from './HistogramJob';
import HistogramResume from './HistogramResume';

import '../styles/ObjectOnHist.css'
import { getHost, getDateText } from '../utils/utils';
import { HistogramReview, HistogramInterview } from './review/reportComponents';

export default class HistogramReport extends Component {
    render() {
        const {onClick, histogramType, fetchingData} = this.props
        const report = this.props.report || {}
        const {type, object, reporter, reason, objectOwner, cd} = report

        const profileLink = getHost() + '/users/'

		return(
            <React.Fragment>
                {(fetchingData)?<React.Fragment>
                    <HistogramJob job={{}} fetchingData/>
                </React.Fragment>:
                <React.Fragment>
                    {(type === 'job')&&<HistogramJob job={object} histogramType={histogramType} onClick={onClick}/>}
                    {(type === 'profile')&&<HistogramResume resume={object} onClick={onClick}/>}
                    {(type === 'review')&&<HistogramReview review={object} onClick={onClick}/>}
                    {(type === 'interview')&&<HistogramInterview interview={object} onClick={onClick}/>}
                    <div className="reportInfo">
                        <div className="histogramObjectText">Reported by: <a href={profileLink + reporter._id} target="_blank" rel="noopener noreferrer">{reporter.first_name + ' ' + reporter.last_name}</a></div>
                        <div className="histogramObjectText">Reason: {reason}</div>
                        {(objectOwner && objectOwner._id)&&<div className="histogramObjectText">Owner: <a href={profileLink + objectOwner._id} target="_blank" rel="noopener noreferrer">{objectOwner.first_name + ' ' + objectOwner.last_name}</a></div>}
                        <div className="histogramObjectText">Reported: {getDateText(cd)}</div>
                    </div>
                </React.Fragment>}
            </React.Fragment>
        )
    }
}