import ReactGA from 'react-ga4'

export const SHOW_DIALOG = 'SHOW_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export function showEnableSearchEngines(title, content){
    return dispatch => {
        console.log({title, content})
        dispatch({type: SHOW_DIALOG, modalType:'searchEngines', title, content, pre:true});
    }
}

export function showDialog(title, content, pre=false, proButton=false, contactType=''){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'dialog', title, content, pre, proButton, contactType});
    }
}

export function showSnack(content){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'snack', content});
    }
}

export function showFeedback(){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'feedback'});
    }
}

export function showOrphanApply(){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'orphanApply'});
    }
}

export function showConfirmOrphanApply(){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'confirmOrphanApply'});
    }
}

export function showNewPassword(email, code){
    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'newPassword', email, code});
    }
}

export function showPayment(featureIndex, role = '', from = '', contactType = ''){
    ReactGA.event({category:'Payment', action:`ShowPayment_pro_${role}_${from}${(contactType)?'_'+contactType:''}`});

    return dispatch => {
        dispatch({type: SHOW_DIALOG, modalType:'payment', featureIndex});
    }
}

export function showMatchDialog(mailRecieverData, showNotification, object){
    return dispatch => {
    dispatch({type: SHOW_DIALOG, modalType:'match', mailRecieverData, showNotification, object});
    }
}

export function showDeleteAlertDialog(alertId, alertHistogramType){
    return dispatch => {
    dispatch({type: SHOW_DIALOG, modalType:'deleteAlert', alertId, alertHistogramType});
    }
}

export function showConfirmPushNotification(messageTitle, message, query, registeredUsers){
    return dispatch => {
    dispatch({type: SHOW_DIALOG, modalType:'confirmPushNotification', messageTitle, message, query, registeredUsers});
    }
}

export function showAlertPopup(anchorEl){
    return dispatch => {
    dispatch({type: SHOW_DIALOG, modalType:'alertPopUp', anchorEl});
    }
}

export function closeDialog(){
    return dispatch => {
        dispatch({type: CLOSE_DIALOG});
    }
}