import React, { useState, useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BflClientsApi from "../utils/BflClientsApi"
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@material-ui/core';
import ObjectsList from './ObjectsList';
import { dateAsString } from '../utils/utils';
import EditIcon from '@material-ui/icons/Edit';
import FilterButton from './FilterButton';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      /*'&.checkIn':{
        backgroundColor: '#fafa07'
      },
      '&.checkIn&:nth-of-type(odd)':{
        backgroundColor: '#e8e801'
      }*/
    },
}))(TableRow);

export default function BflClients(props) {
    const permissions = props.permissions || {}

    const [isLoading, setIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [error, setError] = useState('');
    const [BflEmails, setBflEmails] = useState([]);
    const [newData, setNewData] = useState({});
    const [filter, setFilter] = useState('everyone');

    useEffect(() => {
        setIsLoading(true)

        BflClientsApi.getBflClients({},(response)=>{
            updateDataByRespone(response)

            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }, []);

    const updateDataByRespone = response => {
        //console.log(response)
        setClients(response.clients)  
        
        if (response.bflEmails){
            setBflEmails(response.bflEmails)
        } else {
            setBflEmails([])
        }
    }

    const handleChangeBflEmails = (newEmails) => {
        setIsLoading(true)

        BflClientsApi.updateBflEmailsList({bflEmails:newEmails},(response)=>{
            setBflEmails(response)
            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    } 

    const handleEditApprove = client => () => {
        setNewData({
            id: client._id,
            talentPart: client.talentPart || 0,
            bflPart: client.totalPay - (client.talentPart || 0),
            totalPay: client.totalPay
        })
    }

    const handleCancel = () => {
        setError()
        setNewData({})
    }

    const handleSaveData = () => {
        setIsLoading(true)

        BflClientsApi.updateBflClientData({id: newData.id, talentPart:newData.talentPart},(response)=>{
            updateDataByRespone(response)
            setError()
            setNewData({})
            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }

    const handleSave = e => {
        e.preventDefault();

        setIsLoading(true)

        BflClientsApi.updateBflClientData({id:newData.id, talentPart:newData.talentPart, approve:true},(response)=>{
            updateDataByRespone(response)
            setError()
            setNewData({})
            setIsLoading(false)
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setIsLoading(false)
        })
    }

    const handleChangeBflPart = e => {
        setNewData({
            id: newData.id,
            bflPart: e.target.value || 0,
            talentPart: newData.totalPay - (e.target.value || 0),
            totalPay: newData.totalPay
        })
    };

    const hndleSetFilter = newFilter => () => {
        setFilter(newFilter)
    }

    const headCells = [
        /*{ id: 'email', align: "left", label: 'אימייל', sticky:true },
        { id: 'firstName', align: "right", label: 'שם פרטי', sticky:true },
        { id: 'lastName', align: "right", label: 'שם משפחה', sticky:true },*/
        { id: 'email', align: "left", label: 'אימייל' }, //להקטין
        { id: 'phone', align: "right", label: 'טלפון' }//להקטין
    ];

    const headSheelonCells = [
        { id: 'city', align: "right", label: 'עיר מגורים' },//להקטין
        { id: 'age', align: "right", label: 'גיל' },//להקטין
        { id: 'howFindUs', align: "right", label: "מי המליץ לך על השירות של ג'ובהאנט? איך שמעת עלינו?" , minWidth: 90},//להקטין קצת
        { id: 'emptyH', align: "right", label: 'האם יש תאריך לסיום העבודה?' },
        { id: 'isWorking', align: "right", label: `האם עובד/ת היום?` },//להקטין
        //{ id: 'jCol', align: "right", label: 'משמרות לא קשור לתחום מקום עבודה אחרון / נוכחי' },
        { id: 'seniority', align: "right", label: 'וותק בחברה' },//להקטין
        { id: 'jobTitle', align: "right", label: 'הגדרת תפקיד' , minWidth: 100},
        { id: 'whyLeave', align: "right", label: 'מדוע רוצה לעזוב / עזבת?', minWidth: 100},
        { id: 'jobSearchMonths', align: "right", label: 'כמה זמן את/ה מחפש עבודה (בחודשים)?' },
        { id: 'interviews', align: "right", label: 'בכמה ראיונות עבודה שונים היית עד היום?', minWidth:300 },//read  more //להקטין
        //{ id: 'waysJobSearch', align: "right", label: 'באילו דרכים חיפשת עבודה עד עכשיו?', minWidth: 100 }, 
        //{ id: 'witchPositionsLookingFor', align: "right", label: 'אילו משרות מחפש/ת? מה המטרות העיקריות?', minWidth:300 },
        { id: 'searchIssues', align: "right", label: 'מה לדעתך הקשיים העיקריים בחיפוש העבודה שלך? ', minWidth: 100 },
        //{ id: 'currentSalary', align: "right", label: 'מהו השכר הנוכחי?' },//להקטין
        //{ id: 'emptyT', align: "right", label: '' },
        { id: 'searchGeoRenge', align: "right", label: 'גבולות חיפוש גיאוגרפיים  ' },//להקטין
        { id: 'dreamJob', align: "right", label: 'אז מהי משרת החלומות שלך?' , minWidth:350},
        { id: 'wishedSalary', align: "right", label: 'ציפיות שכר?', minWidth: 100 },
        //{ id: 'linkSource', align: "right", label: 'linkSource' },
    ];

    let filteredClients = clients

    switch (filter){
        case 'notApproved':{
            filteredClients = clients.filter(client => !client.approved)
            break;
        } case 'approved':{
            filteredClients = clients.filter(client => client.approved)
            break;
        } case 'approvedNotPaid':{
            filteredClients = clients.filter(client => client.approved && !client.talentPayed)
            break;
        } case 'approvedPaid':{
            filteredClients = clients.filter(client => client.approved && client.talentPayed)
            break;
        } default:{

        }
    }

    let sum = 0

    filteredClients.forEach(filteredClient => {
        if (filteredClient.talentPart !== undefined){
            sum += (filteredClient.totalPay - filteredClient.talentPart)
        }
    });

    return (
        <>
            <Dialog onClose={handleCancel} open={(newData.id!==undefined)}>
                <DialogTitle></DialogTitle>
                <form onSubmit={handleSave}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <TextField name="bflPart" label="Bfl Part" margin="none" fullWidth 
                                    value={newData.bflPart || ''} onChange={handleChangeBflPart} type="number"/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField name="talentPart" label="Talent Part" margin="none" fullWidth 
                                    value={newData.talentPart || 0} disabled type="number"/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button disabled={isLoading} onClick={handleSaveData} color="primary" variant="outlined" size="small" aria-label="Cancel">
                            Save
                        </Button>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save + Approve
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Paper style={{margin:8, position:'relative', overflow:'auto', height:'calc(100% - 16px)'}} elevation={1}>
                <div style={{position: 'sticky',left: 0,zIndex: 999}}>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Typography variant='h6'>Talents: {filteredClients.length}{sum>0?' ('+sum+' ₪)':''}</Typography>

                    <FilterButton filter={filter} label="Everyone" changeFilter={hndleSetFilter} value="everyone"/>
                    <FilterButton filter={filter} label="Not Approved" changeFilter={hndleSetFilter} value="notApproved"/>
                    <FilterButton filter={filter} label="Approved" changeFilter={hndleSetFilter} value="approved"/>
                    <FilterButton filter={filter} label="Approved Not Paid" changeFilter={hndleSetFilter} value="approvedNotPaid"/>
                    <FilterButton filter={filter} label="Approved + Paid" changeFilter={hndleSetFilter} value="approvedPaid"/>
                </div>
                <Table stickyHeader size="small">
                    <TableHead color='primary'>
                        <TableRow>
                            <StyledTableCell
                                align='left'
                                style={{fontWeight: 'bold', position: 'sticky',left: 0,zIndex: 999, borderRight: '2px solid #e0e0e0'}}
                            >
                                שם
                            </StyledTableCell>
                            <StyledTableCell
                                align='left'
                                style={{minWidth:10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                Registered
                            </StyledTableCell>
                            <StyledTableCell
                                align='left'
                                style={{minWidth:10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                Bfl Part
                            </StyledTableCell>
                            <StyledTableCell
                                align='left'
                                style={{minWidth:10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                Talent Part
                            </StyledTableCell>
                            <StyledTableCell
                                align='left'
                                style={{minWidth:10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                Approved
                            </StyledTableCell>
                            <StyledTableCell
                                align='left'
                                style={{minWidth:10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                Talent Paid
                            </StyledTableCell>
                            {headCells.map(headCell => (
                                <StyledTableCell
                                    key={headCell.id} 
                                    align={headCell.align}
                                    style={{minWidth:headCell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                                >
                                    {headCell.label}
                                </StyledTableCell>
                            ))}
                            {headSheelonCells.map(headCell => (
                                <StyledTableCell
                                    key={headCell.id} 
                                    align={headCell.align} 
                                    style={{minWidth:headCell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                                >
                                    {headCell.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredClients.map(client => {
                            const sheelon = client.data
                            return (
                                <Fragment key={client._id}>
                                    <StyledTableRow>
                                        <TableCell
                                            align='left'
                                            style={{position: 'sticky',left: 0,zIndex: 2, color: '#fff', backgroundColor: '#396a92', borderRight: '2px solid #e0e0e0'}}
                                            dir="auto"
                                        >
                                            {client.fullName}
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{borderRight: '1px solid #e0e0e0'}}
                                            dir="auto"
                                        >
                                            {dateAsString(new Date(client.datecreated))}
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{borderRight: '1px solid #e0e0e0', position:'relative'}}
                                            dir="auto"
                                        >
                                            {client.talentPart!==undefined ? client.totalPay-client.talentPart : '-'}
                                            {!client.approved&&<IconButton style={{position: 'absolute',top: 0,right: 0, padding: 5}} aria-label="edit" className="editButton" onClick={handleEditApprove(client)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>}
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{borderRight: '1px solid #e0e0e0', position:'relative'}}
                                            dir="auto"
                                        >
                                            {(permissions.Admin || permissions.JHAdmin)&&client.approved?<a href={client.paymentLink} target='_blank' rel="noopener noreferrer">
                                                {client.talentPart}
                                            </a>:<>
                                                {client.talentPart!==undefined ? client.talentPart : '-'}
                                            </>}
                                            {!client.approved&&<IconButton style={{position: 'absolute',top: 0,right: 0, padding: 5}} aria-label="edit" className="editButton" onClick={handleEditApprove(client)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>}
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{borderRight: '1px solid #e0e0e0', position:'relative'}}
                                            dir="auto"
                                        >
                                            {client.approved?dateAsString(new Date(client.approveDate)):'X'}
                                            {!client.approved&&<IconButton style={{position: 'absolute',top: 0,right: 0, padding: 5}} aria-label="edit" className="editButton" onClick={handleEditApprove(client)}>
                                                <EditIcon fontSize="small" />
                                            </IconButton>}
                                        </TableCell>
                                        <TableCell
                                            align='left'
                                            style={{borderRight: '1px solid #e0e0e0'}}
                                            dir="auto"
                                        >
                                            {client.talentPayed?dateAsString(new Date(client.talentPayedDate)):'X'}
                                        </TableCell>
                                        {headCells.map(headCell => (
                                            <TableCell
                                                key={headCell.id} 
                                                align={headCell.align} 
                                                style={{borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                                {client[headCell.id]} 
                                            </TableCell>
                                        ))}
                                        {headSheelonCells.map(headCell => (
                                            <TableCell
                                                key={headCell.id} 
                                                align={headCell.align} 
                                                dir="auto"
                                                style={{borderRight: '1px solid #e0e0e0', whiteSpace: 'pre-line'}}
                                            >
                                                {sheelon[headCell.id]}
                                            </TableCell>
                                        ))}
                                    </StyledTableRow>
                                </Fragment>
                            )})}
                    </TableBody>
                </Table>
            </Paper>
            {(permissions.Admin || permissions.JHAdmin)&&<Paper className='profileSection' elevation={1}>
                {(error)&&<Typography color="error">{error}</Typography>}
                <Typography variant='h6'>Bfl Emails</Typography>
                <ObjectsList ObjectsList={BflEmails} setNewObjects={handleChangeBflEmails} disabled={isLoading} fieldName="email" fieldInputType="email"/>
            </Paper>}
        </>
    )
}