import '../styles/MatchDialog.css'
import React from 'react'
import { ContactButton } from './ContactButton';
import CompanyLogo from '../containers/CompanyLogo';
import UserAvatar from '../containers/UserAvatar';
import { NO_LOCATION_TEXT } from '../utils/utils'

export default class JobInfoSection extends React.Component {

    getLocationText = (location) => {
        return (location) ? location : NO_LOCATION_TEXT
    }

    render() {
        const buttonsColor = '#396a92';
        const { object, mailRecieverData, call, sendEmail, lookingforwork } = this.props

        return (
        <div className="jobTitleSection">    
            <div style={{ color: 'rgba(0, 0, 0)'}} className="matchDialogLines">
                {object.title&&(object.title).split(' ').slice(0,3).join(' ')}
            </div>
            <div className="jobInfoSection">
                {lookingforwork ?
                    <CompanyLogo companyName={object.company}
                        companyUrl={object.company_url}
                        className="cardLogo" /> :
                    <UserAvatar positionAbsolute
                        firstName={object.first_name}
                        lastName={object.last_name}
                        avatarSrc={object.avatar.url} />}
                <div className={"jobInfoSectionInner ltr"}>
                    <div style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="jobCompany">{lookingforwork ? object.company : mailRecieverData.name}</div>
                    <div style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="jobLocation">{this.getLocationText(object.location)}</div>
                    <div style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="contactContainer">
                        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="contactLink">{mailRecieverData.email}</div>
                        {mailRecieverData.phone && <div style={{ color: 'rgba(0, 0, 0, 0.54)' }} className="contactLink">{mailRecieverData.phone}</div>}
                        <ContactButton
                            style={{ backgroundColor: buttonsColor }}
                            ariatext={"Send email"}
                            onClick={sendEmail}
                            direction={'ltr'}
                            type={"email"}>
                        </ContactButton>
                        {mailRecieverData.phone && <ContactButton
                            style={{ backgroundColor: buttonsColor }}
                            ariatext={"Call"}
                            onClick={call}
                            direction={'ltr'}
                            type={"phone"}>
                        </ContactButton>}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}