import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getMenuObjects } from '../utils/utils';

class HeaderTabs extends Component {
    handleChange = (event, newValue) => {
        const { dispatch } = this.props
        dispatch(push('/' + newValue));
    }

    render() {
        const {histogramType, isAuthed, userInfo, isFetching, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec, isJobHunt} = this.props;        
        const isRecruiter = (isAuthed && userInfo.lookingforwork === false)
        const menuObject = getMenuObjects(isAuthed, isRecruiter, isJobHunt)
        const tabsValue = (menuObject.allowedValues.includes(histogramType)) ? histogramType : false 

        let hideBadge = true

        if (isAuthed && userInfo){
            if (userInfo.lookingforwork){
                hideBadge = !(hasJobsSuggestion || hasMatchesCan)
            } else {
                hideBadge = !(hasApplicants || hasMatchesRec)
            }
        }

        return (
            <React.Fragment>
                <Tabs value={tabsValue} onChange={this.handleChange} classes={{ indicator: "mainTabIndicator" }}>
                    {menuObject.allowedMenus.map(tab => 
                            <Tab key={tab.value} label={<React.Fragment>{tab.label}{(tab.value === 'activity' && !hideBadge)&&<div style={{right:3}} className="JSBadge"></div>}</React.Fragment>}
                                value={tab.value} className="headerTab" classes={{ selected: "selected"}} disabled={isFetching}/>
                    )}
                </Tabs>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;
    const histogramType = state.histogramData.histogramType;
    const isFetching = state.histogramJobs.isFetching
    const {hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec} = userInfo

    return {
        isAuthed, userInfo, histogramType, isFetching, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec
    }
}

export default connect(mapStateToProps)(HeaderTabs)