import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';

import FacebookShareButton from 'react-share/lib/FacebookShareButton'
import FacebookIcon from 'react-share/lib/FacebookIcon'
import EmailShareButton from 'react-share/lib/EmailShareButton'
import EmailIcon from 'react-share/lib/EmailIcon'
import LinkedinShareButton from 'react-share/lib/LinkedinShareButton'
import LinkedinIcon from 'react-share/lib/LinkedinIcon'
import TwitterShareButton from 'react-share/lib/TwitterShareButton'
import TwitterIcon from 'react-share/lib/TwitterIcon'

import '../../styles/ShareDialog.css'
import { pushShareGaEvent } from '../../utils/utils';
import DialogActions from '@material-ui/core/DialogActions';

export default class ShareDialog extends React.Component {
	state = {
		copied: false,
	};

	pushGaEvent = (action, from) => {
		const {histogramType} = this.props

		pushShareGaEvent(histogramType, action, from)
	}

	handleClose = () => {
		this.setState({copied: false});
		this.props.onClose();
	};

	handleCopyLink = e => {
		this.pushGaEvent('copyUrl');

		e.target.select();
		document.execCommand("copy");
		this.setState({copied: true});
	};

	render(){
		const { classes, onClose, url, title, description, histogramType, ...other } = this.props;

		return (
			<Dialog onClose={this.handleClose} aria-labelledby="share-dialog-title" {...other}>
				<DialogTitle id="share-dialog-title" className="modalTitle">Share</DialogTitle>
				<DialogContent className="modal-content">
					<span className="close-modal" onClick={this.handleClose}>
						<Close/>
					</span>
					<div className="urlToShareSection">
						<div className="urlToShareContainer">
							<label htmlFor="urlToShare" className="labelUrlToShare">{(this.state.copied)?"Link copied":"Click to copy link"}</label>
							<input id="urlToShare" onClick={(e) => this.handleCopyLink(e)} readOnly="readonly" value={url}/>
						</div>
					</div>
				</DialogContent>
				<DialogActions className="shareDialogActions">
					<div className="shareButtons">
						<FacebookShareButton beforeOnClick={() => this.pushGaEvent('socialClose', 'facebook')} 
								className="shareIcon" url={url}>
							<FacebookIcon round size={40}/>
						</FacebookShareButton>
						<LinkedinShareButton beforeOnClick={() => this.pushGaEvent('socialClose', 'linkedin')} 
								className="shareIcon" url={url} title={title} description={description}>
							<LinkedinIcon round size={40}/>
						</LinkedinShareButton>
						<TwitterShareButton beforeOnClick={() => this.pushGaEvent('socialClose', 'twitter')} 
								className="shareIcon" url={url} title={description}>
							<TwitterIcon round size={40}/>
						</TwitterShareButton>
						<EmailShareButton beforeOnClick={() => this.pushGaEvent('socialClose', 'email')} 
								className="shareIcon" url={url}>
							<EmailIcon round size={40}/>
						</EmailShareButton>
					</div>
				</DialogActions>
			</Dialog>
		)
	}
}