import { updateHead  } from './head'
import { appendJobsSetFilter, fetchData, removeObjectFromHistogram, setHistogramWithData, fetchSavedItems } from './histogramJobs'
import { setSearchJobsSingle } from './searchJobs'
import { gaPageView, getHost, getMyJobs, capitalize, getJobRoute, getRusumeRoute } from '../../utils/utils'
import HistApi from "../../utils/HistApi";
import { updateUserInfo, toggleLoginDialog } from './authActions';
import { showDialog, showSnack, showPayment, showMatchDialog, showAlertPopup } from './MainDialog';
import { push } from 'connected-react-router'
import { UNLIMITED_SWIPE, GET_CONTACTS, PROMOTE } from '../../components/dialogs/PaymentDialog';
import ReactGA from 'react-ga4'
import { getLocation } from '../../utils/AuthHelper';
import { setCardContentTypeAndFetch } from './cardContent';

export const CLEAR_OBJECT = 'CLEAR_OBJECT'
export const REQUEST_JOB = 'REQUEST_JOB'
export const RECEIVE_JOB = 'RECEIVE_JOB'
export const SELECT_JOB = 'SELECT_JOB'
export const JOB_NOT_FOUND = 'JOB_NOT_FOUND'
export const NEW_OBJECT = 'NEW_OBJECT'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const CLOSE_SELECTED_DIALOG = 'CLOSE_SELECTED_DIALOG'
export const PROCESS_START = 'PROCESS_START'
export const PROCESS_END = 'CONTACT_END'

function processStart() {
	return {
		type: PROCESS_START
	};
}

function processEnd() {
	return {
		type: PROCESS_END
	};
}

function requestJob() {
    return {
      	type: REQUEST_JOB
    }
}

function jobNotFound() {
    return {
      	type: JOB_NOT_FOUND
    }
}
  
function receiveJob(json) {
	return {
		type: RECEIVE_JOB,
		job: json,
		receivedAt: Date.now()
	}
}

export function setEditMode(editMode) {
	return {
		type: SET_EDIT_MODE,
		editMode: editMode,
	}
}

export function addNewObject(){
	return {
		type: NEW_OBJECT
	}
}

export function closeSelectedDialog(){
	return {
		type: CLOSE_SELECTED_DIALOG
	}
}

export const selectJob = (job, openDialog = false, editMode = false, isNew = false) => (dispatch, getState) => {
	dispatch ({
		type: SELECT_JOB,
		job: job,
		openDialog,
		editMode,
		isNew
	})

	if (getState().histogramData.histogramType === 'companies'){
		dispatch(setCardContentTypeAndFetch(getState().cardContent.cardContentType || 'Interviews', job.name))
	}
}

export const cancelNewObject = () => (dispatch, getState) => {
	const selectedObject = getState().histogramJobs.jobs[0] ? getState().histogramJobs.jobs[0] : {}
	dispatch(selectJob(selectedObject))
}

export const postNewJob = () => (dispatch, getState) => {
	const { isAuthed, userInfo } = getState().authUser;

	if(isAuthed) {
		if(userInfo.lookingforwork){
			let fieldsToUpdate = {};
			fieldsToUpdate.lookingforwork = false;

			HistApi.saveProfile(fieldsToUpdate, (result)=>{
				dispatch(updateUserInfo({userInfo: result.userData, applies: result.applies}));
				dispatch(showSnack('Switched to recruiter'))
				
				dispatch(push('/myJobs'));
			})
		} else { 
			dispatch(push('/myJobs'));
		}
	} else {
		dispatch(toggleLoginDialog(true))
	}
}

const doFetchObject = (state, id, queryParams, cardContent) => dispatch =>{
	const histogramType = state.histogramData.histogramType

	dispatch(requestJob())

	if(window.SERVER_DATA && window.SERVER_DATA.selectedObject){
		let selectedObject = window.SERVER_DATA.selectedObject
		delete window.SERVER_DATA
		dispatch(afterFetchObject(histogramType, queryParams, selectedObject));
	} else {
		HistApi.getHistogramObject({
			histogramType: histogramType,
			cardContent,
			id
		}, (result)=>{
			if (result.notFound || !result.result){
				dispatch(jobNotFound())
				dispatch(setSearchJobsSingle(true));
			} else {
				let selectedObject = result.result
				dispatch(afterFetchObject(histogramType, queryParams, selectedObject, {cardContentType:cardContent, data:result.cardContent}));
			}
		}, (error)=>{
			console.log(error) 
		});
	}
}

const afterFetchObject = (histogramType, queryParams, selectedObject, cardContent) => dispatch =>{
	let newUrl = ''
	let title = ''
	let description = ''

	switch (histogramType){
		case 'jobs': {
			newUrl = getJobRoute(selectedObject._id, selectedObject.title)
			title = capitalize(selectedObject.title)
			description = selectedObject.description
			break;
		} case 'resumes':{
			newUrl = getRusumeRoute(selectedObject._id, selectedObject.first_name, selectedObject.title)
			title = capitalize(selectedObject.first_name) + ((selectedObject.last_name) ? ' ' + capitalize(selectedObject.last_name):'') +
				((selectedObject.title) ? ' - ' + capitalize(selectedObject.title):'')
			description = selectedObject.personal_info || `View ${selectedObject.first_name}'s profile on JobSwipe, Connect with just 1 click. Hiring? post a job for free.`
			break;
		} case 'companies':{
			title = capitalize(selectedObject.name)
			break;
		} default: {
			newUrl = '/' + histogramType + '/' + selectedObject._id
		}
	}
		
	dispatch(updateHead({title, description, canonicalUrl:getHost() + newUrl}))

	if (['jobs', 'resumes'].includes(histogramType) &&  newUrl !== window.location.pathname){
		dispatch(push(newUrl));
	}
	gaPageView(newUrl, title + ' | JobSwipe', queryParams);
	dispatch(appendJobsSetFilter([selectedObject]))
	dispatch(receiveJob(selectedObject))
	dispatch(fetchData({tags:selectedObject.tags, skipId:selectedObject._id}, true))
	if(cardContent){
		dispatch(setCardContentTypeAndFetch(cardContent.cardContentType, selectedObject.name, cardContent.data))
	}
}

const isCanFetch = state => {
    const selectedJob = state.selectedJob
    return !selectedJob.isFetching;
}

export const fetchSingle = (params, queryParams) => {
	if (!params.id){
		return
	}

	return (dispatch, getState) => {
        if (isCanFetch(getState())) {
            dispatch(doFetchObject(getState(), params.id, queryParams, params.cardContent))
        }
    }
}

export const clearHistogramSelected = () => {
    return {
        type: CLEAR_OBJECT
    }
}

export const apply = (type, objectId, jobId, isOnProcess, histogramType, shouldRemove = true) => (dispatch, getState) => {
	if(!isOnProcess){
		dispatch(processStart());
		HistApi.applyHistObject({
			type, objectId, jobId, histogramType
		}, (result)=>{
			if (result.limitReached){
				dispatch(showPayment(UNLIMITED_SWIPE, (type === 'jobs')?'jobseeker':'recruiter', 'likeLimitReached'))
				dispatch(processEnd());
			} else {
				if(histogramType === 'saveditems'){
					dispatch(fetchSavedItems(objectId))
				}
				dispatch(updateUserInfo({applies: result.applies}))
				dispatch(closeSelectedDialog())
				dispatch(processEnd());
				if(result.isMatch){
					const object = getState().selectedJob.job
					let userInfo = getState().authUser.userInfo
					
                    if (type === 'jobs'){
                        userInfo.hasMatchesCan = true
                    }else if (type === 'profiles'){
                        userInfo.hasMatchesRec = true
                    }

                    dispatch(updateUserInfo({userInfo}));
					dispatch(showMatchDialog(result.mailRecieverData, result.showNotification, object))
				} else if (result.showNotification){
					if (type === 'jobs'){
						dispatch(showDialog('You just sent application to this job', 'We notified the recruiter you are interested. If he thinks you fit this job you will be able it see it in Activity/Matches tab'))
					} else if (type === 'profiles'){
						dispatch(showDialog('You just sent a job suggestion', 'If they are interested in this job, you will be able it see it in Activity/Matches tab'))
					}
				}
			}
			if(shouldRemove) {
				dispatch(removeObjectFromHistogram(objectId))
			}
		}, (error)=>{
			console.log(error) 
		});
	}
}

export const fetchContact = (profileId, isOnProcess, jobId, contactType) => (dispatch, getState) => {
	if(!isOnProcess){
		dispatch(processStart())

		HistApi.fetchContact({profileId, jobId}, (result)=>{
			if (result.notPro){
				const state = getState()
				const { authUser/*, histogramData, selectedJob*/ } = state
				//const { histogramType, histogramSubType } = histogramData
				//const { job } = selectedJob
				const lookingforwork = authUser.userInfo.lookingforwork
				const role = (lookingforwork)?'jobseeker':'recruiter'
				//const contactTypeLabel = contactType === 'email' ? 'email' : 'phone number'

				if(lookingforwork){
					dispatch(showPayment(GET_CONTACTS, role, 'getContact', contactType))
				}
				/*if(!lookingforwork){
					if(histogramType === 'resumes'){
						const text = 'Press connect to uncover ' + contactTypeLabel + 
							' and wait for a match or Get JobSwipe Pro and uncover ' + contactTypeLabel + ' now'

						ReactGA.event({category:'Payment', action:`GetContacts_${role}_${contactType}_showDialog_${histogramType}`});
						dispatch(showDialog('Get Contact Details', text, null, true, contactType))
					} else if (histogramSubType === 'myCandidates'){
						if(getDiffInSeconds(job.date) > 86400 * 4){
							dispatch(showPayment(GET_CONTACTS,role, 'getContact', contactType))
						} else {
							const text = 'Wait for a match or Get JobSwipe Pro and uncover ' + contactTypeLabel + ' now'

							ReactGA.event({category:'Payment', action:`GetContacts_${role}_${contactType}_showDialog_${histogramSubType}`});
							dispatch(showDialog('Get Contact Details', text, null, true, contactType))
						}
						
					} else {
						dispatch(showPayment(GET_CONTACTS,role, 'getContact', contactType))
						//this.getContact('phone')
					}
				} else {
					dispatch(showPayment(GET_CONTACTS, role, 'getContact', contactType))
				}*/

				dispatch(processEnd())
			} else if (result.limitReached){
				ReactGA.event({category:'Payment', action:`GetContacts_${(jobId)?'jobseeker':'recruiter'}_${contactType}_limitReached`});
				dispatch(showDialog('Get Contact Details', 'You reached your limit'))
				dispatch(processEnd())
			} else {
				ReactGA.event({category:'Payment', action:`GetContacts_${(jobId)?'jobseeker':'recruiter'}_${contactType}`});
				dispatch(updateUserInfo({contacts: result.contacts}))
				dispatch(processEnd())
			}
		})
	}
}

export const promoteObject = (type, objectId, isOnProcess, role, from) => dispatch => {
	if(!isOnProcess){
		dispatch(processStart())

		HistApi.promoteObject({type, objectId}, (results)=>{
			if (results.notPro){
				dispatch(showPayment(PROMOTE, role, from))
				dispatch(processEnd())
			} else if (results.limitReached){
				ReactGA.event({category:'Payment', action:`Promote_${role}_limitReached`});
				dispatch(showDialog('Promote', 'You reached your limit'))
				dispatch(processEnd())
			} else {
				ReactGA.event({category:'Payment', action:`Promote_${role}`});
				if (type === 'jobs'){
					dispatch(updateUserInfo({jobs: getMyJobs(results.results), promotes: results.promotes}))
					dispatch(setHistogramWithData(results, objectId, false));    
				} else if (type === 'profiles') {
					dispatch(updateUserInfo({userInfo: results.userData, promotes: results.promotes}))
				}
				dispatch(showDialog('Promote', 'Promoted to 24 hours'))
				dispatch(processEnd())
			}
		})
	}
}

export const addNewAlertToUser = (queryParams, locationObj, histogramType, url, anchorEl)  => async dispatch => {
	if (locationObj && (!locationObj.lat || !locationObj.lng)){
		const {err, userLocation} = await getLocation()

		if(err){
			return dispatch(showDialog('Location required', 'Please enter a location'))
		}

		locationObj = userLocation
	}

	HistApi.addAlert({queryParams, locationObj, histogramType, url}, (results)=>{
		dispatch(updateUserInfo({alerts: results}))
		dispatch(showAlertPopup(anchorEl))
		ReactGA.event({category: 'Alerts', action: `Add_${histogramType}_Alert`})
	})
}

export const RemoveAlertToUser = (alertId, histogramType)  => dispatch => {
	HistApi.removeAlert({alertId}, (results)=>{ 
		dispatch(updateUserInfo({alerts: results}))
		dispatch(showSnack('Alert deleted'))
		ReactGA.event({category: 'Alerts', action: `Removed_${histogramType}_Alert`})
	})
}

export const SetFrequency = (alertId, histogramType, frequency)  => dispatch => {
	HistApi.setFrequency({alertId, histogramType, frequency}, (results)=>{ 
		dispatch(updateUserInfo({alerts: results}))
		ReactGA.event({category: 'Alerts', action: `Set_Frequency_${histogramType}_Alert_${frequency}`})
	})
}

export const addSavedItem = (itemId, itemType, item) => dispatch => {
	var saveitemspage = false
	if(itemType === 'saveditems') {
		saveitemspage = true
		itemType = item.itemType
	}
	HistApi.addSavedItem({itemId, itemType , item}, (results)=>{ 
		dispatch(updateUserInfo({savedItems: results.savedItems}))
		dispatch(updateUserInfo({userInfo: results}))
		if(saveitemspage) {
			dispatch(fetchSavedItems())
		}
		ReactGA.event({category: 'SavedItems', action: `Added_SavedItem`})
	})
}

export const removeSavedItem = (itemId, histogramType) => dispatch => {
	HistApi.removeSavedItem({itemId}, (results)=>{ 
		dispatch(updateUserInfo({savedItems: results.savedItems}))
		dispatch(updateUserInfo({userInfo: results}))
		if(histogramType === 'saveditems') {
			dispatch(fetchSavedItems())
		}
		ReactGA.event({category: 'SavedItems', action: `Removed_SavedItem`})
	})
}
