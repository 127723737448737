import React, { Component } from 'react'

import JobCardContent from './JobCardContent'
import ResumeCardContent from './ResumeCardContent'
import EmptyCard from './EmptyCard'

import '../styles/JobCard.css'
import ReportCardContent from './ReportCardContent';
import CompanyCardContent from './CompanyCardContent'
import { PersonalJourneyCardContent } from './JH/PersonalJourneyCardContent'

export default class JobCard extends Component {
    render() {
        const { job:selectedObject, selectedjobbgcolor, applyOnTop, isfetchingjob, view, editMode, setEditMode, jobnotfound, lookingforwork, 
            singleHist, isAuthed, showLogin, searchWords, userTags, savedItems, showMessageDialog, updateHist, autoRefreshList } = this.props;
        const scrollToTop = this.props.scrollToTop || (()=>{})
        let isBookedmark = false
        let { histogramType } = this.props

        if(isAuthed){
            isBookedmark = savedItems.some(r => r._id === selectedObject._id)   
        }

        let params = {}
        let component = ''

        switch (histogramType){
            case 'jobs':{
                component = 'JobCardContent'
                params = {
                    scrollToTop, job:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin,
                    searchWords, userTags, isBookedmark, addSavedItem:this.props.addSavedItem, removeSavedItem:this.props.removeSavedItem, lookingforwork
                }
                break
            } case 'myJobs':{
                component = 'JobCardContent'
                params = {
                    scrollToTop, editMode, setEditMode, job:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, 
                    isBookedmark, addSavedItem: this.props.addSavedItem, removeSavedItem: this.props.removeSavedItem
                }
                break
            } case 'resumes':{
                component = 'ResumeCardContent'
                params = {
                    scrollToTop, resume:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, searchWords, 
                    userTags, isBookedmark, addSavedItem: this.props.addSavedItem, removeSavedItem: this.props.removeSavedItem, lookingforwork
                }
                break
            } case 'profile':{
                component = 'ResumeCardContent'
                params = {
                    scrollToTop, editMode, setEditMode, resume:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, 
                    isBookedmark, addSavedItem: this.props.addSavedItem, removeSavedItem: this.props.removeSavedItem
                }
                break
            } case 'activity':{
                params = {
                    scrollToTop, job:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, userTags, 
                    isBookedmark, addSavedItem: this.props.addSavedItem, removeSavedItem: this.props.removeSavedItem, lookingforwork
                }
                if (lookingforwork){
                    component = 'JobCardContent'
                    params.job = selectedObject
                } else {
                    component = 'ResumeCardContent'
                    params.resume = selectedObject
                }
                break
            } case 'reports':{
                component = 'ReportCardContent'
                params = {
                    scrollToTop, object:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin
                }
                break
            } case 'saveditems':{
                params = {
                    scrollToTop, job:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, searchWords, 
                    userTags, isBookedmark, addSavedItem:this.props.addSavedItem, removeSavedItem:this.props.removeSavedItem, lookingforwork
                }
                if (lookingforwork){
                    component = 'JobCardContent'
                    params.job = selectedObject
                } else {
                    component = 'ResumeCardContent'
                    params.resume = selectedObject
                }
                break
            } case 'companies':{
                component = 'CompanyCardContent'
                params = {
                    scrollToTop, company:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin
                }
                break
            } case 'jobhuntUsers':{
                component = 'PersonalJourneyCardContent'
                params = {
                    scrollToTop, jobHuntUser:selectedObject, selectedjobbgcolor, applyOnTop, view, histogramType, isAuthed, showLogin, showMessageDialog, updateHist, autoRefreshList
                }
                break
            } default: {

            }
        }

        return (
            <React.Fragment>
                {(selectedObject._id || editMode) ? 
                <React.Fragment>
                    {(component === 'JobCardContent')&&<JobCardContent {...params}/>}
                    {(component === 'ResumeCardContent')&&<ResumeCardContent {...params}/>}
                    {(component === 'ReportCardContent')&&<ReportCardContent {...params}/>}
                    {(component === 'CompanyCardContent')&&<CompanyCardContent {...params}/>}
                    {(component === 'PersonalJourneyCardContent')&&<PersonalJourneyCardContent {...params}/>}
                </React.Fragment> : 
				<EmptyCard isfetchingjob={isfetchingjob} jobnotfound={jobnotfound} histogramType={histogramType} singleHist={singleHist}/>}
			</React.Fragment>
        )
    }
}