import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import HistApi from "../../utils/HistApi";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Rotate from '@material-ui/icons/RotateRight';
import ImageIcon from '@material-ui/icons/ImageOutlined'
import AvatarEditor from 'react-avatar-editor'

export default class AvatarEditDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uploading: false,
            fileStream: null,
            rotate: 0,
            scale: 1
        }

        this.onClose = this.onClose.bind(this)
        this.onClickSave = this.onClickSave.bind(this)
    }

    onDelete = () => {
        this.setState({uploading: true})
        
        HistApi.removeAvatarR((results)=>{
            this.onClose();
            this.props.handleUpdateUser(results.userData);
        }, (error)=>{
            console.log(error)
        });
    }

    onClose = () => {
        this.setState({
            uploading: false,
            fileStream: null,
            rotate: 0,
            scale: 1
        })
        this.props.handleClose();
    }

    onClickSave = () => {
        if (this.editor) {
            const canvasScaled = this.editor.getImageScaledToCanvas()

            this.setState({uploading: true})

            const data = new FormData()
            data.append('image', canvasScaled.toDataURL())

            HistApi.uploadAvatarR(data, (results)=>{
                this.onClose();
                this.props.handleUpdateUser(results.userData);
            }, (error)=>{
                console.log(error)
            });
        }
    }
    
    setEditorRef = (editor) => this.editor = editor

    handleSelectedFile = event => {
        const fileStream = event.target.files[0];

        if (fileStream && fileStream.size && fileStream.size > 0){
            if(fileStream.type.match(/image\//ig)){
                this.setState({
                    fileStream,
                    rotate: 0,
                    scale: 1,
                    invalidFile:false
                })
            } else {
                this.setState({
                    fileStream:null,
                    invalidFile:true
                })
            }
        } else {
            this.setState({
                fileStream:null,
                invalidFile:true
            })
        }
    }

    onInputClick = (event) => {
        event.target.value = ''
    }

    handleChangeScale = (event, value) => {
        this.setState({ scale:value });
    };

    handleRotate = () => {
        const rotate = this.state.rotate
        this.setState({rotate:rotate+1})
    }

    render(){
        const DEF_AVATAR = 'https://jobswipe.co/assets/images/def_avatar.jpg'
        const {uploading, fileStream, rotate, scale, invalidFile} = this.state;
        const currAvatar = this.props.currAvatar || DEF_AVATAR
        const hasAvatar = (currAvatar && currAvatar !== DEF_AVATAR)

        return (
            <Dialog
                open={this.props.open}
                onClose={this.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"                
            >
                <DialogTitle id="alert-dialog-title">{(uploading)?'Processing...':'Edit profile picture'}</DialogTitle>
                {(uploading)?<div style={{textAlign:'center', padding:32}}><CircularProgress/></div>:
                <React.Fragment>
                    <DialogContent style={{overflow:"hidden"}}>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="caption">2 MB Max. Minimum 120px x 120px</Typography><br/>
                            <Typography variant="caption">This will replace the current photo if one is already uploaded.</Typography>       
                        </DialogContentText>
                        {(fileStream)?<div style={{textAlign:"center", marginTop:16}}>
                        <Typography variant="caption">Drag Image to Reposition</Typography><br/>
                            <AvatarEditor
                                ref={this.setEditorRef}
                                image={fileStream}
                                rotate={rotate * 90}
                                scale={scale}
                                width={120}
                                height={120}
                                border={0}
                                borderRadius={100}
                            />
                            <div style={{display:"flex", width:180, margin:"auto"}}>
                                <IconButton aria-label="Rotate" onClick={this.handleRotate}>
                                    <Rotate/>
                                </IconButton>
                                <ImageIcon style={{fontSize:16,color:"#0000008a",margin:"14px 0"}}/>
                                <Slider
                                    value={scale}
                                    min={1}
                                    max={4}
                                    step={0.1}
                                    aria-labelledby="scale"
                                    onChange={this.handleChangeScale}
                                    style={{margin:"10px 8px"}}
                                />
                                <ImageIcon style={{fontSize:24,color:"#0000008a",margin:"10px 0",marginRight:12}}/>
                            </div>
                        </div>:
                        <div style={{textAlign:"center", margin:"16px 0"}}>
                            <img alt="currAvatar" src={currAvatar} style={{borderRadius:"50%"}}/>
                        </div>}
                        <div style={{textAlign:"center"}}>
                            {(invalidFile)&&<Typography color="error">Invalid File</Typography>}
                            <input
                                accept="image/*"
                                id="contained-button-image"
                                type="file"
                                style={{display:"none"}}
                                onChange={this.handleSelectedFile}
                                onClick={this.onInputClick}
                            />
                            <label htmlFor="contained-button-image">
                                <Button variant="outlined" color="primary" component="span" aria-label="Choose New Photo">
                                    Choose New Photo 
                                </Button>
                            </label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {(fileStream)&&<Button onClick={this.onClickSave} variant="contained" color="primary" aria-label="Save">Save</Button>}
                        {(hasAvatar && !fileStream)&&<Button variant="contained" onClick={this.onDelete} color="primary" aria-label="Delete Photo">Delete photo</Button>}
                        <Button onClick={this.onClose} color="primary" aria-label="Cancel">Cancel</Button>
                    </DialogActions>
                </React.Fragment>}
            </Dialog>
        )
    }
}