import {SET_SEARCH_JOBS} from '../actions/searchJobs'

export function searchJobs(
    state = {queryString:'', locationObj:{}},
    action
) {
    switch (action.type) {
        case SET_SEARCH_JOBS:
            return action.searchJobs;
        default:
            return state
    }
}