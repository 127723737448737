import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Fields } from '../Fields';
import PaymentApi from "../../utils/PaymentApi"
import { Skeleton } from '@material-ui/lab';

export default function Payment(props) {
    const [linkData, setLinkData] = useState({});
    const [transType, setTransType] = useState("Credit");
    const [transTypes, setTransTypes] = useState([{value: "Credit", label:"עסקה רגילה"}]);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [extraFields, setExtraFields] = useState();

    useEffect(() => {
        setIsLoading(true)

        PaymentApi.getJHPaymentLinkData({transType},(response)=>{
            setFields(response.fields)
            setExtraFields(response.extraFields)
            setLinkData(response.linkData)
            setTransTypes(response.transTypes)

            setIsLoading(false)
        }, (error) => {
            console.log(error)
            setIsLoading(false)
            //setIsError(true)
        })
    }, [transType]);

    const handleChange = e => {
        setLinkData({...linkData,[e.target.name]: e.target.value})
    };

    const handleChangeTrsnsType = e => {
        setTransType(e.target.value)
    };

    const getValue = (name, defaultValue) => {
        if (linkData[name] !== undefined){
            return linkData[name]
        } else {
            return defaultValue
        }
    }

    const handleGetUrl = e => {
        e.preventDefault();

        setIsLoading(true)

        PaymentApi.getJHPaymentUrl({transType, linkData},(response)=>{
            setIsLoading(false)
            window.open(response, '_blank');
        }, (error) => {
            console.log(error)
            props.showMessageDialog('Error', error.message)
            setIsLoading(false)
            //setIsError(true)
        })
    };

    return (
        <>
            <Paper className='profileSection' elevation={1}>
                <FormControl fullWidth>
                    <InputLabel shrink  htmlFor="transType">Trans Type</InputLabel>
                    <Select
                        value={transType}
                        onChange={handleChangeTrsnsType}  
                        name="transType"
                        disabled
                    >
                        {transTypes.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>
            {(isLoading)?<div>
                    <Skeleton variant="rect" width={"100%"} height={20} /> <br/>
                    <Skeleton variant="rect" width={"100%"} height={20} />
                </div>:
            <form onSubmit={handleGetUrl}>
                <Paper className='profileSection' elevation={1}>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChange}/>
                </Paper>
                <Paper className='profileSection' elevation={1}>
                    <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            aria-label="Get Link"
                        >
                            Get Link
                    </Button>
                </Paper>
                <Paper className='profileSection' elevation={1} style={{display:'none'}}>
                    <Fields fields={extraFields} getValue={getValue} handleChange={handleChange}/>
                </Paper>
            </form>}
        </>
    )
}