import AuthApi from "../../utils/AuthApi";
import {
    saveToken,
    removeToken,
    getAndroidToken,
    getUserInfo,
    formatErrors,
    getComputerToken
} from "../../utils/AuthHelper";
import { fetchData } from "./histogramJobs";
import { setEditMode, apply } from "./selectedJob";

import ReactGA from 'react-ga4'
import { CANDIDATE_ACTIVITY_SUB_TYPES, RECRUITER_ACTIVITY_SUB_TYPES } from "../../utils/utils";

export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const TOGGLE_LOGIN_DIALOG = "TOGGLE_LOGIN_DIALOG";
export const LOGIN_CHECKED = "LOGIN_CHECKED";
export const SET_USER_REPORTS = "SET_USER_REPORTS";

const authUser = (fields) => {
    if (!fields){
        return null
    }
    return {
        type: AUTH_USER,
        userInfo: fields.userInfo,
        applies: fields.applies,
        jobs: fields.jobs,
        reports: fields.reports,
        contacts: fields.contacts,
        promotes: fields.promotes,
        alerts: fields.alerts,
        savedItems: fields.savedItems
    };
};

const unAuthUser = () => {
    return { type: UNAUTH_USER };
};

const registerFail = errors => {
    return {
        type: REGISTER_FAIL,
        errors
    };
};

const loginFail = errors => {
    return {
        type: LOGIN_FAIL,
        errors
    };
};

const loginChecked = () => {
    return { 
        type: LOGIN_CHECKED 
    };
}

const clearLocalStorage = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("jobIdToApply");
}

export function toggleLoginDialog(state, section = 'signIn'){
    if (!state){
        clearLocalStorage()
    }

	return {
        type: TOGGLE_LOGIN_DIALOG,
        state,
        section
	}
}

const getExtraUserInfo = () => dispatch => {
    AuthApi.getUserData({androidtoken: getAndroidToken(), computertoken: getComputerToken()}, (data)=>{
        dispatch(updateUserInfo({userInfo:data.userData, 
            applies:data.applies, 
            jobs:data.jobs, 
            reports:data.reports, 
            contacts:data.contacts, 
            promotes: data.promotes,
            alerts: data.alerts,
            savedItems: data.savedItems}));
    });
}

const checkIsJobToApply = () => dispatch => {
    const jobToApply = localStorage.getItem("jobIdToApply");
    if (jobToApply){
        dispatch(apply('jobs', jobToApply, undefined, false, undefined))
    }

    clearLocalStorage()
}

export const updateUserInfo = (fields, updateHistogram = false) => {
    return dispatch => {
        if(!fields.savedItems && fields.userInfo) {
            fields.savedItems = fields.userInfo.savedItems
        }
        dispatch(authUser(fields))
        if (updateHistogram) {
            dispatch(fetchData())
            dispatch(setEditMode(true))
        }
        //dispatch(setHistogramIfNeeded());
    }
};

export const registerUser = (userInfo, redirect) => async dispatch => {
    try {
        let { data } = await AuthApi.register(userInfo);
        saveToken(data.token);
        dispatch(getExtraUserInfo());
        redirect();
    } catch (e) {
        if (!e.response) {
            console.log(e);
            return;
        }
        let { data } = e.response;
        const formattedErrors = formatErrors(data);
        dispatch(registerFail(formattedErrors));
    }
};

export const loginUser = (userInfo, redirect) => async dispatch => {
    try {
        let { data } = await AuthApi.login(userInfo);
        ReactGA.event({category: 'Login',action: 'login-pass'});
        saveToken(data.token);
        dispatch(getExtraUserInfo());
        dispatch(checkIsJobToApply());
        redirect();
    } catch (e) {
        if (!e.response) {
            console.log(e);
            return;
        }
        let { data } = e.response;
        dispatch(loginFail(data));
    }
};

export const socialAuthUser = (token, redirect) => async dispatch => {
    saveToken(token);
    dispatch(getExtraUserInfo());
    dispatch(checkIsJobToApply());
    redirect();
};

export const reAuthUser = () => async dispatch => {
    const userInfo = getUserInfo();
    if (userInfo && userInfo.exp >= Date.now() / 1000) {
        dispatch(getExtraUserInfo());
    } else {
        dispatch(loginChecked());
        if(getAndroidToken()) {
            AuthApi.getUnregistedUserToken({token: getAndroidToken(), device: 'android'})
        } else if (getComputerToken()) {
            AuthApi.getUnregistedUserToken({token: getComputerToken(), device: 'computer'})
        }
    }
};

export const logoutUser = redirect => async dispatch => {
    removeToken();
    clearLocalStorage()
    dispatch(unAuthUser());
    redirect();
};

export const toggleActivityBadge = (histogramSubType, value) => (dispatch, getState) => {
    if(CANDIDATE_ACTIVITY_SUB_TYPES.includes(histogramSubType) || RECRUITER_ACTIVITY_SUB_TYPES.includes(histogramSubType)){
        let userInfo = getState().authUser.userInfo

        if(userInfo.lookingforwork !== undefined){
            const castToField = {
                jobsSuggestion: 'hasJobsSuggestion',
                applicants: 'hasApplicants',
                matches: (userInfo.lookingforwork) ? 'hasMatchesCan' : 'hasMatchesRec'
            }

            if(castToField[histogramSubType]){
                userInfo[castToField[histogramSubType]] = value
                dispatch(updateUserInfo({userInfo}))
            }
        }
    }
}