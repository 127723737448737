import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class UploadDialog extends React.Component {
	render(){
		return (
			<Dialog
                open={this.props.open}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                <div style={{textAlign:'center', padding:32}}><CircularProgress/></div>
            </Dialog>
		)
	}
}