import React, { useState, useEffect, Fragment } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { JSTextEdidor } from "./JSTextEdidor"
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, IconButton, Paper, TextField, Typography, Snackbar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PersonalJourneyClasses } from '../../styles/PersonalJourneyClasses';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { PESONAL_JOURNEY_STEPS, PESONAL_JOURNEY_STEPS_NAMES, removeDuplicate } from '../../utils/utils';
import ObjectsList from '../ObjectsList';
import CheckIcon from '@material-ui/icons/Check';
import Explenations from '../Explenations';
import { Alert } from '@material-ui/lab';

function Explanation(props) {
    const [newExplanation, setNewExplanation] = useState();
    const [onEdit, setOnEdit] = useState(false);

    const handleChangeExplanation = newContent => {
        setNewExplanation(newContent)
    }

    const handleEditExplanation = () => {
        setNewExplanation(props.value)
        setOnEdit(true)
    }

    const handleSaveExplanation = () => {
        props.onSave(props.name, newExplanation, (succees)=>{
            if (succees){
                setOnEdit(false)   
                setNewExplanation()
            }
        })
    }

    const handleCancelExplanation = () => {
        setOnEdit(false)
        setNewExplanation()
    }

    return (
        <Fragment>
            {(onEdit)?
                <Fragment>
                    <JSTextEdidor value={newExplanation || props.value} onChange={handleChangeExplanation}/>
                    <Button size='small' color='primary' variant='contained' onClick={handleSaveExplanation} disabled={props.isLoading}>Save</Button>
                    <Button size='small' color='primary' variant='outlined' onClick={handleCancelExplanation} disabled={props.isLoading}>Cancel</Button>
                </Fragment>
            :
                <Fragment>
                    <div dangerouslySetInnerHTML={{__html: props.value}} />
                    <Button size='small' color='primary' variant='contained' onClick={handleEditExplanation} disabled={props.isLoading}>Edit</Button>
                </Fragment>
            }
        </Fragment>
    )
}

export function PersonalJourneyExplainations(props) {
    const [explenations, setExplenations] = useState({});
    const [emailsToSendTest, setEmailsToSendTest] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [sceduledMeetingStatuses, setSceduledMeetingStatuses] = useState([]);
    const [plans, setPlans] = useState([]);
    const [questionnaireStatuses, setQuestionnaireStatuses] = useState([]);
    const [summaryTypes, setSummaryTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //const [isError, setIsError] = useState(false);
    const [needFetchData, setNeedFetchData] = useState(true);
    const [isNewEmailToSendTestItem, setIsNewEmailToSendTestItem] = useState(false);
    const [newEmailToSendTestItem, setNewEmailToSendTestItem] = useState('');
    const [newsLetterEmails, setNewsLetterEmails] = useState([]);
    const [showCopied, setShowCopied] = useState(false);
    const [explanationDialog, setExplanationDialog] = useState({open:false});

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (needFetchData){
            getDataFromDB()
        }   
    });

    const getDataFromDB = () => {
        setNeedFetchData(false)

        setIsLoading(true)

        AdminJHApi.getPersonalJourneysExplenations({},(response)=>{
            setExplenations(response.explenations)
            setEmailsToSendTest(response.emailsToSendTest)
            setStatuses(response.statuses)
            setSceduledMeetingStatuses(response.sceduledMeetingStatuses)
            setPlans(response.plans)
            setQuestionnaireStatuses(response.questionnaireStatuses)
            setSummaryTypes(response.summaryTypes || [])
            setNewsLetterEmails(response.newsLetterEmails || [])
            setIsLoading(false)
        }, (error) => {
            console.log(error)
            //setIsError(true)
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleChangeExplanation = (name, newValue, cb) => {
        setIsLoading(true)

        AdminJHApi.updatePersonalJourneysExplenations({name, value:newValue},(response)=>{
            setExplenations(response)
            setIsLoading(false)
            cb(true)
        }, (error) => {
            console.log(error)
            //setIsError(true)
            setIsLoading(false)
            cb(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleDeleteEmailToSendTest = excludeListItem => {
        const lowerCaseExcludeListItem = (excludeListItem) ? excludeListItem.toLowerCase() : ''
        const newEmailsToSendTestList = emailsToSendTest.filter(item => item.toLowerCase() !== lowerCaseExcludeListItem)

        setIsLoading(true)

        AdminJHApi.updatePersonalJourneysEmailsToSendTest({emailsToSendTest:newEmailsToSendTestList},(response)=>{
            setEmailsToSendTest(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleEmailToSendTestItem = () => {
        setIsNewEmailToSendTestItem(true)
    }

    const handleSaveExcludeListItem = () => {
        const newEmailsToSendTestList = removeDuplicate([...emailsToSendTest, newEmailToSendTestItem.toLowerCase()])

        setIsLoading(true)

        AdminJHApi.updatePersonalJourneysEmailsToSendTest({emailsToSendTest:newEmailsToSendTestList},(response)=>{
            setIsNewEmailToSendTestItem(false)
            setNewEmailToSendTestItem('')
            setEmailsToSendTest(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleCancelAddEmailToSendTestItem = () => {
        setIsNewEmailToSendTestItem(false)
        setNewEmailToSendTestItem('')
    }

    const changeStatuses = (newStatuses) => {
        setIsLoading(true)

        AdminJHApi.updatePersonalJourneyStatuses({statuses:newStatuses},(response)=>{
            setStatuses(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changePlans = (newPlans) => {
        setIsLoading(true)

        AdminJHApi.updatePersonalJourneyPlans({plans:newPlans},(response)=>{
            setPlans(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changeQuestionnaireStatus = (newStatuses) => {
        setIsLoading(true)

        AdminJHApi.updateQuestionnaireStatuses({statuses:newStatuses},(response)=>{
            setQuestionnaireStatuses(response)            
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changeSummaryTypes = (newList) => {
        setIsLoading(true)

        AdminJHApi.updateSummaryTypes({summaryTypes:newList},(response)=>{
            setSummaryTypes(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const changeNewsLetterEmails = (newList) => {
        setIsLoading(true)

        AdminJHApi.updateNewsLetterEmails({newsLetterEmails:newList},(response)=>{
            setNewsLetterEmails(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const toggleMeetingStatuses = (status) => () => {
        let newStatuses = []
        if (sceduledMeetingStatuses.includes(status)){
            newStatuses = sceduledMeetingStatuses.filter((sceduledMeetingStatuse)=>sceduledMeetingStatuse!==status)
        } else {
            newStatuses = [...sceduledMeetingStatuses, status]
        }

        AdminJHApi.updateSceduledMeetingStatuses({statuses:newStatuses},(response)=>{
            setSceduledMeetingStatuses(response)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const getNewsletterWhatsApp = () => {
        setIsLoading(true)

        AdminJHApi.getNewsletterWhatsApp({},(response)=>{
            navigator.clipboard.writeText(response)

            console.log(response)
            setShowCopied(true)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
            props.showMessageDialog('Error', JSON.stringify(error))
        })
    }

    const handleChange = e => {
        setNewEmailToSendTestItem(e.target.value)
    };

    const explenationsFields = [
        {name: 'general', label:'General'},
    ]

    const classes = PersonalJourneyClasses(); 

    return (
        <>
            <Snackbar open={showCopied} autoHideDuration={2000} onClose={()=>{setShowCopied(false)}}>
                <Alert onClose={()=>{setShowCopied(false)}} severity="success">
                    Copied
                </Alert>
            </Snackbar>

            <div style={{margin:8}}>
                <Button size='small' color='primary' variant='contained' onClick={getNewsletterWhatsApp}>Newsletter WhatsApp</Button>
            </div>
            <Explenations explanationDialog={explanationDialog} setExplanationDialog={setExplanationDialog} canEdit/>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Explenations</Typography>
                <Button size='small' color='primary' variant='contained' onClick={()=>setExplanationDialog({open:true, type:'interviews'})}>Interviews</Button>
                {explenationsFields.map((explenationsField) => 
                    <Accordion key={explenationsField.name}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {explenationsField.label}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{width:'100%'}}>
                                <Explanation name={explenationsField.name} value={explenations[explenationsField.name]||''} onSave={handleChangeExplanation} isLoading={isLoading}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
                {PESONAL_JOURNEY_STEPS_NAMES.map((explenationsField, index) => 
                    <Accordion key={explenationsField}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {PESONAL_JOURNEY_STEPS[index]}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{width:'100%'}}>
                                <Explanation name={explenationsField} value={explenations[explenationsField]||''} onSave={handleChangeExplanation} isLoading={isLoading}/>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Emails To Send Test</Typography>
                {emailsToSendTest.map(emailToSendTest => 
                    <Chip key={emailToSendTest} className="profileTag" label={emailToSendTest} variant="outlined" 
                        onDelete={(isNewEmailToSendTestItem?null:()=>handleDeleteEmailToSendTest(emailToSendTest))} 
                    />
                )}
                {(isNewEmailToSendTestItem)?<Fragment>
                    <TextField onChange={handleChange} type="email" value={newEmailToSendTestItem}/>
                    <IconButton color='primary' onClick={handleSaveExcludeListItem} aria-label="save" disabled={(isLoading || !newEmailToSendTestItem)}>
                        <SaveIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={isLoading} color='primary' onClick={handleCancelAddEmailToSendTestItem} aria-label="cancel">
                        <CancelIcon fontSize="small" />
                    </IconButton>
                </Fragment>:
                <IconButton disabled={isLoading} color='primary' onClick={handleEmailToSendTestItem} aria-label="add">
                    <AddIcon fontSize="small" />
                </IconButton>}
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Personal Journey Statuses</Typography>
                <ObjectsList ObjectsList={statuses} setNewObjects={changeStatuses} fieldName="status"/>
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Statuses Scheduled Meeting</Typography>
                {statuses.map(status => 
                    <Chip key={status} icon={sceduledMeetingStatuses.includes(status)?<CheckIcon/>:undefined} className="profileTag" label={status} variant="outlined" onClick={toggleMeetingStatuses(status)}/>
                )}
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>JobHunt Plans</Typography>
                <ObjectsList ObjectsList={plans} setNewObjects={changePlans} fieldName="plan"/>
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Questionnaire Statuses</Typography>
                <ObjectsList ObjectsList={questionnaireStatuses} setNewObjects={changeQuestionnaireStatus} fieldName="questionnaire-status"/>
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>Summary Types</Typography>
                <ObjectsList ObjectsList={summaryTypes} setNewObjects={changeSummaryTypes} fieldName="summary-type"/>
            </Paper>
            <Paper className={classes.prePublishSection} elevation={1}>
                <Typography variant='h6'>NewsLetter Emails</Typography>
                <ObjectsList ObjectsList={newsLetterEmails} setNewObjects={changeNewsLetterEmails} fieldName="newsLetter-email"/>
            </Paper>
        </>
    )
}