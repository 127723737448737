import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
//import { BrowserRouter as Router, Route } from 'react-router-dom'
import {configureStore, history} from '../redux/configureStore'
import App from './App'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
//import blue from '@material-ui/core/colors/blue';
import ReactGA from 'react-ga4'

const isJobHunt = (window.location.hostname === 'dashboard.jobhunt.co.il' || window.location.hostname === 'devdashboard.jobhunt.co.il')
//const isJobHunt = true

const theme = createTheme({
    /*typography: {
        useNextVariants: true,
        suppressDeprecationWarnings: true
    },*/
    palette: {
        //primary: blue,
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#396a92',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#fbbc04'
        }
    }
});

const themeJH = createTheme({
    typography: {
        fontFamily: [
            //"Inter",
            'DM Sans',
            'sans-serif'
        ].join(','),
    },
    palette: {
        //primary: blue,
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#aa4dc8',
            //main: '#396a92',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#fbbc04'
        }
    },
    /*shape: {
        borderRadius: 10
    }*/
});

const store = configureStore()

const GAID = (window.location.hostname==='jobswipe.co') ?'G-KPZDF6XWV7' : 'G-9BBGEKZBKF';
const themeToUse = isJobHunt?themeJH:theme

ReactGA.initialize(GAID)

/*history.listen((location, action) => {
    if (location.pathname.startsWith('/jobs')){
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
    }
})*/

export default class Root extends Component {
    render() {
        return (
            <MuiThemeProvider theme={themeToUse}>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <App history={history} isJobHunt={isJobHunt}/>
                    </ConnectedRouter>
                </Provider>
            </MuiThemeProvider>
        )
    }
}