import { REQUEST_HTML_TEMPLATES, 
	RECEIVE_HTML_TEMPLATES, 
	CLEAR_HTML_TEMPLATES} from '../actions/htmlTemplates'

const initialState = {
	isFetching: false,
	htmlTemplates: [],
	css: '',
	mainHtml: '',
	params: {}
}

export function htmlTemplates(state = initialState, action) {
    switch (action.type) {
		case CLEAR_HTML_TEMPLATES:
			return initialState
      	case REQUEST_HTML_TEMPLATES:
			return Object.assign({}, state, {
				isFetching: true,
				htmlTemplates: [],
				css: '',
				mainHtml: '',
				params: {}
			})
	  	case RECEIVE_HTML_TEMPLATES:
			return Object.assign({}, state, {
				isFetching: false,
				htmlTemplates: action.htmlTemplates || [],
				css: action.css || '',
				mainHtml: action.mainHtml || '',
				params: action.params || {}
			})
      	default:
        	return state
    }
}