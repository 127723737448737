import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { dateAsString, FIELD_TYPES, getHost } from '../../utils/utils';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { Fields } from '../Fields';
import EventApi from "../../utils/EventApi"

export default function Registered(props) {
    const registeredData = props.registeredData || {}
    const isLoading = props.isLoading || false
    const eventUrl = props.eventUrl || ''

    const [isCancelRegister, setIsCancelRegister] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [error, setError] = useState();
    const [showQuestionnaireDialog, setShowQuestionnaireDialog] = useState(registeredData.haveQuestionnaire !== true);

    const handleCancelRegisterButton = () => {
        setIsCancelRegister(true)
    }

    const closeCancelRegister = () => {
        setIsCancelRegister(false)
    }

    const closeQuestionnaireDialog = () => {
        setShowQuestionnaireDialog(false)
    }

    const getValue = (name, defaultValue) => {
        return cancelReason || ''
    }

    const handleChangeField = e => {
        setCancelReason(e.target.value)
    };

    const handleCancelRegister = e => {
        e.preventDefault();

        props.preServerCall()

        EventApi.cancelRegistrationToEvent({eventUrl, cancelReason, id:registeredData._id, code:registeredData.code},(response)=>{
            setIsCancelRegister(false)
            setError('')
            setCancelReason('')

            props.postServerCall(null, {chngeUrl:'/events/'+eventUrl+'?canceled=true'})
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const fields = [
        {name:'reason', label:'סיבה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12}, 
    ]

    const googleMeetLink = getHost() + '/events/google_meet?id='+registeredData._id+'&code='+registeredData.code
    const personalLink = getHost() + '/events/jobhunt-webinar?id='+registeredData._id+'&code='+registeredData.code

    const description = `נרשמת לJobHunt Webinar - טיפים לחיפוש עבודה חכם ויעיל
בתאריך: ` + dateAsString(new Date(registeredData.date)) + ` בשעה 15:00 (שעון ישראל)

אנא ספר לנו מי אתה באמצעות השאלון הקצר הבא
http://bit.ly/DreamJob3

זוהי פגישת אונליין של Google Meet.
אתה יכול להצטרף לפגישה זו מהמחשב, הטאבלט או הסמארטפון שלך.
`+ googleMeetLink +`

לביטול או הוספה ליומן לחץ כאן
` + personalLink

    return (
        <Paper style={{margin:8, padding:'8px 16px', position:'relative'}} elevation={1} className='hebrew-text'>
            <Dialog
                open={showQuestionnaireDialog}
                onClose={closeQuestionnaireDialog}
                scroll="paper"
            >
                <DialogContent className='hebrew-text'>
                    <Typography style={{fontSize:18}}>הוובינר מצומצם. <br/>
                        כדי שנוכל לתת מענה אישי, <br/>
                        נשמח שתפרט.י קצת יותר על עצמך ועל תהליך החיפוש שחווית עד עתה:</Typography>
                    <Typography><a target="_blank" rel="noopener noreferrer" href="http://bit.ly/DreamJob3">http://bit.ly/DreamJob3</a></Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        size="small"
                        onClick={closeQuestionnaireDialog}
                        aria-label="אישור"
                        disabled={isLoading}
                    >
                        אישור
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isCancelRegister}
                onClose={closeCancelRegister}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll="paper"
                className='hebrew-text'
            >
                <DialogTitle id="alert-dialog-title">ביטול רישום</DialogTitle>
                <form onSubmit={handleCancelRegister}>
                    <DialogContent dividers>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            size="small"
                            onClick={closeCancelRegister}
                            aria-label="Cancel"
                            disabled={isLoading}
                        >
                            סגור
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="ביטול רישום"
                            disabled={isLoading}
                        >
                            ביטול רישום
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Typography variant='h6'>נרשמת למפגש שיתקיים בתאריך {dateAsString(new Date(registeredData.date))} בין 15:00-16:00</Typography><br/>
            <AddToCalendarButton
                name="JobHunt Webinar"
                description={description}
                startDate={registeredData.dateYear+"-"+registeredData.dateMonth+"-"+registeredData.dateDay/*registeredData.date*/}
                startTime="15:00"
                endTime="16:00"
                label="הוסף ללוח שנה"
                options={["Apple",
                    "Google",
                    "iCal",
                    "Microsoft365",
                    "MicrosoftTeams",
                    "Outlook.com",
                    "Yahoo"]}
                timeZone="Asia/Jerusalem"
                iCalFileName="JobHunt Webinar"
                ></AddToCalendarButton>
            <br/>
            <Typography variant='subtitle1'>
                <span>שלחנו אליך אימייל עם כל הפרטים ולינק למפגש</span><br/>
                <span>לשאלות נוספות ומענה צור קשר באמצעות </span>
                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/message/ONYDJASQP4UXF1">הוואטסאפ</a>
            </Typography>
            <br/>
            {/*<Typography><b>מיקום:</b> זוהי פגישת אונליין של Google Meet.<br/>
                אתה יכול להצטרף לפגישה זו מהמחשב, הטאבלט או הסמארטפון שלך.<br/>
                <a target="_blank" rel="noopener noreferrer" href={"/events/google_meet?id="+registeredData._id+'&code='+registeredData.code}>הכנס לפגישה</a>
            </Typography>*/}
            
            <Button color='primary' variant='contained' onClick={handleCancelRegisterButton}>ביטול רישום</Button>
        </Paper>
    )
}