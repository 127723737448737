import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import AdminJHApi from "../../utils/AdminJHApi"
import { Fields } from '../Fields';
import { FIELD_TYPES, showDateTimeFromString } from '../../utils/utils';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

export default function InterviewsReportStats(props) {
    const searchParams = props.searchParams || {}
    const showStats = props.showStats || false

    const [data, setData] = useState();
    const [totals, setTotals] = useState();
    const [error, setError] = useState();

    const [filter, setFilter] = useState({});

    useEffect(() => {
        if(showStats){
            //props.startEdit()
            AdminJHApi.getInterviewsReportDataStats({...searchParams},(response)=>{
                let newTotals = {'Total':0}
                const mappedData = (response.data||[]).map(row => {    
                    newTotals['Total']+=row.total

                    return { 
                        'Week': showDateTimeFromString(row._id, false, true),
                        'Total': row.total,
                    }
                })

                setTotals(newTotals)
                setData(mappedData)
                const extraSearch = searchParams.extraSearch || {}
                setFilter(extraSearch)
                setError()
                //props.finishEdit()
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                //props.finishEdit()
            })
        }
    }, [showStats, searchParams]);

    const handleSearch = () => {
        AdminJHApi.getInterviewsReportDataStats({extraSearch:filter},(response)=>{
            let newTotals = {'Total':0}
            const mappedData = (response.data||[]).map(row => {    
                newTotals['Total']+=row.total

                return { 
                    'Week': showDateTimeFromString(row._id, false, true),
                    'Total': row.total,
                }
            })

            setTotals(newTotals)
            setData(mappedData)
            setError()
            //props.finishEdit()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            //props.finishEdit()
        })
    }

    const renderLegendText = (value) => {
        return <span style={{marginRight:8}}>{value} : {totals[value]}</span>;
    };

    const handleChangeField = e => {
        setFilter({...filter,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (filter[name] !== undefined){
            return filter[name]
        } else {
            return defaultValue
        }
    }

    const fields = [
        { name: 'from', label:'From', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'to', label:'To', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
    ]

    return (
        <Dialog onClose={()=>{props.setShowStats(false)}} open={showStats} fullWidth classes={{paper:'fullMaxWidth'}}>
            {/*<DialogTitle>{(editId===-1)?'Add':'Edit'} Lead</DialogTitle>*/}
            <DialogContent dividers>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{display: 'flex'}}>
                        {data&&<>
                            {(error)&&<Typography color="error">{error}</Typography>}
                            <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                            <IconButton onClick={handleSearch} style={{marginLeft:16}} color="primary">
                                <SearchIcon/>
                            </IconButton>
                        </>}
                    </div>
                    <IconButton onClick={()=>{props.setShowStats(false)}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                
                {data&&<div style={{height:'350px', width:'100%', paddingTop:16}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={350}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Week" />
                            <YAxis />
                            <Tooltip />
                            <Legend formatter={renderLegendText}/>
                            <Line type="monotone" dataKey="Total" stroke="#631976"/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>}
            </DialogContent>
        </Dialog>
    )
}