import React, { Component } from 'react'
import { connect } from 'react-redux'

import { selectJob, addSavedItem, removeSavedItem } from '../redux/actions/selectedJob'
import HistogramJob from '../components/HistogramJob'
import HistogramResume from '../components/HistogramResume'

import api from "../utils/HistApi";

import ReactGA from 'react-ga4'

import '../styles/ObjectOnHist.css'
import { setHistogramWithData } from '../redux/actions/histogramJobs';
import HistogramReport from '../components/HistogramReport';
import { updateUserInfo } from '../redux/actions/authActions';
import { getMyJobs } from '../utils/utils';
import { showDialog } from '../redux/actions/MainDialog'
import HistogramCompany from '../components/HistogramCompany'
import { HistogramPersonalJourney } from '../components/JH/HistogramPersonalJourney'
import HistogramInreviews from '../components/JH/HistogramInreviews'

class ObjectOnHist extends Component {
    constructor(props){
        super(props)

        this.addSavedItem = this.addSavedItem.bind(this)
        this.removeSavedItem = this.removeSavedItem.bind(this)
    }

    handleSelectJob = () => {
        const { histogramType } = this.props;
        if (histogramType === 'jobs'){
            ReactGA.event({category: 'Card',action: 'click_job_from_histogram'})
        }
        const { dispatch } = this.props
        dispatch(selectJob(this.props.histogramObject, true));
    };

    handleActivate = () => {
        api.changeJobStatus({jobId:this.props.histogramObject._id, newStatus: 'A'}, results=>{
            const { dispatch } = this.props

            if (results.jobLimitReached){
                dispatch(showDialog('Jobs limit reached', 'Your jobs limit reached'))
                /*if (results.isPro){
                    ReactGA.event({category:'Payment', action:'JobsLimitReached'});
                    dispatch(showDialog('Jobs limit reached', 'Your jobs limit reached'))
                } else {
                    dispatch(showPayment(INC_JOBS_LIMIT, 'recruiter', 'activateJob'))
                }*/
            } else {
                let newUserData = {jobs: getMyJobs(results.results)}
                if(results.applies){
                    newUserData.applies = results.applies
                }
                dispatch(updateUserInfo(newUserData))
                dispatch(setHistogramWithData(results));     
                ReactGA.event({category: 'Job_manager',action: 'jobActivated'});       
            }
        }, error =>{
            if (error.response.data){
                console.log(error.response.data)
            }
        })
    }

    handleDelete = () => {
        ReactGA.event({category: 'Job_manager',action: 'jobRemoved'});

        api.changeJobStatus({jobId:this.props.histogramObject._id, newStatus: 'D'}, results=>{
            const { dispatch } = this.props

            let newUserData = {jobs: getMyJobs(results.results)}
            if(results.applies){
                newUserData.applies = results.applies
            }
            dispatch(updateUserInfo(newUserData))
            dispatch(setHistogramWithData(results));            
        }, error =>{
            if (error.response.data){
                console.log(error.response.data)
            }
        })
    }

    handleDeactivate = () => {
        ReactGA.event({category: 'Job_manager',action: 'jobDeactivated'});

        api.changeJobStatus({jobId:this.props.histogramObject._id, newStatus: 'O'}, results=>{
            const { dispatch } = this.props

            let newUserData = {jobs: getMyJobs(results.results)}
            if(results.applies){
                newUserData.applies = results.applies
            }
            dispatch(updateUserInfo(newUserData))
            dispatch(setHistogramWithData(results));            
        }, error =>{
            if (error.response.data){
                console.log(error.response.data)
            }
        })
    }

    addSavedItem(){
        const { dispatch, histogramObject, histogramType } = this.props
        dispatch(addSavedItem(histogramObject._id, histogramType, histogramObject))
    }

    removeSavedItem(){
        const { dispatch, histogramObject, histogramType } = this.props
        dispatch(removeSavedItem(histogramObject._id, histogramType))
    }

    render() {
        const { histogramObject, selectedJob, isAuthed, applies, lookingforwork, histogramSubType, jobs, savedItems, fetchingData } = this.props;
        
        const selected = (!fetchingData && histogramObject._id === selectedJob._id)

        let { histogramType } = this.props
        let isBookedmark = false;

        let isApplied = false;
        let rowApply = [];

        if (isAuthed){
            if (lookingforwork){
                rowApply = applies.filter(apply => apply.jobId === histogramObject._id)
            } else {
                rowApply = applies.filter(apply => apply.talentId === histogramObject._id)
            }

            isBookedmark = savedItems.some(r => r._id === histogramObject._id)
            isApplied = (rowApply.length > 0);
        }

        let params = {}
        let component = ''

        switch (histogramType){
            case 'jobs':{
                component = 'HistogramJob'
                params = {
                    job: histogramObject, histogramType, histogramSubType, isApplied, isBookedmark, 
                    isAuthed, lookingforwork, onClick:this.handleSelectJob, removeSavedItem:this.removeSavedItem,
                    addSavedItem:this.addSavedItem,
                }
                break
            } case 'myJobs':{
                component = 'HistogramJob'
                params = {
                    job: histogramObject, histogramType, isBookedmark, isAuthed, histogramSubType,
                    isMyJobs: true, handleActivate: this.handleActivate, handleDelete: this.handleDelete,
                    handleDeactivate: this.handleDeactivate, onClick: this.handleSelectJob, 
                    removeSavedItem: this.removeSavedItem, addSavedItem:this.addSavedItem
                }
                break
            } case 'resumes':{
                component = 'HistogramResume'
                params = {
                    resume: histogramObject, isApplied, histogramSubType, isBookedmark, isAuthed,
                    lookingforwork, onClick: this.handleSelectJob, removeSavedItem: this.removeSavedItem,
                    addSavedItem: this.addSavedItem,
                }
                break
            } case 'profile':{
                component = 'HistogramResume'
                params = {
                    resume:histogramObject, histogramSubType, isBookedmark, isAuthed, isProfile:true, onClick:this.handleSelectJob,
                }
                break
            } case 'activity':{
                if (lookingforwork){
                    component = 'HistogramJob'
                    params = {
                        job:histogramObject, isApplied, histogramSubType, isBookedmark, isAuthed, lookingforwork,
                        onClick:this.handleSelectJob, removeSavedItem:this.removeSavedItem, addSavedItem:this.addSavedItem
                    }
                } else {
                    component = 'HistogramResume'
                    params = {
                        resume:histogramObject, isApplied, histogramSubType, isBookedmark, isAuthed, lookingforwork, userJobs:jobs,
                        onClick:this.handleSelectJob, removeSavedItem:this.removeSavedItem, addSavedItem:this.addSavedItem
                    }
                }
                break
            } case 'reports':{
                component = 'HistogramReport'
                params = {
                    report: histogramObject, histogramType, histogramSubType, onClick: this.handleSelectJob
                }
                break
            } case 'saveditems':{
                if (lookingforwork){
                    component = 'HistogramJob'
                    params = {
                        job:histogramObject, histogramType, histogramSubType, isApplied, isBookedmark, isAuthed, lookingforwork, 
                        onClick:this.handleSelectJob, removeSavedItem:this.removeSavedItem, addSavedItem:this.addSavedItem
                    }
                } else {
                    component = 'HistogramResume'
                    params = {
                        resume:histogramObject, isApplied, histogramSubType, isBookedmark, isAuthed, lookingforwork,
                        onClick:this.handleSelectJob, removeSavedItem:this.removeSavedItem, addSavedItem:this.addSavedItem
                    }
                }
                break
            } case 'companies':{
                component = 'HistogramCompany'
                params = {
                    company: histogramObject, histogramType, histogramSubType, onClick: this.handleSelectJob
                }
                break
            } case 'jobhuntUsers':{
                component = 'HistogramPersonalJourney'
                params = {
                    jobHuntUser: histogramObject, onClick: this.handleSelectJob
                }
                break
            } case 'interviews':{
                component = 'HistogramInreviews'
                params = {
                    interview: histogramObject, onClick: this.handleSelectJob
                }
                break
            } default: {

            }
        }

        if(fetchingData){
            params.fetchingData = fetchingData
            params.onClick = () => {}
        }
        

        return (
            <li className={selected ? 'cl selected':'cl'}>
                {(component === 'HistogramJob')&&<HistogramJob {...params}/>}
                {(component === 'HistogramResume')&&<HistogramResume {...params}/>}
                {(component === 'HistogramReport')&&<HistogramReport {...params}/>}
                {(component === 'HistogramCompany')&&<HistogramCompany {...params}/>}
                {(component === 'HistogramPersonalJourney')&&<HistogramPersonalJourney {...params}/>}
                {(component === 'HistogramInreviews')&&<HistogramInreviews {...params}/>}
            </li>
        )
    }
}

function mapStateToProps(state) {
    const selectedJob = state.selectedJob.job || false;
    const { histogramSubType } = state.histogramData;
    const { isAuthed, applies, userInfo, jobs, savedItems } = state.authUser;
    const lookingforwork = userInfo.lookingforwork;
    const histogramType = state.histogramData.histogramType

    return {
        selectedJob, histogramType, isAuthed, applies, lookingforwork, histogramSubType, jobs, savedItems
    }
}

export default connect(mapStateToProps)(ObjectOnHist)