import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const changeJobStatus = (params, onSuccess, onError = ()=>{}) => {
    post('changeJobStatus', params, onSuccess, onError)
}

const saveUserJob = (params, onSuccess, onError = ()=>{}) => {
    post('saveUserJobR', params, onSuccess, onError)
}

const createNewJob = (params, onSuccess, onError = ()=>{}) => {
    post('createNewJob', params, onSuccess, onError)
}

const saveProfile = (params, onSuccess, onError = ()=>{}) => {
    post('saveProfile', params, onSuccess, onError)
}

const getHistogramObjects = (params, onSuccess, onError = ()=>{}) => {
    post('getHistogramObjects', params, onSuccess, onError)
}

const getHistogramObject = (params, onSuccess, onError = ()=>{}) => {
    post('getHistogramObject', params, onSuccess, onError)
}
   
const getUserJobs = (onSuccess, onError = ()=>{}) => {
    post('getUserJobsR', null, onSuccess, onError)
}

const getUserActivity = (params, onSuccess, onError = ()=>{}) => {
    post('getUserActivity', params, onSuccess, onError)
}

const applyHistObject = (params, onSuccess, onError = ()=>{}) => {
    post('applyHistObject', params, onSuccess, onError)
}

const uploadAvatarR = (params, onSuccess, onError = ()=>{}) => {
    post('uploadAvatarR', params, onSuccess, onError)
}

const uploadResumeR = (params, onSuccess, onError = ()=>{}) => {
    post('uploadResumeReact', params, onSuccess, onError)
}

const removeAvatarR = (onSuccess, onError = ()=>{}) => {
    post('removeAvatarR', null, onSuccess, onError)
}

const reportObject = (params, onSuccess, onError = ()=>{}) => {
    post('reportObject', params, onSuccess, onError)
}

const getFaqData = (onSuccess, onError = ()=>{}) => {
    get('get-faq-data', null, onSuccess, onError)
}

const getTagsData = (onSuccess, onError = ()=>{}) => {
    get('getTagsData', null, onSuccess, onError)
}

const sendFeedback = (params, onSuccess, onError = ()=>{}) => {
    post('sendFeedback', params, onSuccess, onError)
}

const fetchContact = (params, onSuccess, onError = ()=>{}) => {
    post('fetchContact', params, onSuccess, onError)
}

const promoteObject = (params, onSuccess, onError = ()=>{}) => {
    post('promoteObject', params, onSuccess, onError)
}

const addAlert = (params, onSuccess, onError = ()=>{}) => {
    post('addAlert', params, onSuccess, onError)
}

const removeAlert = (params, onSuccess, onError = ()=>{}) => {
    post('removeAlert', params, onSuccess, onError)
}

const setFrequency = (params, onSuccess, onError = ()=>{}) => {
    post('setFrequency', params, onSuccess, onError)
}

const addSavedItem = (params, onSuccess, onError = ()=>{}) => {
    post('addSavedItem', params, onSuccess, onError)
}

const removeSavedItem = (params, onSuccess, onError = ()=>{}) => {
    post('removeSavedItem', params, onSuccess, onError)
}

const getSavedItems = (onSuccess, onError = ()=>{}) => {
    post('getSavedItems', null, onSuccess, onError)
}

const addCompany = (params, onSuccess, onError = ()=>{}) => {
    post('addCompany', params, onSuccess, onError)
}

const getCompanies = (params, onSuccess, onError = ()=>{}) => {
    post('getCompanies', params, onSuccess, onError)
}

const getReviews = (params, onSuccess, onError = ()=>{}) => {
    post('getReviews', params, onSuccess, onError)
}

const addReview = (params, onSuccess, onError = ()=>{}) => {
    post('addReview', params, onSuccess, onError)
}

const addHelpful = (params, onSuccess, onError = ()=>{}) => {
    post('addHelpful', params, onSuccess, onError)
}

const api = {
    saveProfile,
    saveUserJob,
    getHistogramObjects,
    getHistogramObject,
    getUserJobs,
    createNewJob,
    changeJobStatus,
    getUserActivity,
    applyHistObject,
    uploadAvatarR,
    uploadResumeR,
    removeAvatarR,
    reportObject,
    getFaqData,
    sendFeedback,
    getTagsData,
    fetchContact,
    promoteObject,
    addAlert,
    removeAlert,
    setFrequency,
    addSavedItem,
    removeSavedItem,
    getSavedItems,
    addCompany,
    getCompanies,
    getReviews,
    addReview,
    addHelpful,
};
export default api;