import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'

import Button from '@material-ui/core/Button';

import '../styles/Profile.css'
import Basic from '../components/Job/Basic';
import Description from '../components/Job/Description';

import api from "../utils/HistApi";
import { setHistogramWithData } from '../redux/actions/histogramJobs';
import Tags from '../components/Job/Tags';
import { cancelNewObject, setEditMode } from '../redux/actions/selectedJob';
import { getMyJobs } from '../utils/utils';
import { updateUserInfo } from '../redux/actions/authActions';

import ReactGA from 'react-ga4'
import Salary from '../components/Job/Salary';
import Location from '../components/Job/Location';
import Typography from '@material-ui/core/Typography';
import { showDialog } from '../redux/actions/MainDialog';

class EditJob extends Component {
    state = {
        jobInfo: this.props.jobInfo,
        onEdit: 0
    }

    componentDidUpdate(prevProps){
        if(prevProps.isAuthed !== this.props.isAuthed || (prevProps.jobInfo && this.props.jobInfo && prevProps.jobInfo._id !== this.props.jobInfo._id)){
            this.setState({
                jobInfo: this.props.jobInfo
            })
        }
    }

    handleSaveSection = async (fields, salary) => {
        let jobInfo = this.state.jobInfo;

        let fieldsToUpdate = {};

        fields.forEach(field => {
            fieldsToUpdate[field] = jobInfo[field];
        });

        if (salary){
            this.setState({
                jobInfo : {...this.state.jobInfo,
                    salaryCurrency: fieldsToUpdate.salaryCurrency || 'USD',
                    salaryFrequency: fieldsToUpdate.salaryFrequency || 'MONTH'
                }
            });
        }
        
        this.saveSection(jobInfo._id, fieldsToUpdate)
    };

    saveSection = (jobId, fieldsToUpdate) => {
        api.saveUserJob({jobId, ...fieldsToUpdate}, (results)=>{
            const { dispatch } = this.props
            dispatch(updateUserInfo({jobs:getMyJobs(results.results)}))
            dispatch(setHistogramWithData(results, jobId));
        })
    }

    handleChange = (name, value, save = false) => {
        if (Array.isArray(value)){
            value = [...value]
        }
        this.setState({
            jobInfo : {...this.state.jobInfo,
                [name]: value 
            }
        });

        if(save){
            this.saveSection({[name]:value})
        }
    };

    handleUpdateTags = (tags, selectedTags) => {
        if (!tags){
            this.handleChange('selectedTags', selectedTags)
        } else if (!selectedTags){
            this.handleChange('tags', tags)
        } else {
            this.setState({
                jobInfo : {...this.state.jobInfo,
                    tags, selectedTags
                }
            });
        }
    }

    handleLocationChange = (locationObj)  => {
        this.setState({
            jobInfo : {...this.state.jobInfo,
                location: locationObj.text,
                loc_lat: locationObj.lat,
                loc_lng: locationObj.lng,
                country: locationObj.country,
                countryCode: locationObj.countryCode,
                streetAddress: locationObj.streetAddress,
                postalCode: locationObj.postalCode,
            }
        });
    }

    handleCancel = fields => {
        let jobInfo = this.props.jobInfo;

        let updatedUserInfo = {...this.state.jobInfo}
        fields.forEach(field => {
            updatedUserInfo[field] = jobInfo[field];
        });   
        
        this.setState({
            jobInfo : updatedUserInfo
        });
    }

    handleDoneEdit = () => {
        const { dispatch } = this.props
        dispatch(setEditMode(false))
    }

    handleNewJob = e => {
        e.preventDefault();
        
        this.setState({isOnFirstEdit: true})
  
        ReactGA.event({category: 'Job_manager',action: 'jobAdded'});

        api.createNewJob({jobInfo:this.state.jobInfo}, results=>{
            const { dispatch } = this.props

            if (results.jobLimitReached){
                if (results.isPro){
                    ReactGA.event({category:'Payment', action:'JobsLimitReached'});
                    dispatch(showDialog('Jobs limit reached', 'Your jobs limit reached'))
                } else {
                    dispatch(showDialog('Jobs limit reached', 'Your jobs limit reached'))
                    //dispatch(showPayment(INC_JOBS_LIMIT, 'recruiter', 'newJob'))
                }
                
            } else {
                dispatch(updateUserInfo({jobs: getMyJobs(results.results)}))
                dispatch(setHistogramWithData(results));
            }
        }, error =>{
            if (error.response.data){
                const { dispatch } = this.props
                dispatch(showDialog('Error', error.response.data))
            }
        })
    }

    handleCancelNewJob = () => {
        const { dispatch } = this.props
        return dispatch(cancelNewObject());
    }

    changeOnEditCount = (increment) => {
        let onEdit = this.state.onEdit
        
        if (increment){
            onEdit++
        } else {
            onEdit--
        }

        this.setState({onEdit})
    }

    getFields = (jobInfo, isNew, selectedjobbgcolor, onEdit) => {
        const {jobs} = this.props
        
        return (
            <React.Fragment>
                {(isNew)&&<Typography variant="h5" style={{margin:16}}>Add a new job</Typography>}
                <Basic changeOnEditCount={this.changeOnEditCount} jobInfo={jobInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} isNew={isNew}  handleLocationChange={this.handleLocationChange} userJobs={jobs}/>   
                <div className="profileDoneEdit">
                    {(!isNew)&&<Button disabled={(onEdit > 0)} style={(onEdit > 0)?{}:{backgroundColor:selectedjobbgcolor}} variant="contained" color="primary" onClick={this.handleDoneEdit} aria-label="Done Edit">Done Edit</Button>}
                </div>
                <Location changeOnEditCount={this.changeOnEditCount} jobInfo={jobInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} isNew={isNew}  handleLocationChange={this.handleLocationChange}/>
                <Tags jobType changeOnEditCount={this.changeOnEditCount} jobInfo={jobInfo} handleChange={this.handleUpdateTags} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} isNew={isNew}/>   
                <Description changeOnEditCount={this.changeOnEditCount} jobInfo={jobInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} isNew={isNew}/>   
                <Salary changeOnEditCount={this.changeOnEditCount} jobInfo={jobInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} isNew={isNew}/>
                <div className="profileDoneEdit">
                    {(!isNew)&&<Button disabled={(onEdit > 0)} style={(onEdit > 0)?{}:{backgroundColor:selectedjobbgcolor}} variant="contained" color="primary" onClick={this.handleDoneEdit} aria-label="Done Edit">Done Edit</Button>}
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { isAuthed, selectedjobbgcolor, isNew } = this.props
        const { jobInfo, onEdit, isOnFirstEdit } = this.state;

        return (
            <div className="editProfile">
                {(isAuthed)&&<React.Fragment>
                    <Prompt
                        when={(isNew && !isOnFirstEdit) || onEdit > 0}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                    {(isNew)?<form  onSubmit={this.handleNewJob}>
                        {this.getFields(jobInfo, isNew, selectedjobbgcolor, onEdit)}
                        <div className="profileButtons" style={{marginRight:16, marginBottom:16}}>
                            <Button
                                color="primary"
                                onClick={this.handleCancelNewJob}
                                aria-label="Cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                aria-label="Save"
                            >
                                Save
                            </Button>
                        </div>
                    </form>:
                    this.getFields(jobInfo, isNew, selectedjobbgcolor, onEdit)}
                </React.Fragment>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, jobs } = state.authUser;
    const { job:jobInfo, isNew } = state.selectedJob

    return {
        isAuthed, jobInfo, isNew, jobs
    }
}

export default connect(mapStateToProps)(EditJob)