import React from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import AuthApi from "../../utils/AuthApi";

import '../../styles/Login.css'

export default class NewPasswordDialog extends React.Component {
    state = {
        password:'',
        confirmPassword:''
    };

    handleOnSubmit = async e => {
        e.preventDefault();

        const { password, confirmPassword } = this.state;
        const { email, code } = this.props

        if ((password === confirmPassword)){
            AuthApi.updateUserPassword({email, password, code}, token=>{
                this.props.handelLoginUser(token)
            }, (error)=>{
                if (error.response.data && error.response.data.message){
                    this.props.showDialog('Error', error.response.data.message)
                }
            });
        }
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.props.onClose();
    };

    render(){
        const { onClose, email, handelLoginUser, showDialog, ...other } = this.props;
        const { password, confirmPassword } = this.state;

		return (
            <Dialog onClose={this.handleClose} aria-labelledby="share-dialog-title" {...other}>
				<DialogContent className="loginDialog" style={{padding:"24px 24px 32px 24px"}}>
                    <Typography component="h3" variant="h6">
                        Choose New Password
                    </Typography>
                    <form onSubmit={this.handleOnSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Address</InputLabel>
                            <Input id="email" name="email" autoComplete="email" value={email} disabled/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">New Password</InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                        <FormControl margin="normal" required error={(password !== confirmPassword)} fullWidth>
                            <InputLabel htmlFor="confirmPassword">Confirm New Password</InputLabel>
                            <Input
                                name="confirmPassword"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            aria-label="Update Password"
                            style={{marginTop:16}}
                        >
                            Update Password
                        </Button>
                    </form>
                </DialogContent>
			</Dialog>
        )
    }
}