import { REQUEST_PAYMENTS, 
	RECEIVE_PAYMENTS, 
	CLEAR_PAYMENTS} from '../actions/payments'

const initialState = {
	isFetching: false,
	payments: [],
	from: '',
	to: '',
}

export function payments(state = initialState, action) {
    switch (action.type) {
		case CLEAR_PAYMENTS:
			return initialState
      	case REQUEST_PAYMENTS:
			return Object.assign({}, state, {
				isFetching: true,
				payments: []
			})
	  	case RECEIVE_PAYMENTS:
			return Object.assign({}, state, {
				isFetching: false,
				payments: action.payments || [],
				from: action.from || '',
				to: action.to || '',
			})
      	default:
        	return state
    }
}