import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { showDialog } from '../redux/actions/MainDialog'
import HistogramC from './HistogramC'
import BflClientsApi from "../utils/BflClientsApi";
import BflClients from '../components/BflClients'

class BflClientsPC extends Component {
    state = {
        permissions: {}
    }

    componentDidUpdate(prevProps){
        if (this.isChanged(prevProps.userId, this.props.userId)){
            if (this.props.userId){
                this.fetchData()
            } else {
                this.setState({permissions: {}})
            }
        }
    }

    isChanged(prevValue, newValue){
        //return ((newValue) && (!prevValue || newValue !== prevValue))
        return newValue !== prevValue
    }

    fetchData = params => {
        BflClientsApi.getBflUserPermissions({},(data)=>{
            this.setState({permissions: data})
        }, (error) => {
            this.setState({permissions: {}})
            /*const { dispatch } = this.props
            dispatch(showDialog('Error', error.error))*/
        })
    }

    showMessageDialog = (title, content) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content))
    }

    render() {
        const { permissions } = this.state

        return (
            <Fragment>
                {(permissions.Admin || permissions.BflUser|| permissions.JHAdmin)?
                    <div className="fullWidthContainer">
                        <BflClients permissions={permissions} showMessageDialog={this.showMessageDialog}/>
                    </div>
                :<HistogramC histogramType={'main'} />}
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const userId = userInfo._id

    return {
        isAuthed, userId
    }
}

export default connect(mapStateToProps)(BflClientsPC)