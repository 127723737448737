import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';

import { ProfileActions } from './ProfileActions'
import '../../styles/Profile.css'
import ReadMore from '../ReadMore';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ResumeUpload from './ResumeUpload';
import Typography from '@material-ui/core/Typography';

export default class Resume extends Component {
    state = {
        editMode: false
    }

    sectionFields = ['description'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })
        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleChangeSwitch = () => event => {     
        this.props.handleChange(event.target.name, event.target.checked, true)   
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    }

    isHebrewText = (text) => {
		if(!text){
			return false
		}
		const hebrew = text.search(/[\u0590-\u05FF]+/mgi);
  		return (hebrew >= 0)? true : false;
    }
    
    handleNewResume = (userData) => {
        this.props.handleNewResume(userData)
    }

    render() {
        const { userInfo, noEdit, selectedjobbgcolor, showDialog, showEnableSearchEngines } = this.props
        const searchWords = this.props.searchWords ||  []
        const directionResume = this.isHebrewText(userInfo.description) ? 'rtl' : 'ltr'

        return (
            <React.Fragment>
                {(noEdit)?<React.Fragment>
                    {(userInfo.showResume === undefined || userInfo.showResume)&&<div className="resumeSection" style={{paddingBottom:0}}>
                        <div className="sectionTitle">Resume</div>
                        <div className="profileField">
                            <ReadMore view={this.props.view} direction={directionResume} searchWords={searchWords}>{userInfo.description}</ReadMore>
                        </div>
                    </div>}
                </React.Fragment>:
                
            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">Resume</div>
                {(this.state.editMode)?
                <form onSubmit={this.handleSaveSection}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <TextField id="description" name="description" label="Resume Text" margin="none" fullWidth 
                                multiline rowsMax="20" value={userInfo.description || ''} onChange={this.handleChange}/>
                        </Grid>
                    </Grid>
                    <div className="profileButtons">
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.handleCancel}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save Changes"
                        >
                            Save Changes
                        </Button>
                    </div>
                </form> :
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <FormControlLabel
                                checked={(userInfo.showResume === undefined)?true:userInfo.showResume}
                                control={<Switch color="primary"/>}
                                label="show resume"
                                labelPlacement="end"
                                name="showResume"
                                onChange={this.handleChangeSwitch()}
                            />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <ResumeUpload handleNewResume={this.handleNewResume} userInfo={userInfo} selectedjobbgcolor={selectedjobbgcolor} showDialog={showDialog} showEnableSearchEngines={showEnableSearchEngines}/>
                        </Grid>
                        {(userInfo.description)&&<Grid item sm={12} xs={12}>
                            <div style={{position:"relative"}}>
                                <ProfileActions handleEdit={this.handleEdit}/>
                            </div>
                            <Typography style={{margin:"10px 0"}}>Resume Text</Typography>
                            <ReadMore view={this.props.view} direction={directionResume} searchWords={searchWords}>{userInfo.description}</ReadMore>
                        </Grid>}
                    </Grid>
                </React.Fragment>}
            </Paper>}
            </React.Fragment>
        )
    }
}