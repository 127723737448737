import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentApi from "../../utils/PaymentApi"
import ReactGA from 'react-ga4'
import '../../styles/Payment.css'

const OPTIONS = [
    {months:6, price:'33.21', save:'Save 52%', topLabel:'Best Value'},
    {months:3, price:'47.35', save:'Save 32%', topLabel:'Most Popular'},
    {months:1, price:'69.90', save:''},
]

const JS_FEATURES = [
    {title:'Unlimited Application', description:'You have unlimited applications! Apply until you find your dream job'},
    {title:'Get details now', description:'Get email & phone of recruiter now without a match'},
    {title:'Skip The Line', description:'Be a top profile in your professional field to get more matches'},
    {title:'Anonymous Search', description:'Limit what recruiters see about you and only be shown to people you apply to'},
]

const REC_FEATURES = [
    {title:'Connect With No Limits', description:'All you can swipe! Don’t limit yourself'},
    {title:'Super Connect', description:'Get contact details of applicant without a match'},
    {title:'Skip The Line', description:'Get one boost for your Job each month'},
    {title:'Publish More Jobs', description:'Publish up to 20 jobs instead of 5'},
    //{title:'Open Archive', description:'See older resumes'},
]

export const UNLIMITED_SWIPE = 0
export const GET_CONTACTS = 1
export const PROMOTE = 2
export const ANON = 3
export const INC_JOBS_LIMIT = 3

export default class PaymentDialog extends React.Component {
    state={
        option: 3,
        featureIndex: this.props.featureIndex || 0,
    }

    componentDidMount = () => {
        this.setState({timer: setInterval((this.nextFeature), 10000)})
    }

    changeOption = option => {
        const {lookingforwork} = this.props
        ReactGA.event({category:'Payment', action:`PlanSelected_pro_${(lookingforwork)?'jobseeker':'recruiter'}_${option}months`});
        this.setState({option})
    }

    changeFeature = featureIndex => {
        this.setState({featureIndex})
    }

    prevFeature = () => {
        let featureIndex = this.state.featureIndex
        if (featureIndex > 0){
            featureIndex--
        } else {
            featureIndex = this.getFeatures().length - 1
        }

        this.setState({featureIndex})
    }

    nextFeature = () => {
        let featureIndex = this.state.featureIndex
        if (featureIndex < this.getFeatures().length - 1){
            featureIndex++
        } else {
            featureIndex = 0
        }

        this.setState({featureIndex})
    }

    closeDialog = () => {
        const {lookingforwork} = this.props
        ReactGA.event({category:'Payment', action:`ClosePayment_pro_${(lookingforwork)?'jobseeker':'recruiter'}_${this.state.option}months`});
        
        clearInterval(this.state.timer);
        this.props.onClose()
    }

    getFeatures = () => {
        const {lookingforwork} = this.props
        return (lookingforwork) ? JS_FEATURES : REC_FEATURES
    }

    openPayment = () => {
        const {lookingforwork, redirectPath} = this.props
        ReactGA.event({category:'Payment', action:`ContinuePayment_pro_${(lookingforwork)?'jobseeker':'recruiter'}_${this.state.option}months`});
        
        this.setState({fetchingUrl:true})
        PaymentApi.getPaymentUrl({method: 'pro' ,option: this.state.option},(data)=>{
            if (data.url){
                localStorage.setItem("redirectPath", redirectPath)
                window.location=data.url;
            } else {
                this.setState({fetchingUrl:false})
            }
        })
    }

    render(){
        const {featureIndex, fetchingUrl} = this.state
        const {lookingforwork} = this.props
        const features = this.getFeatures()
        const feature = features[featureIndex] || features[0]

		return (
			<Dialog open={this.props.open && lookingforwork} onClose={this.closeDialog} classes={{ paper: "paymentDialogPaper" }}>
                <div className="paymentDialogContainer">
                    <div className="pTitle">Get <span className="JobSwipeLogo">JobSwipe</span> <span className="pro">Pro</span>
                        {(!lookingforwork)&&<span className="for"> For Recruiters</span>}
                    </div>
                    <div className="feature">
                        <div className="fTitle">{feature.title}</div>
                        <div className="fDescription">{feature.description}</div>
                    </div>
                    <div className="points">
                        <PrevIcon className="prevIcon" onClick={this.prevFeature}/>
                        {features.map((feat, index) =>
                            <div key={index} className={"point" + ((index === this.state.featureIndex)?' selected':'')} onClick={()=>this.changeFeature(index)}></div>
                        )}
                        <NextIcon className="nextIcon" onClick={this.nextFeature}/>
                    </div>
                    <div className="options">
                        {OPTIONS.map((option) =>
                            <div key={option.months} onClick={()=>this.changeOption(option.months)}
                            className={"option" + ((option.months === this.state.option)?' selected':'') + ((option.popular)?' popular':'')}>
                                {(option.topLabel)&&<div className="topLabel">{option.topLabel}</div>}
                                <div className="month">{option.months}</div>
                                <div className="monthLabel">{(option.months === 1) ? 'month' : 'months'}</div>
                                <div className="price"><b>₪{option.price}</b>/mo</div>
                                <div className="save">{option.save}</div>
                            </div>
                        )}
                    </div>
                    <div className="paymentButtons">
                        {(fetchingUrl)?<div style={{marginTop:20}}><CircularProgress/></div>:
                        <React.Fragment>
                            <button className="paymentButtonProceed" onClick={this.openPayment}>Continue</button>
                            <button className="paymentButtonCancel" onClick={this.closeDialog}>No Thanks</button>
                        </React.Fragment>}
                    </div>
                </div>
            </Dialog>
		)
	}
}