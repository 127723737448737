export const SET_HEAD = 'SET_HEAD'

export function updateHead(params) {
  return {
    type: SET_HEAD,
    showSchema: params.showSchema,
    title: params.title,
    description: params.description,
    canonicalUrl: params.canonicalUrl
  }
}