import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError((error.response)?error.response.data:error)
    });
}

const getPaymentUrl = (params ,onSuccess, onError = ()=>{}) => {
    post('getPaymentUrl', params, onSuccess, onError)
}

const getJHPaymentLinkData = (params ,onSuccess, onError = ()=>{}) => {
    post('getJHPaymentLinkData', params, onSuccess, onError)
}

const getJHPaymentUrl = (params ,onSuccess, onError = ()=>{}) => {
    post('getJHPaymentUrl', params, onSuccess, onError)
}

const api = {
    getPaymentUrl,
    getJHPaymentLinkData,
    getJHPaymentUrl
};
export default api;