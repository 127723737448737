import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Locations from './options/Locations'

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null, sessiontoken: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function (props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState(Locations);
    const loaded = React.useRef(false);
    const locationText = props.locationText || ''

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBf28azUj-fLePBiZ59pQZBTAZrtUej7Eo&libraries=places&fields=formatted_address,geometry,address_components&language=en',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    window.gm_authFailure = () => {
        autocompleteService.failed = true
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService({types: ['geocode']});
        }
        if (!autocompleteService.failed && autocompleteService.current) {
            if (locationText === '') {
                setOptions(Locations);
                //setOptions(value ? [value] : []);
                return undefined;
            }

            if (!autocompleteService.sessiontoken){
                autocompleteService.sessiontoken = new window.google.maps.places.AutocompleteSessionToken();
            }

            fetch({ input: locationText, sessionToken: autocompleteService.sessiontoken }, (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });

            return () => {
                active = false;
            };
        } else {
            if (locationText === '') {
                setOptions(Locations);
            } else {
                let searchText = locationText.toLowerCase().trim()
                const commaPos = searchText.indexOf(',')
                if(commaPos !==-1 ){
                    searchText = searchText.substr(0, commaPos)
                }

                let filteredLocations = []
                Locations.forEach(location => {
                    let arrayLocationText = location.name || location.country || ''
                    arrayLocationText = arrayLocationText.toLowerCase().trim();
                    
				    const index = arrayLocationText.indexOf(searchText);

                    if (index !== -1){
                        filteredLocations.push({...location, structured_formatting:{main_text_matched_substrings:[{length: searchText.length, offset: index}]}})
                    }
                    
                });
                setOptions(filteredLocations);
            }

            return () => {
                active = false;
            };
        }
    }, [value, locationText, fetch]);

    const handleSelectLocation = (event, newValue) => {
        if (newValue && newValue.place_id){
            const placeServices = new window.google.maps.places.PlacesService(document.getElementById('location'));
            
            placeServices.getDetails({ 
                placeId: newValue.place_id, 
                fields:['formatted_address','geometry', 'address_components'],
                sessiontoken: autocompleteService.sessiontoken
            }, (place, status) => {
                if (status === "OK" && place) {
                    autocompleteService.sessiontoken = null

                    let locationObj = {}

                    const addressComponents = place.address_components
                    if (addressComponents){
                        let streetAddress = addressComponents.find(addressComponent => addressComponent.types.includes('route'))
                        if (streetAddress){
                            locationObj.streetAddress = streetAddress.long_name
                        }   
                        let postalCode = addressComponents.find(addressComponent => addressComponent.types.includes('postal_code'));  
                        if (postalCode){
                            locationObj.postalCode = postalCode.long_name
                        }
                        let locality = addressComponents.find(addressComponent => addressComponent.types.includes('locality')); 
                        if (locality){
                            locationObj.locality = locality.long_name
                        }
                        let country = addressComponents.find(addressComponent => addressComponent.types.includes('country'));
                        if (country){
                            locationObj.country = country.long_name
                            locationObj.countryCode = country.short_name
                        }
                    }

                    if (place.geometry && place.geometry.location){
                        locationObj.lat = place.geometry.location.lat()
                        locationObj.lng = place.geometry.location.lng()
                    }
            
                    locationObj.text = place.formatted_address

                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);

                    props.handleLocationChange(locationObj)
                } else {
                }
            });
        } else if (newValue && newValue.lat && newValue.lng){
            const locationObj = {
                locality: newValue.name,
                country: newValue.country,
                countryCode: newValue.countryCode,
                lat: newValue.lat,
                lng: newValue.lng,
                text: ((newValue.name) ? newValue.name + ', ' : '') + newValue.country,
            }

            setValue(newValue);

            props.handleLocationChange(locationObj)
        }
    }

    //const childrenProps = props.children.props || {}

    return (
        <Autocomplete
            id="location"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description || ((option.name) ? option.name + ', ' : '') + option.country)}
            /*getOptionSelected={(option, value) => {
                const label = (typeof option === 'string' ? option : option.description || ((option.name) ? option.name + ', ' : '') + option.country)
                return label === value
            }}*/
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={locationText}
            //open
            /*PopperComponent={(params) => {
                return (
                    <Popper {...params} transition keepMounted/>
                )}}*/
            onChange={handleSelectLocation}
            onInputChange={(event, newInputValue) => {
                props.setLocationText(newInputValue);
            }}
            className={props.className}
            noOptionsText="Type a Location"
            renderInput={(params) => (
                <TextField 
                    {...params}
                    variant={props.variant}
                    fullWidth={props.fullWidth}
                    label={props.label}
                    margin={props.margin}
                    required={props.required}
                    placeholder={props.placeholder || 'Location'}
                />
            )}
            renderOption={(option) => {
                const structured_formatting = option.structured_formatting || {}
                const main_text = structured_formatting.main_text || option.name || option.country
                const secondary_text = structured_formatting.secondary_text || ((option.name) ? option.country : '')

                const matches = structured_formatting.main_text_matched_substrings || [{length: 0, offset: 0}];
                const parts = parse(
                    main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                                {secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}