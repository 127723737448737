import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Chip, Dialog, DialogActions, DialogContent, Fab, Grid, TextField } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { PersonalJourneyClasses } from '../../../styles/PersonalJourneyClasses';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { capitalize, getInternationalPhone, getNameFromEmail, showDateTimeFromString, validUrl } from '../../../utils/utils';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PublicIcon from '@material-ui/icons/Public';
import JHApi from "../../../utils/JHApi"
import Linkify from 'react-linkify';
import ArrowExpandIcon from '@material-ui/icons/AspectRatio';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 8
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export default function InterviewsList(props) {
    //const company = props.company || ''
    const interviews = props.interviews || []
    const disabled = props.disabled || false

    const journeyData = props.journeyData || {}

    const isAdmin = props.isAdmin
    const userId = props.userId

    const [interviewIndex, setInterviewIndex] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [textDialog, setTextDialog] = useState({open:false});

    const interviewToShow = interviews[interviewIndex] || {}

    const today = new Date().toISOString().substring(0, 10)

    /*const titleSearch = filterUrl(interviewToShow.title)
	const companySearch = filterUrl(interviewToShow.company)*/

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChangeInterviewIndex = newInex => () => {
        setInterviewIndex(newInex)
    }

    const handleNewInterview = () => {
        props.setObjectToEdit({
            company: interviewToShow.company,
            companyUrl: interviewToShow.companyUrl,
            title: interviewToShow.title,
            location: interviewToShow.location,
            status: interviewToShow.status,
            interviewerName: interviewToShow.interviewerName,
            interviewerEmail: interviewToShow.interviewerEmail,
            interviewerPhone: interviewToShow.interviewerPhone,
            type:"HR Interview",
            seniorityLevel: interviewToShow.seniorityLevel,
            interviewDate:today,
        })
    }

    /*const handleEditInterview = () => {
        props.setObjectToEdit(interviewToShow)
    }*/

    const handleEditLocation = () => {
        props.setObjectToEdit({...interviewToShow, fieldsType:'location'})
    }

    const handleEditInterviewer = () => {
        props.setObjectToEdit({...interviewToShow, fieldsType:'interviewer'})
    }

    const handleEditComment = () => {
        props.setObjectToEdit({...interviewToShow, fieldsType:'comment'})
    }

    const handleEditCSComment = () => {
        props.setObjectToEdit({...interviewToShow, fieldsType:'csComment'})
    }

    const handleEditStatus = () => {
        props.setObjectToEdit({...interviewToShow, fieldsType:'status', offer:props.offer})
    }

    /*const handleEditStatus = newStatus => {
        let paramsToPass = {newStatus, company:interviewToShow.company, interviewsSort: -1}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditInterviews()

        JHApi.editTalentInterviewStatus(paramsToPass,(response)=>{
            props.finishEditInterviews(response)
        }, (error) => {
            props.finishEditInterviews()
        })
    }*/

    /*const handleChangeRating = (name, value) => {
        let paramsToPass = {newData:{_id:interviewToShow._id, [name]: value}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditInterviews()

        JHApi.createEditTalentInterview(paramsToPass,(response)=>{
            props.finishEditInterviews(response)
        }, (error) => {
            props.finishEditInterviews()
        })
    }*/

    const showText = (field, text, edit=false) => () => {
        setTextDialog({open:true, text, edit, field})
    }

    const hideText = () => {
        setTextDialog({open:false})
    }

    const handleChangeText = e => {
        setTextDialog({...textDialog,newValue: e.target.value})
    };

    const handleEditTextDialog = e => {
        setTextDialog({...textDialog,edit: true, newValue: textDialog.text})
    };

    const handleSaveTextDialog = () => {
        let paramsToPass

        if (textDialog.field === 'feedback'){
            paramsToPass = {newData:{[textDialog.field]: textDialog.newValue}, company:interviewToShow.company}
        } else {
            paramsToPass = {newData:{_id:interviewToShow._id, [textDialog.field]: textDialog.newValue}}
        }

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditInterviews()

        if (textDialog.field === 'feedback'){
            JHApi.editTalentInterviews(paramsToPass,(response)=>{
                setTextDialog({open:false})
                props.finishEditInterviews(response)
            }, (error) => {
                /*if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }*/
                props.finishEditInterviews()
            })
        } else {
            JHApi.createEditTalentInterview(paramsToPass,(response)=>{
                setTextDialog({open:false})
                props.finishEditInterviews(response)
            }, (error) => {
                /*if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }*/
                props.finishEditInterviews()
            })
        }
    };

    const handleAddOffer = () => {
        props.setOfferToEdit({
            company:capitalize(interviewToShow.company),
            title:capitalize(interviewToShow.title),
            seniority:journeyData.seniorityLevel
        })
    }

    const handleEditOffer = offer => () => {
        props.setOfferToEdit(offer)
    }

    /*const statuses = [
        "Interviewing",
        "Got an Offer",
        "They said no",
        "I said no",
        "In process",
        "Waiting for feedback",
        "Last stage interview",
    ]*/

    const classes = useStyles();
    const pjClasses = PersonalJourneyClasses(); 

    const view = (window.innerWidth <= 768)?'MOBILE':'DESKTOP'

    return (
        <>
        <Dialog onClose={hideText} open={textDialog.open} fullWidth>
            <DialogContent>
                {textDialog.edit?<TextField margin="none" fullWidth variant="outlined" multiline rows={12}
                    value={textDialog.newValue} onChange={handleChangeText}/>:
                <Typography dir="auto" style={{whiteSpace:'pre-line'}}>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}>
                        {textDialog.text}
                    </Linkify>
                </Typography>}
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled} onClick={hideText} color="primary" size="small" aria-label="Cancel">
                    Close
                </Button>
                {textDialog.field&&!textDialog.edit&&<Button disabled={disabled} onClick={handleEditTextDialog} color="primary" variant="outlined" size="small" aria-label="Edit">
                    Edit
                </Button>}
                {textDialog.field&&textDialog.edit&&<Button disabled={disabled} onClick={handleSaveTextDialog} color="primary" variant="outlined" size="small" aria-label="Save">
                    Save
                </Button>}
            </DialogActions>
        </Dialog>

        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        {interviewToShow.company.charAt(0).toUpperCase()}
                    </Avatar>
                }
                action={<>
                    {/*(view==='DESKTOP')&&<SelectAsChip 
                        value={interviewToShow.status} 
                        onChange={handleEditStatus} 
                        list={statuses} 
                        disabled={disabled} 
                    />*/}
                    {(view==='DESKTOP')&&<Chip
                        size="small" 
                        label={interviewToShow.status}
                        onClick={handleEditStatus} 
                        color="primary" 
                        onDelete={handleEditStatus} 
                        deleteIcon={<EditIcon/>}
                        disabled={disabled}
                    />}
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </>}
                title={capitalize(interviewToShow.company)}
                subheader={capitalize(interviewToShow.title)}
            />
            <CardContent>
                <Grid container spacing={1}>
                    {(view==='MOBILE')&&<Grid item sm={12} xs={12}>
                        {/*<SelectAsChip 
                            value={interviewToShow.status} 
                            onChange={handleEditStatus} 
                            list={statuses} 
                            disabled={disabled} 
                        />*/}
                        <Chip
                            size="small" 
                            label={interviewToShow.status}
                            onClick={handleEditStatus} 
                            color="primary" 
                            onDelete={handleEditStatus} 
                            deleteIcon={<EditIcon/>}
                            disabled={disabled}
                        />
                    </Grid>}
                    {(interviewToShow.status==='They said no'||interviewToShow.status==='Waiting for feedback')&&<Grid item sm={12} xs={12} dir="auto" style={{display: 'flex', alignItems: 'flex-start'}}>
                        {interviewToShow.feedback?
                        <>
                            <Typography style={{whiteSpace:'pre-line', maxHeight:64, overflow:'hidden', marginBottom: 8}}>
                                <strong>Feedback:</strong> {interviewToShow.feedback}
                            </Typography>
                            <IconButton onClick={showText('feedback', interviewToShow.feedback || '')} disabled={disabled} size="small" color='primary' style={{margin: '0 8px'}}>
                                <ArrowExpandIcon fontSize='small' style={{transform: 'scaleX(-1)'}}/>
                            </IconButton>
                        </>:
                        <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={showText('feedback','', true)}>+ Feedback{(interviewToShow.status==='They said no')?(' (Ask Feedback)'):''}</Typography>}
                    </Grid>}
                    {props.offer&&<Grid item sm={12} xs={12}>
                        <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditOffer(props.offer)}>Offer</Typography>
                    </Grid>}
                    {(interviewToShow.status==='Got an Offer' && !props.offer)&&<Grid item sm={12} xs={12}>
                        <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleAddOffer}>Add contract for review / tips</Typography>
                    </Grid>}
                    <Grid item sm={6} xs={12}>
                        <Typography>{showDateTimeFromString(interviewToShow.interviewDate, false)}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Typography>{interviewToShow.type}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop:8}}>
                            <Fab size="small" className={pjClasses.contactButton} href={'https://maps.google.com/maps?q='+interviewToShow.location} color="primary" target='_blank' rel="noopener noreferrer" disabled={!interviewToShow.location}>
                                <LocationOnIcon className={pjClasses.contactButtonIcon} fontSize="small" />
                            </Fab>
                            <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditLocation}>{interviewToShow.location||'Add location'}</Typography>
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop:8}}>
                            <Fab size="small" className={pjClasses.contactButton} href={'mailto:'+interviewToShow.interviewerEmail} color="primary" target='_blank' rel="noopener noreferrer" disabled={!interviewToShow.interviewerEmail}>
                                <EmailIcon className={pjClasses.contactButtonIcon} fontSize="small" />
                            </Fab>
                            <Fab size="small" className={pjClasses.contactButton} href={'tel:'+getInternationalPhone(interviewToShow.interviewerPhone)} color="primary" target='_blank' rel="noopener noreferrer" disabled={!getInternationalPhone(interviewToShow.interviewerPhone)}>
                                <PhoneIcon className={pjClasses.contactButtonIcon} fontSize="small" />
                            </Fab>
                            <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditInterviewer}>{interviewToShow.interviewerName||'Add Interviewer Data'}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>
                <Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', margin: '0 4px'}}
                    onClick={handleNewInterview}
                >+ Interview</Button>
                {/*<Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', margin: '0 4px'}}
                    onClick={handleEditInterview}
                >Edit</Button>*/}
                <Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', margin: '0 4px'}}
                    onClick={handleExpandClick}
                >Show {expanded?'Less':'More'}</Button>
                {(interviews.length>1)&&<>
                    <div style={{flex: '1 1 0%'}}></div>
                    <IconButton
                        onClick={handleChangeInterviewIndex(interviewIndex-1)}
                        disabled={disabled || interviewIndex===0}
                    >
                        <ChevronLeftIcon/>
                    </IconButton>
                    <Typography>{interviews.length-interviewIndex}/{interviews.length}</Typography>
                    <IconButton
                        onClick={handleChangeInterviewIndex(interviewIndex+1)}
                        disabled={disabled || interviewIndex===interviews.length-1}
                    >
                        <ChevronRightIcon/>
                    </IconButton>
                </>}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container spacing={1}>
                        {/*<Grid item sm={12} xs={12} dir="auto" style={{display: 'flex', alignItems: 'flex-start'}}>
                            {interviewToShow.moreAboutInterview?
                            <>
                                <Typography style={{whiteSpace:'pre-line', maxHeight:64, overflow:'hidden', marginBottom: 8}}>
                                    {interviewToShow.moreAboutInterview}
                                </Typography>
                                <IconButton onClick={showText('moreAboutInterview', interviewToShow.moreAboutInterview || '')} disabled={disabled} size="small" color='primary' style={{margin: '0 8px'}}>
                                    <ArrowExpandIcon fontSize='small' style={{transform: 'scaleX(-1)'}}/>
                                </IconButton>
                            </>:
                            <Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={showText('moreAboutInterview','', true)}>{'+ More Info About Interview(optional)'}</Typography>}
                        </Grid>*/}
                        <Grid item sm={12} xs={12} dir="auto">
                            {/*<Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditComment}>{interviewToShow.reviewFreeText?'Edit ':'Add '}<strong>Comment</strong></Typography>
                            <Typography dir="auto" style={{whiteSpace:'pre-line'}}>
                                {interviewToShow.reviewFreeText}
                            </Typography>*/}
                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                {interviewToShow.reviewFreeText}
                            </div>
                            {(interviewToShow.clientSummary||[]).map(summary => (
                                <div key={summary.date}>
                                    <strong>
                                        {summary.isAdmin?(getNameFromEmail(summary.by) + ' - '):''}
                                        {showDateTimeFromString(summary.date, false)}
                                    </strong><br/>
                                    <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary.content}</span>
                                </div>
                            ))}
                            <div style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditComment}><strong>Add Client Summary</strong></div>
                        </Grid>
                        <Grid item sm={12} xs={12} dir="auto">
                            {/*isAdmin?<Typography style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditCSComment}>{interviewToShow.csComment?'Edit ':'Add '}<strong>Summary</strong></Typography>:
                            <Typography><strong>Summary</strong></Typography>}
                            <Typography dir="auto" style={{whiteSpace:'pre-line'}}>
                                {interviewToShow.csComment}
                            </Typography>*/}
                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                {interviewToShow.csComment}
                            </div>
                            {(interviewToShow.managerSummary||[]).map(summary => (
                                <div key={summary.date}>
                                    <strong>
                                        {summary.isAdmin?(getNameFromEmail(summary.by) + ' - '):''}
                                        {showDateTimeFromString(summary.date, false)}
                                    </strong><br/>
                                    <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary.content}</span>
                                </div>
                            ))}
                            {(isAdmin)&&<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={handleEditCSComment}><strong>Add Manager Summary</strong></div>}
                        </Grid>
                        {/*<Grid item sm={6} xs={12}>
                            <Typography component="legend">General</Typography>
                            <Rating
                                name={"ratingGeneral"+interviewToShow._id} size="small" value={interviewToShow.ratingGeneral || 0}
                                onChange={(event, newValue) => {
                                    handleChangeRating('ratingGeneral', newValue);
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography component="legend">Interview</Typography>
                            <Rating
                                name={"ratingInterview"+interviewToShow._id} size="small" value={interviewToShow.ratingInterview || 0}
                                onChange={(event, newValue) => {
                                    handleChangeRating('ratingInterview', newValue);
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography component="legend">Interviewer</Typography>
                            <Rating
                                name={"ratingInterviewer"+interviewToShow._id} size="small" value={interviewToShow.ratingInterviewer || 0}
                                onChange={(event, newValue) => {
                                    handleChangeRating('ratingInterviewer', newValue);
                                }}
                            />
                        </Grid>*/}
                        <Grid item sm={12} xs={12}>
                            {(interviewToShow.companyUrl) &&
                                <div className={"cardFooterLink ltr"}>
                                    <span className="icon">
                                        <PublicIcon/>
                                    </span>
                                    <a className="link" target="_blank" rel="noopener noreferrer" href={validUrl(interviewToShow.companyUrl)}>{interviewToShow.companyUrl}</a>
                                    <span className="arrow">
                                        <ChevronRightIcon/>
                                    </span>
                            </div>}
                            {/*interviewToShow.company &&
                                <div className="cardFooterLink ltr">
                                    <span className="icon">
                                        <SearchIcon/>
                                    </span>
                                    <a className="link" target="_blank" rel="noopener noreferrer" href={"https://jobhunt.co.il/interviews/" + companySearch + "-interviews-SRCH_Q0," + companySearch.length + "?utm_source=JobSwipe&utm_campaign=JobSwipe_Interviews"}>Look for what is being asked in the company</a>
                                    <span className="arrow">
                                        <ChevronRightIcon/>
                                    </span>
                                </div>}
                            <div className="cardFooterLink ltr">
                                <span className="icon">
                                    <SearchIcon/>
                                </span>
                                <a className="link" target="_blank" rel="noopener noreferrer" href={"https://jobhunt.co.il/interviews/" + titleSearch + "-interviews-SRCH_Q0," + titleSearch.length + "?utm_source=JobSwipe&utm_campaign=JobSwipe_Interviews"}>Search what is being asked about title</a>
                                <span className="arrow">
                                    <ChevronRightIcon/>
                                </span>
                            </div>*/}
                        </Grid>
                    </Grid>
                </CardContent>
            </Collapse>
        </Card>
        </>
    )
}