import HistApi from "./HistApi";

const WEEK_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7

export const getLocalTags = () => {
    const tags = JSON.parse(localStorage.getItem('words'));
    if(tags && tags.data && tags.date){
        if ((Date.now() - tags.date) > WEEK_IN_MILLISECONDS){
            HistApi.getTagsData((results)=>{
                let tags = {data:results, date:Date.now()}
                localStorage.setItem("words", JSON.stringify(tags));
            })
        }
        return tags.data
    } else {
        HistApi.getTagsData((results)=>{
            let tags = {data:results, date:Date.now()}
            localStorage.setItem("words", JSON.stringify(tags));
        })  

        return []
    }
};