import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../styles/Histogram.css'
import HistTabs from '../components/HistTabs';
import { push } from 'connected-react-router';
import { CANDIDATE_ACTIVITY_TABS, RECRUITER_ACTIVITY_TABS, CANDIDATE_ACTIVITY_SUB_TYPES, RECRUITER_ACTIVITY_SUB_TYPES } from '../utils/utils';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { closeDialog } from '../redux/actions/MainDialog';
import { toggleLoginDialog } from '../redux/actions/authActions';
import AddCompany from '../components/review/AddCompany';
import { fetchData, setCompanies, setHistogramWithData } from '../redux/actions/histogramJobs';
import { FormControlLabel, TextField } from '@material-ui/core';
import { getLocationObject } from '../redux/actions/searchJobs';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import { addNewAlertToUser, addNewObject, RemoveAlertToUser } from '../redux/actions/selectedJob';
import { setAutoRefreshList } from '../redux/actions/histogram';

class HistogramHeader extends Component {
    state = {
        addCompany: false
    }

    handleCloseAddCompany = (results) => {
        this.setState({addCompany:false})

        if (results){
            this.props.dispatch(setHistogramWithData(results, results.id))
        }
    }

    handleAddCompany = () => {
        if(this.props.isAuthed){
            this.setState({addCompany:true})
        } else {
            this.props.dispatch(toggleLoginDialog(true))
        }
    }

    filterCompanies = (event) => {
        this.setState({
            companyText: event.target.value
        })
        this.props.dispatch(setCompanies(event.target.value))
    }    

    handleClose = () => {
        this.props.dispatch(closeDialog())
    }

    handleAlert = (e) => {
        const { isAuthed, dispatch, queryString, locationObj, histogramType, activeAlert } = this.props
        if(isAuthed) {
            if(e.target.checked){
                dispatch(addNewAlertToUser(queryString, locationObj, histogramType, window.location.href, e.currentTarget))
            } else {
                if(activeAlert && activeAlert._id){
                    dispatch(RemoveAlertToUser(activeAlert._id, histogramType))
                }
            }
        } else {
            dispatch(toggleLoginDialog(true))
        }
    }

    handleNewObject = () => {
        const { dispatch } = this.props
        dispatch(addNewObject())
    }

    handleRefetchPersonalJourneys = () => {
        const { dispatch } = this.props
        dispatch(fetchData())
    }

    handleChangAutoRefresh = () => {
        const { dispatch, autoRefreshList } = this.props
        dispatch(setAutoRefreshList(!autoRefreshList))
    }

    /*incJobLimit = () => {
        const { dispatch } = this.props
        dispatch(showPayment(INC_JOBS_LIMIT, 'recruiter', 'increaseJobsLimitButton'))
    }*/

    goToRoute = (route) => {
        this.props.dispatch(push(route));
    }

    getHistTitle = () => {
        const {total, queryString, location, histogramType, histogramSubType, lookingforwork, isFiltered, filteredJobs, noMore} = this.props

        const count = (isFiltered || histogramType === 'jobhuntUsers') ? filteredJobs.length : total

        if(count === undefined){
            return null
        }

        if (histogramType === 'profile' || histogramType === 'myJobs'){
            return null;
        }

        if (histogramType === 'jobhuntUsers'){
            return count + ' Clients';
        }

        let title = (isFiltered) ? 'filtered ' : ''

        title = title + histogramType

        if(location || queryString){
            title = (isFiltered) ? 'filtered ' : ''
            if (queryString){
                title = title + queryString + ' ';
            }
            title = title + histogramType
            if (location){
                title = title + ' near ' + location;
            }
            title = title.charAt(0).toUpperCase() + title.slice(1);
        } else if (histogramType === 'activity'){
            const tabs = (lookingforwork) ? CANDIDATE_ACTIVITY_TABS : RECRUITER_ACTIVITY_TABS
            const keys = (lookingforwork) ? CANDIDATE_ACTIVITY_SUB_TYPES : RECRUITER_ACTIVITY_SUB_TYPES;

            const currntTab = keys.indexOf(histogramSubType)

            if(tabs[currntTab]){
                title = tabs[currntTab] 
            }
        } else if(histogramType === 'saveditems') {
            if(lookingforwork) {
                title = 'Saved jobs'
            } else {
                title = 'Saved resumes' 
            }
        }

        return ((!noMore && isFiltered)?'more than ':'') + count.toLocaleString() + ' ' + title
    }

    render(){
        const {filteredJobs, isFetching, histogramType, isNew, joblimit, histogramSubType, lookingforwork, autoRefreshList,
            isAuthed, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec, view, displayAlerts, activeAlert, userEmail, anchorEl} = this.props

        const histTitle = this.getHistTitle();

        let activeJobs = [];

        if (histogramType === 'myJobs'){
            activeJobs = filteredJobs.filter(job => job.jobstatus === 'A')
        }     

        const open = Boolean(anchorEl)

        return (
            <React.Fragment>
                {(displayAlerts && !isFetching && (histogramType === 'resumes' || histogramType === 'jobs')) && <AppBar style={{ zIndex: 1, flexGrow: 1}} position={'sticky'}>
                    <Toolbar>
                        <Box mr={2}>
                            {activeAlert ? <NotificationsActiveIcon/> : <NotificationsIcon/>}
                        </Box>
                        <Box>
                            {activeAlert ? 'Alerts are on for this search' : 'Turn on email alerts for this search'}
                        </Box>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                                <div style={{padding: '20px 20px 12px', width: '260px'}}>
                                    <div style={{color: 'rgba(0,0,0,0.87)', marginBottom: '8px', marginRight: '24px'}}>
                                        {(view === 'MOBILE') ? 'Customize alerts in the profile menu' : 'Manage alerts in the profile menu'}
                                    </div>
                                    <div style={{fontSize: '14px', lineHeight: '20px', color: '#666'}}>
                                        Sending daily to {userEmail}
                                    </div>
                                    <div style={{textAlign: "right", marginTop: '15px', fontSize: '14px'}}>
                                        <Button onClick={this.handleClose} style={{color: '#4285f4'}}>
                                            Got it
                                        </Button>
                                    </div>
                                </div>
                        </Popover>
                        <Box position={'sticky'} left={'100%'}>
                            <Switch checked={(activeAlert!==undefined)} edge='end' color={'default'} onChange={this.handleAlert}/>    
                        </Box>
                    </Toolbar>
                </AppBar>}
                <HistTabs histogramType={histogramType} histogramSubType={histogramSubType} lookingforwork={lookingforwork} goToRoute={this.goToRoute} isFetching={isFetching}  isAuthed={isAuthed}
                    hasJobsSuggestion={hasJobsSuggestion} hasMatchesCan={hasMatchesCan} hasApplicants={hasApplicants} hasMatchesRec={hasMatchesRec}/>
                {(!isFetching && !isNew && histogramType === 'myJobs')&&<div className="histTitle">
                    {(activeJobs.length < joblimit)&&<React.Fragment>
                        <Button aria-label="Add Job" variant="contained" color="primary" size="small" onClick={this.handleNewObject} style={{marginBottom:8, paddingRight:14}}>
                            <AddIcon style={{marginRight: 4}} />
                            Add a job
                        </Button><br/>
                    </React.Fragment>}
                    <Typography variant={(activeJobs.length < joblimit)?"caption":"inherit"} style={{display:'block'}}>
                        <span>You can </span>
                        <span>add up</span>
                        <span> to {joblimit} active jobs (</span>
                        {(activeJobs.length === joblimit)?
                            <span>Max Reached</span>:
                            <span>{joblimit-activeJobs.length} left</span>
                        }
                        <span>)</span>
                    </Typography>
                    {/*(activeJobs.length >= joblimit)&&<React.Fragment>
                        <Button aria-label="Increase limit" variant="contained" color="secondary" size="small" onClick={this.incJobLimit} style={{marginTop:8, paddingRight:14}}>
                            <AddIcon style={{marginRight: 4}}/>
                            Increase limit
                        </Button>
                    </React.Fragment>*/}
                </div>}
                {/*(!isFetching && !isNew && histogramType === 'jobhuntUsers')&&<div className="histTitle">
                    <Button aria-label="Add Job" variant="contained" color="primary" size="small" onClick={this.handleRefetchPersonalJourneys}>
                        Refetch Clients
                    </Button><br/>
                </div>*/}
                {(histogramType === 'companies')&&<div className="histTitle">
                    <TextField
                        className="searchCompanies"
                        fullWidth
                        margin="none"
                        type="search"
                        value={this.state.companyText || ''}
                        onChange={this.filterCompanies}
                        placeholder="Search Companies"
                        variant="outlined"
                    />
                    <Button aria-label="Add a company" variant="contained" color="primary" size="small" onClick={this.handleAddCompany} style={{paddingRight:14}}>
                        <AddIcon style={{marginRight: 4}} />
                        Add a company
                    </Button>
                    <AddCompany
                        open={this.state.addCompany}
                        onClose={this.handleCloseAddCompany}
                    />
                </div>}
                {(!isFetching && filteredJobs.length === 0) && <div className="histTitle" style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <div>{histTitle}</div>
                    {(!isFetching && !isNew && histogramType === 'jobhuntUsers')&&
                    <div><FormControlLabel
                        style={{margin:0}}
                        control={<Switch
                            checked={autoRefreshList}
                            onChange={this.handleChangAutoRefresh}
                            name="refresh"
                            color="primary"
                            size='small'
                        />}
                        label="Refresh"
                    /></div>}
                </div>}
                {(filteredJobs.length > 0 && histTitle)&&<div className="histTitle" style={{display: 'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <div>{histTitle}</div>
                    {(!isFetching && !isNew && histogramType === 'jobhuntUsers')&&
                    <div><FormControlLabel
                        style={{margin:0}}
                        control={<Switch
                            checked={autoRefreshList}
                            onChange={this.handleChangAutoRefresh}
                            name="refresh"
                            color="primary"
                            size='small'
                        />}
                        label="Refresh"
                    /></div>}
                </div>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { filterJobs } = state
    const { filteredJobs, isFetching, total, noMore } = state.histogramJobs;
    const { queryString } = state.searchJobs;
    const { histogramType, histogramSubType, autoRefreshList } = state.histogramData;
    const { alerts, isAuthed, userInfo } = state.authUser;
    const { isNew, isFetching: isFetchingJob } = state.selectedJob;
    const { anchorEl: anchorElParam, isShowed, modalType } = state.MainDialog

    const anchorEl = (isShowed && modalType === "alertPopUp" && anchorElParam) ? anchorElParam : null

    const joblimit = (isAuthed && userInfo.isPro) ? 20 : (isAuthed && userInfo.joblimit) ? userInfo.joblimit : 0
    const lookingforwork = (isAuthed) ? userInfo.lookingforwork : true;
    const userEmail = (isAuthed && userInfo) ? userInfo.email : ''

    const {hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec} = userInfo

    let isFiltered = false

    for (let value of Object.values(filterJobs)) {
        if (value.filtered && value.filtered.length>0){
            isFiltered = true
        }
    }

    const {companyText} = filterJobs

    let locationObj = getLocationObject(state)
    let location = locationObj.text;

    let activeAlert
    if(alerts) {
        if (locationObj.lat && locationObj.lng){
            activeAlert = alerts.find((r) => { 
                return r.alertQuery === queryString &&
                    r.alertHistogramType === histogramType && 
                    r.alertLocationLat === locationObj.lat && 
                    r.alertLocationLng === locationObj.lng
            })
        } else {
            activeAlert = alerts.find((r) => { 
                return r.alertQuery === queryString &&
                    r.alertHistogramType === histogramType && 
                    !r.alertLocationName && !r.alertLocationCountry
            })
        }
    }

    const displayAlerts = (location || queryString)
    
    return {
        filteredJobs, isFetching:(isFetching || isFetchingJob), total, queryString, location, locationObj, histogramType, histogramSubType, isNew, joblimit, 
        lookingforwork, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec, isAuthed, isFiltered, noMore, displayAlerts, activeAlert, userEmail, anchorEl, companyText,
        autoRefreshList
    }
}

export default connect(mapStateToProps)(HistogramHeader)