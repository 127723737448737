import React, { Component } from 'react'
import { connect } from 'react-redux'
import {createCompanyIfNeeded} from '../redux/actions/companies'
import Avatar from '@material-ui/core/Avatar'
import '../styles/UserAvatar.css'
import { getNameLetters } from '../utils/utils';
import Skeleton from '@material-ui/lab/Skeleton'

class UserAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avatarSrc: props.avatarSrc
        };
    }

    componentDidMount() {
        const { dispatch,firstName,lastName } = this.props
        const letters = this.getLetters(firstName, lastName)
        dispatch(createCompanyIfNeeded(letters))
    }

    componentDidUpdate(prevProps){
        if (this.props.avatarSrc !== prevProps.avatarSrc) {
            this.setState({
                avatarSrc: this.props.avatarSrc
            })
        }
    }

    getLetters(firstName, lastName){
        return getNameLetters(firstName, lastName)
    }

    addDefaultSrc(){
        const { firstName, lastName} = this.props
        const letters = this.getLetters(firstName, lastName)

        const { dispatch } = this.props
        dispatch(createCompanyIfNeeded(letters))

        this.setState({
            avatarSrc: 'https://jobswipe.co/assets/images/def_avatar.jpg'
        })
    }

    render() {
        const defAvatarUrl = 'https://jobswipe.co/assets/images/def_avatar.jpg'
        const { firstName, lastName, margin, onClick, positionAbsolute, companies, header, pro, fetchingData} = this.props
    
        const avatarSrc = this.state.avatarSrc
        const letters = this.getLetters(firstName, lastName)

        const className = 'clAvatar' + ((margin) ? ' margin' : '') + ((positionAbsolute) ? ' positionAbsolute' : '') + ((header) ? ' header' : '') + ((pro) ? ' pro' : '')

        const backgroundColor = (companies[letters]) ? companies[letters].colors.light : '#396a92'

        return (
            <React.Fragment>
                {(fetchingData)?<Skeleton className="logoSkeleton" variant="circle" animation="wave"/>:
                <React.Fragment>
                    {(!avatarSrc || avatarSrc===defAvatarUrl)&&<Avatar onClick={onClick} className={className} style={{backgroundColor}}>{letters}</Avatar>}
                    {(avatarSrc && avatarSrc!==defAvatarUrl)&&<img onClick={onClick} onError={() => this.addDefaultSrc()} alt={firstName} src={avatarSrc} className={className}/>}
                </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { companies } = state;

    return {
        companies
    }
}

export default connect(mapStateToProps)(UserAvatar)