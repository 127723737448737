import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ActivityIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';

class ActivityNav extends Component {
    goToActivity = () => {    
        const { dispatch } = this.props
        dispatch(push('/activity'));
    }

    render() {
        const { isAuthed, userInfo, isFetching, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec, view } = this.props
        let hideBadge = true

        if (userInfo){
            if (userInfo.lookingforwork){
                hideBadge = !(hasJobsSuggestion || hasMatchesCan)
            } else {
                hideBadge = !(hasApplicants || hasMatchesRec)
            }
        }

        return (
            <React.Fragment>
                {(isAuthed && userInfo && view === 'MOBILE')&&
                <IconButton color="inherit" aria-label="Activity" disabled={isFetching}  onClick={this.goToActivity}>
                    <ActivityIcon style={{ fontSize: 32 }} color="primary" />
                    {(!hideBadge)&&<div className="JSBadge"></div>}
                </IconButton>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;
    const { hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec } = userInfo
    const isFetching = state.histogramJobs.isFetching

    return {
        isAuthed, userInfo, isFetching, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec
    }
}

export default connect(mapStateToProps)(ActivityNav)