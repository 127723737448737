import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
//import { createLogger } from 'redux-logger'
import rootReducer from './reducers/reducers'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
// import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();

export function configureStore(preloadedState) {
    return createStore(
        connectRouter(history)(rootReducer), // new root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunkMiddleware,
                //createLogger()
            ),
        ),
    )
}