import {REQUEST_LOCATIONS, RECEIVE_LOCATIONS} from '../actions/locations'

export function locations(
    state = {
            isFetching: false,
            locations: [{
                    "country": "Israel",
                    "name": "",
                    "lat": "32.109333",
                    "lng": "34.855499",
                    "index": 0
                },
                {
                    "country": "Israel",
                    "name": "Tel Aviv",
                    "lat": "32.0853",
                    "lng": "34.781768",
                    "index": 1
                },
                {
                    "country": "Israel",
                    "name": "Jerusalem",
                    "lat": "31.768319",
                    "lng": "35.21371",
                    "index": 2
                },
                {
                    "country": "Israel",
                    "name": "Haifa",
                    "lat": "32.794046",
                    "lng": "34.989571",
                    "index": 3
                },
                {
                    "country": "Israel",
                    "name": "Ramat Gan",
                    "lat": "32.068424",
                    "lng": "34.824785",
                    "index": 4
                },
                {
                    "country": "Israel",
                    "name": "Ashkelon",
                    "lat": "31.668789",
                    "lng": "34.574252",
                    "index": 5
                },
                {
                    "country": "Israel",
                    "name": "Rehovot",
                    "lat": "31.892773",
                    "lng": "34.811272",
                    "index": 6
                },
                {
                    "country": "Israel",
                    "name": "Petah Tikva",
                    "lat": "32.084041",
                    "lng": "34.887762",
                    "index": 7
                },
                {
                    "country": "Israel",
                    "name": "Herzelia Pituach",
                    "lat": "32.174202",
                    "lng": "34.802932",
                    "index": 8
                },
                {
                    "country": "Israel",
                    "name": "Ramat HaHayal",
                    "lat": "32.113373",
                    "lng": "34.836637",
                    "index": 9
                },
                {
                    "country": "Israel",
                    "name": "Beit Shemesh",
                    "lat": "31.747041",
                    "lng": "34.988099",
                    "index": 10
                },
                {
                    "country": "Israel",
                    "name": "Netanya",
                    "lat": "32.321458",
                    "lng": "34.853196",
                    "index": 11
                },
                {
                    "country": "Israel",
                    "name": "Rishon LeTsiyon",
                    "lat": "31.973001",
                    "lng": "34.792501",
                    "index": 12
                },
                {
                    "country": "Israel",
                    "name": "Bat Yam",
                    "lat": "32.013186",
                    "lng": "34.748019",
                    "index": 13
                },
                {
                    "country": "Israel",
                    "name": "Kfar Saba",
                    "lat": "32.178195",
                    "lng": "34.90761",
                    "index": 14
                },
                {
                    "country": "Israel",
                    "name": "Raanana",
                    "lat": "32.184781",
                    "lng": "34.871326",
                    "index": 15
                },
            ]
        },
    action
) {
    switch (action.type) {
        case REQUEST_LOCATIONS:
            return Object.assign({}, state, {
                isFetching: true,
            })
        case RECEIVE_LOCATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                locations: action.locations,
            })
        default:
            return state
    }
}