import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createCompanyIfNeeded } from '../redux/actions/companies'

class UniqueColor extends Component {
    componentDidMount() {
        const { dispatch, value } = this.props

        if(value){
            dispatch(createCompanyIfNeeded(value))
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.value !== prevProps.value) {
            const { dispatch, value } = this.props
            
            if(value){
                dispatch(createCompanyIfNeeded(value))
            }
        }
    }

    render() {
        const { companies, value} = this.props

        const backgroundColor = (companies[value]) ? companies[value].colors.dark : '#396a92'

        return (
            <React.Fragment>
                {React.cloneElement(this.props.children, {backgroundColor})}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { companies } = state;

    return {
        companies
    }
}

export default connect(mapStateToProps)(UniqueColor)