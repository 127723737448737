import { Button, TextField } from '@material-ui/core';
import LocationField from './LocationField';
import React from 'react';

export default function (props) {
    const {top, centered, keyWordPlaceholder, queryString, locationText, setQueryString, setLocationObj, setLocationText, handleSearch, histogramType,
        email, setEmail} = props;

    const style = (!centered) ? {top:top} : undefined

    return (
        <div className={"searchSection" + ((centered)?" centered":"")} style={style}>
            <div className="searchSectionContent">
                {(histogramType === 'jobhuntUsers')?
                    <React.Fragment>
                        <TextField
                            className="keywordsField"
                            id="keyWords"
                            type="search"
                            value={queryString}
                            onChange={setQueryString}
                            placeholder="Name / Title"
                            variant="outlined"
                            style={{marginRight:8}}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                        <TextField
                            className="keywordsField"
                            id="email"
                            type="search"
                            value={email}
                            onChange={setEmail}
                            placeholder='Email'
                            variant="outlined"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                    </React.Fragment>:
                    <React.Fragment>
                        <TextField
                            className="keywordsField"
                            id="keyWords"
                            type="search"
                            value={queryString}
                            onChange={setQueryString}
                            placeholder={keyWordPlaceholder}
                            variant="outlined"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                        />
                        <span className="nearLabel">Near</span>
                        <LocationField 
                            handleLocationChange={setLocationObj} 
                            locationText={locationText} 
                            setLocationText={setLocationText} 
                            variant="outlined" 
                            className="locationField" 
                        />
                    </React.Fragment>
                }
                <Button className="searchButtonD" variant="contained" color="primary" onClick={handleSearch} aria-label="Search">
                    Search
                </Button>
            </div>
        </div>
    )
}