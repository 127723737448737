import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showDialog } from '../../redux/actions/MainDialog'
import HistogramC from '../HistogramC'
import { push } from 'connected-react-router'

class JHPC extends Component {
    showMessageDialog = (title, content) => {
        const {dispatch} = this.props
        dispatch(showDialog(title, content))
    }

    changeUrl = (newRoute) => {    
        const { dispatch } = this.props
        dispatch(push(newRoute));
    }

    render() {
        const { isAdmin, isJHAdmin, email, userId } = this.props

        return (
            <>
                {(isAdmin || isJHAdmin)?
                <>{React.cloneElement(this.props.children, { showMessageDialog: this.showMessageDialog, changeUrl: this.changeUrl, userEmail:email, userId})}</>
                :<HistogramC histogramType={'main'} />}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;

    const isAdmin = (userInfo && userInfo.isAdmin)
    const isJHAdmin = (userInfo && userInfo.isJHAdmin)

    return {
        isAuthed, isAdmin, isJHAdmin, email:userInfo.email, userId:userInfo._id
    }
}

export default connect(mapStateToProps)(JHPC)