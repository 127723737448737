import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import JHApi from "../../../utils/JHApi"
import { FIELD_TYPES } from '../../../utils/utils';

export default function ProjectsDialog(props) {
    const objectToEdit = props.objectToEdit
    const disabled = props.disabled || false
    //const journeyData = props.journeyData || {}

    const isAdmin = props.isAdmin
    const userId = props.userId
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [newData, setNewData] = useState({});

    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        setError()
        setNewData({...objectToEdit})

        if (objectToEdit){
            setEditId(objectToEdit._id||-1)
        } else {
            setEditId()
        }
    }, [objectToEdit]);

    const handleCancel = () => {
        props.setObjectToEdit()
    }

    /*const handleDelete = () => {
        setIsDelete(true)
    }*/

    const handleDeleteYes = () => {
        let paramsToPass = {id:editId}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        /*props.startEdit()

        JHApi.deleteTalentProject(paramsToPass,(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEdit(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEdit()
        })*/
    }

    const handleDeleteNo = () => {
        setIsDelete(false)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        let paramsToPass = {newData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEdit()

        JHApi.createEditTalentProject(paramsToPass,(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEdit(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEdit()
        })
    }

    const fields = [
        { name: 'name', label: 'Project name', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true}, 
        { name: 'dateStart', label: 'Start Date', defaultValue: '', type:FIELD_TYPES.DATE2, sm:12, required:true}, 
        { name: 'dateEnd', label: 'End Date', defaultValue: '', type:FIELD_TYPES.DATE2, sm:12}, 
        { name: 'gitLink', label: 'Link to git', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        { name: 'totalHours', label: 'Total hours', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'number'},
        { name: 'totalDays', label: 'Total days', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'number'},
        { name: 'summary', label: 'Short summary, code level', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:8, sm:12},
    ];

    const action = isDelete?'Delete':((editId===-1)?'Add':'Edit')

    return (
        <Dialog onClose={handleCancel} open={(editId!==undefined)} fullWidth maxWidth="sm">
            {<DialogTitle>{action} Project</DialogTitle>}
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {isDelete?<Typography>Are you sure?</Typography>:
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>}
                </DialogContent>
                <DialogActions>
                    {isDelete?
                    <>
                        <Button disabled={disabled} onClick={handleDeleteNo} color="primary" size="small" aria-label="No">
                            No
                        </Button>
                        <Button disabled={disabled} onClick={handleDeleteYes} color="primary" variant="outlined" size="small" aria-label="Yes">
                            Yes
                        </Button>
                    </>:<>
                        {/*(editId!==-1)&&<Button disabled={disabled} onClick={handleDelete} color="primary" size="small" aria-label="Delete">
                            Delete
                        </Button>*/}
                        <div style={{flex: "1 1 0%"}}></div>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            Save
                        </Button>
                    </>}
                </DialogActions>
            </form>
        </Dialog>
    )
}