export const getToken = () => localStorage.getItem("jwt-token");
export const saveToken = token => localStorage.setItem("jwt-token", token);
export const removeToken = () => localStorage.removeItem("jwt-token");
export const getAndroidToken = () => localStorage.getItem("androiddevice");
export const getComputerToken = () => localStorage.getItem("webdevice");

export const getUserInfo = () => {
    try {
        const token = getToken();
        let payload;
        if (token) {
            payload = token.split(".")[1];
            payload = window.atob(payload);
            return JSON.parse(payload);
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
    }
};

export const parseToken = token => {
    try {
        let payload;
        payload = token.split(".")[1];
        payload = window.atob(payload);
        return JSON.parse(payload);
    } catch (e) {
        console.log(e);
    }
};

export const formatErrors = status => {
    let err = {};
    if (status.code === 11000) {
        err.email = "Duplicate email!";
    } else {
        Object.keys(status.errors).map(key => {
            return err[key] = status.errors[key].message;
        });
    }
    return err;
};

export const getLocation = async () => {
    if (navigator.geolocation) {
        try {
            const position = await getCurrentPosition();

            return {err:null, userLocation:{lat:position.coords.latitude, lng:position.coords.longitude}}
        } catch (error) {
            var errors = {
                1: 'Permission denied',
                2: 'Position unavailable',
                3: 'Request timeout'
            };

            return {err:"Error: " + errors[error.code]}
        }
    } else {
        return {err:"Geolocation is not supported by this browser"}
    }
};

function getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}
