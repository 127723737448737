import React, { Fragment } from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { capitalize, displayCash, displayPosition, getDateText, getNameFromEmail, showDateTimeFromString } from '../utils/utils';
import FilterListIcon from '@material-ui/icons/FilterList';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      /*'&.checkIn':{
        backgroundColor: '#fafa07'
      },
      '&.checkIn&:nth-of-type(odd)':{
        backgroundColor: '#e8e801'
      }*/
    },
}))(TableRow);

export default function JSData(props) {
    const data = props.data || []
    const cells = props.cells || []

    return (        
        <Table stickyHeader size="small">
            <TableHead color='primary'>
                <TableRow>
                    {props.withIndex&&<StyledTableCell
                        style={{minWidth:1, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                    >
                    </StyledTableCell>}
                    {cells.map(cell => (
                        <Fragment key={cell.id}>
                            {(!cell.hidden)&&<StyledTableCell
                                align={cell.align}
                                style={{minWidth:cell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                                <div style={{display: 'flex',justifyContent: 'space-between'}}>
                                    <div>{cell.label}</div>
                                    {cell.filter&&<IconButton size='small' onClick={()=>{props.handleShowFilterColumn(cell.id)}} style={{color:'white', marginLeft: 8}}>
                                        <FilterListIcon fontSize='small'/>
                                    </IconButton>}
                                </div>
                            </StyledTableCell>}
                        </Fragment>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.handleNewRow&&<StyledTableRow> 
                    {props.withIndex&&<TableCell style={{borderRight: '1px solid #e0e0e0'}}>
                        <div onClick={props.handleNewRow} style={{cursor: 'pointer',height: 20}}></div>
                    </TableCell>}
                    {cells.map((cell, index) =>  (
                        <Fragment key={cell.id}>
                            {(!cell.hidden)&&<TableCell
                                key={cell.id} 
                                align={cell.align} 
                                style={{borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                <div onClick={props.handleNewRow} style={{cursor: 'pointer',height: 20, color: 'blue'}}>+New</div>
                            </TableCell>}
                        </Fragment>
                    ))}
                </StyledTableRow>}
                {data.map((row, rowIndex) => (
                    <StyledTableRow key={row._id}>
                        {props.withIndex&&<TableCell 
                            style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                        >
                           {rowIndex+1}
                        </TableCell>}
                        {cells.map(cell =>  {
                            let backgroundColor
                            let value = row[cell.id] === undefined ? '' : row[cell.id]

                            if(cell.getValue){
                                value = cell.getValue(row)
                            }

                            if(cell.capitalize){
                                value = capitalize(value)
                            }

                            switch (cell.type){
                                case 'DATE': {
                                    value = showDateTimeFromString(value, false);
                                    break;
                                } case 'BOOLEAN': {
                                    if(row[cell.id] === undefined){
                                        value = ''
                                    } else {
                                        value = row[cell.id]?'Yes':'No'
                                    }
                                    break;
                                } case 'DATE_TIME': {
                                    value = showDateTimeFromString(value, true);
                                    break;
                                } case 'CONTACT': {
                                    value = value + (row[cell.phone]?(', ' + row[cell.phone]):'') + (row[cell.email]?(', ' + row[cell.email]):'')
                                    break;
                                } case 'SALARY': {
                                    value = displayCash({value:value, currency:row[cell.curField], frequency:row[cell.freqField]})
                                    break;
                                } case 'NAME_FROM_EMAIL': {
                                    value = getNameFromEmail(value)
                                    break;
                                } case 'CHECKBOX': {
                                    value = value?'Yes':'No'
                                    if (value === 'Yes'){
                                        if (cell.fieldToShow){
                                            value = getNameFromEmail(row[cell.fieldToShow]) || 'Yes'
                                        }
                                        backgroundColor = '#E8F0FE'
                                    }
                                    break;
                                } case 'CYCLES': {
                                    if (!row[cell.fieldToCheck]){
                                        if(cell.fieldAlternative){
                                            value = row[cell.fieldAlternative] || ''
                                        } else {
                                            value = ''
                                        }
                                    }
                                    break;
                                } default:{
                                    
                                }
                            }
                            return (
                                <Fragment key={cell.id}>
                                    {(!cell.hidden)&&<TableCell
                                        key={cell.id} 
                                        style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', textAlign: 'initial', backgroundColor:backgroundColor}}
                                        dir="auto"
                                    >
                                        {(cell.type === 'WA_PHONE')&&<a href={'https://wa.me/'+value} target="_blank" rel="noopener noreferrer">{cell.linkLabel || value}</a>}
                                        {(cell.type === 'LINK')&&<a href={value} target="_blank" rel="noopener noreferrer">{cell.linkLabel || value}</a>}
                                        {!['WA_PHONE', 'LINK'].includes(cell.type)&&<>
                                        {(cell.position&&row[cell.position]&&row[cell.position]>1)?<div>
                                            <span onClick={(props.handleEditRow&&!cell.noEdit)?()=>{props.handleEditRow(row, {cell:cell.id})}:undefined} style={(props.handleEditRow&&!cell.noEdit)?{cursor: 'pointer',minHeight:20}:{}}>{value}</span>
                                            <span onClick={(props.handleEditRow&&!cell.noEdit)?()=>{props.handleEditRow(row, {value:row[cell.id], position:true})}:undefined} style={(props.handleEditRow&&!cell.noEdit)?{cursor: 'pointer',minHeight:20}:{}}> - <strong>{displayPosition(row[cell.position])}</strong></span>
                                        </div>:
                                        <div onClick={(props.handleEditRow&&!cell.noEdit)?()=>{props.handleEditRow(row, {cell:cell.id})}:undefined} style={(props.handleEditRow&&!cell.noEdit)?{cursor: 'pointer',minHeight:20}:{}}>
                                            {value}
                                            {cell.showTimeAgo&&<span style={{fontSize:12, display:'block', lineHeight:1}}>{getDateText(row[cell.id])}</span>}
                                        </div>}</>}
                                    </TableCell>}
                                </Fragment>
                            )
                        })}
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    )
}