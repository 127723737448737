import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import AdminJHApi from "../../utils/AdminJHApi"
import { getNameFromEmail, showDateTimeFromString } from '../../utils/utils';

export default function SynamediaClientsStatusDialog(props) {
    const showHistory = props.showHistory || false
    const objectId = props.objectId
    const object = props.object || {}

    const [data, setData] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        if(showHistory && objectId){
            setData([])
            AdminJHApi.getPersonalJourneyHistory({objectId},(response)=>{
                setData((response.data||[]).sort((a, b) => {
                    if (a.date < b.date){
                        return -1
                    } else {
                        return 1
                    }
                }))
                
                setError()
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
            })
        }
    }, [showHistory, objectId]);

    return (
        <Dialog onClose={props.handleHideHistory} open={showHistory} fullWidth  maxWidth="md">
            <DialogTitle>{object.nameEn||''} - {object.email||''}</DialogTitle>
            <DialogContent dividers>
                {(error)&&<Typography color="error">{error}</Typography>}
                {data.map((row, index) => (
                    <div key={index} style={{display:'flex', marginBottom: 8, alignItems:'center', paddingBottom: 8, width:'100%', borderBottom: '1px solid silver'}}>
                        <div style={{marginRight:8}}>
                            <Typography color='primary' dir="auto" style={{textAlign:'left'}}><strong>{showDateTimeFromString(row.date, false)} - {row.title}{row.author?' - ':''}{getNameFromEmail(row.author)}</strong></Typography>
                            <Typography dir="auto" style={{textAlign:'left', whiteSpace: 'pre-line'}}>{row.content}</Typography>
                        </div>
                    </div>
                ))}
            </DialogContent>
        </Dialog>
    )
}
