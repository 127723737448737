import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { postNewJob } from '../redux/actions/selectedJob';

class PostNewJob extends Component {
    handlePostNewJob = (event, newValue) => {
        this.props.dispatch(postNewJob())
    }

    handleJobHunt = (event, newValue) => {
        this.props.dispatch(push('/personal-journey'));
    }

    render() {
        const {isFetching, loginChecked, histogramType, isJHUser} = this.props;      

        return (
            <React.Fragment>
                {(loginChecked && !isJHUser && histogramType !== 'myJobs')&&
                <Tabs value={false} style={{borderLeft: "1px solid rgba(0,0,0,.10)"}} onChange={this.handlePostNewJob} classes={{ indicator: "mainTabIndicator" }}>
                    <Tab label="Post Jobs Free"
                        value="postJobsFree" className="headerTab afterUser" classes={{ selected: "selected"}} disabled={isFetching}/>
                </Tabs>}
                {(isJHUser)&&
                <Tabs value={false} style={{borderLeft: "1px solid rgba(0,0,0,.10)"}} onChange={this.handleJobHunt} classes={{ indicator: "mainTabIndicator" }}>
                    <Tab label="Journey"
                        value="Journey" className="headerTab afterUser" classes={{ selected: "selected"}} disabled={isFetching}/>
                </Tabs>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { loginChecked, userInfo } = state.authUser;
    const isFetching = state.histogramJobs.isFetching
    const histogramType = state.histogramData.histogramType

    return {
        isFetching, loginChecked, histogramType, isJHUser: userInfo.isJHUser
    }
}

export default connect(mapStateToProps)(PostNewJob)