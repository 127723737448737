import React, { Component } from 'react'
import SearchJobs from '../containers/SearchJobs'
import HistogramObjectsC from '../containers/HistogramObjectsC'
import FilterJobs from '../containers/FilterJobs';
import HistSelectedObject from '../containers/HistSelectedObject'
import BookmarkIcon from '@material-ui/icons/Bookmark';

import '../styles/HistogramView.css'
import LandingPage from './LandingPage';

export default class HistogramView extends Component {
    constructor(props) {
        super(props);
        let searchSectionTop = 0;
        let mainViewTop = 238;
        let mainViewHeight = 238;

        if (window.innerWidth <= 768){
            mainViewTop = 128
            mainViewHeight = 176
        }

        this.state = {
            expandedFilter: true,
            histogramScrollPos: 0,
            searchSectionTop,
            mainViewTop,
            mainViewHeight,
            view: (window.innerWidth <= 768)?'MOBILE':'DESKTOP'
        };
    }

    handleScroll = (scrollPos) => {
        if (this.state.view === 'MOBILE'){            
            let sub =  this.state.histogramScrollPos - scrollPos;

            let searchSectionTop = this.state.searchSectionTop + sub;
            let mainViewTop = this.state.mainViewTop + sub;
            //let mainViewHeight = this.state.mainViewHeight + sub;

            if (searchSectionTop < -74){
                searchSectionTop = -74;
            }
            if (searchSectionTop > 0){
                searchSectionTop = 0;
            }
            if (mainViewTop < 56){
                mainViewTop = 56;
            }
            if (mainViewTop > 128){
                mainViewTop = 128;
            }
            /*if (mainViewHeight < 104){
                mainViewHeight = 104;
            }
            if (mainViewHeight > 176){
                mainViewHeight = 176;
            }*/

            let mainViewHeight = 104 + 72 + searchSectionTop

            this.setState({
                histogramScrollPos: scrollPos,
                searchSectionTop,
                mainViewTop,
                mainViewHeight,
                expandedFilter: false
            });
        }
    }

    handleExpandedFilter = (expanded) => {
        if (this.state.view === 'MOBILE'){   
            let mainViewHeight = 104 + ((expanded) ? 152 : 0) + (72 + this.state.searchSectionTop)
            //console.log({mainViewHeight, searchSectionTop:this.state.searchSectionTop});
            this.setState({
                expandedFilter: expanded,
                mainViewHeight
            });
        }
    }
    
    render() {
        const { showCardDialog, histogramType, hasPermission, loginChecked, userInfo, savedItems } = this.props

        const HIST_TYPES = ['jobs', 'resumes', 'main', 'jobhuntUsers'];

        const showLandingPage = (histogramType === 'main' || (loginChecked && !hasPermission))
        const lookingforwork = (userInfo && userInfo.lookingforwork)
        
        const showSearchAndFilter = (showLandingPage || HIST_TYPES.includes(histogramType))

        const mainViewHeight = (showSearchAndFilter)?this.state.mainViewHeight: (window.innerWidth < 600)? 56 : 64 
        const mainViewTop = (showSearchAndFilter)?this.state.mainViewTop: (window.innerWidth < 600)? 56 : 64 

        const mainViewStyle = { top:mainViewTop, height:'calc(100% - ' + mainViewHeight +'px)' };
        const view = this.state.view;

        let emptySavedItems = savedItems ? savedItems.length === 0 : true

        return (
            <React.Fragment>
                {(showLandingPage)&&<LandingPage view={view} lookingforwork={lookingforwork} changeRoute={this.props.changeRoute}/>}
                {(showSearchAndFilter)&&<SearchJobs top={this.state.searchSectionTop} view={view} landingPage={(showLandingPage)}/>}
                {(histogramType === 'saveditems' && emptySavedItems && hasPermission) ? 
                <React.Fragment>  
                    <div className="pageMainContainer">
                        <div style={{fontSize: '16px', textAlign: 'center',padding: '50px'}}>
                            <BookmarkIcon style={{color: '#757575',width: '100px',height: '100px'}}/>
                            <div style={{fontSize: '18px', marginBottom: '4px'}}>No saved jobs/resumes</div>
                            <div style={{color: '#757575'}}>
                                Tap the bookmark on any job/resume that interests you to save it for later
                            </div>
                        </div>
                    </div>
                </React.Fragment> :
                (histogramType!=='main' && hasPermission)&&<React.Fragment>
                    {(showSearchAndFilter)&&<FilterJobs expanded={this.state.expandedFilter} handleExpanded={this.handleExpandedFilter}/>}
                    <div className="mainJobView" style={mainViewStyle} >
                        <HistogramObjectsC 
                            showJobCardDialog={showCardDialog} 
                            handleScroll={this.handleScroll} 
                            view={view}/>
                        <HistSelectedObject 
                            view={view} 
                            />
                    </div>
                </React.Fragment>}
            </React.Fragment>
        )
    }
  }