import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CANDIDATE_ACTIVITY_TABS, RECRUITER_ACTIVITY_TABS, CANDIDATE_ACTIVITY_SUB_TYPES, RECRUITER_ACTIVITY_SUB_TYPES } from '../utils/utils';

export default class HistTabs extends Component {
    handleChange = (event, value) => {
        const {histogramType, lookingforwork, goToRoute} = this.props
        const keys = (lookingforwork) ? CANDIDATE_ACTIVITY_SUB_TYPES : RECRUITER_ACTIVITY_SUB_TYPES;

        goToRoute('/' + histogramType + '/' + keys[value])
    };

    render() {
        const {histogramType, histogramSubType, lookingforwork, isFetching, isAuthed, hasJobsSuggestion, hasMatchesCan, hasApplicants, hasMatchesRec} = this.props
        let showBadgesOnTabs = []

        if(isAuthed){
            if(lookingforwork) {
                showBadgesOnTabs = [false, hasJobsSuggestion, hasMatchesCan]
            } else {
                showBadgesOnTabs = [false, hasApplicants, hasMatchesRec]
            }
        }

        const tabs = (lookingforwork) ? CANDIDATE_ACTIVITY_TABS : RECRUITER_ACTIVITY_TABS
        const keys = (lookingforwork) ? CANDIDATE_ACTIVITY_SUB_TYPES : RECRUITER_ACTIVITY_SUB_TYPES;

        const currntTab = keys.indexOf(histogramSubType)

        return (
            <React.Fragment>
                {(histogramType === 'activity')&&<Paper elevation={2} square>
                    <Tabs
                        value={(currntTab === -1) ? 0 : currntTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="off"
                    >
                        {tabs.map((tab, index) => 
                            <Tab label={<React.Fragment>{tab}{(showBadgesOnTabs[index] !== undefined && showBadgesOnTabs[index])&&<div className="JSBadge" style={{right:2}}></div>}</React.Fragment>}
                                key={tab}
                                className="histTab"
                                disabled={isFetching}/>)
                        }
                    </Tabs>
                </Paper>}
            </React.Fragment>      
        )
    }
}