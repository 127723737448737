import axios from "axios";
import { getToken } from "./AuthHelper";
import { getHost } from './utils'

const SERVER_URL = getHost();

/*const get = (route, params, onSuccess, onError) => {
    axios({ method:'GET', 
        url: `${SERVER_URL}/api/${route}`, 
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}*/

const post = (route, params, onSuccess, onError) => {
    axios({ method:'POST', 
        url: `${SERVER_URL}/api/${route}`, 
        headers: { authorization: `Bearer ${getToken()}` },
        data: params
    })
    .then((response) => {
        onSuccess(response.data)
    })
    .catch((error) => {
        onError(error)
    });
}

const getExplanations = (params, onSuccess, onError = ()=>{}) => {
    post('getExplanations', params, onSuccess, onError)
}

const saveExplanation = (params, onSuccess, onError = ()=>{}) => {
    post('saveExplanation', params, onSuccess, onError)
}

const switchExplanationOrder = (params, onSuccess, onError = ()=>{}) => {
    post('switchExplanationOrder', params, onSuccess, onError)
}

const uploadExplanationImage = (params, onSuccess, onError = ()=>{}) => {
    post('uploadExplanationImage', params, onSuccess, onError)
}

const api = {
    getExplanations,
    saveExplanation,
    switchExplanationOrder,
    uploadExplanationImage
};
export default api;