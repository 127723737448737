import React, { Component } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress';
import WorkOff from '@material-ui/icons/WorkOff';

import '../styles/JobCard.css'

export default class EmptyCard extends Component {
    render() {
        const isfetchingjob = (this.props.isfetchingjob) ? this.props.isfetchingjob : false;
        const jobnotfound = (this.props.jobnotfound) ? this.props.jobnotfound : false;
        const histogramType = this.props.histogramType;
        const singleHist = (this.props.singleHist) ? this.props.singleHist : false;

        let showLogo = false;
        let notFoundText = 'No results';

        switch(histogramType){
            case 'jobs':{
                showLogo = true;
                notFoundText = 'This job is no longer available';
                break;
            }
            case 'resumes':{
                notFoundText = 'This resume is no longer available';
                break;
            }
            case 'myJobs':{
                notFoundText = "You don't have jobs"
                break;
            }
            default:{
                
            }
        }

        return (
            <div className="card empty">
                {(isfetchingjob)&&
                <div><CircularProgress/></div>}
                {(!isfetchingjob&&singleHist&&jobnotfound)&&
                <div>
                    {(showLogo)&&<WorkOff className="icon"/>}
                    <div>{notFoundText}</div>
                </div>}
			</div>
        )
    }
}