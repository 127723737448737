import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import { FIELD_TYPES } from '../../../utils/utils';
import AddIcon from '@material-ui/icons/Add';
import PickIcon from '@material-ui/icons/RadioButtonUnchecked';
import UnPickIcon from '@material-ui/icons/RadioButtonChecked';
import DeleteIcon from '@material-ui/icons/Delete';
import { JSTextEdidor } from '../JSTextEdidor';
import JHApi from "../../../utils/JHApi"
import CoverLetterChanges from './CoverLetterChanges';

export default function CoverLetterManager(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 

    const isAdmin = props.isAdmin
    const userId = props.userId

    const defCoverLetters = journeyData.coverLetter ? [{title:'Cover Letter 1', content:journeyData.coverLetter, picked:true}] : []
    const coverLetters = (journeyData.coverLetters&&journeyData.coverLetters.length>0 ? journeyData.coverLetters : defCoverLetters).map(coverLetter=>(coverLetter.picked && coverLetter.link && !coverLetter.content)?{...coverLetter, content:journeyData.coverLetter}:coverLetter)

    const defDialogData = {open:false}
    const [dialogData, setDialogData] = useState(defDialogData);
    const [changesDialog, setShowJourneyChanges] = useState({open:false});

    const getValue = (name, defaultValue) => {
        if (dialogData[name] !== undefined){
            return dialogData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setDialogData({...dialogData,[e.target.name]: e.target.value})
    };

    const handleChangeCoverLetter = newContent => {
        setDialogData({...dialogData,content: newContent})
    }

    const handleSave = e => {
        e.preventDefault();

        let paramsToPass = {coverLetter:dialogData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        JHApi.addEditCoverLetter(paramsToPass,(response)=>{
            setDialogData(defDialogData)
            props.postServerCall(null, response)
        }, (error) => {
            if(error && error.response&& error.response.data){
                console.log(error.response.data)     
            } else {
                console.log(error)
            }

            setDialogData({...dialogData,error})
            props.postServerCall()
        })
    }

    const handlePickCL = (id) => {
        let fields = {goToNextStep:false}

        fields.coverLetters = coverLetters.map((coverLetter, index) => (index === id)?{...coverLetter, picked:true}:{...coverLetter, picked:false})
        fields.coverLetter = coverLetters[id].content

        props.handleSaveData(fields,(response)=>{
            setDialogData(defDialogData)
        }, (error) => {
            setDialogData({...dialogData,error})
        })
    }

    const handleCancel = () => {
        setDialogData(defDialogData)
    }

    const handleAddLink = () => {
        setDialogData({open:true, editMode:true})
    }

    const handleEditCoverLetter = () => {
        setDialogData({...dialogData, editMode:true})
    }

    const handleDeleteCoverLetter = (id, object) => () => {
        setDialogData({open:true, id, isDelete:true, editMode:true, ...object})
    }

    const handleShowChanges = () => {
        setShowJourneyChanges({
            open:true,
            id:dialogData.id,
            journeyId: journeyData._id
        })
    }

    const handleShowCL = (id, object) => () => {
        setDialogData({open:true, id, ...object})
    }

    const fields = [
        {name:'title', label:'Title', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
    ]
    const fieldsLink = [
        {name:'link', label:'Link', defaultValue: '', type:FIELD_TYPES.TEXT, placeholder:'Example: https://docs.google.com/document/d/{id}/edit?usp=sharing&ouid={uid}&rtpof=true&sd=true', sm:12},
    ]

    return (
        <>
            <Dialog onClose={handleCancel} open={dialogData.open} maxWidth={dialogData.isDelete?'xs':'md'} fullWidth={!dialogData.isDelete}>
                {!dialogData.isDelete&&<DialogTitle>{dialogData.title||''}</DialogTitle>}
                {dialogData.editMode?<form onSubmit={handleSave}>
                    <DialogContent dividers style={dialogData.isDelete?{}:{minHeight:300}}>
                        {(dialogData.error)&&<Typography color="error">{dialogData.error}</Typography>}
                        {dialogData.isDelete?<Typography>Delete cover letter?</Typography>:<>
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                        {dialogData.link&&<Fields fields={fieldsLink} getValue={getValue} handleChange={handleChangeField}/>}
                        <JSTextEdidor limitToolbar value={dialogData.content||''} onChange={handleChangeCoverLetter}/>
                        </>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            {dialogData.isDelete?'No':'Cancel'}
                        </Button>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                        >
                            {dialogData.isDelete?'Yes':'Save'}
                        </Button>
                    </DialogActions>
                </form>:<>
                    <DialogContent dividers>
                        {dialogData.link&&<div><a href={dialogData.link} target="_blank" rel="noopener noreferrer">Link</a></div>}
                        <div dangerouslySetInnerHTML={{__html: dialogData.content}} />
                    </DialogContent>
                    <DialogActions>
                        {isAdmin&&<Button disabled={isLoading} onClick={handleShowChanges} color="primary" size="small" aria-label="Show Changes">
                            Show Changes
                        </Button>}
                        <Button disabled={isLoading} onClick={handleEditCoverLetter} color="primary" size="small" aria-label="Edit">
                            Edit
                        </Button>
                    </DialogActions>
                </>}
            </Dialog>

            <CoverLetterChanges changesDialog={changesDialog} setShowJourneyChanges={setShowJourneyChanges}/>

            {coverLetters.map((coverLetter, index) => <Fragment key={index}>
                {!coverLetter.isDeleted&&<div className={"tag"+(coverLetter.picked?" selected":"")} style={{display: 'inline-flex', fontSize: 15, alignItems: 'center', cursor: 'default'}}>
                    {isAdmin&&<IconButton aria-label="edit"  onClick={()=>{if(!coverLetter.picked){handlePickCL(index)}}} size='small' style={{marginRight:4}} color='primary'>
                        {coverLetter.picked?<UnPickIcon fontSize="small" />:<PickIcon fontSize="small" />}
                    </IconButton>}
                    <span style={{color: '#1861bf', cursor: 'pointer', textDecoration: 'underline'}} onClick={handleShowCL(index, coverLetter)}>{coverLetter.title}</span>
                    {(isAdmin&&!coverLetter.picked)&&<IconButton aria-label="delete" onClick={handleDeleteCoverLetter(index, coverLetter)} size='small' style={{marginLeft:4}}>
                        <DeleteIcon/>
                    </IconButton>}
                </div>}
            </Fragment>)}
            <div style={{display: 'inline-flex', alignItems: 'center', margin:3}}>
                <Button
                    disabled={isLoading}
                    onClick={handleAddLink}
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                >
                    Cover Letter
                </Button>
            </div>
        </>
    )
}