import React, { Component } from 'react'

import '../styles/ObjectOnHist.css'
import Skeleton from '@material-ui/lab/Skeleton';
import CompanyLogo from '../containers/CompanyLogo';
import Rating from '@material-ui/lab/Rating';
import { getCompanyUrl } from '../utils/utils';

export default class HistogramCompany extends Component {
    render() {
        const { company, fetchingData, onClick } = this.props;

        return (
            <React.Fragment>
                <div onClick={onClick}>
                    <CompanyLogo companyName={company.name || ''} companyUrl={getCompanyUrl(company.urls)} className="clLogo" fetchingData={fetchingData}/>
                    <div className="clInfo">
                        <div className="clTitle">
                            {(fetchingData)?<Skeleton variant="text" width={"90%"} animation="wave"/>:<React.Fragment>{company.name}</React.Fragment>}
                        </div>
                        {(company.rating) && <div className="clRating">
                            <Rating value={company.rating} precision={0.5} size="small" readOnly />
                        </div>}
                        <div className="clCompany">
                            {(fetchingData)?<Skeleton variant="text" width={"50%"} animation="wave"/>:<React.Fragment>{(company.interviews || 0) + ' Interviews'}</React.Fragment>}
                        </div>
                        <div className="clCompany">
                            {(fetchingData)?<Skeleton variant="text" width={"50%"} animation="wave"/>:<React.Fragment>{(company.reviews || 0) + ' Reviews'}</React.Fragment>}
                        </div>
                        <div className="clCompany">
                            {(fetchingData)?<Skeleton variant="text" width={"50%"} animation="wave"/>:<React.Fragment>{(company.jobs || 0) + ' Jobs'}</React.Fragment>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}