import React, { useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../../styles/CoverLetter.css'
import { Typography } from '@material-ui/core';

/*function ColorPic(props) {
    const stopPropagation = (event) => {
        event.stopPropagation();
    };
  
    const onChange = (color) => {
        props.onChange('color', color.hex);
    }
  
    const renderModal = () => {
        const { color } = props.currentState;
        return (
            <div
            onClick={stopPropagation}
            >
            <BlockPicker color={color} onChangeComplete={onChange} />
            </div>
        );
    };
  
    return (
        <div
            aria-haspopup="true"
            aria-expanded={expanded}
            aria-label="rdw-color-picker"
        >
            <div onClick={onExpandEvent}>
                <img src={icon} alt=""/>
            </div>
            {expanded ? renderModal() : undefined}
        </div>
      );
}*/

const CustomOption = ({ editorState, onChange }) => {
    const removeStyles = () => {
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
    
        const newBlockMap = blockMap.map(block => {
            const inlineStyles = block.getCharacterList().map(char => {
            const styles = char.getStyle();
            const newStyles = styles.filter(style => !style.startsWith('FONTSIZE') && !style.startsWith('FONTFAMILY') && !style.startsWith('fontsize') && !style.startsWith('fontfamily'));
            return char.set('style', newStyles.add('fontsize-16').add('fontfamily-Arial'));
            });
    
            return block.set('characterList', inlineStyles);
        });
  
        const newContentState = contentState.set('blockMap', newBlockMap);
        onChange(EditorState.push(editorState, newContentState, 'change-inline-style'));
    };
  
    return (
        <div className='rdw-option-wrapper' onClick={removeStyles}>font auto-fix</div>
    );
};

export function JSTextEdidor(props) {
    let html = props.value;
    const contentBlock = htmlToDraft(html);
    let editorStateP
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorStateP = EditorState.createWithContent(contentState);
    }

    const [editorState, setEditorState] = useState(editorStateP);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)

        let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()), {}, true)

        htmlContent = htmlContent.replace(/<ul>/mgi, '<ul dir="auto">');

        props.onChange(htmlContent)
    };

    /*const handleReturn = (e, editorState) => {
        console.log(editorState)
        return false
    }//DraftHandleValue*/

    /*function removeFontStyles(string) {
        // Regular expressions to match font-size and font-family declarations
        const fontSizeRegex = /font-size:[^;]+;/g;
        const fontFamilyRegex = /font-family:[^;]+;/g;
      
        // Remove all occurrences of font-size and font-family
        let cleanedText = string.replace(fontSizeRegex, '').replace(fontFamilyRegex, '');
      
        return cleanedText;
    }*/

    const toolbar = props.limitToolbar ? {
        options: ['inline', 'textAlign', 'colorPicker',/* 'fontSize', 'fontFamily',*/ 'list', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        colorPicker: {
            //component: ColorPicker,
            colors: ['#1c4587', '#3c78d8', 'black', 'white'],
        },
        /*fontSize: {
            options: [16],
        },
        fontFamily: {
            options: ['Arial'],
        },*/
    }:{}

    return (
        <div style={{marginBottom:8}}>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                //handleReturn={handleReturn}
                placeholder="Type here"
                toolbar={toolbar}
                toolbarCustomButtons={[<CustomOption />]}
            />
            <Typography variant='caption'><b>Shift+Enter</b> - New Line</Typography><br/>
            <Typography variant='caption'><b>Enter</b> - New Paragraph</Typography>
        </div>
    )
}