import React, { Component } from 'react'

import '../styles/Chip.css'

export default class JSChip extends Component {
    render() {
        const {selected, rect} = this.props
        return (
            <span className={'tag' + ((selected)?' selected':'') + ((rect)?' rect':'')} onClick={this.props.onClick}>
                {this.props.children}
            </span>
        )
    }
}