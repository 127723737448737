import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Dialog, IconButton, Slide, DialogActions, DialogTitle, DialogContent, TableSortLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function(props) {
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setPage(0)
        setOrder('')
        setOrderBy('')
        props.handleClose()
    }

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const data = props.data || {}
    const rows = data.extraUserData || []
    const columns = data.columns || []

    let rowsToShow = rows
    if (order && orderBy){
        rowsToShow = stableSort(rows, getComparator(order, orderBy))
    }
    rowsToShow = rowsToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <DialogTitle>
                <IconButton onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{padding:0}} dividers>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => 
                                    <TableCell key={column.headerName} sortDirection={orderBy === column.field ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === column.field}
                                            direction={orderBy === column.field ? order : 'asc'}
                                            onClick={createSortHandler(column.field)}
                                        >
                                            {column.headerName}
                                            {orderBy === column.field ? (
                                                <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsToShow.map((row) => (
                                <TableRow key={row._id}>
                                    {columns.map((column) => {
                                        let value = row[column.field]
                                        
                                        if (column.type === 'boolean'){
                                            if (value === true){
                                                value = 'V'
                                            } else if (value === false){
                                                value = 'X'
                                            } else {
                                                value = ''
                                            }
                                        } else {
                                            value = value || ''
                                        }
                                        let link = (column.link)?(column.link+value):''

                                        return (
                                            <React.Fragment key={column.field}>
                                                <TableCell>
                                                    {(column.type === 'link')?
                                                        <a target="_blank" rel="noopener noreferrer" href={link}>{value}</a>:
                                                        <React.Fragment>{value}</React.Fragment>
                                                    }
                                                </TableCell>
                                            </React.Fragment>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </DialogActions>    
        </Dialog>
    )
}