import { combineReducers } from 'redux'

//import { postsBySubreddit, selectedSubreddit } from './example'
import { selectedJob } from './selectedJob'
import { head } from './head'
import { histogramJobs } from './histogramJobs'
import { companies } from './companies'
import { locations } from './locations'
import { searchJobs } from './searchJobs'
import { filterJobs } from './filterJobs'
import { authUser } from './authUser'
import { histogramData } from './histogramData'
import { MainDialog } from './MainDialog'
import { templates } from './templates'
import { htmlTemplates } from './htmlTemplates'
import { payments } from './payments'
import { cardContent } from './cardContent'
import { filterData } from './filterData'
//import { company } from './company'


const rootReducer = combineReducers({
    //postsBySubreddit,
    //selectedSubreddit,
    selectedJob,
    head,
    histogramJobs,
    companies,
    locations,
    searchJobs,
    filterJobs,
    authUser,
    histogramData,
    MainDialog,
    templates,
    htmlTemplates,
    payments,
    cardContent,
    filterData
    //company
})

export default rootReducer