import React, { Component } from 'react'
import CompanyLogo from '../containers/CompanyLogo';
import Share from '@material-ui/icons/Share';
import Rating from '@material-ui/lab/Rating';
import '../styles/JobCard.css';
import CardContentData from '../containers/CardContentData'
import { getCompanyUrl, getHost, filterUrl } from '../utils/utils';
import ShareDialog from './dialogs/ShareDialog';
import CardActionButton from '../containers/CardActionButton';

export default class CompanyCardContent extends Component {
    state = {
		open: false,
		openReportDialog: false
	};

	showShareDialog = () => {
		//const {histogramType}=this.props
		//pushShareGaEvent(histogramType, 'show')

		this.setState({
			open: true,
		});
	};

	closeShareDialog = () => {
		//const {histogramType}=this.props
		//pushShareGaEvent(histogramType, 'close')
		
		this.setState({ 
			open: false 
		});
    };
    
    render() {
        const { company, selectedjobbgcolor, fetchingData, histogramType, scrollToTop } = this.props;
        const applyOnTop = (this.props.applyOnTop) ? this.props.applyOnTop : false;
        const direction = 'ltr'

        const url = `${getHost()}/companies/${filterUrl(company.name)}-${company._id}`

        return (
            <div className={"card " + direction}>
				<div ref={(el) => { this.cardTop = el }}></div>
                <ShareDialog
					open={this.state.open}
					onClose={this.closeShareDialog}
					url={url}
					title={company.name}
					description={company.name}
					histogramType={histogramType}
				/>         	

                <div className="cardHeader" style={{backgroundColor:selectedjobbgcolor}}>
                    <div className="cardHeaderContent">
                        <div className="jobTitleSection">
                            <div className="jobTitle">
                                {company.name || ''}
                            </div>
                            {<div className={"shareButton " + direction}>
                                <span onClick={this.showShareDialog}>
                                    <Share/>
                                </span>
                            </div>}
                        </div>
                        <div className="jobInfoSection">
                            <CompanyLogo companyName={company.name} companyUrl={getCompanyUrl(company.urls)} fetchingData={fetchingData} className="cardLogo"/>
                            <div className={"jobInfoSectionInner " + direction}>
                                {(company.rating)&&<div className="rating">
                                    <Rating value={company.rating} precision={0.5} size="small" readOnly  />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cardFooter">
                    <CardActionButton selectedjobbgcolor={selectedjobbgcolor} applyOnTop={applyOnTop} scrollToTop={scrollToTop} type="companies" company={company.name}/>
                    <CardContentData />
                </div>
            </div>
        )
    }
}