import React from 'react';

import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types'

import '../../styles/CloseDialogButton.css'

export default class CloseDialogButton extends React.Component {
	render(){
        return (
			<span className="close-modal" onClick={this.props.onClick}>
                <Close/>
            </span>
		)
	}
}

CloseDialogButton.propTypes = {
    onClick: PropTypes.func.isRequired
}