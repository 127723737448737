import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import ExplanationsApi from "../utils/ExplanationsApi"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Fields } from './Fields';
import { FIELD_TYPES } from '../utils/utils';

export default function Explenation(props) {
    const dialogData = props.explanationDialog || {}
    const open = dialogData.open || false
    const type = dialogData.type
    const canEdit = props.canEdit || false

    const [explanations, setExplanations] = useState([]);
    const [indexToShow, setIndexToShow] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadImage, setIsUploadImage] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [newData, setNewData] = useState({});

    const explanationsToShow = explanations[indexToShow] || {}

    useEffect(() => {
        if (type && open){
            setIsLoading(true)

            ExplanationsApi.getExplanations({type},(response)=>{
                setExplanations(response)
                setIsLoading(false)
                setIndexToShow(0)
                setIsEdit(false)
            }, (error) => {
                setIsLoading(false)
            })
        }
    }, [open, type]);

    const handleClose = () => {
        props.setExplanationDialog({open:false})
    }

    const handleChangeExplanationsIndex = newInex => () => {
        setIndexToShow(newInex)
    }

    const handleEdit = () => {
        setIsEdit(true)
        setNewData({...explanationsToShow})
    }

    const handleDelete = () => {
        setIsEdit(true)
        setNewData({isDelete:true, _id:explanationsToShow._id, type})
    }

    const handleAdd = () => {
        let order = -1
        explanations.forEach(explanation => {
            if(explanation.order > order){
                order = explanation.order
            }
        });
        
        order = order + 1
        setIsEdit(true)
        setNewData({type, order})
    }

    const handleMoveUp = () => {
        const prevExp = explanations[indexToShow-1]
        setIsLoading(true)

        ExplanationsApi.switchExplanationOrder({
            type,
            exp1:{id: explanationsToShow._id, order:prevExp.order}, 
            exp2:{id: prevExp._id, order:explanationsToShow.order}
        },(response)=>{
            setExplanations(response)
            setIndexToShow(indexToShow-1)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
        })
    }

    const handleMoveDown = () => {
        const nexExp = explanations[indexToShow+1]

        ExplanationsApi.switchExplanationOrder({
            type,
            exp1:{id: explanationsToShow._id, order:nexExp.order}, 
            exp2:{id: nexExp._id, order:explanationsToShow.order}
        },(response)=>{
            setExplanations(response)
            setIndexToShow(indexToShow+1)
            setIsLoading(false)
        }, (error) => {
            setIsLoading(false)
        })
    }

    const handleCancelEdit = () => {
        setIsEdit(false)
        setNewData({})
    }

    const handleSave = e => {
        e.preventDefault();

        setIsLoading(true)

        ExplanationsApi.saveExplanation({newData},(response)=>{
            setExplanations(response)
            setIsLoading(false)
            setIndexToShow(0)
            setIsEdit(false)
        }, (error) => {
            setIsLoading(false)
        })
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleSelectedFile = event => {
        const file = event.target.files[0];

        if (!file) {
            return null
        }

        /*if(file.size > maxUploadResumeSize){
            setUplaodMessage('File too big - 50 KB Max')
            return null
        }
        
        const extension =  file.name.split('.').pop();
        if(!extension.match(/docx|pdf/ig)){
            setUplaodMessage('Invalid extension')
            return null
        }*/

        setIsLoading(true)
        setIsUploadImage(true)

        const data = new FormData()
        data.append('file', file, file.name)
        data.append('type', type)
        data.append('id', explanationsToShow._id)

        ExplanationsApi.uploadExplanationImage(data, (response)=>{
            setExplanations(response)
            setIsUploadImage(false)
            setIsLoading(false)
        }, (error)=>{
            console.log(error)
            setIsUploadImage(false)
            setIsLoading(false)
        });
    }

    const fields = [
        {name:'title', label:'Title', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12},
        {name:'textBeforeImage', label:'Text Before Image(Multi Line)', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:6, sm:12},
        {name:'textAfterImage', label:'Text After Image(Multi Line)', defaultValue: '', type:FIELD_TYPES.MULTILINE, rowsMax:6, sm:12}
    ]

    return (
        <Dialog onClose={handleClose} open={open} fullWidth>
            {isEdit?<form onSubmit={handleSave}>
                <DialogContent dividers>
                    {newData.isDelete?<Typography>Are you Sure?</Typography>:<>
                        {/*(error)&&<Typography color="error">{error}</Typography>*/}
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                        {isUploadImage?<Typography>Uploading...</Typography>:<><input
                            accept="image/*"
                            id="contained-button-image"
                            type="file"
                            style={{display:"none"}}
                            onChange={handleSelectedFile}
                        />
                        <label htmlFor="contained-button-image">
                            <Button variant="outlined" color="primary" component="span" aria-label="Upload Image">
                                Upload Image
                            </Button>
                        </label>
                        {explanationsToShow.imageUrl&&<div><img style={{maxWidth: '100%'}} alt='Screenshot' src={explanationsToShow.imageUrl}/></div>}
                        </>}
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={handleCancelEdit} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        {newData.isDelete?'Yes':'Save'}
                    </Button>
                </DialogActions>
            </form>:<>
            {explanationsToShow.title&&<DialogTitle>{explanationsToShow.title}</DialogTitle>}
            <DialogContent dividers>
                {explanationsToShow.textBeforeImage&&<Typography style={{whiteSpace: 'pre-line'}}>{explanationsToShow.textBeforeImage}</Typography>}
                {explanationsToShow.imageUrl&&<div><img style={{maxWidth: '100%'}} src={explanationsToShow.imageUrl} alt='Screenshot'/></div>}
                {explanationsToShow.textAfterImage&&<Typography style={{whiteSpace: 'pre-line'}}>{explanationsToShow.textAfterImage}</Typography>}
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
                <div>
                    {canEdit&&<>
                    {(explanations.length>0)&&<IconButton
                        onClick={handleEdit}
                        disabled={isLoading}
                    >
                        <EditIcon/>
                    </IconButton>}
                    {(explanations.length>0)&&<IconButton
                        onClick={handleDelete}
                        disabled={isLoading}
                    >
                        <DeleteIcon/>
                    </IconButton>}
                    <IconButton
                        onClick={handleAdd}
                        disabled={isLoading}
                    >
                        <AddIcon/>
                    </IconButton>
                    {(explanations.length>0)&&<IconButton
                        onClick={handleMoveDown}
                        disabled={isLoading || indexToShow===explanations.length-1}
                    >
                        <ArrowDownwardIcon/>
                    </IconButton>}
                    {(explanations.length>0)&&<IconButton
                        onClick={handleMoveUp}
                        disabled={isLoading || indexToShow===0}
                    >
                        <ArrowUpwardIcon/>
                    </IconButton>}
                    </>}
                </div>
                <div>
                    {(explanations.length>1)&&<IconButton
                        onClick={handleChangeExplanationsIndex(indexToShow-1)}
                        disabled={isLoading || indexToShow===0}
                    >
                        <ChevronLeftIcon/>
                    </IconButton>}
                    {(canEdit&&explanations.length>0)&&<span>{1+indexToShow}/{explanations.length}</span>}
                    {(indexToShow===explanations.length-1 || explanations.length===0 || explanations.length===1)?
                    <IconButton
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        <CloseIcon/>
                    </IconButton>:
                    <IconButton
                        onClick={handleChangeExplanationsIndex(indexToShow+1)}
                        disabled={isLoading || indexToShow===explanations.length-1}
                    >
                        <ChevronRightIcon/>
                    </IconButton>}
                </div>
            </DialogActions>
            </>}
        </Dialog>
    )
}