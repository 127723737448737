import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Chip, Menu, MenuItem } from '@material-ui/core';
import { capitalize } from '../../../utils/utils';

export default function SelectAsChip(props) {
    const value = props.value || ''
    const list = props.list || []
    const emptyLabel = props.emptyLabel || ''
    const noEdit = props.noEdit || false

    let style = props.style || {}

    if (props.backgroundColor && !style.backgroundColor){
        style.backgroundColor = props.backgroundColor
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const showOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectValue = value => () => {
        setAnchorEl(null);
        props.onChange(value)
    }

    const labelFunc = value => {
        if (props.labelFunc){
            return capitalize(props.labelFunc(value))
        } else {
            return capitalize(value || emptyLabel)
        }
    }

    return (
        <>
            <Chip
                size="small" 
                label={labelFunc(value) || emptyLabel} 
                onClick={noEdit?undefined:showOptions} 
                color="primary" 
                onDelete={noEdit?undefined:showOptions} 
                deleteIcon={noEdit?undefined:<EditIcon/>}
                disabled={props.disabled}
                style={style}
            />
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {emptyLabel&&<MenuItem onClick={handleSelectValue('')} value=''>{emptyLabel}</MenuItem>}
                {list.map(listItem => (
                    <MenuItem key={listItem} value={listItem} onClick={handleSelectValue(listItem)}>
                        {labelFunc(listItem)}
                    </MenuItem>
                ))}
                {(value&&!list.includes(value))&&<MenuItem value={value}>
                    {labelFunc(value)}
                </MenuItem>}
            </Menu>
        </>
    )
}