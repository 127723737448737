import { Button, TextField, Typography } from '@material-ui/core';
import LocationField from './LocationField';
import React from 'react';

export default function (props) {
    const {keyWordPlaceholder, queryString, locationText, setQueryString, setLocationObj, setLocationText, handleSearch} = props;

    return (
        <div className="searchSectionMain">
            <Typography component="label" htmlFor="keyWords" variant="body1" className="label">I'm looking for</Typography>
            <TextField
                className="keywordsFieldMain"
                id="keyWords"
                fullWidth
                type="search"
                value={queryString}
                onChange={setQueryString}
                placeholder={keyWordPlaceholder}
                variant="outlined"
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
            <Typography component="label" htmlFor="react-select-2-input" variant="body1" className="label">near</Typography>
            <LocationField 
                handleLocationChange={setLocationObj}
                locationText={locationText}
                setLocationText={setLocationText}
                variant="outlined" 
                className="LocationFieldMain" 
                fullWidth
            />
            <br/>
            <Button className="searchButtolp" variant="contained" color="primary" onClick={handleSearch} aria-label="Search">
                Search
            </Button>
        </div>
    )
}