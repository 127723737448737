import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { ProfileActions } from '../profile/ProfileActions';

import '../../styles/Profile.css'

const currencies = [
    {
        value: 'USD',
        label: '$',
    }, {
        value: 'ILS',
        label: '₪',
    }, {
        value: 'EUR',
        label: '€',
    }, {
        value: 'GBP',
        label: '£'
    }
    
];

export default class Salary extends Component {
    state = {
        editMode: false
    }

    sectionFields = ['salaryMin', 'salaryMax', 'salaryCurrency', 'salaryFrequency'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })

        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields, true)
        this.setState({
            editMode: false
        })

        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })

        this.props.changeOnEditCount(false)
    }

    getCurrencySymbol = (salaryCurrency) =>{
        let currencySymbol = ''
        
        if (salaryCurrency){
            currencySymbol = currencies.find(currency => currency.value === salaryCurrency);  
            currencySymbol = currencySymbol ? currencySymbol.label : ''
        }

        return currencySymbol
    }

    getFrequencyLabel = (salaryFrequency) => {
        if (!salaryFrequency){
            return ''
        }

        switch(salaryFrequency){
            case 'YEAR': return 'a year'
            case 'MONTH': return 'a month'
            case 'HOUR': return 'an hour'
            default: return ''
        }
    }

    getValueLabel = (value) => {
        if (!value){
            return '';
        }

        if (value >= 1000000){
            return (value / 1000000) + 'M'
        } else if (value >= 1000){
            return (value / 1000) + 'K'
        } else {
            return value
        }
    }

    getFields = (jobInfo, isNew) => {
        return (
            <Grid container spacing={3}  alignItems="center">
                <Grid item sm={6} xs={12}>
                    <FormControl component="fieldset">
                        {/*<FormLabel component="legend">Choose who you are</FormLabel>*/}
                        <RadioGroup
                            aria-label="Salary Frequency"
                            name="salaryFrequency"
                            value={jobInfo.salaryFrequency || 'MONTH'}
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel value="YEAR" control={<Radio color="primary"/>} label="Annual" />
                            <FormControlLabel value="MONTH" control={<Radio color="primary"/>} label="Monthly" />
                            <FormControlLabel value="HOUR" control={<Radio color="primary"/>} label="Hourly" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        id="salaryCurrency" 
                        name="salaryCurrency"
                        select
                        label="currency"                                
                        value={jobInfo.salaryCurrency || 'USD'}
                        onChange={this.handleChange}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Please select your currency"
                        margin="normal"
                        fullWidth
                    >
                        {currencies.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField id="salaryMin" name="salaryMin" label="Minimum Salary(Base Pay)" margin="none" fullWidth 
                        required={!isNew || (jobInfo.salaryMax !== undefined && jobInfo.salaryMax !== '')} type="number" value={jobInfo.salaryMin || ''} onChange={this.handleChange} inputProps={{min:0}}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField id="salaryMax" name="salaryMax" label="Maximum Salary(Base Pay)" margin="none" fullWidth 
                        required={!isNew || (jobInfo.salaryMin !== undefined && jobInfo.salaryMin !== '')} type="number" value={jobInfo.salaryMax || ''} onChange={this.handleChange} inputProps={{min:jobInfo.salaryMin}}/>
                </Grid>
            </Grid>
        )
    }

    render() {
        const { jobInfo, noEdit, isNew } = this.props
        const {editMode} = this.state

        const currencySymbol = this.getCurrencySymbol(jobInfo.salaryCurrency)
        const frequencyLabel = this.getFrequencyLabel(jobInfo.salaryFrequency)
        const minimumLabal = this.getValueLabel(jobInfo.salaryMin)
        const maximumLabal = this.getValueLabel(jobInfo.salaryMax)

        return (
            <React.Fragment>
                {(noEdit) ? 
                    <React.Fragment>
                        {(jobInfo.salaryMin)&&<div className={"jobMoreInfo " + jobInfo.direction}>
                            <span className="jobMoreInfoIcon" style={{display:'initial', fontSize:17, color:'#757575'}}>{currencySymbol}</span>
                            <span className="jobMoreInfoText">{minimumLabal + ((maximumLabal === minimumLabal)?'':'–' + maximumLabal)} {frequencyLabel}</span>
                        </div>}
                    </React.Fragment>:
                    <Paper className="profileSection" elevation={1}>
                        <div className="sectionTitle">Salary Range</div>
                        <Typography className="sectionText">Candidates are more likely to apply to jobs that show salary</Typography>
                        {(isNew) && this.getFields(jobInfo, isNew)}
                        {(editMode && !isNew) && 
                            <form onSubmit={this.handleSaveSection}>
                                {this.getFields(jobInfo, isNew)}
                                <div className="profileButtons">
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.handleCancel}
                                        aria-label="Cancel"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        aria-label="Save Changes"
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </form>}
                        {(!editMode && !isNew) && 
                            <React.Fragment>
                                <ProfileActions handleEdit={this.handleEdit}/>
                                <div className="profileField">
                                    {(jobInfo.salaryMin)?
                                        <div className={"jobMoreInfo " + jobInfo.direction}>
                                            <span className="jobMoreInfoIcon" style={{display:'initial'}}>{currencySymbol}</span>
                                            <span className="jobMoreInfoText">{minimumLabal + ((maximumLabal !== minimumLabal)?('–' + maximumLabal):'')} {frequencyLabel}</span>
                                        </div>:
                                        <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Add a salary range</span> for this job</React.Fragment>}
                                </div>      
                            </React.Fragment>}
                    </Paper>}
            </React.Fragment>
        )
    }
}