import React from 'react';
import AuthApi from '../../utils/AuthApi'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default class ConfirmsOrphanApply extends React.Component {
    state = {
        password:'',
        confirmPassword:'',
        showPassword:false,
        showConfirmPassword:false,
        error:''
    };

    handleOnSubmit = async e => {
        e.preventDefault();

        const { password, confirmPassword } = this.state;
        const email = localStorage.getItem("email") || ''
        const first_name = localStorage.getItem("first_name") || ''
        const last_name = localStorage.getItem("last_name") || ''

        if (email && first_name && last_name && password === confirmPassword){
            AuthApi.setPasswordOrphan({email, first_name, last_name, password}, (results)=>{
                this.handleClose()
                this.props.showMessage('Email sent', 'We sent you an email, please check your inbox')
            }, (error)=>{
                const errorText = (error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : 'Something went wrong'
                this.setState({error: errorText})
            });
        }
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleClickShowConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    };

    handleClose = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        this.props.onClose()
    }

	render(){
        const email = localStorage.getItem("email") || ''
        const {password, confirmPassword, showPassword, showConfirmPassword, error} = this.state
		return (
			<Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="dialog-title"
                >
                <DialogTitle id="dialog-title">Your application has been submitted</DialogTitle>
                <DialogContent dividers className="confirmOrphanApply">
                    <Typography className="subtitle1">Create an account to save your resume and apply to jobs faster</Typography>
                    <Typography className="subtitle2">Use <strong>{email}</strong> to sign in on your next visit</Typography>
                    <Typography className="terms" variant="caption">
                        <span>By continuing, you agree to our </span>
                        <a target="_blank" rel="noopener noreferrer" href="/terms">Terms of Use</a>
                        <span> and </span>
                        <a target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>
                        <span>.</span>
                    </Typography>
                    <form className="passwordForm" onSubmit={this.handleOnSubmit}>
                        <FormControl margin="normal" required>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={this.handleChange}
                                fullWidth
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <br/>
                        <FormControl margin="normal" required>
                            <InputLabel htmlFor="confirmPassword" error={(password !== confirmPassword)}>Confirm password</InputLabel>
                            <Input
                                name="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                autoComplete="current-password"
                                value={confirmPassword}
                                onChange={this.handleChange}
                                fullWidth
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowConfirmPassword}>
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <br/>
                        {(error)&&<Typography color="error">{error}</Typography>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            aria-label="Sign Up"
                            fullWidth
                        >
                            Sign Up
                        </Button>
                    </form>
                    <div className="greyText">Already have an account? <span onClick={() => this.props.handleSignIn()} className="link">Sign In</span></div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" aria-label="No thanks, continue browsing for jobs">
                        No thanks, continue browsing for jobs
                    </Button>
                </DialogActions>
            </Dialog>
		)
	}
}