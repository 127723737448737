import React, { Fragment } from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDateText } from '../../utils/utils';

export default function HistogramInreviews(props) {
    const { interview, onClick, fetchingData } = props;

    const dateText = getDateText(interview.datecreated);

    return (
        <>
            <div onClick={onClick}>
                <div className="clInfo" style={{marginLeft:0}}>
                    <div className="clTitle" dir="auto">
                        {(fetchingData)?<Skeleton variant="text" width={"90%"} animation="wave"/>:<Fragment>{interview.title}</Fragment>}
                    </div>
                    <div className="clJobMoreInfoSection">
                        <div className="clDateCreated jobMoreInfo ltr">
                            <span className="jobMoreInfoIcon">
                                {(fetchingData)?<Skeleton variant="circle" width={18} height={18} animation="wave"/>:<AccessTime style={{ fontSize: 18 }}/>}
                            </span>
                            {(fetchingData)?<Skeleton variant="text" width={60} animation="wave" style={{display:"inline-block"}}/>:<Fragment>{dateText}</Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}