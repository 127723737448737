import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ArrowBack from '@material-ui/icons/ArrowBack';
import JobCard from '../JobCard';
import CloseDialogButton from './CloseDialogButton'

import ReactGA from 'react-ga4'

import '../../styles/JobCardDialog.css'

export default class JobCardDialog extends React.Component {
    constructor() {
        super();
        this.handleScroll = this.handleScroll.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    state = {
        headerShadow: false,
        applyOnTop: false
    };
    
    /*onBackButtonEvent = (e) => {
        e.preventDefault();
        this.handleClose()
    }

    componentDidMount = () => {
        window.onpopstate = this.onBackButtonEvent;
    }*/

    handleScroll(event) {
        let scrollTop = event.target.scrollTop;

        if(scrollTop < 1){
            this.setState({
                headerShadow: false
            });
        } else {
            if (scrollTop < 295){
                this.setState({
                    applyOnTop: false
                });
            } else {
                this.setState({
                    applyOnTop: true
                });
            }
            this.setState({
                headerShadow: true
            });
        }
    }

	handleClose = () => {
        ReactGA.event({category: 'Card',action: 'click_show_histogrm'});

		this.props.onClose();
    };

    componentDidUpdate(prevProps){
        if (!prevProps.open && this.props.open) {
            this.setState({
                headerShadow: false,
                applyOnTop: false
            });
        }
    }

    scrollToTop = () => {
        this.cardTop.scrollIntoView();
        this.setState({
            headerShadow: false,
            applyOnTop: false
        });
    }

	render(){
        const { classes, onClose, job, isfetchingjob, jobnotfound, view, histogramType, editMode, setEditMode, updateHist, autoRefreshList,
            lookingforwork, singleHist, isAuthed, showLogin, searchWords, savedItems, removeSavedItem, addSavedItem, userTags, showMessageDialog, ...other } = this.props;
        const selectedjobbgcolor = (this.props.selectedjobbgcolor) ? this.props.selectedjobbgcolor : '#396a92';
        const fullScreen = this.props.fullScreen;

        /*let backTitle = '';
        switch(histogramType){
            case 'jobs':{
                backTitle = 'Jobs';
                break;
            }
            case 'resumes':{
                backTitle = 'Resumes';
                break;
            }
            case 'myJobs':{
                backTitle = 'My Jobs';
                break;
            }
            case 'profile':{
                backTitle = 'Profile';
                break;
            }
            case 'activity':{
                backTitle = 'Aplied Me';
                break;
            }
            case 'reports':{
                backTitle = 'Reports';
                break;
            }
            default:{
                backTitle = '';
            }
        }*/

		return (
			<Dialog onClose={this.handleClose} onBackdropClick={this.handleClose} onScroll={(e) => this.handleScroll(e)} {...other}>
                <div ref={(el) => { this.cardTop = el }}></div>
                {fullScreen ?
                <div className={(this.state.headerShadow) ? "mobileHeader withShadow" : "mobileHeader"} style={{backgroundColor:selectedjobbgcolor}}>
                    <div className="toHistogram" onClick={this.handleClose}>
                        <span>
                            <ArrowBack/> 
                        </span>
                        {/*<span className="backToJobs">{backTitle || ''}</span>*/}
                    </div>
                </div> :
                <div>
                    <CloseDialogButton onClick={this.handleClose}/>
                </div>}
                <JobCard 
                    job={job} 
                    selectedjobbgcolor={selectedjobbgcolor} 
                    applyOnTop={this.state.applyOnTop} 
                    isfetchingjob={isfetchingjob ? 1 : 0}
                    view={view}
                    jobnotfound={jobnotfound ? 1 : 0}
                    histogramType={histogramType}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    lookingforwork={lookingforwork}
                    singleHist={singleHist}
                    isAuthed={isAuthed}
                    showLogin={showLogin}
                    searchWords={searchWords}
                    scrollToTop={this.scrollToTop}
                    userTags={userTags}
                    savedItems={savedItems}
                    removeSavedItem={removeSavedItem}
                    addSavedItem={addSavedItem}
                    showMessageDialog={showMessageDialog}
                    updateHist={updateHist}
                    autoRefreshList={autoRefreshList}
                />
			</Dialog>
		)
	}
}