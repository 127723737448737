import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button, Collapse, Dialog, DialogTitle, IconButton, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import JHApi from "../../../utils/JHApi"
import AdminJHApi from "../../../utils/AdminJHApi"
import { FIELD_TYPES, capitalize, displayPosition, getNameFromEmail, showDateTimeFromString } from '../../../utils/utils';
import JourneySummary from './JourneySummary';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import JourneyCycle from './JourneyCycle';
import InterviewsDialog from './InterviewsDialog';
import EditIcon from '@material-ui/icons/Edit';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import { Fields } from '../../Fields';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      /*'&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },*/
      /*'&.checkIn':{
        backgroundColor: '#fafa07'
      },
      '&.checkIn&:nth-of-type(odd)':{
        backgroundColor: '#e8e801'
      }*/
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 8
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

export function ServiceDialog(props) {
    const showServiceDialog = props.showServiceDialog || false
    const journeyData = props.journeyData || {}
    const isAdmin = props.isAdmin
    const userId = props.userId

    const disabled = props.isLoading || false

    const services = (journeyData.services && journeyData.services.length>0) ? journeyData.services : [{start: journeyData.dateCreated}]

    const [editId, setEditId] = useState();
    const [newData, setNewData] = useState({});

    const handleClose = () => {
        setNewData({})
        setEditId()
        props.setShowServiceDialog(false)
    }

    const handleStartEdit = id => {
        const service = services[id] || {}
        setNewData({...service})
        setEditId(id)
    }

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleCancel = () => {
        setNewData({})
        setEditId()
    }

    const handleSave = e => {
        e.preventDefault();

        let newServces = [...services]

        if(editId === -1){
            newServces.push({
                start:newData.start, 
                end:newData.end
            })
        } else {
            newServces[editId].start = newData.start
            newServces[editId].end = newData.end
        }

        newServces = newServces.sort((a, b) => {
            if (a.start <= b.start){
                return -1
            } else {
                return 1
            }
        })

        const beginServiceDate = newServces[newServces.length-1].start

        let paramsToPass = {data:{services:newServces, beginServiceDate}}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.preServerCall()

        AdminJHApi.updatePersonalJourneyDataAdmin(paramsToPass,(response)=>{
            props.postServerCall(null, response)

            setNewData({})
            setEditId()
        }, (error) => {
            console.log(error)
            props.postServerCall()
        })
    }

    const fields = [
        {name:'start', label:'Start', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6, required:true},
        {name:'end', label:'End', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
    ]

    return (
        <Dialog onClose={handleClose} open={showServiceDialog} fullWidth maxWidth="xs">
            <DialogTitle>Services Dates</DialogTitle>
            <List>
                {services.map((service, index) => (
                    <Fragment key={index}>
                        <ListItem>
                            {(editId===index)?<>
                                <form onSubmit={handleSave} style={{width:'100%'}}>
                                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                                    <br/>
                                    <Button
                                        disabled={disabled}
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        aria-label='Save'
                                        style={{marginRight:8}}
                                    >
                                        Save
                                    </Button>
                                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                                        Cancel
                                    </Button>
                                </form>
                            </>:<>
                                <ListItemText primary={showDateTimeFromString(service.start, false)+' - '+showDateTimeFromString(service.end, false)} />
                                <IconButton
                                    disabled={editId!==undefined}
                                    onClick={()=>{handleStartEdit(index)}}
                                    aria-label="edit"
                                >
                                    <EditIcon />
                                </IconButton>
                            </>}
                        </ListItem>
                    </Fragment>
                ))}
                {(editId===-1)?<ListItem>
                    <form onSubmit={handleSave} style={{width:'100%'}}>
                        <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                        <br/>
                        <Button
                            disabled={disabled}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label='Save'
                            style={{marginRight:8}}
                        >
                            Save
                        </Button>
                        <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                            Cancel
                        </Button>
                    </form>
                </ListItem>:<ListItem disabled={editId!==undefined}>
                    <IconButton
                        disabled={editId!==undefined}
                        onClick={() => handleStartEdit(-1)}
                        aria-label="Add"
                    >
                        <ListItemText primary=''/>
                        <AddIcon/>
                    </IconButton>
                </ListItem>}
            </List>
        </Dialog>
    )
}

export function CycleObjects(props) {
    const cycleObjects = props.cycleObjects || []
    const expanded = props.expanded || {}
    const name = props.name || ''
    const isAdmin = props.isAdmin || ''
    const colspan = props.colspan || 1
    const disabled = props.disabled || false
    const cyclesNoSummary = props.cyclesNoSummary || []
    const companies = props.companies || {}
    const selectedCompany = props.selectedCompany
    const groupInterviews = props.groupInterviews || false
    const talentOffers = props.talentOffers || []
    const summaryTypes = props.summaryTypes || []
    const isActiveJHUser = props.isActiveJHUser || false

    const lastEditObject = props.lastEditObject || {}
    const lastAddedObjectRef = props.lastAddedObjectRef

    const handleExpandClick = (id, type, company) => () => {
        if(props.handleExpandClick){
            props.handleExpandClick(id, type, company)
        }
    };

    const handleEditInterview = (interview, fieldsType, offer) => {   
        if (isActiveJHUser){
            props.handleEditInterview(interview, fieldsType, offer)
        }
    }

    const handleToggleComapny = (company) => {
        props.handleToggleComapny(company)
    }

    const classes = useStyles();

    return (
        <>
        {cycleObjects.map((beforeCycle, index) => { 
            const object = beforeCycle.object

            let interviewsCount
            let company
            let offer
            if (beforeCycle.objectType === 'Interview'){
                company = (object.company||'').toLowerCase().trim()
                interviewsCount = companies[company].interviews
                offer = talentOffers.find(talentOffer => company === (talentOffer.company||'').toLowerCase().trim())
            }

            let backgroundColor = ''
            if (index%2===0){
                backgroundColor = '#0000000a'
            }
            if (selectedCompany&&selectedCompany===company){
                backgroundColor = '#ffe9a7'
            }

            return (
                <Fragment key={beforeCycle.id}>
                    {(beforeCycle.objectType === 'Interview'&&((!groupInterviews)||(object.position===1)||(selectedCompany&&selectedCompany===company)))&&<>
                        <StyledTableRow style={{backgroundColor}} ref={(lastEditObject.type==='Interview' && lastEditObject.id===object._id)?lastAddedObjectRef:undefined}>
                            <TableCell style={{borderRight: '1px solid #e0e0e0', padding:0, textAlign: 'center'}}>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded.interviews.includes(object._id),
                                    })}
                                    size="small"
                                    onClick={handleExpandClick(object._id, 'interviews', object.company)}
                                    aria-expanded={expanded.interviews.includes(object._id)}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                <div /*style={{cursor: 'pointer',minHeight:20}} onClick={()=>{handleEditInterview(object)}}*/>
                                    {showDateTimeFromString(beforeCycle.date, false)}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', textTransform: 'capitalize'}}
                                dir="auto"
                            >
                                <div /*style={{cursor: 'pointer',minHeight:20}} onClick={()=>{handleEditInterview(object)}}*/>
                                    {object.title}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', textTransform: 'capitalize'}}
                            >
                                <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
                                    <span style={{marginRight: 8}}>{object.company}</span>
                                    {(interviewsCount>1)&&<span style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleToggleComapny(object.company)}}>
                                        <strong>{' ('+object.position+'/'+interviewsCount+')'}</strong>
                                    </span>}
                                    {isActiveJHUser&&<span onClick={()=>{props.handleNewInterview(object)}} style={{color: '#1861bf', cursor: 'pointer', marginLeft: 8,textAlign: 'right'}}> Add Interview</span>}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                <div /*style={{cursor: 'pointer',minHeight:20}} onClick={()=>{handleEditInterview(object)}}*/>
                                    {object.type}
                                </div>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                <div style={{cursor: 'pointer',minHeight:20}} onClick={()=>{handleEditInterview(object, 'status', offer)}}>
                                    {object.status}
                                </div>
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{backgroundColor:'#fff2cc', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px 0px inset, rgb(0 0 0 / 11%) 0px -2px 4px 0px inset'}}>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', padding: 0}}
                                dir="auto"
                                colSpan={colspan}
                            >
                                <Collapse in={expanded.interviews.includes(object._id)} timeout="auto" unmountOnExit> 
                                    <div style={{padding: '6px 24px 6px 16px'}}>
                                        {["They said no", "I said no"].includes(object.status)&&<div>
                                            <strong>Reason:</strong><br/> 
                                            <sapn dir="auto">{object.feedback}</sapn>
                                        </div>}
                                        {(object.status === 'Got an Offer' && offer)&&
                                        <div style={{color: '#1861bf', cursor: 'pointer', marginBottom:8}} onClick={()=>{handleEditInterview(object, 'status', offer)}}>
                                            <strong>Offer</strong>
                                        </div>}
                                        <div dir="auto">
                                            {/*<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'comment')}}>{object.reviewFreeText?'Edit ':'Add '}<strong>Client Summary</strong></div>*/}
                                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                                {object.reviewFreeText}
                                            </div>
                                            {(object.clientSummary||[]).map(summary => (
                                                <div key={summary.date}>
                                                    <strong>
                                                        {summary.isAdmin?(getNameFromEmail(summary.by) + ' - '):''}
                                                        {showDateTimeFromString(summary.date)}
                                                    </strong><br/>
                                                    <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary.content}</span>
                                                </div>
                                            ))}
                                            {isActiveJHUser&&<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'comment')}}><strong>Add Client Summary</strong></div>}
                                        </div>
                                        <div dir="auto" style={{marginTop:8}}>
                                            {/*(isAdmin)?<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'csComment')}}>{object.csComment?'Edit ':'Add '}<strong>Manager Summary</strong></div>:
                                            <div><strong>Summary</strong></div>*/}
                                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                                {object.csComment}
                                            </div>
                                            {(object.managerSummary||[]).map(summary => (
                                                <div key={summary.date}>
                                                    <strong>
                                                        {summary.isAdmin?(getNameFromEmail(summary.by) + ' - '):''}
                                                        {showDateTimeFromString(summary.date)}
                                                    </strong><br/>
                                                    <span dir="auto" style={{whiteSpace: 'pre-line', display: 'block'}}>{summary.content}</span>
                                                </div>
                                            ))}
                                            {(isAdmin)&&<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'csComment')}}><strong>Add Manager Summary</strong></div>}
                                        </div>
                                        <div dir="auto" style={{marginTop:8}}>
                                            {isActiveJHUser&&<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'contact')}}><strong>Contact</strong></div>}
                                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                                {((object.contact?(object.contact+'\n'):'') + 
                                                ((object.interviewerName||object.interviewerEmail||object.interviewerPhone)?`Interviewer: ${object.interviewerName||'No Name'} - ${object.interviewerEmail||'No email'} - ${object.interviewerPhone||'No phone'}`:''))}
                                            </div>
                                        </div>
                                        <div dir="auto" style={{marginTop:8}}>
                                            {!object.location&&isActiveJHUser&&<div style={{color: '#1861bf', cursor: 'pointer'}} onClick={()=>{handleEditInterview(object, 'location')}}><strong>Location</strong></div>}
                                            <div dir="auto" style={{whiteSpace:'pre-line'}}>
                                                {object.location}
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </TableCell>
                        </StyledTableRow>
                        </>}
                    {(beforeCycle.objectType === 'Summary'&&!object.cycleId)&&<>
                        <StyledTableRow style={{backgroundColor:object.title==='publication summary'?'#eaf1ff':'#d9ead3'}}>
                            <TableCell style={{borderRight: '1px solid #e0e0e0', padding:0, textAlign: 'center'}}>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded.summaries.includes(object._id),
                                    })}
                                    size="small"
                                    onClick={handleExpandClick(object._id, 'summaries')}
                                    aria-expanded={expanded.summaries.includes(object._id)}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                {showDateTimeFromString(object.date, false)}
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                                colSpan={colspan-2}
                            >
                                <strong>{(object.title==='publication summary'&&props.cycle&&props.cycle.position)?(displayPosition(props.cycle.position)+' '):''}{capitalize(object.title)||'Summary'}</strong>
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{backgroundColor:'#fff2cc', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px 0px inset, rgb(0 0 0 / 11%) 0px -2px 4px 0px inset'}}>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', padding: 0}}
                                dir="auto"
                                colSpan={colspan}
                            >
                                <Collapse in={expanded.summaries.includes(object._id)} timeout="auto" unmountOnExit> 
                                    <div style={{padding: '6px 24px 6px 16px'}}>
                                        <JourneySummary 
                                            cycle={props.cycle}
                                            index={object.index}
                                            date={object.date} 
                                            author={object.author} 
                                            summary={object.comment}
                                            title={object.title}
                                            summaryId={object.summaryId}
                                            isAdmin={isAdmin}
                                            name={name}
                                            journeyData={props.journeyData}
                                            handleSaveSummary={props.handleSaveSummary}
                                            disabled={disabled}
                                            cyclesNoSummary={cyclesNoSummary}
                                            summaryTypes={summaryTypes}
                                            startDate={props.startDate}/>
                                    </div>
                                </Collapse>
                            </TableCell>
                        </StyledTableRow>
                    </>}
                    {(beforeCycle.objectType === 'Freeze')&&<>
                        <StyledTableRow style={{backgroundColor:'#e0e0e0'}}>
                            <TableCell style={{borderRight: '1px solid #e0e0e0', padding:0, textAlign: 'center'}}>
                                
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                                {showDateTimeFromString(object.start, false)}
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                            >
                            </TableCell>
                            <TableCell
                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                dir="auto"
                                colSpan={colspan-2}
                            >
                                <strong>Freeze{object.end&&<span> until {showDateTimeFromString(object.end, false)}</span>}</strong>
                            </TableCell>
                        </StyledTableRow>
                    </>}
                </Fragment>
        )})}
        </>
    )
}

export default function JourneyTableView(props) {
    const isLoading = props.isLoading || false
    const journeyData = props.journeyData || {} 
    const isAdmin = props.isAdmin
    const userId = props.userId
    const journeyId = journeyData._id
    const isActiveJHUser = props.isActiveJHUser || false

    const allFreezeHistory = props.freezeHistory || []

    const services = (journeyData.services && journeyData.services.length>0) ? journeyData.services : [{start: journeyData.dateCreated}]
    const defServiceToShow = services.length-1

    //const [sortDesc, setSort] = useState(false);
    const [isLoadingInterviews, setIsLoadingInterviews] = useState(false);
    const [mainLoading, setMainLoading] = useState(true);

    const [interviews, setInterviews] = useState([]);
    const [companies, setCompanies] = useState({});
    const [cycles, setCycles] = useState([]);
    const [publishesData, setPublishesData] = useState([]);
    const [summaries, setSummaries] = useState([]);
    const [freezeHistory, setFreezeHistory] = useState([]);
    const [talentOffers, setTalentOffers] = useState([]);
    const [firstCycleDate, setFirstCycleDate] = useState();
    const [lastEditObject, setLastEditObject] = useState({});
    const [summaryTypes, setSummaryTypes] = useState([]);

    const [expanded, setExpanded] = useState({cycles:[], summaries:[], cycleSummaries:[], interviews:[]});
    const [isNewSummary, setIsNewSummary] = useState(false);

    const [objectToEdit, setObjectToEdit] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [groupInterviews, setGroupInterviews] = useState(false);

    const [showServiceDialog, setShowServiceDialog] = useState(false);

    const [serviceToShow, setServiceToShow] = useState(defServiceToShow);
    const disabled = isLoading || isLoadingInterviews
    const today = new Date().toISOString().substring(0, 10)

    const lastAddedObjectRef = useRef(null);

    useEffect(() => {
        if (journeyId && serviceToShow<=defServiceToShow){
            let paramsToPass = {serviceToShow}

            if (isAdmin){
                paramsToPass.userId = userId
            }

            setIsLoadingInterviews(true)
            setMainLoading(true)

            JHApi.getTimelineDataForUser(paramsToPass,(response)=>{
                setSummaryTypes(response.summaryTypes || [])
                setTalentOffers(response.talentOffers || [])
                if(response.interviews && response.interviews.length>0){
                    let interviewsWithPosition = []
                    let newCompanies = {}

                    response.interviews.forEach(interview => {
                        const company = (interview.company||'').toLowerCase().trim()
                        if (!newCompanies[company]){
                            newCompanies[company] = {interviews:0}
                        }

                        newCompanies[company].interviews++
                        interviewsWithPosition.push({...interview, position:newCompanies[company].interviews})
                    });

                    setCompanies(newCompanies)
                    setInterviews(interviewsWithPosition);
                } else {
                    setInterviews([]);
                }

                if(response.cycles && response.cycles.length>0){
                    let cyclesWithPosition = []
                    let nextFirstSent
                    let firstCycle

                    response.cycles.forEach((cycle, index) => {
                        cyclesWithPosition.push({...cycle, position:response.cycles.length-index, nextCycle:nextFirstSent})
                        nextFirstSent = cycle.firstSent

                        if (cycle.firstSent){
                            firstCycle = cycle.firstSent.substring(0, 10)
                        }
                    });

                    cyclesWithPosition = cyclesWithPosition.sort((a, b) => {
                        if (a.firstSent <= b.firstSent){
                            return -1
                        } else {
                            return 1
                        }
                    })
                    
                    setCycles(cyclesWithPosition);
                    setFirstCycleDate(firstCycle)
                } else {
                    setCycles([]);
                    setFirstCycleDate()
                }

                setPublishesData(response.publishesData || []);    
                setSummaries(response.summaries || [])

                const startService = response.startService
                const endService = response.endService
                setFreezeHistory(allFreezeHistory.filter(item => (!startService || item.start>=startService) && (!endService || item.start<endService)))
                //setRatingsFields(response.ratingsFields || [])
                //setTalentOffers(response.talentOffers || [])
                //setCommentToEdit({})
                setIsLoadingInterviews(false)
                setMainLoading(false)
            }, (error) => {
                setIsLoadingInterviews(false)
                setMainLoading(false)
                //setIsError(true)
            })
        } else {
            setInterviews([])
            setCycles([])
            setPublishesData([])
            setSummaries([])
            setFreezeHistory([])
            setFirstCycleDate()

            setExpanded({cycles:[], summaries:[], cycleSummaries:[], interviews:[]})
            setIsNewSummary(false)

            setObjectToEdit()

            setServiceToShow(defServiceToShow)
        }
    }, [journeyId, isAdmin, userId, serviceToShow, defServiceToShow, allFreezeHistory]);

    const handleExpandClick = (id, type, company) => {
        /*if (type === 'interviews'){
            console.log(type)
            console.log(company)
        } else {
            if (expanded[type].includes(id)){
                setExpanded({
                    ...expanded, 
                    [type]:expanded[type].filter(object=>object!==id)
                });
            } else {
                setExpanded({...expanded, [type]:[...expanded[type],id]});
            }
        }*/
        if (expanded[type].includes(id)){
            setExpanded({
                ...expanded, 
                [type]:expanded[type].filter(object=>object!==id)
            });
        } else {
            setExpanded({...expanded, [type]:[...expanded[type],id]});
        }
    };

    const startEditInterviews = () => {
        setIsLoadingInterviews(true)
    }

    const finishEditInterviews = (response) => {
        if(response.interviews && response.interviews.length>0){
            const sortedInterviews = response.interviews.sort((a, b) => {
                if (a.interviewDate <= b.interviewDate){
                    return -1
                } else {
                    return 1
                }
            })

            let interviewsWithPosition = []
            let newCompanies = {}

            sortedInterviews.forEach(interview => {
                const company = (interview.company||'').toLowerCase().trim()
                if (!newCompanies[company]){
                    newCompanies[company] = {interviews:0}
                }

                newCompanies[company].interviews++
                interviewsWithPosition.push({...interview, position:newCompanies[company].interviews})
            });

            setCompanies(newCompanies)
            setInterviews(interviewsWithPosition);
        } else {
            setInterviews([]);
        }

        setTalentOffers(response.talentOffers || [])
    
        setObjectToEdit()
        setIsLoadingInterviews(false)

        if (response.editedId){
            setLastEditObject({type:"Interview", id:response.editedId})
        }

        if (lastAddedObjectRef && lastAddedObjectRef.current){
            lastAddedObjectRef.current.scrollIntoView();
        }
    }

    const propsToChildren = {
        journeyData,
        isAdmin,
        userId,
        disabled,
        startEditInterviews,
        finishEditInterviews,
        setObjectToEdit,
    }

    const handleNewInterview = interview => {
        if (interview){
            setObjectToEdit({
                company: interview.company,
                companyUrl: interview.companyUrl,
                title: interview.title,
                location: interview.location,
                status: interview.status,
                interviewerName: interview.interviewerName,
                interviewerEmail: interview.interviewerEmail,
                interviewerPhone: interview.interviewerPhone,
                type:"HR Interview",
                seniorityLevel: interview.seniorityLevel,
                interviewDate:today,
            })
        } else {
            setObjectToEdit({
                interviewDate:today,
                type:"HR Interview",
                status:"Interviewing",
                seniorityLevel: journeyData.seniorityLevel||'',
            })
        }
    }

    const handleEditInterview = (interview, fieldsType, offer) => {
        setObjectToEdit({...interview, fieldsType, offer})
    }

    const handleSaveSummary = (params, cb) => {
        const {date, title, cycleId, cyclePosition} = params
        const startDate = services&&services[serviceToShow]?services[serviceToShow].start:''

        const cycle = cycles.find(cycle => {
                if (cycleId){
                    return cycle._id === cycleId
                } else {
                    return (date >= cycle.firstSent && (!cycle.nextCycle || date < cycle.nextCycle))
                }
            }
            
        ) || {};

        const oneDay = 1000 * 60 * 60 * 24;
        const daysSinceStart = date?Math.ceil((new Date(date) - new Date(cycle.firstSent || startDate))/oneDay):0
        const publicationString = cycle.position?(displayPosition(cycle.position) + ' Publication - '):''

        const cyclePositionText = (title==='publication summary'&&cyclePosition)?(displayPosition(cyclePosition)+' '):''

        const firstRow = ` - ${showDateTimeFromString(date, false)}${title?(' - '+cyclePositionText+capitalize(title)):''}${name?(' - '+name):''}`
        const secondRow = `${publicationString}${capitalize(journeyData.plan||'')} - ${daysSinceStart} ${(daysSinceStart===1?'day':'days')} passed${journeyData.title?' - ' + capitalize(journeyData.title):''}`

        let paramsToPass = {journeyId, editedComment:params, serviceToShow, firstRow, secondRow}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        setIsLoadingInterviews(true)
        props.preServerCall()
        
        JHApi.addEditInterviewCommentNew(paramsToPass,(response)=>{
            props.postServerCall(null, response)
            setIsLoadingInterviews(false)

            setSummaries(response.summaries || [])

            if(response.newCycles){
                let cyclesWithPosition = []
                let nextFirstSent
                let firstCycle

                response.newCycles.forEach((cycle, index) => {
                    cyclesWithPosition.push({...cycle, position:response.newCycles.length-index, nextCycle:nextFirstSent})
                    nextFirstSent = cycle.firstSent

                    if (cycle.firstSent){
                        firstCycle = cycle.firstSent.substring(0, 10)
                    }
                });

                cyclesWithPosition = cyclesWithPosition.sort((a, b) => {
                    if (a.firstSent <= b.firstSent){
                        return -1
                    } else {
                        return 1
                    }
                })
                
                setCycles(cyclesWithPosition);
                setFirstCycleDate(firstCycle)
            }

            if (params.id === -1){
                setIsNewSummary(false)
            } else {
                if(cb){
                    cb()
                }
            }            
        }, (error) => {
            props.postServerCall()
            setIsLoadingInterviews(false)
            if(cb){
                cb(error)
            }
        })
    }

    const handleNewSummary = () => {
        setIsNewSummary(!isNewSummary)
    }

    const handleCancelNewSummary = () => {
        setIsNewSummary(false)
    }

    const handleToggleComapny = company => {
        const companyValid = company?company.toLowerCase().trim():undefined

        if (selectedCompany === companyValid){
            setSelectedCompany()
        } else {
            setSelectedCompany(companyValid)
        }
    }

    const handleChangeServiceToShow = newInex => () => {
        setServiceToShow(newInex)
    }

    let beforeCycles = []

    if(!mainLoading){
        let filteretInterviews
        let filteretSummaries
        let filteretFreezeHistory

        if (firstCycleDate){
            filteretInterviews = interviews.filter(interview => interview.interviewDate < firstCycleDate)
            filteretSummaries = summaries.filter(summary => summary.date <= firstCycleDate)
            filteretFreezeHistory = freezeHistory.filter(freezeItem => freezeItem.start <= firstCycleDate)
        } else {
            filteretInterviews = [...interviews]
            filteretSummaries = [...summaries]
            filteretFreezeHistory = [...freezeHistory]
        }
        
        beforeCycles = [
            ...filteretSummaries.map((summary, index) => ({
                id: summary._id,
                date: summary.date,
                type: summary.title || 'Summary',
                objectType: 'Summary',
                object: summary,
            })),
            ...filteretInterviews.map((interview, index) => ({
                id: interview._id,
                date: interview.interviewDate,
                type: 'Interview',
                title: interview.title,
                objectType: 'Interview',
                object: interview,
            })),
            ...filteretFreezeHistory.map((freezeItem, index) => ({
                id: 'Freeze'+freezeItem.start,
                date: freezeItem.start,
                type: 'Freeze',
                objectType: 'Freeze',
                object: freezeItem,
            })),
        ]

        beforeCycles = beforeCycles.sort((a, b) => {
            if (a.date <= b.date){
                if (a.date === b.date && a.objectType === 'Interview' && b.objectType === 'Interview'){
                    if (a.object.company < b.object.company){
                        return -1
                    } else if (a.object.company === b.object.company){
                        if (a.object.position <= b.object.position){                            
                            return -1
                        } else {
                            return 1
                        }
                    } else {
                        return 1
                    }
                } else {
                    return -1
                }
            } else {
                return 1
            }
        })
    }

    const cells = [
        { id: 'date', align: "left", label: 'Date', type: 'DATE'}, 
        { id: 'title', align: "left", label: 'Title'},
        { id: 'type', align: "left", label: ''}, 
        { id: 'interviewType', align: "left", label: 'Interview Type'},
        { id: 'status', align: "left", label: 'Status'},
    ];

    const colspan = cells.length+1

    const name = journeyData.first_name + ' ' + journeyData.last_name

    const cyclesNoSummary = cycles.filter(filter=> !filter.csComment)

    const cycleObjectsParams = {
        handleNewInterview,
        handleEditInterview,
        disabled,
        expanded,
        name,
        isAdmin,
        colspan,
        handleExpandClick,
        handleSaveSummary,
        cyclesNoSummary,
        companies,
        selectedCompany,
        handleToggleComapny,
        groupInterviews,
        summaryTypes,
        journeyData,
        startDate:services&&services[serviceToShow]?services[serviceToShow].start:'',
        isActiveJHUser
    }

    const classes = useStyles();

    return (
        <>
            <InterviewsDialog {...propsToChildren} objectToEdit={objectToEdit} version='Basic' serviceToShow={serviceToShow}/>
            {isAdmin&&<ServiceDialog {...props} showServiceDialog={showServiceDialog} setShowServiceDialog={setShowServiceDialog}/>}

            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center'}} elevation={1}>
                {isAdmin&&<Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled || (serviceToShow+1 !== services.length)} 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8, marginTop:8, marginBottom:8}}
                    onClick={handleNewSummary}
                >{isNewSummary?'Cancel Add Manager Summary':'Add Manager Summary'}</Button>}
                {isActiveJHUser&&<Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled || (serviceToShow+1 !== services.length)} 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8, marginTop:8, marginBottom:8}}
                    onClick={()=>{handleNewInterview('')}}
                >Add Interview</Button>}
                <Button 
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8, marginTop:8, marginBottom:8}}
                    onClick={()=>{setGroupInterviews(!groupInterviews)}}
                >{groupInterviews ? 'Show all Interviews' : 'Group Interviews'}</Button>
                {/*<Button
                    variant='contained' 
                    color='primary' 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8, marginTop:8, marginBottom:8}}
                    onClick={handleChangeSort}
                >{sortDesc?'First To Last':'Last To First'}</Button>*/}
                <div style={{flex: "1 1 0%"}}></div>
                {isAdmin&&<Button
                    variant='contained' 
                    color='primary' 
                    disabled={disabled} 
                    size='small' 
                    style={{textTransform: 'capitalize', marginRight: 8, marginTop:8, marginBottom:8}}
                    onClick={()=>{setShowServiceDialog(true)}}
                >Service Dates</Button>}
                {(services.length>1)&&<div>
                    <IconButton
                        onClick={handleChangeServiceToShow(serviceToShow-1)}
                        disabled={disabled || serviceToShow===0}
                    >
                        <ChevronLeftIcon/>
                    </IconButton>
                    <span>{1+serviceToShow}/{services.length}</span>
                    <IconButton
                        onClick={handleChangeServiceToShow(serviceToShow+1)}
                        disabled={disabled || serviceToShow===services.length-1}
                    >
                        <ChevronRightIcon/>
                    </IconButton>
                </div>}
            </Paper>
            {isNewSummary&&<Paper style={{margin: '2px 4px', padding: '6px 24px 6px 16px', backgroundColor: '#fff2cc'}} elevation={1}>
                <JourneySummary newSummary handleCancelNewSummary={handleCancelNewSummary}
                    isAdmin={isAdmin}
                    name={name}
                    handleSaveSummary={handleSaveSummary}
                    disabled={disabled}
                    cyclesNoSummary={cyclesNoSummary}
                    summaryTypes={summaryTypes}/>
            </Paper>}
            <div style={{margin:'2px 0', position:'relative', overflow:'auto'}}>
                <Table stickyHeader size="small">
                    <TableHead color='primary'>
                        <TableRow>
                            {props.withIndex&&<StyledTableCell
                                style={{minWidth:1, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                            >
                            </StyledTableCell>}
                            <StyledTableCell style={{minWidth:1, padding: 0, borderRight: '1px solid #e0e0e0'}}>
                            </StyledTableCell>
                            {cells.map(cell => (
                                <StyledTableCell
                                    key={cell.id} 
                                    align={cell.align}
                                    style={{minWidth:cell.minWidth||10, fontWeight: 'bold', borderRight: '1px solid #e0e0e0'}}
                                >
                                    {cell.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <CycleObjects {...cycleObjectsParams} cycleObjects={beforeCycles} lastEditObject={lastEditObject} lastAddedObjectRef={lastAddedObjectRef} talentOffers={talentOffers}/>

                        {cycles.map(cycle => {
                            const publishesDataForCycle = publishesData.find(row => row._id === cycle.publishId) || {};

                            let cycleObjects = []

                            const firstSent = (cycle.firstSent||'').substring(0, 10)
                            const nextCycle = (cycle.nextCycle||'').substring(0, 10)

                            const filteretInterviews = interviews.filter(interview => interview.interviewDate >= firstSent && (!nextCycle || interview.interviewDate < nextCycle))
                            const filteretSummaries = summaries.filter(summary => summary.date > firstSent && (!nextCycle || summary.date <= nextCycle))
                            const filteretFreezeHistory = freezeHistory.filter(freezeItem => freezeItem.start > firstSent && (!nextCycle || freezeItem.start <= nextCycle))
                            
                            cycleObjects = [
                                ...filteretSummaries.map((summary, index) => ({
                                    id: summary._id,
                                    date: summary.date,
                                    type: summary.title || 'Summary',
                                    objectType: 'Summary',
                                    object: summary,
                                })),
                                ...filteretInterviews.map((interview, index) => ({
                                    id: interview._id,
                                    date: interview.interviewDate,
                                    type: 'Interview',
                                    title: interview.title,
                                    objectType: 'Interview',
                                    object: interview,
                                })),
                                ...filteretFreezeHistory.map((freezeItem, index) => ({
                                    id: 'Freeze'+freezeItem.start,
                                    date: freezeItem.start,
                                    type: 'Freeze',
                                    objectType: 'Freeze',
                                    object: freezeItem,
                                })),
                            ]
                    
                            cycleObjects = cycleObjects.sort((a, b) => {
                                if (a.date <= b.date){
                                    if (a.date === b.date && a.objectType === 'Interview' && b.objectType === 'Interview'){
                                        if (a.object.company < b.object.company){
                                            return -1
                                        } else if (a.object.company === b.object.company){
                                            if (a.object.position <= b.object.position){                            
                                                return -1
                                            } else {
                                                return 1
                                            }
                                        } else {
                                            return 1
                                        }
                                    } else {
                                        return -1
                                    }
                                } else {
                                    return 1
                                }
                            })

                            return (
                                <Fragment key={cycle._id}>
                                    {cycle.firstSent&&<>
                                        <StyledTableRow style={{backgroundColor:'#c9daf8'}}>
                                            <TableCell style={{borderRight: '1px solid #e0e0e0', padding:0, textAlign: 'center'}}>
                                                {<IconButton
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expanded.cycles.includes(cycle._id),
                                                    })}
                                                    size="small"
                                                    onClick={()=>{handleExpandClick(cycle._id, 'cycles')}}
                                                    aria-expanded={expanded.cycles.includes(cycle._id)}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>}
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                                {showDateTimeFromString(cycle.firstSent, false)}
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                                {publishesDataForCycle.emailTitle}
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                                colSpan={colspan-2}
                                            >
                                                <strong>{displayPosition(cycle.position) + ' Publication'}</strong>
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow style={{backgroundColor:'#fff2cc', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px 0px inset, rgb(0 0 0 / 11%) 0px -2px 4px 0px inset'}}>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', padding: 0}}
                                                dir="auto"
                                                colSpan={colspan}
                                            >
                                                <Collapse in={expanded.cycles.includes(cycle._id)} timeout="auto" unmountOnExit> 
                                                    <div style={{padding: '6px 24px 6px 16px'}}>
                                                        <JourneyCycle
                                                            cycle = {cycle}
                                                            publishesDataForCycle = {publishesDataForCycle}
                                                            isAdmin = {isAdmin}
                                                        />
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </StyledTableRow>

                                        <CycleObjects {...cycleObjectsParams} cycle={cycle} cycleObjects={cycleObjects} lastEditObject={lastEditObject} lastAddedObjectRef={lastAddedObjectRef} talentOffers={talentOffers}/>

                                        {cycle.csComment?<>
                                        <StyledTableRow style={{backgroundColor:'#eaf1ff'}}>
                                            <TableCell style={{borderRight: '1px solid #e0e0e0', padding:0, textAlign: 'center'}}>
                                                <IconButton
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: expanded.cycleSummaries.includes(cycle._id),
                                                    })}
                                                    size="small"
                                                    onClick={()=>{handleExpandClick(cycle._id, 'cycleSummaries')}}
                                                    aria-expanded={expanded.cycleSummaries.includes(cycle._id)}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                                {showDateTimeFromString(cycle.summaryDate, false)}
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                            >
                                            </TableCell>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0'}}
                                                dir="auto"
                                                colSpan={colspan-2}
                                            >
                                                <strong>{displayPosition(cycle.position) + ' Publication Summary'}</strong>
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow style={{backgroundColor:'#fff2cc', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px 0px inset, rgb(0 0 0 / 11%) 0px -2px 4px 0px inset'}}>
                                            <TableCell
                                                style={{whiteSpace: 'pre-line', borderRight: '1px solid #e0e0e0', padding: 0}}
                                                dir="auto"
                                                colSpan={colspan}
                                            >
                                                <Collapse in={expanded.cycleSummaries.includes(cycle._id)} timeout="auto" unmountOnExit> 
                                                    <div style={{padding: '6px 24px 6px 16px'}}>
                                                        <JourneySummary
                                                            date={cycle.summaryDate} 
                                                            author={cycle.summaryAuthor} 
                                                            summary={cycle.csComment}
                                                            summaryId={cycle.summaryId}
                                                            title={displayPosition(cycle.position) + ' Publication Summary'}
                                                            isAdmin={isAdmin}
                                                            name={name}
                                                            handleSaveSummary={handleSaveSummary}
                                                            disabled={disabled}
                                                            cycle={cycle}
                                                            journeyData={journeyData}
                                                            cyclesNoSummary={cyclesNoSummary}
                                                            summaryTypes={summaryTypes}
                                                            cycleSummary/>
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </StyledTableRow>
                                        </>:<></>}
                                    </>}
                                </Fragment>
                        )})}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}