import React, { useState, useEffect } from 'react';
import AdminJHApi from "../../utils/AdminJHApi"
import { Button, Dialog, DialogActions, DialogContent, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JSData from '../JSData';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import JSTablePagination from '../JSTablePagination';
import SynamediaClientsStatusDialog from './SynamediaClientsStatusDialog';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FIELD_TYPES, capitalize, downloadCSV, getCSVContent } from '../../utils/utils';
import { Fields } from '../Fields';
import GetAppIcon from '@material-ui/icons/GetApp';
import HistogramC from '../../containers/HistogramC';

function Search(props) {
    const cells = props.cells || []

    const filteredCells = cells.filter(cell => cell.search)

    const [search, setSearch] = useState('');
    const [fields, setFields] = useState([]);

    const handleChangeSearch = e => {
        setSearch(e.target.value)
    };

    const handleToggleField = (event, newFields) => {
        setFields(newFields||[]);
    };

    const handleSearch = () => {
        props.handleSearch(search, fields)
    }

    return (
        <>
            <TextField size='small' variant='outlined' placeholder="Search" margin="none" value={search || ''} onChange={handleChangeSearch} type="search"/>
            {(filteredCells.length>0)&&<ToggleButtonGroup value={fields} onChange={handleToggleField} size='small' style={{margin: '0 8px'}}>
                {filteredCells.map(cell => (
                    <ToggleButton key={cell.id} value={cell.id} aria-label={cell.label}>
                        {cell.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>}
            <IconButton disabled={props.disabled||fields.length===0} aria-label="Search" color='primary' style={{padding: 10}} onClick={handleSearch}>
                <SearchIcon fontSize='small' />
            </IconButton>
        </>
    )
}

function SearchExtra(props) {
    const extraSearch = props.extraSearch || {}
    const showExtraSearch = props.showExtraSearch || false
    const disabled = props.disabled || false
    const plans = props.plans || []
    const statuses = props.statuses || []
    const extraSearchField = props.extraSearchField

    const [newData, setNewData] = useState({...extraSearch});

    useEffect(() => {
        if(showExtraSearch){
            setNewData({...extraSearch})
        }
    }, [showExtraSearch, extraSearch]);

    const handleCancel = () => {
        setNewData({...extraSearch})
        props.setShowExtraSearch(false)
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        props.search(newData)
    }

    const plansOptions = [{value:'', label:'-All-'}, ...plans.map(plan=>{return {value:plan, label:capitalize(plan)}})]

    let fields = [
        { name: 'from', label:'From', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'to', label:'To', defaultValue: '', type:FIELD_TYPES.DATE2, sm:6},
        { name: 'status', label: 'Status', defaultValue: '', options:statuses, type:FIELD_TYPES.SELECT, sm:12}, 
        { name: 'plan', label: 'Plan', defaultValue: '', options:plansOptions, type:FIELD_TYPES.SELECT, sm:12}, 
    ]

    if (extraSearchField){
        fields = fields.filter(field => field.name === extraSearchField)
    }

    return (
        <Dialog onClose={handleCancel} open={showExtraSearch} fullWidth maxWidth="xs">
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                    {<Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Search'
                    >
                        Search
                    </Button>}
                </DialogActions>
            </form>
        </Dialog>
    )
}

function EditDClientDialog(props){
    const editDialog = props.editDialog || {}
    const isOpen = editDialog.isOpen || false
    const id = editDialog.id
    const amount = editDialog.amount

    const disabled = editDialog.disabled || false

    const [newData, setNewData] = useState({});

    useEffect(() => {
        if(isOpen){
            setNewData({amount:amount})
        }
    }, [isOpen, id, amount]);

    const handleCancel = () => {
        setNewData({})
        props.setEditDialog()
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        //const handleEdit = (id, newData) => {
        props.handleEdit(id, newData)
    }

    const fields = [
        { name: 'amount', label:'Amount', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'number'},
    ]

    return (
        <Dialog onClose={handleCancel} open={isOpen} fullWidth maxWidth="xs">
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Close">
                        Close
                    </Button>
                    {<Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>}
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default function SynamediaClientsStatus(props) {
    const [hasPermmision, setHasPermmision] = useState(false);
    const [isCAdmin, setIsCAdmin] = useState(false);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [cells, setCells] = useState([]);
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState(false);

    const [plans, setPlans] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [search, setSearch] = useState('');
    const [showExtraSearch, setShowExtraSearch] = useState(false);
    const [extraSearch, setExtraSearch] = useState({});
    const [extraSearchField, setExtraSearchField] = useState();
    const [fieldsToSearch, setFieldsToSearch] = useState([]);
    const [page, setPage] = useState(0);

    const [showHistory, setShowHistory] = useState(false);
    const [objectId, setObjectId] = useState();
    const [object, setObject] = useState();

    const [editDialog, setEditDialog] = useState();

    useEffect(() => {
        const now = new Date();
        const from = new Date(now.getFullYear()-1, now.getMonth(), now.getDate() + 1).toISOString()
        const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2).toISOString()

        const searchParams = new URLSearchParams(window.location.search);
        const defPlan = searchParams.get("plan") || '';

        AdminJHApi.getSynamediaClientsData({extraSearch:{from, to, plan:defPlan}},(response)=>{
            setHasPermmision(response.hasPermmision)
            setIsCAdmin(response.isCAdmin)
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPlans(response.plans||[])
            setExtraSearch({from, to, plan:defPlan})
            setError()
            setStatuses([{value:'', label:'-All-'}, ...(response.statuses||[]).map(status=>{return {value:status, label:capitalize(status)}})])
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
        })
    }, []);

    const handleChangePage = newPage => {
        setDisabled(true)

        AdminJHApi.getSynamediaClientsData({search, fieldsToSearch, extraSearch, page:newPage},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(newPage);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    };

    const handleSearch = (search, fieldsToSearch) => {
        setSearch(search||'')
        setFieldsToSearch(fieldsToSearch||[])
        setDisabled(true)

        AdminJHApi.getSynamediaClientsData({search, fieldsToSearch, extraSearch},(response)=>{
            setData(response.data||[])
            setCells(response.cells||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleShowFilter = () => {
        setExtraSearchField()
        setShowExtraSearch(true)
    };

    const handleExtraSearch = newSearch => {
        setExtraSearch(newSearch||{})
        setShowExtraSearch(false)
        setDisabled(true)

        AdminJHApi.getSynamediaClientsData({search, fieldsToSearch, extraSearch:newSearch},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setPage(0);
            setError()
            setDisabled(false)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleEdit = (id, newData) => {
        setDisabled(true)
        AdminJHApi.editClientsData({id, newData, search, fieldsToSearch, extraSearch, page},(response)=>{
            setData(response.data||[])
            setCount(response.count||0)
            setError()
            setDisabled(false)
            setEditDialog()
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            setDisabled(false)
        })
    }

    const handleShowHistory=(row, params={})=>{
        if ( isCAdmin && params.cell === 'amount'){
            setEditDialog({isOpen:true, id: row._id, amount: row.amount})
        } else {
            setShowHistory(true)
            setObjectId(row._id)
            setObject(row)
        }
    }

    const handleShowFilterColumn=(field)=>{
        setExtraSearchField(field)
        setShowExtraSearch(true)
    }

    const handleHideHistory=(row, params={})=>{
        setShowHistory(false)
        setObjectId()
    }

    const exportToCsv = () => {
        if (count > 100){
            AdminJHApi.getSynamediaClientsData({search, fieldsToSearch, extraSearch, noLimit:true},(response)=>{
                const csvContent = getCSVContent(response.cells||[], response.data||[])
                downloadCSV(csvContent, `JobHunt Clients.csv`)
            }, (error) => {
                if(error&&error.response&&error.response.data){
                    setError(error.response.data)
                } else {
                    setError('Something went wrong')
                }
                setDisabled(false)
            })
        } else {
            const csvContent = getCSVContent(cells, data)
            downloadCSV(csvContent, `JobHunt Clients.csv`)
        }
    }

    return (
        <>
        {hasPermmision?<div className="fullWidthContainer" >
            <SynamediaClientsStatusDialog showHistory={showHistory} object={object} objectId={objectId}  handleHideHistory={handleHideHistory}/>

            <SearchExtra 
                extraSearch={extraSearch} 
                disabled={disabled} 
                showExtraSearch={showExtraSearch} 
                setShowExtraSearch={setShowExtraSearch} 
                search={handleExtraSearch}
                plans={plans}
                statuses={statuses}
                extraSearchField={extraSearchField}
            />

            <EditDClientDialog
                editDialog={editDialog}
                disabled={disabled} 
                setEditDialog={setEditDialog}
                handleEdit={handleEdit}
            />

            <Paper style={{margin:'0 4px', padding: 8, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} elevation={1}>
                <div>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    {(cells.length>0)&&<Search handleSearch={handleSearch} disabled={disabled} cells={cells}/>}
                </div>
                <IconButton disabled={props.disabled} aria-label="Filter" color='primary' style={{padding: 10}} onClick={handleShowFilter}>
                    <FilterListIcon fontSize='small' />
                </IconButton>
            </Paper>
            <Paper style={{margin:'2px 4px', position:'relative', overflow:'auto', height:'calc(100% - 112px)'}} elevation={1}>
                <JSData cells={cells} data={data} handleEditRow={handleShowHistory} handleShowFilterColumn={handleShowFilterColumn}/>
            </Paper>
            <Paper style={{margin:'0 4px'}} elevation={1}>
                <JSTablePagination
                    count={count}
                    rowsPerPage={100}
                    page={page}
                    onChangePage={handleChangePage}
                    disabled={disabled}
                >
                    <IconButton onClick={exportToCsv} disabled={disabled} color='primary'>
                        <GetAppIcon/>
                    </IconButton>
                </JSTablePagination>
            </Paper>
        </div>:<HistogramC histogramType={'main'} />}
        </>
    )
}