import React, { Component } from 'react'
import { connect } from 'react-redux'

import Histogram from '../components/Histogram'
import { setPageAndFilter } from '../redux/actions/histogramJobs'

class HistogramObjectsC extends Component {
    handlePageClick = (page) => {
        const { dispatch } = this.props
        dispatch(setPageAndFilter(page))
    }

    render() {
        const { page, fetchingData, filteredJobs, hiddenList } = this.props

        return (
            <React.Fragment>
                <Histogram 
                    hiddenList={hiddenList}
                    isFetchingJobs={fetchingData} 
                    histogramObjects={filteredJobs} 
                    page={page}
                    showJobCardDialog={this.props.showJobCardDialog} 
                    handleScroll={this.props.handleScroll} 
                    view={this.props.view}
                    handlePageClick={this.handlePageClick}
                />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const { page, isFetching } = state.histogramJobs;
    const { isFetching: isFetchingJob } = state.selectedJob;
    const { hiddenList } = state.histogramData;
    let { filteredJobs } = state.histogramJobs;

    const fetchingData = (isFetching || isFetchingJob)

    return {
        filteredJobs, page, fetchingData, hiddenList: hiddenList||false
    }
}

export default connect(mapStateToProps)(HistogramObjectsC)