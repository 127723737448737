import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PublicIcon from '@material-ui/icons/Public';
import TextField from '@material-ui/core/TextField';

import FacebookIcon from 'react-share/lib/FacebookIcon'
import LinkedinIcon from 'react-share/lib/LinkedinIcon'
import TwitterIcon from 'react-share/lib/TwitterIcon'
import GithubIcon from 'mdi-material-ui/Github'

import { ProfileActions } from './ProfileActions'

import '../../styles/Profile.css'
import { validUrl } from '../../utils/utils';

export default class Links extends Component {
    state = {
        editMode: false
    }

    sectionFields = ['social_links'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })
        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        const newLinks = Object.assign({}, this.props.userInfo.social_links, {
            [event.target.name]: event.target.value
        })

        this.props.handleChange('social_links', newLinks)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    }

    render() {
        const { userInfo, noEdit } = this.props
        const links = userInfo.social_links || {}
        const hasLinks = (links.github || links.linkedin || links.facebook || links.twitter || links.global)

        return (
            <React.Fragment>
                {(noEdit)?<React.Fragment>
                    {(hasLinks)&&<div className="resumeSection">
                        <div className="sectionTitle">Websites</div>
                        <div className="profileField">
                            <Grid container spacing={3}>
                                {(links.github)&&<Grid item sm={6} xs={12}>
                                    <div className="profileLink">
                                        <div className="icon">
                                            <GithubIcon size={24}/>
                                        </div>
                                        <a href={validUrl(links.github)} className="link" target="_blank" rel="noopener noreferrer">{links.github}</a>
                                    </div>
                                </Grid>}
                                {(links.linkedin)&&<Grid item sm={6} xs={12}>
                                    <div className="profileLink">
                                        <div className="icon">
                                            <LinkedinIcon round size={24}/>
                                        </div>
                                        <a href={validUrl(links.linkedin)} className="link" target="_blank" rel="noopener noreferrer">{links.linkedin}</a>
                                    </div>
                                </Grid>}
                                {(links.facebook)&&<Grid item sm={6} xs={12}>
                                    <div className="profileLink">
                                        <div className="icon">
                                            <FacebookIcon round size={24}/>
                                        </div>
                                        <a href={validUrl(links.facebook)} className="link" target="_blank" rel="noopener noreferrer">{links.facebook}</a>
                                    </div>
                                </Grid>}
                                {(links.twitter)&&<Grid item sm={6} xs={12}>
                                    <div className="profileLink">
                                        <div className="icon">
                                            <TwitterIcon round size={24}/>
                                        </div>
                                        <a href={validUrl(links.twitter)} className="link" target="_blank" rel="noopener noreferrer">{links.twitter}</a>
                                    </div>
                                </Grid>}
                                {(links.global)&&<Grid item sm={6} xs={12}>
                                    <div className="profileLink">
                                        <div className="icon">
                                            <PublicIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
                                        </div>
                                        <a href={validUrl(links.global)} className="link" target="_blank" rel="noopener noreferrer">{links.global}</a>
                                    </div>
                                </Grid>}
                            </Grid>
                        </div>
                    </div>}
                </React.Fragment>:

            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">Websites</div>
                {(this.state.editMode)?
                <form onSubmit={this.handleSaveSection}>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField id="github" name="github" label="Github" margin="none" fullWidth 
                                value={links.github || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="linkedin" name="linkedin" label="Linkedin" margin="none" fullWidth 
                                value={links.linkedin || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="facebook" name="facebook" label="Facebook" margin="none" fullWidth 
                                value={links.facebook || ''} onChange={this.handleChange}/> 
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="twitter" name="twitter" label="Twitter" margin="none" fullWidth 
                                value={links.twitter || ''} onChange={this.handleChange}/> 
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="global" name="global" label="Other" margin="none" fullWidth 
                                value={links.global || ''} onChange={this.handleChange}/> 
                        </Grid>
                    </Grid>
                    <div className="profileButtons">
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.handleCancel}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save Changes"
                        >
                            Save Changes
                        </Button>
                    </div>
                </form> :
                <React.Fragment>
                    <ProfileActions handleEdit={this.handleEdit}/>
                    <div className="profileField">
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <div className="profileLink">
                                    <div className="icon">
                                        <GithubIcon size={24}/>
                                    </div>
                                    <a href={validUrl(links.github)} className="link" target="_blank" rel="noopener noreferrer">{links.github}</a>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className="profileLink">
                                    <div className="icon">
                                        <LinkedinIcon round size={24}/>
                                    </div>
                                    <a href={validUrl(links.linkedin)} className="link" target="_blank" rel="noopener noreferrer">{links.linkedin}</a>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className="profileLink">
                                    <div className="icon">
                                        <FacebookIcon round size={24}/>
                                    </div>
                                    <a href={validUrl(links.facebook)} className="link" target="_blank" rel="noopener noreferrer">{links.facebook}</a>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className="profileLink">
                                    <div className="icon">
                                        <TwitterIcon round size={24}/>
                                    </div>
                                    <a href={validUrl(links.twitter)} className="link" target="_blank" rel="noopener noreferrer">{links.twitter}</a>
                                </div>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className="profileLink">
                                    <div className="icon">
                                        <PublicIcon style={{color:"rgba(0, 0, 0, 0.54)"}}/>
                                    </div>
                                    <a href={validUrl(links.global)} className="link" target="_blank" rel="noopener noreferrer">{links.global}</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>      
                </React.Fragment>}
            </Paper>}
            </React.Fragment>
        )
    }
}