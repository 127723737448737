import React, { Fragment, useState } from 'react';
import {Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField, Typography, FormLabel, RadioGroup, Radio, BottomNavigationAction, BottomNavigation } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { FIELD_TYPES, displayCash } from '../utils/utils';
import LocationField from './LocationField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FieldTitle from './FieldTitle';

export function Fields(props) {
    const fields = props.fields || []
    const showData = props.showData || false

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOptions, setMenuOptions] = useState([]);
    const [optionField, setOptionField] = useState();

    const handleChangeCheckBox = (event) => {
        props.handleChange({target:{name:event.target.name, value:event.target.checked}})
        //props.handleChangeFinal(event.target.name, event.target.checked)
    };

    const getCurrencyValue = (fieldName, defaultValue) => {
        const currenciesLabels = {'ILS':'₪', 'USD':'$', 'EUR':'€', 'GBP':'£'}
        const value = props.getValue(fieldName, defaultValue)
        return currenciesLabels[value] || defaultValue
    }

    const getFrequencyValue = (fieldName, defaultValue) => {
        const frequenciesLabels = {'HOUR':'Hourly', 'MONTH':'Monthly', 'YEAR':'Annual'}
        const value = props.getValue(fieldName, defaultValue)
        return frequenciesLabels[value] || defaultValue
    }

    const showOptionsCurrency = fieldName => (event) => {
        setAnchorEl(event.currentTarget);
        setOptionField(fieldName)
        setMenuOptions([
            {
                value: 'ILS',
                label: '₪',
            }, {
                value: 'USD',
                label: '$',
            }, {
                value: 'EUR',
                label: '€',
            }, {
                value: 'GBP',
                label: '£'
            }
        ])
    }

    const showOptionsFrequency = fieldName => (event) => {
        setAnchorEl(event.currentTarget);
        setOptionField(fieldName)
        setMenuOptions([
            {
                value: 'HOUR',
                label: 'Hourly',
            }, {
                value: 'MONTH',
                label: 'Monthly',
            }, {
                value: 'YEAR',
                label: 'Annual',
            }
        ])
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectValue = value => () => {
        setAnchorEl(null);
        props.handleChangeField(optionField, value)
    }

    return (
        <Grid container spacing={3}>
            {fields.map(field =>
                <Fragment key={field.name}>
                    {!field.hide&&<>
                    {showData?<>
                        <Grid item sm={field.sm || 6} xs={field.xs || 12}>
                            <Typography dir="auto"><strong>{field.label}</strong></Typography>
                            {(() => {
                                switch (field.type) {
                                    case FIELD_TYPES.CASH:
                                        return <Typography dir="auto" style={{textAlign:'left'}}>
                                            {displayCash({value:props.getValue(field.name, ''), 
                                                currency:props.getValue(field.curField, ''), 
                                                frequency:props.getValue(field.freqField, '')
                                            })}
                                        </Typography>
                                    default:
                                        return <Typography dir="auto" style={{textAlign:'left'}}>{props.getValue(field.name, field.defaultValue)}</Typography>
                                    }
                            })()}
                        </Grid>
                    </>:<>
                    {(field.type===FIELD_TYPES.TEXT)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <TextField name={field.name} label={field.label} margin="none" fullWidth 
                            required={field.required||false} value={props.getValue(field.name, field.defaultValue)} onChange={props.handleChange}
                            helperText={field.helperText} type={field.inputType} onBlur={props.handleBlur} placeholder={field.placeholder}
                            inputRef={props.focus===field.name?input => input && input.focus():undefined}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.DATE_TIME)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <TextField
                            type="datetime-local" name={field.name} label={field.label} margin="none" fullWidth 
                            value={props.getValue(field.name, field.defaultValue).substr(0, 16)} onChange={props.handleChange}
                            required={field.required||false} InputLabelProps={{shrink: true}}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.DATE2)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <TextField
                            type="date" name={field.name} label={field.label} margin="none" fullWidth 
                            value={props.getValue(field.name, field.defaultValue).substr(0, 10)} onChange={props.handleChange}
                            required={field.required||false} InputLabelProps={{shrink: true}}
                            inputRef={props.focus===field.name?input => input && input.focus():undefined}
                            inputProps={{lang:"GB"}}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.MULTILINE)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <TextField name={field.name} label={field.label} margin="none" fullWidth variant={field.variant}
                            required={field.required||false} value={props.getValue(field.name, field.defaultValue)} onChange={props.handleChange}
                            multiline helperText={field.helperText} type={field.inputType} rows={field.rows} maxRows={field.rowsMax} onBlur={props.handleBlur}  placeholder={field.placeholder}
                            inputRef={props.focus===field.name?input => input && input.focus():undefined}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.SELECT)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <FormControl fullWidth required={field.required||false}>
                            <InputLabel shrink  htmlFor={field.name}>{field.label}</InputLabel>
                            <Select
                                value={props.getValue(field.name, field.defaultValue)}
                                onChange={props.handleChange}  placeholder={field.placeholder}
                                name={field.name} type={field.inputType} displayEmpty={field.displayEmpty}
                            >
                                {field.options&&field.options.map(option => (
                                    <MenuItem  
                                        key={(option.value === undefined) ? option.label : option.value} 
                                        value={(option.value === undefined) ? option.label : option.value} 
                                        disabled={option.isGroupTitle}
                                        style={option.isGroupTitle?{fontWeight: 700, opacity: 1}:{}}
                                    >
                                        {option.icon&&<ListItemIcon>
                                            {option.icon}
                                        </ListItemIcon>}
                                        <Typography variant="inherit">{option.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>}
                    {(field.type===FIELD_TYPES.RADIO)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <FormControl component="fieldset" error={field.error}>
                            <FormLabel component="legend">{field.label} {field.required?'*':''}</FormLabel>
                            <RadioGroup aria-label={field.label} name={field.name} value={props.getValue(field.name, field.defaultValue)} onChange={props.handleChange}>
                                <Grid container spacing={0}>                                
                                {field.options&&field.options.map(option => (
                                    <Grid key={option.value || option.label} item sm={6} xs={12}>
                                    <FormControlLabel value={option.value||option.label} control={<Radio color='primary'/>} label={option.label} />
                                    </Grid>
                                ))}
                                </Grid>
                            </RadioGroup>
                        </FormControl>
                    </Grid>}

                    {(field.type===FIELD_TYPES.CHECK_BOX_GROUP)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <FormControl component="fieldset" error={field.error}>
                            <FormLabel component="legend">{field.label} {field.required?'*':''}</FormLabel>
                            {field.options&&field.options.map(option => (
                                <FormControlLabel key={option.value || option.label}
                                    control={<Checkbox color='primary' checked={props.getValue(option.name, option.defaultValue)||false} onChange={handleChangeCheckBox} name={option.name} />}
                                    label={option.label} disabled={field.disabled||false}
                                />
                            ))}
                        </FormControl>
                    </Grid>}                    
                    {(field.type===FIELD_TYPES.RADIO_ICONS)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <FormControl error={field.error}>
                            <FormLabel component="legend">{field.label} {field.required?'*':''}</FormLabel>
                        </FormControl>
                        <div>
                        <BottomNavigation 
                            showLabels 
                            value={field.options.findIndex(option => (option.value || option.label) === (props.getValue(field.name, field.defaultValue)))}
                            onChange={(event, newValue) => {
                                props.handleChange({target:{name:field.name, value:field.options[newValue].label}})
                            }}>
                            {field.options&&field.options.map(option => (
                                <BottomNavigationAction 
                                    key={option.value || option.label} 
                                    label={option.label} 
                                    icon={option.icon} 
                                    classes={{ selected: "selectedRadioIcon" }}  
                                />
                            ))}
                        </BottomNavigation>
                            {
                                /*
                                <Button key={option.value || option.label}
                                    onClick={()=>{props.handleChange({target:{name:field.name, value:option.value||option.label}})}}
                                    color="primary"
                                    startIcon={option.icon}
                                    variant={(props.getValue(field.name, field.defaultValue) === (option.value||option.label))?'contained':'outlined'}
                                    style={{marginRight:8}}
                                >{option.label}</Button>
                                */
                            }
                        </div>
                    </Grid>}
                    {(field.type===FIELD_TYPES.LOCATION)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <LocationField
                            handleLocationChange={props.handleLocationChange} 
                            locationText={field.value || ''} 
                            setLocationText={props.handleLocationType}  
                            label={field.label}
                            margin="none" 
                            fullWidth
                            required={field.required||false}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.DATE)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                margin="none" fullWidth disableFuture={props.disableFuture} openTo="year" format="dd/MM/yyyy" views={["year", "month", "date"]}
                                label={field.label} value={props.getDateValue(field.name)} onChange={(date)=>props.handleChangeField(field.name, date)} required={props.required}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>}
                    {(field.type===FIELD_TYPES.CHECK_BOX)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <FormControlLabel
                            control={<Checkbox color='primary' checked={props.getValue(field.name, field.defaultValue)} onChange={handleChangeCheckBox} name={field.name} />}
                            label={field.label} disabled={field.disabled||false}
                        />
                    </Grid>}
                    {(field.type===FIELD_TYPES.CASH)&&<Grid item sm={field.sm || 6} xs={field.xs || 12}>
                        <TextField name={field.name} label={field.label} margin="none" fullWidth 
                            required={field.required||false} value={props.getValue(field.name, field.defaultValue)} onChange={props.handleChange}
                            helperText={field.helperText} type="number" onBlur={props.handleBlur} placeholder={field.placeholder}
                            InputProps={{endAdornment: <InputAdornment position="end">
                                <span onClick={showOptionsCurrency(field.curField)} style={{marginRight: 8, cursor: 'pointer'}}>{getCurrencyValue(field.curField, 'ILS')}</span>
                                <span onClick={showOptionsFrequency(field.freqField)} style={{cursor: 'pointer'}}>{getFrequencyValue(field.freqField, 'MONTH')}</span>
                            </InputAdornment>}}
                        />
                    </Grid>}   
                    {(field.type===FIELD_TYPES.TITLE)&&<FieldTitle field={field} getValue={props.getValue}/>}  
                    </>}
                    </>}
                </Fragment>)
            }

            <Menu
                id="options"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuOptions.map(option => (
                    <MenuItem key={option.value} value={option.value} onClick={handleSelectValue(option.value)}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    )
}