import React from 'react';

import AuthApi from "../../utils/AuthApi";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import HistogramJob from '../HistogramJob';
import CloseDialogButton from './CloseDialogButton';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class OrphanApplyDialog extends React.Component {
	state = {
		first_name: '',
		last_name: '',
		email: '',
		file: undefined,
		uploadError: '',
		applyError: '',
		isApplying: false
	}
	
	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	};

	handleApplyWithProfile = () => {
		const { job } = this.props

		if (job && job._id) {
			localStorage.setItem("jobIdToApply", this.props.job._id)
		}
		
		this.props.handleSignIn('signUp')
	}

	handleApplyWithResume = () => {
		const {file, email, first_name, last_name} = this.state

		localStorage.setItem("email", email)
		localStorage.setItem("first_name", first_name)
		localStorage.setItem("last_name", last_name)

		//ReactGA.event({category: 'uploadingResume',action: 'PC_uploadingStart_profile'});

        /*this.setState({uploading: true, uploadTitle: 'Uploading...'})
		this.timer = setTimeout((this.changeUploadTitle), 2000);*/

        const data = new FormData()
		data.append('file', file, file.name)
		data.append('email', email);
		data.append('first_name', first_name);
		data.append('last_name', last_name);
		data.append('jobId', this.props.job._id)

		this.setState({isApplying: true})

        AuthApi.applyOrphan(data, (results)=>{
			this.props.showConfirmOrphanApply()
        }, (error)=>{
			const errorText = (error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : 'Something went wrong'
			this.setState({applyError: errorText, isApplying: false})
		});
	}

	handleSelectedFile = event => {
        const maxUploadResumeSize = 500 * 1024; // 500 KB
        const file = event.target.files[0];

        if (!file) {
            return null
        }

        if(file.size > maxUploadResumeSize){
			this.setState({
				uploadError: 'File too big - 500 KB Max',
				file: undefined
			})
            return null
        }
        
        const extension =  file.name.split('.').pop();
        if(!extension.match(/docx|doc|pdf/ig)){
			this.setState({
				uploadError: 'Invalid extension',
				file: undefined
			})
            return null
		}
		
		this.setState({
			uploadError: '',
			file: file
		})
	}
	
	render(){
		const { first_name, last_name, email, uploadError, applyError, file, isApplying} = this.state
		const { job } = this.props

		const canApply = (first_name && last_name && email && file && file.name)

		return (
			<Dialog open={this.props.open} onClose={this.props.onClose} classes={{ paper: "orphanApplyDialogPaper" }}>
				<DialogContent>
					<CloseDialogButton onClick={this.props.onClose}s/>
					<HistogramJob job={job} orphanApply />
					<Divider/>
					<Grid container spacing={1} className="options">
						<Grid item sm={5} xs={12} style={{textAlign:'center', direction:'ltr'}}>
							<Typography variant="h6" className="title">Apply with JobSwipe profile</Typography>
							<img className="defAvatar" alt="default avatar" src="https://jobswipe.co/assets/images/def_avatar.jpg"/><br/>
							<Button variant="contained" color="primary" disabled={isApplying} onClick={this.handleApplyWithProfile}>Apply with profile</Button>
						</Grid>
						<Grid item sm={2} xs={12} style={{textAlign:'center'}}>
							<div className="orContainer">
								<div className="verticalLine"></div>
								<Typography className="orLabel">OR</Typography>
							</div>
						</Grid>
						<Grid item sm={5} xs={12} style={{direction:'ltr'}}>
							<Typography variant="h6" className="title">Apply with Resume</Typography>
							<TextField id="first_name" className="textField" name="first_name" label="First name" margin="none" fullWidth 
                                required value={first_name || ''} onChange={this.handleChange}/>
							<TextField id="last_name" className="textField" name="last_name" label="Last name" margin="none" fullWidth 
                                required value={last_name || ''} onChange={this.handleChange}/>
							<TextField id="email" className="textField" name="email" label="Email" margin="none" fullWidth 
								type="email" required value={email || ''} onChange={this.handleChange}/>
							{(uploadError)&&<Typography color="error">{uploadError}</Typography>}
							{(file && file.name)&&<Typography variant="caption" style={{display:'block'}}>{file.name}</Typography>}
							<input
								accept=".doc,.docx,.pdf"
								id="contained-button-file"
								type="file"
								style={{display:"none"}}
								onChange={this.handleSelectedFile}
							/>
							<label htmlFor="contained-button-file">
								<Button variant="outlined" color="primary" className="upload" component="span" aria-label="Upload">
									Upload Resume
								</Button>
							</label>
						</Grid>
					</Grid>
					<Typography className="terms" variant="caption">
						<span>By clicking Apply, I agree to JobSwipe's </span>
						<a target="_blank" rel="noopener noreferrer" href="/terms">Terms of Use</a>
						<span>, </span>
						<a target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>
						<span> and to save my application materials.</span>
					</Typography>
					{(applyError)&&<Typography style={{marginBottom:8, fontWeight:'bold'}}>{applyError}</Typography>}
					{(isApplying)?<CircularProgress/>:
					<Button variant="contained" color="primary" disabled={!canApply} onClick={this.handleApplyWithResume}>Apply</Button>}
				</DialogContent>
            </Dialog>
		)
	}
}