import React, { Component } from 'react'
import Share from '@material-ui/icons/Share';
import AccessTime from '@material-ui/icons/AccessTime';
import Work from '@material-ui/icons/Work';
import Public from '@material-ui/icons/Public';
import Search from '@material-ui/icons/Search';
import ChevronRight from '@material-ui/icons/ChevronRight';

import ShareDialog from './dialogs/ShareDialog'
import { getDateText, getHost, getCompanyText, getemploymentTypeText, getlocationText, pushShareGaEvent, validUrl, getJobBadge, NO_COMPANY, getJobRoute, filterUrl } from '../utils/utils'
import CompanyLogo from '../containers/CompanyLogo'
import EditJob from '../containers/EditJob';

import '../styles/JobCard.css'
import CardActionButton from '../containers/CardActionButton';
import ReportDialog from '../containers/ReportDialog';

import ReactGA from 'react-ga4'
import Tags from './Job/Tags';
import Salary from './Job/Salary';
import Description from './Job/Description';
import Contact from '../containers/Contact';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BriefcaseAccountIcon from 'mdi-material-ui/BriefcaseAccount'
import { Popover, Typography } from '@material-ui/core';

export default class JobCardContent extends Component {
	state = {
		open: false,
		anchorEl: null
	};

	showShareDialog = () => {
		const {histogramType}=this.props
		pushShareGaEvent(histogramType, 'show')

		this.setState({
			open: true,
		});
	};

	closeShareDialog = () => {
		const {histogramType}=this.props
		pushShareGaEvent(histogramType, 'close')
		
		this.setState({ 
			open: false 
		});
	};

	showReportDialog = () => {
		if (this.props.isAuthed){
			this.setState({
				openReportDialog: true,
			});
		} else {
			this.props.showLogin()
		}
	};

	closeReportDialog = () => {
		this.setState({ 
			openReportDialog: false 
		});
	};

	handleEditJob = () =>{
		this.props.setEditMode(true)	
	}

	pushGaEvent(action){
		ReactGA.event({category: 'Card',action: action})
	}

	showPopup = (e) => {
        this.setState({anchorEl: e.currentTarget});
    }

    hidePopup = () => {
        this.setState({anchorEl: null})
    }

    render() {
		const { job, selectedjobbgcolor,  histogramType, scrollToTop, userTags, isAuthed, lookingforwork } = this.props;
		const editMode = this.props.editMode || false;
		const host = getHost();

		const company = getCompanyText(job.company)
		const company_url = company === 'JobSwipe' ? 'jobswipe.co' : job.company_url
		const location = getlocationText(job.location);
		const employmentType = getemploymentTypeText(job.employmentType);
		const tags = job.tags || [];
		const dateText = getDateText(job.datecreated);		
		const applyOnTop = (this.props.applyOnTop) ? this.props.applyOnTop : false;

		const expired = (job.jobstatus !== 'A' && histogramType === 'jobs')
		
		const searchWords = this.props.searchWords ||  []

		let tagsSearch = '';
		let numOfWordsTotal = 0;

		for(let i=0; i<tags.length; i++){
			let tag = tags[i];
			let numOfWords = tag.split(' ').length;

			if (numOfWordsTotal + numOfWords <= 10){
				if(tagsSearch){
					tagsSearch = tagsSearch + ' ';
				}
				tagsSearch = tagsSearch + tag//.replace(/ /mgi, '%20');

				numOfWordsTotal += numOfWords;
			}
		}
		tagsSearch = filterUrl(tagsSearch)
		const companySearch = filterUrl(company)

		const searchByTagsString = (tags.length > 0) ? 'חפש מה שואלים בתחום' : 'חפש מה שואלים בראיונות עבודה'
		const searchByTagsLink = (tags.length > 0) ? "https://jobhunt.co.il/interviews/" + tagsSearch + "-interviews-SRCH_Q0," + tagsSearch.length + "?utm_source=JobSwipe&utm_campaign=JobSwipe_Jobs" : "https://jobhunt.co.il/interviews/?utm_source=JobSwipe&utm_campaign=JobSwipe_Jobs"

		const {badge, badgeDesc, badgePercentHiring} = getJobBadge(job, 'job', expired)

		const jobUrl = host + getJobRoute(job._id, job.title, job.company)

		const showPopup = Boolean(this.state.anchorEl)

        return (
			<React.Fragment>
				{(editMode)?<EditJob selectedjobbgcolor={selectedjobbgcolor}/>:
            <div className={"card " + job.direction}>
				<ShareDialog
					open={this.state.open}
					onClose={this.closeShareDialog}
					url={jobUrl}
					title={job.title}
					description={job.description}
					histogramType={histogramType}
				/>
                    <div className="cardHeader" style={{backgroundColor:selectedjobbgcolor}}>
						<div className="cardHeaderContent">
							<div className="jobTitleSection">
								<div className="jobTitle">
									{job.title || ''}
									{/*<Highlighter
										highlightClassName="YourHighlightClass"
										searchWords={searchWords}
										autoEscape={true}
										textToHighlight={job.title || ''}
									/>*/}
								</div>
								<div className={"shareButton " + job.direction}>
									<span onClick={this.showShareDialog}>
										<Share/>
									</span>
								</div>
								{(!applyOnTop && lookingforwork && isAuthed && histogramType !== 'myJobs') && <div className={"bookmarkIconContent " + job.direction}>
									{this.props.isBookedmark ? 
									<div onClick={this.props.removeSavedItem}>
										{(this.props.view !== 'MOBILE') ? <BookmarkIcon style={{color: '#396a92'}}/> : <BookmarkIcon/>}
										{(this.props.view !== 'MOBILE')&&<div className={"bookmarkIconContentText " + job.direction}>
											SAVED
										</div>}
									</div> :
									<div onClick={this.props.addSavedItem}>
										<TurnedInNotIcon/>
										{(this.props.view !== 'MOBILE')&&<div className={"bookmarkIconContentText " + job.direction}>
											SAVE
										</div>}
									</div>}
                    			</div>}
							</div>
							<div className="jobInfoSection">
								<CompanyLogo companyName={company} companyUrl={job.company_url} className="cardLogo"/>
								<div className={"jobInfoSectionInner " + job.direction}>
									<div className="jobCompany">{company}</div>
									<div className="jobLocation">{location}</div>
									<Contact direction={job.direction} type="jobs"/>
								</div>
							</div>
						</div>
					</div>
					
                    <div className="cardFooter">
						<CardActionButton selectedjobbgcolor={selectedjobbgcolor} applyOnTop={applyOnTop} scrollToTop={scrollToTop} type="jobs" expired={expired}/>
						{(badge)&&<div className={"jobBadge" + (expired ? ' expiredBadge': '')}>
							<span className="name">{badge}</span>
							<span>{badgeDesc}</span>
							{(badgePercentHiring)&&<React.Fragment>
								<span onClick={this.showPopup} className="connectAnyway">Connect anyway</span>
								<span>{badgePercentHiring}% This Recruiter is Hiring!</span>
								<Popover
									open={showPopup}
									anchorEl={this.state.anchorEl}
									onClose={this.hidePopup}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
								>
									<div style={{padding:8, maxWidth:500}}>
										<Typography>JobSwipe AI predicts job openings before they open publicly. Analyzing data & information of positions in companies, JobSwipe smart AI algorithms can help you apply early and improve your chances by actively reaching out to recruiters with openings before everyone else.</Typography>
										<Typography>JobSwipe pro subscribers can view Email\Phone details.</Typography>
									</div>
								</Popover>
							</React.Fragment>}
                        </div>}
						<div className="jobMoreInfoSection">
							<div className={"jobMoreInfo " + job.direction}>
								<span className="jobMoreInfoIcon">
									<AccessTime style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{dateText}</span>
							</div>
							<Salary jobInfo={job} noEdit/>
							<div className={"jobMoreInfo " + job.direction}>
								<span className="jobMoreInfoIcon">
									<Work style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{employmentType}</span>
							</div>
							{(job.seniorityLevel)&&<div className={"jobMoreInfo " + job.direction}>
								<span className="jobMoreInfoIcon">
									<BriefcaseAccountIcon style={{ fontSize: 18 }}/>
								</span>
								<span className="jobMoreInfoText">{job.seniorityLevel}</span>
							</div>}
						</div>
						<Description jobInfo={job} noEdit searchWords={searchWords} view={this.props.view}/>
						<Tags jobInfo={job} noEdit searchWords={searchWords} userTags={userTags} isAuthed={isAuthed}/>
						<CardActionButton selectedjobbgcolor={selectedjobbgcolor} scrollToTop={scrollToTop} type="jobs" footer expired={expired}/>
						{(company_url) &&
							<div className={"cardFooterLink " + job.direction}>
								<span className="icon">
									<Public/>
								</span>
								<a onClick={() => {this.pushGaEvent('company_link')}} className="link" target="_blank" rel="noopener noreferrer" href={validUrl(company_url)}>{company_url}</a>
								<span className="arrow">
									<ChevronRight/>
								</span>
							</div>}
						{(company && company !== NO_COMPANY) &&
							<div className={"cardFooterLink " + job.direction}>
								<span className="icon">
									<Search/>
								</span>
								<a onClick={() => {this.pushGaEvent('interviews_by_company')}} className="link" target="_blank" rel="noopener noreferrer" href={"https://jobhunt.co.il/interviews/" + companySearch + "-interviews-SRCH_Q0," + companySearch.length + "?utm_source=JobSwipe&utm_campaign=JobSwipe_Jobs"}>חפש מה שואלים בחברה</a>
								<span className="arrow">
									<ChevronRight/>
								</span>
							</div>}
						<div className={"cardFooterLink " + job.direction}>
							<span className="icon">
								<Search/>
							</span>
							<a onClick={() => {this.pushGaEvent('interviews_by_tags')}} className="link" target="_blank" rel="noopener noreferrer" href={searchByTagsLink}>{searchByTagsString}</a>
							<span className="arrow">
								<ChevronRight/>
							</span>
						</div>
						<ReportDialog
							type={"job"}
							objectId={job._id}
						/>
					</div>
				</div>}
			</React.Fragment>
        )
    }
}