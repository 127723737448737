import React from 'react';
import { Button } from '@material-ui/core';

export default function FilterButton(props) {
    return (
        <Button 
            size='small' 
            variant={(props.filter===props.value)?'contained':'outlined'}
            color='primary' 
            style={{marginRight: 8,marginBottom: 8}}
            onClick={props.changeFilter(props.value)}
        >{props.label}</Button>
    )
}