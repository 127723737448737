import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { ProfileActions } from '../profile/ProfileActions';

import '../../styles/Profile.css'
import LocationField from '../LocationField';
import Typography from '@material-ui/core/Typography';

export default class Location extends Component {
    state = {
        editMode: false,
        location: this.props.jobInfo.location,
        lat: undefined,
        lng: undefined
    }

    sectionFields = ['location', 'loc_lat', 'loc_lng', 'country', 'countryCode', 'streetAddress', 'postalCode'];

    componentDidUpdate(prevProps){
        if (this.props.jobInfo.location !== prevProps.jobInfo.location) {
            this.setState({
                location: this.props.jobInfo.location
            })
        }
    }

    handleEdit = () => {
        this.setState({
            editMode: true
        })

        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleSaveSection = e => {
        e.preventDefault();

        if(this.state.lat !== undefined && this.state.lng !== undefined) {

            this.props.handleSaveSection(this.sectionFields)

            this.setState({
                editMode: false
            })

            this.props.changeOnEditCount(false)
        } else {
            this.setState({location: ''})
        }

        this.setState({lng: undefined, lat: undefined})
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })

        this.props.changeOnEditCount(false)
    }

    handleLocationChange = (locationObj)  => {
        this.setState({lat: locationObj.lat})
        this.setState({lng: locationObj.lng})
        this.props.handleLocationChange(locationObj)
    }

    handleLocationType = value => {
        this.setState({
            location: value
        })
    }

    getFields = (jobInfo) => {
        const {location} = this.state

        return (
            <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                    <LocationField 
                        handleLocationChange={this.handleLocationChange} 
                        locationText={this.state.location || ''} 
                        setLocationText={this.handleLocationType} 
                        label="Location" 
                        margin="none" 
                        fullWidth 
                        required
                    />
                </Grid>
                {(location)&&<Grid item sm={6} xs={12}>
                    <TextField id="streetAddress" name="streetAddress" label="Street Address" margin="none" fullWidth 
                        value={jobInfo.streetAddress || ''} onChange={this.handleChange}/>
                </Grid>}
                {(location)&&<Grid item sm={6} xs={12}>
                    <TextField id="postalCode" name="postalCode" label="Zip Code" margin="none" fullWidth 
                        type="number" value={jobInfo.postalCode || ''} onChange={this.handleChange}/>
                </Grid>}
            </Grid>
        )
    }

    render() {
        const { jobInfo, isNew } = this.props
        const {editMode} = this.state        

        return (
            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">Job Location</div>
                <Typography className="sectionText" style={{marginBottom:16}}>Adding street address and zip code can help get your jobs more applicants</Typography>
                {(isNew) && this.getFields(jobInfo)}
                {(editMode && !isNew) && 
                    <form onSubmit={this.handleSaveSection}>
                        {this.getFields(jobInfo)}
                        <div className="profileButtons">
                            <Button
                                color="primary"
                                size="small"
                                onClick={this.handleCancel}
                                aria-label="Cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="small"
                                aria-label="Save Changes"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </form>}
                {(!editMode && !isNew) && 
                    <React.Fragment>
                        <ProfileActions handleEdit={this.handleEdit}/>  
                        <Grid container spacing={1} style={{marginTop:4}}>
                            <Grid item sm={12} xs={12}>
                                <Typography>{jobInfo.location}</Typography>
                            </Grid>
                            {(jobInfo.streetAddress === undefined || jobInfo.streetAddress === null || jobInfo.streetAddress === '')&&<Grid item sm={6} xs={12}>
                                <span onClick={this.handleEdit} className="profileEditText">+ add a street address</span>
                            </Grid>}
                            {(jobInfo.postalCode === undefined || jobInfo.postalCode === null || jobInfo.postalCode === '')&&<Grid item sm={6} xs={12}>
                                <span onClick={this.handleEdit} className="profileEditText">+ add a zip code</span>
                            </Grid>}
                        </Grid>
                    </React.Fragment>}
            </Paper>  
        )
    }
}