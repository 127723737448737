import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'

import Basic from '../components/profile/Basic'
import AboutMe from '../components/profile/AboutMe'
import Skills from '../components/profile/Skills'
import ProfileList from '../components/profile/ProfileList'
import Links from '../components/profile/Links'
import Resume from '../components/profile/Resume'

import Button from '@material-ui/core/Button';
import { updateUserInfo, toggleLoginDialog } from '../redux/actions/authActions'
import api from "../utils/HistApi";

import '../styles/Profile.css'
import { showDialog, showEnableSearchEngines } from '../redux/actions/MainDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { promoteObject } from '../redux/actions/selectedJob'
import { FormControlLabel, Switch, Typography, Paper } from '@material-ui/core'

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: this.props.userInfo,
            onEdit: 0
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.isAuthed !== this.props.isAuthed){
            this.setState({userInfo: this.props.userInfo})
        }
    }

    handleSaveSection = fields => {
        let userInfo = this.state.userInfo;

        let fieldsToUpdate = {};
        fields.forEach(field => {
            fieldsToUpdate[field] = userInfo[field];
        });
        
        this.saveSection(fieldsToUpdate)
    };

    saveSection = fieldsToUpdate => {
        api.saveProfile(fieldsToUpdate, (result)=>{
            this.updateUserData(result.userData)
        }, error =>{
            if (error.response.data){
                const { dispatch } = this.props
                dispatch(showDialog('Error', error.response.data))
            }
        })
    }

    updateUserData = userData => {
        const { dispatch } = this.props
        dispatch(updateUserInfo({userInfo: userData}, true));
    }

    handleChange = (name, value, save = false) => {
        if (Array.isArray(value)){
            value = [...value]
        }
        this.setState({
            userInfo : {...this.state.userInfo,
                [name]: value 
            }
        });

        if(save){
            this.saveSection({[name]:value})
        }
    };

    handleNewResume = (userData) => {
        this.setState({
            userInfo : {...this.state.userInfo,
                description: userData.description 
            }
        });

        const { dispatch } = this.props
        dispatch(updateUserInfo({userInfo: userData}, true));
    }

    handleLocationChange = (locationObj)  => {
        this.setState({
            userInfo : {...this.state.userInfo,
                location: locationObj.text,
                loc_lat: locationObj.lat,
                loc_lng: locationObj.lng,
                country: locationObj.country,
                countryCode: locationObj.countryCode,
                //streetAddress: streetAddress,
                //postalCode: postalCode,
            }
        });
    }

    handleUpdateTags = (tags, selectedTags) => {
        if (!tags){
            this.handleChange('selectedTags', selectedTags)
        } else if (!selectedTags){
            this.handleChange('tags', tags)
        } else {
            this.setState({
                userInfo : {...this.state.userInfo,
                    tags, selectedTags
                }
            });
        }
    }

    handleCancel = fields => {
        let userInfo = this.props.userInfo;

        let updatedUserInfo = {...this.state.userInfo}
        fields.forEach(field => {
            updatedUserInfo[field] = userInfo[field];
        });   
        
        this.setState({
            userInfo : updatedUserInfo
        });
    }

    showDialog = (title, message) => {
        const { dispatch } = this.props
        dispatch(showDialog(title, message))
    }

    showEnableSearchEngines = (title, message) => {
        const { dispatch } = this.props
        dispatch(showEnableSearchEngines(title, message))
    }

    changeOnEditCount = (increment) => {
        let onEdit = this.state.onEdit
        
        if (increment){
            onEdit++
        } else {
            onEdit--
        }

        this.setState({onEdit})
    }

    handlePromote = () => {
        const {dispatch, isAuthed, selectedObject, isOnProcess, userInfo} = this.props

        if (isAuthed){
            dispatch(promoteObject('profiles', selectedObject._id, isOnProcess, (userInfo.lookingforwork)?'jobseeker':'recruiter', 'profilesPromoteEditMode'))
        } else {
            dispatch(toggleLoginDialog(true))
        }
    }

    render() {
        const { isAuthed, selectedjobbgcolor, isOnProcess, selectedObject } = this.props

        const isPromoted = (selectedObject.promoted === 1)

        const { userInfo, onEdit } = this.state;
        return (
            <div className="editProfile">
                {(isAuthed && userInfo)&&<React.Fragment>
                    <Prompt
                        when={onEdit > 0}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />      
                    {(isOnProcess)?<CircularProgress/>:<React.Fragment>
                        <Button disabled={onEdit > 0} aria-label="Preview" style={(onEdit > 0)?{}:{backgroundColor:selectedjobbgcolor}} className="profileDoneEdit" variant="contained" color="primary" onClick={this.props.handleDoneEdit}>Preview</Button>
                        {(userInfo.lookingforwork)&&<Button color="secondary" style={{height:36}} 
                            className="profilePromote"
                            variant="contained"
                            onClick={this.handlePromote}
                            disabled={isPromoted}
                            aria-label={(isPromoted)?'Promoted':'Promote'}>{(isPromoted)?'Promoted':'Promote'}
                        </Button>}
                        <Paper className="profileSection" elevation={1}>
                            <FormControlLabel
                                checked={(this.props.userInfo.visibleSearchEngines !== false)}
                                control={<Switch color="primary"/>}
                                label="show on search engines"
                                labelPlacement="end"
                                name="visibleSearchEngines"
                                onChange={()=>{this.handleChange('visibleSearchEngines', !(this.props.userInfo.visibleSearchEngines !== false), true)}}
                            /><br/>
                            <Typography style={{color:'rgba(0, 0, 0, 0.54)'}}>Recruiters can find you on Google, Bing and other search engines</Typography>
                        </Paper>
                    </React.Fragment>}
                    <Basic changeOnEditCount={this.changeOnEditCount} userInfo={userInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} updateUserData={this.updateUserData} handleLocationChange={this.handleLocationChange}/>   
                    <AboutMe changeOnEditCount={this.changeOnEditCount} userInfo={userInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel}/>
                    <Skills changeOnEditCount={this.changeOnEditCount} userInfo={userInfo} handleChange={this.handleUpdateTags} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel}/>
                    <ProfileList changeOnEditCount={this.changeOnEditCount} field="experience" userInfo={userInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel}/>  
                    <ProfileList changeOnEditCount={this.changeOnEditCount} field="education" userInfo={userInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel}/>  
                    <Links changeOnEditCount={this.changeOnEditCount} userInfo={userInfo} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel}/>
                    <Resume changeOnEditCount={this.changeOnEditCount} userInfo={userInfo} selectedjobbgcolor={selectedjobbgcolor} showDialog={this.showDialog} showEnableSearchEngines={this.showEnableSearchEngines} handleChange={this.handleChange} handleSaveSection={this.handleSaveSection} handleCancel={this.handleCancel} handleNewResume={this.handleNewResume}/>
                </React.Fragment>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo } = state.authUser;
    const { job: selectedObject, isOnProcess } = state.selectedJob;

    return {
        isAuthed, userInfo, isOnProcess, selectedObject
    }
}

export default connect(mapStateToProps)(Profile)