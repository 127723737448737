import { REQUEST_JOB, 
  RECEIVE_JOB, 
  SELECT_JOB, 
  JOB_NOT_FOUND, 
  SET_EDIT_MODE, 
  NEW_OBJECT, 
  CLOSE_SELECTED_DIALOG, 
  CLEAR_OBJECT,
  PROCESS_START,
  PROCESS_END} from '../actions/selectedJob'

  const initialState = {
    editMode: false,
    isFetching: false,
    didInvalidate: false,
    notFound: false,
    job: {},
    openDialog: false,
    isNew: false,
    isOnProcess : false
  }

export function selectedJob(state = initialState, action) {
    switch (action.type) {
      case CLEAR_OBJECT:
        return initialState
      case REQUEST_JOB:
        return Object.assign({}, state, {
          isFetching: true,
          notFound: false,
          editMode:false,
        })
      case RECEIVE_JOB:
        return Object.assign({}, state, {
          isFetching: false,
          job: action.job,
          lastUpdated: action.receivedAt,
          notFound: false,
          openDialog: true
        })
      case SELECT_JOB:
        return Object.assign({}, state, {
          isFetching: false,
          job: action.job,
          notFound: (!action.job._id),
          editMode: action.editMode,
          openDialog: action.openDialog,
          isNew: action.isNew
        })
      case JOB_NOT_FOUND:
        return Object.assign({}, state, {
          isFetching: false,
          job: {},
          notFound: true,
          openDialog: true
        })
      case SET_EDIT_MODE:
        return Object.assign({}, state, {
          editMode: action.editMode
        })
      case NEW_OBJECT:
        return Object.assign({}, state, {
          editMode:true,
          job: {},
          notFound: false,
          openDialog: true,
          isNew: true
        })
      case CLOSE_SELECTED_DIALOG:
        return Object.assign({}, state, {
          openDialog: false
        })
      case PROCESS_START:
        return Object.assign({}, state, {
          isOnProcess: true
        })
      case PROCESS_END:
        return Object.assign({}, state, {
          isOnProcess: false
        })
      default:
        return state
    }
}