import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IconButton } from '@material-ui/core';
import { setHiddenListList } from '../redux/actions/histogram';
import { ReactComponent as ExpandCircleRight } from '../assets/svgs/expand_circle_right.svg';

class ToggleList extends Component {
    handleHideExpantList = () => {
        const { dispatch, hiddenList } = this.props
        dispatch(setHiddenListList(!hiddenList))
    }

    render() {
        const {hiddenList} = this.props

        return (
            <IconButton
                color='primary'
                onClick={this.handleHideExpantList}
                aria-expanded={hiddenList}
                aria-label="hide"
                className={'hideListButton' + (hiddenList?' on':'')}
            >
                <ExpandCircleRight style={{fill: '#396a92', height: 30, width: 30}}/>
            </IconButton>
        )
    }
}

function mapStateToProps(state) {
    const { hiddenList } = state.histogramData;

    return {
        hiddenList: hiddenList||false
    }
}

export default connect(mapStateToProps)(ToggleList)