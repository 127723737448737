import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';

import { ProfileActions } from './ProfileActions'
import '../../styles/Profile.css'

export default class AboutMe extends Component {
    state = {
        editMode: false
    }

    sectionFields = ['personal_info'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })
        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    }

    render() {
        const { userInfo, noEdit } = this.props
        //const searchWords = this.props.searchWords ||  []

        return (
            <React.Fragment>
                {(noEdit)?<React.Fragment>
                    {(userInfo.personal_info)&&<div className="resumeSection">
                        <div className="sectionTitle">About Me</div>
                        <div className="profileField"><span className="text">
                            {userInfo.personal_info || ''}
                            {/*<Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={searchWords}
                                autoEscape={true}
                                textToHighlight={userInfo.personal_info || ''}
                            />*/}
                        </span></div>
                    </div>}
                </React.Fragment>:
                
            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">About Me</div>
                {(this.state.editMode)?
                <form onSubmit={this.handleSaveSection}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                            <TextField id="personal_info" name="personal_info" label="Description" margin="none" fullWidth 
                                multiline rowsMax="4" value={userInfo.personal_info || ''} onChange={this.handleChange}/>
                        </Grid>
                    </Grid>
                    <div className="profileButtons">
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.handleCancel}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save Changes"
                        >
                            Save Changes
                        </Button>
                    </div>
                </form> :
                <React.Fragment>
                    <ProfileActions handleEdit={this.handleEdit}/>
                    <div className="profileField">
                        {(userInfo.personal_info)?
                            <span className="text">
                                {userInfo.personal_info || ''}
                                {/*<Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={searchWords}
                                    autoEscape={true}
                                    textToHighlight={userInfo.personal_info || ''}
                                />*/}
                            </span>:
                            <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Add an introduction</span> about yourself with a brief summary of your experience.</React.Fragment>}
                    </div>      
                </React.Fragment>}
            </Paper>}
            </React.Fragment>
        )
    }
}