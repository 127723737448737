import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Fields } from '../../Fields';
import { FIELD_TYPES } from '../../../utils/utils';
import JHApi from "../../../utils/JHApi"

export default function ContractOfferDialog(props) {
    const objectToEdit = props.objectToEdit
    const disabled = props.disabled || false

    const isAdmin = props.isAdmin
    const userId = props.userId
    
    const [error, setError] = useState();
    const [editId, setEditId] = useState();
    const [newData, setNewData] = useState({});

    const seniorities = [
        {
            value: 'Junior',
            label: 'Junior',
        },{
            value: 'Mid-Level',
            label: 'Mid-Level',
        },{
            value: 'Senior',
            label: 'Senior',
        }
    ]

    useEffect(() => {
        setError()
        setNewData({...objectToEdit})

        if (objectToEdit){
            setEditId(objectToEdit._id||-1)
        } else {
            setEditId()
        }
    }, [objectToEdit]);

    const handleCancel = () => {
        props.setObjectToEdit()
    }

    const handleChangeField = e => {
        setNewData({...newData,[e.target.name]: e.target.value})
    };

    const handleChangeFieldWithName = (name, value) => {
        setNewData({...newData,[name]: value})
    };

    const getValue = (name, defaultValue) => {
        if (newData[name] !== undefined){
            return newData[name]
        } else {
            return defaultValue
        }
    }

    const handleSave = e => {
        e.preventDefault();

        let paramsToPass = {newData}

        if (isAdmin){
            paramsToPass.userId = userId
        }

        props.startEditData()

        JHApi.createEditTalentOffer(paramsToPass,(response)=>{
            setEditId()
            setError()
            setNewData({})

            props.finishEditData(response)
        }, (error) => {
            if(error&&error.response&&error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }
            props.finishEditData()
        })
    }

    const handleChangeTitle = (titleGroup, titleCategories) => {
        if (getValue('title', '')){
            setNewData({...newData, titleGroup, titleCategories})
        } else {
            setNewData({...newData, title:titleGroup, titleGroup, titleCategories})
        }
    }

    const fields = [
        {name:'company', label:'Company', defaultValue: '', type:FIELD_TYPES.TEXT, required:true},
        {name:'titleGroup', label:'Professional Title', type:FIELD_TYPES.TITLE, handleChangeTitle, required: true},
        {name:'title', label:'Professional Title (Free Text)', defaultValue: '', hide: (!getValue('titleGroup', '') && !getValue('title', '')) , type:FIELD_TYPES.TEXT, required: true},
        {name:'seniority', label:'Seniority', defaultValue: '', type:FIELD_TYPES.SELECT, options:seniorities},

        {name:'firstSalaryOffer', label:'First Salary Offer', curField:'firstSalaryCur', freqField:'firstSalaryFreq', defaultValue: 0, type:FIELD_TYPES.CASH},
        {name:'finalSalary', label:'Final Salary', curField:'finalSalaryCur', freqField:'finalSalaryFreq', defaultValue: 0, type:FIELD_TYPES.CASH},
        {name:'food', label:'Food', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'transportation', label:'Transportation', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'bonuses', label:'Bonuses', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'hybridDays', label:'Hybrid Days', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'daysOff', label:'Days Off', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'companyLocation', label:'Company Location', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'extraPerks', label:'Extra Perks', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'optionsAndStockPackage', label:'Options & Stock Package', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'cLevelTips', label:'C-Level Tips', defaultValue: '', type:FIELD_TYPES.TEXT},
        {name:'clearJobDefenition', label:'Clear Job Defenition ', defaultValue: '', type:FIELD_TYPES.TEXT},
    ]

    return (
        <Dialog onClose={handleCancel} open={(editId!==undefined)}>
            <DialogTitle></DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField} handleChangeField={handleChangeFieldWithName}/>
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={handleCancel} color="primary" size="small" aria-label="Cancel">
                        Cancel
                    </Button>
                    <Button
                        disabled={disabled}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label='Save'
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}