import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ProfileActions } from '../profile/ProfileActions';
import EmploymentTypes from '../profile/EmploymentTypes'

import '../../styles/Profile.css'
import Typography from '@material-ui/core/Typography';
import { validUrl, getemploymentTypeText } from '../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class Basic extends Component {
    state = {
        editMode: false,
        updatePhoneToAllJobs: false
    }

    sectionFields = ['title', 'employmentType', 'company', 'company_url', 'seniorityLevel', 'phone', 'phoneToAllJobs'];

    handleEdit = () => {
        this.setState({
            editMode: true
        })

        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        const {userJobs} = this.props
        if (event.target.name === 'phone' && userJobs && userJobs.length > 1){
            this.setState({
                updatePhoneToAllJobs : true
            })
        }

        this.props.handleChange(event.target.name, event.target.value)
    };

    handleCheckBoxChange = name => event => {
        this.props.handleChange(name, event.target.checked)
    };

    handleSaveSection = e => {
        e.preventDefault();

        this.props.handleSaveSection(this.sectionFields)
        this.setState({
            editMode: false,
            updatePhoneToAllJobs: false
        })

        this.props.changeOnEditCount(false)
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false,
            updatePhoneToAllJobs: false
        })

        this.props.changeOnEditCount(false)
    }

    getFields = () => {
        const {jobInfo} = this.props
        const {updatePhoneToAllJobs} = this.state

        return (
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <TextField id="title" name="title" label="Title" margin="none" fullWidth 
                        required value={jobInfo.title || ''} onChange={this.handleChange}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControl required fullWidth>
                        <InputLabel htmlFor="employmentType">Employment Type</InputLabel>
                        <Select
                            value={jobInfo.employmentType || ''}
                            onChange={this.handleChange}
                            name="employmentType"
                            inputProps={{
                                id: 'employmentType',
                            }}
                        >
                            {EmploymentTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField id="company" name="company" label="Company" margin="none" fullWidth 
                        required value={jobInfo.company || ''} onChange={this.handleChange}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField id="company_url" name="company_url" label="Company Link" margin="none" fullWidth 
                        value={jobInfo.company_url || ''} onChange={this.handleChange}/>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <FormControl required fullWidth>
                        <InputLabel htmlFor="seniorityLevel">Seniority Level</InputLabel>
                        <Select
                            value={jobInfo.seniorityLevel || ''}
                            onChange={this.handleChange}
                            name="seniorityLevel"
                            inputProps={{
                                id: 'seniorityLevel',
                            }}
                        >
                            <MenuItem value="Junior">Junior</MenuItem>
                            <MenuItem value="Mid-Level">Mid Level</MenuItem>
                            <MenuItem value="Senior">Senior</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField id="phone" name="phone" label="Phone Number" margin="none" fullWidth 
                        value={jobInfo.phone || ''} onChange={this.handleChange} helperText="Phone number will be displayed only to a match"/>
                    {(updatePhoneToAllJobs)&&<FormControlLabel label="Set this phone number to other jobs"
                        control={<Checkbox
                            checked={jobInfo.phoneToAllJobs || false}
                            onChange={this.handleCheckBoxChange('phoneToAllJobs')}
                            value="phoneToAllJobs"
                            color="primary"
                        />}
                    />}
                </Grid>
            </Grid>
        )
    }

    render() {
        const { jobInfo, isNew } = this.props
        const {editMode} = this.state        

        return (
            <Paper className="profileSection" elevation={1}>
                <div className="sectionTitle">Job Details</div>
                {(isNew) && this.getFields()}
                {(editMode && !isNew) && 
                    <form onSubmit={this.handleSaveSection}>
                        {this.getFields()}
                        <div className="profileButtons">
                            <Button
                                color="primary"
                                size="small"
                                onClick={this.handleCancel}
                                aria-label="Cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="small"
                                aria-label="Save Changes"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </form>}
                {(!editMode && !isNew) && 
                    <React.Fragment>
                        <ProfileActions handleEdit={this.handleEdit}/>  
                        <Grid container spacing={1} style={{marginTop:4}}>
                            <Grid item sm={6} xs={12}>
                                <Typography>{jobInfo.title}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography>{getemploymentTypeText(jobInfo.employmentType)}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography>{jobInfo.company}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography>{(jobInfo.company_url)?<a rel="noopener noreferrer" target="_blank" href={validUrl(jobInfo.company_url)}>{jobInfo.company_url}</a>:
                                <span onClick={this.handleEdit} className="profileEditText">+ add a company link</span>}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography>{jobInfo.seniorityLevel}</Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography>{(jobInfo.phone)?<React.Fragment>{jobInfo.phone}</React.Fragment>:
                                    <React.Fragment>
                                        <span onClick={this.handleEdit} className="profileEditText">+ Phone number</span><br/>
                                        <Typography variant="caption">*phone number will be displayed only to a match</Typography>
                                    </React.Fragment>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </React.Fragment>}
            </Paper>  
        )
    }
}