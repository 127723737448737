import { AppBar, Button, Dialog, DialogContent, IconButton, Paper, TextField, Toolbar, Typography } from '@material-ui/core';
import LocationField from './LocationField';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import React from 'react';

export default function (props) {
    const {top, searchTitle, keyWordPlaceholder, queryString, locationText, setQueryString, setLocationText, histogramType,
        email, setEmail} = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const setLocationObj = locationObj => {
        setOpen(false)

        props.setLocationObj(locationObj)
    }

    const handleSearch = () => {
        setOpen(false)

        props.handleSearch()
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();
        handleSearch();
    }

    return (
        <div className="searchSection" style={{top}}>
            <Paper className="searchBox" elevation={1}>
                <div className="searchTextContainer" onClick={handleClickOpen}>
                    <Typography variant="h5" component="h4" className={queryString ? "keywords" : "keywords placeHolder"}>
                        {(histogramType === 'jobhuntUsers')?
                            queryString || "Name / Title"
                        :
                            queryString || keyWordPlaceholder
                        }
                    </Typography>
                    <Typography component="p" className={locationText ? "location" : "location placeHolder"}>
                        {(histogramType === 'jobhuntUsers')?
                            email || "Email"
                        :
                            locationText ? 'Near ' + locationText : "Location"
                        }
                    </Typography>
                </div>
                <Button className="searchButtonWithText" variant="contained" color="primary" onClick={handleSearch} aria-label="Search">
                    <Search />
                </Button>
            </Paper>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                //TransitionComponent={Transition} 
                >
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <IconButton aria-label="close" color="inherit" onClick={handleClose}>
                            <Close/>
                        </IconButton>
                        <Typography variant="h5" color="inherit" style={{flex:1}}>
                            {searchTitle}
                        </Typography>
                        <IconButton aria-label="Search" color="inherit" onClick={handleSearch}>
                            <Search/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className="searchDialogContent">
                    <form noValidate autoComplete="off" onSubmit={handleOnSubmit}>
                    {(histogramType === 'jobhuntUsers')?
                    <React.Fragment>
                        <TextField
                            fullWidth
                            id="keyWords"
                            className="keywordsField"
                            placeholder="Name"
                            type="search"
                            value={queryString}
                            onChange={setQueryString}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            className="keywordsField"
                            id="email"
                            type="search"
                            value={email}
                            onChange={setEmail}
                            placeholder='Email'
                            variant="outlined"
                            autoFocus
                        />
                    </React.Fragment>:
                    <React.Fragment>
                        <TextField
                            fullWidth
                            id="keyWords"
                            className="keywordsField"
                            placeholder={keyWordPlaceholder}
                            type="search"
                            value={queryString}
                            onChange={setQueryString}
                            variant="outlined"
                            autoFocus
                        />
                        <LocationField 
                            handleLocationChange={setLocationObj} 
                            locationText={locationText} 
                            setLocationText={setLocationText} 
                            variant="outlined" 
                            className="locationField" 
                            fullWidth
                        />
                    </React.Fragment>}
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="Search"
                        >Search
                    </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}