import React, { Component } from 'react'
import { connect } from 'react-redux'
//import DashboardJH from '../../components/JH/DashboardJH';
import { showDialog, showFeedback } from '../../redux/actions/MainDialog';
import { Typography, Button } from '@material-ui/core';
import { toggleLoginDialog } from '../../redux/actions/authActions';

class DashboardPC extends Component {
    showMessageDialog = (title, content, pre) => {
        const {dispatch} = this.props

        dispatch(showDialog(title, content, pre))
    }

    componentDidUpdate(prevProps){
        if (this.props.loginChecked && !this.props.isAuthed){
            this.handleSignIn()
        }
    }

    handleSignIn = () => {
		const { dispatch } = this.props

        dispatch(toggleLoginDialog(true, 'signIn'))
    }

    showFeedback = () => {
        const { dispatch } = this.props

        dispatch(showFeedback())
    }

    /*<DashboardJH showMessageDialog={this.showMessageDialog} userInfo={userInfo} isActiveJHUser={isActiveJHUser}/>*/
    //<div className="fullWidthContainer" style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}><Typography variant='h3'>Under Construction</Typography></div>

    render() {
        const { isJHUser, loginChecked, isAuthed, isActiveJHUser/*, userInfo, isActiveJHUser*/ } = this.props

        return (
            <>
                {(loginChecked && !isAuthed)?
                <div className="fullWidthContainer" style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.handleSignIn} color='primary' variant='contained'>Sign In</Button>
                    </div>
                </div>:
                <>{(isJHUser)?
                    <>{React.cloneElement(this.props.children, { isActiveJHUser, showMessageDialog: this.showMessageDialog, showFeedback: this.showFeedback})}</>
                :<div className="fullWidthContainer" style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                    <div style={{textAlign: 'center'}}>
                        <img src="https://jobhunt.co.il/wp-content/themes/jobboard/assets/images/jobhunt-ntl.png" alt="סוכן טאלנטים אישי לעולם ההיי-טק" style={{height: 60}}></img>
                        <Typography variant='h5' style={{margin: '16px 0'}}>There is no active plan found</Typography>
                        <Button rel="noopener noreferrer" href='https://jobhunt.co.il/pricing' color='primary' variant='contained'>Prices & Plans</Button>
                    </div>
                </div>}
                </>}
            </>
        )
    }
}

function mapStateToProps(state) {
    const { isAuthed, userInfo, loginChecked } = state.authUser;

    const isJHUser = (userInfo && userInfo.isJHUser)
    const isActiveJHUser = (userInfo && userInfo.isActiveJHUser)

    const userId = (userInfo||{})._id

    return {
        isAuthed, isJHUser, userInfo, userId, isActiveJHUser, loginChecked
    }
}

export default connect(mapStateToProps)(DashboardPC)