import React, { Component } from 'react'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';

import { ProfileActions } from './ProfileActions'
import UserAvatar from '../../containers/UserAvatar';
import AvatarEditDialog from '../dialogs/AvatarEditDialog';
import '../../styles/Profile.css'
import LocationField from '../LocationField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import EmploymentTypes from './EmploymentTypes'
import { getemploymentTypeText } from '../../utils/utils';
//import MuiPhoneNumber from "material-ui-phone-number";

export default class Basic extends Component {
    state = {
        editMode: false,
        openAvatarEditDialog: false,
        location: this.props.userInfo.location,
        lat: undefined,
        lng: undefined
    }

    sectionFields = ['first_name', 'last_name', 'title', 'employmentType', 'phone', 'location', 'loc_lat', 'loc_lng', 'country', 'countryCode', 'seniorityLevel'];

    componentDidUpdate(prevProps){
        if (this.props.userInfo.location !== prevProps.userInfo.location) {
            this.setState({
                location: this.props.userInfo.location
            })
        }
    }
    
    handleOpenAvatarEditDialog = () => {
        this.setState({ openAvatarEditDialog: true });
    };
    
    handleCloseAvatarEditDialog = () => {
        this.setState({ openAvatarEditDialog: false });
    };

    handleCloseAvatarEditDialogAndUpdateUser = (newUserData) => {
        this.props.updateUserData(newUserData);
        this.props.handleChange('avatar', newUserData.avatar)
        //this.setState({ openAvatarEditDialog: false });
    };

    handleEdit = () => {
        this.setState({
            editMode: true
        })
        
        this.props.changeOnEditCount(true)
    }

    handleChange = event => {
        this.props.handleChange(event.target.name, event.target.value)
    };

    handleLocationChange = (locationObj)  => {
        this.setState({lat: locationObj.lat})
        this.setState({lng: locationObj.lng})
        this.props.handleLocationChange(locationObj)
    }

    handleLocationType = newLocation => {
        this.setState({
            location: newLocation
        })
    }

    handleSaveSection = e => {
        e.preventDefault();
    
        this.props.handleSaveSection(this.sectionFields)
        
        if(this.state.lat !== undefined && this.state.lng !== undefined) {
            this.setState({
                editMode: false
            })

            this.props.changeOnEditCount(false)
        } else {
            this.setState({location: ''})
        }

        this.setState({lat: undefined, lng: undefined})
    };

    handleCancel = () => {
        this.props.handleCancel(this.sectionFields)
        this.setState({
            editMode: false
        })
        this.props.changeOnEditCount(false)
    }

    handleChangeAvatar = () => {
        this.handleOpenAvatarEditDialog();
    }

    /*handlePhoneChange = (value) => {
        console.log(value)
    }*/

    render() {
        const userInfo = this.props.userInfo

        /*let internationalPhone = userInfo.phone || ''

        if (internationalPhone && internationalPhone.charAt(0) === '0'){
            internationalPhone = '+972' + internationalPhone.substr(1)
        }*/

        const avatarSrc = (userInfo.avatar && userInfo.avatar.url) ? userInfo.avatar.url : undefined

        return (
            <Paper className="profileSection" elevation={1}>
                {(this.state.editMode)?
                <form onSubmit={this.handleSaveSection}>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField id="first_name" name="first_name" label="First name" margin="none" fullWidth 
                                required value={userInfo.first_name || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="last_name" name="last_name" label="Last name" margin="none" fullWidth 
                                required value={userInfo.last_name || ''} onChange={this.handleChange}/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="title" name="title" label="Professional Title" margin="none" fullWidth 
                                required value={userInfo.title || ''} onChange={this.handleChange}
                                helperText="Your goal title"/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="employmentType">Employment Type</InputLabel>
                                <Select
                                    value={userInfo.employmentType || 'FULL_TIME'}
                                    onChange={this.handleChange}
                                    name="employmentType"
                                    inputProps={{
                                        id: 'employmentType',
                                    }}
                                >
                                    {EmploymentTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <LocationField 
                                handleLocationChange={this.handleLocationChange} 
                                locationText={this.state.location || ''} 
                                setLocationText={this.handleLocationType}  
                                label="Location"
                                margin="none" 
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            {/*<MuiPhoneNumber
                                name="phone"
                                label="Phone Number"
                                defaultCountry={"il"}
                                value={internationalPhone}
                                onChange={this.handlePhoneChange}
                                fullWidth
                            />*/}
                            {<TextField id="phone" name="phone" label="Phone Number" margin="none" fullWidth 
                                value={userInfo.phone || ''} onChange={this.handleChange} helperText="Phone number will be displayed only to a match"/>}
                            {/*<ReactPhoneInput
                                defaultCountry='il'
                                label="Phone"
                                value={userInfo.phone || ''}
                                onChange={this.handlePhoneChange} // passed function receives the phone value
                                component={TextField}
                            />*/}
                            {/*<PhoneInput
                                name="phone"
                                country="IL"
                                placeholder="Enter phone number"
                                value={userInfo.phone || ''}
                                onChange={this.handlePhoneChange} 
                            label="Phone"/>*/}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl required fullWidth>
                                <InputLabel htmlFor="seniorityLevel">Seniority Level</InputLabel>
                                <Select
                                    value={userInfo.seniorityLevel || ''}
                                    onChange={this.handleChange}
                                    name="seniorityLevel"
                                    inputProps={{
                                        id: 'seniorityLevel',
                                    }}
                                >
                                    <MenuItem value="Junior">Junior</MenuItem>
                                    <MenuItem value="Mid-Level">Mid Level</MenuItem>
                                    <MenuItem value="Senior">Senior</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div className="profileButtons">
                        <Button
                            color="primary"
                            size="small"
                            onClick={this.handleCancel}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="small"
                            aria-label="Save Changes"
                        >
                            Save Changes
                        </Button>
                    </div>
                </form> :
                <React.Fragment>
                    <ProfileActions handleEdit={this.handleEdit}/>  
                    <AvatarEditDialog
                        open={this.state.openAvatarEditDialog} 
                        handleClose={this.handleCloseAvatarEditDialog}
                        handleUpdateUser={this.handleCloseAvatarEditDialogAndUpdateUser}
                        currAvatar={avatarSrc}/>
                    <div className="profileBasic" style={{display:'flex'}}>
                        <div>
                            <div className="profileAvatar" onClick={this.handleChangeAvatar}>
                                <UserAvatar firstName={userInfo.first_name} 
                                            lastName={userInfo.last_name} 
                                            name={userInfo.name} 
                                            avatarSrc={avatarSrc} 
                                            backgroundColor={userInfo.card_color}
                                            className="avatar" />
                                <span className="change">Change</span>
                            </div>
                        </div>
                        <div style={{margin:"13px 0 13px 16px"}}>
                            {(userInfo.title)?
                                <div className="name">{userInfo.title}</div>:
                                <div className="profileFieldText"><span onClick={this.handleEdit} className="profileEditText">+ Add your goal title</span></div>
                            }
                            <div className="profileFieldText">{userInfo.first_name + ' ' + userInfo.last_name}</div>
                            <div className="profileFieldText">{userInfo.seniorityLevel}</div>
                            <div className="profileFieldText">{getemploymentTypeText(userInfo.employmentType)}</div>
                            <div className="profileFieldText">{userInfo.location}</div>
                            <div className="profileFieldText">
                                {(userInfo.phone)?
                                    <React.Fragment>{userInfo.phone}</React.Fragment>:
                                    <React.Fragment><span onClick={this.handleEdit} className="profileEditText">+ Phone number</span></React.Fragment>}
                            </div>
                            <Typography variant="caption">*phone number will be displayed only to a match</Typography>
                        </div>
                    </div>
                </React.Fragment>}
            </Paper>  
        )
    }
}