import React, { Component } from 'react'

import { getHost, filterUrl, getHistogramTypeLabel } from '../utils/utils'

import '../styles/HistogramFooter.css'

export default class HistogramFooterLinks extends Component {
    getUrl(text){
        let host = getHost()
        let filteredText = filterUrl(text)
        const histogramType = this.props.histogramType
        return `${host}/${histogramType}/${filteredText}-${getHistogramTypeLabel(histogramType)}-SRCH_Q0,${filteredText.length}`;
        //return `${host}/${histogramType}/Israel-${filteredText}-${getHistogramTypeLabel(histogramType)}-SRCH_L0_Q7,${filteredText.length}`;
    }

    render() {
        const { label, links } = this.props;

        return (
            <React.Fragment>
                {(links.length > 0)&&
                <div className="histogramFooterLinks">
                    <div className="label">
                        {label}
                    </div>
                    <div className="links">
                        {links.map((link) => <div key={link}><a href={this.getUrl(link)}>{link}</a></div>)}
                    </div>
                </div>}
            </React.Fragment>
        )
    }
}