import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React from 'react'
import '../../styles/MatchDialog.css'

export default class ConfirmPushNotificationDialog extends React.Component {
    closeDialog = () => {
        this.props.onClose()
    }

    render() {

        return (
            <Dialog style={{fontSize: '16px', }} onClose={this.closeDialog} open={this.props.open} classes={{ paper: 'deleteAlertDialogPaper' }}>
                <Typography style={{fontSize: '16px'}}>
                    Send Push Notification?
                </Typography>
                <Box style={{marginTop: '32px', marginLeft: '40px'}}>
                    <Button style={{fontSize: '14px', color: '#4285f4', marginRight: '8px', padding: '0 8px', lineHeight: '20px', float: 'right'}} onClick={this.props.sendNotification}>
                        <span>Send</span>
                    </Button>
                    <Button style={{fontSize: '14px', color: '#4285f4', marginRight: '8px', padding: '0 8px', lineHeight: '20px', float: 'right'}} onClick={this.closeDialog}>
                        <span>Cancel</span>
                    </Button>
                </Box>
            </Dialog>
        )
    }

}