import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { dateAsString, FIELD_TYPES } from '../../utils/utils';
import { Fields } from '../Fields';
import EventApi from "../../utils/EventApi"

export default function EvetsRegister(props) {
    const isLoading = props.isLoading || false
    const isRegester = props.isRegester || false
    const occurence = props.selectedOccurence || {}

    const [registerData, setRegisterData] = useState({});
    const [error, setError] = useState('');
    const [limitReached, setLimitReached] = useState(false);

    const getValue = (name, defaultValue) => {
        if (registerData[name] !== undefined){
            return registerData[name]
        } else {
            return defaultValue
        }
    }

    const handleChangeField = e => {
        setRegisterData({...registerData,[e.target.name]: e.target.value})
    };

    const handleSaveRegister = e => {
        e.preventDefault();

        props.preServerCall()

        EventApi.registerToEvent({occurence, registerData},(response)=>{
            setError('')
            setRegisterData({})

            if (response.limitReached){
                EventApi.getEventData({eventUrl:response.eventUrl},(response)=>{
                    setLimitReached(true)
                    props.postServerCall(response)
                }, (error) => {
                    if (error && error.response && error.response.data){
                        setError(error.response.data)
                    } else {
                        setError('Something went wrong')
                    }
        
                    props.postServerCall()
                })
            } else {
                setError('')
                setRegisterData({})

                props.postServerCall(response, {closeRegister:true})
                props.postServerCall(null, {chngeUrl:'/events/'+response.eventUrl+'?id='+response.id+'&code='+response.code})
            }
        }, (error) => {
            if (error && error.response && error.response.data){
                setError(error.response.data)
            } else {
                setError('Something went wrong')
            }

            props.postServerCall()
        })
    }

    const handleAfterLimitReached = e => {
        setLimitReached(false)
        props.handleCloseRegister()
    }

    const handleCancelRegister = e => {
        setRegisterData({})
        setError('')
        props.handleCloseRegister()
    }

    const fields = [
        {name:'email', label:'אימייל', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true, inputType:'email'},
        {name:'firstName', label:'שם פרטי', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'lastName', label:'שם משפחה', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, required:true},
        {name:'phone', label:'טלפון', defaultValue: '', type:FIELD_TYPES.TEXT, sm:12, inputType:'tel', required:true},        
    ]

    return (
        <>
        {(limitReached)?
        <Dialog
            open={limitReached}
            onClose={handleAfterLimitReached}
            scroll="paper"
        >
            <DialogContent className='hebrew-text'>
                <Typography style={{fontSize:18}}>לא בוצע רישום <br/>
                    לא נשארו מקומות פנויים</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    size="small"
                    onClick={handleAfterLimitReached}
                    aria-label="אישור"
                >
                    אישור
                </Button>
            </DialogActions>
        </Dialog>:
        <Dialog
            open={isRegester}
            onClose={handleCancelRegister}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll="paper"
            className='hebrew-text'
        >
            {(occurence.eventId)&&<><DialogTitle id="alert-dialog-title">רישום JobHunt Webinar בתאריך {dateAsString(new Date(occurence.date))} בין 15:00-16:00</DialogTitle>
            <form onSubmit={handleSaveRegister}>
                <DialogContent dividers>
                    {(error)&&<Typography color="error">{error}</Typography>}
                    <Fields fields={fields} getValue={getValue} handleChange={handleChangeField}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleCancelRegister}
                        aria-label="בטל"
                        disabled={isLoading}
                    >
                        ביטול
                    </Button>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                        aria-label="הרשם"
                        disabled={isLoading}
                    >
                        הרשם
                    </Button>
                </DialogActions>
            </form></>}
        </Dialog>}
        </>
    )
}